import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Tabs, Timeline } from 'antd'

import { useSchemaForm } from '../../hooks/use-schema-form'
import { ISchemaEditorProps } from './interface'
import { SchemaContent, SchemaTitle, SchemaWrapper, TabContent } from './styles'

export const SchemaEditor: FC<ISchemaEditorProps> = ({ title, isEdit = false, formData }) => {
  const history = useHistory()

  const handleOnSave = useCallback(() => {
      history.push('/admin/schemas')
  }, [history])

  const { render: SchemaForm } = useSchemaForm({ onAfterSave: handleOnSave, isEdit: isEdit, formData: formData })

  return (
    <SchemaWrapper>
      <SchemaTitle>{title}</SchemaTitle>
      <SchemaContent>
        <SchemaForm />
        <Tabs type="card">
          <Tabs.TabPane tab="История изменений" key="1">
            <TabContent>
              <Timeline>
                <Timeline.Item>
                  {new Date().toLocaleDateString('ru-ru')} Создание новой структуры
                </Timeline.Item>
              </Timeline>
            </TabContent>
          </Tabs.TabPane>
        </Tabs>
      </SchemaContent>
    </SchemaWrapper>
  )
}
