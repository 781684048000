const JSONPATH_JOIN_CHAR = '.'
exports.JSONPATH_JOIN_CHAR = JSONPATH_JOIN_CHAR
exports.lang = 'ru_RU'
exports.format = [
  { name: 'date-time' },
  { name: 'date' },
  { name: 'email' },
  { name: 'hostname' },
  { name: 'ipv4' },
  { name: 'ipv6' },
  { name: 'uri' },
  { name: 'platform-collection' },
  { name: 'platform-user' },
  { name: 'date-time' },
  { name: 'data-url' },
  { name: 'email' },
  { name: 'phone' },
  { name: 'file-s3'},
]
exports.SCHEMA_TYPE = [
  {
    type: 'string',
    title: 'Строка',
  },
  {
    type: 'email',
    title: 'E-mail',
  },
  {
    type: 'phone',
    title: 'Телефон',
  },
  {
    type: 'number',
    title: 'Число',
  },
  {
    type: 'table',
    title: 'Таблица',
  },
  {
    type: 'array',
    title: 'Набор',
  },
  {
    type: 'collection',
    title: 'Cправочник',
  },
  {
    type: 'date',
    title: 'Дата',
  },
  {
    type: 'date-time',
    title: 'Дата и время',
  },
  {
    type: 'time',
    title: 'Время',
  },
  {
    type: 'data-url',
    title: 'Файл',
  },
  {
    type: 'object',
    title: 'Объект',
  },
  {
    type: 'boolean',
    title: 'Булево',
  },
  {
    type: 'integer',
    title: 'Целое число',
  },
  {
    type: 'user',
    title: 'Пользователь',
  },
  {
    type: 'file-s3',
    title: 'Файл S3',
  },
  {
    type: 'time-range',
    title: 'Временной интервал',
  },
]
exports.defaultSchema = {
  string: {
    type: 'string',
  },
  number: {
    type: 'number',
  },
  table: {
    type: 'array',
    format: 'table',
    items: {
      type: 'object',
      properties: {
        title: {
          type: 'string',
        }
      },
    },
  },
  array: {
    type: 'array',
    items: {
      type: 'string',
    }
  },
  collection: {
    type: 'string',
    format: 'platform-collection',
    'collection-name': '',
  },
  user: {
    type: 'string',
    format: 'platform-user',
  },
  date: {
    type: 'string',
    format: 'date',
  },
  time: {
    type: 'string',
    format: 'time',
  },
  email: {
    type: 'string',
    format: 'email',
  },
  phone: {
    type: 'string',
    format: 'phone',
  },
  'date-time': {
    type: 'string',
    format: 'date-time',
  },
  'time-range': {
    type: 'string',
    format: 'time-range',
  },
  'data-url': {
    type: 'string',
    format: 'data-url',
  },
  object: {
    type: 'object',
    properties: {},
  },
  boolean: {
    type: 'boolean',
    default: true,
  },
  integer: {
    type: 'integer',
  },
  'file-s3': {
    type: 'string',
    format: 'file-s3',
  }
}

// Функция стабилизации, уменьшает частоту выполнения высокочастотной триггерной функции
// Пожалуйста, используйте в конструкторе:

// this.func = debounce(this.func, 400);
exports.debounce = (func, wait) => {
  let timeout
  return function () {
    clearTimeout(timeout)
    timeout = setTimeout(func, wait)
  }
}

function getData(state, keys) {
  let curState = state
  for (let i = 0; i < keys.length; i++) {
    curState = curState[keys[i]]
  }
  return curState
}

exports.getData = getData

exports.setData = function (state, keys, value) {
  let curState = state
  for (let i = 0; i < keys.length - 1; i++) {
    curState = curState[keys[i]]
  }
  curState[keys[keys.length - 1]] = value
}

exports.deleteData = function (state, keys) {
  let curState = state
  for (let i = 0; i < keys.length - 1; i++) {
    curState = curState[keys[i]]
  }

  delete curState[keys[keys.length - 1]]
}

exports.getParentKeys = function (keys) {
  if (keys.length === 1) return []
  let arr = [].concat(keys)
  arr.splice(keys.length - 1, 1)
  return arr
}

exports.clearSomeFields = function (keys, data) {
  const newData = Object.assign({}, data)
  keys.forEach((key) => {
    delete newData[key]
  })
  return newData
}

function getFieldstitle(data) {
  const requiredtitle = []
  Object.keys(data).forEach((title) => {
    requiredtitle.push(title)
  })

  return requiredtitle
}

function handleSchemaRequired(schema, checked) {
  if (schema.type === 'object') {
    let requiredtitle = getFieldstitle(schema.properties)
    // schema.required = checked ? [].concat(requiredtitle) : [];
    if (checked) {
      schema.required = [].concat(requiredtitle)
    } else {
      delete schema.required
    }

    handleObject(schema.properties, checked)
  } else if (schema.type === 'array') {
    handleSchemaRequired(schema.items, checked)
  } else {
    return schema
  }
}

function handleObject(properties, checked) {
  for (var key in properties) {
    if (properties[key].type === 'array' || properties[key].type === 'object')
      handleSchemaRequired(properties[key], checked)
  }
}

exports.handleSchemaRequired = handleSchemaRequired

function cloneObject(obj) {
  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      var newArr = []
      obj.forEach(function (item, index) {
        newArr[index] = cloneObject(item)
      })
      return newArr
    } else {
      var newObj = {}
      for (var key in obj) {
        newObj[key] = cloneObject(obj[key])
      }
      return newObj
    }
  } else {
    return obj
  }
}

exports.cloneObject = cloneObject
