import {KeyPath} from "../types/types";

export const getDefaultPrintPaths = (
    api:string,
    pageSettings: URLSearchParams,
    settings: URLSearchParams
) => {
    settings.delete('page')
    return [
        {
            key: KeyPath.PAGE,
            path: `${api}?${pageSettings}`
        },
        {
            key: KeyPath.ALL_BY_SETTINGS,
            path: `${api}?${settings}`
        },
        {
            key: KeyPath.ALL,
            path: `${api}?pageSize=all`
        },
    ]
}