import styled from 'styled-components'

export const RibonWrapper = styled.div`
  width: 100%;
  display: flex;
  textarea {
    width: 100%;
  }
  div {
    width: 100%;
  }
  button {
    width: 100%;
  }
`
