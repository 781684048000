import styled from 'styled-components'

export const SchemaWrapper = styled.div`
  padding: 10px 0;
`

export const SchemaTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 12px;
`

export const SchemaContent = styled.div`
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  background: white;

  padding: 36px;
`

export const TabContent = styled.div`
  padding: 10px
`
