import React, { FC } from 'react';
import { PageTitle } from './styles'
import { IHeaderLeftContentProps } from './interface'

export const HeaderLeftContent: FC<IHeaderLeftContentProps> = (props) => {
  const { id, name } = props

  return (
    <PageTitle>
      <span>{id}:&nbsp;</span>
      <span>{name}</span>
    </PageTitle>
  )
}
