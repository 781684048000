import {TableTitle} from "../styles";
import Circle from "../circle/circle";
import {HStack, VStack} from "../../../../ui/Stack";
import React from "react";

export const CircleTemplate = ({percent}: { percent: number }) => {
    return (
        <div>
            <TableTitle>План ЦО</TableTitle>
            <Circle percent={percent}/>
            <HStack gap={"16"} justify={"between"}>
                <VStack align={"start"}>
                    <span>План ЦО</span>
                    <span style={{color: 'red', fontWeight: 'bold'}}>228</span>
                </VStack>
                <VStack align={"start"}>
                    <span>Факт ЦО</span>
                    <span style={{color: 'red', fontWeight: 'bold'}}>159</span>
                </VStack>
            </HStack>
        </div>
    )
}