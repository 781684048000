import { Button, Form, Input, Space } from 'antd'
import React, { memo, useCallback, useRef, useState } from 'react'
import { PaperClip } from '../../../../../assets/icons/PaperClip'
import { fileUpload } from '../../../../../connectors/file-s3-json-form/utils-files-s3'
import fetchAPI from '../../../../../../lib/utils/fetch-api'
import { ReplyBoxStyled } from './styles'
import { IReplyBox } from './types'


export const ReplyBox: React.FC<IReplyBox> = memo((props: IReplyBox) => {
  const { id, chat_id } = props
  const [form] = Form.useForm()
  const fileRef = useRef<any>(null)
  const [ isBusy, setIsBusy ] = useState(false)
  const hidden = { display: 'none' }

  const uploadFile = useCallback(async ({ target }) => {
    if(!Boolean(target.files[0])){
      return
    }
    try {
      setIsBusy(true)
      await fileUpload(target.files)
        .then(response => {
          const { url } = response
          if (url) {
            const value = form.getFieldValue('message')
            form.setFieldsValue({
              message: (value && value.length) ? `${value}\n${url}` : url
            })
          } else {
            alert('Не удалось получить ссылку')
          }
        })
        .finally( () => {
          setIsBusy(false)
        })
    } catch (e) {
      console.error({ e })
    }
  }, [])

  const clickFileInput = useCallback( () => {
    fileRef.current?.click()
  }, [fileRef])

  const sendMessage = useCallback(async () => {
    const value = form.getFieldValue('message')
    if (!value?.length || !chat_id) {
      console.error({ value, chat_id })
      return
    }
    try {
      setIsBusy(true)
      await fetchAPI(`/api/avito/chat/case/${id}/${chat_id}`, {
        method: 'POST',
        body: JSON.stringify({ msg: value })
      })
        .then(() => {
          form.setFieldsValue({ message: '' })
        })
        .catch(error => {
          console.error({ error })
        })
        .finally(() => {
        setIsBusy(false)
      })
    } catch (errors) {
      console.error({ errors })
    }
  }, [id, form])


  return (
    <ReplyBoxStyled>
      <Form
        form={form}
      >
        <Form.Item name='message'>
          <Input.TextArea
            rows={4}
            disabled={isBusy}
          />
        </Form.Item>
        <Space size='large'>
          <Button
            htmlType='submit'
            type='primary'
            onClick={sendMessage}
            disabled={isBusy}
          >
            Отправить сообщение
          </Button>
          <Button
            disabled={isBusy}
            type='link'
            icon={<PaperClip />}
            size='small'
            onClick={clickFileInput}
            title='Прикрепить файл'
          />
          <input
            name='file'
            type='file'
            onChange={uploadFile}
            style={hidden}
            ref={fileRef}
          />
        </Space>
      </Form>
    </ReplyBoxStyled>
  )
})
