export const organization = {
  "title": "Организация",
  "type": "pointer",
  "view": {
    "name": "select-from-organizations"
  }
}

export const user = {
  "title": "Менеджер",
  "type": "pointer",
  "view": {
    "name": "select-from-user"
  }
}

export const stage = {
  "type": "string",
  "title": "Стадия",
  "enum": [
    "new",
    "work",
    "delivery-planned",
    "team-arrived",
    "team-order-acceptance",
    "mounting-completed",
    "mounting-accepted",
    "mounting-not-accepted",
    "done",
  ],
  "oneOf": [
    {
      "const": "new",
      "title": "Новая"
    },
    {
      "const": "work",
      "title": "В работе"
    },
    {
      "const": "delivery-planned",
      "title": "Доставка запланирована"
    },
    {
      "const": "team-arrived",
      "title": "Бригада приехала"
    },
    {
      "const": "team-order-acceptance",
      "title": "Принятие заказа"
    },
    {
      "const": "mounting-completed",
      "title": "Монтаж произведен"
    },
    {
      "const": "mounting-accepted",
      "title": "Монтаж принят"
    },
    {
      "const": "mounting-not-accepted",
      "title": "Монтаж не принят"
    },
    {
      "const": "done",
      "title": "Выполнено"
    }
  ],
  "view": {
    "name": "select-from-enum"
  }
}

export const dateRange = {
  "type": "string",
  "format": "date-range",
  "title": "Дата"
}