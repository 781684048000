import React from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { MapTitle } from '../../card/styles'

const TableHeader = ({ organizationName, id }) => {
  const history = useHistory()

  const handleCreate = () => {
    history.push(`/office/${id}/new`)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <MapTitle>Список офисов <span>{organizationName}</span></MapTitle>
      <Button onClick={handleCreate}>
        <PlusOutlined /> Добавить офис
      </Button>
    </div>
  )
}

export default TableHeader