import React, { useState, useEffect, useMemo, useCallback } from 'react'
import B2BLayout from '../../../layouts/b2b'
import { Button, Table } from 'antd'
import { getColumns } from './columns'
import fetchAPI from '../../../../lib/utils/fetch-api'
import {useHistory, useLocation} from 'react-router-dom'
import FiltersList from '../../../components/v2/filters'
import { Sorter } from '../../../interfaces'
import { objectToUrl } from '../../utils'
import { PlusOutlined } from '@ant-design/icons'
import { currentFiltersProps, Enums, TablePaginationPosition } from './interface'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import {getDataOfType} from "../../../../lib/utils/get-data-of-type";
import {getJsonSettingsByKey, TransferItem, TableSettingsButton} from "../../../components/v2/table-settings-button";
import { HStack } from 'src/react-app/ui/Stack'
import {AppealsPrintButton} from "../ui/appeals-print-button/appeals-print-button";
import {getPrintPath} from "../../../components/v2/export-excel-button";

const createMenuCounters = createSelector(
  (state: any) => state.menuV2,
  counters => counters,
)

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

const AppealsList = () => {
  const history = useHistory()
  const [data, setData] = useState([])
  const [availableFilters, setAvailableFilters] = useState<Enums>({
    manager: [],
    stage: [],
    organization: [],
  })
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [currentFilters] = useState<currentFiltersProps>({
    phone: urlParams?.phone,
    manager: urlParams?.manager ? +urlParams.manager : undefined,
    id: urlParams.id ? +urlParams.id : undefined,
    organization: urlParams.organization ? +urlParams.organization : undefined,
    stage: urlParams?.stage,
    dates: urlParams.dates,
    brand: urlParams?.brand,
    office: urlParams?.office,
  })
  const { sessionData } = useSelector(createSession)
  const isLeader = getDataOfType(sessionData, 'organization.id', Number, null) === 1

  const [loading, setLoading] = useState(false)
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [stages, setStages] = useState<any>(null)
  const [sorter, setSorter] = useState<Sorter>({})

  const {pathname} = useLocation();
  const jsonSettings = useSelector((state) => getJsonSettingsByKey(state, pathname));

  const [pagination, setPagination] = useState({
    current: Number(urlParams?.page) || 1,
    pageSize: 10,
    position: [bottomCenter],
  })

  const {counters} = useSelector(createMenuCounters)

  const officeId = sessionData?.user?.office?.id
  const isAxiomaOffice = officeId === 1

  const [localCounter, setLocalCounter] = useState<null | number>(null)

  const areObjectsIdentical = (obj1: Object, obj2: Object): boolean => {
    if (obj1 === obj2) return true

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
      return false
    }

    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) return false

    for (const key of keys1) {
      if (!keys2.includes(key) || !areObjectsIdentical(obj1[key], obj2[key])) {
        return false
      }
    }

    return true
  }

  useEffect( () => {
    // устанавливает в локальную переменную текущее состояние счетчиков
    const newCounterData = counters?.['/appeals']
    if (newCounterData && !localCounter) {
      setLocalCounter(newCounterData)
    }
    // устанавливает в локальную переменную обновленное состояние счетчиков и подгружает свежие данные, если нужный счетчик обновился
    if (newCounterData && localCounter && newCounterData !== localCounter) {
      setLocalCounter(newCounterData)
      getData()
    }
  }, [counters])

  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(() => {
    return [
      {
        href: '/cases',
        title: 'Продажи',
      },
      {
        href: location.pathname,
        title: 'Обращения',
      },
    ]
  }, [])

  useEffect(() => {
    try {
      /* Пытаюсь получить стадии и фильтры */
      getStages()
    } catch (e) {
      alert('Не удалось получить данные, пожалуйста, перезагрузите страницу')
    }
  }, [])

  useEffect(() => {
    if (stages) {
      getData()
      getAvailableFilters()
    }
  }, [stages])

  /**
   * Получает стадии
   */
  const getStages = useCallback(() => {
    if (!stages) {
      fetchAPI('/api/get-stages/appeal')
        .then(data => {
          setStages(data)
        })
        .catch(() => {
          console.error('Не удалось получить стадии')
        })
    }
  }, [])

  /**
   * Удаляет запись
   */
  const deleteRecord = useCallback(currentId => {
    fetchAPI(`/api/appeal/${currentId}`, { method: 'DELETE' })
      .then(() => {
        getStages()
      })
      .catch(() => {
        console.error('Не удалось удалить запись')
      })
  }, [])

  interface IOrganization {
    value: string
    id: number
  }
  interface IStage {
    name: string
    code: string
  }
  interface IManager {
    value: string
    id: number
  }

  /**
   * Получает список доступных фильтров для обращений
   */
  const getAvailableFilters = useCallback(() => {
    const enumsList = { types: ['organizations', 'managers', 'brands', 'offices'] }
    fetchAPI(`/api/enum-list?${objectToUrl(enumsList)}`)
      .then(data => {
        const filters = {
          organization: data?.organizations.map((i: IOrganization) => ({ label: i.value, value: i.id })) || [],
          stage: stages.filter((i: IStage) => i.code !== 'cancelled').map((i: IStage) => ({ label: i.name, value: i.code })),
          manager: data?.managers?.map((i: IManager) => ({ label: i.value, value: i.id })),
          brand: data?.brands?.map((i: IManager) => ({ label: i.value, value: i.id })),
          office: data?.offices?.map((i: IManager) => ({ label: i.value, value: i.id })),
        }
        setAvailableFilters(filters)
      })
      .catch(() => {
        console.error('Не удалось получить список доступных фильтров')
      })
  }, [stages])

  /**
   * Получает записи
   */
  const getData = useCallback(() => {
    if (!stages) {
      getStages()
    }
    try {
      setLoading(true)
      const urlParams = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI(`/api/list/appeal?${objectToUrl(urlParams)}`).then(({ data, pagination, sorter }) => {
        if (data) {
          setData(
            data.map(item => {
              return {
                ...item,
                deleteRecord,
                stage: stages && stages.find(stage => stage.code === item.stage),
              }
            }),
          )
          setPagination(prevState => ({
            ...prevState,
            ...pagination
          }))
          setSorter(sorter)
        }
        setLoading(false)
      })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [deleteRecord, stages, loading, pagination])

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param _filters
   * @param sorter
   */
  const handleTableChange = (pagination, _filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    setPagination(prevState => ({...prevState, ...pagination}))

    const current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current,
      pageSize: pagination.pageSize
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    getData()
  }

  /**
   * Отправляет на страницу деталки
   * @param record
   */
  const handleRowClick = record => {
    const { id } = record
    history.push(`/appeal/${id}`)
  }

  /**
   * Отслеживает состояние фильтров
   * @param filters
   */
  const changeFiltersHandler = useCallback(filters => {
    let tmpFilter = {...(filters || {}), pageSize: pagination.pageSize }
    const newUrlParams = new URLSearchParams(tmpFilter).toString()
    history.push({ search: newUrlParams })
    getData()
  }, [pagination])

  const handleCreateNewClick = useCallback( () => {
    history.push('/appeal/new')
  }, [history])

  const filters: filtersType[] = [
    {
      name: 'id',
      placeholder: 'ID',
      type: 'input-integer',
      value: currentFilters?.id,
      options: {
        width: '170px',
      },
    },
    {
      name: 'phone',
      type: 'phone',
      value: currentFilters?.phone,
    },
    {
      name: 'stage',
      placeholder: 'Фильтр по стадии',
      type: 'select',
      value: currentFilters?.stage,
      options: {
        enum: availableFilters?.stage,
      },
    },
    /*{
      name: 'manager',
      placeholder: 'Фильтр по менеджеру',
      type: 'select',
      value: currentFilters?.manager,
      options: {
        enum: availableFilters?.manager,
      },
    },*/
    {
      name: 'dates',
      type: 'dates',
      value: currentFilters?.dates,
    },
  ]

  if (isAxiomaOffice) {
    filters.unshift({
      name: 'office',
      placeholder: 'Офис',
      type: 'select',
      value: currentFilters?.office,
      options: {
        enum: availableFilters?.office || [],
      },
    })
    filters.unshift({
      name: 'brand',
      placeholder: 'Бренд',
      type: 'select',
      value: currentFilters?.brand,
      options: {
        enum: availableFilters?.brand || [],
      },
    })
  }

  const columns = useMemo(() => getColumns(isLeader, sorter), [isLeader, sorter]);
  const viewColumns: typeof columns = useMemo(() => {
    if (jsonSettings && jsonSettings?.length > 0) {
      let result: any[] = []
      for (let i = 0; i < jsonSettings.length; i++) {
        // @ts-ignore
        let item = columns.find((column) => jsonSettings[i] === column.dataIndex)
        if (item) {
          result.push(item)
        }
      }
      return result;
    }
    return columns;
  }, [columns, jsonSettings]);

  const excelPath = useMemo(() => {
    return getPrintPath('/api/list/appeal', new URLSearchParams(location.search))
  }, [location.search])

  const settingsColumns = useMemo(() => columns.map((column) => ({
    // @ts-ignore
    key: column?.dataIndex, title: column?.title,
  })), [columns]) as TransferItem[]

  return (
    <B2BLayout breadcrumbs={breadcrumbs} isViewScrollButton>
      <FiltersList
        beforeElement={
          <Button onClick={handleCreateNewClick}>
            <PlusOutlined />
            Оформить обращение
          </Button>}
        filters={filters}
        onChange={changeFiltersHandler}
      />
      <HStack gap={'24'} align={'start'} justify={'end'} style={{marginBottom: '20px'}}>
        <AppealsPrintButton
            path={excelPath}
            columns={viewColumns}
            stages={stages}
        />
        <TableSettingsButton items={settingsColumns}/>
      </HStack>
      <Table
        showSorterTooltip={{ overlay: 'Нажмите для сортировки', placement: 'bottom' }}
        columns={viewColumns}
        rowKey={r => r?.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={record => ({ onClick: () => handleRowClick(record) })}
        scroll={{
          x: 'max-content',
        }}
        size="small"
      />
    </B2BLayout>
  )
}

export default AppealsList
