import React from 'react'
import { Select } from 'antd'
import {IFilterOption} from "../../model/types/types";

export interface IBrandFilterProps {
  selectedBrands: string[]
  onBrandChange: (brands: string[]) => void
  brands: IFilterOption[]
  style?: React.CSSProperties
}


const { Option } = Select

const BrandFilter: React.FC<IBrandFilterProps> = ({
  selectedBrands,
  onBrandChange,
  brands,
  style,
}) => {
  return (
    <Select
      mode="multiple"
      value={selectedBrands}
      onChange={onBrandChange}
      style={style}
      placeholder="Все бренды"
      allowClear
    >
      {brands.map((brand) => (
        <Option key={brand.id} value={brand.id}>
          {brand.value}
        </Option>
      ))}
    </Select>
  )
}

export default BrandFilter
