import React, { useState, useRef, useEffect, useCallback,useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Alert, Skeleton, Button, Space } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import { createUISchemaVertical } from 'src/lib/utils/grid-fild-orientation'
import fieldsSchema from './json-schema.json'
import { objectFilter } from 'src/lib/utils/get-table-transform-schema-and-form-data'
import TableConversion from './table-conversion'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { modalAddMutualSettlements } from 'src/react-app/views/modal-add-mutual-settlements'


const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)


function downloadUrl(url, exportName) {
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', url)
  downloadAnchorNode.setAttribute('download', exportName)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

const fieldsFormDataCode = {
  'conversion-organization': ['date-range', 'organization', 'users'],
  'rating-organization': ['organization'],
  'income-expenses': ['stage', 'order-group', 'date-range','organization']
}

function isFormDataValid(formData, reportCode) {
  const fields = getDataOfType(fieldsFormDataCode, reportCode, Array, []) as string[]
  const keys = Object.keys(formData)
  return fields.map(item => keys.includes(item)).every(item => item) &&
  Object.values(formData).every(item => item)
}

const rolesAll = [19, 12, 3, 4, 5, 6, 18]

function isDublicate(arr1, arr2) {
  const concatArray = arr1.concat(arr2)
  return concatArray.length > Array.from(new Set(concatArray)).length
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const breadcrumbs = [
  {
    href: '/reports',
    title: 'Отчеты',
  },
  {
    href: `/reports`,
    title: 'Список отчетов',
  },
]


// UISchema?.options?.organizationId

function fieldsSchemaFromCode(reportCode) {
  const fields = getDataOfType(fieldsFormDataCode, reportCode, Array, []) as string[]
  const filter = ([key]) => fields.includes(key)
  return {
    ...fieldsSchema,
    properties: objectFilter(fieldsSchema.properties, filter)
  }
}



export default function AdminNewObjectPage() {
  const { id } = useParams()  as any
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({ users: [], 'order-group': 'order', 'stage': 'all' })
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [object, setObject] = useState<any>({})
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [tableData, setTableData] = useState<any>({ titles: [], rows: [] })
  const { sessionData } = useSelector(createSession)

  const onPrint = useCallback(
    async () => {
      const formData = formDataRef.current
      const reportCode = getDataOfType(object, 'data.attributes.code', String, null)
      if (isFormDataValid(formData, reportCode) === false) {
        return
      }

      const params = Object.entries(formData)
        .map(([key, value]: any[]) => {
          const localValue = Array.isArray(value) ? value.join(',') : value
          return `${key}=${encodeURIComponent(localValue)}`
        })
        .join('&')
      downloadUrl(`/api/print-report/excel/${reportCode}?${params}`, `${reportCode}.xlsx`)
    },
    [object]
  ) 

  const getData = useCallback(async (id, sessionData) => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, 0)
    const userId = getDataOfType(sessionData, 'user.id', Number, 0)
    const isDisabledOrganization = isDublicate(roles, rolesAll) === false
    if (isDisabledOrganization) {
      formDataRef.current.organization = organizationId
    }
    const isDisabledUsers = isDublicate(roles, rolesAll.concat(24)) === false
    if (isDisabledUsers) {
      formDataRef.current.users = [userId]
    }

    const responseObject = await fetchAPI(`/api/collections/objects/${id}`)
    const object = responseObject['data']
    setObject(object)
    
    {
      const result = await fetchAPI(`/api/profiles-types?type=organization`)
      const profilesTypes = getDataOfType(result, 'data', Array, [])

      const defaultPrifileId = getDataOfType(profilesTypes, '[0].id', [String, Number], null)

      {
        const result = await fetchAPI(`/api/profiles?type=organization&organization-id=${id}`)
        const data = getDataOfType(result, 'data', Array, [])       
        const prifileId = getDataOfType(data, '[0].id', [String, Number], defaultPrifileId)
        const prifileTypeId = Number(getDataOfType(data, '[0].profile-type', [String, Number], 3))
        console.log(prifileId, prifileTypeId)

        const reportCode = getDataOfType(object, 'data.attributes.code', String, null)
        const jsonShema = fieldsSchemaFromCode(reportCode)

        const UISchema = createUISchemaVertical(jsonShema)
        if (reportCode === 'rating-organization') {
          UISchema.organization = {
            ...UISchema.organization,
            options: { 
              types: [5],
              disabled: isDisabledOrganization
            }
          }
        }
        if (reportCode === 'income-expenses') {
          UISchema.organization = {
            ...UISchema.organization,
            options: { 
              types: [5,2,3],
              disabled: isDisabledOrganization
            }
          }
        }
        if (reportCode === 'conversion-organization') {
          UISchema.users = {
            ...UISchema.users,
            options: { 
              organizationId: isDisabledOrganization ? organizationId : 0,
              roleId: [2,10],
              disabled: isDisabledUsers
            }
          }
          UISchema.organization = {
            ...UISchema.organization,
            options: { 
              types: [5],
              disabled: isDisabledOrganization
            }
          }
        }

        setUISchema(UISchema)
        setMatchedJSONSchema(jsonShema)
      }
    }


    setPageErrors([])
    setTableData({ titles: [], rows: [] })
    setIsDataLoading(false)
  }, [])

  useEffect(() => {
    getData(id,sessionData)
  }, [getData, id, sessionData])

  const onFormData = useCallback(
    ({ formData }) => {
      if (formData['organization'] !== formDataRef.current['organization']) {
        formData['users'] = []
      }
      //const formDataPrev = {...formDataRef.current }
      formDataRef.current = { ...formData }
      // Проверяем, что изменили организацию
      const organization = getDataOfType(formData, 'organization', Number, 0)
      const roles = getDataOfType(sessionData, 'roles', Array, [])
      const isDisabledUsers = isDublicate(roles, rolesAll.concat(24)) === false

        setUISchema(UISchema => {
          const UISchemaNext = { ...UISchema }
          if ("users" in UISchemaNext) {
            UISchemaNext.users = {
              ...UISchemaNext.users,
              options: { 
                organizationId: organization,
                roleId: [2,10],
                disabled: isDisabledUsers
              }
            }
          }
          return UISchemaNext
        })
    }, [sessionData]
  )

  const name = useMemo(() => {
    return getDataOfType(object, 'name', String, '')
  }, [object])

  const updateTable = useCallback(async () => {
    const reportCode = getDataOfType(object, 'data.attributes.code', String, null)
    const formData = formDataRef.current
    if (isFormDataValid(formData, reportCode) === false) {
      return
    }
    const params = Object.entries(formData)
      .map(([key, value]: any[]) => {
        const localValue = Array.isArray(value) ? value.join(',') : value
        return `${key}=${encodeURIComponent(localValue)}`
      })
      .join('&')
    
    const result = await fetchAPI(`/api/report-data/excel/${reportCode}?${params}`)
    const data = getDataOfType(result, 'data', Object, {})

    console.log(data)

    setTableData(data)
  },
  [object])
  const addPay = useCallback(async () => {
    const result: any = await modalAddMutualSettlements({})
    if (result === null) {
      return
    }

    await fetchAPI(`/api/web-hooks/add-mutual-settlements`, { method: 'POST', body: JSON.stringify({
      organization: formDataRef.current.organization,
      ...result
    })})

    console.log(result)
    updateTable()
  }, [updateTable])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            Отчет: <span>{`${name}, № ${id}`}</span>
          </MapTitle>
        </TaskWrapper>
        <MapContent>
        
          <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {isDataLoading === false ? (
              UISchema && (
                  <DefaultJsonForm
                    formData={formDataRef.current}
                    schema={matchedJSONSchema}
                    onChange={onFormData}
                    UISchema={UISchema}
                  />
              )
            ) : (
              <Skeleton active />
            )}
          </Form>
          <Space>
            <Button onClick={updateTable} style={{ marginBottom: 16, marginTop: 16 }} >
              <SaveOutlined />
              Применить фильтры
            </Button>
            <Button onClick={onPrint} style={{ marginBottom: 16, marginTop: 16 }} type="primary">
              <SaveOutlined />
              Экспорт в эксель
            </Button>
            <Button onClick={addPay} style={{ marginBottom: 16, marginTop: 16 }} > Добавить платеж </Button>
          </Space>
          {isDataLoading === false ? (
            <TableConversion titles={tableData.titles} rows={tableData.rows} />
          ): (
            <Skeleton active />
          )}
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
