import React, {memo, Suspense, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    ActivityTimelineContextProps,
    ActivityTimeline,
    ActivityTimelineContext,
    selectTimelineError,
    selectTimelineFiles,
    selectTimelineIsLoading,
} from "../../../../../components/v2/activity-timeline";
import {UploadFiles} from "../../../../../components/v2/upload-files";
import {deleteTimeLineItem} from "../../../../../store/timeline/actions";

interface HrFilesTimelineProps {
    className?: string
    id: string | number
}

export const HrFilesTimeline: React.FC<HrFilesTimelineProps> = memo((props: HrFilesTimelineProps) => {
    const {id, className} = props;
    const dispatch = useDispatch();
    const timelineIsLoading = useSelector(selectTimelineIsLoading);
    const timelineError = useSelector(selectTimelineError);
    const timelineFiles = useSelector(selectTimelineFiles);
    const entityType = "hr";
    const url = "hr/request";

    const deleteHrTimelineItem = useCallback((activityItem) => {
        dispatch(deleteTimeLineItem(activityItem))
    }, [])

    const defaultProps = useMemo(
        (): ActivityTimelineContextProps => ({
            card_id: id,
            card_type: entityType,
            deleteTimelineItem: deleteHrTimelineItem,
        }),
        [id],
    );

    return (
        <>

            <Suspense fallback={''}>
                <UploadFiles
                    entityType={entityType}
                    entityId={id}
                    url={`/api/${entityType}/request/${id}/add-file`}
                />
            </Suspense>
            <br/>
            <Suspense fallback={''}>
                <ActivityTimelineContext.Provider value={defaultProps}>
                    <ActivityTimeline
                        isLoading={timelineIsLoading}
                        items={timelineFiles}
                        error={timelineError}
                        className={className}
                        emptyMessage={'Нет прикрепленных файлов'}
                    />
                </ActivityTimelineContext.Provider>
            </Suspense>
        </>
    );
});

