import React from 'react'
import IconWrapper from './styles'

export const BurgerIcon = () => {
  return (
    <IconWrapper>
      <svg
        width="38"
        height="38"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M10 13.25C10 12.5596 10.5596 12 11.25 12H28.75C29.4404 12 30 12.5596 30 13.25C30 13.9404 29.4404 14.5 28.75 14.5H11.25C10.5596 14.5 10 13.9404 10 13.25Z"
          fill="#1C3775"
        />
        <path
          d="M10 19.45C10 18.7596 10.5596 18.2 11.25 18.2H28.75C29.4404 18.2 30 18.7596 30 19.45C30 20.1404 29.4404 20.7 28.75 20.7H11.25C10.5596 20.7 10 20.1404 10 19.45Z"
          fill="#1C3775"
        />
        <path
          d="M10 25.65C10 24.9596 10.5596 24.4 11.25 24.4H28.75C29.4404 24.4 30 24.9596 30 25.65C30 26.3404 29.4404 26.9 28.75 26.9H11.25C10.5596 26.9 10 26.3404 10 25.65Z"
          fill="#1C3775"
        />
      </svg>
    </IconWrapper>
  )
}
