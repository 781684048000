import React, { useState } from 'react';
import { Form, Input, Select } from 'antd';
import { getSuggestions, Suggestion } from './dadata-service';

const { Option } = Select;

const AddressForm: React.FC = () => {
  const [form] = Form.useForm();
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [district, setDistrict] = useState<string>('');

  const handleInputChange = async (value: string) => {
    setInputValue(value);
    if (value.length > 2) {
      try {
        const suggestions = await getSuggestions(value);
        setSuggestions(suggestions.filter(suggestion =>
          suggestion.data.city || (suggestion.data.city_district || suggestion.data.street_with_type || suggestion.data.settlement_with_type)
        ));
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSelect = (value: string) => {
    const selectedSuggestion = suggestions.find(suggestion => suggestion.value === value);
    if (selectedSuggestion) {

      let district = selectedSuggestion.data.city_district ||
        selectedSuggestion.data.street_with_type ||
        selectedSuggestion.data.settlement_with_type;

      setCity(selectedSuggestion.data.city);
      setDistrict(district);

      form.setFieldsValue({
        address: value,
        city: selectedSuggestion.data.city,
        district: district,
      });
    }
    setInputValue(value);
    setSuggestions([]);
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        label="Адрес"
        name="address"
        rules={[{ required: true, message: 'Пожалуйста, введите адрес' }]}
      >
        <Select
          showSearch
          value={inputValue}
          placeholder="Введите адрес"
          notFoundContent={null}
          filterOption={false}
          onSearch={handleInputChange}
          onChange={handleSelect}
        >
          {suggestions.map((suggestion) => (
            <Option key={suggestion.value} value={suggestion.value}>
              {suggestion.value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Город" name="city">
        <Input value={city} readOnly />
      </Form.Item>
      <Form.Item label="Район / Улица / Населенный пункт" name="district">
        <Input value={district} readOnly />
      </Form.Item>
    </Form>
  );
};

export default AddressForm;
