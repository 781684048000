import styled from 'styled-components'
import { Space } from 'antd'

export const SpaceWrapper = styled(Space)`
  width: 100%;
  height: 32px;
  align-items: flex-start;
  & div:first-child {
    width: 100%;
  }
`
