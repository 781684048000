import React, { useCallback, useEffect, useState } from 'react'
import { Space, Slider} from 'antd'
//import SelectFromOrganizations from 'src/react-app/connectors/select-from-organizations'
import SelectFromEnum from 'src/react-app/components/select-from-enum'
import InputDateRange from 'src/react-app/components//input-date-range'
//import SelectFromUser from 'src/react-app/connectors/select-from-user'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { dateRange, stage } from './utils'
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)




const rolesAll = [19, 12, 3, 4, 5, 6, 18]

function isDublicate(arr1, arr2) {
  const concatArray = arr1.concat(arr2)
  return concatArray.length > Array.from(new Set(concatArray)).length
}

function Filter({ onChange }) {
  const { sessionData } = useSelector(createSession)
  const [formData, setFormData] = useState<any>(() =>({
    //'organization-id': undefined,
    'date-range': undefined,
    'region': undefined,
    'city': undefined,
    'rating': '0-100',
  }))
/*   const [ setUISchemaSelectFromUsers] = useState({
    options: { width: 200, allowClear: true, showSearch: true, roleId: 2, placeholder: 'Менеджер' }
  })
  const [ setUISchemaSelectFromOrganizations] = useState({
    options: { width: 200, allowClear: true, types: [5], showSearch: true, placeholder: 'Организация' }
  }) */


  const [ UISchemaSelectFromEnum, setUISchemaSelectFromEnum] = useState({
    options: { width: 200, placeholder: 'Регион', allowClear: true, showSearch: true,  }
  })
  const [ UISchemaSelectFromEnum2, setUISchemaSelectFromEnum2] = useState({
    options: { width: 200, placeholder: 'Город', allowClear: true, showSearch: true,  }
  })



  useEffect(() => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    //const organizationId = getDataOfType(sessionData, 'organization.id', Number, 0)
    if (isDublicate(roles, rolesAll) === false) {
      setFormData(data => ({ ...data, 'organization-id': 0 }))
      setUISchemaSelectFromEnum(({ options }) => ({ options: { ...options, disabled: true }  }))
      setUISchemaSelectFromEnum2(({ options }) => ({ options: { ...options, disabled: true }  }))
      //setUISchemaSelectFromUsers(({ options }) => ({ options: { ...options, 'organizationId': organizationId }  }))
    }
  }, [sessionData])


  

  const onChangeLocal = useCallback((value, name) => {
    const nextformData = {} 
    setFormData(prevFormData => Object.assign(nextformData, prevFormData, { [name]: value }))
    onChange(nextformData)
  }, [onChange])
  const onChangeUser = useCallback(value => {
    onChangeLocal(value, 'city')
  }, [onChangeLocal])
  const onChangeDateRange = useCallback(value => {
    onChangeLocal(value, 'date-range')
  }, [onChangeLocal])
  const onChangeStageCode = useCallback(value => {
    onChangeLocal(value, 'region')
  }, [onChangeLocal])
  const onChangeRating = useCallback(value => {
    onChangeLocal(value.join('-'), 'rating')
  }, [onChangeLocal])

  const tipFormatter = useCallback(value => `${value}% рейтинг`, [])

  return (
    <Space>
      <SelectFromEnum
        value={formData['region']}
        schema={stage}
        onChange={onChangeStageCode}
        UISchema={UISchemaSelectFromEnum}
      />
      <SelectFromEnum
        value={formData['city']}
        schema={stage}
        onChange={onChangeUser}
        UISchema={UISchemaSelectFromEnum2}
      />
      <InputDateRange
        value={formData['date-range']}
        schema={dateRange}
        onChange={onChangeDateRange}
      />
      <div style={{ width: 200 }}>
        <Slider range defaultValue={[0, 100]} tipFormatter={tipFormatter} onAfterChange={onChangeRating} tooltipVisible />
      </div>
    </Space>
  )
}

export default Filter