import React, {FC, memo} from 'react';
import {Button, Typography} from "antd";
import {VStack} from "../../ui/Stack";

interface PageErrorProps {
    className?: string;
}

export const PageError: FC<PageErrorProps> = memo((props) => {
    const { className } = props;
    const reloadPage = () => window.location.reload();
    return (
        <VStack align={"center"} justify={"center"} gap={'16'} style={{height: '100vh'}} className={className}>
            <Typography.Text>Произошла непредвиденная ошибка!</Typography.Text>
            <Button type="primary" onClick={reloadPage}>
                Перезагрузить страницу
            </Button>
        </VStack>
    );
});
