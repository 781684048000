import styled from "styled-components";

export const FieldGroup = styled.div`
  display: flex;

  margin: 10px 0;
`

export const FieldName = styled.span`
  font-weight: bold;

  margin-right: 10px;
`;
