import {
    CALENDAR_LOADING,
    CALENDAR_DATA,
    CALENDAR_FAILED,
    CALENDAR_FILTER,
    CALENDAR_TYPES_DATA,
    CALENDAR_TYPES_LOADING,
    CALENDAR_TYPES_FAILED,
} from './actions'
import {ICalendarState} from "./interfaces";


const initialState: ICalendarState = {
    data: [],
    isLoading: false,
    error: '',
    filter: {},
    types: {
        data: {
            task_type: [],
            responsible: [],
            stages: []
        },
        error: '',
        isLoading: false
    }
}

export const calendar = (
    state = initialState,
    {type, payload},
) => {
    switch (type) {
        case CALENDAR_LOADING:
            return {
                ...state,
                isLoading: true,
                data: [],
                error: ''
            }
        case CALENDAR_DATA:
            return {
                ...state,
                isLoading: false,
                data: payload,
                error: ''
            }
        case CALENDAR_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case CALENDAR_FILTER:
            return {
                ...state,
                filter: payload
            }
        case CALENDAR_TYPES_LOADING:
            return {
                ...state,
                types: {
                    isLoading: true,
                    data: [],
                    error: ''
                },
            }
        case CALENDAR_TYPES_DATA:
            return {
                ...state,
                types: {
                    isLoading: false,
                    data: payload,
                    error: ''
                },
            }

        case CALENDAR_TYPES_FAILED:
            return {
                ...state,
                types: {
                    ...state.types,
                    isLoading: false,
                    error: payload
                },
            }
        default:
            return state
    }
}

