import React, { FC, useEffect, useState, useCallback, Fragment } from 'react';
import WhiteSpace from 'antd-mobile/lib/white-space';

import { FieldGroup } from './styles'
import Card from 'antd-mobile/lib/card';
import { INoteCardProps } from './interface'
import fetchAPI from '../../../../../lib/utils/fetch-api'
import { parseDate } from '../../../../utilits'
import { getDataOfType } from '../../../../../lib/utils/get-data-of-type'
import moment from 'moment';

export const NoteCard: FC<INoteCardProps> = props => {
  const { targetId, reRenderNotes, onDropReRender } = props
  const [noteList, setNoteList] = useState<Record<string, any>[]>([])

  useEffect(() => {
    const accumulateNotes = async () => {
      const noteResponse = await fetchAPI(`/api/collections/note/?parentId=${targetId}&access_key=axioma`)
      const noteList = getDataOfType(noteResponse, 'data.data', Array, [])
        .map(item => {
          const note = getDataOfType(item, 'data.attributes', Object, {})
          const date = moment(note['date']).valueOf()

          return {
            date,
            sort: date,
            id: item['id'],
            type: 'note',
            body:{
              content: note['content'],
              date: note['date'],
              author: note['author'],
            }
          }
        })
      .sort((a, b) => b['id'] - a['id'])

      if (noteList.length > 0) {
        setNoteList(noteList)
      }
      if (reRenderNotes && onDropReRender) {
        onDropReRender()
      }
    }

    if (targetId || reRenderNotes) {
      accumulateNotes()
    }
  }, [targetId, reRenderNotes, onDropReRender])

  const getNoteDate = useCallback((note: Record<string, any>) => {
    let result = ''
    const noteDate = getDataOfType(note, 'body.date', String, '')

    if (noteDate) {
      result = parseDate(noteDate)
    }

    return result
  }, [])

  const getNoteText = useCallback((note) => {
    return getDataOfType(note, 'body.content', String, '')
  }, [])

  return (
    <>
      {noteList.length > 0 && noteList.map(note => (
        <Fragment key={note['id']}>
          <Card>
            <Card.Header
              title="Заметка"
              extra={getNoteDate(note)}
            />
            <Card.Body>
              <FieldGroup>
                {getNoteText(note)}
              </FieldGroup>
            </Card.Body>
          </Card>
          <WhiteSpace size="lg" />
        </Fragment>
      ))}
    </>
  )
}
