import {Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo, useRef, useState} from "react";

type useAutoCloseModal = [boolean, Dispatch<SetStateAction<boolean>>];
export const useAutoCloseModal = (onCancel?: () => void): useAutoCloseModal => {
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const timerRef = useRef() as MutableRefObject<ReturnType<typeof setTimeout>>;
    useEffect(() => {
        if (isSuccess) {
            timerRef.current = setTimeout(() => {
                onCancel?.()
                setIsSuccess(false)
            }, 200)
        }
        return () => clearTimeout(timerRef.current)
    }, [isSuccess])
    return useMemo(() => [isSuccess, setIsSuccess], [isSuccess])
}
