import {
  SorterDirections,
  SorterDirectionsFull,
  TColumnSort,
  TUseSorter,
} from './interface'

export const useSorter: TUseSorter = (props) => {
  const {
    onSort,
  } = props

  const onColumnSort: TColumnSort = (key) => (a, b, direction) => {
    const dir = direction === SorterDirectionsFull.DESCEND
      ? SorterDirections.DESC
      : SorterDirections.ASC

    onSort(key, dir)

    return 0
  }

  return {
    onColumnSort,
  }
}
