import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { FolderAddOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

import { ButtonWrapper, ChildrenWrapper, GroupCreateWrapper, IconWrapper } from './styles'
import { IAdminGroupCreateButtonProps } from './interface'
import { AdminEditGroup } from '../../views/admin-edit-group'

export const AdminGroupCreateButton: FC<IAdminGroupCreateButtonProps> = (props) => {
  const { children, groups = [], group, onAfterSave, getUpdateGroupURL, getCreateGroupURL } = props
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [showButton, setShowButton] = useState(false)
  const [showGroupModal, setShowGroupModal] = useState(false)

  const handleWrapperHover = useCallback(() => {
    setShowButton(true)
  }, [])

  const handleWrapperLeave = useCallback(() => {
    setShowButton(false)
  }, [])

  const toggleGroupModal = useCallback((event) => {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }

    setShowGroupModal(prevState => !prevState)
  }, [])

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      const element = wrapperRef.current

      element.addEventListener('mouseenter', handleWrapperHover)
      element.addEventListener('mouseleave', handleWrapperLeave)

      return () => {
        if (element) {
          element.removeEventListener('mouseenter', handleWrapperHover)
          element.removeEventListener('mouseleave', handleWrapperLeave)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAfterGroupSave = useCallback(() => {
    if (onAfterSave && typeof onAfterSave === 'function') {
      onAfterSave()
    }
  }, [onAfterSave])

  return (
    <GroupCreateWrapper ref={wrapperRef}>
      <ChildrenWrapper>
        {children}
      </ChildrenWrapper>
      <AdminEditGroup
        createURL={getCreateGroupURL}
        updateURL={getUpdateGroupURL}
        showModal={showGroupModal}
        group={group}
        groups={groups}
        toggleModal={toggleGroupModal}
        onAfterSave={handleAfterGroupSave}
      >
        <ButtonWrapper show={showButton} onClick={toggleGroupModal}>
          <Tooltip title="Создать подгруппу">
            <IconWrapper>
              <FolderAddOutlined />
            </IconWrapper>
          </Tooltip>
        </ButtonWrapper>
      </AdminEditGroup>
    </GroupCreateWrapper>
  )
}
