import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Alert, Skeleton, Space, Modal, Button } from 'antd'
import { JSONSchema7 } from 'json-schema'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import StepCollectionObject from 'src/react-app/views/step-collection-object'
import EventRibbonTab from 'src/react-app/forms/case-event-ribbon-tab'
import { createUISchemaHorizontal, recalcUISchemaHorizontal } from 'src/lib/utils/grid-fild-orientation'
import ButtonAddNotes from 'src/react-app/views/modal-add-notes'
import { debounce } from 'lodash'
import {
    addActionsTimeLine,
    setCaseId,
    clearCaseStore,
    setAppealStage,
    initialCase,
    reloadActionsTimeLine,
    setTargetId,
    reloadTablePayment
} from 'src/react-app/store/appeals/actions'
import { addTask } from 'src/react-app/pages/card-order/utils'
import { modalAppointDrivert } from './modal-appoint-drivert'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import StepButtonsTransition from 'src/react-app/views/step-buttons-transition'
import { addValidateFieldsUISchema, accessUISchema, isFormDataDifferenceFilesS3 } from 'src/react-app/pages/card-order/utils'
import { isFormDataRequired } from 'src/lib/utils/collections'
import StuckFiles from 'src/react-app/components/tab-stuck-files'
import TableProduct from 'src/react-app/connectors/json-form-product-table/adaptor-tab'
import { isProductsSpcificationStage } from 'src/lib/utils/cards'
import { setStageFromCard } from 'src/lib/utils/cards'
import dayjs from 'dayjs'
import { modalAdjustedDate } from 'src/react-app/views/modal-adjusted-date'
import { insetNoteToCollection } from 'src/react-app/views/modal-add-notes/utils-notes'
import { getObjectFirst } from 'src/react-app/pages/card-order/utils'

 function genJSONSchemaStage(JSONSchema, requiredArr){
  const required = getDataOfType(JSONSchema, 'required', Array, []).concat(requiredArr)
  const requiredUnic = Array.from(new Set(required))
  return ({ ...JSONSchema, required: requiredUnic })
}

function genJSONSchemaNamesrequired(JSONSchema){
  return getDataOfType(JSONSchema, 'required', Array, [])
}

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  appealsCurrentStep => appealsCurrentStep,
  workflows => workflows,
  stageCode => stageCode,
  targetId => targetId,
  tablePayment => tablePayment,
)
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const breadcrumbs = [{
  href: '/logistic-delivery',
  title: 'Логистика',
}, {
  href: `/logistic-delivery`,
  title:  'Список доставок',
}]

const name = 'card-delivery'
const cardName = 'delivery'

export default function AdminNewObjectPage() {
  const { id } = useParams()  as any
  const [orderNumber, setOrderNumber] = useState<any>(null)
  const dispatch = useDispatch()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [selectedSchema, setSelectedSchema] = useState<string[]>([])
  const formDataRef = useRef<any>(null)
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<JSONSchema7 | null>(null)
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const { appealsCurrentStep, workflows, targetId, stageCode, tablePayment } = useSelector(createAppealsCurrentStep)
  const { sessionData } = useSelector(createSession)

  useEffect(() => {
    const currentStageAccess = getDataOfType(workflows, `${cardName}[${appealsCurrentStep}].access-rights`, Array, null)
    if(currentStageAccess === null){
      return
    }
    setUISchema(UISchema => recalcUISchemaHorizontal(accessUISchema(UISchema || {}, currentStageAccess, sessionData)))
  },[workflows, appealsCurrentStep, sessionData])


  const [currentStage, tableProductDisabled ] = useMemo(() => {
    if(appealsCurrentStep < 3){
      return [
        'manufacture',
        true
      ]
    }
    if(appealsCurrentStep === 3){
      return [
        'loading',
        false
      ]
    }
    if(appealsCurrentStep === 5){
      return [
        'shipment',
        false
      ]
    }
    return [
      'shipment',
      true
    ]
  }, [appealsCurrentStep])

  const custumTabs = useMemo(() => ({
    'stuck-products': {
      isVirtual: true,
      schema: {
        properties: {
          'stuck-products': {
            title: 'Данные заказа',
            description: null,
          }
        }
      },
      render: () => <TableProduct currentStage={currentStage} disabled={tableProductDisabled} id={id} />
    },
    'stuck-files': {
      isVirtual: true,
      schema: {
        properties: {
          'stuck-files': {
            title: 'Прикрепленные файлы',
            description: null,
          }
        }
      },
      render: () => <StuckFiles />
    },
    'event-ribbon': EventRibbonTab
  }), [id, currentStage, tableProductDisabled])


  const save = useCallback(async formData => {
    const requestBody = {
      data: {
        name: '',
        title: '',
        attributes: formData,
        metadata: { schema: name }
      }
    }
    const result = await fetchAPI(`/api/collections/-/objects/${id}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })
    const updated = getDataOfType(result, 'data.updated-at', [String, Number], null)
    if (updated === null) {
      console.warn('Ошибка сохранения записи')
    }
  }, [id])
  // eslint-disable-next-line
  const saveCase = useCallback(debounce(save, 1000), [])

  useEffect(() => {
    dispatch(initialCase({ targetId: null, cardName: cardName }))
    const result = setCaseId({ caseId: id, parentIds: [id] })
    dispatch(result)
    return () => { dispatch(clearCaseStore()) }
  }, [id, dispatch])

  const getData = useCallback(async () => {
    const fields = [
      'title',
      'description',
      'type',
      'properties',
      'required',
      'created_by',
      'created_at',
      'updated_at',
    ].join(',')
    const responseSchemas = await fetchAPI(`/api/schemas?access_key=axioma&fields=${fields}`)
    const fetchedSchemas: JSONSchema7[] = getDataOfType(responseSchemas, 'data.data', Array, [])
    const selectedSchemas = fetchedSchemas.filter(item => item['name'] === name)
    const selectedSchema = (typeof selectedSchemas[0] === 'object') ? selectedSchemas[0] : null
    if (selectedSchema === null) {
      setPageErrors(['Некорректный ответ сервера при cхемы коллекции'])
      return
    }
    const responseObject = await fetchAPI(`/api/collections/objects/${id}`)
    const object = responseObject['data']
    const schema = object['data']['metadata']['schema']
    const formData = object['data']['attributes']
    const parentId = Number(object['parent-id'])
    const stageCode = getDataOfType(object, 'stage-code', String, null)
    dispatch(setTargetId({ targetId: parentId }))
    setOrderNumber(object['parent-id'] || null)
    setSelectedSchema(schema)
    if(object['created-at']){
      const paramsDispatch = addActionsTimeLine({ type: 'info', items: [
        {
          date: object['created-at'],
          text: 'Создание записи'
        }
      ]})
      dispatch(paramsDispatch)
    }
    setPageErrors([])
    formDataRef.current = formData
    const stage = formData['stage'] || 0

    if( selectedSchema.required ) {
      if (stageCode === 'expect-shipment')
        selectedSchema.required = [...selectedSchema.required, 'is-client-confirmed-delivery']
      if (stageCode === 'delivered-client')
        selectedSchema.required = [...selectedSchema.required, 'file-surrender-client']
    }

    setMatchedJSONSchema(selectedSchema)
    const UISchema = createUISchemaHorizontal(selectedSchema)
    setUISchema(UISchema)
    const currentStageAccess = getDataOfType(workflows, `${cardName}[${stage}].access-rights`, Array, [])
    //setUISchema(UISchema => recalcUISchemaHorizontal(accessUISchema(UISchema || {}, currentStageAccess, sessionData)))

    setUISchema(UISchema => {
      const actualUISchema = accessUISchema(UISchema || {}, currentStageAccess, sessionData)
      if( actualUISchema['is-client-confirmed-delivery'] )
        actualUISchema['is-client-confirmed-delivery'].options.disabled = tablePayment?.sum - tablePayment?.paymentsSumAll > 0
      return recalcUISchemaHorizontal(actualUISchema)
    })

    dispatch(setAppealStage({ stageCode: stageCode, type: cardName, isHistory: false }))
    setIsDataLoading(!(tablePayment && tablePayment?.targetId && workflows))
  }, [dispatch, id, sessionData, workflows, tablePayment])

  useEffect(() => {
    if(sessionData === null || workflows === null){
      return
    }
      if(!tablePayment || !tablePayment?.targetId)
        dispatch(reloadTablePayment())
    getData()
  }, [getData, sessionData, workflows, tablePayment])

  const onFormData = useCallback(
    ({ formData }) => {
      const isFilesEdit = isFormDataDifferenceFilesS3(formDataRef.current, formData, matchedJSONSchema)
      if (isFilesEdit) {
        dispatch(reloadActionsTimeLine())
      }
      const nextFormData = { ...formData, stage: appealsCurrentStep }
      formDataRef.current = nextFormData
      saveCase(nextFormData)
    },
    [saveCase, appealsCurrentStep, matchedJSONSchema, dispatch])

  const transition = useCallback(async transition => {
    const stageCodeNext = transition['target']

    setMatchedJSONSchema(matchedJSONSchema => {
      if( matchedJSONSchema?.required ) {
        if (stageCode === 'expect-shipment')
          matchedJSONSchema.required.push('is-client-confirmed-delivery')
        if (stageCode === 'delivered-client')
          matchedJSONSchema.required.push('file-surrender-client')
      }
      return matchedJSONSchema
    })

    if (stageCodeNext === 'crew-assigned') {
      const resultFormData = await modalAppointDrivert()
      if (resultFormData === null) {
        return
      }
      const userId = getDataOfType(resultFormData, 'responsible', Number, null)
      const result = await setStageFromCard({
        cardName: cardName,
        stageCode: 'crew-assigned',
        targetId: targetId,
        isHistory: true,
        addFormData: {
         'driver-forwarder': userId,
         'date-carriage': getDataOfType(resultFormData, 'date-carriage', String, null),
         'time-carriage': getDataOfType(resultFormData, 'time-carriage', String, null),
       }
      })
      const nextFormData = getDataOfType(result, 'data.formData', Object, null)
      formDataRef.current = nextFormData
      const comment = getDataOfType(resultFormData, 'comment', Number, null)
      await addTask({
        'parent-id': targetId,
        responsible: [userId, 'me'],
        attributes: {
          stage: 'crew-assigned',
          status: 'work',
          comment: 'Экипаж. Доставить изделия клиенту. ' + comment,
        },
      })
      dispatch(reloadActionsTimeLine())
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      return
    }
    if (stageCodeNext === 'accepted-driver') {
      const isProducts = await isProductsSpcificationStage(targetId, 'loading')
      if (isProducts === false) {
        Modal.info({
          icon: null,
          title: 'Не передана продукция',
          content: 'Выберите продукцию для внесения в акт приема-передачи',
        })
        return
      }
      const result2 = await getObjectFirst({ collectionName: 'card-manufacture', parentId: targetId })
      const isNotDone =  ['new', 'work', 'expect-shipment', 'shipment-date-changed'].includes(getDataOfType(result2, 'stage-code', String, 'new'))
      if (isNotDone) {
        Modal.warning({
          title: 'Не удалось изменить стадию',
          content: 'Нет акта отгрузки с производства',
        })
        return
      }
      const result = await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: targetId,
        isHistory: true,
        addFormData: {
          'date-accepted-driver': new Date().toISOString()
        }
      })
      const nextFormData = getDataOfType(result, 'data.formData', Object, null)
      formDataRef.current = nextFormData
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
      return
    }
    if (stageCodeNext === 'delivered-client') {
      const result = await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: targetId,
        isHistory: true,
        addFormData: {
          'date-delivered': new Date().toISOString()
        }
      })
      const nextFormData = getDataOfType(result, 'data.formData', Object, null)
      formDataRef.current = nextFormData
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
      return
    }
    if (stageCodeNext === 'accepted-client') {
      const isProducts = await isProductsSpcificationStage(targetId, 'shipment')
      if (isProducts === false) {
        Modal.info({
          icon: null,
          title: 'Не передана продукция',
          content: 'Выберите продукцию для внесения в акт приема-передачи',
        })
        return
      }
      const result = await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: targetId,
        isHistory: true,
        addFormData: {
          'date-accepted-client': new Date().toISOString()
        }
      })
      const nextFormData = getDataOfType(result, 'data.formData', Object, null)
      formDataRef.current = nextFormData
      dispatch(setAppealStage({ stageCode: 'done', type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
      return
    }
    const result = await setStageFromCard({
      cardName: cardName,
      stageCode: stageCodeNext,
      targetId: targetId,
      isHistory: true,
    })
    const nextFormData = getDataOfType(result, 'data.formData', Object, null)
    formDataRef.current = nextFormData
    dispatch(reloadActionsTimeLine())
    dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName }))
}, [dispatch, targetId])

const transitionRrequired = useCallback((_, nextFields) => {
  const gen = genJSONSchemaStage(matchedJSONSchema, nextFields)
  const names = genJSONSchemaNamesrequired(gen)
  const isRequired = isFormDataRequired(formDataRef.current, gen)
  if(isRequired){
    return true
  }
  setUISchema(UISchema => addValidateFieldsUISchema(UISchema, names))
  return false
}, [matchedJSONSchema])

// crew-assigned

const isAadjusted = useMemo(() => {
  // const currentRoles = getDataOfType(sessionData, 'roles', Array, [])
  // const isAccountant = currentRoles.includes(100)
  const isStagePayment = ['crew-assigned'].includes(stageCode)
  return isStagePayment
}, [stageCode])

const onModalAdjustedDate = useCallback(async () => {
  const authorId = getDataOfType(sessionData, 'user.id', Number, 0)
  const result = await modalAdjustedDate()
  if (result === null) {
    return
  }
  const { date, comment, time } = result as any
  const content = `Изменена дата достаки на: ${dayjs(date).format('DD.MM.YYYY')}. ${comment ?? ''}`
  const nextDataCard = await fetchAPI('/api/web-hooks/set-card-form-data', { method: 'POST', body: JSON.stringify({
    'card-name': cardName,
    'target-id': targetId,
    'add-form-data': {
      'date-adjusted-delivery': date, 'time-adjusted-delivery': time
    }
  })})
  const nextFormData = getDataOfType(nextDataCard, 'data.fromData', Object, null)
  formDataRef.current = nextFormData
  await insetNoteToCollection({ date: new Date().toISOString(), content: content, author: authorId }, targetId)
  dispatch(reloadActionsTimeLine())
}, [sessionData, targetId, dispatch])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>Доставка №{orderNumber}</MapTitle>
          <Space size={'small'}>
            <ButtonAddNotes />
            {isAadjusted && <Button onClick={onModalAdjustedDate}>Изменить дату</Button> }
            <StepButtonsTransition type={cardName} transition={transition} transitionRequired={transitionRrequired} />
          </Space>
        </TaskWrapper>
        <MapContent>
          <StepCollectionObject type="delivery" />
          <Form
            {...formItemLayout}
            layout="horizontal"
            form={form}
            fields={[
              {
                name: 'schema',
                value: selectedSchema,
              },
            ]}
            scrollToFirstError={true}
          >
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {(isDataLoading === false && matchedJSONSchema) ? (
              <DefaultJsonForm
                formData={formDataRef.current}
                schema={matchedJSONSchema}
                onChange={onFormData}
                customTabs={custumTabs}
                UISchema={UISchema}
              />
            ) : (
              <Skeleton active />
            )}
          </Form>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
