import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Form, Skeleton } from 'antd'
import { useSelector } from 'react-redux'
import {
  getMontageFormData,
  getMontageFormLoading,
  getVisibleFormData, getMontageFormError,
} from '../../selectors'
import { useDebounce } from 'src/react-app/hooks/useDebounce/useDebounce'
import { HStack, VStack } from '../../../../../ui/Stack'
import { HSteps } from '../../../../../ui/Steps'
import { DefaultJsonForm } from '../../../../../components/v2/default-json-form'
import { MontageFormsHeader } from './montage-form-header/montage-form-header'
import { useSaveResult } from '../../hooks/useSaveResult'
import { useHistory } from 'react-router-dom'

interface MontageFormsProps {
  className?: string
  id: string | number,
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

export const MontageForms: React.FC<MontageFormsProps> = memo((props: MontageFormsProps) => {
  const { id } = props
  const [form] = Form.useForm()
  const montageFormData = useSelector(getMontageFormData)
  const montageFormIsLoading = useSelector(getMontageFormLoading)
  const montageFormError = useSelector(getMontageFormError)
  const [isDisabled, setIsDisabled] = useState<boolean>()
  const visibleFormData = useSelector(getVisibleFormData)
  const { saveResult } = useSaveResult()
  const history = useHistory()
  const isInitialRender = useRef(true)

  const saveCase = useCallback(async (is_next_stage, is_done = undefined) => {
    await saveResult(id, visibleFormData, montageFormData?.stages?.current, is_next_stage, is_done)
  }, [id, visibleFormData])

  useEffect(() => {
    const redirectUrl = montageFormData?.redirectUrl
    if (redirectUrl) {
      history.push(redirectUrl)
    }
  }, [montageFormData])

  useEffect(() => {
    if (visibleFormData) {
      isInitialRender.current = false
    }
  }, [visibleFormData])

  const debounceSaveCase = useDebounce(saveCase, 1000)

  const onChangeForm = useCallback(
    async ({formData}) => {
      if (isInitialRender.current) {
        return
      }
      if (!visibleFormData) {
        return
      }
      const isFilesEdit = JSON.stringify(formData) === JSON.stringify(visibleFormData?.resultObject)
      if (isFilesEdit) {
        return
      }
      visibleFormData.resultObject = {...formData}
      debounceSaveCase()
    }, [visibleFormData, debounceSaveCase]
  )

  const handleToOrder = useCallback(async () => {
    setIsDisabled(true)
    await saveCase(true)
    setIsDisabled(false)
  }, [isDisabled, saveCase])

  const handleToFinish = useCallback(async () => {
    setIsDisabled(true)
    await saveCase(true, true)
    setIsDisabled(false)
  }, [saveCase])

  const setCaseStage = useCallback(async data => {
    await saveResult(id, data, montageFormData?.stages?.current, true, false)
  },[id, montageFormData])

  const handleToNotAccepted = useCallback(async data => {
    const formData = visibleFormData && visibleFormData?.resultObject
    setIsDisabled(true)
    await setCaseStage({ resultObject: { ...formData, ...data, to_stage: 'not_accepted' } })
    setIsDisabled(false)
  }, [visibleFormData])

  if (montageFormError && !montageFormIsLoading && !montageFormData) {
    return (
      <HStack gap={'8'} className={'mapWrapper'}>
        При выполнении запроса возникла ошибка.
        Попробуйте перезагрузить страницу или открыть карточку повторно.
      </HStack>
    )
  }

  return (
    <>
      <MontageFormsHeader
        id={id}
        isFinished={montageFormData?.stages && montageFormData?.stages?.current === Object.keys(montageFormData?.stages?.items).length - 1}
        stage={montageFormData?.stages?.current}
        toOrder={handleToOrder}
        toFinish={handleToFinish}
        toNotAccepted={handleToNotAccepted}
      />
      <HStack gap={"8"} className={'mapContent'} max>
        <Form
          {...formItemLayout}
          layout='horizontal'
          form={form}
          scrollToFirstError={true}
          style={{width: '100%'}}
        >
          {montageFormError && !montageFormIsLoading ? (
            <div className={'alertWrapper'}>
              <Alert
                message='При выполнении операции возникла ошибка:'
                showIcon
                type='error'
                description={montageFormError}
              />
            </div>
          ) : null}
          {!montageFormIsLoading ? (
            <VStack gap={'16'} max align={'stretch'} className={'appealsForm'}>
              {montageFormData?.stages && (
                <HSteps
                  items={Object.values(montageFormData?.stages?.items)}
                  current={montageFormData?.stages?.current}
                  history={montageFormData?.stages?.history}
                />)
              }
              {
                visibleFormData && <DefaultJsonForm
                  formData={{...visibleFormData.resultObject}}
                  schema={visibleFormData.resultSchema}
                  onChange={onChangeForm}
                  orientation='horizontal'
                />
              }
            </VStack>
          ) : (
            <Skeleton active/>
          )}
        </Form>
      </HStack>
    </>
  )
})
