import React, { useEffect, useState, useMemo } from 'react'
import fetchAPI from 'src/lib/utils/fetch-api'
import { Select } from 'antd'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { ISelect } from './interface' 

function SelectFrom({ value, schema, UISchema, onChange }: ISelect) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const allowClear = useMemo(() => Boolean(UISchema?.options?.allowClear), [UISchema])
  const [objects, setObjects] = useState<any[]>([{ id: value, value }])
  const filter = useMemo(() => {
    const organizationId = UISchema?.options?.organizationId
    if(organizationId) {
      return `type=organization&organization-id=${organizationId}`
    }
    return ''
  }, [UISchema])

  useEffect(() => {
    (async () => {
      const objectsResult: any = await fetchAPI(`/api/profiles?${filter}`)
      const objectsFormat = objectsResult?.['data']?.[0]?.['profile-data']?.['requisite']?.filter?.(item => item?.['active'])
        ?.map?.((item) => {
          const label = [item?.['inn'], item?.['name']].filter(Boolean).join(', ')
          return { value: item?.['inn'], label: label }
      })
      setObjects(objectsFormat)
    })()
  }, [filter, UISchema])

  const localValue = useMemo(() => {
    return value || undefined
  }, [value])

  return (
    <Select
      placeholder="ИНН"
      allowClear={allowClear}
      optionFilterProp="children"
      onChange={onChange}
      value={localValue}
      options={objects}
      disabled={disabled}
    />
  )
}

export default SelectFrom

