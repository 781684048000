import { IAction, IErrors } from '../interfaces'
import { GET_MENU_ERROR, GET_MENU_REQUEST, GET_MENU_SUCCESS } from './actions'
import { IMenuItem, IMenuState } from './interfaces'

const initialState: IMenuState = {
  isLoading: false,
  items: [],
  error: null,
}

export const menuReducer = (
  state = initialState,
  { type, payload }: IAction<IMenuItem[] | IErrors>,
) => {
  switch (type) {
    case GET_MENU_REQUEST:
      return {
        ...state,
        isLoading: true,
        items: [],
      }
    case GET_MENU_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: payload as IMenuItem[],
      }
    case GET_MENU_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload as IErrors
      }
    default:
      return {
        ...state,
      }
  }
}
