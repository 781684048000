import styled from "styled-components";

export const PhoneWrapper = styled.div`
  max-height: 20px;
  max-width: 20px;

  svg {
    height: 100%;
  }
`
