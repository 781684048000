import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { reloadActionsTimeLine, caseDeleteFile, caseEditNote } from 'src/react-app/store/appeals/actions'
import ActivityTimeLine from 'src/react-app/components/activity-timeline'

const createActionsTimeLine = createSelector(
  (state: any) => state.appeals,
  appealsActionsTimeLine => appealsActionsTimeLine,
  users => users,
  taskStages => taskStages
)

function ActivityTimeLineConnector() {
  const { appealsActionsTimeLine, users, taskStages } = useSelector(createActionsTimeLine)
  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      await dispatch(reloadActionsTimeLine())
    })()
  }, [dispatch])

  const actions = useMemo(() => ({
    deleteFile: props => dispatch(caseDeleteFile(props)),
    editNote: props => dispatch(caseEditNote(props))
  }), [dispatch])

  const data = useMemo(() => ({
    actionsItems: appealsActionsTimeLine,
    usersProfile: users,
    taskStages: taskStages
  }), [appealsActionsTimeLine, users, taskStages])

  return <ActivityTimeLine actions={actions} data={data}   />
}

export default ActivityTimeLineConnector

