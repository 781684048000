import React, { useCallback, ReactElement, FC } from 'react';
import Modal from 'antd-mobile/lib/modal';
import List from 'antd-mobile/lib/list';

import FiileS3 from '../../../../connectors/file-s3-json-form'
import { IAdditionalMenuProps } from './interface'

export const AdditionalMenu: FC<IAdditionalMenuProps> = (props) => {
  const { show = false, onClose = () => {}, updateData, measurementsData, data } = props

  const uploadDocument = useCallback((field: string) => async (fileId: number) => {
    await updateData({
      [field]: fileId,
    })
  }, [updateData])

  const getSubSubMenuComponentHandler = useCallback((code: string) => {
    return {
      'file-s3': uploadDocument(code),
    }
  }, [uploadDocument])

  const SubSubMenuComponent = useCallback(() => {
    const { format, code } = data
    let result: ReactElement | null = null
    const value = measurementsData[code] || ''

    switch (format) {
      case 'file-s3':
        result = <FiileS3 value={value} onChange={getSubSubMenuComponentHandler(code)[format]} />
        break
      default:
        break
    }

    return result
  }, [data, getSubSubMenuComponentHandler, measurementsData])

  return (
    <Modal
      transparent
      visible={show}
      onClose={onClose}
      animationType="slide-up"
      footer={[
        {
          text: 'Закрыть',
          onPress: onClose,
        },
      ]}
    >
      <List renderHeader={() => <div>Дополнительное меню</div>}>
        <List.Item>
          <SubSubMenuComponent />
        </List.Item>
      </List>
    </Modal>
  )
}
