import React, { useCallback, useEffect } from 'react'

export const CalculatorPage = () => {
  const loadScript = useCallback(() => {
    const scriptUrl = 'https://calculator.ze-okna.axept.com/dev-bundle.js'
    const script = document.createElement('script')

    script.setAttribute('src', scriptUrl)
    document.body.appendChild(script)
  }, [])

  useEffect(() => {
    loadScript()
  }, [loadScript])

  return (
    <div id="calculator"/>
  )
}
