import PhoneInput from 'react-phone-input-2'
import {Button} from 'antd'
import {SearchOutlined} from '@ant-design/icons'
import React, {useCallback, useEffect, useState} from 'react'
import Search from 'antd/es/input/Search'
import ru from 'react-phone-input-2/lang/ru.json'


/**
 * Принимает и возвращает только положительные целочисленные значения, напр. ID
 *
 * @param defaultValue
 * @param onSearch
 * @param onChange
 * @constructor
 */
export const InputInteger = ({defaultValue, onSearch, onChange}) => {
  const [localValue, setLocalValue] = useState(defaultValue || null)

  const handlerChange = useCallback(e => {
    const {value: inputValue} = e.target
    const reg = /^\d*$/
    if (reg.test(inputValue)) {
      const realValue = inputValue.length ? +inputValue : undefined
      setLocalValue(realValue)
      onChange(realValue)
    }
  }, [onChange, localValue, setLocalValue])

  return (
    <Search
      value={localValue}
      style={{width: 170}}
      prefix={'ID'}
      allowClear
      onChange={handlerChange}
      onSearch={onSearch}
      maxLength={10}
    />
  )
}

/**
 * Возвращает только валидные номера телефонов
 *
 * @param defaultValue
 * @param onChange
 * @constructor
 */
export const InputPhone = ({defaultValue, onChange}) => {
  const [localValue, setLocalValue] = useState(defaultValue || null)
  const [length, setLength] = useState()

  const changePhone = useCallback(val => {
    if(localValue.length === length)
      onChange(localValue)
  }, [onChange, localValue])

  const handlerChange = useCallback((v, c) => {
    setLength(c.format.match(/\./g).length)
    setLocalValue(v)
  }, [setLocalValue, localValue])

  const clearLocal = useCallback(() => {
    setLocalValue('7')
    onChange(undefined)
  }, [setLocalValue])

  return (
    <div style={{display: 'flex', width: 'max-content', position: 'relative'}}>
      <PhoneInput
        inputStyle={{height: 32, borderRadius: 2, border: '1px solid #d9d9d9', width: 200}}
        value={localValue}
        dropdownStyle={{}}
        enableSearch={true}
        searchPlaceholder={'Поиск'}
        localization={ru}
        country={'ru'}
        onlyCountries={['ru', 'kz', 'by', 'tj', 'uz', 'am', 'kg', 'az', 'md', 'tm']}
        onEnterKeyPress={changePhone}
        onChange={(v, c) => handlerChange(v, c)}
      />
      {localValue && localValue >= 10 && (
        <span className="ant-input-suffix" style={{height: '100%', right: '38px', zIndex: 10, position: "absolute"}}
              onClick={clearLocal}>
          <span className="ant-input-clear-icon" role="button">
            <span role="img" aria-label="close-circle" className="anticon anticon-close-circle">
              <svg viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em"
                   fill="currentColor" aria-hidden="true">
                <path
                  d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"/>
              </svg>
            </span>
          </span>
        </span>
      )}
      <Button icon={<SearchOutlined />} onClick={changePhone} style={{marginLeft: '-5px'}}/>
    </div>)
}
