import React, { useCallback, useEffect, useState } from 'react'
import fetchAPI from 'src/lib/utils/fetch-api'
//import { CascaderValueType } from 'antd/lib/cascader'

import { ICascaderOption, TAdminCollectionItemConnect, TAdminCollectionItemConnector } from './interface'

export const AdminCollectionItemConnector: TAdminCollectionItemConnector = props => {
  const {
    title,
    isEdit= false,
    form,
    formData,
    setFormData,
    pageErrors,
    initSelectedSchemaForCascader,
    onFinish,
    idFieldExtra,
  } = props
  const [isSchemasLoading, setIsSchemasLoading] = useState<boolean>(false)
  const [schemaForCascader, setSchemaForCascader] = useState<ICascaderOption[]>([])
  const [selectedSchemaForCascader, setSelectedSchemaForCascader] = useState<any[]>([])
  const [allSchemas, setAllSchemas] = useState<any[]>([])
  const [schemaData, setSchemaData] = useState<any[]>([])

  useEffect(() => {
    if (
      initSelectedSchemaForCascader && initSelectedSchemaForCascader.length > 0 &&
      allSchemas.length > 0
    ) {
      const resultSchema: any[] = []

      initSelectedSchemaForCascader.forEach(initSchema => {
        if (initSchema) {
          const schema = allSchemas.find(schemaData => schemaData.name === initSchema[0])
          resultSchema.push(schema)
        }
      })

      setSelectedSchemaForCascader(initSelectedSchemaForCascader)
      setSchemaData(resultSchema)
    }
  }, [allSchemas, initSelectedSchemaForCascader])

  const updateSchemaData = useCallback((schemaList, cascaderType, index) => {
    const formFieldsValue = form.getFieldsValue()
    const currentSchema = schemaList.find(item => item.name === cascaderType[0])
    const newSchema = [
      ...formData.schema.slice(0, index),
      cascaderType,
      ...formData.schema.slice(index + 1, formData.schema.length),
    ]

    setSelectedSchemaForCascader(newSchema)
    setSchemaData(prevState => [
        ...prevState.slice(0, index),
        currentSchema,
        ...prevState.slice(index + 1, prevState.length)
      ]
    )
    setFormData(prevState => ({
      ...prevState,
      schema: newSchema,
    }))
    form.setFieldsValue({
      ...formFieldsValue,
      schema: newSchema,
    })
  }, [form, formData.schema, setFormData])

  /** Select option */
  const createHandleSelectSchema = useCallback(index => cascaderType => {
    updateSchemaData(allSchemas, cascaderType, index)
  }, [allSchemas, updateSchemaData])

  /** Fetching schema data */
  const gettingSchemaData = useCallback((selectData?: any, index?: number) => {
    fetchAPI('/api/schemas?access_key=axioma&fields=title,metadata,properties,description,type,required')
      .then(response => {
        setIsSchemasLoading(false)
        const schemas = (response['data'] && response['data']['data']) || []
        const newSchemasForCascader: ICascaderOption[] = []
        schemas.forEach(item => {
          newSchemasForCascader.push({
            value: item['name'],
            label: item['title'],
          })
        })
        setAllSchemas(schemas)
        setSchemaForCascader(newSchemasForCascader)
        if (selectData) {
          updateSchemaData(schemas, [selectData['name']], index)
        }
      })
      .catch(err => {
        setIsSchemasLoading(false)
      })
  }, [updateSchemaData])

  /** Getting schema data after creating schema */
  const handleSchemaCreateOnSave = useCallback((data, index) => {
    gettingSchemaData(data, index)
  }, [gettingSchemaData])

  const handleGetCollectionTitle = useCallback(() => formData.title, [formData])
  const handleGetCollectionDescription = useCallback(() => formData.description, [formData])

  // @todo Extract to Redux Store to avoid double call
  useEffect(() => {
    setIsSchemasLoading(true)
    gettingSchemaData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinishFailed = errorInfo => {
    console.error('AdminNewCollectionPage: form failed', errorInfo)
  }

  const handleFormChange = useCallback((event) => {
    const key = event.target.id
    const value = event.target.value

    setFormData(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }, [setFormData])

  const connect: TAdminCollectionItemConnect = Component => (
    <Component
      {...props}
      isEdit={isEdit}
      pageErrors={pageErrors}
      title={title}
      form={form}
      createHandleSelectSchema={createHandleSelectSchema}
      idFieldExtra={idFieldExtra}
      isSchemasLoading={isSchemasLoading}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onFormChange={handleFormChange}
      schemaForCascader={schemaForCascader}
      onGetCollectionTitle={handleGetCollectionTitle}
      onGetCollectionDescription={handleGetCollectionDescription}
      onSchemaCreateOnSave={handleSchemaCreateOnSave}
      schemaData={schemaData}
      selectedSchemaForCascader={selectedSchemaForCascader}
    />
  )

  return connect
}
