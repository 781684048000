import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import {InputNumber, Spin} from "antd";

const createCase = createSelector(
    (state: any) => state.appeals,
    targetId => targetId,
    tablePayment => tablePayment
)

function formatCurrency(value){
  return (value || 0).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', currencyDisplay: 'symbol' }).replace("₽", "");
}

function ContractDebt() {
  const { targetId, tablePayment } = useSelector(createCase)
  const [localValue, setLocalValue] = useState<any>(0)

  useEffect(() => {
      const paymentsSumAll = tablePayment?.paymentsSumAll
      const sum = tablePayment?.sum
      setLocalValue( formatCurrency((sum - paymentsSumAll)) )
  }, [targetId, tablePayment])

  return <InputNumber value={localValue} disabled={true} />
}

export default React.memo(ContractDebt, () => {
    return true
})
