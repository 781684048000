import styled from 'styled-components'

export const TableWrapper = styled.div`
    tr {
        cursor: pointer;
    }
    .ant-table-body {
        overflow: auto !important;
    }
    .ant-table-body .ant-table-row:hover td,
    .ant-table-column-sort{
        background-color: unset;
    }

    .table-row-new td:not([class*='ant-table-cell-fix-']) {
        background-color: #AFE4AF;
    }
    .table-row-new:hover td:not([class*='ant-table-cell-fix-']) {
        background-color: #C3EDC3;
    }

    .table-row-expired td:not([class*='ant-table-cell-fix-']) {
        background-color: #F49191;
    }
    .table-row-expired:hover td:not([class*='ant-table-cell-fix-']) {
        background-color: #F7B8B8;
    }

    .table-row-done td:not([class*='ant-table-cell-fix-']) {
        background-color: #B5DEFF;
    }
    .table-row-done:hover td:not([class*='ant-table-cell-fix-']) {
        background-color: #C9E7FF;
    }
    td[class*='ant-table-cell-fix'] {
        background-color: #FFFFFF!important;
    }

    .attrs {
        margin: 0;
        overflow: hidden;
    }

    .attrs dt {
        margin: 0;
        width: 120px;
        float: left;
        clear: both;
        color: #808d9a;
    }

    .attrs dd {
        margin: 0 0 0 125px;
    }
`