import fetchAPI from '../../../../lib/utils/fetch-api'
import {ISaveBrandRequest} from './types'

export const saveBrand = async (formData: ISaveBrandRequest) => {
  try {
    const response = await fetchAPI(
      `/api/add-brand`,
      { method: 'POST', body: JSON.stringify(formData) }
    )
    if (response['errors']) {
      let error = response['errors']
      if (Array.isArray(error)) {
        error = error.join()
      }
      throw new Error(error)
    }
    return response
  } catch (err) {
    console.error(err)
    return false
  }
}
