import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'

export async function getTaskStages() {
  const name = 'task-stages'
  try {
    const response = await fetchAPI(`/api/collections/${name}/?access_key=axioma`)
    const stages = response['data']['data']
    const stagesFormat = stages
      .map(item => {
        const task = {
          id: item['id'],
          name: item['name'],
          title: getDataOfType(item, 'data.attributes.title', String, ''),
          sort: getDataOfType(item, 'data.attributes.sort', String, ''),
        }
        return task
      })
      .sort((a, b) => a['sort'] - b['sort'])

    return stagesFormat
  } catch (err) {
    console.warn(err)
    return []
  }
}

export async function getTaskStageId(name) {
  const stages = await getTaskStages()
  const stageTarget = stages.find( item => item.name === name)
  return getDataOfType(stageTarget, 'id', [Number, String], null)
} 