import {createSelector} from "reselect";
import {ActivityItems} from "../types/types";

const selectTimeline = state => state.timeline

export const selectTimelineData = createSelector(
    selectTimeline,
    ({data}) => data
)

export const selectTimelineFiles = createSelector(
    selectTimelineData,
    ({items}: { items: ActivityItems[] }) => {
        if (!items || items?.length === 0) {
            return []
        }

        return items?.map(activityItems => {
            const files = activityItems?.items?.filter(activityItem => activityItem.type === 'file')
            return files?.length ? {...activityItems, items: files} : undefined
        }).filter(_ => Boolean(_)) as ActivityItems[]
    }
)

export const selectTimelineIsLoading = createSelector(
    selectTimeline,
    ({isLoading}) => isLoading
)

export const selectTimelineError = createSelector(
    selectTimeline,
    ({error}) => error
)