import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Form, Alert, Skeleton, Modal } from 'antd'
import { MapTitle, AlertWrapper, TaskWrapper } from './styles'
import fetchAPI from 'src/lib/utils/fetch-api'
import { recalcUISchemaHorizontal } from 'src/lib/utils/grid-fild-orientation'
import { isFormDataRequired } from 'src/lib/utils/collections'
import userFieldsSchemaAdd from './json.json'
import { DefaultJsonForm } from '../../../../components/v2/default-json-form'
import './style.css'
import { objectToUrl, renameKeys, setEnumFields } from '../../../utils'


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}


export default function AdminNewObjectPage({ onChange, officeId, organizationId, users_count, data }) {
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const refData = useRef<any>({})
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [profileTypeId, setProfileTypeId] = useState<any>(null)
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [organizationData, setOrganizationData] = useState<any>({})

  const saveCase = useCallback(async (event) => {
    const isRequired = isFormDataRequired(formDataRef.current, matchedJSONSchema)
    if (!isRequired) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Заполните все обязательные поля'
      })
      return false
    }

    const formData = { ...formDataRef.current, office_id: Number(officeId), organization_id: Number(organizationId) }

    await fetchAPI(`/api/users_new/add`, {
      method: 'POST',
      body: JSON.stringify(formData)
    })
    onChange({ target: refData.current})
    return true
  }, [onChange, profileTypeId, matchedJSONSchema, officeId, organizationId])

  useEffect(() => {
    data.save = () => {
      return saveCase({})
    }
  }, [saveCase, data])

  const getData = useCallback(async () => {
    formDataRef.current = { mango_extension: `${organizationId}${officeId}${users_count}` }
    const enumsList = { types: ['case_city', 'case_region'] }
    const enums = await fetchAPI(`/api/enum-list?${objectToUrl(enumsList)}`)
    const fixKeys = renameKeys(enums, {
      case_region: 'regions',
      case_city: 'city_id',
    })
    setEnumFields(fixKeys, userFieldsSchemaAdd)
    setMatchedJSONSchema(userFieldsSchemaAdd)
    setUISchema(recalcUISchemaHorizontal(userFieldsSchemaAdd))

    /*{
      const result = await fetchAPI(`/api/profiles-types?type=user`)
      const profilesTypes = getDataOfType(result, 'data', Array, [])
      setProfilesType(profilesTypes)
      const defaultProfileId = getDataOfType(profilesTypes, '[0].id', [String, Number], null)
      const defaultSelectedSchema = getDataOfType(profilesTypes, '[0].profile-schema', Object, null)
      const organizationData = await fetchAPI(`/api/organizations-from-user/${id}`)
      setOrganizationData(getDataOfType(organizationData, 'data', Object, {}))

      {
        const result = await fetchAPI(`/api/profiles?type=user&user-id=${id}`)
        const data = getDataOfType(result, 'data', Array, [])       
        const profileData = getDataOfType(data, '[0].profile-data', Object, {})
        const profileTypeId = Number(getDataOfType(data, '[0].profile-type', [String, Number], 1))
        const selectedSchema2 = getDataOfType(data, '[0].profile-schema', Object, defaultSelectedSchema)
        Object.assign(selectedSchema2)

        formDataRef.current = { ...profileData, mango_extension: `${organizationId}${officeId}${users_count}` }
        setProfileTypeId(profileTypeId)
        setUISchema(recalcUISchemaHorizontal(userFieldsSchemaAdd))
      }
    }
    {
      const result = await fetchAPI(`/api/roles-user/${id}`)
      const data = getDataOfType(result, 'data', Array, [])
      rolesRef.current = data
      setInitRoles(data)
    }
    {
      const result = await fetchAPI(`/api/roles?filter[user-id]=${id}`)
      const data = getDataOfType(result, 'data', Array, null)
      setRoles(data)
    }
    */
    setPageErrors([])
    setIsDataLoading(false)
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const onFormData = useCallback(
    ({ formData }) => {
      formDataRef.current = formData
    }, []
  )

  return (
    <div ref={refData}>
      <TaskWrapper>
        <MapTitle>
          {Boolean(organizationData.title) && <><span>{`(${organizationData.title},`}</span>
          <span>{`${organizationData['type-title']})`}</span></>}
        </MapTitle>
      </TaskWrapper>
        <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}>
          {pageErrors.length > 0 ? (
            <AlertWrapper>
              <Alert
                message="При выполнении операции возникла ошибка:"
                showIcon
                type="error"
                description={pageErrors.join('. ')}
              />
            </AlertWrapper>
          ) : null}
          {isDataLoading === false ? (
            UISchema && (
              <DefaultJsonForm
                formData={formDataRef.current}
                schema={matchedJSONSchema}
                onChange={onFormData}
              />
            )
          ) : (
            <Skeleton active />
          )}
        </Form>
    </div>
  )
}
