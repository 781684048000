import React, { useCallback, useMemo } from 'react'
import { TimePicker } from 'antd'
import moment from 'moment'
import { ConfigProvider } from 'antd'
import locale from 'antd/es/locale/ru_RU'
import { ITime } from './interface'

function range(start, end): number[] {
  return Array
          .from({ length: end - start })
          .map((_, index) => start + index)
}

function InputTime({ value, onChange, UISchema }: ITime) {

  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
   const isDisabledTimePrev = useMemo(() => {
    console.log(UISchema, 'UISchema')
    return UISchema?.options?.isDisabledTimePrev === true
  }, [UISchema])

  const disabledTime = useMemo(() => {
    if(isDisabledTimePrev){
      return {
        disabledHours: () => {
          const currentHours = new Date().getHours()
          return range(0, currentHours)
        },
        disabledMinutes: seletedHours => {
          const currentHours = new Date().getHours()
          const currentMinutes = new Date().getMinutes()
          if(currentHours === seletedHours){
            return range(0, currentMinutes)
          }
          if(seletedHours === -1){
            return range(0, 60)
          }
          return range(60, 60)
        } 
      }
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => []
    }
  } ,[isDisabledTimePrev]) 

  const handleChange = useCallback(nextValue => onChange(nextValue && nextValue.toISOString()), [
    onChange,
  ])
  // @ts-ignore
  return (
    <ConfigProvider locale={locale}>
      <TimePicker
        disabledHours={disabledTime.disabledHours}
        disabledMinutes={disabledTime.disabledMinutes}
        format="HH:mm"
        onChange={handleChange}
        value={value && moment(value)}
        disabled={disabled}
      />
    </ConfigProvider>
  )
}

export default InputTime