import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Table, Alert, Button, Pagination } from 'antd'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import B2BLayout from 'src/react-app/layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import { afterDataColumns, beforeDataColumns } from 'src/react-app/components/list-operations/consts'
import { getStagesGroup } from 'src/react-app/pages/list-orders/utils'
import { calcTotalWidth, tableRowClick, getColumnsOfSchema, fetchSchema, listColumnsHidden, formatListData } from 'src/lib/utils/list'
import { CheckOutlined } from '@ant-design/icons'
import { objectUpdate } from 'src/react-app/pages/card-order/utils'
import { PaginationWrapper, ButtonWrapper } from './styles'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import OrderFilter from './orders-filter'
import { formDataToUrlParams } from 'src/react-app/pages/list-orders/utils'
import './index.css'

const createViewed = createSelector(
  (state: any) => state.viewed,
  counts => counts
)

const name = 'card-manufacture'

export default function AdminObjectsListPage() {
  const location = useLocation()
  const history = useHistory()
  const orderFiltersRef = useRef<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [dataColumns, setDataColumns] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [page, setPage] = useState<number>(1)
  const { counts } = useSelector(createViewed)
  const breadcrumbs = useMemo(() => (
    [{
      href: location.pathname,
      title: 'Производство',
    }, {
      href: location.pathname,
      title: 'Список заказов на изготовление',
    }]
  ), [location.pathname])
  const totalWidth: any = useMemo(() => calcTotalWidth(beforeDataColumns, dataColumns, afterDataColumns), [dataColumns])

  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(beforeDataColumns, dataColumns, afterDataColumns).filter(item => item.title), [dataColumns])
  const cbTableRowClick = useCallback(({ id }) => history.push(`/manufacture/${id}/edit`), [history])


  const deleteRecord = useCallback(async (currentId) => {
    try{
      await fetchAPI(`/api/collections/objects/${currentId}`, { method: 'DELETE'})
      setDataSource(dataSource => dataSource.filter(({ id }) => id !== currentId))
    }catch(error){
      console.error(error)
    }
  },[])

  const getData = useCallback(async (value, addUrlParams?: string) => {
    const urlParams = formDataToUrlParams(value)
    const pageErrors: string[] = []
    const [selectedSchema, metadata, collections, stages] = await Promise.all([
      fetchSchema({ name }),
      fetchAPI(`/api/collections/${name}/metadata`),
      fetchAPI(`/api/collections-case/${name}?access_key=axioma&${urlParams}&${addUrlParams}`),
      getStagesGroup('manufacture')
    ])

    const columnsHidden = getDataOfType(metadata, 'data.columnsHidden', Array, [])
    const collectionsResultData = getDataOfType(collections, 'data.data', Array, [])

    const isMetadata = getDataOfType(metadata, 'data', Object, null)
    if(isMetadata === null) {
      pageErrors.push('Некорректный ответ сервера при получении метаданных коллекции')
    }
    const dataSource: any[] = formatListData(collectionsResultData, selectedSchema)
    const newDataSource = dataSource.map(item => ({
      ...item,
      deleteRecord,
      stages,
    }))
    const preparedDataColumns: any[] = getColumnsOfSchema(selectedSchema)
    const datalistColumnsHidden = listColumnsHidden(preparedDataColumns, columnsHidden)
    const newDataColumns = datalistColumnsHidden.map(column => ({
        ...column,
        width: 120,
        /*render: (text, { id, schema }) => (
        <EditCard id={id} schema={schema} title="Редактирование">
         {FieldHideText({ text })}
        </EditCard>
        )*/}
    ))
    const totalRows = getDataOfType(collections, 'data.total-rows', Number, 0)
    setTotalRows(totalRows)
    setDataColumns(newDataColumns)
    setDataSource(newDataSource)
    setPageErrors(pageErrors)
    setIsDataLoading(false)
  }, [deleteRecord])

  const onPagination = useCallback(async ( page, pageSize) => {
    setPage(page)
    const urlParams = `page=${page}&pageSize=${pageSize}`
    setIsDataLoading(true)
    await getData(orderFiltersRef.current, urlParams)
    setIsDataLoading(false)
  }, [getData])

  useEffect(() => {
    page === 1 && getData('','')
  }, [counts, page, getData])


  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const inSelected = useMemo(() => (Boolean(selectedRowKeys.length) === false), [selectedRowKeys])
  const onSelect = useCallback(({ id }, selected) => setSelectedRowKeys((keys: any) => selected
    ? keys.concat(id)
    : keys.filter(key => key !== id)
  ), [])
  const renderCell = useCallback(
    (_, { stage }, index, originNode) => stage === 0 ? originNode : null,
    []
  )
  const rowSelection = useMemo(() => ({
    selectedRowKeys,
    hideSelectAll: true,
    onSelect: onSelect,
    renderCell
  }), [selectedRowKeys, renderCell, onSelect])
  const handleBox = useCallback(async () => {
    setSelectedRowKeys([])
    await Promise.all(selectedRowKeys.map(id => {
      return objectUpdate({ id, formData: { stage: 1 } })
    }))
    setDataSource(data => data.map(item => {
      if(selectedRowKeys.includes(item['id'])){
        return { ...item, stage: 1 }
      }
      return item
    }))
  },[selectedRowKeys])

  const rowClassName = useCallback(record => {
    return record['stage'] === 0 ? 'table-row-dark' :  'table-row-light'
  }, [])

  const onOrderFilter = useCallback(value => {
    Object.assign(orderFiltersRef.current, value)
    getData(value)
  }, [getData])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {(pageErrors.length > 0) ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
            />
        </div>
      ): null}
      <ButtonWrapper>
        <Button onClick={handleBox} disabled={inSelected}>
          <CheckOutlined />
          Подтвердить выбранные
        </Button>
        <OrderFilter onChange={onOrderFilter} />
      </ButtonWrapper>
      <Table
        rowKey="id"
        rowSelection={rowSelection}
        rowClassName={rowClassName}
        columns={finalColumns}
        size="small"
        dataSource={dataSource}
        scroll={{ x: totalWidth, y: '72vh' }}
        pagination={false}
        loading={isDataLoading}
        onHeaderRow={(column: any) => ({
          style: {
            fontSize: (column.key && column.key.substr(-3) === '-at') ? 9 : 13,
          }
        })}
        onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick)})}
        />
      {Boolean(totalRows) && <PaginationWrapper>
        <Pagination defaultCurrent={1} pageSize={10} total={totalRows} onChange={onPagination} showSizeChanger={false} />
      </PaginationWrapper>}
    </B2BLayout>
  )
}
