import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface IStyledProps {
  fontWeight?: string;
}

export const DarkLink = styled(Link)<IStyledProps>`
  color: ${({ theme }) => theme.colors.black};
  overflow: hidden;
  display: flex;
  width: 100%;
  ${props => props?.fontWeight ? 'font-weight: ' + props.fontWeight + ';' : '' }
  label:hover {
    cursor: pointer;
  }
`
