import React from 'react'
import { Select } from 'antd'
import { IFilterOption } from  '../../model/types/types'

const { Option } = Select

export interface IMonthFilterProps {
  selectedMonths: string[]
  onMonthChange: (months: string[]) => void
  months: string[]
  style?: React.CSSProperties
}

const MonthFilter: React.FC<IMonthFilterProps> = ({
  selectedMonths,
  onMonthChange,
  months,
  style,
}) => {
  const monthOptions: IFilterOption[] = months.map(month => ({ id: month, value: month }))

  return (
    <Select
      mode="multiple"
      value={selectedMonths}
      onChange={onMonthChange}
      style={style}
      placeholder="Все месяцы"
      allowClear
    >
      {monthOptions.map(month => (
        <Option key={month.id} value={month.id}>
          {month.value}
        </Option>
      ))}
    </Select>
  )
}

export default MonthFilter
