import styled from 'styled-components'

export const ChatWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  overflow: hidden;
`

export const ChatBody = styled.div`
  overflow-y: auto;
  outline: none;
  padding: 10px;
  height: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: column-reverse;
`

export const MessageRow = styled.div<{ left?: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  flex-direction: ${props => props.left ? "row-reverse" : "row"};
  margin-bottom: 4px;
`

export const MessageText = styled.div<{ incoming?: boolean }>`
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 16px;
  background: ${props => props.incoming ? "#79f5ff" : "#c6f931"};
  white-space: pre-wrap;
`

export const MessageDate = styled.div<{ incoming?: boolean }>`
  text-align: right;
  font-size: 12px;
  margin-top: 4px;
`

export const ChatFooter = styled.div`
  padding: 10px;
  position: relative;
  background-color: #eceff1;
  border-top: 1px solid #ccc;
`

export const ButtonActionWrapper = styled.button`
  margin: 0px;
  font-size: 20px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 6px;
`