import {Moment} from "moment";
import {IDataByDay} from "../selectors";

export const getDetailTaskItemData = (value: Moment, dataByDay: IDataByDay) => {
    let currentDate = value.format('YYYY.MM.DD');
    if (!dataByDay[currentDate]) {
        return []
    }
    return dataByDay[currentDate]?.filter(val => val?.stage !== 'done');
}
