import {ActivityItemDate, ActivityItems} from "../types/types";

export const setTimelineItem = (
    newItems:ActivityItems[],
    timelineItems:ActivityItems[],
    timelineDates: ActivityItemDate[]
) => {
    if (!timelineItems.length) {
        timelineItems = newItems;
        timelineDates = newItems.map((item) => item.date);
    } else {
        newItems.forEach((itemsByDay) => {
            if (timelineDates?.includes(itemsByDay.date)) {
                const index = timelineDates?.findIndex((date) => date == itemsByDay.date);
                timelineItems[index].items = [
                    ...(itemsByDay?.items || []),
                    ...(timelineItems?.[index]?.items || [])
                ];
                return;
            }

            if (itemsByDay.date === 'planned') {
                timelineItems = [itemsByDay, ...timelineItems];
                timelineDates = [itemsByDay.date, ...timelineDates];
                return;
            }

            const currentDate = itemsByDay.date;// текущая
            const index = timelineDates.findIndex((dateDay) => currentDate > dateDay); //ищем первый индекс с большей датой

            if (index === -1) {
                timelineItems = [itemsByDay, ...timelineItems];
                timelineDates = [currentDate, ...timelineDates];
            } else {
                timelineItems = [...timelineItems.slice(0, index), itemsByDay, ...timelineItems.slice(index)];
                timelineDates = [...timelineDates.slice(0, index), currentDate, ...timelineDates.slice(index)];
            }
        })
    }

    return {
        timelineItems,
        timelineDates
    }
}
