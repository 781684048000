import styled from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  a {
    width: 100%;
  }

  .am-button span {
    font-size: 14px;
  }
`

export const ModalBody = styled.div`
  padding: 10px 0;
`
