import React, { useCallback, useEffect, useState } from 'react'
import { Space } from 'antd'
import SelectFromEnum from 'src/react-app/components/select-from-enum'
import InputDateRange from 'src/react-app/components//input-date-range'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { dateRange, typeAppeal, complaintDecision, confirmationForm, guiltyDivision, payer, status } from './utils'
import fetchAPI from "../../../../lib/utils/fetch-api";
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

function setEnumSchemaSelect(prop, oneOf, enumValues) {
  Object.assign(prop, {
    oneOf: oneOf,
    'enum': Object.keys(enumValues),
    view: { 'name': 'select-from-enum' }
  })
}

function Filter({ onChange }) {
  const { sessionData } = useSelector(createSession)
  const [formData, setFormData] = useState<any>(() =>({
    'filter[date_application]': undefined,
    'filter[type_appeal]': undefined,
    'filter[complaint_decision]': undefined,
    'filter[confirmation_form]': undefined,
    'filter[guilty_division]': undefined,
    'filter[payer]': undefined,
  }))

  const [schemaTypeAppeal, setSchemaTypeAppeal] = useState<any>({})
  const [schemaComplaintDecision, setSchemaComplaintDecision] = useState<any>({})
  const [schemaConfirmationForm, setSchemaConfirmationForm] = useState<any>({})
  const [schemaGuiltyDivision, setSchemaGuiltyDivision] = useState<any>({})
  const [schemaPayer, setSchemaPayer] = useState<any>({})
  const [schemaStatus, setSchemaStatus] = useState<any>({})

  const [UISchemaSelectFromEnum] = useState({
    options: {width: 180, placeholder: 'Вид обращения', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnum2] = useState({
    options: {width: 180, placeholder: 'Решение по рекламации', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnum3] = useState({
    options: {width: 180, placeholder: 'Форма подтверждения', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnum4] = useState({
    options: {width: 180, placeholder: 'Виновное подразделение', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnum5] = useState({
    options: {width: 180, placeholder: 'Плательщик', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnum6] = useState({
    options: {width: 180, placeholder: 'Статус', allowClear: true, showSearch: true,}
  })

  useEffect( () => {
    const getData = async () => {
      await fetchAPI('/api/claims/enum')
        .then(result => {
          const enumItems = result?.['data'] || {}
          Object.keys(enumItems).forEach(code => {
            const enumValues = result?.['data']?.[code] || {}
            const oneOf: Record<string, string>[] = []

            Object.keys(enumValues).forEach(enumValue => {
              return oneOf.push({
                'const': enumValue,
                'title': enumValues[enumValue]
              })
            })

            if(code === 'type_appeal') {
              setEnumSchemaSelect(typeAppeal, oneOf, enumValues)
              setSchemaTypeAppeal(typeAppeal)
            }
            if(code === 'complaint_decision') {
              setEnumSchemaSelect(complaintDecision, oneOf, enumValues)
              setSchemaComplaintDecision(complaintDecision)
            }
            if(code === 'confirmation_form') {
              setEnumSchemaSelect(confirmationForm, oneOf, enumValues)
              setSchemaConfirmationForm(confirmationForm)
            }
            if(code === 'guilty_division') {
              setEnumSchemaSelect(guiltyDivision, oneOf, enumValues)
              setSchemaGuiltyDivision(guiltyDivision)
            }
            if(code === 'payer') {
              setEnumSchemaSelect(payer, oneOf, enumValues)
              setSchemaPayer(payer)
            }
            if(code === 'status') {
              setEnumSchemaSelect(status, oneOf, enumValues)
              setSchemaStatus(status)
            }
          })
        })
    }
    getData()
  }, [])

  /*useEffect(() => {
    setFormData(data => ({ ...data, 'organization-id': 0 }))
    setUISchemaSelectFromEnum(({ options }) => ({ options: { ...options, disabled: true }  }))
    setUISchemaSelectFromEnum2(({ options }) => ({ options: { ...options, disabled: true }  }))
    setFormData(data => ({ ...data, 'organization-id': organizationId }))
    setUISchemaSelectFromOrganizations(({ options }) => ({ options: { ...options, disabled: true }  }))
  }, [sessionData])*/

  const onChangeLocal = useCallback((value, name) => {
    const nextformData = {}
    setFormData(prevFormData => Object.assign(nextformData, prevFormData, {[name]: value ? encodeURIComponent(value) : value}))
    onChange(nextformData)
  }, [onChange])

  const onChangeDateRange = useCallback(value => {
    onChangeLocal(value, 'filter[date_application]')
  }, [onChangeLocal])
  const onChangeTypeAppeal = useCallback(value => {
    onChangeLocal(value, 'filter[type_appeal]')
  }, [onChangeLocal])
  const onChangeComplaintDecision = useCallback(value => {
    onChangeLocal(value, 'filter[complaint_decision]')
  }, [onChangeLocal])
  const onChangeConfirmationForm = useCallback(value => {
    onChangeLocal(value, 'filter[confirmation_form]')
  }, [onChangeLocal])
  const onChangeGuiltyDivision = useCallback(value => {
    onChangeLocal(value, 'filter[guilty_division]')
  }, [onChangeLocal])
  const onChangePayer = useCallback(value => {
    onChangeLocal(value, 'filter[payer]')
  }, [onChangeLocal])
  const onChangeStatus = useCallback(value => {
    onChangeLocal(value, 'filter[status]')
  }, [onChangeLocal])

  return (
    <Space wrap>
      <InputDateRange
        value={formData['date_application']}
        schema={dateRange}
        onChange={onChangeDateRange}
      />
      <SelectFromEnum
        value={formData['type_appeal']}
        schema={schemaTypeAppeal}
        onChange={onChangeTypeAppeal}
        UISchema={UISchemaSelectFromEnum}
      />
      <SelectFromEnum
        value={formData['complaint_decision']}
        schema={schemaComplaintDecision}
        onChange={onChangeComplaintDecision}
        UISchema={UISchemaSelectFromEnum2}
      />
      <SelectFromEnum
        value={formData['confirmation_form']}
        schema={schemaConfirmationForm}
        onChange={onChangeConfirmationForm}
        UISchema={UISchemaSelectFromEnum3}
      />
      <SelectFromEnum
        value={formData['guilty_division']}
        schema={schemaGuiltyDivision}
        onChange={onChangeGuiltyDivision}
        UISchema={UISchemaSelectFromEnum4}
      />
      <SelectFromEnum
        value={formData['payer']}
        schema={schemaPayer}
        onChange={onChangePayer}
        UISchema={UISchemaSelectFromEnum5}
      />
      <SelectFromEnum
        value={formData['status']}
        schema={schemaStatus}
        onChange={onChangeStatus}
        UISchema={UISchemaSelectFromEnum6}
      />
    </Space>
  )
}

export default Filter