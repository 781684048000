import React, {memo, useCallback} from "react";
import fetchAPI from "../../../../../lib/utils/fetch-api";
import {ExportExcelButton, format2Print} from "src/react-app/components/v2/export-excel-button";

export interface AppealsPrintButtonProps {
    path: string,
    columns: any[],
    stages: any[],
}

export const AppealsPrintButton: React.FC<AppealsPrintButtonProps> = memo((props) => {
    const {path, columns, stages} = props;

    const handlePrintClick = useCallback(async () => {
        try {
            const response = await fetchAPI(path);
            const data = response.data;
            if (!data) {
                return
            }

            const viewColumnNames = columns.map((column) => column.dataIndex);
            const count = data.length;

            for (let i = 0; i < count; i++) {
                let keys = Object.keys(data[i])
                keys.forEach((key) => {
                    if (!viewColumnNames.includes(key)) {
                        return;
                    }
                    if (key === 'stage') {
                        data[i][key] = stages.find((stage) => stage.code === data[i][key])?.name || data[i][key];
                        return;
                    }

                    data[i][key] = format2Print(key, data[i][key]);
                })
            }

            return data
        } catch (e) {
            console.log('handlePrintClick', e)
        }
    }, [columns, stages, path])


    return (
        <ExportExcelButton
            fileName={'Обращения'}
            path={path}
            columns={columns}
            onPrintClick={handlePrintClick}
        />
    )
})