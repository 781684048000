export function downloadFileJson(object, exportName) {
  const jsonFormat = encodeURIComponent(JSON.stringify(object, null, 2))
  const dataStr = `data:text/json;charset=utf-8,${jsonFormat}`
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', exportName + '.json')
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

export function loadFileJson() {
  const inputNode = document.createElement('input')
  inputNode.setAttribute('type', 'file')
  inputNode.setAttribute('style', 'display: none')
  document.body.appendChild(inputNode)
  return new Promise(resolve => {
    inputNode.onchange = function({ target }) {
      // @ts-ignore
      if(Boolean(target?.files[0]) === false){
        inputNode.remove()
        return resolve(null)
      }
      const fr = new FileReader()
      fr.onload = ({ target }) => {
        resolve(
          // @ts-ignore
          JSON.parse(target?.result) ?? null
        )
        inputNode.remove()
      }
      // @ts-ignore
      fr.readAsText(target.files[0])
    }
    inputNode.click()
  })
}