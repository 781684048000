import React, { useEffect, useState, useMemo, useCallback } from 'react'
import fetchAPI from 'src/lib/utils/fetch-api'
import { Select } from 'antd'

function filterOption(input, option){
  return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

function SelectFromRoles({ value, schema, onChange, UISchema }:{ value?:any, schema:any, onChange?:any, UISchema:any }) {
  const [objects, setObjects] = useState<any[]>([{ id: value, value }])

  useEffect(() => {
    (async () => {
      const objectsResult: any = await fetchAPI(`/api/roles`)
      const objectsFormat = objectsResult['data'] && objectsResult['data'].map(({ id, title }) => {
        return { id, value: title }
      })
      setObjects(objectsFormat)
    })()
  }, [])
  const values: any[] = useMemo(() =>  Array.isArray(value) ? value: [], [value])

  const onSearch = useCallback(text => {
    if (['b', 'б'].includes(text)) {
      const roles = [2,10,11,13,14,15,17,20,21,23,24,25,26,28,29]
      const nextValues = Array.from(new Set(values.concat(roles)))
      onChange(nextValues)
      return
    }
    if (['g', 'г'].includes(text)) {
      const roles = [3,4,5,6,12,18,19]
      const nextValues = Array.from(new Set(values.concat(roles)))
      onChange(nextValues)
      return
    }
    return text
  }, [values, onChange])

  return (
    <Select
      onSearch={onSearch}
      mode="multiple"
      showSearch
      placeholder="Выберите запись"
      optionFilterProp="children"
      onChange={onChange}
      value={values}
      filterOption={filterOption}
    >
    {objects.map(({ id, value }) => (
      <Select.Option value={id}>{value}</Select.Option>
    ))}
    </Select>
  )
}

export default SelectFromRoles

