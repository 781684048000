import fetchAPI from './fetch-api'
import { createSelector } from 'reselect'
import { IStore } from '../../react-app/store/interfaces'

// @todo нижняя функция должна быть удалена, источник багов
export const getSchemaArrayEnum = (name, collectionName, id) => `${name}-${collectionName}-${id}`

export const getSchemaFromData = (item, collectionData: any) => {
  const { properties } = item
  return {
    id: item['id'],
    name: item['name'],
    title: item['title'],
    description: item['description'],
    type: item['type'],
    required: item['required'],
    properties: properties,
    createdBy: item['created-by'],
    createdAt: item['created-at'],
    updatedAt: item['updated-at'],
    customKey: '',
  }
}

export const checkIsRequired = (formData = {}, required) => {
  const formDataKeys = formData !== null ? Object.keys(formData) : []
  const formDataValues = formData !== null ? Object.values(formData) : []
  const isRequiredSchema: boolean = required.every((item) => formDataKeys.includes(item))
  const isRequiredFormDataValues: boolean = formDataValues
    .every(item => item !== null && item !== undefined)

  return isRequiredSchema && isRequiredFormDataValues
}

export const getCollectionsListFromProperties: (schemas: any) => string[] = schemas => {
  const references: any = {}

  schemas.forEach(schema => {
    const props = Object.values(schema['properties'])

    props.forEach((item: any) => {
      if ((item['type'] === 'string') && (item['format'] === 'platform-collection')) {
        if(('collection-name' in item) && (typeof item['collection-name'] === "string") && (item['collection-name'] !== "")){
          // Используем ключи для сбора только уникальных значений
          references[item['collection-name']] = null
        }
      }
    })
  })

  return Object.keys(references)
}

export const fetchAllCollections: (names: any[]) => Promise<any> = (names) => {
  return Promise.all(names.map(
    name => fetchAPI(`/api/collections/${name}?access_key=axioma&order_by=updated-at&order_dir=desc`)
  ))
}

export const createCollections = createSelector(
  (state: IStore) => state.collections,
  collections => collections?.current,
)

export function isFormDataRequired(formData, schema){
  const { required } = schema || {}
  const result = Array.from(required).every((value: any) => {
    if ((value in formData) === false) {
      return false
    }
    if (formData[value] === undefined) {
      return false
    }
    if (formData[value] === null) {
      return false
    }
    if (formData[value] === '') {
      return false
    }
    if (Array.isArray(formData[value]) && formData[value].length === 0) {
      return false
    }
    return true
  })
  return result
}
