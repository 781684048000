import fetchAPI from '../../../lib/utils/fetch-api'
import {EntityType} from "../../shared/types/EntityType";
import {setStagePlanData, setStagePlanFailed, setStagePlanLoading} from "./actions";

export function getStagePlanData(entityType: EntityType, entityId: string | number) {
    return async dispatch => {
        dispatch(setStagePlanLoading());
        try {
            const response = await fetchAPI(`/api/stage-plan/${entityType}/${entityId}`) || [];

            if (response['errors']) {
                let error = response['errors'];
                if (Array.isArray(error)) {
                    error = error.join();
                }
                return dispatch(setStagePlanFailed(error));
            }

            return dispatch(setStagePlanData(response));
        } catch (err) {
            return dispatch(setStagePlanFailed(err as string));
        }
    }
}



