import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import { Popconfirm } from 'antd'
import { DataType } from './interface'
import { ButtonActionWrapper } from '../../../components/list-operations/styles'
import { DeleteTwoTone } from '@ant-design/icons'
import { CountBallInline, Stage } from '../../../components/activity-timeline/styles'
import { formatPrice } from '../../utils'
import { Value } from '../../elements'

const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
  }
  return phoneNumber
}

const ColumnId: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    fixed: 'left'
  },
  {
    title: 'Стадия',
    dataIndex: 'stage',
    key: 'stage',
    width: 170,
    render: (_, { stage }) => <StageContainer stage={stage} />
  },
]

const ColumnsGeneral: ColumnsType<DataType> = [
  {
    title: 'Отметка о согласовании',
    dataIndex: 'approval_mark',
    key: 'approval_mark',
    width: 120,
    render: val => <Value data={val}/>
  },
  {
    title: '№ Технического задания',
    dataIndex: 'technical_assignment_number',
    key: 'technical_assignment_number',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Плановая дата доставки',
    dataIndex: 'delivery_date',
    key: 'delivery_date',
    width: 150,
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  },
  {
    title: 'Первичная дата доставки',
    dataIndex: 'old_delivery_date',
    key: 'old_delivery_date',
    width: 150,
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  },
  {
    title: 'Имя клиента',
    dataIndex: 'client_name',
    key: 'client_name',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Телефон клиента',
    dataIndex: 'client_phone',
    key: 'client_phone',
    width: 150,
    render: val => formatPhoneNumber(val)
  },
  {
    title: 'Проезд',
    dataIndex: 'passage',
    key: 'passage',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Телефон дилера',
    dataIndex: 'dealer_phone',
    key: 'dealer_phone',
    width: 160,
    render: val => formatPhoneNumber(val)
  },
  {
    title: 'Отвественный менеджер',
    dataIndex: 'responsible_manager',
    key: 'responsible_manager',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Телефон менеджера',
    dataIndex: 'manager_phone',
    key: 'manager_phone',
    width: 160,
    render: val => formatPhoneNumber(val)
  },
  {
    title: 'Адрес доставки',
    dataIndex: 'customer_location_address',
    key: 'customer_location_address',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Время С',
    dataIndex: 'drop_window_start',
    key: 'drop_window_start',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Время До',
    dataIndex: 'drop_window_end',
    key: 'drop_window_end',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Возмоность проезда',
    dataIndex: 'passage_availability',
    key: 'passage_availability',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Этаж/Лифт',
    dataIndex: 'floor_elevator',
    key: 'floor_elevator',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Дилер',
    dataIndex: 'dealer',
    key: 'dealer',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Очередность заказа в маршруте ',
    dataIndex: 'sequence_in_run',
    key: 'sequence_in_run',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Статус заказа в WD',
    dataIndex: 'order_status_in_wd',
    key: 'order_status_in_wd',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Что не готово? Когда?',
    dataIndex: 'what_is_not_ready_and_when',
    key: 'what_is_not_ready_and_when',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Машина',
    dataIndex: 'vehicle_number',
    key: 'vehicle_number',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Пирамида',
    dataIndex: 'pyramid_number',
    key: 'pyramid_number',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Коментарий к доставке',
    dataIndex: 'delivery_comment',
    key: 'delivery_comment',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Сложность',
    dataIndex: 'delivery_complexity',
    key: 'delivery_complexity',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Подъем',
    dataIndex: 'lifting',
    key: 'lifting',
    width: 160,
    render: val => <Value data={val}/>
  },
  {
    title: 'Дата создания',
    width: 200,
    dataIndex: 'created_at',
    key: 'created_at',
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  },
  {
    title: 'Дата обновления',
    width: 200,
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  }
]

const ColumnAction: ColumnsType<DataType> = [
  {
    title: 'Действие',
    key: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    align: 'center',
    render: (_, {id, deleteRecord}) => {
      return (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <DeleteButton id={id} deleteRecord={deleteRecord}/>
        </span>
      )
    }
  }
]

export function DeleteButton({id, deleteRecord}) {
  const localDeleteRecord = useCallback(() => deleteRecord(id), [deleteRecord, id])
  return (
    <Popconfirm
      title="Вы уверены, что хотите удалить эту запись?"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => localDeleteRecord()}
    >
      <ButtonActionWrapper title="Удалить">
        <DeleteTwoTone />
      </ButtonActionWrapper>
    </Popconfirm>
  )
}

export const getColumns = (leader, sorter) => {
  // собирает колонки для разных ролей
  const columns = leader ? ColumnId.concat(ColumnsGeneral, ColumnAction) : ColumnId.concat(ColumnsGeneral)
  // устанавливает порядок сортировки
  return columns.map(item => {
    if (item.key === sorter.sort_field)
      item.sortOrder = sorter.sort_order
    else
      delete item.sortOrder
    return item
  })
}


export function StageContainer({ stage }) {
  if (stage) {
    return <Stage style={stage?.style}>{stage.name}</Stage>
  }
  return <>...</>
}
