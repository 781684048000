import React, { useCallback, useMemo, useState } from 'react'
import { Modal, Button } from 'antd'
import { IFormData, NotAcceptedModalProps } from './interfaces'
import { DefaultJsonForm } from '../../../../../../components/v2/default-json-form'
import schemaJson from './schema.json'
import { convertData2JsonForm } from '../../../../../../shared/lib/convertData2JsonForm'

const ModalNotAccepted: React.FC<NotAcceptedModalProps> = (props: NotAcceptedModalProps) => {
  const { onOk } = props
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const [formData, setFormData] = useState<IFormData>({
    disagreement_act: undefined,
  })

  const schema = useMemo(() => {
    return convertData2JsonForm(schemaJson).resultSchema
  },[schemaJson])

  const handleOpenModal = () => {
    setFormData( prevData => ({...prevData }))
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOk = useCallback(() => {
    if (!formData.disagreement_act) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Заполните все обязательные поля'
      })
      return
    }
    onOk?.({ disagreement_act: formData.disagreement_act })
  }, [formData])

  const handleChange = useCallback (data => {
    setFormData(prevData => ({ ...prevData, ...data }))
  },[ formData ])

  return (
    <>
      <Button onClick={handleOpenModal}>Монтаж не принят</Button>
      <Modal
        title='Монтаж не принят'
        visible={isModalOpen}
        onCancel={handleCloseModal}
        onOk={handleOk}
      >
        <DefaultJsonForm
          formData={formData}
          schema={schema}
          onChange={handleChange}
          orientation='horizontal'
        />
      </Modal>
    </>
  )
}

export default ModalNotAccepted
