import styled, { css } from 'styled-components';
import { Button } from 'antd'

interface IHeadingStyled {
  disabled: boolean;
  error: boolean
}

export const ContentWrapper = styled.div<IHeadingStyled>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  flex-direction: row;
  padding: 0 !important;
  border-radius: 3px;
  border-color: ${props => props.error ? '#ff4d4f': '#d9d9d9'};;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  background-color: ${props => props.disabled ? '#f5f5f5': 'unset'};

  &:hover, &:focus {
    border-color: ${props => props.error ? '#ff4d4f': props.disabled ? '#d9d9d9': '#40a9ff'};
  }
  &:hover svg, &:focus svg {
    visibility: visible;
  }
`;


export const FileLink = styled.span`
  max-width: 250px;
  min-width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileNoLink = styled.span`
  white-space: nowrap;
  color: #d9d9d9;
`;

export const FileLinkWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  white-space: nowrap;
  align-items: center;
  padding-left: 10px !important;
  padding-right: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    font-size: 16px;
    color: #d9d9d9;
    visibility: ${({ isMobile = false }) => (isMobile ? 'visible' : 'hidden')};
  }
`;

export const FileButton = styled(Button)`
  width: 32px;
  padding: 0 !important;
  border-color: inherit !important;
  border-right-width: 0 !important;
  &:hover, &:focus {
    border-right-width: 0 !important;
  }
`;



export const SuggestWrapper = styled.div`
  position: relative;
  & .ant-checkbox-wrapper {
    margin-bottom: 5px;
  }
  
`

export const InputTitle = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: #525252;

  margin-top: 6px;
`

export const SuggestList = styled.div<{ isShow: boolean }>`
  background-color: #ffffff;

  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);

  display: flex;
  flex-direction: column;

  position: absolute;
  max-height: 300px;
  overflow-x: scroll;

  top: 32px;
  left: 0;

  min-width: 200px;

  padding: 4px 0;
  opacity: 0;
  pointer-events: none;
  transform: translateY(27px);
  transition: opacity 0.3s, transform 0.3s;
  z-index: 1;

  ${({ isShow }) =>
    isShow &&
    css`
      opacity: 1;
      transform: translateY(27px);
      pointer-events: all;
    `}
`

export const SuggestItem = styled.div`
  background-color: transparent;
  display: flex;
  align-items: center;
  flex: auto;

  overflow: hidden;

  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;

  position: relative;

  min-height: 32px;
  max-height: 32px;

  padding: 2px 10px;

  transition: background-color 0.3s;

  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`
