import React, { useCallback, useEffect, useState } from 'react'
import { Form, Input, Button, Skeleton, message, Popconfirm, Select } from 'antd'
import fetchAPI from '../../../../lib/utils/fetch-api'
import B2BLayout from '../../../layouts/b2b'
import { HStack } from '../../../ui/Stack'
import './style.css'
import { ApiResponse } from './interrfaces'

export const StagePlanEditor = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [form] = Form.useForm()
  const [data, setData] = useState<ApiResponse>({ settings: [], stages: [] })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAPI('/api/admin/stage-plan') as ApiResponse
        setData(response)
      } catch (err) {
        const errorMessage = (err as Error).message
        setError(errorMessage)
        message.error(`Ошибка загрузки данных: ${errorMessage}`)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleSave = useCallback(async () => {
    const values = data.settings
    try {
      setLoading(true)
      await fetchAPI('/api/admin/stage-plan', {
        method: 'POST',
        body: JSON.stringify({ settings: values }),
      })
      message.success('Данные успешно сохранены')
    } catch (err) {
      const errorMessage = (err as Error).message
      message.error(`Ошибка сохранения данных: ${errorMessage}`)
    } finally {
      setData( prevData => ({ ...prevData, settings: values }))
      setLoading(false)
    }
  }, [data, setLoading, setData])

  const handleAdd = () => {
    const newSettings = [...data.settings, { name: '', stage: 0, min_days: 0, max_days: 0 }]
    setData({ ...data, settings: newSettings })
  }

  const handleRemove = useCallback((index: number) => {
    const newSettings = data.settings.filter((_, i) => i !== index)
    setData(prevData => ({ ...prevData, settings: [...newSettings] }))
  }, [data, setData])

  if (error) {
    return <div>Ошибка: {error}</div>
  }

  /**
   * Хлебные крошки
   */
  const breadcrumbs = [
    {
      href: '/admin',
      title: 'Админ панель',
    },
    {
      href: '',
      title: 'Настройки плана исполнения заказа',
    }
  ]

  const formItemLayout = {
    labelCol: {
      sm: { span: 6 },
    },
    wrapperCol: {
      sm: { span: 18 },
    },
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {loading ? (<Skeleton active />): (
        <Form
          form={form}
          initialValues={data}
          layout='horizontal'
          { ...formItemLayout }
        >
          {data.settings.map((_setting, index) => (
            <div key={index} style={{ marginBottom: 16 }}>
              <Form.Item
                label='Название'
                name={['settings', index, 'name']}
                rules={[{ required: true, message: 'Введите название' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Стадия'
                name={['settings', index, 'stage']}
                rules={[{ required: true, message: 'Введите стадию' }]}
              >
                <Select options={ data?.stages.map(stage => ({ label: stage.name, value: stage.code })) } />
              </Form.Item>
              <Form.Item
                label='Минимальное кол-во дней'
                name={['settings', index, 'min_days']}
                rules={[{ required: true, message: 'Введите минимальное количество дней' }]}
              >
                <Input type='number' />
              </Form.Item>
              <Form.Item
                label='Максимальное кол-во дней'
                name={['settings', index, 'max_days']}
                rules={[{ required: true, message: 'Введите максимальное количество дней' }]}
              >
                <Input type='number' />
              </Form.Item>
              <HStack justify='end'>
                <Popconfirm
                  title='Вы уверены, что хотите удалить этот блок?'
                  onConfirm={() => handleRemove(index)}
                  okText='Да'
                  cancelText='Нет'
                >
                  <Button danger >Удалить</Button>
                </Popconfirm>
              </HStack>
            </div>
          ))}

          <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 6 }}>
            <Button type='dashed' onClick={handleAdd} style={{ width: '100%', marginBottom: 16 }}>
              Добавить блок
            </Button>
          </Form.Item>
          <Button type='primary' onClick={handleSave} disabled={loading}>
            Сохранить
          </Button>
        </Form>
        )}
    </B2BLayout>
  )
}
