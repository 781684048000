import {
    HR_FORM_LOADING,
    HR_FORM_DATA,
    HR_FORM_FAILED,
    HR_ENUMS_LOADING,
    HR_ENUMS_DATA,
    HR_ENUMS_FAILED,
    HR_FORM_SUCCESS_UPDATE,
    HR_FORM_ID_UPDATE,
    HR_CANCEL_FORM_DATA,
} from './actions'
import {IHrState} from "./interfaces";

const initialState: IHrState = {
    form: {
        data: null,
        isLoading: false,
        error: '',
    },
    enums: {
        data: null,
        isLoading: false,
        error: '',
    },
    cancelModal: null,
}

export const hrReducer = (
    state = initialState,
    {type, payload},
) => {
    switch (type) {
        case HR_FORM_LOADING:
            return {
                ...state,
                'id': null,
                'form': {
                    ...state['form'],
                    isLoading: true,
                    data: null,
                    error: ''
                }
            }
        case HR_FORM_DATA:
            return {
                ...state,
                'form': {
                    ...state['form'],
                    isLoading: false,
                    data: payload,
                    error: ''
                }
            }
        case HR_FORM_FAILED:
            return {
                ...state,
                'form': {
                    ...state['form'],
                    isLoading: false,
                    error: payload
                }
            }
        case HR_FORM_SUCCESS_UPDATE:
            return {
                ...state,
                'form': {
                    ...state['form'],
                    isLoading: false,
                    error: ''
                }
            }
        case HR_FORM_ID_UPDATE:
            return {
                ...state,
                'id': payload,
                'form': {
                    ...state['form'],
                    isLoading: false,
                    error: ''
                }
            }
        case HR_CANCEL_FORM_DATA:
            return {
                ...state,
                'cancelModal': payload
            }
        case HR_ENUMS_LOADING:
            return {
                ...state,
                'enums': {
                    ...state['enums'],
                    isLoading: true,
                    data: null,
                    error: ''
                }
            }
        case HR_ENUMS_DATA:
            return {
                ...state,
                'enums': {
                    ...state['enums'],
                    isLoading: false,
                    data: payload,
                    error: ''
                }
            }
        case HR_ENUMS_FAILED:
            return {
                ...state,
                'enums': {
                    ...state['enums'],
                    isLoading: false,
                    error: payload
                }
            }
        default:
            return state
    }
}

