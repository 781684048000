import {Dropdown, Menu} from "antd";
import React, {memo, Suspense, useCallback, useState} from "react";
import {ExcelFileAsync} from "../excel-file/excel-file.async";
import {ExcelPathsType, KeyPath} from "../../model/types/types";
import {mapLabelByKey} from "../../model/const/mapLabelByKey";


export interface ExportExcelDropdownButtonsProps {
    fileName?: string,
    paths: ExcelPathsType[],
    columns: any[],
    onPrintClick: (path: string, buttonKey: KeyPath) => Promise<any>,
}


export const ExportExcelDropdownButtons: React.FC<ExportExcelDropdownButtonsProps> = memo((props) => {
    const {paths, columns, onPrintClick, fileName = 'file'} = props;
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const onClickMenuItem = useCallback((path: string, buttonKey: KeyPath) => async () => {
        setIsLoading(true)
        try {
           const data = await onPrintClick(path, buttonKey);
           setData(data)
           setIsMounted(true)
        } catch (e) {
            console.log('onClickMenuItem', e)
        }
    }, [columns])


    const onMountedExcelComponent = useCallback(() => {
        setIsLoading(false)
        setIsMounted(false)
    }, [])

    return (<>
            <Dropdown.Button
                trigger={['click']}
                loading={isLoading}
                placement={'bottomRight'}
                overlay={
                    <Menu>
                        {
                            paths.map((pathData) => (
                                    <Menu.Item key={pathData?.key || pathData?.label}
                                               onClick={onClickMenuItem(pathData.path, pathData.key)}>
                                        {pathData?.label ? pathData.label : mapLabelByKey[pathData.key]}
                                    </Menu.Item>
                                )
                            )
                        }
                    </Menu>
                }>
                Печать
            </Dropdown.Button>
            <Suspense fallback={''}>
                {
                    isMounted && <ExcelFileAsync
                        onMountedExcelComponent={onMountedExcelComponent}
                        fileName={fileName}
                        columns={columns}
                        data={data}
                    />
                }
            </Suspense>
        </>
    )
})
