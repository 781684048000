import React, { memo, useMemo } from 'react'
import { createSelector } from 'reselect'
import { IStore } from '../store/interfaces'
import { useSelector } from 'react-redux'
import { getDataOfType } from '../../lib/utils/get-data-of-type'
import AppLoader from '../components/app-loader'
import { Redirect, Route, Switch } from 'react-router-dom'
import LoginPage from '../pages/login'
import CasesListPage from '../pages/list-cases'
import { PrivateRoute } from '../utilits'
import {
  DeliveryListPage,
  DeliveryEditPage,
  MeasurementsListPage,
  MountingListPage,
  MeasurementsEditPage,
  MountingEditPage,
} from '../pages/mobile'

const createPageSelector = createSelector(
  (state: IStore) => ({
    page: state.page,
    session: state.session,
  }),
  ({ page, session }) => ({ page, session }),
)

export const MobileRouter = memo(() => {
  const isAuthorizedSession = session => {
    return (
      session.sessionData
      && session.sessionData.user
      && (session.sessionData.user['id'] > 0)
      && (session?.sessionData?.roles && session?.sessionData?.roles.length > 0)
    )
  }

  const { page, session } = useSelector(createPageSelector)
  const startPage = useMemo(() => getDataOfType(session, 'sessionData.start-page', String, ''), [
    session,
  ])

  return (
    <>
      {page.isLoading && <AppLoader />}
      {!page.isLoading && (
        /* @ts-ignore */
        <Switch>
          {/* @ts-ignore */}
          <Route
            exact
            path="/"
            render={() =>
              /* @ts-ignore */
              isAuthorizedSession(session) ? <Redirect to={startPage} /> : <Redirect to="/login" />
            }
          />
          {/* @ts-ignore */}
          <Route exact path="/login" component={LoginPage} />
          <PrivateRoute exact path="/call-center/cases" session={session}>
            <CasesListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/logistic-delivery" session={session}>
            <DeliveryListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/delivery/:id/edit" session={session}>
            <DeliveryEditPage />
          </PrivateRoute>
          <PrivateRoute exact path="/logistic-dashboard" session={session}>
            <DeliveryListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/sales/measurements" session={session}>
            <MeasurementsListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/froze/:id/edit" session={session}>
            <MeasurementsEditPage />
          </PrivateRoute>
          <PrivateRoute exact path="/sales/mounting" session={session}>
            <MountingListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/mounting/:id/edit" session={session}>
            <MountingEditPage />
          </PrivateRoute>
        </Switch>
      )}
    </>
  )
})
