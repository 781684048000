import {IEnumsData, IFormData} from "./interfaces";

export const APPEALS_FORM_LOADING = 'APPEALS_FORM_LOADING'
export const APPEALS_FORM_DATA = 'APPEALS_FORM_DATA'
export const APPEALS_FORM_FAILED = 'APPEALS_FORM_FAILED'
export const APPEALS_FORM_ID_UPDATE = 'APPEALS_FORM_ID_UPDATE'
export const APPEALS_ENUMS_LOADING = 'APPEALS_ENUMS_LOADING'
export const APPEALS_ENUMS_DATA = 'APPEALS_ENUMS_DATA'
export const APPEALS_ENUMS_FAILED = 'APPEALS_ENUMS_FAILED'

/* ============== APPEALS_FORM ============== */
export function setAppealsFormLoading() {
    return async dispatch => {
        dispatch({type: APPEALS_FORM_LOADING})
    }
}

export function setAppealsFormData(formData: IFormData) {
    return async dispatch => {
        dispatch({type: APPEALS_FORM_DATA, payload: formData})
    }
}

export function setAppealsFormFailed(error: string) {
    return async dispatch => {
        dispatch({type: APPEALS_FORM_FAILED, payload: error})
    }
}

export function setAppealId(id: string) {
    return async dispatch => {
        dispatch({type: APPEALS_FORM_ID_UPDATE, payload: id})
    }
}



/* ============== end APPEALS_FORM ============== */


/* ============== APPEALS_ENUMS ============== */
export function setAppealsEnumsLoading() {
    return async dispatch => {
        dispatch({type: APPEALS_ENUMS_LOADING})
    }
}

export function setAppealsEnumsData(enumsData: IEnumsData) {
    return async dispatch => {
        dispatch({type: APPEALS_ENUMS_DATA, payload: enumsData})
    }
}

export function setAppealsEnumsFailed(error: string) {
    return async dispatch => {
        dispatch({type: APPEALS_ENUMS_FAILED, payload: error})
    }
}

/* ============== end APPEALS_ENUMS ==============  */
