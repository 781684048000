import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {Table, Alert, Pagination, Space, Button, Modal} from 'antd'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import B2BLayout from 'src/react-app/layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import { formDataToUrlParams } from 'src/react-app/pages/list-orders/utils'
import { calcTotalWidth, tableRowClick } from 'src/lib/utils/list'
import { TableWrapper, PaginationWrapper } from './styles'
import { useSelector} from 'react-redux'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import OrderFilter from './orders-filter'
import './index.css'
import {PlusOutlined, FileExcelOutlined} from "@ant-design/icons"
import modalClaimsExport from "../../views/modal-export-claims";

const createViewed = createSelector(
  (state: any) => state.viewed,
  counts => counts
)

function downloadUrl(url, exportName) {
  console.log('url', url)
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', url)
  downloadAnchorNode.setAttribute('download', exportName)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

export default function AdminObjectsListPage() {
  const history = useHistory()
  const location = useLocation()
  const orderFiltersRef = useRef<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [page, setPage] = useState<number>(1)
  const { counts } = useSelector(createViewed)
  const breadcrumbs = useMemo(() => (
    [{
      href: '/',
      title: 'Отчеты Satels',
    }, {
      href: location.pathname,
      title: 'Список рекламаций',
    }]
  ), [location.pathname])

  const onButtonCreate = useCallback(() => history.push(`/claims/new`), [history])

  const addDataColumns = useMemo(() => {
    const addDataColumns: any[] = [
      {
        title: 'ID',
        width: 100,
        dataIndex: 'id',
        key: 'id',
        render: (_, item) => getDataOfType(item, 'id', Number, '')
      },
      {
        title: 'Дата обращения',
        width: 200,
        dataIndex: 'date_application',
        key: 'date_application',
        render: key => key && dayjs(key).format('DD.MM.YYYY')
      },
      {
        title: 'Номер тех. Задания',
        width: 200,
        dataIndex: 'num_tech',
        key: 'num_tech',
        render: key => key
      },
      {
        title: 'Номер переделки',
        width: 150,
        dataIndex: 'num_rework',
        key: 'num_rework',
        render: key => key
      },
      {
        title: 'Название клиента',
        width: 150,
        dataIndex: 'client_name',
        key: 'client_name',
        render: (key, item) => key
      },
      {
        title: 'Вид обращения',
        width: 150,
        dataIndex: 'type_appeal',
        key: 'type_appeal',
        render: key => key
      },
      {
        title: 'Решение по рекламации',
        width: 150,
        dataIndex: 'complaint_decision',
        key: 'complaint_decision',
        render: key => key
      },
      {
        title: 'Дата готовности переделки',
        width: 150,
        dataIndex: 'date_completion_rework',
        key: 'date_completion_rework',
        render: key => key && dayjs(key).format('DD.MM.YYYY')
      },
      {
        title: 'Исполнитель работ',
        width: 150,
        dataIndex: 'responsible_work',
        key: 'responsible_work',
        render: key => key
      },
      {
        title: 'Стоимость закрытия рекламации',
        width: 150,
        dataIndex: 'price_close_complaints',
        key: 'price_close_complaints',
        render: key => key
      },
      {
        title: 'Форма подтверждения',
        width: 150,
        dataIndex: 'confirmation_form',
        key: 'confirmation_form',
        render: key => key
      },
      {
        title: 'Характер рекламации',
        width: 150,
        dataIndex: 'nature_complaint',
        key: 'nature_complaint',
        render: key => key
      },
      {
        title: 'Виновное подразделение',
        width: 150,
        dataIndex: 'guilty_division',
        key: 'guilty_division',
        render: key => key
      },
      {
        title: 'Участок появления дефекта',
        width: 150,
        dataIndex: 'area_defect',
        key: 'area_defect',
        render: key => key
      },
      {
        title: 'Плательщик',
        width: 150,
        dataIndex: 'payer',
        key: 'payer',
        render: key => key
      },
      {
        title: 'Статус рекламации',
        width: 150,
        dataIndex: 'status',
        key: 'status',
        render: key => key
      },
      {
        title: 'Срок исполнения(кол-во дней)',
        width: 150,
        dataIndex: 'term',
        key: 'term',
        render: key => key
      },
    ]
    return addDataColumns
  }, [])

  const totalWidth: any = useMemo(() => calcTotalWidth(addDataColumns), [ addDataColumns])
  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(addDataColumns).filter(item => item?.title), [addDataColumns])

  const cbTableRowClick = useCallback((item) => item['id'] && history.push(`/claims/${item['id']}`), [history])

  const deleteRecord = useCallback(async (currentId) => {
    try{
      await fetchAPI(`/api/collections/objects/${currentId}`, { method: 'DELETE'})
      setDataSource(dataSource => dataSource.filter(({ id }) => id !== currentId))
    }catch(error){
      console.error(error)
    }
  }, [])

  const getData = useCallback(async (value, addUrlParams?: string) => {
    const urlParams = formDataToUrlParams(value)
    const pageErrors: string[] = []
    const [collections] = await Promise.all([
      fetchAPI(`/api/claims?${urlParams}&${addUrlParams}`),
    ])

    const collectionsResultData = getDataOfType(collections, 'data.data', Array, [])

    console.log(collectionsResultData)

    const dataSource: any[] = collectionsResultData
    const newDataSource = dataSource.map((item) => ({
      ...item,
      deleteRecord,
    }))

    const totalRows = getDataOfType(collections, 'data.total-rows', Number, 0)
    setTotalRows(totalRows)
    setDataSource(newDataSource)
    setPageErrors(pageErrors)
    setIsDataLoading(false)
  }, [deleteRecord])

  const onPagination = useCallback(async ( page, pageSize) => {
    setPage(page)
    const urlParams = `page=${page}&pageSize=${pageSize}`
    setIsDataLoading(true)
    await getData(orderFiltersRef.current, urlParams)
    setIsDataLoading(false)
  }, [getData])

  useEffect(() => {
    const urlParams = `page=${page}&pageSize=10`
    page === 1 && getData(orderFiltersRef.current,urlParams)
  }, [counts, page, getData])

  const rowClassName = useCallback(record => {
    const isColor = record['orders-quantity'] >= 2 && record['orders-quantity-new'] >= 1
    return isColor ? 'table-client-row-dark' :  'table-client-row-light'
  }, [])

  const onOrderFilter = useCallback(value => {
    Object.assign(orderFiltersRef.current, value)
    getData(value)
  }, [getData])

  const onPrint = useCallback(async () => {
    const result = await modalClaimsExport()
    if (result === null) {
      return
    }
    const urlParams = formDataToUrlParams(orderFiltersRef.current)
    downloadUrl(`/api/claims/report?year=${result.year}&${urlParams}`, `Отчет.xlsx`)
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <Space direction="horizontal">
        <Button onClick={onButtonCreate} style={{ marginBottom: 16 }}>
          <PlusOutlined /> Создать рекламацию
        </Button>
        <Button style={{ marginBottom: 16 }} onClick={onPrint}>
          <FileExcelOutlined /> Экспорт в эксель
        </Button>
      </Space>
      <Space align="baseline">
        <OrderFilter onChange={onOrderFilter} />
      </Space>
      {pageErrors.length > 0 ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
          />
        </div>
      ) : null}
      <TableWrapper>
        <Table
          columns={finalColumns}
          rowClassName={rowClassName}
          size="small"
          dataSource={dataSource}
          scroll={{ x: totalWidth, y: '72vh' }}
          pagination={false}
          loading={isDataLoading}
          onHeaderRow={(column: any) => ({
            style: {
              fontSize: column.key && column.key.substr(-3) === '-at' ? 9 : 13,
            },
          })}
          onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick) })}
        />
      </TableWrapper>
      {Boolean(totalRows) && <PaginationWrapper>
        <Pagination defaultCurrent={1} pageSize={10} total={totalRows} onChange={onPagination} showSizeChanger={false} />
      </PaginationWrapper>}
    </B2BLayout>
  )
}
