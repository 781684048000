import {
  MENU_DATA,
  MENU_LOADING,
  MENU_FAILED,
  ROLES_FAILED,
  ROLES_LOADING,
  ROLES_DATA
} from './actions'
import { MenuState } from './interfaces'

const initialState: MenuState = {
  data: [],
  isLoading: false,
  error: null,
  roles: [],
  rolesLoading: false,
  rolesError: null,
}

export const menuEditor = (
  state = initialState,
  {type, payload}
): MenuState => {
  switch (type) {
    case MENU_DATA:
      return {
        ...state,
        isLoading: false,
        data: payload,
        error: ''
      }
    case MENU_LOADING:
      return {
        ...state,
        isLoading: true,
        data: [],
        error: null,
      }
    case MENU_FAILED:
      return {
        ...state,
        isLoading: false,
        data: payload,
      }
    case ROLES_DATA:
      return {
        ...state,
        rolesLoading: false,
        roles: payload,
        rolesError: '',
      };
    case ROLES_LOADING:
      return {
        ...state,
        rolesLoading: true,
        roles: [],
        rolesError: null,
      };
    case ROLES_FAILED:
      return {
        ...state,
        rolesLoading: false,
        roles: [],
        rolesError: payload,
      };
    default:
      return state
  }
}
