import React, { useState, useCallback, FC, useEffect } from 'react'
import NavBar from 'antd-mobile/lib/nav-bar'
import Popover from 'antd-mobile/lib/popover'
import Icon from 'antd-mobile/lib/icon';

import { IHeaderBlock } from './interface'
import { PopoverIconWrapper } from './styles';

export const HeaderBlock: FC<IHeaderBlock> = (props) => {
  const { leftContent, popoverOverlay, show, onAfterClose } = props
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (typeof show !== 'undefined') {
      setVisible(show)

      if (show === false) {
        onAfterClose()
      }
    }
  }, [onAfterClose, show])

  const handleVisibleChange = useCallback((visibleState: boolean) => {
    setVisible(visibleState)
  }, [])

  return (
    <NavBar
      mode="light"
      leftContent={leftContent}
      rightContent={(
        <Popover
          mask
          visible={visible}
          overlay={popoverOverlay}
          onVisibleChange={handleVisibleChange}
        >
          <PopoverIconWrapper>
            <Icon type="ellipsis" />
          </PopoverIconWrapper>
        </Popover>
      )}
    />
  )
}
