import dayjs from "dayjs";

const months = {
    'Январь': 0,
    'Февраль': 1,
    'Март': 2,
    'Апрель': 3,
    'Май': 4,
    'Июнь': 5,
    'Июль': 6,
    'Август': 7,
    'Сентябрь': 8,
    'Октябрь': 9,
    'Ноябрь': 10,
    'Декабрь': 11,
}

export const isDateInRange = (targetDate: string, selectedMonths: string[], selectedYears: string[]) => {
    if (selectedMonths.length === 0 && selectedYears.length === 0) {
        return true;
    }
    const target = dayjs(targetDate);
    const targetYear = String(target.year());

    if (selectedMonths.length === 0 && selectedYears.length !== 0) {
       return selectedYears.includes(targetYear);
    }

    const targetMonth = target.month();
    //@ts-ignore
    const mapM: number[] = selectedMonths.map((m) => months[m]);
    if (selectedMonths.length !== 0 && selectedYears.length === 0) {
        return mapM.includes(targetMonth);
    }

     return mapM.includes(targetMonth) && selectedYears.includes(targetYear);
}
