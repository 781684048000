import React, { useRef, useCallback } from 'react'
import { Modal } from 'antd'
import { DefaultJsonForm } from 'src/react-app/components/default-json-form'
import { isFormDataRequired } from 'src/lib/utils/collections'
import { BrowserRouter } from 'react-router-dom'
import { MapContent, MapWrapper } from './styles'

const UISchema = {
  'reason-cancellation': {
    css: {
      gridColumn: '1 / span 1',
      gridRow: '1',
    },
  },
  comment: {
    options: {
      type: 'textarea',
      minRows: 6,
      maxRows: 6,
    },
    css: {
      gridColumn: '1 / span 24',
      gridRow: '2',
    },
  },
}

const selectedSchema = {
  type: 'object',
  required: ['reason-cancellation'],
  properties: {
    'reason-cancellation': {
      type: 'string',
      format: 'platform-collection',
      'collection-name': 'reason-for-cancellation',
      title: 'Причина отмены',
    },/*
    comment: {
      type: 'string',
      title: 'Комментарий',
    },*/
  },
}

export function modalClose(event){
  let target = event.target
  while (Boolean(target?.tagName) === true) {
    if (target?.tagName.toLowerCase() === 'div') {
      break
    }
    target = target?.parentNode
  }
  try{
    Array.from(target?.children as any[])[0].click()
    return true
  }catch{
    return false
  }
}

function modalOrderCancellation(): Promise<any> {
  let formData = {}
  return new Promise(resolve => Modal.confirm({
    icon: null,
    title: 'Отмена обращения',
    content: <Content onChange={value => { formData = value }} />,
    onCancel: () => resolve(null),
    okButtonProps: { 
      onClick: (event) => {
        const isRequired = isFormDataRequired(formData, selectedSchema)
        if(isRequired){
          resolve(formData)
          modalClose(event)
        }
      }
    }
  }))
}

function Content({ onChange }){
  const formDataRef =  useRef({})
  const localOnChange = useCallback(({ formData }) => {
    formDataRef.current = formData
    onChange(formData)
  }, [onChange])

  return (
    /* @ts-ignore */
    <BrowserRouter>
    <MapContent>
      <MapWrapper>
        <DefaultJsonForm
          formData={formDataRef.current}
          schema={selectedSchema}
          onChange={localOnChange}
          UISchema={UISchema}
        />
      </MapWrapper>
      </MapContent>
    </BrowserRouter>
  )
}

export default modalOrderCancellation