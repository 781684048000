import { parse } from 'query-string'

interface ILocation {
  pathname: string,
  search?: string,
  hash?: string,
}

export function getRequestedQueryFromLocation(location: ILocation): string {
  let result: string = `requested_uri=${encodeURI(location.pathname)}`
  if (location.search) {
    // Removes first symbol from search string (e.g. from `?a=1`)
    result += `&requested_query=${encodeURIComponent(location.search.substr(1))}`
  }
  if (location.hash) {
    // Removes first symbol from hash string (e.g. from `#qwe`)
    result += `&requested_hash=${encodeURIComponent(location.hash.substr(1))}`
  }
  return result
}

/**
 * Example:
 * - input: ?requested_uri=/admin/collections&requested_query=id%3D12&requested_hash=meta%3D1
 * - output: /admin/collections?id=12#meta=1
 * 
 * @param query 
 */
export function getLocationFromRequestedQuery(query: string): string {
  const parsed = parse(query)
  let result
  if (parsed['requested_uri']) {
    result = parsed['requested_uri']
    if (parsed['requested_query']) {
      result += '?' + parsed['requested_query']
    }
    if (parsed['requested_hash']) {
      result += '#' + parsed['requested_hash']
    }
  } else {
    result = '/'
  }
  return result
}