import React, { useState, useEffect, useCallback }  from 'react'
import dayjs from 'dayjs'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Table } from 'antd'

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  tablePayment => tablePayment,
  cardName => cardName
)

function formatCurrency(val){
  return (val || 0).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB', currencyDisplay: 'symbol'}).replace("₽", "")
}

function priceFormat(price){
  if (Array.isArray(price)) {
      return price.join('')
  }
  if(Number.isFinite(price) === false) {
    return 0
  }
  if (price < 0) {
    return `Возврат: `+ formatCurrency(price)
  }
  return `Оплата: `+ formatCurrency(price)
}

const columns: Array<object> = [
  {
    title: 'Сумма',
    dataIndex: 'price',
    key: 'price',
    render: text => priceFormat(text),
  },
  {
    title: 'Дата',
    dataIndex: 'date',
    render: text => text && dayjs(text).format('DD.MM.YYYY'),
  },
  {
    title: 'Тип',
    dataIndex: 'ag2e',
    key: 'ag2e',
  },
]

function TablePayments({ targetId }) {
  const { tablePayment, cardName  } = useSelector(createAppealsCurrentStep)
  const [data, setData] = useState([])

  const getData = useCallback(async ({ targetId }) => {
    const result2 = await fetchAPI(`/api/web-hooks/add-payment-contract`, { method: 'POST',  body: JSON.stringify({
      'targetId': Number(targetId),
      pay: {
        sum: 0,
        date: ''
      }
    })})
    const payments = getDataOfType(result2, 'data.attributes.payments', Array, [])
      .map(item => ({ price: item['price'], ...item, 'ag2e': 'безналичные'}))
    const sum = getDataOfType(result2, 'sum', Number, 0)
    const paymentsSum = getDataOfType(result2, 'paymentsSum', Number, 0)
    const paymentsSumAll = getDataOfType(result2, 'paymentsSum', Number, 0)
    if (paymentsSum === paymentsSumAll) {
      payments.push({price: [`Задолженность: ${formatCurrency((sum - paymentsSum))}`]})
    }
    if (paymentsSum !== paymentsSumAll) {
      payments.push({price: [`Задолженность: 0`]})
    }
    console.log({payments})
    setData(payments)
  }, [])

  useEffect(() => {
    if (targetId !== null) {
      getData({ targetId })
    }
  }, [getData, targetId, tablePayment])

  return ['order', 'measurement'].includes(cardName) ? <Table columns={columns} dataSource={data} pagination={false} /> : null
}


export default TablePayments
