import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { objectMap } from 'src/lib/utils/get-table-transform-schema-and-form-data'

const nameTasks = 'tasks'

export async function UpdateTaskIsStage({ parentId, stage, formData }) {
  return true
}

export async function addTask(body) {
  const result = await fetchAPI(`/api/tasks`, {
    method: 'POST',
    body: JSON.stringify(body)
  })
  return getDataOfType(result, 'data.id', [Number, String], null)
}

export async function objectUpdate({ id, formData }) {
  const responseObject = await fetchAPI(`/api/collections/objects/${id}`)
  const schema = getDataOfType(responseObject, 'data.data.metadata.schema', [String, Array], '')
  const objectName = getDataOfType(responseObject, 'data.data.name', String, '')
  const objectTitle = getDataOfType(responseObject, 'data.data.title', String, '')
  const prevFormData = getDataOfType(responseObject, 'data.data.attributes', Object, {})

  const nextFormData = { ...prevFormData, ...formData }
  const requestBody = {
    data: {
      name: objectName,
      title: objectTitle,
      attributes: nextFormData,
      metadata: { schema: schema }
    }
  }

  const result = await fetchAPI(`/api/collections/-/objects/${id}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  })

  const updated = getDataOfType(result, 'data.updated-at', [String, Number], null)
  if (updated === null) {
    console.warn('Ошибка обновления записи')
    return null
  }

  return true
}

export async function createClientCard({ formData }) {
  const schemaName = 'client-card'
  const newFormData = {
    'name': formData['name'],
    'telephone': formData['telephone'],
    'additional-contact': formData['additional-contact'],
  }

  const requestBody = {
    'parent_id':  0,
    'data': {
      'name': newFormData['name'],
      'attributes': newFormData,
      'metadata': {
        'schema': schemaName,
      },
    },
  }

  const result = await fetchAPI(`/api/collections/${nameTasks}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
  return getDataOfType(result, 'data.id', [Number, String], null)
}

export async function createObject({ formData, parentId, collectionName, owner_organization }: any) {
  const requestBody = {
    'parent_id': parentId || 0,
    'data': {
      'name': '',
      'attributes': formData,
      'metadata': {
        'schema': collectionName,
      },
    },
  }

   if(owner_organization){
    const result = await fetchAPI(`/api/collections/${collectionName}?organization=${owner_organization}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })
    return getDataOfType(result, 'data.id', [Number, String], null)
   }

  const result = await fetchAPI(`/api/collections/${collectionName}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
  return getDataOfType(result, 'data.id', [Number, String], null)
}

export async function getObjectFirstId({ parentId, collectionName }){
  const result = await fetchAPI(`/api/collections/${collectionName}/?parentId=${parentId}&access_key=axioma`)
  return getDataOfType(result, 'data.data[0].id', [Number, String], null)
}

export async function getObjectFirst({ collectionName, parentId }) {
  const result = await fetchAPI(`/api/collections/${collectionName}/?parentId=${parentId}&access_key=axioma`)
  return getDataOfType(result, 'data.data[0]', Object, null)
}

export function UISchemaDisabled(UISchema){
 const options = {
    disabled: true
  }
  return objectMap(UISchema, ([key, value]) => {
    return [key, { ...value, options }]
  })
}

export function addValidateFieldsUISchema(UISchema, fields){
  return objectMap(UISchema, ([key, value]) => {
    return [key, { ...value, required: fields.includes(key) }]
  })
}


export function filterRoles(currentRoles, fieldRoles){
  return fieldRoles.filter(role => currentRoles.includes(role))
}

function itemAccessUISchema(key, value, access, sessionData){
  const currentRoles = getDataOfType(sessionData, 'roles', Array, [])
  const fieldRoles = getDataOfType(access, [key, 'roles'], Array, [])
  const roles = filterRoles(currentRoles, fieldRoles)
  const display = roles.length ? getDataOfType(access, [key, 'access'], String, 'display-only') : 'hidden'
  const options = getDataOfType(value, 'options', Object, {})
  if(display === 'hidden'){
    return {
      ...value,
      hidden: true,
      options: { ...options, disabled: false }
    }
  }
  if(display === 'display-only'){
    return {
      ...value,
      hidden: false,
      options: { ...options, disabled: true }
    }
  }
  // allow-change
  return {
    ...value,
    hidden: false,
    options: { ...options, disabled: false }
  }
}

function privilegeEscalationConcat(prevItem, item) {
  const concatAccess = [prevItem['access'], item['access']]
  if (concatAccess.includes('allow-change')) {
    return {
      'field': prevItem['field'],
      'access': 'allow-change'
    }
  }
  if (concatAccess.includes('display-only')) {
    return {
      'field': prevItem['field'],
      'access': 'display-only'
    }
  }
  return {
    'field': prevItem['field'],
    'access': 'hidden'
  }
}

function privilegeEscalation(access) {
  // Повышаем привелегии на поле по самой привелегированной роли
  const nextAccess = [] as any[]
  access.forEach(item => {
     const nextAccessItem = nextAccess.find(object => object?.['field'] === item?.['field'])
     if (nextAccessItem === undefined) {
       nextAccess.push(item)
       return
     }
     Object.assign(nextAccessItem, privilegeEscalationConcat(nextAccessItem, item))
  })
  return nextAccess
}

export function accessRemoveDublicate(access, sessionData){
  const currentRoles = getDataOfType(sessionData, 'roles', Array, [])
  // Удаляем все записи о правах в которых нет текущих ролей
  const nextAccess = access.filter( item => {
    const fieldRoles = getDataOfType(item, 'roles', Array, [])
    const roles = filterRoles(currentRoles, fieldRoles)
    return Boolean(roles.length)
  })
  return privilegeEscalation(nextAccess)
}

export function accessUISchema(UISchema, access, sessionData){
  const accUnic = accessRemoveDublicate(access, sessionData)
  const accessObject = Object.fromEntries(
    accUnic.map(item => [item['field'], item])
  )
  return objectMap(UISchema, ([key, value]) => {
    return [key, itemAccessUISchema(key, value, accessObject, sessionData)]
  })
}

export function formDataDifferenceFilesS3 (prev, next, JSONSchema) {
  const properties = getDataOfType(JSONSchema, 'properties', Object, null)
  if (properties === null) {
    return []
  }
  return Object.entries(properties)
    .filter(([, value]: any) => (value['type'] === 'string') && (value['format'] === 'file-s3'))
    .filter(([key]) => {
      if ((key in prev) !== (key in next)) {
        return true
      }
      if ((key in prev) === (key in next) && (prev[key] !== next[key])) {
          return true
      }
      return false
  })
}

export function isFormDataDifferenceFilesS3 (prev, next, JSONSchema) {
  return Boolean(formDataDifferenceFilesS3(prev, next, JSONSchema).length)
}
