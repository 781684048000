import React from 'react'

const IconComponent = ({ iconName }: { iconName?: string }) => {
  if (!iconName) {
    return null
  }
  const Icon = require('@ant-design/icons')[iconName]
  if (Icon) {
    return <Icon height={150} width={150} />
  }
  const AdditionalIcon = require('../../assets/icons')[iconName]
  if (AdditionalIcon) {
    return <AdditionalIcon height={150} width={150} />
  }
  return null
}

export default IconComponent