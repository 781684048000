import {
  setMenuLoading,
  setMenuFailed,
  setMenuData, setRolesLoading, setRolesFailed, setRolesData,
} from './actions'
import fetchAPI from '../../../lib/utils/fetch-api'

export function getMenuData() {
  return async dispatch => {
    dispatch(setMenuLoading())
    try {
      const response = await fetchAPI('/api/admin/menu')
      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setMenuFailed(error))
      }
      return dispatch(setMenuData(response || []))
    } catch (err) {
      return dispatch(setMenuFailed(err as string))
    }
  }
}

export function saveMenuData(menuData: any) {
  return async dispatch => {
    dispatch(setMenuLoading())
    try {
      const response = await fetchAPI('/api/admin/menu', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(menuData),
      })

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setMenuFailed(error))
      }

      return dispatch(setMenuData(response.data))
    } catch (err) {
      return dispatch(setMenuFailed(err as string))
    }
  }
}

export function getRolesData() {
  return async dispatch => {
    dispatch(setRolesLoading())
    try {
      const response = await fetchAPI('/api/roles/list')
      if (response.errors) {
        let error = response.errors
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setRolesFailed(error))
      }
      return dispatch(setRolesData(response || []))
    } catch (err) {
      return dispatch(setRolesFailed(err as string))
    }
  }
}
