export const measurementEditDateModalSchema = [
    {
        name: "date",
        title: "Дата скорректированная",
        value: null,
        required: true,
        input: {
            type: "string",
            format: "date",
        },
        options: {
            isDisabledDatePrev: true,
        },
    },
    {
        name: "time",
        title: "Время скорректированное",
        value: null,
        required: true,
        input: {
            type: "string",
            format: "time-range",
        },
    },
    {
        name: "comment",
        title: "Комментарий",
        value: '',
        required: false,
        input: {
            type: "string",
        },
        options: {
            type: "textarea",
            minRows: 3,
            maxRows: 3
        },
    },
]
