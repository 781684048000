import styled from "styled-components";

export const BackToMeasurementsList = styled.a`
  font-size: 19px;
  color: #404040;
  padding: 21px 0;
  text-align: left;
`

export const PhoneButton = styled.a`
  display: inline-flex;
`

export const FieldGroup = styled.div``

export const FieldName = styled.span`
  font-weight: bold;

  margin-right: 10px;
`;

export const FieldContent = styled.div`
  display: flex;

  margin: 10px 0;
`

export const ActionListItemText = styled.span`
  white-space: nowrap;
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  a {
    width: 100%;
  }

  .am-button span {
    font-size: 14px;
  }
`
