import React, { useEffect, useState, useMemo } from 'react'
import { Table } from 'antd'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import dayjs from 'dayjs'
import { DarkLink } from 'src/react-app/components/dark-link'

function CellId({ id, props }) {
  const url = useMemo(() => {
    if (Number.isInteger(props['order_id'])) {
      return `/order/${props['order_id']}/edit`
    }
    return `/case/${id}/edit`
  }, [props, id])

  return <DarkLink to={url}>{id}</DarkLink>
}

function formatCurrency(value){
  return (value || 0).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', currencyDisplay: 'symbol' }).replace("₽", "")
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'parent_id',
    key: 'parent_id',
    render: (id, props) => <CellId id={ id } props={ { ...props, order_id: Number(props.id) } }/>,
  },
  {
    title: 'Стадия',
    dataIndex: 'stage',
    key: 'stage',
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    render: text => dayjs(text).format('DD.MM.YYYY HH:mm'),
  },
  {
    title: 'Сумма',
    dataIndex: 'sum',
    key: 'sum',
    render: val => formatCurrency(val)
  },
  {
    title: 'Номер производственного заказа',
    dataIndex: 'production_number',
    key: 'production_number',
  },
  {
    title: 'Адрес объекта',
    dataIndex: 'address',
    key: 'address',
  },

]

function TabGeneral({ clientId }){
  const [data, setData] = useState([])
  useEffect(() => {
    (async () => {
      const result = await fetchAPI(`/api/orders-client/${clientId}`).then(items => ({ data: items }))
      const array = getDataOfType(result, 'data', Array, [])
      setData(array)
    })()

  }, [clientId])

  return <Table dataSource={data} columns={columns} pagination={false} key='id' />
}

export default TabGeneral
