import React, { useState, useCallback, useEffect, useMemo, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import Card from 'antd-mobile/lib/card';
import WingBlank from 'antd-mobile/lib/wing-blank';
import WhiteSpace from 'antd-mobile/lib/white-space';

import { formDataToUrlParams, getStagesGroup } from '../../list-orders/utils'
import { B2BMobile } from '../../../layouts/b2b-mobile'
import { fetchSchema, formatListData } from '../../../../lib/utils/list'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import { dateFNS } from '../../../utilits'
import { CardTitle } from './styles'
import { collectionsName, deliverySortData, deliverySortList } from './consts'
import { ErrorsList, PagePagination, pageSize, HeaderListFilters } from '../components'

export const DeliveryListPage = () => {
  const history = useHistory()
  const [dataSource, setDataSource] = useState<any[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [stagesList, setStagesList] = useState<Record<string, any>[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [stageSelected, setStageSelected] = useState<string[]>([])

  const getData = useCallback(async (value, addUrlParams?: string) => {
    const urlParams = formDataToUrlParams(value)
    const pageErrors: string[] = []
    const stageCodeParam = stageSelected.length > 0 && stageSelected[0] ? `stage-code=${stageSelected[0]}` : ''
    const [selectedSchema, metadata, collections, stages] = await Promise.all([
      fetchSchema({ name: collectionsName }),
      fetchAPI(`/api/collections/${collectionsName}/metadata`),
      fetchAPI(`/api/collections-case/${collectionsName}?access_key=axioma&${urlParams}&${addUrlParams}&${stageCodeParam}`),
      getStagesGroup('delivery')
    ])

    const collectionsResultData = getDataOfType(collections, 'data.data', Array, [])
    const isMetadata = getDataOfType(metadata, 'data', Object, null)
    if(isMetadata === null) {
      pageErrors.push('Некорректный ответ сервера при получении метаданных коллекции')
    }
    const rawDataSource: any[] = formatListData(collectionsResultData, selectedSchema)
    const sortedDataSource: any[] = []
    rawDataSource.forEach(item => {
      const stageCode = stages[item['stage']] ? stages[item['stage']]['code'] : 'other'

      if (deliverySortData[stageCode]) {
        deliverySortData[stageCode].push(item)
      } else {
        deliverySortData['other'].push(item)
      }
    })
    deliverySortList.forEach(key => {
      sortedDataSource.push(...deliverySortData[key])
      deliverySortData[key] = []
    })
    const newTotalRows = getDataOfType(collections, 'data.total-rows', Number, 0)

    setStagesList(stages)
    setTotalRows(newTotalRows)
    setDataSource(sortedDataSource)
    setPageErrors(pageErrors)
    setIsDataLoading(false)
  }, [stageSelected])

  useEffect(() => {
    getData({}, `page=1&pageSize=${pageSize}`)

    const timer = setInterval(() => getData({}, `page=1&pageSize=${pageSize}`), 3000)

    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageSelected])

  const handleFilterChange = useCallback((value) => {
    setStageSelected(value)
  }, [])

  const handleOpenEditDelivery = useCallback((id: number) => () => history.push(`/delivery/${id}/edit`), [history])

  const getStage = useCallback(({ stage = 0 }) => {
    let result = stagesList[stage]

    if ('name' in result) {
      result = result['name']
    }

    return result
  }, [stagesList])

  const parseDate = useCallback((date) => {
    return date ? dateFNS.format(new Date(date), 'dd.MM.yyyy hh:mm') : ''
  }, [])

  const getContent = useCallback(({ name, 'address-object': address, city }) => (
    <>
      <div>
        {name}
      </div>
      {address && (
        <div>{`${city}, ${address}`}</div>
      )}
    </>
  ), [])

  const stagesFilterList = useMemo(() => {
    return stagesList.length > 0 ? stagesList.map(stage => ({ label: stage['name'], value: stage['code']})) : []
  }, [stagesList])

  return (
    <B2BMobile>
      <HeaderListFilters
        onChangeStage={handleFilterChange}
        stageSelected={stageSelected}
        stagesFilterList={stagesFilterList}
        stagesList={stagesList}
      />
      <WhiteSpace size="lg" />
      <ErrorsList pageErrors={pageErrors} />
      <WhiteSpace size="lg" />
      {isDataLoading && (<div>Loading...</div>)}
      <WingBlank size="lg">
        {!isDataLoading && dataSource && dataSource.length > 0 && dataSource.map(data => (
          <Fragment key={`delivery-list-item-${data['id']}`}>
            <Card onClick={handleOpenEditDelivery(data['id'])}>
              <Card.Header
                title={<CardTitle>{data['parent-id']}: {getStage(data)}</CardTitle>}
                extra={<CardTitle>{parseDate(data['date-shipping'])}</CardTitle>}
              />
              <Card.Body>
                <div>{getContent(data)}</div>
              </Card.Body>
              <Card.Footer content={data['telephone']} extra={<div>{/*extra footer content*/}</div>} />
            </Card>
            <WhiteSpace size="lg" />
          </Fragment>
        ))}
      </WingBlank>
      <WingBlank>
        <PagePagination
          current={currentPage}
          setCurrent={setCurrentPage}
          onDataLoad={getData}
          onSetIsDataLoading={setIsDataLoading}
          totalRows={totalRows}
        />
      </WingBlank>
    </B2BMobile>
  )
}
