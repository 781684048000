import React, {memo, Suspense, useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {UploadFiles} from '../../../../../components/v2/upload-files'
import {deleteTimeLineItem} from '../../../../../store/timeline/actions'
import {
    ActivityTimelineContextProps,
    ActivityTimeline,
    ActivityTimelineContext,
    selectTimelineIsLoading,
    selectTimelineError,
    selectTimelineFiles
} from '../../../../../components/v2/activity-timeline'


interface AppealsFilesTimelineProps {
  className?: string
  id: string | number
}

export const AppealFiles: React.FC<AppealsFilesTimelineProps> = memo((props: AppealsFilesTimelineProps) => {
  const {id, className} = props
  const dispatch = useDispatch()
  const timelineIsLoading = useSelector(selectTimelineIsLoading)
  const timelineError = useSelector(selectTimelineError)
  const timelineFiles = useSelector(selectTimelineFiles)
  const entityType = 'case'

  const deleteAppealsTimelineItem = useCallback((activityItem) => {
      dispatch(deleteTimeLineItem(activityItem))
  }, [])

  const defaultProps = useMemo(
    (): ActivityTimelineContextProps => ({
      card_id: id,
      card_type: entityType,
      deleteTimelineItem: deleteAppealsTimelineItem,
    }),
    [id],
  )

  return (
    <>
      <Suspense fallback={''}>
        <UploadFiles
          entityType={entityType}
          entityId={id}
          url={`/api/${entityType}/${id}/file`}
          method='PUT'
        />
      </Suspense>
      <br/>
      <Suspense fallback={''}>
        <ActivityTimelineContext.Provider value={defaultProps}>
          <ActivityTimeline
            isLoading={timelineIsLoading}
            items={timelineFiles}
            error={timelineError}
            className={className}
            emptyMessage={'Нет прикрепленных файлов'}
          />
        </ActivityTimelineContext.Provider>
      </Suspense>
    </>
  )
})
