import React, {useState} from 'react'
import {ChatWrapper, ChatBody, ChatFooter, MessageRow, MessageText, MessageDate} from './styles'
import {IChatProps} from './interface'
import {Button, Form, Input} from 'antd';
import {SendOutlined} from '@ant-design/icons'
import dayjs from "dayjs";

function AppChat({data, form, onSubmit}: IChatProps) {
  const [disabledBtn, setDisabledBtn] = useState(true);

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    setDisabledBtn(!values?.message || values?.message === '');
  }

  return (
    <ChatWrapper>
      <ChatBody>
        {data.map((item) => {
          return <MessageRow left={!!item.dealer_code}>
            <MessageText incoming={!!item.dealer_code}>
              {item.msg}
              <MessageDate>
                {dayjs(item.created_at).format('DD.MM.YYYY HH:mm')}
              </MessageDate>
            </MessageText>
          </MessageRow>
        })}
      </ChatBody>
      <ChatFooter>
        <Form
          onFinish={onSubmit}
          autoComplete="off"
          onFieldsChange={handleFormChange}
          form={form}
        >
          <Form.Item
            name="message"
            style={{marginBottom: 12}}
          >
            <Input.TextArea/>
          </Form.Item>

          <Form.Item style={{marginBottom: 0, textAlign: 'right'}}>
            <Button type="primary" htmlType="submit" disabled={disabledBtn}>
              <SendOutlined/>
              Отправить
            </Button>
          </Form.Item>
        </Form>
      </ChatFooter>
    </ChatWrapper>
  )
}

export default AppChat

