// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// Source: https://reactrouter.com/web/example/auth-workflow
import { Redirect, Route } from 'react-router-dom'
import { getRequestedQueryFromLocation } from '../../lib/utils/requested-query'
import React from 'react'

export const PrivateRoute = ({ children, session, ...rest }) => {
  const isAuthorizedSession = (session) => {
    return (
      session.sessionData
      && session.sessionData.user
      && (session.sessionData.user['id'] > 0)
      && (session?.sessionData?.roles && session?.sessionData?.roles.length > 0)
    )
  }

  return (
    /* @ts-ignore */
    <Route
      {...rest}
      render={({ location }) =>
        (isAuthorizedSession(session)) ? (
          children
        ) : (
          /* @ts-ignore */
          <Redirect
            to={{
              pathname: `/login`,
              search: `?${getRequestedQueryFromLocation(location)}`,
              // state: { from: location }
            }}
          />
        )
      }
    />
  );
}
