import React, { useRef, useEffect, useCallback } from 'react'
import { Input } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'

const _fn = () => null

function InputDescription({ value, placeholder = 'Описание', onChange, handleShowEdit = _fn }) {
  const ref = useRef<any>(null)
  useEffect(()=> {
    const refType = typeof getDataOfType(ref, 'current.input.value', String, null)
    if(refType === 'string'){
      ref.current.input.value = value
    }
  }, [value])
// eslint-disable-next-line
  const localOnChange = useCallback(debounce(() => {
    const refType = typeof getDataOfType(ref, 'current.input.value', String, null)
    if(refType === 'string'){
      onChange(ref.current.input.value)
    }
  }, 500), [onChange])

  return (
    <Input
      ref={ref}
      addonAfter={<EditOutlined onClick={handleShowEdit} />}
      placeholder={placeholder}
      onChange={localOnChange}
      defaultValue={value}
    />
  )
}

export default InputDescription