import React, {useCallback, useEffect, useState} from 'react'
import {Input, Space} from 'antd'
import SelectFromEnum from 'src/react-app/components/select-from-enum'
import InputDateRange from 'src/react-app/components//input-date-range'
import {dateRange, status} from './utils'
import fetchAPI from '../../../../lib/utils/fetch-api'


function setEnumSchemaSelect(prop, oneOf, enumValues) {
  Object.assign(prop, {
    oneOf: oneOf,
    'enum': Object.keys(enumValues),
    view: {'name': 'select-from-enum'}
  })
}

function InputFilter({onChange, placeholder, code}) {
  const onPressEnter = useCallback(({target}) => {
    onChange(target?.value, code)
  }, [onChange])
  const onLocalChange = useCallback(({target}) => {
    const value = target?.value
    if (typeof value !== 'string' || value === '') {
      onChange(undefined, code)
    }
  }, [onChange])
  const onSearch = useCallback((value) => {
    onChange(value, code)
  }, [onChange])
  return <Input.Search placeholder={placeholder} onPressEnter={onPressEnter} onChange={onLocalChange}
                       onSearch={onSearch} allowClear style={{width: 200}}/>
}

function Filter({onChange}) {
  const [formData, setFormData] = useState<any>(() => ({
    'filter[date_application]': undefined,
    'filter[type_appeal]': undefined,
    'filter[complaint_decision]': undefined,
    'filter[confirmation_form]': undefined,
    'filter[guilty_division]': undefined,
    'filter[payer]': undefined,
    'filter[num_tech]': undefined,
  }))

  const [schemaStatus, setSchemaStatus] = useState<any>({})

  const [UISchemaSelectFromEnumStatus] = useState({
    options: {width: 180, placeholder: 'Статус', allowClear: true, showSearch: true,}
  })

  useEffect(() => {
    const getData = async () => {
      await fetchAPI('/api/claims_request/enum')
        .then(result => {
          const enumItems = result?.['data'] || {}
          Object.keys(enumItems).forEach(code => {
            const enumValues = result?.['data']?.[code] || {}
            const oneOf: Record<string, string>[] = []

            Object.keys(enumValues).forEach(enumValue => {
              return oneOf.push({
                'const': enumValue,
                'title': enumValues[enumValue]
              })
            })

            if (code === 'status') {
              setEnumSchemaSelect(status, oneOf, enumValues)
              setSchemaStatus(status)
            }
          })
        })
    }
    getData()
  }, [])

  const onChangeLocal = useCallback((value, name) => {
    const nextformData = {}
    setFormData(prevFormData => Object.assign(nextformData, prevFormData, {[name]: value ? encodeURIComponent(value) : value}))
    onChange(nextformData)
  }, [onChange])

  const onChangeDateRange = useCallback(value => {
    onChangeLocal(value, 'filter[date_application]')
  }, [onChangeLocal])
  const onChangeStatus = useCallback(value => {
    onChangeLocal(value, 'filter[status]')
  }, [onChangeLocal])
  const onChangeFilter = useCallback((value, code) => {
    onChangeLocal(value, `filter[${code}]`)
  }, [onChangeLocal])

  return (
    <Space wrap>
      <InputDateRange
        value={formData['date_application']}
        schema={dateRange}
        onChange={onChangeDateRange}
      />
      <SelectFromEnum
        value={formData['status']}
        schema={schemaStatus}
        onChange={onChangeStatus}
        UISchema={UISchemaSelectFromEnumStatus}
      />
      <InputFilter onChange={onChangeFilter} placeholder="Код дилера" code="dealer_code"/>
      <InputFilter onChange={onChangeFilter} placeholder="Адрес объекта" code="address"/>
      <InputFilter onChange={onChangeFilter} placeholder="ФИО" code="fio"/>
      <InputFilter onChange={onChangeFilter} placeholder="Телефон" code="phone"/>
      <InputFilter onChange={onChangeFilter} placeholder="Классификация" code="classification"/>
      <InputFilter onChange={onChangeFilter} placeholder="Суть претензии" code="claim"/>
      <InputFilter onChange={onChangeFilter} placeholder="Мероприятия дилера" code="dealer_event"/>
      <InputFilter onChange={onChangeFilter} placeholder="Требования дилера" code="dealer_requirement"/>
      <InputFilter onChange={onChangeFilter} placeholder="Номер тех. задания" code="num_tech"/>
    </Space>
  )
}

export default Filter