import React, {memo, useCallback, useRef} from 'react';
import {Modal} from 'antd';
import {DefaultJsonForm} from "../../../../../components/v2/default-json-form";
import {measurementEditDateModalSchema} from "../../schema/measurement-edit-date-modal-schema";
import {convertData2JsonForm} from "../../../../../shared/lib/convertData2JsonForm";
import {isFormDataRequired} from "../../../../../../lib/utils/collections";
import {setMeasurementEditDate} from "../../api/setMeasurementEditDate";
import {MeasurementFormI} from "../../types/types";
import {useAutoCloseModal} from "../../../../../hooks/useAutoCloseModal";
import moment from "moment";

interface MeasurementEditDateModalProps {
    id: string | number,
    centered?: boolean,
    onChange?: () => void;
    onCancel?: () => void,
    isOpen?: boolean
}


export const MeasurementEditDateModal: React.FC<MeasurementEditDateModalProps> = memo(
    (props: MeasurementEditDateModalProps) => {
        const {
            onChange,
            centered,
            id,
            onCancel,
            isOpen,
            ...otherProps
        } = props;
        const {resultObject, resultSchema} = convertData2JsonForm(measurementEditDateModalSchema);
        const form = useRef<MeasurementFormI>(resultObject as MeasurementFormI);
        const [isSuccess, setIsSuccess] = useAutoCloseModal(onCancel)


        const changeFormData = useCallback(async ({formData}) => {
            const isFilesEdit = JSON.stringify(formData) === JSON.stringify(form.current);
            if (isFilesEdit) {
                return;
            }
            form.current = {...formData}
        }, [])


        const handleOk = useCallback(async () => {
            if (!form.current) {
                return;
            }

            const controller = new AbortController();
            const data = {
                ...form.current,
                date: moment(form.current.date).format('YYYY-MM-DD'),
                time: form.current.time,
                id
            }
            const isRequired = isFormDataRequired(data, resultSchema)

            if (!isRequired) {
                Modal.warning({
                    title: 'Предупреждение',
                    content: 'Заполните все обязательные поля'
                })
                return
            }

            await setMeasurementEditDate(data, controller.signal)
                .then((response) => {
                    if (response?.error) {
                        Modal.error({
                            title: 'Ошибка',
                            content: `При сохранении скорректированной даты возникла ошибка: ${response?.error}.`
                        })
                        return;
                    }
                    setIsSuccess(true)
                    location.reload()
                }).catch(() =>
                    Modal.error({
                        title: 'Ошибка',
                        content: 'При сохранении скорректированной даты возникла ошибка. Дата не изменена.'
                    })
                )

        }, [id])


        return (
            <Modal
                title={'Изменение даты'}
                onOk={handleOk}
                centered={centered}
                onCancel={onCancel}
                visible={isOpen}
                destroyOnClose={true}
                {...otherProps}
            >
                <DefaultJsonForm
                    formData={form.current}
                    schema={resultSchema}
                    onChange={changeFormData}
                    orientation="horizontal"
                />
            </Modal>
        );
    });

