// eslint-disable-next-line
export default {
  colors: {
    white: '#ffffff',
    black: '#202124',
    darkBlack: '#252525',
    bg: '#efefef',
    main: '#24292e',
    grayDark: '#434343',
    grayDark2: '#333333',
    grayMedium: '#b8b9b9',
    grayMediumOpacity: 'rgba(184,185,185,0.6)',
    grayExtraSmallOpacity: 'rgba(184,185,185,0.1)',
    grayNormal: '#8f8f8f',
    graySubNormal: '#7A7A7A',
    grayLight: '#e0e0e0',
    graySuperLight: '#f3f3f3',
    grayUltraLight: '#E1E1E1',
    grayForBody: '#e5e5e5',
    grayMegaLight: '#c6c6c6',
    grayIcon: '#5f6368',
    green: '#34c759',
    greenSub: '#ebfbed',
    purple: '#af52de',
    subPurple: '#f9effe',
    blue: '#007aff',
    blueSub: '#e7f3ff',
    blueLight: '#5ac8fa',
    blueSubActive: '#2e92ff',
    blueIcon: '#1890ff',
    blueSubLight: '#f0fbff',
    orange: '#ff9500',
    orangeSub: '#fff5e7',
    red: '#ff453a',
    redSub: '#ffeceb',
    yellow: '#ffcc00',
    yellowSubLight: '#fffbe7',
    yellowSub: '#ffca8a',
    violet: '#5856d6',
    violetSub: '#efeffd',
  },
  mediaQueries: {
    desktop: '1279px',
    tablet: '1023px',
    mobile: '767px',
  },
  fontSizes: {
    fs20: '20px',
    fs18: '18px',
    fs16: '16px',
    fs14: '14px',
    fs12: '12px',
  },
  fonts: {
    size: '16px',
    main: `
      "SourceSansPro",
      -apple-system,
      BlinkMacSystemFont,
      Arial,
      "Segoe UI",
      Helvetica,
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol"
    `,
  },
}
