/**
 * Originally based on https://github.com/YMFE/json-schema-editor-visual
 */

import React, { useCallback, useEffect, useMemo } from 'react'
import { Provider } from 'react-redux'
import moox from 'moox'
import JSONSchemaEditor from './editor.js'
import utils from './utils'
import schema from './models/schema'
import setObjects from './models/setObjects'
import PropTypes from 'prop-types'
import fetchAPI from '../../../lib/utils/fetch-api'


const JSONSchemaEditorComponent = (props: any = {}) => {
  const Model = useMemo(() => {
    return moox({
      schema,
      setObjects,
    })
  }, [])

  const updateCollectionsList = useCallback(() => {
    fetchAPI('/api/collections?access_key=axioma&fields=title,description')
      .then(response => {
        const rows = (response['data'] && response['data']['data']) || [];
        Model.setObjects.setCollectionsAction(rows)
      })
      .catch(err => {
        console.error('json-schema-editor/setCollectionsAction', err)
      })
  }, [Model])

  useEffect(() => {
    if (props.lang) {
      utils.lang = props.lang
    }

    if (props.format) {
      Model.__jsonSchemaFormat = props.format
    } else {
      Model.__jsonSchemaFormat = utils.format
    }
    if (props.mock) {
      Model.__jsonSchemaMock = props.mock
    }

    updateCollectionsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const store: any = useMemo(() => {
    return Model.getStore()
  }, [Model])

  const Component = (props) => (
    /* @ts-ignore */
    <Provider store={{ ...store }}>
      <JSONSchemaEditor Model={Model} {...props} />
    </Provider>
  );

  Component.propTypes = {
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    showEditor: PropTypes.bool,
  };

  return Component
}

export default JSONSchemaEditorComponent
