import React, { ReactElement } from 'react'
import { Tooltip } from 'antd'
import { DarkLink } from 'src/react-app/components/dark-link'

const paths = [
  {objectName: 'appeal', path: 'case'},
  {objectName: 'case-order', path: 'order'},
  {objectName: 'client-card', path: 'client'},
  {objectName: 'card-delivery', path: 'delivery'},
  {objectName: 'card-mounting', path: 'mounting'},
  {objectName: 'card-froze', path: 'froze'},
  {objectName: 'card-manufacture', path: 'manufacture'},
  {objectName: 'user-profile', path: 'employee'},
  {objectName: 'contract-client', path: 'contract-client'},
  {objectName: 'card-audit', path: 'audit'},
  {objectName: 'card-complaint', path: 'complaint'},
]

function selectPath(schema){
  const targetSchema = Array.isArray(schema) ? schema[0] : schema
  const index = paths.map(({ objectName }) => objectName).indexOf(targetSchema)

  if (index === -1) {
    return null
  }

  return paths[index].path
}

function EditCard({
  id,
  title,
  schema,
  children,
  fontWeight,
}: { id: any, title: any, schema: any, children: any, fontWeight?: any }) {
  const card = selectPath(schema)
  let result: ReactElement | null = null

  if (card !== null) {
    result = (
      <Tooltip placement="top" title={title}>
        <DarkLink to={`/${card}/${id}/edit`} fontWeight={fontWeight}>
          {children}
        </DarkLink>
      </Tooltip>
    )
  }

  return result
}

export default EditCard

