import React, {useMemo} from 'react'
import { Timeline } from 'antd'
import TimelineItem from './activity-timeline-Item'
import ActivityTimelineContext from './activity-timeline-context'
import moment from 'moment'
import { insertSpaceActivityTimeLine, insertSpaceActivityTimeLineSpace } from './utils'
import './index.css'

function ActivityTimeLine({ actions, data }) {

  const itemsTimeLine = useMemo(() => {
    if(data.actionsItems.length === 0){
      return []
    }
    const currentDate = moment().valueOf()
    const indexCurrentDate = data.actionsItems.findIndex(({ sort }) => sort <= currentDate)
    const correctIndex = indexCurrentDate === -1 ? 0 : indexCurrentDate
    const newArr = insertSpaceActivityTimeLine(data.actionsItems, correctIndex, insertSpaceActivityTimeLineSpace)
    if(correctIndex !== 0){
      newArr.unshift({
        id: Number.MAX_VALUE,
        date: Number.MAX_VALUE,
        type: 'space',
        body: {
          color: '#97c908',
          colorText:  '#FFF',
          text: 'Запланировано'
        }
      })
    }

    // Если по первому ключю warning - удаляет надпись Сегодня
    if (newArr[1].body?.warning)
      newArr[0].body.text = null

    return newArr
  }, [data])

  const contextProps = useMemo(() => {
    return {
      file: {
        deleteFile: actions.deleteFile
      },
      note: {
        editNote: actions.editNote
      },
      task: {
        taskStages: data.taskStages,
        usersProfile: data.usersProfile,
      }
    }
  }, [data, actions])

  return (
    <ActivityTimelineContext.Provider value={contextProps}>
      <Timeline>
        {itemsTimeLine.map((value, index) => (
          <TimelineItem key={index} {...value} index={index} items={itemsTimeLine} />
        ))}
      </Timeline>
    </ActivityTimelineContext.Provider>
  )
}

export default ActivityTimeLine

