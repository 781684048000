import React from 'react'
import {Progress} from "../styles";

export interface CircleProps {
    percent?: number
}

const Circle: React.FC<CircleProps> = (props) => {
    const {percent = 0} = props
    return (
        <>
            <span style={{opacity: 0}}>123</span>
            <svg id="svg" viewBox="0 0 100 100">
                <circle
                    cx="50"
                    cy="50"
                    r="45"
                    fill="#fff"
                    stroke="#ccc"
                />
                <Progress fill="none"
                          $percent={percent}
                          d="M50 10 a 40 40 0 0 1 0 80  a 40 40 0 0 1 0 -80"
                          display={percent === 0 ? 'none' : ''}
                />
                <text x="50" y="50" textAnchor="middle" dy="7" fontSize="18">{percent}%</text>
            </svg>
        </>

    )
}

export default Circle
