import { CloseCircleOutlined, DashOutlined } from '@ant-design/icons'
import { message, Popconfirm, Spin, Upload, UploadProps } from 'antd'
import React, { memo, ReactElement, useCallback, useContext, useMemo, useState } from 'react'
import { ContentWrapper, UploadWrapper, FileButton, FileLink } from './styles'
import { UploadFile } from 'antd/lib/upload/interface'
import DefaultJsonFormContext from '../../components/v2/default-json-form/context/default-json-form-context'
import {caseDeleteFile} from "../../store/appeals/actions"
import {useDispatch} from "react-redux"
import {ActivityTimelineContext} from "../../components/v2/activity-timeline"
import dayjs from "dayjs";
import {deleteTimeLineItem} from "../../store/timeline/actions";

interface UploadFilesProps {
  value?: any,
  schema?: any,
  onChange?: (value) => void,
  UISchema?: any,
}

const UploadFilesS3: React.FC<UploadFilesProps> = (props: UploadFilesProps) => {
  const { UISchema, value, schema, onChange } = props
  // @ts-ignore
  const { entityType, entityId } = useContext(DefaultJsonFormContext)
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const multiple = useMemo(() => Boolean(UISchema?.options?.multiple), [UISchema])
  const required = useMemo(() => Boolean(UISchema?.required || UISchema?.options?.required), [UISchema])
  const [isLoading, setIsLoading] = useState(false)
  const [isBorder, setIsBorder] = useState(false)
  const error = required && (!value || value?.length === 0)
  const dispatch = useDispatch()
  const { deleteTimelineItem } = useContext(ActivityTimelineContext)

  const [fileList, setFileList] = useState(value?.length ? value : [])

  const action = useMemo(() => {
    if (entityType && entityId) {
      if (entityType === 'hr') {
        return `/api/${entityType}/request/${entityId}/add-file`
      } else {
        return `/api/${entityType}/${entityId}/add-file`
      }
    }
    return `/api/v2/upload`
  }, [entityType, entityId])

  const handleOnChange = useCallback((data) => {
    const { file, fileList: newFileList } = data
    const { status, error } = file
    const isDone = status === 'done'

    if (error) {
      message.error(`${file.name} не удалось загрузить`)
      setIsLoading(false)
    }
    if (isDone) {
      message.success(`${file.name} загружен`)
      setIsLoading(false)
      onChange?.(newFileList.map(loadedFile => loadedFile.response?.files?.[0] || loadedFile))
    }

    setFileList(newFileList)
    setIsBorder(Boolean(newFileList?.length))
  }, [onChange])

  const removeHandler = useCallback(async (file) => {
    const id = file.id || file?.response?.files?.[0]?.id
    const newFileList = fileList
      .filter(f => f.response?.files?.[0]?.id !== id && f?.id !== id)
      .map(f => f.response?.files?.[0] || f)

    await dispatch(caseDeleteFile({ id }))

    dispatch(deleteTimeLineItem({
      data: { id }
    }))

    setFileList(newFileList)
    onChange?.(newFileList)
  }, [onChange, dispatch, fileList])

  const defaultProps: UploadProps = useMemo(() => ({
    name: 'file',
    multiple: multiple,
    action: action,
    fileList: fileList,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    data: (_) => ({title: schema?.key || 'file'}),
    itemRender: (
      _originNode: ReactElement,
      file: UploadFile,
    ) => (
      <ContentWrapper disabled={disabled || isLoading} error={error} className='antd-input antd-input-container'>
        <FileLink href={file?.url}>{file.name}</FileLink>
        {!disabled && <Popconfirm title={`Удалить файл ${file.name}?`} onConfirm={() => removeHandler(file)}>
          <CloseCircleOutlined style={{color: '#ff0000'}}/>
        </Popconfirm>
        }
      </ContentWrapper>
    ),
    beforeUpload: () => {
      setIsLoading(true)
    }
  }), [fileList, disabled, multiple, action, isLoading, error, removeHandler])

  return (
    <UploadWrapper className='custom-upload-wrapper' isBorder={true}>
      <Upload {...defaultProps} disabled={disabled} onChange={handleOnChange}>
        <FileButton disabled={disabled || isLoading} className='ant-input'>
          {isLoading ? <Spin size='small'/> : <DashOutlined/>}
        </FileButton>
      </Upload>
    </UploadWrapper>
  )
}

export default memo(UploadFilesS3)
