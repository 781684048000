import styled from 'styled-components'

export const GeneralSpace = styled.div`
  display: flex;
  gap: 50px;
  .ant-layout-sider-collapsed & {
    display: none;
  }
  .ant-form {
    min-width: 270px;
    min-width: 270px;
  }
`