import {useCallback} from "react";
import {isFormDataRequired} from "../../../../../lib/utils/collections";
import {Modal} from "antd";
import {nextStage, updateFormData} from "../../../../store/hr/action-creators";
import {useDispatch} from "react-redux";

export function useHrSaveResultForms () {
    const dispatch = useDispatch();
    const saveResult = useCallback(async (id, visibleFormData, stage, isOpenModal, setIsOpenModal, isNextStage = false) => {
            if (!visibleFormData) {
                return;
            }
            const formData = {
                candidate: {...visibleFormData.candidate},
                request: {...visibleFormData.request},
                is_next_stage: isNextStage,
            }
            const hrId = id !== 'new' && id || '';

            if ((id === 'new' || stage === 0) && !isNextStage) {
                const isRequiredRequest = isFormDataRequired(formData.request, visibleFormData.requestFieldsSchema)
                const isRequiredCandidate = isFormDataRequired(formData.candidate, visibleFormData.candidateFieldsSchema)

                if (!isRequiredCandidate || !isRequiredRequest) {
                    return
                }
            }

            if (isNextStage) {

                const isRequiredCandidate = isFormDataRequired(formData.candidate, visibleFormData.candidateFieldsSchema)
                const isRequiredRequest = isFormDataRequired(formData.request, visibleFormData.requestFieldsSchema)

                if (!isRequiredCandidate || !isRequiredRequest) {
                    Modal.warning({
                        title: 'Предупреждение',
                        content: 'Заполните все обязательные поля'
                    })
                    return
                }

                if (visibleFormData.modal) {
                    const isRequiredModal = isFormDataRequired(visibleFormData.modal, visibleFormData.modalFieldsSchema)
                    if (!isRequiredModal) {
                        if (isOpenModal) {
                            return
                        }
                        setIsOpenModal(true);
                        return
                    }
                    formData.request = {...formData.request, ...visibleFormData.modal};
                }

                dispatch(nextStage(hrId, formData));
            } else {
                dispatch(updateFormData(hrId, formData));
            }
        }, []
    )
    return {
        saveResult,
    }
}
