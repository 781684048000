import React from 'react'
import { Select } from 'antd'
import { IManagerFilterProps } from '../interfaces/interfaces'

const { Option } = Select

const ManagerFilter: React.FC<IManagerFilterProps> = ({
  selectedManagers,
  onManagerChange,
  managers,
}) => {
  return (
    <Select
      mode="multiple"
      allowClear
      placeholder="Все менеджеры"
      value={selectedManagers}
      onChange={onManagerChange}
      style={{ width: '100%' }}
    >
      {managers.map(manager => (
        <Option key={manager.id} value={manager.id}>
          {manager.value}
        </Option>
      ))}
    </Select>
  )
}

export default ManagerFilter
