import React, { useMemo } from 'react'
import { CountBall, Stage } from 'src/react-app/components/activity-timeline/styles'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'

function StageCell(props) {
  const [text, color, fontWeight, fontColor, countValue, countColor, countTitle] = useMemo(() => {
    const stageIndex = getDataOfType(props, 'stage', [Number, String], 0)
    const name = getDataOfType(props, `stages[${stageIndex}].group`, String, '')
    const color = getDataOfType(props, `stages[${stageIndex}].color`, String, null)
    const fontWeight = getDataOfType(props, `stages[${stageIndex}].font-weight`, String, null)
    const fontColor = getDataOfType(props, `stages[${stageIndex}].font-color`, String, null)
    const countValue = getDataOfType(props, `count.value`, Number, null)
    const countColor = getDataOfType(props, `count.color`, String, null)
    const countTitle = getDataOfType(props, `count.title`, String, null)

    return [name, color, fontWeight, fontColor, countValue, countColor, countTitle]
  },[props])

  return (
    <>
      <Stage fontWeight={fontWeight} fontColor={fontColor} color={color}>{text}</Stage>
      {countValue ? (<CountBall title={countTitle}>{countValue}</CountBall>) : null}
    </>
  )
}

export default StageCell
