import React, { useEffect, useState, useMemo } from 'react'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { Steps, Space  } from 'antd'
import { StepsContainer, HeaderTitle, ItemTitleWrapper } from './styles'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  stageCode => stageCode,
  users => users,
  targetId => targetId,
  cardName => cardName
)
const createSession = createSelector(
    (state: any) => state.session,
    sessionData => sessionData
)

function Title({ title, date, datePlan, timePlan, dateFact, dateAdjusted, timeAdjusted, code, responsibles }) {
  const { users } = useSelector(createAppealsCurrentStep)
  const { sessionData } = useSelector(createSession)
  const formatResponsibles = useMemo(()=>
    responsibles.map(item2 => {
      const item = users.find(({ login }) => String(item2.login) === String(login))
      const author = (getDataOfType( item, 'profile-data.name', String, '') || getDataOfType(item, 'name',  String, '') ) //+ ` (ID: ${authorId})`
      return author || item2?.['profile-data']?.['name']
      //return item['login']
    }).filter(Boolean).join(', ')
  , [responsibles, users])

  const formatResponsibleOrganization = useMemo( () => {
    if( +sessionData.organization.id === responsibles[0]?.organization?.id )
      return null
    const title = responsibles[0]?.organization?.title
    return <>{ title ? (<span style={{color: '#a8adb4'}}>&nbsp;({title})</span>) : null}</>
  }, [responsibles, sessionData])

  const ratingFormat = useMemo(()=> {
    if (['manufacture', 'delivery'].includes(code)) {
      return null
    }
    const rating = getDataOfType(responsibles, '[0].rating', Number, null)
    return rating
  }, [responsibles, code])

  const titleFormat = useMemo(() => {
    const suffix = dayjs(date).format("DD.MM.YYYY")
    if (date === null || code === 'case') {
      return title
    }
    if (code === 'order') {
      return `${title}, до ${dayjs(date).format("DD.MM.YYYY HH:mm")}`
    }
    return `${title}, до ${suffix}`
  }, [title, code, date])

  const dateAdjustedFormat = useMemo(() => {
    if (dateAdjusted === null) {
      return null
    }
    if (code === 'manufacture') {
      return dayjs(dateAdjusted).format("DD.MM.YYYY")
    }
    return `${dayjs(dateAdjusted).format("DD.MM.YYYY")} ${timeAdjusted}`
  }, [dateAdjusted, timeAdjusted, code])

  const datePlanFormat = useMemo(() => {
    if (datePlan === null) {
      return null
    }
    return `${dayjs(datePlan).format("DD.MM.YYYY")} ${timePlan ?? ''}`.trim()
  }, [datePlan, timePlan])

  return (
    <ItemTitleWrapper>
      <Space size={4}>
        <span style={{ fontWeight: 'bold'}}>{titleFormat}</span>
      </Space>
      {datePlanFormat  && <Space size={4}>
        <span>Дата (план):</span> {datePlanFormat}
      </Space>}
      {dateAdjustedFormat && <Space size={4}>
        <span>Дата (скорректированная):</span> {dateAdjustedFormat}
      </Space>}
      {dateFact && <Space size={4}>
        <span>Дата (факт):</span> {`${dayjs(dateFact).format("DD.MM.YYYY HH:mm")}`}
      </Space>}
      <Space size={4}>
        Ответственный:
        <span style={{ color: '#a8adb4'}}>{formatResponsibles}</span>
        {formatResponsibleOrganization}
      </Space>
      {Boolean(ratingFormat) && <Space size={4}>
        Рейтинг:
        <span style={{ color: '#a8adb4'}}>{`${Number(ratingFormat).toFixed(2)}%`}</span>
      </Space>}
    </ItemTitleWrapper>
  )
} 

function OrderExecutionPlan() {
  const { targetId, appealsActionsTimeLine, stageCode } = useSelector(createAppealsCurrentStep)
  const [ currentStep, setCurrentStep ] = useState<any>(0)
  const [stages, setStages] = useState([])
  const stagesMemo = useMemo(()=> {
    return stages.map((item: any) => ({
      ...item,
      date: item['date'],
      datePlan: item['date-plan'],
      timePlan: item['time-plan'],
      dateFact: item['date-fact'],
      dateAdjusted: item['date-adjusted'],
      timeAdjusted: item['time-adjusted'],
    }))
  },[stages])

  useEffect(()=> {
    (async (targetId) => {
      try {
        const response = await fetchAPI(`/api/order-forecast/${targetId}`)
        const stages = getDataOfType(response, 'data', Array, [])
        const forecastStageCode = getDataOfType(response, 'stage-code', String, 'case')
        const step = stages.findIndex(item => item['code'] === forecastStageCode)
        setCurrentStep(step === -1 ? 0 : step)
        setStages(stages)
      } catch(err) {
        console.warn(err)
        console.warn(Boolean(appealsActionsTimeLine), Boolean(stageCode))
      }
    })(targetId)
  }, [targetId, appealsActionsTimeLine, stageCode])

  return <>
      <StepsContainer>
        <HeaderTitle>План исполнения заказа</HeaderTitle>
        <Steps size="small" current={currentStep} direction="vertical" >
          {stagesMemo.map((item: any) => (
            <Steps.Step key={item['stage-number']} title={<Title {...item} />}  />
          ))}
        </Steps>
      </StepsContainer>
  </>
}

export default OrderExecutionPlan