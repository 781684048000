import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Alert, Pagination } from 'antd'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
//import B2BLayout from 'src/react-app/layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import FieldHideText from 'src/react-app/components/field-hide-text'
// import { afterDataColumns } from 'src/react-app/components/list-operations/consts'
import EditCard from 'src/react-app/components/list-operations/edit-card'
import { formDataToUrlParams } from 'src/react-app/pages/list-orders/utils'
import { calcTotalWidth, tableRowClick, getColumnsOfSchema, fetchSchema, listColumnsHidden, formatListData } from 'src/lib/utils/list'
import { TableWrapper, PaginationWrapper } from './styles'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import './index.css'
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)
const createViewed = createSelector(
  (state: any) => state.viewed,
  counts => counts
)

const name = 'call-log'

function callDirectionFormat(id) {
  if (id === 1) {
    return 'Входящий'
  }
  if (id === 2) {
    return 'Исходящий'
  }
  return 'Внутренний'
}

function callTimeFormat(data) {
  const endTime = data['end_time']
  const talkTime = data['talk_time']
  if (endTime && talkTime) {
    const dateExpired = dayjs(talkTime * 1000).to(endTime * 1000, true)
    return dateExpired
  }
  return ''
}

export default function AdminObjectsListPage({ userId }) {
  const history = useHistory()
  //const location = useLocation()
  const orderFiltersRef = useRef<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [, setDataColumns] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [page, setPage] = useState<number>(1)
  const { counts } = useSelector(createViewed)

  
  const { sessionData } = useSelector(createSession)
  const addDataColumns = useMemo(() => {
    const addDataColumns: any[] = [
      {
        title: 'Вызов',
        width: 200,
        dataIndex: 'call_direction',
        key: 'call_direction',
        render: (key) => callDirectionFormat(key)
      },
      {
        title: 'Статус',
        width: 200,
        dataIndex: 'entry_result',
        key: 'entry_result',
        render: (key) => key ? 'Успешен' : 'Пропущен'
      },
      {
        title: 'Дата',
        width: 200,
        dataIndex: 'forward_time',
        key: 'forward_time',
        render: (key) => key && dayjs(key * 1000).format('DD.MM.YYYY hh:mm')
      },
      {
        title: 'Время ответа',
        width: 200,
        dataIndex: 'talk_time',
        key: 'talk_time',
        render: (key) => key ? dayjs(key * 1000).format('DD.MM.YYYY hh:mm') : ''
      },
      {
        title: 'Время разговора',
        width: 200,
        dataIndex: 'end_time',
        key: 'end_time',
        render: (_, item) => callTimeFormat(item)
      },
      {
        title: 'Входящий номер',
        width: 200,
        dataIndex: 'from',
        key: 'from',
        render: (key) => key['number']
      },
      {
        title: 'Исходящий номер',
        width: 200,
        dataIndex: 'to',
        key: 'to',
        render: (key) => key['number']
      },
      {
        title: 'Запись',
        width: 200,
        dataIndex: 'to',
        key: 'to',
        render: (key) => ''
      },
    ]
    if (getDataOfType(sessionData, 'organization.id', Number, null) === 1) {
      return [{
        title: 'Организация',
        width: 200,
        dataIndex: 'organizationTitle',
        key: 'organizationTitle',
      }].concat(addDataColumns)
    }
    return addDataColumns
  }, [sessionData]) 

  const totalWidth: any = useMemo(() => calcTotalWidth( addDataColumns), [ addDataColumns])

  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(addDataColumns).filter(item => item.title), [addDataColumns])
  const cbTableRowClick = useCallback(({ id }) => history.push(`/order/${id}/edit`), [history])

  const getData = useCallback(async (value, addUrlParams?: string) => {
    const urlParams = formDataToUrlParams(value)
    const pageErrors: string[] = []
    const [selectedSchema, metadata, collections] = await Promise.all([
      fetchSchema({ name }),
      fetchAPI(`/api/collections/${name}/metadata`),
      fetchAPI(`/api/collections-call-log?access_key=axioma&user-id=${userId}&${urlParams}&${addUrlParams}`)
    ])
    const columnsHidden = getDataOfType(metadata, 'data.columnsHidden', Array, [])
    const collectionsResultData = getDataOfType(collections, 'data.data', Array, [])
    //const ordersIds = collectionsResultData.map(item => item['id']).join(',')
  
    const isMetadata = getDataOfType(metadata, 'data', Object, null)
    if(isMetadata === null) {
      pageErrors.push('Некорректный ответ сервера при получении метаданных коллекции')
    }
    const dataSource: any[] = formatListData(collectionsResultData, selectedSchema)
    const newDataSource = dataSource.map((item) => ({
      ...item
    }))
    const preparedDataColumns: any[] = getColumnsOfSchema(selectedSchema)
    const datalistColumnsHidden = listColumnsHidden(preparedDataColumns, columnsHidden)
    const newDataColumns = datalistColumnsHidden.map(column => ({ 
        ...column, 
        fixed: 'left', 
        width: 120, 
        render: (text, { id, schema }) => (
        <EditCard id={id} schema={schema} title="Редактирование">
         {FieldHideText({ text })}
        </EditCard>
      )}
    ))
    const totalRows = getDataOfType(collections, 'data.total-rows', Number, 0)
    setTotalRows(totalRows)
    setDataColumns(newDataColumns)
    setDataSource(newDataSource)
    setPageErrors(pageErrors)
    setIsDataLoading(false)
  }, [userId])

  const onPagination = useCallback(async ( page, pageSize) => {
    setPage(page)
    const urlParams = `page=${page}&pageSize=${pageSize}`
    setIsDataLoading(true)
    await getData(orderFiltersRef.current, urlParams)
    setIsDataLoading(false)
  }, [getData])

  useEffect(() => {
    const urlParams = `page=${page}&pageSize=10`
    page === 1 && getData(orderFiltersRef.current,urlParams)
  }, [counts, page, getData])

  const rowClassName = useCallback(record => {
    return record['stage'] === 0 ? 'table-row-dark' :  'table-row-light'
  }, [])

  return (
    <div>
      {pageErrors.length > 0 ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
          />
        </div>
      ) : null}
      <TableWrapper>
        <Table
          columns={finalColumns}
          rowClassName={rowClassName}
          size="small"
          dataSource={dataSource}
          scroll={{ x: totalWidth, y: '72vh' }}
          pagination={false}
          loading={isDataLoading}
          onHeaderRow={(column: any) => ({
            style: {
              fontSize: column.key && column.key.substr(-3) === '-at' ? 9 : 13,
            },
          })}
          onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick) })}
        />
      </TableWrapper>
      {Boolean(totalRows) && <PaginationWrapper>
        <Pagination defaultCurrent={1} pageSize={10} total={totalRows} onChange={onPagination} showSizeChanger={false} />
      </PaginationWrapper>}
    </div>
  )
}
