import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Input } from 'antd'

import { AdminGroupCreateButton } from '../admin-group-create-button'
import { IEditableCellProps } from './interface'
import { ChildrenWrapper } from './styles'

export const EditableCell: FC<IEditableCellProps> = (props) => {
  const {
    children,
    title,
    editable,
    dataIndex,
    record,
    handleSave,
    groups,
    onAfterGroupCreate,
    getCreateGroupURL,
    getUpdateGroupURL,
    ...restProps
  } = props
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState<string>()
  const inputRef = useRef<any>(null)

  useEffect(() => {
    if (record && dataIndex && dataIndex in record) {
      setValue(record[dataIndex])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  const toggleEdit = useCallback((event?: any) => {
    setEditing(prevState => !prevState)
  }, [])

  const handleChange = useCallback((event) => {
    const val = event.target.value || ''

    setValue(val)
  }, [])

  const onSave = useCallback(async (event) => {
    try {
      const val = event.target?.value?.trim() || ''

      toggleEdit()

      if (val.length > 0 && val !== record[dataIndex]) {
        handleSave({ ...record, [dataIndex]: val })
      }
    } catch (errInfo) {
      console.error('Save failed:', errInfo)
    }
  }, [dataIndex, handleSave, record, toggleEdit])

  let childNode = useMemo(() => {
    let result = children

    if (editing) {
      result = <Input ref={inputRef} onPressEnter={onSave} onBlur={onSave} defaultValue={value} onChange={handleChange} />
    } else if (editable) {
      result = (
        <AdminGroupCreateButton
          groups={groups}
          group={{ ...record, 'parent-id': record['id'], id: null }}
          onAfterSave={onAfterGroupCreate}
          getCreateGroupURL={getCreateGroupURL}
          getUpdateGroupURL={getUpdateGroupURL}
        >
          <ChildrenWrapper onClick={toggleEdit}>
            {children}
          </ChildrenWrapper>
        </AdminGroupCreateButton>
      )
    }

    return result
  }, [
    children,
    editable,
    editing,
    getCreateGroupURL,
    getUpdateGroupURL,
    groups,
    handleChange,
    onAfterGroupCreate,
    onSave,
    record,
    toggleEdit,
    value,
  ])

  useEffect(() => {
    if (editing) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }, [editing])

  return (<td {...restProps}>{childNode}</td>)
}
