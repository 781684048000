import React, { useCallback, useMemo } from 'react'
import { DateRangePicker } from './styles'
import moment from 'moment'
import { ConfigProvider } from 'antd'
import locale from 'antd/es/locale/ru_RU'
import { ITime } from './interface'

function dateToMoment(date) {
  if (!Boolean(date)) return
  return moment(date).utc(false)
}

function InputDate({ value, onChange, UISchema }: ITime) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])

  const handleChange = useCallback(interval => {
    if(Array.isArray(interval) === false){
      return onChange(undefined)
    }
    const [start, end] = interval
    if((start && end) === false){
      return onChange(undefined)
    }
    const timeRange = moment(start).format('YYYY.MM.DD') + '-' + moment(end).format('YYYY.MM.DD')
    onChange(timeRange)
  }, [onChange])

  const valueFormat = useMemo<any>(() => {
    if(!Boolean(value)){
      return undefined
    }
    const [start, end] = value.split('-')
    if((start && end) === false){
      return undefined
    }
    return [dateToMoment(start), dateToMoment(end)]
  }, [value])

  return (
    <ConfigProvider locale={locale}>
      <DateRangePicker
        onChange={handleChange}
        value={valueFormat}
        disabled={disabled}
      />
    </ConfigProvider>
  )
}

export default React.memo(InputDate, (prev, next) => {
  if(prev.value === next.value)
    return true
  return prev?.UISchema === next?.UISchema;
})

