import { IAction, IErrors } from '../interfaces'
import { ILoginState } from './interfaces'
import {
  LOGIN_REQUESTED,
  LOGIN_DONE,
  LOGIN_FAILED
} from './actions'


const initialState: ILoginState = {
  isFetching: false,
  lastResponse: null,
  hasErrors: false,
}

export const loginReducer = (
  state = initialState, 
  { type, payload }: IAction<boolean | IErrors>,
) => {
  switch (type) {
    case LOGIN_REQUESTED:
      return {
        ...state,
        isFetching: true,
        lastResponse: null,
        hasErrors: false,
      }
    case LOGIN_DONE:
      return {
        ...state,
        isFetching: false,
        lastResponse: payload,
        hasErrors: false,
      }
    case LOGIN_FAILED:
      return {
        ...state,
        isFetching: false,
        lastResponse: payload,
        hasErrors: true,
      }
    default:
      return state
  }
}

