import fetchAPI from "../../../../lib/utils/fetch-api";
import {ISetNoteRequest} from "../types/types";


export const setNote = async (formData: ISetNoteRequest) => {
    try {
        const response = await fetchAPI(
            `/api/v2/notes`,
            {
                method: 'POST', body: JSON.stringify(formData)
            }
        )
        if (response['errors']) {
            let error = response['errors'];
            if (Array.isArray(error)) {
                error = error.join();
            }
            throw new Error(error);
        }

        return response;
    } catch (err) {
        console.log(err);
        return false;
    }
}
