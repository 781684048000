import React from 'react'
import { Select } from 'antd'
import { IDepartmentFilterProps } from '../interfaces/interfaces'

const { Option } = Select

const DepartmentFilter: React.FC<IDepartmentFilterProps> = ({
  selectedDepartments,
  onDepartmentChange,
  departments,
  style,
}) => {
  return (
    <Select
      mode="multiple"
      value={selectedDepartments}
      onChange={onDepartmentChange}
      style={style}
      placeholder="Все отделы"
      allowClear
    >
      {departments.map(department => (
        <Option key={department.id} value={department.id}>
          {department.value}
        </Option>
      ))}
    </Select>
  )
}

export default DepartmentFilter
