import moment from 'moment'

export const dateToMoment = date => {
  return (!Boolean(date)) ? undefined : moment(date).utc(false)
}

/**
 * Преобразовывает строку в
 * @param dates
 */
export const datesToMoment = (dates: any): any | undefined => {
  if (typeof dates === 'string') {
    const [start, end] = dates.split('-')
    if (start && end)
      return [dateToMoment(start)?.utcOffset(3), dateToMoment(end)?.utcOffset(3)]
  }
  return undefined
}

export const datesToString = interval =>{
  if (!Array.isArray(interval))
    return undefined
  const [start, end] = interval
  if (!start && !end)
    return undefined
  return moment(start).format('YYYY.MM.DD') + '-' + moment(end).format('YYYY.MM.DD')
}

export const objectsIsEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length)
    return false

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }
  return true
}