import {calcOrders} from "./calcOrders";
import {formatPrice} from "../../../utils";

export const oneTableRender = (ordersByMonthTmp,ordersPrepayment, monthKeys:string[], selectedMonths, selectedYears) => {
    const key = monthKeys.join(' ');
    const ordersByMonth = {}
    const total = {
        total: 'Всего',
        total_co: 0,
        total_cancel_before_kp: 0,
        total_cancel_after_kp: 0,
        total_workOrders: 0,
        total_measurements: 0,
        total_contracts: 0,
        total_percent_ld: 0,
        total_percent_md: 0,
        total_percent_cc: 0,
        total_average_score: 0
    }

    monthKeys.forEach((month: string) => {
        const managers = Object.keys(ordersByMonthTmp[month]);
        managers.forEach((manager) => {
            const orders = ordersByMonthTmp[month][manager];
            const ordersByPrepayment = ordersPrepayment[month][manager];
            const calc = calcOrders(orders,ordersByPrepayment, selectedMonths, selectedYears, manager);

            total.total_co += calc.co;
            total.total_cancel_before_kp += calc.cancel_before_kp;
            total.total_cancel_after_kp += calc.cancel_after_kp;
            total.total_workOrders += calc.workOrders;
            total.total_measurements += calc.measurements;
            total.total_contracts += calc.contracts;
            total.total_percent_ld += calc.percent_ld;
            total.total_percent_md += calc.percent_md;
            total.total_percent_cc += calc.percent_cc;
            total.total_average_score += calc.average_score;

            if (ordersByMonth[manager]) {
                    ordersByMonth[manager].co += calc.co
                    ordersByMonth[manager].cancel_before_kp += calc.cancel_before_kp
                    ordersByMonth[manager].cancel_after_kp += calc.cancel_after_kp
                    ordersByMonth[manager].workOrders += calc.workOrders
                    ordersByMonth[manager].measurements += calc.measurements
                    ordersByMonth[manager].contracts += calc.contracts
                    ordersByMonth[manager].percent_ld += calc.percent_ld
                    ordersByMonth[manager].percent_md += calc.percent_md
                    ordersByMonth[manager].percent_cc += calc.percent_cc
                    ordersByMonth[manager].average_score += calc.average_score
            } else {
                ordersByMonth[manager] = calc
            }
        })
    })
    //Данные колонки не используются в сортировке, отображаются в summary
    //@ts-ignore
    total.total_percent_md = total.total_percent_md?.toFixed(2) + '%'
    //@ts-ignore
    total.total_percent_ld = total.total_percent_ld?.toFixed(2) + '%'
    //@ts-ignore
    total.total_percent_cc = total.total_percent_cc?.toFixed(2) + '%'   //@ts-ignore
    //@ts-ignore
    total.total_average_score = formatPrice(total.total_average_score)
    return {
        [key]: [...Object.values(ordersByMonth), total]
    };
}