import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Form, Alert, Skeleton, Button, Modal, Tooltip, Tabs } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import B2BLayout from '../../../layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../../components/v2/default-json-form'
import { createUISchemaHorizontal } from 'src/lib/utils/grid-fild-orientation'
import userFieldsSchema from './json-schema.json'
import { isFormDataRequired } from 'src/lib/utils/collections'
import userFieldsSchemaAdd from './json.json'
import { InfoCircleOutlined } from '@ant-design/icons'
import './style.css'
import TableSelected from './table-selected'
import { objectToUrl, renameKeys, setEnumFields } from '../../utils'


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}


interface IBreadcrumbs {
  href: string
  title: string
}

export interface IDataRoles {
  id: number
  name: string
  is_active: boolean
}

interface IOrgData {
  office: {
    id: number
    name: string
  }
  organization: {
    id: number
    name: string
  }
}

export default function AdminNewObjectPage() {
  const { user_id } = useParams<any>()
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [roles, setRoles] = useState<undefined | IDataRoles[]>(undefined)
  const [initRoles, setInitRoles] = useState<any[]>([])
  const [, setProfilesType] = useState<any[]>([])
  const [profileTypeId, setProfileTypeId] = useState<any>(null)
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [organizationData, setOrganizationData] = useState<IOrgData>()
  const rolesRef = useRef<any[]>([])
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbs[]>([
    {
      href: '/new/organizations',
      title: 'Организации',
    },
  ])

  const saveCase = useCallback(
    async () => {
      const isRequired = isFormDataRequired(formDataRef.current, matchedJSONSchema)
      if (!isRequired) {
        Modal.warning({
          title: 'Предупреждение',
          content: 'Заполните все обязательные поля'
        })
        return
      }

      const formData = { ...formDataRef.current }

      await fetchAPI(`/api/users_new/${user_id}`, {
        method: 'POST',
        body: JSON.stringify(formData)
      })
      history.push(`/office/${organizationData?.office?.id}`)

      /*
      const userData = {
        'login': formData['login'],
        'password': formData['password']
      }
      delete formData['login']
      delete formData['password']

      await fetchAPI(`/api/roles-user/${user_id}?roles-ids=${rolesIds.join(',')}`, { method: 'PUT'})
      const dataUser = await fetchAPI(`/api/user-create-or-update/${user_id}`, { method: 'POST', body: JSON.stringify({
        'profile-type-id': profileTypeId,
        'profile-data': formDataRef.current,
        'user-data': userData
      })})

      const params = (new URL(window.document.location as any)).searchParams
      const back = params.get('back')
      if (back) {
        history.push(`/${back}`)
        return
      }
      history.push('/employees')
      */
    },
    [history, profileTypeId, user_id, matchedJSONSchema, organizationData],
  ) 

  const getData = useCallback(async () => {
    {
      const result = await fetchAPI(`/api/profiles-types?type=user`)
      const profilesTypes = getDataOfType(result, 'data', Array, [])
      setProfilesType(profilesTypes)
      const organizationData = await fetchAPI(`/api/office_info/${user_id}`)
      setOrganizationData(organizationData as IOrgData)
      setBreadcrumbs(prev => {
        return [
          ...prev,
          {
            href: `/new/organization/${organizationData.organization.id}`,
            title: `Организация «${organizationData.organization.name}»`,
          },
          {
            href: `/office/${organizationData.office.id}`,
            title: `Офис «${organizationData.office.name}»`,
          },
        ]
      })
      {
        const { data, roles } = await fetchAPI(`/api/user_new/${user_id}`)
        setInitRoles(roles || [])
        const profileTypeId = 1
        formDataRef.current = data
        setProfileTypeId(profileTypeId)
        if (user_id === 'new') {
          userFieldsSchema['required'] = ['login', 'password']
        }

        const mergedSchema = userFieldsSchemaAdd
        const updatedMergedSchema = {
          ...mergedSchema,
          properties: {
            ...mergedSchema.properties,
            password: {
              ...mergedSchema.properties.password,
              suffix: (
                <Tooltip
                  title={<span style={{ textAlign: 'center', display: 'block' }}>Заполнение поля приведет к изменению пароля!</span>}
                >
                  <InfoCircleOutlined style={{ color: 'rgba(200,0,0,.6)' }} />
                </Tooltip>
              )
            }
          }
        }

        const enumsList = { types: ['case_city', 'case_region'] }
        const enums = await fetchAPI(`/api/enum-list?${objectToUrl(enumsList)}`)
        const fixKeys = renameKeys(enums, {
          case_region: 'regions',
          case_city: 'city_id',
        })
        setEnumFields(fixKeys, updatedMergedSchema)
        setMatchedJSONSchema(updatedMergedSchema)
        setUISchema(createUISchemaHorizontal(updatedMergedSchema))
      }
    }
    {
      const result = await fetchAPI(`/api/roles/list`).then(data => data as IDataRoles[])
      setRoles(result)
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [user_id])

  useEffect(() => {
    getData()
  }, [getData])

  const onFormData = useCallback(
    ({ formData }) => {
      formDataRef.current = formData
    }, []
  )

  const onTableChange = useCallback(async (ids) => {
    rolesRef.current = ids
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            { user_id === 'new' ? 'Добавление сотрудника' :
              <>
                {formDataRef?.current?.name
                ? (<>Сотрудник <span>{`${formDataRef.current.name}`}</span></>)
                : '...'}
              </>
            }
          </MapTitle>
        </TaskWrapper>
        <MapContent>
          <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {isDataLoading === false ? (
              UISchema && (
                <DefaultJsonForm
                  formData={formDataRef.current}
                  schema={matchedJSONSchema}
                  onChange={onFormData}
                  orientation={'horizontal'}
                />
              )
            ) : (
              <Skeleton active />
            )}
          </Form>
          <Tabs type="card">
            <Tabs.TabPane tab="Роли" key="1">
              { roles === null ? 'Нет дотупных ролей, сохраните сотрудника' :
                <TableSelected dataSource={roles} onChange={onTableChange} selected={initRoles} user_id={Number(user_id)} />
              }
            </Tabs.TabPane>
          {/*
            <Tabs.TabPane tab="Рейтинг" key="2">
              <RatingUser id={user_id} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="История заказов" key="3">
              <TabGeneral clientId={user_id} />
            </Tabs.TabPane>
          */}
          </Tabs>
          <Button onClick={saveCase} style={{ marginBottom: 16, marginTop: 16 }} type="primary">
            <SaveOutlined />
            Сохранить
          </Button>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
