import React from 'react'

export const AcceptIcon = (props) => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="-255 347 100 125"
        xmlSpace="preserve">
        <path
          d="M-205,363.2c-18.7,0-33.8,15.2-33.8,33.8c0,18.7,15.2,33.8,33.8,33.8s33.8-15.1,33.8-33.8S-186.3,363.2-205,363.2z   M-205,426.8c-16.4,0-29.8-13.4-29.8-29.8s13.4-29.8,29.8-29.8s29.8,13.4,29.8,29.8S-188.6,426.8-205,426.8z" />
        <polygon points="-207.9,405.4 -220.1,393.7 -222.9,396.6 -207.8,411 -184.7,387.2 -187.6,384.4 " />
      </svg>
    </div>
  )
}
