import React, { ReactNode, useMemo } from 'react'
import { Steps } from 'antd'
import { classNames } from '../../../lib/classNames/classNames'
import cls from '../Steps/Steps.module.css'
import './index.css'

type Status = 'wait' | 'process' | 'finish' | 'error'
type Direction = 'horizontal' | 'vertical'

interface StepItem {
  description?: ReactNode
  disabled?: boolean
  icon?: ReactNode
  status?: Status
  subTitle?: ReactNode
  name: string
  code: string
}

interface HistoryItem {
  code?: string
  date_start?: string
  date_end?: string
}

export interface StepsProps {
  className?: string
  current: number
  direction: Direction
  items: StepItem[]
  history?: HistoryItem[]
}

export const StepsUi: React.FC<StepsProps> = (props: StepsProps) => {
  const {
    direction,
    current,
    className,
    items,
    history = [],
    ...otherProps
  } = props
  const { Step } = Steps

  const optionsList = useMemo(() => {
    const historySteps = history.map(historyItem => historyItem.code)
    return items.map((optionItem, index) => {
      let status: Status = 'wait'
      if (index < current) {
        status = 'finish'
      } else if (index === current) {
        status = 'process'
      }

      if (!historySteps.includes(optionItem.code) && index < current) {
        status = 'error'
      }

      return (
        <Step
          className='cardStep'
          title={optionItem.name}
          key={optionItem.code}
          status={status}
        />
      )
    })
  }, [items, history, current])

  return <Steps
    className={classNames(cls.Step, {}, [className])}
    direction={direction}
    current={current}
    size='small'
    {...otherProps}
  >
    {optionsList}
  </Steps>
}
