import { ColumnsType } from 'antd/lib/table/Table'
import { ICollectionsGroup } from '../../views/admin-edit-group-form'
import { CellRecord } from '../../pages/list-admin-collections'

export enum CollectionObjectListType {
  SIMPLE = 'simple',
  FULL = 'full'
}

export interface ICollectionObjectListProps {
  collectionName: string
  isModalView?: boolean
  viewType?: CollectionObjectListType
  onAddNew?: (name: string) => void
  onEdit?: (data: any) => void
  onRowSelect?: (id: number, selectId: string) => void
  outerSelectedId?: number | null
}

export interface IGroupsColumnsProps {
  sortCallback?: any
  groups?: ICollectionsGroup[]
  onAfterGroupCreate?: () => void
  handleGroupUpdate?: (record: CellRecord) => void
  expandedList?: Record<number, boolean>
  selectedId: number | null
  isModalView?: boolean
}

export interface IAfterDataColumnsProps {
  onEdit?: (item: { id: number; collectionName: string; groupId?: number }) => () => void
  onDelete: (id: number) => () => void
  selectedId: number | null
  onSort?: any
  isModalView?: boolean
}

export type IGroupsColumns = (props: IGroupsColumnsProps) => ColumnsType<any>
export type IBeforeDataColumns = (onSort?: any) => ColumnsType<any>
export type IAfterDataColumns = (props?: IAfterDataColumnsProps) => ColumnsType<any>

export interface IGroupedObjects {
  id: number
  key: number
  name?: string
  schema: string
  groupId: number
  children?: IGroupedObjects[] | null
  'collection-name'?: string | null
  objectsId?: number[]
  parentId?: number | null
  isGroup: boolean
  createdAt: string
  updatedAt: string
}
