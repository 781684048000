import React from 'react'
import dayjs from 'dayjs'
import ActivityTimelineFile from './activity-timeline-file'
import ActivityTimelineNote from './activity-timeline-note'
import ActivityTimelineAvito from './activity-timeline-avito'
import ActivityTimelineTask from './activity-timeline-task'
import ActivityTimelineStage from './activity-timeline-stage'
import ActivityTimelineSpace from './activity-timeline-space'
import ActivityTimelineInfo from './activity-timeline-info'
import ActivityTimelinePhone from './activity-timeline-phone'
import ActivityTimelineFileDynamic from './activity-timeline-file-dynamic'
import ActivityTimelinePayment from './activity-timeline-payment'


export function formatDate(date){
  const time = dayjs(date).format('HH:mm')
  const isTomorrow = dayjs().add(1, 'day').format('DD.MM.YYYY') === dayjs(date).format('DD.MM.YYYY')
  if (isTomorrow) {
    return `завтра, ${time}`
  }
  const isToday = dayjs().format('DD.MM.YYYY') === dayjs(date).format('DD.MM.YYYY')
  if (isToday) {
    return `сегодня, ${time}`
  }
  const isYesterday = dayjs().subtract(1, 'day').format('DD.MM.YYYY') === dayjs(date).format('DD.MM.YYYY')
  if (isYesterday) {
    return `вчера, ${time}`
  }
  return dayjs(date).format('D MMMM, HH:mm')
}

function TimelineItem({ type, body, date, index, id }) {
  if (type === 'payment') {
    return <ActivityTimelinePayment type={type} body={body} date={date} index={index} id={id} />
  }
  if (type === 'info') {
    return <ActivityTimelineInfo type={type} body={body} date={date} index={index} id={id} />
  }
  if (type === 'stage') {
    return <ActivityTimelineStage type={type} body={body} date={date} index={index} id={id} />
  }
  if (type === 'space') {
    return  <ActivityTimelineSpace type={type} body={body} date={date} index={index} id={id} />
  }
  if (type === 'task') {
    return <ActivityTimelineTask type={type} body={body} date={date} index={index} id={id} />
  }
  if (type === 'note') {
    return <ActivityTimelineNote type={type} body={body} date={date} index={index} id={id} />
  }
  if (type === 'avito') {
    return <ActivityTimelineAvito type={type} body={body} date={date} index={index} id={id} />
  }
  if (type === 'file') {
    return <ActivityTimelineFile type={type} body={body} date={date} index={index} id={id} />
  }
  if (type === 'phone') {
    return <ActivityTimelinePhone type={type} body={body} date={date} index={index} id={id} />
  }
  if (type === 'dynamic-file') {
    return <ActivityTimelineFileDynamic type={type} body={body} date={date} index={index} id={id} />
  }
  return null
}

export default TimelineItem
