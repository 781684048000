import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Button, Form, Input, Skeleton, Popconfirm } from 'antd'
import { SaveOutlined, SettingOutlined } from '@ant-design/icons'

import {
  createSchema,
  localizedServerErrors,
  updateSchema,
  validateSchemaProperties,
} from '../../../lib/utils/schema'
import fetchAPI from '../../../lib/utils/fetch-api'
import createSchemaEditor from '../../components/json-schema-editor'
import { Footer } from './styles'
import { useSchemaFormType } from './interface'
// import { Store } from 'rc-field-form/lib/interface'
import { formItemLayout } from './consts'

export const useSchemaForm: useSchemaFormType = (
  {
    isEdit = false,
    noFooter = false,
    onAfterSave,
    getTitle,
    getDescription,
    formData,
    isNewOpen,
  },
) => {
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditOnce, setEditOnce] = useState(true)
  const [form] = Form.useForm()
  const haveErrorRef = useRef(false)

  const SchemaEditor = createSchemaEditor({})

  const setSchemaName = useCallback(() => {
    setIsLoading(true)
    fetchAPI(`/api/total/schemas`)
      .then((response) => {
        if (typeof response['data'] === 'string') {
          const total: number = Number(response['data']) + 1
          const values = form.getFieldsValue()
          form.setFieldsValue({
            ...values,
            name: `schema-${total}`,
          })
          setPageErrors([])
        } else {
          setPageErrors(['Некорректный ответ сервера'])
        }
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        setPageErrors(['Ошибка загрузки данных: ' + err])
      })
  }, [form])

  useEffect(() => {
    if (!isEdit) {
      setSchemaName()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (form) {
      const oldData = form.getFieldsValue()
      if (formData) {
        form.setFieldsValue(formData)
      } else if (oldData['id']) {
        form.resetFields()
        setSchemaName()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  useEffect(() => {
    if (isNewOpen) {
      setPageErrors([])
    }
  }, [isNewOpen])

  const handleUpdateTitle = useCallback(() => {
    if (form && getTitle && getTitle.constructor === Function) {
      const title = getTitle()
      const values = form.getFieldsValue()

      if (!values.title) {
        form.setFieldsValue({
          ...values,
          title,
        })
      }
    }
  }, [form, getTitle])

  const handleUpdateDescription = useCallback(() => {
    if (form && getDescription && getDescription.constructor === Function) {
      const description = getDescription()
      const values = form.getFieldsValue()

      if (!values.description) {
        form.setFieldsValue({
          ...values,
          description,
        })
      }
    }
  }, [form, getDescription])

  const onFinish: (values: any) => void = useCallback(values => {
    const handleFinishSuccess = async () => {
      const handler = formData ? updateSchema : createSchema
      const result = await handler(values)

      haveErrorRef.current = true

      if (result['data'] && result['data']['id']) {
        setPageErrors([])
        if (onAfterSave && onAfterSave.constructor === Function) {
          onAfterSave(result['data'])
        }
      } else {
        const errors =
          (Array.isArray(result['errors']) && (result['errors'].length > 0))
            ? result['errors'].map((error) => localizedServerErrors(error['detail']))
            : ['Неизвестная ошибка при выполнении запроса']
        setPageErrors(errors)
      }
    }

    validateSchemaProperties({
      values,
      onError: error => {
        haveErrorRef.current = true
        setPageErrors(error)
      },
      onSuccess: handleFinishSuccess,
    })
  }, [formData, onAfterSave])

  const onFinishFailed = useCallback(errorInfo => {
    haveErrorRef.current = true
    console.error('AdminNewSchemaPage: form failed', errorInfo)
  }, [])

  const handleSchemaEditorChange = useCallback(newData => {
    if (typeof newData === 'string') {
      let value

      try {
        value = JSON.parse(newData)
      } catch (err) {
        value = {}
      }

      form.setFieldsValue({
        schema: value,
      })
      return
    }
  }, [form])

  const handleSubmit = useCallback(() => {
    if (form && form.submit) {
      form.submit()
    }
  }, [form])

  const handleReloadForm = useCallback(() => {
    if (haveErrorRef && !haveErrorRef.current) {
      form.resetFields()
      setSchemaName()
    }
  }, [form, setSchemaName])

  const schemaEditorData = useMemo(() => {
    const result = {}

    if (isEdit && formData) {
      result['data'] = formData['schema']
    }

    return result
  }, [formData, isEdit])

  const confirmMessage = useMemo(() => isEdit ? 'Изменить данные структуры?' : 'Создать структуру?', [isEdit])
// eslint-disable-next-line
  const render = useCallback(({ needUpdateData }) => (
    <>
      {needUpdateData && handleUpdateTitle()}
      {needUpdateData && handleUpdateDescription()}
      <Skeleton loading={isLoading} active>
        <Form
          {...formItemLayout}
          layout="horizontal"
          form={form}
          scrollToFirstError={true}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            schemas: [undefined],
          }}
        >
          <Form.Item name="id"/>
          {(pageErrors.length > 0) ? (
            <div style={{ marginBottom: 40 }}>
              <Alert
                message="При выполнении операции возникла ошибка:"
                showIcon
                type="error"
                description={pageErrors.join('. ')}
              />
            </div>
          ) : null}
          <Form.Item
            label="Наименование структуры"
            name="title"
            rules={[
              {
                required: true,
                message: 'Поле обязательно к заполнению',
              },
            ]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Идентификатор структуры"
            extra="Внимание! Допустимы только латинские букв (a-z, A-Z) и символ -"
            name="name"
            rules={[
              {
                required: true,
                message: 'Поле обязательно к заполнению',
              },
            ]}
          >
            <Input disabled={isEditOnce} addonAfter={isEdit? null: <SettingOutlined onClick={() => setEditOnce(old => !old)} />} />
          </Form.Item>
          <Form.Item
            label="Описание структуры"
            name="description"
          >
            <Input/>
          </Form.Item>
          <Form.Item name="schema">
            <SchemaEditor
              {...schemaEditorData}
              onChange={handleSchemaEditorChange}
            />
          </Form.Item>
          {!noFooter && (
            <Footer>
              <Popconfirm title={confirmMessage} okText="Да" cancelText="Нет" onConfirm={handleSubmit}>
                <Button type="primary">
                  <SaveOutlined/>
                  Сохранить
                </Button>
              </Popconfirm>
            </Footer>
          )}
        </Form>
      </Skeleton>
    </>
  ), [
    confirmMessage,
    form,
    handleSchemaEditorChange,
    handleSubmit,
    handleUpdateDescription,
    handleUpdateTitle,
    isEdit,
    isLoading,
    noFooter,
    onFinish,
    onFinishFailed,
    pageErrors,
    schemaEditorData,
    isEditOnce,
    SchemaEditor
  ])

  return {
    render,
    onSubmit: handleSubmit,
    onReloadForm: handleReloadForm,
  }
}
