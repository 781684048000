import React, {  useMemo, useCallback } from 'react'
import { Form, Space } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { SpaceWrapper } from './styles'
import { getInputNode } from 'src/react-app/components/json-form-object-editor'

const ArrayMultiFild = ({ value, onChange, schema, UISchema }:{ value?:any, onChange?:any, schema:any, UISchema:any }) => {
  const [form] = Form.useForm()
  const items = useMemo(() => {
    if(Array.isArray(value)){
      return value
    }
    if (schema?.items?.type === 'object') {
      return [{}]
    }
    return [undefined]
  }, [value, schema])

  const localOnChange = useCallback((_,  all ) => {
    const { value } = all.find(({ name }) => name[0] === 'items' && name[1] === undefined)
    onChange(value)
  }, [onChange])

  const InputNode = useMemo(() => {
    const schemaItems = schema['items']
    return getInputNode(schemaItems)
  }, [schema])

  return (
    <Form
      form={form}
      name="array"
      layout="vertical"
      onFieldsChange={localOnChange}
      autoComplete="off"
      initialValues={{ items }}
    >
      <Form.List name="items">
        {(fields, { add, remove }) => (
          <Space direction="vertical" style={{ width: '100%' }}>
            {fields.map(field => (
              <SpaceWrapper key={field.key}>
                <Form.Item name={field.name}>{InputNode}</Form.Item>
                <PlusOutlined style={{ padding: 5, fontSize: 20 }} onClick={() => add()} />
                <MinusCircleOutlined
                  style={{ padding: 5, fontSize: 20 }}
                  onClick={() => remove(field.name)}
                />
              </SpaceWrapper>
            ))}
          </Space>
        )}
      </Form.List>
    </Form>
  )
}

export default ArrayMultiFild