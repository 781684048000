import styled from 'styled-components'
import { Steps } from 'antd'

export const UserProfile = styled.div`
  background: none;
  padding: 15px;
  user-select: none;
  cursor: pointer;

  .ant-layout-sider-collapsed & {
    display: none;
  }
`

export const MapWrapper = styled.div`
  padding: 10px 0px;
`

export const MapTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 12px;
`

export const MapContent = styled.div`
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  background: white;

  padding: 24px 36px;
`
export const StepsContainer = styled.div`
  margin: 20px 0 30px 0;
  user-select: none;
`

export const TabContent = styled.div`
  padding: 10px
`
interface IHeadingStyled {
  color: string;
}

export const Step = styled(Steps.Step)<IHeadingStyled>`
  .ant-steps-item-icon{
    background: ${props => props.color ? props.color: '#1890ff'};
    border-color: ${props => props.color ? props.color: '#1890ff'};
  }
`