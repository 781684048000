import React, { FC } from 'react';
import WhiteSpace from 'antd-mobile/lib/white-space';

import { FieldGroup, FieldName } from './styles'
import { parseDate } from '../../../utilits'
import Card from 'antd-mobile/lib/card';
import { IMeasurementCardProps } from './interface'

export const MeasurementDateCard: FC<IMeasurementCardProps> = (props) => {
  const { data } = props

  return (
    <>
      <Card>
        <Card.Header
          title="Замер"
          extra={<span>Даты</span>}
        />
        <Card.Body>
          <FieldGroup>
            <FieldName>Плановая:</FieldName>
            {data['date-plan']}
          </FieldGroup>
          {data['date-adjusted'] && (
            <FieldGroup>
              <FieldName>Изменённая:</FieldName>
              {data['date-adjusted']}
            </FieldGroup>
          )}
          {data['date-fact'] && (
            <FieldGroup>
              <FieldName>Фактическая:</FieldName>
              {parseDate(data['date-fact'])}
            </FieldGroup>
          )}
        </Card.Body>
      </Card>
      <WhiteSpace size="lg" />
    </>
  )
}
