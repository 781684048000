import React, {useState, useEffect, useMemo, useCallback} from 'react'
import B2BLayout from '../../../layouts/b2b'
import {Button, Table} from 'antd'
import Search from 'antd/es/input/Search'
import {SearchOutlined} from '@ant-design/icons'
import {getColumns} from './columns'
import {createSelector} from 'reselect'
import {useSelector} from 'react-redux'
import {getDataOfType} from '../../../../lib/utils/get-data-of-type'
import fetchAPI from '../../../../lib/utils/fetch-api'
import {useHistory} from 'react-router-dom'
import {FilterWrapper} from './styles'
import ru from 'react-phone-input-2/lang/ru.json'
import PhoneInput from 'react-phone-input-2'
import {DateRangePicker} from '../../../components/input-date-range/styles'
import moment from 'moment'


const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

type TablePaginationPosition = 'bottomCenter'

const CustomersList = () => {
  const history = useHistory()
  const [data, setData] = useState([])
  const [availableFilters, setAvailableFilters] = useState({})
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [currentFilters] = useState<any>({
    name: urlParams?.name,
    phone: urlParams?.phone,
    id: urlParams.id ? +urlParams.id : null,
    stage: urlParams?.stage,
    dates: urlParams.dates
  })
  const {sessionData} = useSelector(createSession)
  const isLeader = getDataOfType(sessionData, 'organization.id', Number, null) === 1
  const [loading, setLoading] = useState(false)
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [sorter, setSorter] = useState<any>({})
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 10,
    position: [bottomCenter],
    showSizeChanger: false
  })

  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(() => (
    [{
      href: '/cases',
      title: 'Контактный центр',
    }, {
      href: location.pathname,
      title: 'Обращения',
    }]
  ), [location.pathname])

  useEffect(() => {
    try {
      /* Пытаюсь получить стадии и фильтры */
      getAvailableFilters()
      /* Пытаюсь получить список */
      getData()
    } catch (e) {
      alert('Не удалось получить данные, пожалуйста, перезагрузите страницу')
    }
  }, [])


  /**
   * Обновляет состояние пагинации
   */
  const updatePagination = useCallback(newState => {
    setPagination({
      ...pagination,
      ...newState
    })
  }, [pagination, setPagination])

  /**
   * Получает список доступных фильтров для обращений
   */
  const getAvailableFilters = useCallback(() => {
    fetchAPI('/api/get-filter-appeals')
      .then(data => {
        setAvailableFilters(data)
      })
      .catch(() => {
        console.error('Не удалось получить список доступных фильтров')
      })
  }, [])

  /**
   * Получает записи
   */
  const getData = useCallback(() => {
    try {
      setLoading(true)
      const urlParams = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI('/api/users-new/get-list/',
        {
          method: 'POST',
          body: JSON.stringify(urlParams)
        }
      )
        .then(({data, pagination, sorter}) => {
          if (data) {
            updatePagination(pagination)
            setSorter(sorter)
            setData(data)
          }
          setLoading(false)
        })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [])

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param filters
   * @param sorter
   */
  const handleTableChange = (pagination, filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    let current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({search: urlParams})
    getData()
  }

  /**
   * Отправляет на страницу деталки
   * @param record
   */
  const handleRowClick = record => {
    const {id} = record
    history.push(`/users-new/${id}`)
  }

  /**
   * Отслеживает состояние фильтров
   * @param filters
   */
  const handleFilterChange = filters => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    const obj = {...currentUrlParams, ...filters}

    /* в строку попадают только непустые строковые и положительные числовые значения */
    const newParams = Object.keys(obj).reduce((acc, key) => {
      if (
        (typeof obj[key] === 'string' && obj[key].length)
        ||
        (typeof obj[key] === 'number' && obj[key] > 0)
      ) {
        acc[key] = obj[key]
      }
      return acc
    }, {})

    const newUrlParams = new URLSearchParams(newParams).toString()
    history.push({search: newUrlParams})
    getData()
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <Filters values={currentFilters} filters={availableFilters} onFilter={handleFilterChange}/>
      <Table
        showSorterTooltip={{overlay: "Нажмите для сортировки", placement: 'bottom'}}
        columns={getColumns(isLeader, sorter)}
        rowKey={r => r?.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={record => ({onClick: () => handleRowClick(record)})}
        scroll={{
          x: 'max-content',
          y: '72vh'
        }}
        size="small"
      />
    </B2BLayout>
  )
}

export default CustomersList

export function Filters({filters, values, onFilter}) {
  const [phone, setPhone] = useState(values?.phone || '')
  const [filter, setFilter] = useState<any>({
    phone: values?.phone,
    id: values?.id,
    dates: values?.dates,
    name: values?.name,
  })

  const changeID = (id) => {
    setFilter({...filter, id})
    onFilter({...filter, id})
  }

  const changeFilterInput = (code, value) => {
    setFilter({...filter, ...{[code]: value}})
    onFilter({...filter, ...{[code]: value}})
  }

  const changePhone = () => {
    setFilter({...filter, phone})
    onFilter({...filter, phone})
  }

  const changeDates = useCallback(interval => {
    if (!Array.isArray(interval)) {
      setFilter({...filter, ...{dates: undefined}})
      onFilter({...filter, ...{dates: undefined}})
    }
    const [start, end] = interval
    if (!start && !end) {
      setFilter({...filter, ...{dates: undefined}})
      onFilter({...filter, ...{dates: undefined}})
    }
    const dates = moment(start).format('YYYY.MM.DD') + '-' + moment(end).format('YYYY.MM.DD')
    setFilter({...filter, dates})
    onFilter({...filter, dates})
  }, [onFilter])

  function dateToMoment(date) {
    if (!Boolean(date)) return
    return moment(date).utc(false)
  }

  const formatDates = useMemo<any>(() => {
    if (typeof values.dates === 'string') {
      const [start, end] = values.dates.split('-')
      if (!start && !end)
        return undefined
      return [dateToMoment(start), dateToMoment(end)]
    }
  }, [values.dates])

  return (
    <FilterWrapper>
      <FilterID
        value={filter.id}
        onSearch={changeID}
      />
      <FilterInput
        value={filter.name}
        onSearch={changeFilterInput}
        code="name"
        placeholder="ФИО"
      />
      <FilterInput
        value={filter.login}
        onSearch={changeFilterInput}
        code="login"
        placeholder="Логин"
      />
      <div style={{display: 'flex', width: 'max-content', position: 'relative'}}>
        <PhoneInput
          inputStyle={{height: 32, borderRadius: 2, border: '1px solid #d9d9d9', width: 200}}
          value={phone}
          onChange={setPhone}
          dropdownStyle={{}}
          enableSearch={true}
          searchPlaceholder={'Поиск'}
          localization={ru}
          country={'ru'}
          onlyCountries={['ru', 'kz', 'by', 'tj', 'uz', 'am', 'kg', 'az', 'md', 'tm']}
        />
        {filter?.phone && (
          <span className="ant-input-suffix" style={{height: '100%', right: '38px', zIndex: 10, position: "absolute"}}
            onClick={() => {
              setPhone(undefined)
              setFilter({...filter, ...{phone: undefined}})
              onFilter({...filter, ...{phone: undefined}})
            }}>
            <span className="ant-input-clear-icon" role="button">
              <span role="img" aria-label="close-circle" className="anticon anticon-close-circle">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em"
                     fill="currentColor" aria-hidden="true">
                  <path
                    d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"/>
                </svg>
              </span>
            </span>
          </span>
        )}
        <Button icon={<SearchOutlined />} onClick={changePhone} style={{marginLeft: '-5px'}}/>
      </div>
      <DateRangePicker
        onChange={changeDates}
        value={formatDates}
        disabled={false}
      />
    </FilterWrapper>
  )
}

const FilterInput = (props) => {
  const {value, onSearch, placeholder, code} = props
  const [localValue, setLocalValue] = useState(value)

  const handleChange = (e) => {
    const {value: inputValue} = e.target
    setLocalValue(inputValue)
  }
  const handleSearch = (value) => {
    onSearch(code, value)
  }

  return (
    <Search
      {...props}
      value={localValue}
      style={{width: 300}}
      allowClear
      onChange={handleChange}
      onSearch={handleSearch}
      maxLength={10}
      placeholder={placeholder}
    />
  )
}

const FilterID = (props) => {
  const {value, onSearch} = props
  const [localValue, setLocalValue] = useState(value)

  const handleChange = (e) => {
    const {value: inputValue} = e.target
    const reg = /^-?\d*(\.\d*)?$/
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      setLocalValue(inputValue)
    }
  }

  return (
    <Search
      {...props}
      value={localValue}
      style={{width: 170}}
      prefix={'ID'}
      allowClear
      onChange={handleChange}
      onSearch={onSearch}
      maxLength={10}
    />
  )
}
