import styled from 'styled-components'
import TableActSource from './table-date-fact'

export const ImageWrapper = styled.div`
  padding: 10px;
`

export const TitleWrapper = styled.span`
  margin-bottom: 2px;
  margin-left: 10px;
  font-weight: bold;
`

export const GridWrapper = styled.div`
  display:grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  grid-auto-rows: min-content;
`

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: min-content;
  min-width: 400px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
`


export interface IStyledTableActSource {
  data: any 
  stageCode: string
}


export const TableDatesActPrint = styled(TableActSource)<IStyledTableActSource>`

 

`



