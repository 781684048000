import {Button} from "antd";
import styled from 'styled-components'
export const ButtonStyled = styled(Button)`
    border: 1px solid;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 50px;
    right: 10px;
    z-index: 1000;
`