import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
import { JSONSchema7 } from 'json-schema'

import fetchAPI from '../../../lib/utils/fetch-api'
import {
  checkIsRequired, createCollections,
  fetchAllCollections,
  getCollectionsListFromProperties,
  getSchemaFromData,
} from '../../../lib/utils/collections'
import { ICollectionObjectEditorProps } from './interface'
import { ICollectionItem } from '../../store/collections/interfaces'
import { EditObjectForm } from '../../forms/app-edit-object-form'

export const EditCollectionObject: FC<ICollectionObjectEditorProps> = (
  {
    title,
    footer,
    id,
    groupId,
    collectionName,
    onAfterSave,
    needOwnCollections = false,
  }
) => {
  const history = useHistory()
  const collections = useSelector(createCollections)
  const [innerCollections, setInnerCollections] = useState<ICollectionItem | null>(null)
  const [collectionMetadata, setCollectionMetadata] = useState({})
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [name, setName] = useState<string>('')
  const [loadedSchemas, setLoadedSchemas] = useState<JSONSchema7[]>([])
  const [selectedSchema, setSelectedSchema] = useState<string[]>([])
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any | null>(null)
  const [availableSchemas, setAvailableSchemas] = useState<JSONSchema7[]>([])
  const [formData, setFormData] = useState<any | null>({})
  const isRequiredContentRef = useRef<boolean>(true)
  const [form] = Form.useForm()

  useEffect(() => {
    const getOwnCollections = async () => {
      const response = await fetchAPI(`/api/collections/${collectionName}/metadata`)

      setInnerCollections(response['data'])
    }

    if (needOwnCollections) {
      getOwnCollections()
    }

    if (id) {
      fetchAPI(`/api/collections/objects/${id}`)
        .then(res => {
          const object = res['data']
          const schema = object?.['data']?.['metadata']?.['schema']
          const formData = object?.['data']?.['attributes'] ?? {}
          const name = object?.['data']?.['name']

          form.setFieldsValue({
            schema: schema,
            name,
          })
          setSelectedSchema(schema)
          setFormData(formData)
          setName(name)
        })
    }
  }, [])

  const handleUpdateSchemasData = useCallback(() => {
    setIsDataLoading(true)
    fetchAPI('/api/schemas?access_key=axioma&fields=title,description,type,properties,required,created_by,created_at,updated_at')
      .then(async response => {
        const schemas = (response['data'] && response['data']['data']) || []
        const selectedSchemas = schemas.filter(item => selectedSchema.includes(item.name))
        const fetchedSchemas: JSONSchema7[] = []
        const collectionsList = getCollectionsListFromProperties(selectedSchemas)

        try {
          const collectionsAll = await fetchAllCollections(collectionsList)
          const collectionsData = Object.fromEntries(
            collectionsList.map((name, index) => {
              const data = collectionsAll[index] && collectionsAll[index]['data'] && collectionsAll[index]['data']['data']
              return [name, data]
            }),
          )

          selectedSchemas.forEach(item => {
            const parsedItem = getSchemaFromData(item, collectionsData)
            fetchedSchemas.push(parsedItem)
          })

          setLoadedSchemas(fetchedSchemas)
        } catch (err) {
          console.error('AdminNewObjectPage: failed to fetch collectionsResult', err)
        }
        setIsDataLoading(false)
      })
  }, [selectedSchema])

  useEffect(() => {
    if (selectedSchema && selectedSchema.length > 0 && !isDataLoading) {
      handleUpdateSchemasData()
    }
  }, [selectedSchema])

  const updateData = useCallback((collectionsData) => {
    setCollectionMetadata(collectionsData)
    setSelectedSchema(collectionsData['schema'])
    setPageErrors([])
    setIsDataLoading(false)
  }, [])

  useEffect(() => {
    if (collections !== null) {
      updateData(collections)
    }
  }, [collections])

  useEffect(() => {
    if (needOwnCollections && innerCollections !== null) {
      updateData(innerCollections)
    }
  }, [innerCollections, needOwnCollections])

  useEffect(() => {
    const result: JSONSchema7[] = []
    loadedSchemas.forEach(item => {
      if (selectedSchema && selectedSchema.includes(item['name'])) {
        setMatchedJSONSchema(item)
      }

      if ((collectionMetadata['schema'] || []).indexOf(item['name']) >= 0) {
        result.push(item)
      }
    })

    setAvailableSchemas(result)
  }, [loadedSchemas, collectionMetadata, selectedSchema])

  useEffect(() => {
    const isNotFormData = formData === null
    const isNotMatchedJSONSchema = matchedJSONSchema === null
    const isNotDataLoading = !isDataLoading
    if(isNotDataLoading || isNotMatchedJSONSchema || isNotFormData){
      return
    }
    setIsDataLoading(false)
  }, [formData, matchedJSONSchema])

  const onFormData = ({ schema, formData = {} }) => {
    const { required } = schema
    isRequiredContentRef.current = checkIsRequired(formData, required)
    setFormData(formData)
  }

  const handleValuesChange = (changedValues) => {
    if (changedValues.schema) {
      setSelectedSchema(changedValues.schema)
    }
  }

  const onFinish = useCallback(async (values) => {
    let result: any = {}
    const requestBody = {
      'data': {
        'name': name,
        'group-id': values['group-id'],
        'title': values['title'],
        'attributes': formData,
        'metadata': {
          'schema': values['schema'],
        },
      },
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(requestBody),
    }
    if (collectionName) {
      const target = id
        ? `/api/collections/${collectionName}/objects/${id}`
        : `/api/collections/${collectionName}`
      result = await fetchAPI(target, requestOptions)
    }
    if (result['data'] && (result['data']['updated-at'] || result['data']['id'])) {
      setPageErrors([])

      if (onAfterSave && onAfterSave.constructor === Function) {
        const resultId = id || (result['data'] && result['data']['id'])

        onAfterSave(resultId)
      } else {
        history.push(`/admin/collections/${collectionName}/objects`)
      }
    } else {
      const errors =
        Array.isArray(result['errors']) && result['errors'].length > 0
          ? result['errors'].map((error) => error['detail'])
          : ['Неизвестная ошибка при выполнении запроса']
      setPageErrors(errors)
    }
  }, [collectionName, formData, history, name, id, onAfterSave])

  const onFinishFailed = errorInfo => {
    console.error('AdminNewObjectPage: form failed', errorInfo)
  }

  const onSubmit = useCallback(() => {
    form.submit()
  }, [form])

  const handleRowSelect = useCallback(key => id => {
    const { required } = matchedJSONSchema
    const newFormData = {
      ...formData,
      [key]: id,
    }

    handleUpdateSchemasData()
    isRequiredContentRef.current = checkIsRequired(newFormData, required)
    setFormData(newFormData)
  }, [formData, handleUpdateSchemasData, matchedJSONSchema])

  return (
    <EditObjectForm
      form={form}
      title={title}
      footer={footer}
      pageErrors={pageErrors}
      collectionName={collectionName}
      isDataLoading={isDataLoading}
      formData={formData}
      name={name}
      availableSchemas={availableSchemas}
      matchedJSONSchema={matchedJSONSchema}
      selectedSchema={selectedSchema}
      id={id}
      groupId={groupId}
      needOwnCollections={needOwnCollections}
      onFinishFailed={onFinishFailed}
      onFinish={onFinish}
      onRowSelect={handleRowSelect}
      onSubmit={onSubmit}
      onChangeName={setName}
      onFormData={onFormData}
      onValuesChange={handleValuesChange}
      onAfterSave={onAfterSave}
      onUpdateSchemasData={handleUpdateSchemasData}
    />
  )
}
