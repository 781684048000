export const organization = {
  "title": "Организация",
  "type": "pointer",
  "view": {
    "name": "select-from-organizations"
  }
}

export const user = {
  "title": "Менеджер",
  "type": "pointer",
  "view": {
    "name": "select-from-user"
  }
}

export const stage = {
  "type": "string",
  "title": "Стадия",
  "enum": [
    "new",
    "work",
    "measurer-arrived",
    "measurement-ready",
    "contract-signed",
    "done",
  ],
  "oneOf": [
    {
      "const": "new",
      "title": "Новая"
    },
    {
      "const": "work",
      "title": "В работе"
    },
    {
      "const": "measurer-arrived",
      "title": "Замерщик приехал"
    },
    {
      "const": "measurement-ready",
      "title": "Замер произведен"
    },
    {
      "const": "contract-signed",
      "title": "Договор подписан"
    },
    {
      "const": "done",
      "title": "Выполнено"
    }
  ],
  "view": {
    "name": "select-from-enum"
  }
}

export const dateRange = {
  "type": "string",
  "format": "date-range",
  "title": "Дата"
}