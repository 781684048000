import React from 'react'
import { Select } from 'antd'
import { IVacancyFilterProps } from '../interfaces/interfaces'

const { Option } = Select

const VacancyFilter: React.FC<IVacancyFilterProps> = ({
  selectedVacancies,
  onVacancyChange,
  vacancies,
}) => {
  return (
    <Select
      mode="multiple"
      allowClear
      value={selectedVacancies}
      onChange={onVacancyChange}
      placeholder="Все вакансии"
      style={{ width: '100%' }}
    >
      {vacancies.map(vacancy => (
        <Option key={vacancy.id} value={vacancy.id}>
          {vacancy.value}
        </Option>
      ))}
    </Select>
  )
}

export default VacancyFilter
