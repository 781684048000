import React from 'react';

import { IconWrapper } from './styles';

export const CarIcon = () => {
  return (
    <IconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 125" x="0px" y="0px"><title>08</title>
        <g data-name="Group">
          <path data-name="Compound Path"
            d="M15.36,17.52v4H58.12V45.94H88.37l5,6.39V69.4h-7.7a11.11,11.11,0,0,0-21.88,0H44a11.11,11.11,0,0,0-21.88,0H15.36v4h6.73a11.12,11.12,0,0,0,21.86,0H63.77a11.12,11.12,0,0,0,21.86,0H97.34V51l-6.22-8h0L81.45,22.63H62.12v-5.1ZM33,78.48a7.13,7.13,0,1,1,7.13-7.12A7.13,7.13,0,0,1,33,78.48Zm41.69,0a7.13,7.13,0,1,1,7.13-7.12A7.13,7.13,0,0,1,74.7,78.48Zm4.23-51.85,7.3,15.32H62.12V26.63Z" />
          <rect data-name="Path" x="7.66" y="54" width="16" height="4" />
          <rect data-name="Path" x="2.66" y="42" width="28" height="4" />
          <rect data-name="Path" x="7.66" y="30" width="28" height="4" />
        </g>
      </svg>
    </IconWrapper>
  )
}

