import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Input, Modal, Space, Timeline } from 'antd'
import { formatDate } from './activity-timeline-Item'
import {
  AvitoStyle,
  TimeLineContentHeaderWrapper,
  TimeLineContentWrapper
} from './styles'
import ModalAvitoResponse from '../../views/modal-avito-response'

/**
 * TODO раскидать на разные компоненты
 * @param type
 * @param body
 * @param date
 * @param id
 * @constructor
 */

function ActivityTimelineAvito({ type, body, date, id }: any) {
  const dateFormat = useMemo(() => formatDate(date), [date])
  const noteName = useMemo(()=>{
    return body?.title
  }, body)

  const dotIcon = () => {
    return (<svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.595" cy="5.225" r="3.325" fill="#965EEB"></circle>
      <circle cx="22.245" cy="7.235" r="7.235" fill="#0AF"></circle>
      <circle cx="8.9" cy="18.6" r="8.9" fill="#04E061"></circle>
      <circle cx="24.325" cy="21.005" r="5.375" fill="#FF4053"></circle>
    </svg>)
  }

  const getBgColor = useCallback( () => {
    if (body.type === 'avito-note') {
      if (body.direction === 'out')
        return '#cfffcc'
      return '#ccd7ff'
    }
    return ''
  }, [body])

  const bgColor = getBgColor()
  const user = (body.direction === 'out') ? 'Менеджер' : 'Пользователь'

  return (
    <Timeline.Item
      dot={
        <AvitoStyle>
          {dotIcon()}
        </AvitoStyle>
      }
    >
      <TimeLineContentWrapper color={bgColor}>
        <TimeLineContentHeaderWrapper>
          <Space>
            <span style={{ fontWeight: 'bold' }}>{noteName}</span>
            {
              body?.type !== 'avito-top' &&
              <span style={{color: '#a8adb4'}}>{dateFormat}</span>
            }
          </Space>
        </TimeLineContentHeaderWrapper>
          <>
            {
              body.type === 'avito-top'
              ?
                (
                  <>
                    <div>
                      <p><b>{formatDate(body.date)}</b></p>
                    </div>
                    <div>
                      {body.content.split('\n').map( (line: string) => (<div>{line}</div>))}
                    </div>
                  </>
                )
              :
                (
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                      {user}: {body.content}
                    </div>
                    { body.type === 'avito-note' && body.direction === 'in'
                      ? (<ModalAvitoResponse />)
                      : null
                    }
                  </div>
                )
            }
          </>
      </TimeLineContentWrapper>
    </Timeline.Item>
  )
}

export default ActivityTimelineAvito