import React, { useState, useEffect, useRef, memo, useCallback, MutableRefObject } from 'react'
import {Button, Modal} from 'antd'
import { DefaultJsonForm } from '../../../components/v2/default-json-form'
import { saveType } from './saveType'
import { ISaveTypeRequest } from './types'

interface ModalAddTypeProps {
  isOpen?: boolean
  onClose?: (
    data?: {
      id?: number,
      value?: string
    }
  ) => void
}

export interface ISaveType {
  name?: string
}

export const ModalAddType: React.FC<ModalAddTypeProps> = memo((props: ModalAddTypeProps) => {
  const {isOpen, onClose} = props
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>
  const [value, setValue] = useState<ISaveType>({ name: undefined })
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      inputRef.current?.focus()
    }, 200)
    return () => clearTimeout(timer)
  }, [isOpen])

  const handleSaveBrand = useCallback(async () => {
    if (value?.name?.trim() === '') {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Название не может быть пустым'
      })
      return
    }
    await saveType(value as ISaveTypeRequest)
      .then(result => {
        if (result) {
          setIsDisabled(true)
          handleOnClose({ id: result?.id, value: result?.value})
        }
      })
  }, [value, setValue, setIsDisabled])

  const handleOnClose = useCallback(data => {
    if (onClose) {
      onClose?.(data)
      setValue({ name: undefined })
    }
  }, [onClose, value, setValue])

  const formData = { name: null }

  const schema = {
    type: 'object',
    required: ['name'],
    properties: {
      name: {
        title: 'Название',
        type: 'string',
        disabled: false,
        required: true,
        options: {
          required: true,
        }
      }
    }
  }

  const onChange = useCallback(({ formData }) => {
    setValue({ name: formData.name })
  }, [setValue, value])

  return (
    <Modal
      title="Добавить Тип организации"
      visible={isOpen}
      onCancel={handleOnClose}
      footer={[
        <Button key="back" onClick={handleOnClose}>Отмена</Button>,
        <Button key="submit" disabled={isDisabled} onClick={handleSaveBrand}>Добавить</Button>,
      ]}
    >
      <DefaultJsonForm
        formData={formData}
        schema={schema}
        onChange={onChange}
        orientation="horizontal"
      />
    </Modal>
  )
})
