import React, { useState, useEffect, useRef } from 'react'
import { Button, List, message, Progress } from 'antd'
import fetchAPI from '../../../lib/utils/fetch-api'

const MigrationComponent = () => {
  const [taskId, setTaskId] = useState(null)
  const [logs, setLogs] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [stage, setStage] = useState<null | string>(null)
  const listRef = useRef<HTMLDivElement>(null)

  const startMigration = async () => {
    setLoading(true)
    setLogs([])
    setProgress(0)
    try {
      const response = await fetchAPI('/api/migration')
      const newTaskId = response.taskId
      setTaskId(newTaskId)
      message.success('Процесс миграции запущен')
    } catch (error) {
      message.error('Ошибка запуска миграции')
    }
  }

  useEffect(() => {
    if (!taskId) return
    const interval = setInterval(async () => {
      try {
        const response = await fetchAPI(`/api/migration?task=${taskId}`) as { logs: string[], progress: number, stage?: string }
        setLogs(response.logs)
        setProgress(Math.ceil(response?.progress || 0))
        setStage(response?.stage || null)
        if (response.logs.some(log => log.includes('Работа завершена'))) {
          clearInterval(interval)
          setLoading(false)
          setProgress(100)
          setStage('Готово')
        }
      } catch (error) {
        console.error('Ошибка получения журнала миграции:', error)
        clearInterval(interval)
      }
    }, 2000)

    return () => clearInterval(interval)
  }, [taskId])

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight
    }
  }, [logs])

  return (
    <div style={{ padding: '20px', backgroundColor: '#fff' }}>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <Button
          type="primary"
          onClick={startMigration}
          loading={Boolean(progress>0 && progress<100)}
        >
          Запустить миграцию данных
        </Button>
        <div style={{ flexGrow: 0, marginLeft: 10 }}>
          Стадия: {stage}
        </div>
        <div style={{ flexGrow: 1, marginLeft: 10 }}>
          <Progress percent={progress} status='active' />
        </div>
      </div>
      <div ref={listRef} style={{ maxHeight: '90vh', minWidth: '300px', overflowY: 'auto', marginTop: '20px' }}>
        <List
          size='small'
          bordered
          dataSource={logs}
          renderItem={(item, index) => (
            <List.Item
              style={{ backgroundColor: item.includes('Ошибка') ? '#f8d7da' : 'transparent' }}
            >
              {`${index + 1}.\t ${item}`}
            </List.Item>
          )}
        />
      </div>
    </div>
  )
}

export default MigrationComponent
