export const dateRange = {
  "type": "string",
  "format": "date-range",
  "title": "Дата запуска заказа"
}

export const status = {
  "type": "string",
  "title": "Статус",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}

