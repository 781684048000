export const beforeDataColumns: any[] = [{
  title: 'ID',
  width: 60,
  dataIndex: 'id',
  key: 'id',
  fixed: 'left',
  defaultSortOrder: 'descend',
  sorter: (a, b) => a.id - b.id,
}]

export const afterDataColumns: any[] = [
 /* {
    title: 'Действие',
    key: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    //render: (_, { id, deleteRecord, schema }) => <ListOperations id={id} schema={schema} deleteRecord={deleteRecord} />,
  },
  */ 
]
