import React, { useMemo, useCallback, useContext } from 'react'
import { Timeline, Space, Button } from 'antd'
import { formatDate } from './activity-timeline-Item'
import {
  FileOutlined,
  CopyOutlined,
//  DeleteOutlined
} from '@ant-design/icons'
//import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import {
  TimeLineContentWrapper,
  TimeLineContentHeaderWrapper,
  DotStyle,
} from './styles'
//import filesize from 'filesize'
//import ActivityTimelineContext from './activity-timeline-context'

const dotColor = '#2fc4f5'

function ActivityTimelineFile({ type, body, date, id }: any){
  const dateFormat = useMemo(() => formatDate(date), [date])
  const copyBuffer = useCallback(() => {
    window?.navigator?.clipboard?.writeText(body.url)
  },[body.url])

  //const contextProps = useContext(ActivityTimelineContext)
  /*
  const deleteFile = useCallback(async () => {
    const { deleteFile } = contextProps[type]
    await deleteFile({ id })
  },[contextProps, type, id])
  */
// window.open( `/api/web-hooks/test-kp-print/${id}`)
  const url = useMemo(() => {
    return `/api/web-hooks/test-kp-print/${id}`
  }, [id])

  const openUrl = useCallback((event) => {
    event.preventDefault()
    window.open(url)
  }, [url])

  return (
    <Timeline.Item dot={<DotStyle color={dotColor}><FileOutlined style={{ fontSize: '16px' }} /></DotStyle>}>
      <TimeLineContentWrapper>
        <TimeLineContentHeaderWrapper>
          <Space>
            <span style={{ fontWeight: 'bold' }}>Динамический файл</span>
            <span style={{ color: '#a8adb4' }}>{dateFormat}</span>
          </Space>
        </TimeLineContentHeaderWrapper>
        <Space>
          {Array.isArray(url) && url.map((url, index) => (
            <a href={url} style={{ width: 'min-content', whiteSpace: 'nowrap' }}>
              {`${body.name} #${index + 1}`}
            </a>
          ))}
          {!Array.isArray(url) && (
            <a href={url} onClick={openUrl} style={{ width: 'min-content', whiteSpace: 'nowrap' }}>
              { /* body?.name */ 'Коммерческое предложение'}
            </a>
          )}
          <Button
            style={{ width: 'min-content', border: 'none', padding: 0 }}
            onClick={copyBuffer}
          >
            <CopyOutlined />
          </Button>
        </Space>
      </TimeLineContentWrapper>
    </Timeline.Item>
  )
}

export default ActivityTimelineFile
