import fetchAPI from 'src/lib/utils/fetch-api'
import {getDataOfType} from 'src/lib/utils/get-data-of-type'
import moment from 'moment'
import {deleteCollectionObject, getWorkflows, updateCollectionObject} from './utils-case'
import {getStageFromCode} from './stage-utils'


export const RELOAD_ACTIONS_TIME_LINE = 'RELOAD_ACTIONS_TIME_LINE'
export const ADD_ACTIONS_TIME_LINE = 'ADD_ACTIONS_TIME_LINE'
export const SET_CASE_STAGE = 'SET_CASE_STAGE'
export const SET_USERS = 'SET_USERS'
export const SET_USERS_PROFILE = 'SET_USERS_PROFILE'
export const SET_CASE_ID = 'SET_CASE_ID'
export const CLEAR_CASE_STORE = 'CLEAR_CASE_STORE'
export const CASE_DELETE_FILE = 'CASE_DELETE_FILE'
export const CASE_EDIT_NOTE = 'CASE_EDIT_NOTE'
export const SET_TASK_STAGES = 'SET_TASK_STAGES'
export const SET_STAGE_COLLECTION = 'SET_STAGE_COLLECTION'
export const SET_WORKFLOWS = 'SET_WORKFLOWS'
export const SET_TARGET_ID = 'SET_TARGET_ID'
export const SET_CARD_NAME = 'SET_CARD_NAME'
export const SET_TABLE_PAYMENT = 'SET_TABLE_PAYMENT'
export const SET_CALC_DELIVERY = 'SET_CALC_DELIVERY'

const nameChangeHistory = 'change-history'
const nameTasks = 'tasks'
const nameUserProfile = 'user-profile'
const nameDynamicFile = 'collection-13'
const avitoNote = 'avito_note'
const nameNote = 'note'
const nameTaskStages = 'task-stages'

export const initialCase = ({targetId, cardName}) => async (dispatch, getState) => {

  if (targetId !== null) {
    dispatch({
      type: SET_TARGET_ID,
      targetId
    })
  }

  dispatch({
    type: SET_CARD_NAME,
    cardName
  })

  const [workflows, usersData] = await Promise.all([
    getWorkflows(),
    fetchAPI(`/api/users`)
  ])

  dispatch({
    type: SET_WORKFLOWS,
    items: workflows
  })

  const users = getDataOfType(usersData, 'data', Array, []).map(item => Object.assign({
    'name': item['login']
  }, item))

  dispatch({
    type: SET_USERS,
    items: users
  })

}

export const setCalcDelivery = ({isCalcDelivery}) => async (dispatch, getState) => {
  dispatch({
    type: SET_CALC_DELIVERY,
    isCalcDelivery: isCalcDelivery
  })
}

export const reloadTablePayment = () => async (dispatch, getState) => {
  try {
    const targetId = getDataOfType(getState(), 'appeals.targetId', Number, null)
    dispatch({
      type: SET_TABLE_PAYMENT,
      tablePayment: await fetchAPI(`/api/web-hooks/add-payment-contract`, {
        method: 'POST', body: JSON.stringify({
          'targetId': Number(targetId),
          pay: {
            sum: 0,
            date: ''
          }
        })
      })
    })
  } catch (err) {
    console.warn(err)
  }
}

export const reloadActionsTimeLine = () => async (dispatch, getState) => {
  const targetId = getDataOfType(getState(), 'appeals.targetId', Number, null)
  const cardName = getDataOfType(getState(), 'appeals.cardName', String, null)
  if (targetId === null) {
    return
  }
  const sessionData = getDataOfType(getState(), 'session.sessionData', Object, {})

  // Функция фильтрует задачи, задачи видет ГО, автор и ответственные
  const filterTask = data => {
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, null)
    if (organizationId === 1) {
      return true
    }
    const userId = getDataOfType(sessionData, 'user.id', Number, null)
    const responsible = getDataOfType(data, 'data.attributes.responsible', Array, [])
    const author = getDataOfType(data, 'data.attributes.author', Number, null)
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    return ((author === userId) || responsible.includes(userId) || roles.includes(2) || roles.includes(28));
  }

  try {
    const [
      responseTasks,
      responseTaskTypes,
      responseUserProfile,
      responseFileS3,
      responseNotes,
      responseChangeHistory,
      responseTaskStages,
      responseDynamicFile,
      responseCallRecordings,
      responsePayments,
      responseAvito
    ] = await Promise.all([
      fetchAPI(`/api/collections/${nameTasks}/?parentId=${targetId}&access_key=axioma`),
      fetchAPI(`/api/collections/objects-grouped/task-type`).then(result => result).catch(() => []),
      fetchAPI(`/api/collections/${nameUserProfile}/?access_key=axioma`),
      fetchAPI(`/api/web-hooks/get-files-from-card/?target-id=${targetId}&card-name=${cardName}`),
      fetchAPI(`/api/collections/${nameNote}/?parentId=${targetId}&access_key=axioma`),
      fetchAPI(`/api/collections/${nameChangeHistory}/?parentId=${targetId}&access_key=axioma`),
      fetchAPI(`/api/collections/${nameTaskStages}/?access_key=axioma`),
      fetchAPI(`/api/collections/${nameDynamicFile}/?parentId=${targetId}&access_key=axioma`),
      fetchAPI(`/api/mango/recordings-order/${targetId}`).then(result => result).catch(() => []),
      fetchAPI(`/api/web-hooks/add-payment-contract`, {
        method: 'POST',
        body: JSON.stringify({'targetId': Number(targetId), pay: {sum: 0, date: ''}})
      }),
      fetchAPI(`/api/collections/${avitoNote}/?parentId=${targetId}&access_key=axioma`),
    ])

    const sum = getDataOfType(responsePayments, 'sum', Number, 0)
    let totalPayments = 0

    const payments = getDataOfType(responsePayments, 'data.attributes.payments', Array, [])
      .map((item, index) => {
        const date = moment(item.date).valueOf()
        totalPayments += item.price
        item.debt = sum - totalPayments
        return {
          date: date,
          sort: date,
          id: `payment-${index}`,
          type: 'payment',
          body: {
            ...item
          }
        }
      })

    const stages = getDataOfType(responseTaskStages, 'data.data', Array, [])
      .map(item => {
        return {
          id: item['id'],
          name: item['name'],
          title: getDataOfType(item, 'data.attributes.title', String, ''),
          sort: getDataOfType(item, 'data.attributes.sort', String, ''),
        }
      })
      .sort((a, b) => a['sort'] - b['sort'])

    const filesS3List = getDataOfType(responseFileS3, 'data.data', Array, []).map(item => {
      const fileS3Data = getDataOfType(item, 'data.attributes', Object, {})
      const date = moment(fileS3Data['date']).valueOf()
      const urls = typeof fileS3Data.url === 'string' ? [{
        size: fileS3Data?.size,
        url: fileS3Data?.url,
        type: fileS3Data?.type,
        name: fileS3Data?.name,
        'field-name': fileS3Data?.['field-name'],
      }] : fileS3Data.urls
      return {
        date,
        sort: date,
        id: item['id'],
        type: 'file',
        body: {
          ...fileS3Data,
          urls,
          id: item['id']
        }
      }
    })

    const dynamicFileList = getDataOfType(responseDynamicFile, 'data.data', Array, []).map(item => {
      const date = moment(item['created-at']).valueOf()
      return {
        date,
        sort: date,
        id: item['id'],
        type: 'dynamic-file',
        body: {
          id: item['id'],
          author: item['owner-user'],
        }
      }
    })

    const notesList = getDataOfType(responseNotes, 'data.data', Array, []).map(item => {
      const note = getDataOfType(item, 'data.attributes', Object, {})
      const date = moment(note['date']).valueOf()
      return {
        date,
        sort: date,
        id: item['id'],
        type: 'note',
        body: {
          content: note['content'],
          date: note['date'],
          author: note['author'],
          type: note['type'],
          title: note?.title
        }
      }
    })

    const avitoNotesList = getDataOfType(responseAvito, 'data.data', Array, []).map(item => {
      const note = getDataOfType(item, 'data.attributes', Object, {})
      const type = getDataOfType(note, 'type', String, null)
      const date = type === 'avito-top' ? moment().valueOf() : moment(note['date']).valueOf()
      return {
        date,
        sort: date,
        id: item['id'],
        type: 'avito',
        body: {
          content: note?.content,
          date: note?.date,
          type,
          title: note?.title,
          direction: note?.direction
        }
      }
    })

    const tasksList = getDataOfType(responseTasks, 'data.data', Array, []).filter(filterTask)
    const usersProfileList = getDataOfType(responseUserProfile, 'data.data', Array, [])
    const taskTypes = getDataOfType(responseTaskTypes, 'data.data', Array, [])
    const list = tasksList.map(item => {
      const dateIso = getDataOfType(item, 'data.attributes.date', String, 0)
      const dateCompletionIso = getDataOfType(item, 'data.attributes.date-completion', String, null)
      const date = moment(dateIso).valueOf()
      const sort = dateCompletionIso ? moment(dateCompletionIso).valueOf() : date
      const typeId = getDataOfType(item, 'data.attributes.type-task', Number, null)
      const typeName = taskTypes.filter(item => item.id === typeId)?.[0]?.name
      return {
        date,
        sort: sort,
        id: item.id,
        type: 'task',
        body: {...item, ...{type: typeName}},
      }
    })

    const stageHistoryList = getDataOfType(responseChangeHistory, 'data.data', Array, []).map(item => {
      const dateIso = getDataOfType(item, 'data.attributes.date', String, 0)
      const body = getDataOfType(item, 'data.attributes', Object, {})
      const date = moment(dateIso).valueOf()
      return {
        date,
        sort: date,
        id: item.id,
        type: 'stage',
        body
      }
    })

    const callLogList = responseCallRecordings.map(item => {
      const body = {
        ...item,
        record: item?.links,
        end_time: item?.start,
        talkTime: item?.finish,
      }
      const date = +item?.start * 1000
      return {
        date,
        sort: date,
        id: +item?.start,
        type: 'phone',
        body
      }
    })

    const debt = +sum - +totalPayments
    const card_name = getDataOfType(getState(), 'appeals.cardName', String, {})
    const currentStep = getDataOfType(getState(), 'appeals.appealsCurrentStep', Number, {})

    if (card_name === 'order' && [3, 4].indexOf(currentStep) !== -1 && debt > 0) {
      dispatch({
        type: ADD_ACTIONS_TIME_LINE,
        items: [{
          sort: (new Date()).getTime(),
          date: (new Date()).getTime(),
          id: (new Date()).getTime(),
          type: 'payment',
          body: {
            warning: true,
            debt
          }
        }]
      })
    }

    dispatch({
      type: SET_USERS_PROFILE,
      items: usersProfileList
    })

    dispatch({
      type: SET_TASK_STAGES,
      items: stages
    })

    dispatch({
      type: RELOAD_ACTIONS_TIME_LINE,
      items: list
    })

    dispatch({
      type: ADD_ACTIONS_TIME_LINE,
      items: payments
    })

    dispatch({
      type: ADD_ACTIONS_TIME_LINE,
      items: filesS3List
    })

    dispatch({
      type: ADD_ACTIONS_TIME_LINE,
      items: dynamicFileList,
    })

    dispatch({
      type: ADD_ACTIONS_TIME_LINE,
      items: notesList,
    })

    dispatch({
      type: ADD_ACTIONS_TIME_LINE,
      items: avitoNotesList,
    })

    dispatch({
      type: ADD_ACTIONS_TIME_LINE,
      items: stageHistoryList,
    })

    dispatch({
      type: ADD_ACTIONS_TIME_LINE,
      items: callLogList,
    })

  } catch (err) {
    console.warn(err)
  }
}

export const addActionsTimeLine = ({type, items}) => async dispatch => {
  if (type === "note") {
    const list = items.map(item => {
      const date = moment(item.date).valueOf()
      return {
        id: item.id || date,
        sort: date,
        type,
        date: date,
        body: item
      }
    })
    dispatch({
      type: ADD_ACTIONS_TIME_LINE,
      items: list
    })
  }
  if (type === 'info') {
    const list = items.map(item => {
      const date = moment(item.date).valueOf()
      return {
        id: date,
        sort: date,
        type,
        date: date,
        body: item
      }
    })
    dispatch({
      type: ADD_ACTIONS_TIME_LINE,
      items: list
    })
  }

  if (type === 'stage') {
    const list = items.map(item => {
      const date = moment(item.date).valueOf()
      return {
        id: date,
        sort: date,
        type,
        date: date,
        body: item
      }
    })
    dispatch({
      type: ADD_ACTIONS_TIME_LINE,
      items: list
    })
  }

  if (type === 'file') {
    const list = items.map(item => {
      return {
        id: item.id,
        sort: item.date,
        type,
        date: item.date,
        body: item
      }
    })
    dispatch({
      type: ADD_ACTIONS_TIME_LINE,
      items: list
    })
  }

  if (type === 'dynamic-file') {
    const list = items.map(item => {
      return {
        id: item.id,
        sort: item.date,
        type,
        date: item.date,
        body: item
      }
    })
    dispatch({
      type: ADD_ACTIONS_TIME_LINE,
      items: list
    })
  }
}

export const setAppealStage = props => async (dispatch, getState) => {
  const stages = getDataOfType(getState(), `appeals.workflows.${props.type}`, Array, [])
  const stageNumber = getDataOfType(getStageFromCode(stages, props.stageCode), 'number', Number, null)
  dispatch({
    type: SET_CASE_STAGE,
    step: stageNumber === null ? props.step : stageNumber,
    stageCode: props.stageCode
  })
}

export const setCaseId = ({parentIds}: any) => async dispatch => {
  dispatch({
    type: SET_CASE_ID,
    parentIds
  })
}

export const setTargetId = ({targetId}: any) => async dispatch => {
  dispatch({
    type: SET_TARGET_ID,
    targetId: Number(targetId)
  })
}

export const clearCaseStore = () => dispatch => {
  dispatch({
    type: CLEAR_CASE_STORE
  })
}

export const caseDeleteFile = ({id}) => async dispatch => {
  const result = await deleteCollectionObject(id)
  if (result) {
    dispatch({
      type: CASE_DELETE_FILE,
      id
    })
    return true
  }
  return false
}

export const caseEditNote = ({id, content}) => async (dispatch, getState) => {
  const item = getDataOfType(getState(), 'appeals.appealsActionsTimeLine', Array, [])
    .find(item => item.id === id)

  if (!Boolean(item))
    return false

  const attributes = {'content': content, date: getDataOfType(item, 'body.date', String, '')}
  const result = await updateCollectionObject(id, attributes)

  if (result) {
    dispatch({
      type: CASE_EDIT_NOTE,
      id,
      content
    })
    return true
  }
  return false
}
