import React from 'react'
import { Provider } from 'react-redux'
import { hot, setConfig } from 'react-hot-loader'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { ConfigProvider } from 'antd'
import moment from 'moment'
import ruLang from 'antd/es/locale/ru_RU'
import 'moment/locale/ru'
import isMobile from 'is-mobile'
import Normalize from '../styles/normalize'
import GlobalStyle from '../styles/main'
import FontsStyle from '../styles/fonts'
import theme from '../styles/theme'
import './b2b.less'
import { DesktopRouter } from './desktop-router'
import { MobileRouter } from './mobile-router'

moment.locale('ru')

const GlobalStyles = createGlobalStyle`
  ${Normalize}
  ${GlobalStyle}
  ${FontsStyle}
`

function App({ store }) {
  const isMobileVersion = isMobile()

  return (
    <>
      <ThemeProvider theme={theme} >
        <Provider store={store}>
          <ConfigProvider locale={ruLang}>
            {isMobileVersion && (
              <MobileRouter/>
            )}
            {!isMobileVersion && (
              <DesktopRouter/>
            )}
          </ConfigProvider>
        </Provider>
      </ThemeProvider>
      <GlobalStyles />
    </>
  )
}

setConfig({ logLevel: 'debug' })

export default hot(module)(App)
