import styled from "styled-components";

export const Copyright = styled.div`
  color: ${({ theme }) => theme.colors.grayDark};
  padding: 20px;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
