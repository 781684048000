
/**
 * TODO: по хорошему списки в фильтрах нужно получать из БД
 */

export const organization = {
  "title": "Организация",
  "type": "pointer",
  "view": {
    "name": "select-from-organizations"
  }
}

export const user = {
  "title": "Менеджер",
  "type": "pointer",
  "view": {
    "name": "select-from-user"
  }
}

export const stage = {
  "type": "string",
  "title": "Стадия",
  "enum": [
    "new",
    "done",
  ],
  "oneOf": [
    {
      "const": "new",
      "title": "Обращение"
    },
    {
      "const": "work",
      "title": "В работе"
    },
    {
      "const": "measurement",
      "title": "Замер"
    },
    {
      "const": "prepayment",
      "title": "Предоплата"
    },
    {
      "const": "manufacture",
      "title": "Производство"
    },
    {
      "const": "delivery",
      "title": "Доставка"
    },
    {
      "const": "mounting",
      "title": "Монтаж"
    },
    {
      "const": "done",
      "title": "Выполнено"
    }
  ],
  "view": {
    "name": "select-from-enum"
  }
}

export const dateRange = {
  "type": "string",
  "format": "date-range",
  "title": "Дата"
}