import React from 'react'
import { Select } from 'antd'
import { IResourceFilterProps } from '../interfaces/interfaces'

const { Option } = Select

const ResourceFilter: React.FC<IResourceFilterProps> = ({
  selectedResources,
  onResourceChange,
  resources,
}) => {
  return (
    <Select
      mode="multiple"
      value={selectedResources}
      onChange={onResourceChange}
      style={{ width: '100%' }}
      placeholder="Все ресурсы"
      allowClear
    >
      {resources.map(resource => (
        <Option key={resource.id} value={resource.id}>
          {resource.value}
        </Option>
      ))}
    </Select>
  )
}

export default ResourceFilter
