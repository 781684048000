import React, {useEffect, useState} from 'react';
import { FieldGroup, FieldName } from './styles'
import { parseDate } from '../../../utilits'
import Card from 'antd-mobile/lib/card';
import WhiteSpace from 'antd-mobile/lib/white-space';
import fetchAPI from "../../../../lib/utils/fetch-api";
import {getDataOfType} from "../../../../lib/utils/get-data-of-type";

export const MountingCard = props => {
  const { data, mountingData } = props
  const [manager, setManager] = useState<any>(null)

  async function getManagerData(id){
    const result = await fetchAPI(`/api/profiles?type=user&user-id=${id}`)
    const data = getDataOfType(result, 'data', Array, [])
    const profileData = getDataOfType(data, '[0].profile-data', Object, {})
    setManager(profileData)
  }

  useEffect( () => {
    getManagerData(mountingData['case-manager'])
  }, [data])

  return (
    <>
      <Card>
        <Card.Header
          title="Монтаж"
          extra={<span>Даты</span>}
        />
        <Card.Body>
          <FieldGroup>
              <FieldName>Номер произв.заказа:</FieldName>
              {mountingData["production-order-number"]}
          </FieldGroup>
            <FieldName>ФИО сотрудника:</FieldName>
            { manager?.name }
          <FieldGroup>
            <FieldName>Дата монтажа (плановая):</FieldName>
            {data['date-plan']|| 'Не указана'}
          </FieldGroup>
          {data['date-adjusted'] && (
            <FieldGroup>
              <FieldName>Изменённая:</FieldName>
              {data['date-adjusted']}
            </FieldGroup>
          )}
          {data['date-fact'] && (
            <FieldGroup>
              <FieldName>Фактическая:</FieldName>
              {parseDate(data['date-fact'])}
            </FieldGroup>
          )}
        </Card.Body>
      </Card>
      <WhiteSpace size="lg" />
    </>
  )
}
