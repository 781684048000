import React from 'react'
import { TableWrapper, ThWrapper, TdWrapper, TrWrapper } from './styles' 

const stringsBold = [
  'Итого баланс (данные Axioma):',
  'Итого баланс (данные участника):',
  'Выплаты (данные Axioma):',
  'Поступления (данные участника):',
  'Поступления (данные Axioma):',
  'Выплаты (данные участника):',
  'Вознаграждение AXIOMA:',
  'Расходы начислено:',
  'Входящие ЗАКАЗЫ',
  'Доходы:',
  'Затраты на Изготовление:',
  'Затраты на Изготовление/Доставку Заказов:',
  'ВХОДЯЩИЕ ОФФ-ЛАЙН ЗАКАЗЫ',
  'Входящие ОН-ЛАЙН ЗАКАЗЫ',
]

function TableConversion({ titles, rows }: { titles: any[], rows: any[]}) {
  return (
    <TableWrapper>
      <TrWrapper>
        {titles.map(title => (
          <ThWrapper>
            {title}
          </ThWrapper>
        ))}
      </TrWrapper>
      {
        rows.map(row => (
          <TrWrapper>
           {row.map(column => (
             <TdWrapper>
               {stringsBold.includes(column) ? <b>{column}</b>: column}
             </TdWrapper>
            ))}
          </TrWrapper>
        ))
      }    
    </TableWrapper>
  )
}


export default TableConversion
