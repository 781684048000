import { useState, useCallback } from 'react'
import fetchAPI from '../../../../../lib/utils/fetch-api'

const useFetchData = (id, updatePagination, setOrganization, setSorter, setData) => {
  const [loading, setLoading] = useState(false)

  const setOfficeState = useCallback(async (id, state) => {
    try {
      await fetchAPI(`/api/office/state`, { method: 'POST', body: JSON.stringify({ id, state }) })
      getData() // Обновляем данные после изменения состояния офиса
    } catch (e) {
      alert('При выполнении запроса возникла ошибка')
      console.error(e)
    }
  }, [])

  const getData = useCallback(() => {
    try {
      setLoading(true)
      const urlParams = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI(`/api/offices/${id}`, {
        method: 'POST',
        body: JSON.stringify(urlParams)
      })
        .then(({ data, pagination, sorter, organization }) => {
          if (data) {
            updatePagination(pagination)
            setOrganization(organization)
            setSorter(sorter)
            setData(data.map(i => ({ ...i, setOfficeState })))
          }
          setLoading(false)
        })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [id, updatePagination, setOrganization, setSorter, setData, setOfficeState])

  return { loading, getData, setOfficeState }
}

export default useFetchData
