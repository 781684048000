import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import B2BLayout from '../../layouts/b2b'
import { EditCollectionObject } from '../../components/app-edit-collection-object'
import { createCollections } from '../../../lib/utils/collections'
import { getCollectionsMetadata } from '../../store/collections/actions'

const AdminNewObjectPage = () => {
  const dispatch = useDispatch()
  const { name } = useParams()  as any
  const collections = useSelector(createCollections)

  useEffect(() => {
    dispatch(getCollectionsMetadata(name))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const breadcrumbs = useMemo(() => [{
      href: '/admin',
      title: 'Администрирование',
    }, {
      href: '/admin/collections',
      title: 'Справочники',
    }, {
      href: `/admin/collections/${name}/objects`,
      title: (collections && collections['title']) || '...',
    }],
    [collections, name])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <EditCollectionObject title="Добавление новой записи" collectionName={name} />
    </B2BLayout>
  )
}

export default AdminNewObjectPage
