import styled from 'styled-components'

export const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

export const FilterWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  padding-bottom: 8px;
`
