import React from 'react'
import { Select } from 'antd'
import {IFilterOption} from "../../model/types/types";
export interface IYearFilterProps {
  selectedYears: string[]
  onYearChange: (years: string[]) => void
  years: IFilterOption[]
  style?: React.CSSProperties
}

const { Option } = Select

const YearFilter: React.FC<IYearFilterProps> = ({ selectedYears, onYearChange, years, style }) => {
  return (
    <Select
      mode="multiple"
      value={selectedYears}
      onChange={onYearChange}
      style={style}
      placeholder="Все года"
      allowClear
    >
      {years.map(year => (
        <Option key={year.id} value={year.id}>
          {year.value}
        </Option>
      ))}
    </Select>
  )
}

export default YearFilter
