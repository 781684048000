import {IOrdersDetailData, IOrdersDetailProduct, IOrdersDetailService} from "./interfaces";

export const ORDERS_DETAIL_DATA = 'ORDERS_DETAIL_DATA';
export const ORDERS_DETAIL_FAILED = 'ORDERS_DETAIL_FAILED';
export const ORDERS_DETAIL_LOADING = 'ORDERS_DETAIL_LOADING';
export const ORDERS_DETAIL_SET_EDIT_PRODUCT_DETAIL = 'ORDERS_DETAIL_SET_EDIT_PRODUCT_DETAIL';
export const ORDERS_DETAIL_CLEAR_EDIT_PRODUCT_DETAIL = 'ORDERS_DETAIL_CLEAR_EDIT_PRODUCT_DETAIL';
export const ORDERS_DETAIL_SET_EDIT_SERVICE_DETAIL = 'ORDERS_DETAIL_SET_EDIT_SERVICE_DETAIL';
export const ORDERS_DETAIL_CLEAR_EDIT_SERVICE_DETAIL = 'ORDERS_DETAIL_CLEAR_EDIT_SERVICE_DETAIL';
export const ORDERS_DETAIL_CLEAR = 'ORDERS_DETAIL_CLEAR';


export function setOrdersDetailData(data: IOrdersDetailData) {
    return async dispatch => {
        dispatch({type: ORDERS_DETAIL_DATA, payload: data})
    }
}

export function setOrdersDetailFailed(error: string) {
    return async dispatch => {
        dispatch({type: ORDERS_DETAIL_FAILED, payload: error})
    }
}

export function setOrdersDetailLoading() {
    return async dispatch => {
        dispatch({type: ORDERS_DETAIL_LOADING})
    }
}

export function setOrdersDetailEditProduct(data: IOrdersDetailProduct) {
    return async dispatch => {
        dispatch({type: ORDERS_DETAIL_SET_EDIT_PRODUCT_DETAIL, payload: data})
    }
}

export function clearOrdersDetailEditableProduct() {
    return async dispatch => {
        dispatch({type: ORDERS_DETAIL_CLEAR_EDIT_PRODUCT_DETAIL})
    }
}

export function setOrdersDetailEditService(data: IOrdersDetailService) {
  return async dispatch => {
    dispatch({type: ORDERS_DETAIL_SET_EDIT_SERVICE_DETAIL, payload: data})
  }
}

export function clearOrdersDetailEditableService() {
  return async dispatch => {
    dispatch({type: ORDERS_DETAIL_CLEAR_EDIT_SERVICE_DETAIL})
  }
}

export function clearOrdersDetail() {
    return async dispatch => {
        dispatch({type: ORDERS_DETAIL_CLEAR})
    }
}
