import React, {Suspense, useCallback, useEffect} from 'react'
import {Form} from 'antd'
import './index.css'
import {ProductsDetail} from '../../../../../components/v2/product-detail'
import {useDispatch} from 'react-redux'
import {getOrderDetailData} from '../../../../../store/ordersDetail/action-creators'
import {clearOrdersDetail} from '../../../../../store/ordersDetail/actions'


const ProductsTab = ({id}) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const entityType = 'case'

    const fetchData = useCallback(() => [
        dispatch(getOrderDetailData(entityType, id))
    ], [entityType, id])

    useEffect(() => {
        fetchData()
        return () => {
            dispatch(clearOrdersDetail())
        };
    }, [])

    return (
        <Suspense fallback={''}>
            <ProductsDetail id={id} entityType={entityType} reloadData={fetchData}/>
        </Suspense>
    )
}

export default ProductsTab
