import styled from 'styled-components'

export const UserProfile = styled.div`
  background: none;
  padding: 15px;
  user-select: none;
  cursor: pointer;
  .ant-layout-sider-collapsed & {
    display: none;
  }
`

export const MapWrapper = styled.div`
  padding: 10px 0;
`

export const MapTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 12px;
  span {
    color: #c5bfbf;
    margin-left: 5px;
  }
`

export const MapContent = styled.div`
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  border-radius: 5px;
  background: white;
  padding: 36px;
`

export const TabContent = styled.div`
  padding: 10px;
`

export const AlertWrapper = styled.div`
  margin-bottom: 40px;
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 16px;
`

export const TaskWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`

export const FiltersContainer = styled.div`
  padding: 10px 0;

  .ant-select {
    width: 100%;
  }
`

export const StatisticsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0;
  gap: 10px;
  margin-bottom: 8px;
`

export const StatisticsCard = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  border-radius: 5px;
  padding: 10px;
  flex: 1;
  text-align: center;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TableContainer = styled.div`
  overflow-x: auto;
  background-color: white;
  .ant-table-wrapper {
    background-color: white;
  }
`

export const Sidebar = styled.div`
  padding: 16px;
  height: 100%;
  background: #fff;
`

export const SidebarTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 12px;
`

export const TableTitle = styled.h3`
  font-size: 20px; /* Увеличим размер заголовков таблиц */
  font-weight: bold;
  padding-bottom: 12px;
`
