import React, { useCallback, useRef, useState, FC, useEffect, useMemo } from 'react';
import InputItem from 'antd-mobile/lib/input-item';
import DatePicker from 'antd-mobile/lib/date-picker';
import List from 'antd-mobile/lib/list';
import MaskedInput from 'react-input-mask'

import FileS3 from '../../../../connectors/multi-file-s3-json-form/multi-file-s3-json-form'
import { InputWrapper, DatepickerWrapper, DatetimeWrapper, TextareaWrapper } from './styles'
import { IAdditionalValidationMenuComponentProps } from './interface'
import { datepickerLocale } from './consts'
import TextareaItem from 'antd-mobile/lib/textarea-item';

export const AdditionalValidationMenuComponent: FC<IAdditionalValidationMenuComponentProps> = (props) => {
  const { format, code, value = '', title = '', onChange, onInProcess = () => {} } = props
  const inputRef = useRef<Record<string, HTMLInputElement>>({})
  const [inputValue, setInputValue] = useState<Record<string, any>>({})
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (value && code) {
      setInputValue({
        [code]: value,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (inputRef.current && inputRef.current[code]) {
      inputRef.current[code].focus()
    }
  }, [code])

  const handleUploadDocument = useCallback(async (fileId: number) => {
    onInProcess(false)

    await onChange({
      [code]: fileId,
    })
  }, [code, onInProcess, onChange])

  const handleInputChange = useCallback(newValue => {
    let newInputValue = inputValue

    setInputValue(prevState => {
      newInputValue = {
        ...prevState,
        [code]: newValue,
      }

      return newInputValue
    })

    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      if (Object.keys(newInputValue).length > 0) {
        onChange(newInputValue)
      }
    }, 1000)
  }, [code, inputValue, onChange])

  const handleDatepickerChange = useCallback(date => {
    console.log({ date })
    onChange({
      [code]: date,
    })
  }, [code, onChange])

  const handleDatetimeChange = useCallback(event => {
    let result: string = ''
    let name: string = ''

    if (event.target) {
      result = event['target']['value']
      name = event['target']['name']
    }

    onChange({
      [code]: {
        ...(value as any),
        [name]: result
      },
    })
  }, [code, onChange, value])

  const handleTextareaChange = useCallback((value: string | undefined) => {
    onChange({
      [code]: value,
    })
  }, [code, onChange])

  const handlers = useMemo(() => ({
    'file-s3': handleUploadDocument,
    'production-order-number': handleInputChange,
    timepicker: handleDatetimeChange,
    textarea: handleTextareaChange,
  }), [
    handleDatetimeChange,
    handleInputChange,
    handleTextareaChange,
    handleUploadDocument,
  ])

  const DatePickerMemo = useMemo(() => {
    return (
      <DatepickerWrapper>
        <DatePicker
          mode="date"
          title="Выбор даты"
          extra="гггг-мм-дд"
          value={value as any}
          onChange={handleDatepickerChange}
          locale={datepickerLocale}
        >
          <List.Item arrow="horizontal">Выбор даты</List.Item>
        </DatePicker>
      </DatepickerWrapper>
    )
  }, [value, handleDatepickerChange])

  return (
    <>
      {format === 'datepicker' && DatePickerMemo}
      {format === 'timepicker' && (
        <DatetimeWrapper>
          <MaskedInput
            className='ant-input'
            name="start"
            mask="99:99"
            placeholder="чч:мм"
            onChange={handlers[format]}
            value={value['start'] as any}
          />
          <MaskedInput
            className='ant-input'
            name="end"
            mask="99:99"
            placeholder="чч:мм"
            onChange={handlers[format]}
            value={value['end'] as any}
          />
        </DatetimeWrapper>
      )}
      {format === 'textarea' && (
        <TextareaWrapper>
          <TextareaItem
            autoHeight
            rows={3}
            count={256}
            onChange={handlers[format]}
            value={value as any}
            placeholder="Введите комментарий"
          />
        </TextareaWrapper>
      )}
      {format === 'file-s3' && (
        <FileS3
          schema={{ 'title': title }}
          value={value}
          onChange={handlers[format]}
          onStartUpload={onInProcess}
        />
      )}
      {code === 'production-order-number' && (
        <InputWrapper>
          <InputItem
            ref={ref => inputRef.current[code] = ref}
            type="number"
            placeholder="Введите номер заказа"
            value={inputValue[code]}
            onChange={handlers[code]}
          />
        </InputWrapper>
      )}
    </>
  )
}
