import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import React from 'react'
import { DataType } from './interface'
import { Sorter } from '../../../interfaces'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { Typography, Modal } from 'antd'
import { ButtonActionWrapper } from '../../../components/list-operations/styles'
import fetchAPI from '../../../../lib/utils/fetch-api'

const Columns: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 40,
    fixed: 'left',
    sorter: true,
  },
  {
    title: 'Дата оплаты',
    dataIndex: 'payment_date',
    key: 'payment_date',
    width: 100,
    sorter: true,
    render: key => dayjs(key).format('MM.YYYY'),
  },
  {
    title: 'Ресурс',
    width: 120,
    dataIndex: 'resource',
    key: 'resource',
    sorter: true,
  },
  {
    title: 'План оплат',
    width: 120,
    dataIndex: 'payment_plan',
    key: 'payment_plan',
    sorter: true,
  },
  {
    title: 'Факт оплат',
    width: 120,
    dataIndex: 'payment_fact',
    key: 'payment_fact',
    sorter: true,
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 150,
    sorter: true,
    render: key => dayjs(key).format('DD.MM.YYYY HH:mm'),
  },
  {
    title: 'Дата обновления',
    dataIndex: 'updated_at',
    key: 'updated_at',
    width: 150,
    sorter: true,
    render: key => dayjs(key).format('DD.MM.YYYY HH:mm'),
  },
  {
    title: 'Действия',
    key: 'actions',
    width: 50,
    fixed: 'right',
    render: (_, record) => {
      const history = useHistory()
      const handleEdit = () => {
        history.push(`/hr/payments/${record.id}`)
      }
      const handleDelete = () => {
        Modal.confirm({
          title: 'Вы уверены, что хотите удалить эту запись?',
          okText: 'Да',
          okType: 'danger',
          cancelText: 'Нет',
          onOk: async () => {
            await fetchAPI(`/api/hr/payments/${record.id}`, { method: 'DELETE' })
            history.go(0)
          },
        })
      }
      return (
        <span style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px' }}>
          <Typography.Link onClick={handleEdit}>
            <ButtonActionWrapper title="Редактировать">
              <EditOutlined />
            </ButtonActionWrapper>
          </Typography.Link>
          <Typography.Link onClick={handleDelete}>
            <ButtonActionWrapper title="Удалить" style={{ color: 'red' }}>
              <DeleteOutlined />
            </ButtonActionWrapper>
          </Typography.Link>
        </span>
      )
    },
  },
]

export const getColumns = (sorter: Sorter) => {
  return Columns.map(item => {
    if (item.key === sorter.sort_field) {
      item.sortOrder = sorter.sort_order
    } else {
      delete item.sortOrder
    }
    return item
  })
}
