import {
    STAGE_PLAN_LOADING,
    STAGE_PLAN_DATA,
    STAGE_PLAN_FAILED,
    STAGE_PLAN_CLEAR
} from "./actions";
import {StagePlanItemI} from "../../components/v2/stage-plan/model/types/types";


interface IStagePlan {
    isLoading: boolean,
    error: string,
    items?: StagePlanItemI[]
}


const initialState: IStagePlan = {
    items: [],
    isLoading: false,
    error: ''
}

export const stagePlanReducer = (
    state = initialState,
    {type, payload},
) => {
    switch (type) {
        case STAGE_PLAN_LOADING:
            return {
                ...state,
                isLoading: true,
                items: [],
                error: ''
            }
        case STAGE_PLAN_DATA:
            return {
                ...state,
                isLoading: false,
                items: payload,
                error: ''
            }
        case STAGE_PLAN_FAILED:
            return {
                ...state,
                items: [],
                isLoading: false,
                error: payload
            }
        case STAGE_PLAN_CLEAR:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}
