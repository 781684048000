import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { CollectionObjectList } from '../../components/app-collection-object-list/collection-object-list'

export function AdminObjectsListPage() {
  const location = useLocation()
  const { name } = useParams()  as any
  const [collectionMetadata, setCollectionMetadata] = useState({})
  const breadcrumbs = useMemo(() => (
    [{
      href: '/admin',
      title: 'Администрирование'
    }, {
      href: '/admin/collections',
      title: 'Справочники',
    }, {
      href: location.pathname,
      title: (collectionMetadata && collectionMetadata['title']) || '...',
    }]
  ), [collectionMetadata, location.pathname])

  useEffect(() => {
    const getData = async () => {
      const metadataResult = await fetchAPI(`/api/collections/${name}/metadata`)
      if (metadataResult) {
        if (typeof metadataResult['data'] === 'object') {
          const data = metadataResult['data']
          setCollectionMetadata(data)
        }
      }
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <CollectionObjectList collectionName={name} />
    </B2BLayout>
  )
}
