import React, {memo, useCallback, useEffect} from 'react'
import { Tabs } from 'antd'
import {HStack} from '../../../../../ui/Stack'
import cls from '../../card-hr.module.css'
import {HrTimeLine} from '../hr-timeline/hr-timeline'
import {classNames} from '../../../../../lib/classNames/classNames'
import {useDispatch} from 'react-redux'
import {setStagePlanClear} from '../../../../../store/stagePlan/actions'
import {HrFilesTimeline} from '../hr-files-timeline/hr-files-timeline'
import {HrStagePlan} from '../hr-stage-plan/hr-stage-plan'
import {HrChats} from '../hr-chats/hr-chats'
import {setTimelineClear} from "../../../../../store/timeline/actions";
import {getTimeLineData} from "../../../../../store/timeline/action-creators";


// export interface HrTabsTabItem {
//     title: string;
//     tabContent: ReactNode;
// }

interface HrTabsProps {
    className?: string
    onChange?: (key: string) => void;
    onTabClick?: (key: string) => void;
    id: string | number
}

export const HrTabs: React.FC<HrTabsProps> = memo((props: HrTabsProps) => {
    const {className, onChange, onTabClick, id} = props;
    const dispatch = useDispatch();

    useEffect(() => {
        let mounted = true
        if (id !== 'new') {
            if (mounted) {
                dispatch(getTimeLineData('hr', id));
           //     dispatch(getStagePlanData('hr',id));
            }
        }
        return () => {
            mounted = false
            dispatch(setTimelineClear());
            dispatch(setStagePlanClear());
        };
    }, [id, dispatch])


    const handleOnChange = useCallback((key) => {
        if (onChange) {
            onChange(key)
        }
    }, [onChange])

    const handleOnTabClick = useCallback((key) => {
        if (onTabClick) {
            onTabClick(key)
        }
    }, [onTabClick])
//fixme можно декомпозировать
    return <Tabs
        onChange={handleOnChange}
        type="card"
        style={{marginTop: '2rem', backgroundColor: '#fff', paddingBottom: '2rem'}}
        className={classNames('mapContent', {}, [className])}
        onTabClick={handleOnTabClick}
    >
        <Tabs.TabPane tab="Лента событий" key="1">
            <HStack align={'start'} justify={'between'}>
                <div className={cls.hrFilesTimeline}>
                    <HrStagePlan id={id}/>
                </div>
                <div className={cls.hrTimeline}>
                    <HrTimeLine id={id}/>
                </div>
            </HStack>
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span className="chatTabText">Чаты</span>} key="3" className='chatBox'>
            <HrChats id={id}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Прикрепленные файлы" key="2">
            <HStack align={'start'} justify={'between'}>
                <div className={cls.hrFilesTimeline}>
                    <HrFilesTimeline id={id} />
                </div>
            </HStack>
        </Tabs.TabPane>
    </Tabs>
});

export default HrTabs;
