import { Dispatch } from 'redux'
import { MenuAction, MenuItem } from './interfaces'
import fetchApi from '../../../lib/utils/fetch-api'

export const fetchMenuRequest = () => ({
  type: 'FETCH_MENU_REQUEST' as const,
})

export const fetchMenuSuccess = (menu: MenuItem[]) => ({
  type: 'FETCH_MENU_SUCCESS' as const,
  payload: menu,
})

export const fetchMenuFailure = (error: string) => ({
  type: 'FETCH_MENU_FAILURE' as const,
  payload: error,
})

export const fetchMenu = () => {
  return (dispatch: Dispatch<MenuAction>) => {
    dispatch(fetchMenuRequest())
    fetchApi('/api/menu')
      .then(response => response)
      .then(data => dispatch(fetchMenuSuccess(data as MenuItem[])))
      .catch(error => dispatch(fetchMenuFailure(error.message)))
  }
}

export const setCounterData = data => {
  return {
    type: 'SET_COUNTER_DATA' as const,
    payload: data,
  }
}
