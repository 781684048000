import React, { useMemo, useCallback, useContext } from 'react'
import { Timeline, Space, Button } from 'antd'
import { formatDate } from './activity-timeline-Item'
import {FileOutlined, CopyOutlined, DeleteOutlined} from '@ant-design/icons'
import {TimeLineContentWrapper, TimeLineContentHeaderWrapper, DotStyle,} from './styles'
import filesize from 'filesize'
import ActivityTimelineContext from './activity-timeline-context'

const dotColor = '#2fc4f5'

function ActivityTimelineFile({ type, body, date, id }: any){
  const dateFormat = useMemo(() => formatDate(date), [date])

  const copyBuffer = (url) => {
    window?.navigator?.clipboard?.writeText(url)
    return undefined
  }

  const contextProps = useContext(ActivityTimelineContext)
  const deleteFile = useCallback(async () => {
    const { deleteFile } = contextProps[type]
    await deleteFile({ id })
  },[contextProps, type, id])

  return (
    <Timeline.Item dot={<DotStyle color={dotColor}><FileOutlined style={{ fontSize: '16px' }} /></DotStyle>}>
      <TimeLineContentWrapper>
        <TimeLineContentHeaderWrapper>
          <Space>
            <span style={{ fontWeight: 'bold' }}>Файл</span>
            <span style={{ color: '#a8adb4' }}>{dateFormat}</span>
          </Space>
        </TimeLineContentHeaderWrapper>
        <Space style={{display:'block'}}>
          {Array.isArray(body.urls) && body.urls.map((item, index) => (
            <Space key={index}>
              <a href={item.url} style={{ width: 'min-content', whiteSpace: 'nowrap' }}>
                {item.name}
              </a>
              <span style={{ color: '#a8adb4' }}>{`( ${filesize(item.size)} )`}</span>
              <Button
                style={{ width: 'min-content', border: 'none', padding: 0 }}
                onClick={copyBuffer(item.url)}
              >
                <CopyOutlined />
              </Button>
              <Button
                style={{ width: 'min-content', border: 'none', padding: 0 }}
                onClick={deleteFile}
              >
                <DeleteOutlined />
              </Button>
            </Space>
          ))}
        </Space>
      </TimeLineContentWrapper>
    </Timeline.Item>
  )
}

export default ActivityTimelineFile
