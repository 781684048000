import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getDataOfType } from '../../../../../lib/utils/get-data-of-type'

const createSession = createSelector(
  (state) => state.session,
  (sessionData) => sessionData,
)

const useSessionData = () => {
  const { sessionData } = useSelector(createSession)
  const isLeader = getDataOfType(sessionData, 'organization.id', Number, null) === 1
  return { sessionData, isLeader }
}

export default useSessionData
