import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { reloadActionsTimeLine, caseDeleteFile, caseEditNote } from 'src/react-app/store/appeals/actions'
import ActivityTimeLine from 'src/react-app/components/activity-timeline'
import UploadFiles from 'src/react-app/connectors/case-upload-file'

const createActionsTimeLine = createSelector(
  (state: any) => state.appeals,
  appealsActionsTimeLine => appealsActionsTimeLine,
  usersProfile => usersProfile,
  taskStages => taskStages
)

function ActivityTimeLineConnector() {
  const { appealsActionsTimeLine, usersProfile, taskStages } = useSelector(createActionsTimeLine)
  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      await dispatch(reloadActionsTimeLine())
    })()
  }, [dispatch])

  const actions = useMemo(() => ({
    deleteFile: props => dispatch(caseDeleteFile(props)),
    editNote: props => dispatch(caseEditNote(props))
  }), [dispatch])

  const data = useMemo(() => ({
    actionsItems: appealsActionsTimeLine.filter(item => ['file', 'dynamic-file', 'payment'].includes(item['type'])),
    usersProfile: usersProfile,
    taskStages: taskStages
  }), [appealsActionsTimeLine, usersProfile, taskStages])

  return (
    <div>
      <div style={{ marginBottom: 40 }}>
        <UploadFiles />
      </div>
      <ActivityTimeLine actions={actions} data={data} />
    </div>
  )
}

export default ActivityTimeLineConnector

