import {IEnumsData, IFormData} from './interfaces'

export const ORDERS_FORM_LOADING = 'ORDERS_FORM_LOADING'
export const ORDERS_FORM_DATA = 'ORDERS_FORM_DATA'
export const ORDERS_FORM_FAILED = 'ORDERS_FORM_FAILED'
export const ORDERS_FORM_ID_UPDATE = 'ORDERS_FORM_ID_UPDATE'
export const ORDERS_ENUMS_LOADING = 'ORDERS_ENUMS_LOADING'
export const ORDERS_ENUMS_DATA = 'ORDERS_ENUMS_DATA'
export const ORDERS_ENUMS_FAILED = 'ORDERS_ENUMS_FAILED'


/* ============== ORDERS_FORM ============== */
export function setOrdersFormLoading() {
  return async dispatch => {
    dispatch({type: ORDERS_FORM_LOADING})
  }
}

export function setOrdersFormData(formData: IFormData) {
  return async dispatch => {
    dispatch({type: ORDERS_FORM_DATA, payload: formData})
  }
}

export function setOrdersFormFailed(error: string) {
  return async dispatch => {
    dispatch({type: ORDERS_FORM_FAILED, payload: error})
  }
}

export function setOrderId(id: string) {
  return async dispatch => {
    dispatch({type: ORDERS_FORM_ID_UPDATE, payload: id})
  }
}

/* ============== end ORDERS_FORM ============== */

/* ============== ORDERS_ENUMS ============== */
export function setOrdersEnumsLoading() {
  return async dispatch => {
    dispatch({type: ORDERS_ENUMS_LOADING})
  }
}

export function setOrdersEnumsData(enumsData: IEnumsData) {
  return async dispatch => {
    dispatch({ type: ORDERS_ENUMS_DATA, payload: enumsData })
  }
}

export function setOrdersEnumsFailed(error: string) {
  return async dispatch => {
    dispatch({ type: ORDERS_ENUMS_FAILED, payload: error })
  }
}

/* ============== end ORDERS_ENUMS ==============  */


export function reloadTablePayment() {return}
