import {
    ORDERS_DETAIL_DATA,
    ORDERS_DETAIL_FAILED,
    ORDERS_DETAIL_LOADING,
    ORDERS_DETAIL_SET_EDIT_PRODUCT_DETAIL,
    ORDERS_DETAIL_CLEAR_EDIT_PRODUCT_DETAIL,
    ORDERS_DETAIL_CLEAR,
    ORDERS_DETAIL_SET_EDIT_SERVICE_DETAIL,
    ORDERS_DETAIL_CLEAR_EDIT_SERVICE_DETAIL
} from './actions'
import {IOrdersState} from './interfaces'


const initialState: IOrdersState = {
    data: {
        products: [],
        services: [],
        payments: [],
        editableProduct: undefined,
        editableService: undefined,
        debt: 0
    },
    isLoading: false,
    error: '',
}

export const ordersDetailReducer = (
    state = initialState,
    {type, payload},
) => {
    switch (type) {
        case ORDERS_DETAIL_DATA:
            return {
                ...state,
                isLoading: false,
                data: payload,
                error: ''
            }
        case ORDERS_DETAIL_FAILED:
            return {
                ...state,
                data: {...initialState.data},
                isLoading: false,
                error: payload
            }
        case ORDERS_DETAIL_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case ORDERS_DETAIL_SET_EDIT_PRODUCT_DETAIL:
            return {
                ...state,
                data: {
                    ...state?.data,
                    editableProduct: payload
                },
            }
        case ORDERS_DETAIL_CLEAR_EDIT_PRODUCT_DETAIL:
            return {
                ...state,
                data: {
                    ...state?.data,
                    editableProduct: undefined
                },
            }
        case ORDERS_DETAIL_SET_EDIT_SERVICE_DETAIL:
            return {
                ...state,
                data: {
                    ...state?.data,
                    editableService: payload
                },
            }
        case ORDERS_DETAIL_CLEAR_EDIT_SERVICE_DETAIL:
            return {
                ...state,
                data: {
                    ...state?.data,
                    editableService: undefined
                },
            }
        case ORDERS_DETAIL_CLEAR:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}
