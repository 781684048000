import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Table, Alert, Button, Space } from 'antd'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import B2BLayout from 'src/react-app/layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import { tableRowClick, getColumnsOfSchema, formatListData2 } from 'src/lib/utils/list'
import { PlusOutlined } from '@ant-design/icons'
import selectedSchema from './json-schema.json'
import { beforeDataColumns, afterDataColumns } from './utils'
import OrderFilter from './orders-filter'
import { formDataToUrlParams } from 'src/react-app/pages/list-orders/utils'

function booleanInversion(value) {
  return typeof value === 'boolean'? value === false: value
}

function recalcFormData(item) {
  return {
    ...item,
    'is-active': booleanInversion(item['is-active']) 
  }
}

function downloadUrl(url, exportName) {
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', url)
  downloadAnchorNode.setAttribute('download', exportName)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}


export default function AdminObjectsListPage() {
  const location = useLocation()
  const history = useHistory()
  const [dataSource, setDataSource] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [dataColumns, setDataColumns] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const orderFiltersRef = useRef({})
  const breadcrumbs = useMemo(() => (
    [{
      href: location.pathname,
      title: 'Организации',
    }, {
      href: location.pathname,
      title: 'Список организаций',
    }]
  ), [location.pathname])

  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(beforeDataColumns, dataColumns, afterDataColumns).filter(item => item.title), [dataColumns])
  const cbTableRowClick = useCallback(({ id }) => history.push(`/organizations/${id}`), [history])

 
  const onButtonCreate = useCallback(() => history.push(`/organizations/new`), [history])


  const getData = useCallback(async (value) => {
    const urlParams = formDataToUrlParams(value)
    const pageErrors: string[] = []
    const collections = await fetchAPI(`/api/organizations-list?${urlParams}`)
    const collectionData = getDataOfType(collections, 'data', Array, null)
    if(collectionData === null) {
      pageErrors.push('Некорректный ответ сервера при получении метаданных коллекции')
      return 
    }
    const dataSource: any[] = formatListData2(collectionData.map(value => recalcFormData(value)), selectedSchema)
    const newDataSource = dataSource.map(item => ({ 
      ...item, 
    }))
    const newDataColumns: any[] = getColumnsOfSchema(selectedSchema)

    setDataColumns(newDataColumns)
    setDataSource(newDataSource)
    setPageErrors(pageErrors)
    setIsDataLoading(false)
  }, [])

  useEffect(() => {
    getData({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData])

  const onOrderFilter = useCallback(value => {
    Object.assign(orderFiltersRef.current, value)
    getData(value)
  }, [getData])

  const onPrint = useCallback(async () => {
    const urlParams = formDataToUrlParams(orderFiltersRef.current)
    console.log(urlParams)
    downloadUrl(`/api/print/excel-org-list?${urlParams}`, `organizations-pay.xlsx`)
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <Space direction="horizontal">
        <Button onClick={onButtonCreate} style={{ marginBottom: 16 }}>
          <PlusOutlined /> Создать организацию
        </Button>
        <Button style={{ marginBottom: 16 }} onClick={onPrint}>Экспорт в эксель</Button>
      </Space>
      <Space align="baseline">
        <OrderFilter onChange={onOrderFilter} />
      </Space>
      {(pageErrors.length > 0) ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
            />
        </div>
      ): null}


      <Table
        rowKey="id"
        size="small"
        columns={finalColumns}
        dataSource={dataSource}
        pagination={false}
        loading={isDataLoading}
        onHeaderRow={(column: any) => ({
          style: {
            fontSize: (column.key && column.key.substr(-3) === '-at') ? 9 : 13,
          }
        })}
        onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick)})}
      />
      
    </B2BLayout>
  )
}
