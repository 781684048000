import styled from 'styled-components'

export const UserProfile = styled.div`
  background: none;
  padding: 15px;
  user-select: none;
  cursor: pointer;
`

export const MapWrapper = styled.div`
  padding: 10px 49px;
`

export const MapTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
`

export const MapContent = styled.div`
  padding: 24px 36px;
`
export const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  `

export const ButtonWrapper = styled.div`
  display: flex;

  & > button {
    margin-right: 10px;
  }
`
