import fetchAPI from '../../../lib/utils/fetch-api'
import {
  setMeasurementId,
  setMeasurementEnumsData,
  setMeasurementEnumsFailed,
  setMeasurementEnumsLoading,
  setMeasurementFormData,
  setMeasurementFormFailed,
  setMeasurementFormLoading,
} from './actions'

export function getMeasurementForm(id) {
  return async dispatch => {
    dispatch(setMeasurementFormLoading())
    try {
      const response = await fetchAPI(`/api/measurement/${id}`) || []
      if (response['errors'] || response?.error) {
        let error = response['errors'] || response?.error
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setMeasurementFormFailed(error))
      }
      return dispatch(setMeasurementFormData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setMeasurementFormFailed('При загрузки полей возникла ошибка'))
    }
  }
}

export function nextStage(id, formData) {
  return async dispatch => {
    dispatch(setMeasurementFormLoading())
    try {
      const response =  await fetchAPI(
        `/api/measurement/${id}`,
        { method: 'POST', body: JSON.stringify(formData) }
      )

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error =  error.join( )
        }
        return dispatch(setMeasurementFormFailed(error))
      }
      return dispatch(setMeasurementFormData(response))
    } catch (err) {
      return dispatch(setMeasurementFormFailed(err as string))
    }
  }
}

export function updateFormData(id, formData) {
  return async dispatch => {
    try {
      const response = await fetchAPI(
        `/api/measurement/${id}`,
        {
          method: 'POST', body: JSON.stringify(formData)
        }
      )

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setMeasurementFormFailed(error))
      }

      if (response?.id) {
        return dispatch(setMeasurementId(response.id))
      }

      return
    } catch (err) {
      return dispatch(setMeasurementFormFailed(err as string))
    }
  }
}

export function getMeasurementEnums(id: string) {
  return async dispatch => {
    dispatch(setMeasurementEnumsLoading())
    try {
      const response = await fetchAPI(`/api/measurement/${id}/enum`)

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setMeasurementEnumsFailed(error))
      }

      return dispatch(setMeasurementEnumsData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setMeasurementEnumsFailed('При загрузке enum возникла ошибка'))
    }
  }
}

