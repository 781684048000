import React, { useCallback, useEffect, useState } from 'react'
import { Space, Input } from 'antd'
import SelectFromEnum from 'src/react-app/components/select-from-enum'
import InputDateRange from 'src/react-app/components/input-date-range'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { dateRange, stage, organization, region } from './utils'
import SelectFromOrganizations from 'src/react-app/connectors/select-from-organizations'
import InputPhoneFind from 'src/react-app/components/input-phone-find'
import SelectObjectFromCollection from "../../../connectors/json-form-select-object";
import MultiSelect from "../../../connectors/multi-select";
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)


function InputFilter({ onChange }) {
  const onPressEnter = useCallback(({ target }) => {
    onChange(target?.value)
  }, [onChange])
  const onLocalChange = useCallback(({ target }) => {
    const value = target?.value
    if (typeof value !== 'string' || value === '') {
      onChange(undefined)
    }
  }, [onChange])
  const onSearch = useCallback((value) => {
    onChange(value)
  }, [onChange])
  return <Input.Search prefix="Обрщ:" onPressEnter={onPressEnter} onChange={onLocalChange} onSearch={onSearch} allowClear style={{ width: 200 }} />
}

const rolesAll = [19, 12, 3, 4, 5, 6, 18]

function isDublicate(arr1, arr2) {
  const concatArray = arr1.concat(arr2)
  return concatArray.length > Array.from(new Set(concatArray)).length
}

function Filter({ onChange }) {
  const { sessionData } = useSelector(createSession)
  const [formData, setFormData] = useState<any>(() =>({
    'brand-id': undefined,
    'date-range': undefined,
    'region': undefined,
    'city': undefined,
    'organization-id': undefined,
    'stage-code': undefined,
    'user-id': undefined,
    'id': undefined,
  }))

  const [UISchemaSelectFromOrganizations, setUISchemaSelectFromOrganizations] = useState({
    options: { width: 200, allowClear: true, types: [5, 1, 6], showSearch: true, placeholder: 'Организация'}
  })
  const [UISchemaSelectFromBrands, setUISchemaSelectFromBrands] = useState({
    options: { width: 200, placeholder: 'Бренд' , allowClear: true, showSearch: true}
  })
  const [ UISchemaSelectFromEnum, setUISchemaSelectFromEnum] = useState({
    options: { width: 200, placeholder: 'Регион', allowClear: true, showSearch: true,  }
  })
  const [ UISchemaSelectFromEnum2, setUISchemaSelectFromEnum2] = useState({
    options: { width: 200, placeholder: 'Город', allowClear: true, showSearch: true,  }
  })
  const [ UISchemaSelectFromEnum3] = useState({
    options: { width: 200, placeholder: 'Стадия', allowClear: true, showSearch: true,  }
  })

  useEffect(() => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, 0)
    if (!isDublicate(roles, rolesAll)) {
      setFormData(data => ({ ...data, 'organization-id': 0 }))
      setUISchemaSelectFromEnum(({ options }) => ({ options: { ...options, disabled: true }  }))
      setUISchemaSelectFromEnum2(({ options }) => ({ options: { ...options, disabled: true }  }))
      setFormData(data => ({ ...data, 'organization-id': organizationId }))
      setUISchemaSelectFromOrganizations(({ options }) => ({ options: { ...options, disabled: true }  }))
      setUISchemaSelectFromBrands(({ options }) => ({ options: { ...options, disabled: true }  }))
    }
  }, [sessionData])

  const onChangeLocal = useCallback((value, name) => {
    const nextFormData = {}
    setFormData(prevFormData => Object.assign(nextFormData, prevFormData, { [name]: value ? encodeURIComponent(value) : value }))
    onChange(nextFormData)
  }, [onChange])

  const onChangeUser = useCallback(value => {
    onChangeLocal(value, 'city')
  }, [onChangeLocal])

  const onChangeDateRange = useCallback(value => {
    onChangeLocal(value, 'date-range')
  }, [onChangeLocal])

  const onChangeRegion = useCallback(value => {
    onChangeLocal(value, 'region')
  }, [onChangeLocal])

  const onChangeOrganization = useCallback(value => {
    onChangeLocal(value, 'organization-id')
  }, [onChangeLocal])

  const onChangeStageCode = useCallback(value => {
    onChangeLocal(value, 'stage-code')
  }, [onChangeLocal])

  const onChangeId = useCallback(value => {
    onChangeLocal(value, 'id')
  }, [onChangeLocal])

  const onChangePhone = useCallback(value => {
    onChangeLocal(value, 'phone')
  }, [onChangeLocal])

  const onChangeBrand = useCallback(value => {
    onChangeLocal(value, 'brand-id')
  }, [onChangeLocal])

  const brands = {
    type: "string",
    name: "brand",
    title: "Бренды",
  }

  return (
    <Space style={{display: 'flex', flexWrap: 'wrap', paddingBottom: '10px'}}>
      <InputFilter onChange={onChangeId} />
      <InputPhoneFind onEnter={onChangePhone}/>
      <MultiSelect
        value={formData['brand-id']}
        schema={brands}
        onChange={onChangeBrand}
        UISchema={UISchemaSelectFromBrands}
      />
      <SelectFromOrganizations
        value={formData['organization-id']}
        schema={organization}
        onChange={onChangeOrganization}
        UISchema={UISchemaSelectFromOrganizations}
      />
      <SelectFromEnum
        value={formData['stage-code']}
        schema={stage}
        onChange={onChangeStageCode}
        UISchema={UISchemaSelectFromEnum3}
      />
      <InputDateRange
        value={formData['date-range']}
        schema={dateRange}
        onChange={onChangeDateRange}
      />
      <SelectFromEnum
        value={formData['region']}
        schema={region}
        onChange={onChangeRegion}
        UISchema={UISchemaSelectFromEnum}
      />
      <SelectFromEnum
        value={formData['city']}
        schema={stage}
        onChange={onChangeUser}
        UISchema={UISchemaSelectFromEnum2}
      />
    </Space>
  )
}

export default Filter