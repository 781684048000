export const updateTimelineItemByIndex = (newDataItem, timelineItems, timelineDates ) => {
    const indexScope = timelineDates?.findIndex((dateItem) => dateItem == newDataItem.date);

     timelineItems[indexScope].items?.forEach((activityItem, indexActivityItem) => {
        if (indexActivityItem === newDataItem.index) {
            activityItem.data = {...activityItem?.data, ...newDataItem.data};
        }
    })
    return timelineItems;
}
