import styled from 'styled-components'

export const UserProfile = styled.div`
  background: none;
  padding: 15px;
  user-select: none;
  cursor: pointer;

  .ant-layout-sider-collapsed & {
    display: none;
  }
`

export const MapWrapper = styled.div`
  padding: 10px 0px;
`

export const HeaderTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 10px;
`

export const MapContent = styled.div`
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  background: white;

  padding: 24px 36px;
`
export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  box-shadow: 0 2px 4px 0 #cacaca80;
  background-color: #fdfdfd;
  margin-left: 5px;
  padding-top: 5px;
  padding-left: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
`

export const ItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`
