import React, {useCallback, useEffect, useState} from 'react'
import {Input, Select, Space, Tooltip} from 'antd'
import SelectFromOrganizations from 'src/react-app/connectors/select-from-organizations'
import SelectFromEnum from 'src/react-app/components/select-from-enum'
import InputDateRange from 'src/react-app/components/input-date-range'
import SelectFromUser from 'src/react-app/connectors/select-from-user'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { organization, dateRange, stage, user } from './utils'
import InputPhoneFind from 'src/react-app/components/input-phone-find'
import fetchAPI from "../../../lib/utils/fetch-api";
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

export interface Input {
  onChange: any,
  placeholder?: any,
  name?: any,
  value?: any,
}

const InputFilter = ({ onChange, placeholder, name, value }: Input) => {
  const onPressEnter = useCallback(({ target }) => {
    onChange(target?.value)
  }, [onChange])
  const onLocalChange = useCallback(({ target }) => {
    const value = target?.value
    if (typeof value !== 'string' || value === '') {
      onChange(undefined)
    }
  }, [onChange])

  const onSearch = useCallback((value) => {
    onChange(value)
  }, [onChange])

  return <Input.Search prefix={name ?? false} placeholder={placeholder ?? ''} onPressEnter={onPressEnter} onChange={onLocalChange} onSearch={onSearch} allowClear style={{ width: 180 }} />
}

const UISchemaSelectFromEnum = {
  options: { width: 200, placeholder: 'Стадия', allowClear: true, showSearch: true,  }
}

const UISchemaSelectFromCancellation = {
  options: { width: 200, placeholder: 'Причина отмены', allowClear: true, showSearch: true,  }
}
const UISchemaSelectFromOrderAttribute = {
  options: { width: 200, placeholder: 'Признак заказа', allowClear: true, showSearch: true,  }
}

const rolesAll = [19, 12, 3, 4, 5, 6, 18]

function isDublicate(arr1, arr2) {
  const concatArray = arr1.concat(arr2)
  return concatArray.length > Array.from(new Set(concatArray)).length
}

function Filter({ onChange }) {
  const { sessionData } = useSelector(createSession)
  const [cancellation, setCancellation] = useState<any>( async () => {
    fetchAPI('/api/collections/objects-grouped/reason-for-cancellation?access_key=axioma')
      .then( data => {
        const oneOf = [{const: 'is_null', title: "Пусто"}].concat(data.data.data.map( item => {
          const {id, name} = item;
          return {const: id, title: name}
        }))
        const enumArr = ['is_null'].concat( data.data.data.map( item => item.id) )
        setCancellation({
          "placeholder": "Причина отмены",
          "type": "pointer",
          "view": {
            "name": "select-from-enum"
          },
          enum: enumArr,
          oneOf
        })
      })
  })
  const [orderAttribute, setOrderAttribute] = useState<any>( async () => {
    fetchAPI('/api/collections/objects-grouped/orderattribute-client?access_key=axioma')
      .then( data => {
        const oneOf = [{const: 'is_null', title: "Пусто"}].concat(data.data.data.map( item => {
          const {id, name} = item;
          return {const: id, title: name}
        }))
        const enumArr = ['is_null'].concat( data.data.data.map( item => item.id) )
        setOrderAttribute({
          "placeholder": "Признак заказа",
          "type": "pointer",
          "view": {
            "name": "order-attribute"
          },
          enum: enumArr,
          oneOf
        })
      })
  })

  const [formData, setFormData] = useState<any>(() => {
    const data = {
      'organization-id': undefined,
      'range-dates': undefined,
      'stage-code': undefined,
      'user-id': undefined,
      'id': undefined,
      'field': undefined,
      'order': undefined,
      'name': undefined,
      'production-order-number': undefined,
      'workflow-id': undefined,
      'order-attribute': undefined,
      'type-dates': undefined
    }

    return {...data, ...Object.fromEntries(new URLSearchParams(location.search))}
  })

  const [UISchemaSelectFromUsers, setUISchemaSelectFromUsers] = useState({
    options: { width: 200, allowClear: true, showSearch: true, roleId: [2, 12], placeholder: 'Менеджер' }
  })
  const [UISchemaSelectFromOrganizations, setUISchemaSelectFromOrganizations] = useState({
    options: { width: 200, allowClear: true, types: [5, 1], showSearch: true, placeholder: 'Организация' }
  })

  useEffect(() => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, 0)
    if (!isDublicate(roles, rolesAll)) {
      setFormData(data => ({ ...data, 'organization-id': organizationId }))
      setUISchemaSelectFromOrganizations(({ options }) => ({ options: { ...options, disabled: true }  }))
      setUISchemaSelectFromUsers(({ options }) => ({ options: { ...options, 'organizationId': organizationId }  }))
    }
  }, [sessionData])


  const onChangeLocal = useCallback((value, name) => {
    const nextFormData = {}
    setFormData(prevFormData => Object.assign(nextFormData, prevFormData, { [name]: value }))
    if(nextFormData['date-range'] && !nextFormData['type-dates'])
      nextFormData['type-dates'] = "created-at"
    if(!nextFormData['date-range'] && nextFormData['type-dates']) {
      delete nextFormData['type-dates']
      return
    }
    if(Object.entries(nextFormData).length)
      onChange(nextFormData)
  }, [onChange])

  const onChangeOrganization = useCallback(value => {
    onChangeLocal(value || undefined, 'organization-id')
  }, [onChangeLocal])

  const onChangeUser = useCallback(value => {
    onChangeLocal(value || undefined, 'user-id')
  }, [onChangeLocal])

  const onChangeDateRange = useCallback(value => {
    onChangeLocal(value || undefined, 'date-range')
  }, [onChangeLocal])

  const onChangeDateType = useCallback((value) => {
    onChangeLocal(value || undefined, 'type-dates')
  }, [onChangeLocal])

  const onChangeStageCode = useCallback(value => {
    onChangeLocal(value || undefined, 'stage-code')
  }, [onChangeLocal])

  const onChangeId = useCallback(value => {
    onChangeLocal(value || undefined, 'id')
  }, [onChangeLocal])

  const onChangePhone = useCallback(value => {
    onChangeLocal(value || undefined, 'phone')
  }, [onChangeLocal])

  const onChangeName = useCallback(value => {
    onChangeLocal(value || undefined, 'name')
  }, [onChangeLocal])

  const onChangeProductionNumber = useCallback(value => {
    onChangeLocal(value || undefined, 'production-order-number')
  }, [onChangeLocal])

  const onChangeCancellation = useCallback(value => {
    onChangeLocal(value || undefined, 'reason-for-cancellation')
  }, [onChangeLocal])

  const onChangeOrderAttribute = useCallback(value => {
    onChangeLocal(value || undefined, 'order-attribute')
  }, [onChangeLocal])

  return (
    <Space className='orders-list'>
      <SelectFromOrganizations
        value={formData['organization-id']}
        schema={organization}
        onChange={onChangeOrganization}
        UISchema={UISchemaSelectFromOrganizations}
      />
      <SelectFromUser
        value={formData['user-id']}
        schema={user}
        onChange={onChangeUser}
        UISchema={UISchemaSelectFromUsers}
      />
      <div>
        <InputDateRange
          value={formData['date-range']}
          schema={dateRange}
          onChange={onChangeDateRange}
        />
        <Select
          onChange={onChangeDateType}
          value={formData['date-type']}
          defaultValue={'created-at'}
          options={[
            {value: 'created-at', label: 'Дата обращения'},
            {value: 'date-measurement', label: 'Дата замера'},
            {value: 'date-prepayment', label: 'Дата оплаты'},
            {value: 'date-shipping', label: 'Дата доставки'},
            {value: 'date-mounting', label: 'Дата монтажа'},
            {value: 'date-task', label: 'Дата задачи'}
          ]}
        />
      </div>
      <div className='free-space'></div>
      <InputFilter onChange={onChangeId} name={'ID'}/>
      <InputPhoneFind onEnter={onChangePhone} currentValue={formData['phone']} />
      <SelectFromEnum
        value={formData['stage-code']}
        schema={stage}
        onChange={onChangeStageCode}
        UISchema={UISchemaSelectFromEnum}
      />
      {cancellation && (
      <SelectFromEnum
        value={formData['reason-for-cancellation']}
        schema={cancellation}
        onChange={onChangeCancellation}
        UISchema={UISchemaSelectFromCancellation}
      />
      )}
      {orderAttribute && (
      <SelectFromEnum
        value={formData['order-attribute']}
        schema={orderAttribute}
        onChange={onChangeOrderAttribute}
        UISchema={UISchemaSelectFromOrderAttribute}
      />
      )}
      <InputFilter onChange={onChangeName} placeholder={'Контактное лицо клиента'} value={formData['name']}/>
      <InputFilter onChange={onChangeProductionNumber} placeholder={'Номер пр. заказа'} value={formData['production-order-number']}/>
    </Space>
  )
}

export default Filter