import { IAction, IErrors } from '../interfaces'
import {
  // LOAD_PAGE_REQUESTED,
  LOAD_PAGE_DONE,
  LOAD_PAGE_FAILED,
} from './actions'

const initialState: Object = {
  isLoading: true,
  lastResult: null,
}

export const pageReducer = (
  state = initialState, 
  { type, payload }: IAction<boolean | IErrors>,
) => {
  switch (type) {
    // case LOAD_PAGE_REQUESTED:
    //   return {
    //     ...state,
    //     isFetching: true,
    //     lastResponse: null,
    //     hasErrors: false,
    //   }
    case LOAD_PAGE_DONE:
      return {
        ...state,
        lastResult: payload,
        isLoading: false,
      }
    case LOAD_PAGE_FAILED:
      return {
        ...state,
        lastResult: payload,
        isLoading: false,
      }
    default:
      return state
  }
}

