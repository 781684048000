import React, { useEffect, useState } from 'react'
import fetchAPI from 'src/lib/utils/fetch-api'
import { Select } from 'antd';

function filterOption(input, option){
  return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

function SelectCollection({ value, schema, onChange, UISchema}:{ value?:any, schema?:any, onChange?:any, UISchema?:any }) {
  const [objects, setObjects] = useState<any[]>([{ id: value, value }])

  useEffect(() => {
    (async () => {
      const objectsResult: any = await fetchAPI(`/api/users`)
      const objectsFormat = objectsResult['data'] && objectsResult['data'].map(({ id, email }) => {
        const value = [id, email].join( ' | ')
        return { id, value }
      })
      setObjects(objectsFormat)
    })()
  }, [])

  return (
    <Select
      showSearch
      placeholder="Выберите запись"
      optionFilterProp="children"
      onChange={onChange}
      defaultValue={value}
      filterOption={filterOption}
    >
    {objects.map(({ id, value }) => (
      <Select.Option key={id} value={id}>{value}</Select.Option>
    ))}
    </Select>
  )
}

export default SelectCollection

