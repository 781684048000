import React, { useMemo } from 'react'
import { Space } from 'antd'
import moment from 'moment'

function formatDateTime(date, time){
  const fDate = moment(date).format('DD.MM.YYYY')
  const fTime = moment(time).format('HH:mm')
  return `${date? fDate: ''} ${time? ' ' + fTime: ''}`
}

function CellUserDataTime ({ responsible, date, time }){
  const dateTime = useMemo(() => {
    return formatDateTime(date, time)
  },[date,time])
  return <Space direction="vertical">
    <span>{dateTime}</span>
    <span>{responsible.map(({ name }) => name).join(', ')}</span>
  </Space>
}

export default CellUserDataTime