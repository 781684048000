import React, { useMemo, useCallback } from 'react'
import FormDataEditor from '../json-form-object-editor'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'

function Adaptor({ name, schema, formData, onChange }){
  const localSchema = useMemo(() => {
    return getDataOfType(schema, ['properties', name], Object, null)
  }, [name, schema])
  const localformData = useMemo(() => {
    return getDataOfType(formData, name, Object, {})
  }, [name, formData])
  const localOnChange = useCallback(({ formData: localformData }) => {
    onChange({ formData: { ...formData, [name]: localformData}, schema})
  },[schema, name, onChange, formData])
  return localSchema && <FormDataEditor formData={localformData} schema={localSchema} onChange={localOnChange} />
}

export default Adaptor