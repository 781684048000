import React, {memo, useMemo} from 'react'
import {Table, Typography} from 'antd'
import {
    IOrdersByMonth,
    IOrdersByMonthColumns
} from "../../model/types/types";

const {Title, Text} = Typography;
export interface OrdersByManagersTableProps {
    data?: IOrdersByMonth
    columns?: IOrdersByMonthColumns
}

const OrdersByMonthTable = (props: OrdersByManagersTableProps) => {
    const {data, columns} = props;
    if (!data || !columns) {
        return <Typography.Text>Сервер не может найти запрошенный ресурс. </Typography.Text>;
    }
    const months = useMemo(() => Object.keys(data), [data]);
    return (
        <div>{
            months.map((month) => (
                <Table
                    title={() => <Title level={5}>{month.replace(/[0-9]/g, ' ')}</Title>}
                    key={month}
                    dataSource={data[month]}
                    columns={columns}
                    pagination={false}
                    size="small"
                    style={{marginBottom: '40px'}}
                    summary={(currentData) => (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                {
                                    Object.entries(currentData[currentData.length - 1]).map(([key, val], index) => (
                                        <Table.Summary.Cell key={key} index={index}>
                                            <Text strong>{val}</Text>
                                        </Table.Summary.Cell>
                                    ))
                                }
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            ))
        }</div>

    )
}

export default memo(OrdersByMonthTable)
