import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Button, Space, Tooltip } from 'antd'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  appealsCurrentStep => appealsCurrentStep,
  workflows => workflows,
  stageCode => stageCode,
  targetId => targetId,
)

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

function filterRoles(currentRoles, fieldRoles){
  return fieldRoles.filter(role => currentRoles.includes(role))
}

function FormatTooltip({ type, item, oneOf, disabled, onClick }) {
  const { stageCode, targetId } = useSelector(createAppealsCurrentStep)

  const checkDebt = async (targetId) => {
    return await fetchAPI(`/api/web-hooks/add-payment-contract`, { method: 'POST',  body: JSON.stringify({
        targetId: Number(targetId),
        pay: {sum: 0,date: ''}
      })}).then( res => {
        return res.sum - res.paymentsSumAll > 0
    })
  }

  const nextValidators: any = useMemo(() => {
    const nextValidators = getDataOfType(item, `nextValidators`, Array, [])
      .map(item => item['title'])
    if (nextValidators.length === 0) {
      return []
    }
    return nextValidators.map(name => (
      getDataOfType(
        oneOf.find(item => item['const'] === name),
        `title`,
        String,
        name
      )
    ))
  }, [item, oneOf])

  const isProduct = useMemo(() => {
    const nextStageCode = getDataOfType(item, 'target', String, null)
    const stageProducts: Array<string> = ['shipped-from-production', 'team-order-acceptance', 'accepted-driver', 'accepted-client']
    return stageProducts.includes(nextStageCode)
  }, [item])

  return isProduct || Boolean(nextValidators.length) ? (
    <Tooltip
      placement="bottom"
      title={
        <Space direction="vertical" size={2}>
          {isProduct && <span>Выберите продукцию</span>}
          {Boolean(nextValidators.length) && <span>Обязательные поля:</span>}
          {nextValidators.map(title => (
            <span key={title}>{title}</span>
          ))}
        </Space>
      }
    >
      <Button disabled={disabled} onClick={onClick}>
        {item['name']}
      </Button>
    </Tooltip>
  ) : (
    <Button disabled={disabled} onClick={onClick}>
      {item['name']}
    </Button>
  )
}

function ButtonsTransition({ type, transition, transitionRequired, isBusy }:{
  type: any
  transition: any,
  transitionRequired: any
  isBusy?: boolean
}){
  const [stages, setStages] = useState([])
  const [oneOf, setOneOf] = useState([])
  const { appealsCurrentStep, workflows } = useSelector(createAppealsCurrentStep)
  const { sessionData } = useSelector(createSession)

  useEffect(()=> {
    const stages = getDataOfType(workflows, type, Array, [])
    setStages(stages)
  }, [type, workflows])

  useEffect(()=> {
    (async () => {
      const result = await fetchAPI('/api/schemas?fields=properties&filter[schemas]=workflows')
      const oneOf = getDataOfType(result, 'data.data[0].properties.access-rights.items.properties.field.oneOf', Array, [])
      setOneOf(oneOf)
    })()
  }, [])

  const transitions: any[] = useMemo(() => {
    const transitions: any[] = getDataOfType(stages, `[${appealsCurrentStep}].transitions`, Array, [])
      .map(item => {
        const target = getDataOfType(item, `target`, String, null)
        const nextStage = stages.find(item => item['code'] === target)
        return {
          nextValidators: getDataOfType(nextStage, 'validators', Array, []),
          ...item
        }
      })
    return transitions
  }, [stages, appealsCurrentStep])

  const onClick = useCallback((item) => {
    if(typeof transition === 'function'){
      const target = item['target']
      const nextStageIndex = stages.findIndex(stage => stage['code'] === target)
      if(nextStageIndex === -1){
        console.warn(`В справочнике не найдена целевая стадия: ${target}`)
        return
      }
      const prevReq = getDataOfType(stages, `[${appealsCurrentStep}].validators`, Array, []).map(item => item['title'])
      const nextReq = getDataOfType(stages, `[${nextStageIndex}].validators`, Array, []).map(item => item['title'])
      const required  = transitionRequired(prevReq, nextReq)
      if(required === false){
        return
      }

      transition(item, {
        prevStageIndex: appealsCurrentStep,
        prevStage: getDataOfType(stages, `[${appealsCurrentStep}]`, Object, []),
        nextStageIndex,
        nextStage: stages[nextStageIndex]
      })
    }
  },[transition, appealsCurrentStep, stages, transitionRequired])

  const calcRoleDisabled: any = useCallback(item => {

    if(isBusy)
      return isBusy

    const currentRoles = getDataOfType(sessionData, 'roles', Array, [])
    const fieldRoles = getDataOfType(item, 'roles', Array, [])
    const roles = filterRoles(currentRoles, fieldRoles)

    return !roles.length
  }, [sessionData, isBusy])

  return (
    <Space>
       {transitions.map((item, index) => {
         const disabled = calcRoleDisabled(item)
         return (
           <FormatTooltip
             type={type}
             key={index}
             item={item}
             oneOf={oneOf}
             disabled={disabled}
             onClick={() => onClick(item)}
           />
         )
       })}
    </Space>
  )
}

export default ButtonsTransition
