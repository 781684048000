import  {Moment} from "moment";
import {IDataByDay} from "../selectors";

export const getPreviewTaskItemData = (value: Moment, dataByDay: IDataByDay) => {
    let currentDate = value.format('YYYY.MM.DD');

    if (!dataByDay[currentDate]) {
        return []
    }

    let result: { type: string, content: string }[] = []
    let expiredCount = dataByDay[currentDate]?.filter((val) => val.stage === 'expired')?.length;
    let newCount = dataByDay[currentDate]?.filter((val) => val.stage === 'new')?.length;

    if (expiredCount) {
        result.push({type: 'error', content: `${expiredCount} просрочено`})
    }

    if (newCount) {
        result.push({type: 'success', content: `${newCount} запланировано`})
    }


    return result;
};
