import fetchAPI from 'src/lib/utils/fetch-api'

export async function fileUpload(files) {
    const formData = new FormData()
    Array
        .prototype
        .forEach
        .call(files, file => formData.append('file', file))
    try {
        const response = await fetch('/api/upload', {method: 'POST', body: formData})
        return await response.json()
    } catch (error) {
        console.warn(error)
        return {error}
    }
}

export async function insetFileToColletion(file, targetId?: number) {
    const name = 'file-s3'
    const writeFile = {
        date: file['date'] || '',
        name: file['name'],
        size: file['size'],
        type: file['type'] || '',
        url: file['url'],
        'name-field': file['name-field'],
        'is_deleted': false,
        'code': file['code'] || null,
    }

    const requestBody = {
        'parent_id': Number.isFinite(targetId) ? targetId : 0,
        'data': {
            'name': writeFile['name'],
            'attributes': writeFile,
            'metadata': {
                'schema': name,
            },
        },
    }

    const result = await fetchAPI(`/api/collections/${name}`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
    })
    if (result['data'] && result['data']['id']) {
        return result['data']['id']
    }
    return null
}

export async function getFileData(id) {
    try {
        const res = await fetchAPI(`/api/collections/objects/${id}`)
        const object = res['data']
        const formData = object['data']['attributes']
        return {
            id,
            date: formData['date'] || '',
            type: formData['type'] || '',
            name: formData['name'],
            size: formData['size'],
            url: formData['url'],
            'name-field': formData['name-field'],
        }
    } catch {
        return null
    }
}
