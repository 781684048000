import {dateTimeColumnNames} from "../const/dateTimeColumnNames";
import {phoneColumnNames} from "../const/phoneColumnNames";
import {priceColumnNames} from "../const/priceColumnNames";
import {formatDate} from "../lib/formatDate";
import {formatPhone} from "../lib/formatPhone";
import {formatPrice} from "../lib/formatPrice";
import {dateColumnNames} from "../const/dateColumnNames";

export const format2Print = (key: string, cell: any) => {
    if (dateTimeColumnNames.includes(key)) {
        cell = formatDate(cell)
        return cell;
    }

    if (dateColumnNames.includes(key)) {
        cell = formatDate(cell, 'DD.MM.YYYY')
        return cell;
    }

    if (phoneColumnNames.includes(key)) {
        cell = formatPhone(cell)
        return cell;
    }

    if (priceColumnNames.includes(key)) {
        cell = formatPrice(cell)
        return cell;
    }

    return cell
}

// if (enumsKeys.includes(key)) {
//     data[i][key] = enums[key].find((enumItem) => enumItem.value === data[i][key])?.label || ''
//     return;
// }