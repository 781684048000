import {createSelector} from 'reselect'
import {convertData2JsonForm} from '../../../../shared/lib/convertData2JsonForm'
import {objectMap} from '../../../../../lib/utils/get-table-transform-schema-and-form-data'
import {addEnumValBySchema} from '../../../../shared/lib/addEnumValBySchema'


export const createSession = createSelector(
    (state: any) => state.session,
    sessionData => sessionData
)
const selectMontage = state => state.montage

const selectMontageForm = createSelector(
    selectMontage,
    ({form}) => form
)

const selectMontageEnums = createSelector(
    selectMontage,
    ({enums}) => enums
)

export const getMontageId = createSelector(
    selectMontage,
    ({id}) => id
)

export const getMontageFormLoading = createSelector(
    selectMontageForm,
    ({isLoading}) => isLoading
)

export const getMontageFormError = createSelector(
    selectMontageForm,
    ({error}) => error
)

export const getMontageFormData = createSelector(
    selectMontageForm,
    ({data}) => data
)

export const getMontageStageCurrent = createSelector(
    getMontageFormData,
    (formData) => formData?.stages?.current
)

export const getMontageEnumsData = createSelector(
    selectMontageEnums,
    ({data}) => data
)

export const getMontageEnumsError = createSelector(
    selectMontageEnums,
    ({error}) => error
)

export const getMontageEnumsIsLoading = createSelector(
    selectMontageEnums,
    ({isLoading}) => isLoading
)

const selectChatAvito = state => state.chatAvito

export const selectChatAvitoIsLoading = createSelector(
  selectChatAvito,
  ({isLoading}) => isLoading
)

export const selectChatAvitoData = createSelector(
  selectChatAvito,
  ({data}) => data
)

export const selectChatAvitoError = createSelector(
  selectChatAvito,
  ({error}) => error
)

export const getActiveAvitoChatId = createSelector(
  selectChatAvito,
  ({activeChatId}) => activeChatId
)

export const getActiveAvitoChat = createSelector(
  [selectChatAvitoData, getActiveAvitoChatId],
  (data, activeChatId) => {
      return data?.filter((chatItem) => chatItem.chat_id === activeChatId)[0]
  }
)

export const getVisibleFormData = createSelector(
    [getMontageFormData, getMontageEnumsData],
    (formData, enums) => {
        if (formData && enums) {
            const { data: form } = formData

            if (!form) {
                return false
            }

            const {
                resultObject,
                resultSchema
            } = convertData2JsonForm(form)

            const enumItems = enums?.['enum'] || []
            if (enums?.['city']) {
                enumItems.city_id = Object.values(objectMap(enums['city'], ([key, value]) => {
                    return [key, {id: parseInt(key), value: value}]
                }))
            }

            if (enums?.['region']) {
                enumItems.region_id = Object.values(objectMap(enums['region'], ([key, value]) => {
                    return [key, {id: parseInt(key), value: value}]
                }))
            }

            Object.keys(enumItems).forEach(code => {
                if (
                    resultSchema?.properties.hasOwnProperty(code)
                ) {
                    const enumValues = enums?.['enum']?.[code] || {}
                    const oneOf: Record<string, string>[] = []

                    Object.keys(enumValues).forEach(enumValue => {
                        return oneOf.push({
                            'const': enumValues[enumValue].id,
                            'title': enumValues[enumValue].value
                        })
                    })

                    if (resultSchema?.properties.hasOwnProperty(code)) {
                        addEnumValBySchema(resultSchema, code, oneOf, enumValues)
                    }

                    if (resultObject.hasOwnProperty(code)) {
                        resultObject[code] = resultObject[code] ? parseInt(resultObject[code]) : null
                    }
                }
            })

            return { resultSchema, resultObject }
        }
    }
)
