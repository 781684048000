import {calcOrders} from "./calcOrders";
import {formatPrice} from "../../../utils";

export const manyTablesData = (ordersByMonthTmp, ordersPrepayment, monthKeys:string[], selectedMonths:string[], selectedYears:string[]) => {
    const ordersByMonthResult = {}
    monthKeys.forEach((month: string) => {
        const managers = Object.keys(ordersByMonthTmp[month]);
        const total = {
            total: 'Всего',
            total_co: 0,
            total_cancel_before_kp: 0,
            total_cancel_after_kp: 0,
            total_workOrders: 0,
            total_measurements: 0,
            total_contracts: 0,
            total_percent_ld: 0,
            total_percent_md: 0,
            total_percent_cc: 0,
            total_average_score: 0
        }

        ordersByMonthResult[month] = managers.map((manager) => {
            const orders = ordersByMonthTmp[month][manager];
            const ordersByPrepayment: number = ordersPrepayment[month][manager];

            const calc = calcOrders(orders, ordersByPrepayment, selectedMonths, selectedYears, manager);

            total.total_co += calc.co;
            total.total_cancel_before_kp += calc.cancel_before_kp;
            total.total_cancel_after_kp += calc.cancel_after_kp;
            total.total_workOrders += calc.workOrders;
            total.total_measurements += calc.measurements;
            total.total_contracts += calc.contracts;
            total.total_percent_ld += calc.percent_ld;
            total.total_percent_md += calc.percent_md;
            total.total_percent_cc += calc.percent_cc;
            total.total_average_score += calc.average_score;

            return calc;
        })

        //Данные колонки не используются в сортировке, отображаются в summary
        //@ts-ignore
        total.total_percent_md = total.total_percent_md?.toFixed(2) + '%'
        //@ts-ignore
        total.total_percent_ld = total.total_percent_ld?.toFixed(2) + '%'
        //@ts-ignore
        total.total_percent_cc = total.total_percent_cc?.toFixed(2) + '%'
        //@ts-ignore
        total.total_average_score = formatPrice(total.total_average_score)

        ordersByMonthResult[month].push(total)

    })
    return ordersByMonthResult;
}