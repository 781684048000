import React, { useState, useEffect, useCallback } from 'react'
import B2BLayout from '../../../layouts/b2b'
import { Table, TablePaginationConfig } from 'antd'
import { getColumns } from './columns'
import { useHistory, useParams } from 'react-router-dom'
import useBreadcrumbs from './hooks/useBreadcrumbs'
import useFetchData from './hooks/useFetchData'
import useSessionData from './hooks/useSessionData'
import TableHeader from './components/TableHeader'
import { IOrganizationData } from './interface'

const CustomersList = () => {
  const { id } = useParams<any>()
  const history = useHistory()
  const [data, setData] = useState([])
  const { isLeader } = useSessionData()
  const [loading, setLoading] = useState(false)
  const [sorter, setSorter] = useState({})
  const [organization, setOrganization] = useState<IOrganizationData>()
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 10,
    position: ['bottomCenter'],
    showSizeChanger: false
  })

  const breadcrumbs = useBreadcrumbs()

  const updatePagination = useCallback((newState) => {
    setPagination((prev) => ({
      ...prev,
      ...newState
    }))
  }, [])

  const { loading: fetchLoading, getData, setOfficeState } = useFetchData(id, updatePagination, setOrganization, setSorter, setData)

  useEffect(() => {
    try {
      getData()
    } catch (e) {
      alert('Не удалось получить данные, пожалуйста, перезагрузите страницу')
    }
  }, [getData])

  const handleTableChange = (pagination, filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    let current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    getData()
  }

  const handleRowClick = (record) => {
    const { id } = record
    history.push(`/office/${id}`)
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <TableHeader organizationName={organization?.name} id={id} />
      <Table
        showSorterTooltip={{ overlay: "Нажмите для сортировки", placement: 'bottom' }}
        columns={getColumns(isLeader, sorter)}
        rowKey={(r) => r?.id}
        dataSource={data}
        pagination={pagination}
        loading={loading || fetchLoading}
        onChange={handleTableChange}
        onRow={(record) => ({ onClick: () => handleRowClick(record) })}
        scroll={{ x: 'max-content', y: '72vh' }}
        size="small"
      />
    </B2BLayout>
  )
}

export default CustomersList
