import styled from 'styled-components';
import { Link } from 'react-router-dom'

export const LinkWrapper = styled(Link)`
  font-size: 20px;
  padding: 6px;
`;

export const IdBlock = styled.div`
  overflow-wrap: initial;
  text-align: right;
  
  display: flex;
  justify-content: center;

  width: 100%;
`

export const ButtonWrapper = styled.div`
  padding-bottom: 16px;
  
  button {
    margin-right: 10px;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-self: center;

  padding-left: 20px;

  svg {
    height: 24px;
    width: 24px;

    margin-right: 16px;
  }
  
  .anticon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const EditGroupWrapper = styled.div`
  display: inline-flex;
  justify-content: stretch;
  align-items: center;
  
  max-width: 85%;
`
