const API_KEY = '202f07e5711c4f92e5167758fa392229901215ac';
const BASE_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs';

export interface Suggestion {
  value: string;
  data: {
    city: string;
    city_district: string;
    street_with_type: string;
    settlement_with_type: string;
  };
}

const getSuggestions = async (query: string): Promise<Suggestion[]> => {
  const response = await fetch(`${BASE_URL}/suggest/address`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Token ${API_KEY}`,
    },
    body: JSON.stringify({ query }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  return data.suggestions;
};

export { getSuggestions };
