import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import Modal from 'antd-mobile/lib/modal'
import List from 'antd-mobile/lib/list'
import Button from 'antd-mobile/lib/button'
import WhiteSpace from 'antd-mobile/lib/white-space'

import { ButtonWrapper } from '../../measurements-edit/styles'
import { IValidationsMenuProps } from './interface'
import { ItemTitle } from './styles'
import { ValidationMenuComponent } from './validation-menu-component'
import { AdditionalValidationMenuComponent } from './additional-validation-menu-component'
import { parseDate } from '../../../../utilits'
import fetchAPI from '../../../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../../../lib/utils/get-data-of-type'
import { insetNoteToCollection } from '../../../../views/modal-add-notes/utils-notes'

export const ValidationsMenu: FC<IValidationsMenuProps> = props => {
  const {
    show = false,
    cardName,
    targetId,
    author,
    onClose = () => {},
    validations,
    isCantSwitchStage,
    onSwitchStage,
    updateData,
    data,
    isAdditional,
    adjustedDateKey,
    adjustedTimeKey,
  } = props

  const [additionalData, setAdditionalData] = useState<Record<string, any>>({})
  const inUpdateProcessRef = useRef(false)
  const formDataAccumRef = useRef<Record<string, any>>({})

  const handleUpdateData = useCallback(async newFormData => {
    if (inUpdateProcessRef.current) {
      formDataAccumRef.current = {
        ...formDataAccumRef.current,
        ...newFormData,
      }
    } else {
      inUpdateProcessRef.current = true
      await Promise.all([updateData(newFormData)]).then(res => {
        inUpdateProcessRef.current = false

        if (Object.keys(formDataAccumRef.current).length > 0) {
          handleUpdateData(formDataAccumRef.current)
          formDataAccumRef.current = {}
        }

        return res
      })
    }
  }, [updateData])

  const handleInProcess = useCallback((state: boolean) => {
    inUpdateProcessRef.current = state
  }, [])

  const handleAdditionalUpdateData = useCallback(async () => {
    const timeObject = additionalData['time'] || {}
    const time = timeObject ? `${timeObject['start']}-${timeObject['end']}` : ''
    const contentList = [`Изменена дата замера на: ${parseDate(additionalData['date'], 'dd.MM.yyyy')}  ${time}`]

    if (additionalData['comment']) {
      contentList.push(additionalData['comment'])
    }

    const content = contentList.join('. ')

    const nextDataCard = await fetchAPI('/api/web-hooks/set-card-form-data', { method: 'POST', body: JSON.stringify({
        'card-name': cardName,
        'target-id': targetId,
        'add-form-data': {
          [adjustedDateKey]: additionalData['date'],
          [adjustedTimeKey]: time
        }
      })})

    const newData = getDataOfType(nextDataCard, 'data.formData', Object, null)

    await insetNoteToCollection({ date: new Date().toISOString(), content, author }, targetId)
    updateData(newData)
    onClose()
  }, [
    additionalData,
    adjustedDateKey,
    adjustedTimeKey,
    author,
    cardName,
    onClose,
    targetId,
    updateData,
  ])

  const handleSubmit = useMemo(() => {
    if (isAdditional) {
      return handleAdditionalUpdateData
    } 
    return onSwitchStage
  }, [handleAdditionalUpdateData, isAdditional, onSwitchStage])

  const handleAdditionalChange = useCallback(newData => {
    setAdditionalData(prevState => ({
      ...prevState,
      ...newData,
    }))
  }, [])

  const isCanAdditionalSubmit = useMemo(() => {
    return validations.some(validation => !additionalData[validation['code']])
  }, [additionalData, validations])

  const isCanSubmit = useMemo(() => {
    return isAdditional ? isCanAdditionalSubmit : isCantSwitchStage
  }, [isAdditional, isCanAdditionalSubmit, isCantSwitchStage])

  return (
    <Modal
      transparent
      visible={show}
      onClose={onClose}
      animationType="slide-up"
    >
      <List renderHeader={() => <div>Обязательные поля</div>}>
        {!isAdditional && validations.length > 0 && validations.map(validation => (
          <List.Item key={validation['code']}>
            <ItemTitle>
              {validation['title']}
            </ItemTitle>
            
            <ValidationMenuComponent
              title={validation['title']}
              value={data[validation['code']]}
              updateData={updateData}
              code={validation['code']}
              format={validation['format']}
              onInProcess={handleInProcess}
            />
          </List.Item>
        ))}
        {isAdditional && validations.length > 0 && validations.map(validation => (
          <List.Item key={validation['code']}>
            <ItemTitle>
              {validation['title']}
            </ItemTitle>
            <AdditionalValidationMenuComponent
              value={additionalData[validation['code']]}
              onChange={handleAdditionalChange}
              code={validation['code']}
              title={validation['title']}
              format={validation['format']}
              onInProcess={handleInProcess}
            />
          </List.Item>
        ))}
        {validations.length === 1 && (
          <WhiteSpace size="lg" />
        )}
        <List.Item>
          <ButtonWrapper>
            <Button type="ghost" onClick={onClose}>Свернуть</Button>
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={isCanSubmit}
            >
              Продолжить
            </Button>
          </ButtonWrapper>
        </List.Item>
      </List>
    </Modal>
  )
}
