import React, {useMemo} from 'react'
import {Space, Timeline} from 'antd'
import dayjs from 'dayjs'
import {PhoneOutlined,} from '@ant-design/icons'
import {formatDate} from './activity-timeline-Item'
import {DotStyle, TimeLineContentHeaderWrapper, TimeLineContentWrapper} from './styles'

function callStatus({ entry_result }) {
  return entry_result ? 'успешен' : 'пропущен'
}		
			
function callDirectionFormat({ call_direction }) {
  if (call_direction === 1)
    return 'Входящий'
  if (call_direction === 2)
    return 'Исходящий'
  return 'Внутренний'
}

function callTimeFormat(data) {
  const endTime = data['end_time']
  const talkTime = data['talk_time']
  if (endTime && talkTime)
    return dayjs(talkTime * 1000).to(endTime * 1000, true)
  return 'пропущен'
}

const dotColor = '#2fc4f5'

function ActivityTimelineInfo({ type, body, date, id, index }: any){
  const dateFormat = useMemo(() => formatDate(date), [date])
  return (
    <Timeline.Item dot={<DotStyle color={dotColor}><PhoneOutlined /></DotStyle>}>
      <TimeLineContentWrapper>
        <TimeLineContentHeaderWrapper>
          <Space>
            <span style={{ fontWeight: 'bold' }}>Звонок</span>
            <span style={{ color: '#a8adb4' }}>{dateFormat}</span>
          </Space>
        </TimeLineContentHeaderWrapper>
        <Space>
          <span>Статус:</span>
          <span style={{ color: '#a8adb4' }}>{`${callDirectionFormat(body)}, ${callStatus(body)}`}</span>
        </Space>
        { body?.entry_result && (
          <Space>
            <span>Время разговора:</span>
            <span style={{ color: '#a8adb4' }}>{`${callTimeFormat(body)}`}</span>
          </Space>
        )}
        {Array.isArray(body.record) && body.record.length > 0 &&
		  <div>
		    <div>Запись разговора:</div>
            {body.record.map((url, index) => (
              <audio controls style={{ width: '100%', height: '30px'}}>
                <source src={url} type="audio/mpeg"></source>
              </audio>
            ))}
		  </div>
        }
      </TimeLineContentWrapper>
    </Timeline.Item>
  )
}

export default ActivityTimelineInfo
