import React from 'react'
import {Button, Popconfirm, Space} from 'antd'
import {DeleteTwoTone, EditTwoTone, PlusOutlined} from '@ant-design/icons'
import {ButtonActionWrapper} from '../../components/list-operations/styles'

export function DeleteButton({onClick}) {
  return (
    <ButtonActionWrapper title="Удалить" onClick={onClick}>
      <DeleteTwoTone />
    </ButtonActionWrapper>
  )
}

export function EditButton({onClick}) {
  return (
    <ButtonActionWrapper title="Редактировать" onClick={onClick}>
      <EditTwoTone />
    </ButtonActionWrapper>
  )
}

export function CreateButton({name = '', onClick}) {
  const title = name || 'Создать'
  return (
    <Button title={title} onClick={onClick}>
      <PlusOutlined /> {title}
    </Button>
  )
}