import * as React from 'react'
import { Layout, Spin } from 'antd'

export default function AppLoader() {
  return (
    <Layout style={{ minHeight: '100vh', background: 'white', padding: 100, textAlign: 'center' }}>
      <Spin size="large" />
    </Layout>
  )
}
