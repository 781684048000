import React, { useEffect, useState, useMemo } from 'react'
import fetchAPI from 'src/lib/utils/fetch-api'
import { Select } from 'antd'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'

function filterOption(input, option){
  return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

function SelectFrom({ value, schema, onChange, UISchema }:{value?:any, schema?:any, onChange?:any, UISchema?:any}) {
  const [objects, setObjects] = useState<any[]>([{ id: value, value }])

  useEffect(() => {
    (async () => {
      const objectsResult: any = await fetchAPI(`/api/organization_types`)
      const objectsFormat = getDataOfType(objectsResult, 'data', Array, []).map(({ id, title }) => {
        return { id, value: title }
      })
      setObjects(objectsFormat)
    })()
  }, [])

  const localValue = useMemo(() => Number(value) || undefined, [value])

  return (
    <Select
      placeholder="Выберите запись"
      optionFilterProp="children"
      onChange={onChange}
      value={localValue}
      filterOption={filterOption}
    >
    {objects.map(({ id, value }) => (
      <Select.Option value={id}>{value}</Select.Option>
    ))}
    </Select>
  )
}

export default SelectFrom

