export const dateTimeColumnNames = [
    'start_probation_period',
    'completed_at',
    'created_at',
    'updated_at',
    'datetime_interview',
    'task_deadline',
    'end_probation_period',
    'order_created_at',
    'old_delivery_date',
    'appeal_date',
    'measurement_datetime',
    'montage_date',
    'montage_datetime',
    'task_date',
    'payment_date',
    'employment_date',
    'dismissal_date',
    'deadline',
]