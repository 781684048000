import React, { useCallback, FC, useEffect, useState, useMemo, ReactElement } from 'react'
import Card from 'antd-mobile/lib/card'
import WhiteSpace from 'antd-mobile/lib/white-space'

import { CardTitle } from '../delivery-list/styles'
import { PhoneButton } from '../measurements-edit/styles'
import { IMountingListCardProps } from './interface'
import { parseDate } from '../../../utilits'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { CarIcon, HomeIcon } from '../assets/icons'
import { CardExtraTitleWrapper } from './styles'

export const MountingListCard: FC<IMountingListCardProps> = (props) => {
  const { onOpenEdit, data, stagesList, onCall } = props
  const [foreCast, setForeCast] = useState<Record<string, any> | null>(null)

  useEffect(() => {
    const updateForecast = async () => {
      const forecastResponse = await fetchAPI(`/api/order-forecast/${data['parent-id']}`)

      setForeCast(forecastResponse['data'])
    }

    if (data['parent-id']) {
      updateForecast()
    }
  }, [data])

  const getStage = useCallback(({ stage = 0 }) => {
    let result = stagesList[stage]

    if (result && 'name' in result) {
      result = result['name']
    }

    return result
  }, [stagesList])

  const deliveryDate = useMemo(() => {
    const deliveryForecast = foreCast ? foreCast.find(f => f['code'] === 'delivery') : null
    let result = ''

    if (deliveryForecast && deliveryForecast['date-fact']) {
      result = deliveryForecast['date-fact']
    }

    return result
  }, [foreCast])

  const getContent = useCallback(({ name, 'address-object': address, city }) => {
    return (
        <>
          <div>
            <b>ФИО: </b>{name}
          </div>
          {address && (
              <div><b>Адрес: </b>{`${city}, ${address}`}</div>
          )}
        </>
    )
  }, [])

  const CardExtraTitle = useCallback(() => {
    let icon: ReactElement | null = null
    const date = data['date-plan-assembly-team-arrived'] ? (
      <CardTitle><b>Дата монтажа:</b>&nbsp;{data['date-plan-assembly-team-arrived']} {data['time-plan-assembly-team-arrived']}</CardTitle>
    ) : (
      <CardTitle><b>Дата монтажа не указана</b></CardTitle>
    )

    if (data['stage'] === 2) {
      if (deliveryDate) {
        icon = <HomeIcon />
      } else {
        icon = <CarIcon />
      }
    }

    return (
      <CardExtraTitleWrapper>
        {icon}
        {date}
      </CardExtraTitleWrapper>
    )
  }, [data, deliveryDate])

  return (
    <>
      <Card onClick={onOpenEdit(data['id'])}>
        <Card.Header
          title={<CardTitle>{data['parent-id']}: {getStage(data)}</CardTitle>}
          extra={<CardExtraTitle />}
        />
        <Card.Body>
          <div>{getContent(data)}</div>
          <WhiteSpace size="lg" />
          <PhoneButton
            href={`tel:${data['telephone']}`}
            onClick={onCall(data['telephone'])}
          >
            <b>Телефон:&nbsp;</b>{data['telephone']}
          </PhoneButton>
        </Card.Body>
        {deliveryDate && (
          <Card.Footer content="Дата доставки" extra={parseDate(deliveryDate)} />
        )}
      </Card>
      <WhiteSpace size="lg" />
    </>
  )
}
