import React from 'react'
import { Modal, Space, Button  } from 'antd'

export function onCall(phone, done) {
  Object.assign(window.location, { href: `tel:${phone}` })
  done(null)
}

export async function onCallMango(phone, done) {
  await fetch('/api/web-hooks/mango-called', {
    method: 'POST', 
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ phone })  
})
  done(null)
}
 

export function modalPhone({ phone }) {
  return new Promise(resolve => {
    Modal.info({
      icon: null,
      title: 'Как позвонить?',
      content: <Space>
          <Button onClick={() => onCall(phone, resolve)}>Прямой звонок</Button>
          <Button onClick={() => onCallMango(phone, resolve)}>Звонок через манго</Button>
      </Space>,
      onOk: () => new Promise((ok, err) => {
        ok(null)
      }),
      onCancel() {
        resolve(null)
      }
    })
  })
}

