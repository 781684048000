import { IEnumsData, IFormData } from './interfaces'

export const MONTAGE_FORM_LOADING = 'MONTAGE_FORM_LOADING'
export const MONTAGE_FORM_DATA = 'MONTAGE_FORM_DATA'
export const MONTAGE_FORM_FAILED = 'MONTAGE_FORM_FAILED'
export const MONTAGE_FORM_ID_UPDATE = 'MONTAGE_FORM_ID_UPDATE'
export const MONTAGE_ENUMS_LOADING = 'MONTAGE_ENUMS_LOADING'
export const MONTAGE_ENUMS_DATA = 'MONTAGE_ENUMS_DATA'
export const MONTAGE_ENUMS_FAILED = 'MONTAGE_ENUMS_FAILED'


/* ============== ORDERS_FORM ============== */
export function setMontageFormLoading() {
  return async dispatch => {
    dispatch({ type: MONTAGE_FORM_LOADING })
  }
}

export function setMontageFormData(formData: IFormData) {
  return async dispatch => {
    dispatch({ type: MONTAGE_FORM_DATA, payload: formData })
  }
}

export function setMontageFormFailed(error: string) {
  return async dispatch => {
    dispatch({ type: MONTAGE_FORM_FAILED, payload: error })
  }
}

export function setMontageId(id: string) {
  return async dispatch => {
    dispatch({ type: MONTAGE_FORM_ID_UPDATE, payload: id })
  }
}

/* ============== end ORDERS_FORM ============== */

/* ============== ORDERS_ENUMS ============== */
export function setMontageEnumsLoading() {
  return async dispatch => {
    dispatch({ type: MONTAGE_ENUMS_LOADING })
  }
}

export function setMontageEnumsData(enumsData: IEnumsData) {
  return async dispatch => {
    dispatch({ type: MONTAGE_ENUMS_DATA, payload: enumsData })
  }
}

export function setMontageEnumsFailed(error: string) {
  return async dispatch => {
    dispatch({ type: MONTAGE_ENUMS_FAILED, payload: error })
  }
}

/* ============== end ORDERS_ENUMS ==============  */


export function reloadTablePayment() {return}
