import React, { memo, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getMontageStageCurrent,
} from '../../selectors'
import {
  selectStagePlanError,
  selectStagePlanIsLoading,
  selectStagePlanData,
  StagePlan
} from "../../../../../components/v2/stage-plan";
import {getStagePlanData} from "../../../../../store/stagePlan/action-creators";
import {setStagePlanClear} from "../../../../../store/stagePlan/actions";



interface AppealsStagePlanProps {
  className?: string
  id: string | number
}

export const AppealStagePlan: React.FC<AppealsStagePlanProps> = memo((props: AppealsStagePlanProps) => {
  const {className, id} = props
  const dispatch = useDispatch()
  const stagePlanIsLoading = useSelector(selectStagePlanIsLoading)
  const stagePlanError = useSelector(selectStagePlanError)
  const stagePlanFiles = useSelector(selectStagePlanData)
  const stage = useSelector(getMontageStageCurrent)

  useEffect(() => {
    let mounted = true
    if (stage !== undefined && id !== 'new') {
      if (mounted) {
        dispatch(getStagePlanData('order', id))
      }
    }
    return () => {
      mounted = false
      dispatch(setStagePlanClear());
    }
  }, [stage, id, dispatch])

  return (
    <Suspense fallback={''}>
      <StagePlan
        isLoading={stagePlanIsLoading}
        items={stagePlanFiles}
        error={stagePlanError}
        className={className}
        title={'Прогноз исполнения'}
        emptyMessage={'Прогноз исполнения пуст'}
        typeItem={'order'}
      />
    </Suspense>
  )
})
