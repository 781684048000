import React, { useCallback, useMemo } from 'react'
import { Checkbox, Switch } from 'antd'
import { ICheckbox } from './interface'

function InputCheckbox({ value, onChange, UISchema }: ICheckbox){ 
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const label = useMemo(() => UISchema?.options?.label || 'Да', [UISchema])
  const onChecked = useCallback(({ target }) => onChange(target?.checked), [onChange])
  const onSwitch = useCallback(val => onChange(val), [onChange])
  const type = useMemo(() => UISchema?.options?.type || 'checkbox', [UISchema])
  if (type === 'switch') {
    return (
      <>
        <span>{label}</span>
        <Switch disabled={disabled} checked={value} onChange={onSwitch} />
      </>
    )
  } else {
    return <Checkbox disabled={disabled} checked={value} onChange={onChecked}>{label}</Checkbox>
  }
}

export default InputCheckbox