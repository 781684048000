import React from 'react'
import { ColumnsType } from 'antd/lib/table/Table'
import StageCell from 'src/react-app/components/list-operations/stage-cell'
import dayjs from '../../../lib/utils/configured-dayjs'
import ListOperations from 'src/react-app/components/list-operations'
import EditCard from 'src/react-app/components/list-operations/edit-card'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'

function formatListId(data) {
  const parentId = Number(data['parent-id'])
  let result = data['id']

  if (parentId) {
    result = parentId
  }

  return result
}

function getFontWeightId(data) {
  const isStageNew = getDataOfType(data, 'stage', Number, null) === 0
  return isStageNew ? 'bold' : ''
}

export const beforeDataColumns: ColumnsType<any> = [
  {
    title: 'ID',
    width: 70,
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    render: (_, data) => {
      return (
        <EditCard
          fontWeight={getFontWeightId(data)}
          id={formatListId(data)}
          schema={data?.schema}
          title="Редактирование"
        >{formatListId(data)}</EditCard>
      )
    },
  },
  {
    title: 'Стадия',
    width: 290,
    key: 'stage',
    render: (_, { stage, stages }) => <StageCell stage={stage} stages={stages} />,
  }
]

export const afterDataColumns: ColumnsType<any> = [
  {
    title: 'Дата создания',
    dataIndex: 'createdAt',
    key: 'created-at',
    sorter: (a, b) => a.id - b.id,
    render: (text, { id, createdAt, schema }) => (
      <EditCard
        id={id}
        schema={schema}
        title={dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')}
      >
        <span>{dayjs(createdAt).fromNow()}</span>
      </EditCard>
    ),
    width: 100,
  },
  {
    title: 'Дата обновления',
    dataIndex: 'updatedAt',
    key: 'updated-at',
    sorter: (a, b) => a.id - b.id,
    render: (text, { id, updatedAt, schema }) => (
      <EditCard
        id={id}
        schema={schema}
        title={dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss')}
      >
        <span>{dayjs(updatedAt).fromNow()}</span>
      </EditCard>
    ),
    width: 100,
  },
  {
    title: 'Действие',
    key: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    render: (_, { id, deleteRecord, schema }) => <ListOperations id={id} schema={schema} deleteRecord={deleteRecord} />,
  },
]
