import React, {ReactNode, useCallback, useEffect, useRef, useState} from "react";
import './style.css'
import moment, {Moment} from "moment";
import {Alert, Badge, BadgeProps, Button, Calendar} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
    createSession,
    getTaskCalendarData,
    getTaskCalendarDataLoading,
    getTaskCalendarError,
    IDataByDayItem
} from "../../model/selectors";
import {getFilterDateRange} from "../../model/lib/getFilterDateRange";
import {getPreviewTaskItemData} from "../../model/services/getPreviewTaskItemData";
import {getDetailTaskItemData} from "../../model/services/getDetailTaskItemData";
import {TaskDetailModal} from "../task-detail-modal/task-detail-modal";
import FiltersList from "../../../filters";
import {ITaskFilter} from "../../model/types/ITaskFilter";
import {useHistory} from "react-router-dom";
import {useFilterParams} from "../../model/hooks/useFilterParams";
import {ITaskCalendarFilter} from "../../../../../store/calendar/interfaces";
import {DefaultCellTemplate} from "../cell-templates/default/default";
import {HoursCellTemplate} from "../cell-templates/hours/hours";
import {getPreviewTaskItemDataByTime} from "../../model/services/getPreviewTaskItemDataByTime";
import {getDetailTimeSlotData} from "../../model/services/getDetailTimeSlotData";


interface TaskCalendarProps {
    getList: (filter: ITaskCalendarFilter) => void
}

export const TasksCalendarComponent = ({getList}: TaskCalendarProps) => {
    const {sessionData} = useSelector(createSession);
    const history = useHistory();
    const dispatch = useDispatch();

    const dataByDay = useSelector(getTaskCalendarData);
    const isLoading = useSelector(getTaskCalendarDataLoading);
    const error = useSelector(getTaskCalendarError);
    const {fields, urlParams} = useFilterParams();

    const isLeader = sessionData.roles.includes(19)
    const isDealerLeader = sessionData.roles.includes(25)

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const [value, setValue] = useState<Moment>(() => moment());
    const [localMode, setLocalMode] = useState<'year' | 'month'>('month');
    const [isTimeTableViewMode, setIsTimeTableViewMode] = useState<boolean>(false);

    const [currentDetailData, setCurrentDetailData] = useState<IDataByDayItem[]>([]);
    const targetDate = useRef(moment());

    const filterRef = useRef<ITaskFilter>({
        id: urlParams?.id,
        stage: urlParams?.stage,
        task_type: +urlParams?.task_type,
        phone: urlParams?.phone,
        responsible: +urlParams?.responsible,
    } as ITaskFilter)

    const clearFilter = (filter) => Object.entries(filter)
        .filter(([_, v]) => Boolean(v))
        .reduce((acc, [k, v]) => ({...acc, [k]: v}), {});

    useEffect(() => {
        let mounted = true
        if (mounted) {
            const filter = {
                ...clearFilter(filterRef.current),
                dates: getFilterDateRange(targetDate.current)
            }

            if (!isLeader && !sessionData?.user?.id) {
                return;
            }
            if (!isDealerLeader && sessionData?.user?.id) {
                filter['responsible'] = sessionData.user.id;
            }
            //@ts-ignore
            if (!filter?.stage) {
                filter['stage'] = 'notDone'
            }

            getList(filter)
        }
        return () => {
            mounted = false
        };
    }, [dispatch, sessionData])

    const onSelect = useCallback((newValue: Moment) => {
        setValue(newValue);
    }, []);

    const handleCancelModal = useCallback(() => {
        setIsOpenModal(false);
    }, []);


    const onPanelChange = useCallback(async (newValue: Moment, mode: 'year' | 'month') => {
        setValue(newValue);
        if (localMode !== mode) {
            setLocalMode(mode)
        }

        if (mode === 'month') {
            if (newValue.format("YYYY.MM") !== targetDate.current.format("YYYY.MM")) {
                targetDate.current = newValue;

                const filter = {
                    ...clearFilter(filterRef.current),
                    dates: getFilterDateRange(newValue)
                }
                if (!isLeader && !sessionData?.user?.id) {
                    return;
                }
                //@ts-ignore
                if (!filter?.stage) {
                    filter['stage'] = 'notDone'
                }
                if (!isDealerLeader && sessionData?.user?.id) {
                    filter['responsible'] = sessionData.user.id;
                }
                getList(filter);
            }
        }
    }, [dispatch, localMode]);



    const onClickDefaultCell = useCallback((value: Moment) => {
        const detailData = getDetailTaskItemData(value, dataByDay)
        setCurrentDetailData(detailData)
        setIsOpenModal(true)
    }, [dataByDay])

    const onClickHoursCell = useCallback((value: Moment, time) => {
        console.log('click')
        const detailData = getDetailTimeSlotData(value, dataByDay, time)
        if (detailData.length === 0) {
            return
        }
        setCurrentDetailData(detailData)
        setIsOpenModal(true)
    }, [dataByDay])


    const dateCellRender = useCallback((value: Moment) => {
        if (isTimeTableViewMode) {
            const listData = getPreviewTaskItemDataByTime(value, dataByDay);
            return <HoursCellTemplate listData={listData} value={value} clickCell={onClickHoursCell} />
        }

        const listData = getPreviewTaskItemData(value, dataByDay);
        if (!listData.length) {
            return
        }
        return <DefaultCellTemplate listData={listData} value={value} clickCell={onClickDefaultCell}/>;
    }, [dataByDay, isLoading, isTimeTableViewMode]);

    const handlerFilterChange = useCallback((data) => {
        const filter = {
            ...data,
            dates: getFilterDateRange(targetDate.current)
        }
        filterRef.current = {...data}

        if (!isLeader && !sessionData?.user?.id) {
            return;
        }
        //@ts-ignore
        if (!filter?.stage) {
            filter['stage'] = 'notDone'
        }

        if (!isDealerLeader && sessionData?.user?.id) {
            filter['responsible'] = sessionData.user.id;
        }
        history.push({search: new URLSearchParams(data).toString()})
        getList(filter)

    }, [history, dispatch])

    return (
        <>
            {
                !isLoading && error && (
                    <Alert type={"error"} message={'При выполнении запроса возникла ошибка' + error}/>
                )
            }
            <FiltersList
                filters={fields}
                onChange={handlerFilterChange}
            />

            {(localMode === 'month') && (
                <div style={{background: '#fff', paddingTop: '10px'}}>
                    <Button style={{marginLeft: 'auto', display: 'block'}}
                            onClick={() => setIsTimeTableViewMode(!isTimeTableViewMode)}
                    >
                        {isTimeTableViewMode ? 'Скрыть расписание' : 'Показать расписание'}
                    </Button>
                </div>
            )}

            <Calendar
                value={value}
                dateCellRender={dateCellRender}
                onPanelChange={onPanelChange}
                onSelect={onSelect}
            />
            <TaskDetailModal
                onCancel={handleCancelModal}
                isOpen={isOpenModal}
                data={currentDetailData}
            />
        </>
    )
}
