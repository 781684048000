import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Alert, Skeleton, Button } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import { createUISchemaVertical } from 'src/lib/utils/grid-fild-orientation'
//import { debounce } from 'lodash'
import selectedSchema from './json-schema.json'
//
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const breadcrumbs = [
  {
    href: '/profiles-organization',
    title: 'Профили',
  },
  {
    href: `/profiles-organization`,
    title: 'Список профилей организаций',
  },
]

function booleanInversion(value) {
  return typeof value === 'boolean'? value === false: value
}

function recalcFormData(item) {
  return {
    ...item,
    'is-active': booleanInversion(item['is-active'])
  }
}



export default function AdminNewObjectPage() {
  const { id } = useParams()  as any
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const matchedJSONSchemaRef = useRef<any>(null)
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()



   const saveCase = useCallback(
    async () => {

    },
    [],
  ) 

  const getData = useCallback(async () => {
    const isNew = id === 'new'
    
    if(isNew === false ){
      const result = await fetchAPI(`/api/users/${id}`)
      const data = getDataOfType(result, 'data', Object, null)

      formDataRef.current = recalcFormData(data)
    }


    setPageErrors([])

    matchedJSONSchemaRef.current = selectedSchema
    const UISchema = createUISchemaVertical(selectedSchema)
    setUISchema(UISchema)

    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData()
  }, [getData])

  const onFormData = useCallback(
    ({ formData }) => {
      formDataRef.current = formData
    }, []
  )

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>Пользователь <span>{`№ ${id}`}</span></MapTitle>
        </TaskWrapper>
        <MapContent>
          <Form
            {...formItemLayout}
            layout="horizontal"
            form={form}
            scrollToFirstError={true}
          >
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {isDataLoading === false ? (
                matchedJSONSchemaRef !== null && matchedJSONSchemaRef.current && UISchema && (
                  <DefaultJsonForm
                    formData={formDataRef.current}
                    schema={matchedJSONSchemaRef.current}
                    onChange={onFormData}
                    UISchema={UISchema}
                  />
                )
            ) : (
              <Skeleton active />
            )}
          </Form>
          <Button onClick={saveCase} style={{ marginBottom: 16 }}  type="primary">
            <SaveOutlined />
            Сохранить
          </Button>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
