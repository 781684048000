import styled from 'styled-components';

export const PhoneButton = styled.a`
  display: inline-flex;
  color: rgb(51, 51, 51)
`

export const FieldGroup = styled.div`
  display: flex;
  margin: 10px 0;
`

export const FieldName = styled.span`
  font-weight: bold;
  color: #108EE9;
  margin-right: 10px;
`;
