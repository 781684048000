import React from 'react'
import { Select } from 'antd'
import { IMonthFilterProps, IFilterOption } from '../interfaces/interfaces'

const { Option } = Select

const MonthFilter: React.FC<IMonthFilterProps> = ({
  selectedMonths,
  onMonthChange,
  months,
  style,
}) => {
  const monthOptions: IFilterOption[] = months.map(month => ({ id: month, value: month }))

  return (
    <Select
      mode="multiple"
      value={selectedMonths}
      onChange={onMonthChange}
      style={style}
      placeholder="Все месяцы"
      allowClear
    >
      {monthOptions.map(month => (
        <Option key={month.id} value={month.id}>
          {month.value}
        </Option>
      ))}
    </Select>
  )
}

export default MonthFilter
