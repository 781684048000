import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import B2BLayout from '../../layouts/b2b'
import { SchemaEditor } from '../../components/app-schema-editor'

export default function AdminNewSchemaPage() {
  const history = useHistory()
  const breadcrumbs = [{
    href: '/admin',
    title: 'Администрирование'
  }, {
    href: '/admin/schemas',
    title: 'Структуры (схемы)',
  }]

  const handleOnSave = useCallback(() => {
    setTimeout(() => {
      history.push('/admin/schemas')
    }, 1500)
  }, [history])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <SchemaEditor title="Создание новой структуры (схемы)" onSave={handleOnSave} />
    </B2BLayout>
  )
}
