import React, { useEffect, useState, useMemo } from 'react'
import { Table, Space } from 'antd'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import dayjs from 'dayjs'
import { DarkLink } from '../../../components/dark-link'
/* 
function CellSchema({ schema }) {
  if (schema === 'appeal') {
    return <span>Обращение</span>
  }
  if (schema === 'case-order') {
    return <span>Заказ</span>
  }
  return <></>
} */

function DateFactFormat({data}) {
  return (
  <Space direction="vertical">
    {data.map(item=> (
      <>
      <Space direction="vertical">
      {item['title']}
    </Space>
    <Space direction="vertical">
      {item['date-fact'] ? dayjs(item['date-fact']).format('DD.MM.YYYY HH:mm') : 'нет' }
    </Space>
      </>
    )
    )}
  </Space>
  )
}

function datePlanFormatText(item) {
  if (item['date-adjusted']) {
    return dayjs(item['date-adjusted']).format('DD.MM.YYYY')
  }
  if (item['date-plan']) {
    return dayjs(item['date-plan']).format('DD.MM.YYYY')
  }
  if (item['date']) {
    return dayjs(item['date']).format('DD.MM.YYYY')
  }
  return 'нет'
}

function DatePlanFormat({data}) {
  return (
  <Space direction="vertical">
    {data.map(item=> (
      <>
      <Space direction="vertical">
      {item['title']}
    </Space>
    <Space direction="vertical">
      {datePlanFormatText(item)}
    </Space>
      </>
    )
    )}
  </Space>
  )
}

function ResponsiblesFormat({data}) {
  return (
  <Space direction="vertical">
    {data.map(item=> (
      <>
      <Space direction="vertical">
      {item['title']}
    </Space>
    <Space direction="vertical">
      {(item['responsibles'] || []).map(item => {
      return <Space direction="vertical">
      {item['login']}
      </Space>
    
      })}
    </Space>
      </>
    )
    )}
  </Space>
  )
}

function CellId({ id, props }) {
  const url = useMemo(() => {
    if (Number.isInteger(props['order_id'])) {
      return `/order/${props['order_id']}/edit`
    }
    return `/case/${id}/edit`
  }, [props, id])

  return <DarkLink to={url}>{id}</DarkLink>
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (id, props) => <CellId id={id} props={props} />,
  },
  {
    title: 'Стадия',
    dataIndex: 'sum',
    key: 'sum',
    render: text => 'Консультация',
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    render: text => dayjs(text).format('DD.MM.YYYY HH:mm'),
  },
  {
    title: 'Сумма',
    dataIndex: 'sum',
    key: 'sum',
    render: text => text,
  },
  {
    title: 'Номер производственного заказа',
    dataIndex: 'sum',
    key: 'sum',
    render: text => '',
  },
  {
    title: 'Адрес объекта',
    dataIndex: 'sum',
    key: 'sum',
    render: text => '',
  },

]

function TabGeneral({ clientId }){
  const [data, setData] = useState([])
  useEffect(() => {
    (async () => {
      const result = await fetchAPI(`/api/customers-from-orders/${clientId}`).then(items => ({ data: items }))
     // const array = result as any []
      const array = getDataOfType(result, 'data', Array, [])
/*       const data = array.map(item => ({
        id: getDataOfType(item, 'id', [String, Number], null),
        schema: getDataOfType(item, 'data.metadata.schema', [String], null),
        date: getDataOfType(item, 'created_at', [String], null),
      })) */
      setData(array)
    })()

  }, [clientId])

  return <Table dataSource={data} columns={columns} pagination={false} key='id' />
}

export default TabGeneral
