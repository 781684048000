function localizedServerErrors(error: string): string {
  {
    const regexp = /^Schema "(.+)" is already exists$/
    if(regexp.test(error)){
      const [, name] = regexp.exec(error) as any[]
      return `Схема с идентификатором "${name}" уже существует`
    }
  }
  {
    const regexp = /^invalid schema name "(.+)"$/
    if(regexp.test(error)){
      const [, name] = regexp.exec(error) as any[]
      return `Схема имеет некорректный идентификатор "${name}"`
    }
  }
  {
    const regexp = /^Collection "(.+)" is already exists$/
    if(regexp.test(error)){
      const [, name] = regexp.exec(error) as any[]
      return `Коллекция с идентификатором "${name}" уже существует`
    }
  }
  {
    const regexp = /^invalid collection name "(.+)"$/
    if(regexp.test(error)){
      const [, name] = regexp.exec(error) as any[]
      return `Коллекция имеет некорректный идентификатор "${name}"`
    }
  }
  return error
}

export default localizedServerErrors