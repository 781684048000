import React, { useRef, useCallback } from 'react'
import { Modal } from 'antd'
import { DefaultJsonForm } from 'src/react-app/components/default-json-form'
import { isFormDataRequired } from 'src/lib/utils/collections'
import { BrowserRouter } from 'react-router-dom'
import { MapContent, MapWrapper } from './styles'
import fetchAPI from "../../../lib/utils/fetch-api";

const UISchema = {
  'reason-transfer': {
    css: {
      gridColumn: '1 / span 24',
      gridRow: '1',
    },
  },
  'organization': {
    css: {
      gridColumn: '1 / span 24',
      gridRow: '2',
    },
    options: {
      types: [5],
      regions: []
    }
  },
}

const selectedSchema = {
  type: 'object',
  required: ['reason-transfer','organization'],
  properties: {
    'reason-transfer': {
      type: 'string',
      format: 'platform-collection',
      'collection-name': 'reason-transfer-order',
      title: 'Причина передачи заказа',
    },
    'organization': {
      type: 'pointer',
      view: {
        name: 'select-from-organizations'
      },
      title: 'Организация',
    }
  },
}
export async function reasons(){
  return await fetchAPI('/api/collections/reason-transfer-order?access_key=axioma')
}

export function modalClose(event){
  let target = event.target
  while (Boolean(target?.tagName) === true) {
    if (target?.tagName.toLowerCase() === 'div') {
      break
    }
    target = target?.parentNode
  }
  try{
    Array.from(target?.children as any[])[0].click()
    return true
  }catch{
    return false
  }
}

async function modalChangeRegion(region): Promise<any> {
  const _reas = await reasons()
  let formData = {}
  UISchema['organization']['options']['regions'] = region
  return new Promise(async resolve => Modal.confirm({
    icon: null,
    title: 'Изменение региона',
    content: <Content UISchema={UISchema} onChange={value => {
      const reason = _reas.data.data.find( item => +item.id === +value['reason-transfer'] )
      const reason_name = reason?.data?.name
      formData = {...value, 'reason-transfer': reason_name}
      // formData = value
    }} />,
    onCancel: () => resolve(null),
    okButtonProps: { 
      onClick: async (event) => {
        const isRequired = isFormDataRequired(formData, selectedSchema)
        if (isRequired) {
          resolve(formData)
          modalClose(event)
        }
      }
    }
  }))
}

function Content({ onChange, UISchema }){
  const formDataRef = useRef({})
  const localOnChange = useCallback(({ formData }) => {
    formDataRef.current = formData
    onChange(formData)
  }, [onChange])

  return (
    /* @ts-ignore */
    <BrowserRouter>
    <MapContent>
      <MapWrapper>
        <DefaultJsonForm
          formData={formDataRef.current}
          schema={selectedSchema}
          onChange={localOnChange}
          UISchema={UISchema}
        />
      </MapWrapper>
      </MapContent>
    </BrowserRouter>
  )
}

export default modalChangeRegion