import React, {memo, useCallback, useState} from "react";
import {SettingOutlined} from "@ant-design/icons";
import {ButtonStyled} from "./style";
import TableSettingsModal from "../table-settings-modal/table-settings-modal";
import {TransferItem} from "../../model/types/types";

interface TableSettingsButtonProps {
    items: TransferItem[],
}


export const TableSettingsButton: React.FC<TableSettingsButtonProps> = memo((props) => {
    const {items} = props
    const [isOpen, setIsOpen] = useState(false)

    const onCloseModal = useCallback(() => {
        setIsOpen(false)
    }, [])
    const onOpenModal = useCallback(() => {
        setIsOpen(true)
    }, [])

    if (!items) {
        return null
    }

    return (
        <>
            <ButtonStyled
                type={'default'}
                onClick={onOpenModal}
            >
                <SettingOutlined/>
            </ButtonStyled>
            {isOpen && (
                <TableSettingsModal
                    isOpen={isOpen}
                    items={items}
                    onCancel={onCloseModal}
                />
            )}
        </>

    )

})