import * as React from 'react'
import { Layout, Empty } from 'antd'
import { Link } from 'react-router-dom'

export default function Error404NotFound() {
  return (
    <Layout style={{ minHeight: '100vh', paddingTop: 100, textAlign: 'center' }}>
      <Empty description={'Ошибка 404'} />
      <h1 style={{ margin: 30 }}>Запрошенная страница не найдена</h1>
      <p>
        <Link to="/">Вернуться на главную</Link>
      </p>
    </Layout>
  )
}
