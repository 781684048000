import { SET_TASKS, SET_COUNTS, SET_OBJECS } from './actions'

const initialState: any = {
  tasks: 0,
  counts: {
    order: 0,
    mounting: 0,
    measurement: 0,
    manufacture: 0,
    delivery: 0,
    complaint: 0,
    case: 0,
    audit: 0,
    phone: 0,
  },
  objects: [],
}

export const viewedReducer = (
  state = initialState,
  { type, ...props }: any,
) => {
  switch (type) {
    case SET_TASKS: {
      return {
        ...state,
        tasks: props.count
      }
    }
    case SET_COUNTS: {
      return {
        ...state,
        counts: props.counts
      }
    }
    case SET_OBJECS: {
      return {
        ...state,
        objects: props.objects
      }
    }
    default:
      return state
  }
}
