export const dateRange = {
  "type": "string",
  "format": "date-range",
  "title": "Дата"
}

export const typeAppeal = {
  "type": "string",
  "title": "Вид обращения",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}

export const complaintDecision = {
  "type": "string",
  "title": "Решение по рекламации",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}

export const confirmationForm = {
  "type": "string",
  "title": "Форма подтверждения",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}

export const guiltyDivision = {
  "type": "string",
  "title": "Виновное подразделение",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}

export const payer = {
  "type": "string",
  "title": "Плательщик",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}

export const status = {
  "type": "string",
  "title": "Статус рекламации",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}

