import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {NavBar } from 'antd-mobile'
import 'antd-mobile/dist/antd-mobile.css'

import { BurgerIcon } from '../../assets/icons'
import { MainMenu } from './main-menu'
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {getDataOfType} from "../../../lib/utils/get-data-of-type";
import fetchAPI from "../../../lib/utils/fetch-api";

const createSession = createSelector(
    (state: any) => state.session,
    sessionData => sessionData
)

export const B2BMobile: FC = props => {
  const { children } = props
  const [showMenu, setShowMenu] = useState(false)
  const { sessionData } = useSelector(createSession)
  const [user, setUser] = useState<any>(null)

  const handleMenuClick = useCallback(event => {
    event.preventDefault()

    setShowMenu(prevState => !prevState)
  }, [])

  const name = useMemo(() => {
    return user?.name
  }, [user])

  useEffect(() => {
    const userId = getDataOfType(
      sessionData,
      'user.id',
      Number,
      null,
    );

    (async () => {
      const user = await fetchAPI(`/api/profiles?type=user&user-id=${userId}`)
      const profileData = getDataOfType(user.data[0], 'profile-data', Object, null)
      setUser( profileData )
    })()
  }, [sessionData])

  const left = (
    <div style={{ display: 'flex', alignItems: 'center'}}>
      <div onClick={handleMenuClick}>
        <BurgerIcon/>
      </div>
      <div style={{margin: '0 15px', fontSize:'75%', lineHeight: '1.1em'}}>
        {name}
      </div>
    </div>
  )

  const title = () => {
    if ( window.location.pathname.includes('measurements') )
      return ' | ЗАМЕРЫ'
    if ( window.location.pathname.includes('mounting') )
      return ' | МОНТАЖИ'
  }

  return (
    <div className={showMenu ? 'menu-active' : ''}>
      <div>
        <NavBar
          mode="dark"
          className="top-nav-bar"
          leftContent={left}
          >
            ОКНА{title()}
          </NavBar>
      </div>
      {showMenu && (
        <MainMenu />
      )}
      {children}
    </div>
  )
}
