import utils from '../utils';
import handleSchema from '../schema.js';
const _ = require('underscore');

function getFieldNameNew(propertiesData){
  const maxNumber = Math.max(
    ...Object.keys(propertiesData)
      .map( key => Number(key.split(/^field_/)[1]))
      .filter( key => key > 0 && key !== Infinity)
  )
  if(maxNumber + 1 !== maxNumber){
    return  `field_${String(maxNumber + 1)}`
  }
  return  `field_1`
}
// eslint-disable-next-line
export default {
  state: {
    message: null,
    data: {
      title: '',
      type: 'object',
      properties: {},
      required: [],
    },
    open: {
      properties: true,
    },
  },

  changeEditorSchemaAction: function (state, { value }) {
    const rootFieldsKeys = Object.keys(value['properties'] || {})
    const rootRequired = (value['required'] || []).filter(key => rootFieldsKeys.includes(key))
    handleSchema({ ...value, required: rootRequired })
    state.data = { ...value, required: rootRequired }
  },

  changeNameAction: function (state, action, oldState) {
    const keys = action.prefix
    const name = action.name
    const value = action.value
    let oldData = oldState.data
    let parentKeys = utils.getParentKeys(keys)
    let parentData = utils.getData(oldData, parentKeys)
    let requiredData = [].concat(parentData.required || [])
    let propertiesData = utils.getData(oldData, keys)
    let newPropertiesData = {}

    let curData = propertiesData[name]
    let openKeys = [].concat(keys, value, 'properties').join(utils.JSONPATH_JOIN_CHAR)
    let oldOpenKeys = [].concat(keys, name, 'properties').join(utils.JSONPATH_JOIN_CHAR)
    if (curData.properties) {
      delete state.open[oldOpenKeys]
      state.open[openKeys] = true
    }

    if (propertiesData[value] && typeof propertiesData[value] === 'object') {
      return
    }

    requiredData = requiredData.map(item => {
      if (item === name) return value
      return item
    })

    parentKeys.push('required')
    utils.setData(state.data, parentKeys, requiredData)

    for (let i in propertiesData) {
      if (i === name) {
        newPropertiesData[value] = propertiesData[i]
      } else newPropertiesData[i] = propertiesData[i]
    }

    utils.setData(state.data, keys, newPropertiesData)
  },

  changeValueAction: function (state, action) {
    const keys = action.key
    if (action.value) {
      utils.setData(state.data, keys, action.value)
    } else {
      utils.deleteData(state.data, keys)
    }
  },

  changeTypeAction: function (state, action, oldState) {
    const keys = action.key
    const value = action.value
    const parentKeys = utils.getParentKeys(keys)
    const oldData = oldState.data
    const parentData = utils.getData(oldData, parentKeys)
    const newParentDataItem = utils.defaultSchema[value]
    // Копируем заголовок и описание, если они есть
    const parentDataItem = {}
    if ('description' in parentData) {
      parentDataItem['description'] = parentData['description']
    }
    if ('title' in parentData) {
      parentDataItem['title'] = parentData['title']
    }
    const newParentData = Object.assign({}, newParentDataItem, parentDataItem)
    const newKeys = [].concat('data', parentKeys)
    utils.setData(state, newKeys, newParentData)
  },

  enableRequireAction: function (state, action, oldState) {
    const keys = action.prefix
    let parentKeys = utils.getParentKeys(keys)
    let oldData = oldState.data
    let parentData = utils.getData(oldData, parentKeys)
    let requiredData = [].concat(parentData.required || [])
    let index = requiredData.indexOf(action.name)

    if (!action.required && index >= 0) {
      requiredData.splice(index, 1)
      parentKeys.push('required')
      if (requiredData.length === 0) {
        utils.deleteData(state.data, parentKeys)
      } else {
        utils.setData(state.data, parentKeys, requiredData)
      }
      return
    } 
    if (action.required && index === -1) {
      requiredData.push(action.name)
      parentKeys.push('required')
      utils.setData(state.data, parentKeys, requiredData)
    }
  },

  requireAllAction: function (state, action, oldState) {
    // let oldData = oldState.data;
    let data = utils.cloneObject(action.value)
    utils.handleSchemaRequired(data, action.required)

    state.data = data
  },

  deleteItemAction: function (state, action, oldState) {
    const keys = action.key

    let name = keys[keys.length - 1]
    let oldData = oldState.data
    let parentKeys = utils.getParentKeys(keys)
    let parentData = utils.getData(oldData, parentKeys)
    let newParentData = {}
    for (let i in parentData) {
      if (i !== name) {
        newParentData[i] = parentData[i]
      }
    }

    utils.setData(state.data, parentKeys, newParentData)
  },

  addFieldAction: function (state, action, oldState) {
    const keys = action.prefix
    let oldData = oldState.data
    let name = action.name
    let propertiesData = utils.getData(oldData, keys)
    let newPropertiesData = {}

    let parentKeys = utils.getParentKeys(keys)
    let parentData = utils.getData(oldData, parentKeys)
    let requiredData = [].concat(parentData.required || [])
    const ranName = getFieldNameNew(propertiesData)
    if (!name) {
      newPropertiesData = Object.assign({}, propertiesData)
      newPropertiesData[ranName] = utils.defaultSchema.string
      requiredData.push(ranName)
    } else {
      for (let i in propertiesData) {
        newPropertiesData[i] = propertiesData[i]
        if (i === name) {
          newPropertiesData[ranName] = utils.defaultSchema.string
          requiredData.push(ranName)
        }
      }
    }
    utils.setData(state.data, keys, newPropertiesData)
    // add required
    parentKeys.push('required')
    utils.setData(state.data, parentKeys, requiredData)
  },
  addChildFieldAction: function (state, action, oldState) {
    const keys = action.key
    let oldData = oldState.data
    let propertiesData = utils.getData(oldData, keys)
    let newPropertiesData = {}
    newPropertiesData = Object.assign({}, propertiesData)
    const ranName = getFieldNameNew(propertiesData)
    newPropertiesData[ranName] = utils.defaultSchema.string
    utils.setData(state.data, keys, newPropertiesData)

    // add required
    let parentKeys = utils.getParentKeys(keys)
    let parentData = utils.getData(oldData, parentKeys)
    let requiredData = [].concat(parentData.required || [])
    requiredData.push(ranName)
    parentKeys.push('required')
    utils.setData(state.data, parentKeys, requiredData)
  },

  setOpenValueAction: function (state, action, oldState) {
    const keys = action.key.join(utils.JSONPATH_JOIN_CHAR)

    let status
    if (_.isUndefined(action.value)) {
      status = utils.getData(oldState.open, [keys]) ? false : true
    } else {
      status = action.value
    }
    utils.setData(state.open, [keys], status)
  },
}
