import { TUseChunkDataload } from './interface'
import { useCallback, useEffect, useState } from 'react'

export const useChunkDataload: TUseChunkDataload = props => {
  const { total, dataCount, zeroOutPage = false, forceUpdate = false, dataloadCallback, node } = props
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [needUpdate, setNeedUpdate] = useState(false)
  const [startedListening, setStartedListening] = useState<boolean>(false)

  const handleTableScroll = useCallback(innerNode => () => {
    const perc = (innerNode.scrollTop / (innerNode.scrollHeight - innerNode.clientHeight)) * 100

    if (perc >= 100) {
      setPage(prevState => prevState + 1)
    }
  }, [])

  useEffect(() => {
    if (!startedListening && node) {
      setStartedListening(true)
      node.addEventListener('scroll', handleTableScroll(node))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node])

  useEffect(() => {
    if (dataCount < total || needUpdate) {
      dataloadCallback(page, limit)
      setNeedUpdate(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, needUpdate])

  useEffect(() => {
    if (zeroOutPage) {
      setPage(0)
      setNeedUpdate(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zeroOutPage])

  useEffect(() => {
    if (forceUpdate) {
      setPage(0)
      setLimit(total)
      setNeedUpdate(true)
    } else {
      setLimit(10)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate])

  return {
    page,
  }
}
