import React, { memo, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDeliveriesStageCurrent,
  selectDeliveriesStagePlanData,
  selectDeliveriesStagePlanError,
  selectDeliveriesStagePlanIsLoading
} from '../selectors'
import { getDeliveriesStagePlanData } from '../../../../store/deliveries/action-creators'
import { StagePlanDeliveries } from '../../../../components/v2/stage-plan-deliveries'

interface DeliveriesStagePlanProps {
  className?: string
  id: string | number
}

export const DeliveryStagePlan: React.FC<DeliveriesStagePlanProps> = memo((props: DeliveriesStagePlanProps) => {
  const {className, id} = props
  const dispatch = useDispatch()
  const stagePlanIsLoading = useSelector(selectDeliveriesStagePlanIsLoading)
  const stagePlanError = useSelector(selectDeliveriesStagePlanError)
  const stagePlanFiles = useSelector(selectDeliveriesStagePlanData)
  const stage = useSelector(getDeliveriesStageCurrent)

  useEffect(() => {
    let mounted = true
    if (stage !== undefined && id !== 'new') {
      if (mounted) {
        dispatch(getDeliveriesStagePlanData(id))
      }
    }
    return () => {
      mounted = false
    }
  }, [stage, id, dispatch])

  return (
    <Suspense fallback={''}>
      <StagePlanDeliveries
        isLoading={stagePlanIsLoading}
        items={stagePlanFiles}
        error={stagePlanError}
        className={className}
      />
    </Suspense>
  )
})
