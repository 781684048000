import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Table } from 'antd'

function TableSelected({ dataSource, onChange, selected }){
  const columns = useMemo(() => [{
      title: 'Роль',
      width: 290,
      key: 'title',
      dataIndex: 'title'
    }
  ], [])
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>(selected)
  const onSelect = useCallback(({ id }, selected) => setSelectedRowKeys((keys: any) => { 
    const result = selected 
    ? keys.concat(id) 
    : keys.filter(key => key !== id)
    onChange(result)
    return result
  }), [onChange])
  useEffect(() => {
    setSelectedRowKeys(selected)
  },[selected])
  const rowSelection = useMemo(() => ({
    selectedRowKeys,
    onSelect,
    hideSelectAll: true
  }), [selectedRowKeys, onSelect])
  const onHeaderRow = useCallback((column: any) => ({
    style: {
      fontSize: (column.key && column.key.substr(-3) === '-at') ? 9 : 13,
    }
  }), [])
  const loading = useMemo(() => Boolean(dataSource?.length) === false, [dataSource])

  return (<Table
    size="small"
    rowKey="id"
    pagination={false}
    rowSelection={rowSelection}
    columns={columns}
    dataSource={dataSource}
    loading={loading}
    onHeaderRow={onHeaderRow}
  />)
}

export default TableSelected