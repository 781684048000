import React, { memo, useCallback, useEffect } from 'react'
import { Tabs } from 'antd'
import cls from '../../card-case.module.css'
import { useDispatch} from 'react-redux'
import { classNames } from '../../../../../lib/classNames/classNames'
import { HStack } from '../../../../../ui/Stack'
import { AppealFiles } from '../tab-files'
import { AppealTimeline } from '../tab-timeline'
import { AppealStagePlan } from '../stage-plan'
import { Chats } from '../tab-chats/chats'
import {setTimelineClear} from "../../../../../store/timeline/actions";
import {getTimeLineData} from "../../../../../store/timeline/action-creators";
import {setStagePlanClear} from "../../../../../store/stagePlan/actions";
import TabProducts from '../tab-products'

interface AppealsTabsProps {
  className?: string
  onChange?: (key: string) => void
  onTabClick?: (key: string) => void
  id: string | number
}

export const MontageTabs: React.FC<AppealsTabsProps> = memo((props: AppealsTabsProps) => {
  const { className, onChange, onTabClick, id } = props
  const dispatch = useDispatch()

  useEffect(() => {
    let mounted = true
    dispatch(setStagePlanClear())
    if (id !== 'new') {
      if (mounted) {
        dispatch(getTimeLineData('case', id))
      }
    }
    return () => {
      mounted = false
      dispatch(setTimelineClear())
      dispatch(setStagePlanClear())
    }
  }, [id, dispatch])


  const handleOnChange = useCallback((key) => {
    if (onChange) {
      onChange(key)
    }
  }, [onChange])

  const handleOnTabClick = useCallback((key) => {
    if (onTabClick) {
      onTabClick(key)
    }
  }, [onTabClick])

  return <Tabs
      onChange={handleOnChange}
      type='card'
      style={{ marginTop: '2rem', backgroundColor: '#fff', paddingBottom: '2rem' }}
      className={classNames('mapContent', {}, [className])}
      onTabClick={handleOnTabClick}
    >
    <Tabs.TabPane tab='Лента событий' key='1'>
      <HStack align={'start'} justify={'between'}>
        <div className={cls.appealFilesTimeline}>
          <AppealStagePlan id={id}/>
        </div>
        <div className={cls.appealTimeline}>
          <AppealTimeline id={id}/>
        </div>
      </HStack>
    </Tabs.TabPane>
    <Tabs.TabPane tab='Данные заказа' key="2" className='chatBox'>
        <TabProducts id={id}/>
    </Tabs.TabPane>
    <Tabs.TabPane tab={<span className="chatTabText">Чаты</span>} key="3" className='chatBox'>
        <Chats id={id}/>
    </Tabs.TabPane>
    <Tabs.TabPane tab='Прикрепленные файлы' key='4'>
      <HStack align={'start'} justify={'between'}>
        <div className={cls.appealFilesTimeline}>
          <AppealFiles id={id} />
        </div>
      </HStack>
    </Tabs.TabPane>
  </Tabs>
})

export default MontageTabs;
