import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import WingBlank from 'antd-mobile/lib/wing-blank'
import Toast from 'antd-mobile/lib/toast'

import { B2BMobile } from '../../../layouts/b2b-mobile'
import { ErrorsList, PagePagination, pageSize, HeaderListFilters } from '../components'
import { formDataToUrlParams, getStagesGroup } from '../../list-orders/utils'
import { fetchSchema, formatListData } from '../../../../lib/utils/list'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import { collectionName } from './consts'
import WhiteSpace from 'antd-mobile/lib/white-space'
import { MountingListCard } from './mounting-list-cart'

export const MountingListPage = () => {
  const history = useHistory()
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [dataSource, setDataSource] = useState<any[]>([])
  const [stagesList, setStagesList] = useState<Record<string, any>[]>([])
  const [stageSelected, setStageSelected] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState(1)

  const getData = useCallback(async (value, addUrlParams?: string) => {
    const urlParams = formDataToUrlParams(value)
    const pageErrors: string[] = []
    const stageCodeParam = stageSelected.length > 0 && stageSelected[0] ? `stage-code=${stageSelected[0]}` : ''
    const [selectedSchema, metadata, collections, stages] = await Promise.all([
      fetchSchema({ name: collectionName }),
      fetchAPI(`/api/collections/${collectionName}/metadata`),
      fetchAPI(`/api/collections-case/${collectionName}?access_key=axioma&${urlParams}&${addUrlParams}&${stageCodeParam}`),
      getStagesGroup('mounting')
    ])

    const collectionsResultData = getDataOfType(collections, 'data.data', Array, [])
    const isMetadata = getDataOfType(metadata, 'data', Object, null)
    if(isMetadata === null) {
      pageErrors.push('Некорректный ответ сервера при получении метаданных коллекции')
    }
    const newDataSource: any[] = formatListData(collectionsResultData, selectedSchema)
    const totalRows = getDataOfType(collections, 'data.total-rows', Number, 0)

    setDataSource(newDataSource)
    setStagesList(stages)
    setTotalRows(totalRows)
    setPageErrors(pageErrors)
    setIsDataLoading(false)
  }, [stageSelected])

  useEffect(() => {
    getData({}, `page=1&pageSize=${pageSize}`)

    const timer = setInterval(() => getData({}, `page=1&pageSize=${pageSize}`), 3000)

    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageSelected])

  const handleOpenEdit = useCallback((id: number) => () => history.push(`/mounting/${id}/edit`), [history])

  useEffect(() => {
    if (isDataLoading) {
      Toast.loading('Загрузка...', 1);
    } else {
      Toast.hide()
    }
  }, [isDataLoading])

  const handleFilterChange = useCallback((value) => {
    setStageSelected(value)
  }, [])

  const stagesFilterList = useMemo(() => {
    return stagesList.length > 0 ? stagesList.map(stage => ({ label: stage['name'], value: stage['code']})) : []
  }, [stagesList])

  const handleCall = useCallback((value: string) => () => Object.assign(window.location, { href: `tel:${value}` }), [])

  return (
    <B2BMobile>
      <HeaderListFilters
        onChangeStage={handleFilterChange}
        stageSelected={stageSelected}
        stagesFilterList={stagesFilterList}
        stagesList={stagesList}
      />
      <WhiteSpace size="lg" />
      <ErrorsList pageErrors={pageErrors} />
      <WingBlank size="lg">
        {dataSource && dataSource.length > 0 && dataSource.map(data => (
          <MountingListCard
            key={`mounting-list-card-${data['id']}`}
            data={data}
            onCall={handleCall}
            onOpenEdit={handleOpenEdit}
            stagesList={stagesList}
          />
        ))}
      </WingBlank>
      <WingBlank>
        <PagePagination
          current={currentPage}
          setCurrent={setCurrentPage}
          onDataLoad={getData}
          onSetIsDataLoading={setIsDataLoading}
          totalRows={totalRows}
        />
      </WingBlank>
    </B2BMobile>
  )
}
