import {createSelector} from "reselect";


const selectAppealsStagePlan = state => state.stagePlan

export const selectStagePlanData = createSelector(
    selectAppealsStagePlan,
    ({items}) => items
)

export const selectStagePlanIsLoading = createSelector(
    selectAppealsStagePlan,
    ({isLoading}) => isLoading
)

export const selectStagePlanError = createSelector(
    selectAppealsStagePlan,
    ({error}) => error
)
