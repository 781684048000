import { IAction, IErrors } from '../interfaces'
import { GET_COLLECTIONS_ERROR, GET_COLLECTIONS_REQUEST, GET_COLLECTIONS_SUCCESS } from './actions'
import { ICollectionItem, ICollectionState } from './interfaces'

const initialState: ICollectionState = {
  isLoading: false,
  current: null,
  error: null,
}

export const collectionsReducer = (
  state = initialState,
  { type, payload }: IAction<ICollectionItem | IErrors>,
) => {
  switch (type) {
    case GET_COLLECTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        current: null,
      }
    case GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        current: payload as ICollectionItem,
      }
    case GET_COLLECTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload as IErrors
      }
    default:
      return {
        ...state,
      }
  }
}
