import {createSelector} from "reselect";

const selectSession = state => state.session

const selectSessionData = createSelector(
    selectSession,
    ({sessionData}) => sessionData,
)

enum Roles {
    // ГО лидер
    GO = 16,
// Руководитель дилера
    RD = 25,
// Менеджеры по продажам
    MPP = 3
}

export const getRole = createSelector(
    selectSessionData,
    ({roles}: { roles: number[] }) => {
        if (roles?.includes(Roles.GO)) {
            return 'go';
        }
        if (roles?.includes(Roles.RD)) {
            return 'd';
        }
        if (roles?.includes(Roles.MPP)) {
            return 'm';
        }
        return ''
    },
)