import React from 'react'
import SubMenu from 'antd/es/menu/SubMenu'
import { Menu } from 'antd'
import {
  ExperimentOutlined,
  MoneyCollectOutlined,
  SecurityScanOutlined,
  ForkOutlined,
  NodeExpandOutlined,
  PieChartOutlined,
  FolderOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { preventLink } from '../../../lib/utils/helpers'

export const DefaultMenu = ({ currentKey, currentSubMenuKey, onClick }) => {
  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={[currentKey]}
      defaultOpenKeys={[currentSubMenuKey]}
      mode="inline"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <SubMenu key="/call-center" title="Коммуникации" icon={<FolderOutlined />}>
        <Menu.Item key="/call-center/cases">
          <Link to="/call-center/cases" onClick={preventLink}>
            <span>Список обращений</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="/sales_orders/" title="Продажи" icon={<ExperimentOutlined />}>
        <Menu.Item key="/sales/orders">
          <Link to="/sales/orders" onClick={preventLink}>
            <span>Список заказов</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="/clients/" title="Клиенты" icon={<FolderOutlined />}>
        <Menu.Item key="/clients">
          <Link to="/clients" onClick={preventLink}>
            <span>Список клиентов</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub2" title="Финансы" icon={<MoneyCollectOutlined />}>
        <Menu.Item key="2">
          <Link to="2" onClick={preventLink}>
            <span>Дэшборд</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub3" title="Аудит" icon={<SecurityScanOutlined />}>
        <Menu.Item key="3">
          <Link to="/sales/audit" onClick={preventLink}>
            <span>Список аудитов</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub4" title="Диспетчеризация" icon={<ForkOutlined />}>
        <Menu.Item key="4">
          <Link to="4" onClick={preventLink}>
            <span>Дэшборд</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link to="5" onClick={preventLink}>
            <span>Title 2</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub5" title="Планирование" icon={<NodeExpandOutlined />}>
        <Menu.Item key="6">
          <Link to="/planning/tasks" onClick={preventLink}>
            <span>Задачи</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="7">
          <Link to="7" onClick={preventLink}>
            <span>Title 2</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub6" title="Замеры" icon={<FolderOutlined />}>
        <Menu.Item key="8">
          <Link to="/sales/measurements" onClick={preventLink}>
            <span>Список замеров</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub7" title="Производство" icon={<FolderOutlined />}>
        <Menu.Item key="9">
          <Link to="/production-page" onClick={preventLink}>
            <span>Список заказов на изготовление</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub8" title="Логистика" icon={<FolderOutlined />}>
        <Menu.Item key="10">
          <Link to="/logistic-delivery" onClick={preventLink}>
            <span>Список доставок</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub9" title="Монтаж" icon={<FolderOutlined />}>
        <Menu.Item key="11">
          <Link to="/sales/mounting" onClick={preventLink}>
            <span>Список монтажа</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub10" title="Рекламации" icon={<FolderOutlined />}>
        <Menu.Item key="12">
          <Link to="/sales/complaint" onClick={preventLink}>
            <span>Список рекламаций</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub118" title="Сотрудники" icon={<FolderOutlined />}>
        <Menu.Item key="118">
          <Link to="/employees" onClick={preventLink}>
            <span>Список сотрудников</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub11" title="Маркетинг" icon={<FolderOutlined />}>
        <Menu.Item key="13">
          <Link to="13" onClick={preventLink}>
            <span>Список обращений</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub1120" title="Договоры" icon={<FolderOutlined />}>
          <Menu.Item key="20">
            <Link to="/contracts-client" onClick={preventLink}>
              <span>Договоры участников</span>
            </Link>
        </Menu.Item>
        </SubMenu>
      <SubMenu key="sub12" title="Отчёты" icon={<PieChartOutlined />}>
        <Menu.Item key="14">
          <Link to="14" onClick={preventLink}>
            <span>Рейтинги</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="15">
          <Link to="15" onClick={preventLink}>
            <span>Доходы/расходы</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="16">
          <Link to="16" onClick={preventLink}>
            <span>РКО</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="17">
          <Link to="17" onClick={preventLink}>
            <span>Договоры</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="18">
          <Link to="18" onClick={preventLink}>
            <span>Конверсия</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="19">
          <Link to="19" onClick={preventLink}>
            <span>Производство</span>
          </Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  )
}
