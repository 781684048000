import { MenuAction, MenuState } from './interfaces'

const initialState: MenuState = {
  menu: [],
  counters: {},
  loading: false,
  error: null,
}

export const menuV2 = (state: MenuState = initialState, action: MenuAction): MenuState => {
  switch (action.type) {
    case 'FETCH_MENU_REQUEST':
      return { ...state, loading: true }
    case 'FETCH_MENU_SUCCESS':
      return { ...state, menu: action.payload, loading: false, error: null }
    case 'FETCH_MENU_FAILURE':
      return { ...state, menu: [], loading: false, error: action.payload }
    case 'SET_COUNTER_DATA':
      return { ...state, counters: action.payload }
    default:
      return state
  }
}
