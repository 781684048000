import React, { FC } from 'react'
import { FieldGroup, FieldName } from '../../measurements-edit/styles'
import Card from 'antd-mobile/lib/card'
import WhiteSpace from 'antd-mobile/lib/white-space'
import { IOrderNumberCardProps } from './interface'

export const OrderNumberCard: FC<IOrderNumberCardProps> = (props) => {
  const { orderNumber = 0, productionNumber = 0} = props

  return (<>
      <Card>
        <Card.Header
          title="Договор"
          extra={<span>Номера</span>}
        />
        <Card.Body>
          {orderNumber > 0 && (
            <FieldGroup>
              <FieldName>Номер договора с конечным клиентом:</FieldName>
              {orderNumber}
            </FieldGroup>
          )}
          <WhiteSpace size="lg" />
          {productionNumber > 0 && (
            <FieldGroup>
              <FieldName>Номер производственного заказа:</FieldName>
              {productionNumber}
            </FieldGroup>
          )}
        </Card.Body>
      </Card>
      <WhiteSpace size="lg" />
    </>
  )
}
