import React from 'react';

import { IconWrapper } from './styles';

export const HomeIcon = () => {
  return (
    <IconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 125" x="0px" y="0px"><title>09</title>
        <g data-name="Group">
          <path data-name="Compound Path"
            d="M77.54,29.88v-21H63.86V18.2L50,6.37,6.09,43.86l9.75,11.42,3.41-2.91V93.63H80.76V52.36l3.41,2.91,9.75-11.42Zm-11.68-19h9.68v17.3l-9.68-8.26Zm-8,80.75H42.12V69.41H57.87Zm20.88,0H59.87V67.41H40.12V91.63H21.24v-41L50,26.11,78.76,50.66Zm5.19-39.17L50,23.48l-33.94,29L8.91,44.08,50,9,63.86,20.83V21h.25l27,23Z" />
        </g>
      </svg>
    </IconWrapper>
  )
}

