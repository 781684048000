import React, { useMemo, useState, useCallback, FC, useEffect } from 'react';
import Pagination from 'antd-mobile/lib/pagination'
import { locale, pageSize } from './consts'
import { IPagePaginationProps } from './interface'

export const PagePagination: FC<IPagePaginationProps> = (props) => {
  const { totalRows, onDataLoad, current, setCurrent } = props
  // const [current, setCurrent] = useState<number>(1)
  const [total, setTotal] = useState<number>(1)

  useEffect(() => {
    setTotal(Math.ceil(totalRows / pageSize))
  }, [totalRows])

  const onChange = useCallback((page: number) => {
    const urlParams = `page=${page}&pageSize=${pageSize}`

    setCurrent(page)
    // onSetIsDataLoading(true)
    if(typeof onDataLoad === 'function') {
      onDataLoad({}, urlParams)
    }
    // onSetIsDataLoading(false)
  }, [onDataLoad, setCurrent])

  const paginationProps: any = useMemo(() => ({
    locale,
  }), [])

  return (<Pagination {...paginationProps} onChange={onChange} total={total} current={current} />)
}
