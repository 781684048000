import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tree, Button, Modal, Form, Input, Select, Spin, Alert, Tooltip } from 'antd'
import { PlusOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons'
import { getMenuData, getRolesData, saveMenuData } from '../../../store/menu_editor/action-creators'
import B2BLayout from '../../../layouts/b2b'
import { MapContent, MapTitle, MapWrapper, TaskWrapper } from '../../users/card/styles'
import IconSelect from '../../../components/icon-selector'
import IconComponent from '../../../components/icon-component'

const { TreeNode } = Tree
const { Option } = Select

interface MenuItem {
  id: number
  title?: string
  url?: string
  roles?: number[]
  children?: MenuItem[]
  parent?: number
  icon?: string
}

const MenuEditor: React.FC = () => {
  const dispatch = useDispatch()
  const { data, isLoading, error, roles, rolesLoading, rolesError  } = useSelector((state: any) => state.menuEditor)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>('Свойства')
  const [currentItem, setCurrentItem] = useState<MenuItem | null>(null)
  const [form] = Form.useForm()
  const [filteredRoles, setFilteredRoles] = useState(roles)

  useEffect(() => {
    if (roles.length) {
      setFilteredRoles(roles)
    }
  }, [roles])

  useEffect(() => {
    dispatch(getMenuData())
    dispatch(getRolesData())
  }, [dispatch])

  const onAdd = (parentKey: number) => {
    form.setFieldsValue({ parent: parentKey, title: undefined, url: undefined, roles: [], icon: undefined })
    const item = { id: 0, parent: parentKey, title: undefined, url: undefined, roles: [], icon: undefined }
    setCurrentItem(item)
    setModalTitle('Добавление нового элемента')
    setModalVisible(true)
  }

  const onEdit = useCallback((item: MenuItem) => {
    setCurrentItem(item)
    form.setFieldsValue({ title: item.title, url: item.url, roles: item.roles, icon: item.icon })
    setModalTitle('Свойства')
    setModalVisible(true)
  }, [setCurrentItem, currentItem])

  const handleModalOk = useCallback(async () => {
    try {
      const values = await form.validateFields()
      const updateItem = (items: MenuItem[]): MenuItem[] => {
        return items.map(item => {
          if (currentItem?.id === 0 && currentItem?.parent === item?.id) {
            if (item?.children?.length) {
              return {...item, children: [ ...item.children, { ...currentItem, ...values }]}
            }
            return {...item, children: [{ ...currentItem, ...values }]}
          }
          if (item.id === currentItem?.id) {
            return { ...item, ...values }
          }
          if (item.children) {
            return { ...item, children: updateItem(item.children) }
          }
          return item
        })
      }
      const updatedData = updateItem(data)
      dispatch(saveMenuData(updatedData))
      setModalVisible(false)
      setCurrentItem(null)
    } catch (error) {
      console.error('Ошибка сохранения данных:', error)
    }
  }, [form, currentItem])

  const handleModalCancel = () => {
    setModalVisible(false)
    setCurrentItem(null)
  }

  const renderTreeNodes = (items: MenuItem[]): React.ReactNode => {
    return items.map(item => {
      if (item.children) {
        return (
          <TreeNode title={renderTitle(item)} key={item.id}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        )
      }
      return <TreeNode key={item.id} title={renderTitle(item)} />
    })
  }

  const renderTitle = (item: MenuItem) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {item?.icon && (
        <span style={{ marginRight: 10 }} >
          <IconComponent iconName={item.icon} />
        </span>
      )}
      <span>{item.title}</span>
      <div style={{ marginLeft: 15 }}>
        <Button icon={<PlusOutlined />} size='small' type='link' onClick={() => onAdd(item.id)} style={{ marginRight: 4 }} />
        <Button icon={<EditOutlined />} size='small' type='link' onClick={() => onEdit(item)} style={{ marginRight: 4 }} />
        {!item.roles?.length && (
          <Tooltip title="Пункт меню будет скрыт для всех, т.к. не выбраны роли!" placement={'right'} color={'#F81D22'} style={{ maxWidth: 'max-content' }}>
            <WarningOutlined style={{ color: '#F81D22', marginLeft: '5px' }} />
          </Tooltip>
        )}
      </div>
    </div>
  )

  const breadcrumbs = [
    {
      url: '/admin',
      title: 'Admin'
    }
  ]

  const onDrop = (info: any) => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (data: MenuItem[], id: number, callback: (item: MenuItem, index: number, arr: MenuItem[]) => void) => {
      for (let i = 0; i < data.length; i++) {
        if (+data[i].id === +id) {
          return callback(data[i], i, data)
        }
        if (data[i].children) {
          loop(data[i].children!, +id, callback)
        }
      }
    }

    const dataCopy = [...data]

    let dragObj: MenuItem
    loop(dataCopy, +dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!info.dropToGap) {
      loop(dataCopy, +dropKey, (item) => {
        item.children = item.children || []
        item.children.push(dragObj!)
      })
    } else if (
      (info.node.children || []).length > 0 &&
      info.node.expanded &&
      dropPosition === 1
    ) {
      loop(dataCopy, +dropKey, (item) => {
        item.children = item.children || []
        item.children.unshift(dragObj!)
      })
    } else {
      let ar: MenuItem[] = []
      let i: number
      loop(dataCopy, +dropKey, (item, index, arr) => {
        ar = arr
        i = index
      })
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!)
      } else {
        ar.splice(i! + 1, 0, dragObj!)
      }
    }

    dispatch(saveMenuData(dataCopy))
  }

  const icons = [
    'BarChartOutlined',
    'BankOutlined',
    'StarOutlined',
    'SettingOutlined',
    'HomeOutlined',
    'HeartOutlined',
    'DollarOutlined',
    'UserOutlined',
    'PhoneOutlined',
    'CarOutlined',
    'AreaChartOutlined',
    'PieChartOutlined',
    'LineChartOutlined',
    'FormatPainterOutlined',
    'GlobalOutlined',
    'HistoryOutlined',
    'ProductOutlined',
    'FileExclamationOutlined',
    'ToolOutlined',
    'ColumnWidthOutlined',
    'HammerOutlined',
  ]

  const handleSearch = val => {
    const filtered = roles.filter(role => role.name.toLowerCase().includes(val.toLowerCase()))
    setFilteredRoles(filtered)
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>Редактор меню</MapTitle>
        </TaskWrapper>
        <MapContent>
          {(isLoading || rolesLoading) && <Spin />}
          {error && <Alert message='Ошибка' description={error} type='error' showIcon />}
          {error && <Alert message="Error" description={error} type="error" showIcon />}
          {rolesError && <Alert message="Error" description={rolesError} type="error" showIcon />}
          <Tree
            draggable
            onDrop={onDrop}
          >
            {renderTreeNodes(data)}
          </Tree>
          <Modal
            title={modalTitle}
            visible={modalVisible}
            maskClosable={true}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
          >
            <Form form={form} layout='vertical'>
              <Form.Item label='Иконка' name='icon'>
                <IconSelect iconList={icons} onChange={() => {}}/>
              </Form.Item>
              <Form.Item name='title' label='Название' rules={[{ required: true, message: 'Укажите название!' }]}>
                <Input />
              </Form.Item>
              <Form.Item name='url' label='Ссылка' rules={[{ required: true, message: 'Укажите ссылку!' }]}>
                <Input />
              </Form.Item>
              <Form.Item name='roles' label='Роли'>
                { filteredRoles.length && (
                  <Select mode='multiple' onSearch={handleSearch} filterOption={false}>
                    {filteredRoles.map(role => (
                      <Option key={role.id} value={role.id}>{role.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Form>
          </Modal>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}

export default MenuEditor
