import fetchAPI from '../../../lib/utils/fetch-api'
import {
  setMontageId,
  setMontageEnumsData,
  setMontageEnumsFailed,
  setMontageEnumsLoading,
  setMontageFormData,
  setMontageFormFailed,
  setMontageFormLoading,
} from './actions'

export function getMontageForm(id) {
  return async dispatch => {
    dispatch(setMontageFormLoading())
    try {
      const response = await fetchAPI(`/api/montage/${id}`) || []
      if (response['errors'] || response?.error) {
        let error = response['errors'] || response?.error
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setMontageFormFailed(error))
      }
      return dispatch(setMontageFormData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setMontageFormFailed('При загрузки полей возникла ошибка'))
    }
  }
}

export function nextStage(id, formData) {
  return async dispatch => {
    dispatch(setMontageFormLoading())
    try {
      console.log('dispatch nextStage', formData)
      const response =  await fetchAPI(
        `/api/montage/${id}`,
        { method: 'POST', body: JSON.stringify(formData) }
      )

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error =  error.join( )
        }
        return dispatch(setMontageFormFailed(error))
      }
      return dispatch(setMontageFormData(response))
    } catch (err) {
      return dispatch(setMontageFormFailed(err as string))
    }
  }
}

export function updateFormData(id, formData) {
  return async dispatch => {
    try {
      const response = await fetchAPI(
        `/api/montage/${id}`,
        {
          method: 'POST', body: JSON.stringify(formData)
        }
      )

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setMontageFormFailed(error))
      }

      if (response?.id) {
        return dispatch(setMontageId(response.id))
      }

      return
    } catch (err) {
      return dispatch(setMontageFormFailed(err as string))
    }
  }
}

export function getMontageEnums(id: string) {
  return async dispatch => {
    dispatch(setMontageEnumsLoading())
    try {
      const response = await fetchAPI(`/api/montage/${id}/enum`)

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setMontageEnumsFailed(error))
      }

      return dispatch(setMontageEnumsData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setMontageEnumsFailed('При загрузке enum возникла ошибка'))
    }
  }
}

