import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import { IResourcesTableProps, IResourcesTableData, IColumn } from '../interfaces/interfaces'
import '../style.css'

const monthOrder = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

const ResourcesTable: React.FC<IResourcesTableProps> = ({ data, columns, showTotal }) => {
  const [sortedInfo, setSortedInfo] = useState<any>({ columnKey: 'month', order: 'ascend' })

  useEffect(() => {
    setSortedInfo({ columnKey: 'month', order: 'ascend' })
  }, [])

  const generateTotalRow = (data: IResourcesTableData[]) => {
    const totalRow: IResourcesTableData = {
      month: 'Всего',
      year: '',
      total: 0,
    }

    columns.forEach(column => {
      const key = column.dataIndex
      if (key !== 'month' && key !== 'total') {
        totalRow[key] = data.reduce((acc, curr) => acc + (curr[key] || 0), 0)
      }
    })

    totalRow.total = data.reduce((acc, curr) => acc + curr.total, 0)

    return totalRow
  }

  const dataSource = showTotal || data.length > 1 ? [...data, generateTotalRow(data)] : data

  const customSorter = (
    a: IResourcesTableData,
    b: IResourcesTableData,
    dataIndex: string,
    order: string,
  ) => {
    if (a.month === 'Всего') return 1
    if (b.month === 'Всего') return -1
    if (dataIndex === 'month') {
      return order === 'ascend'
        ? monthOrder.indexOf(a[dataIndex]) - monthOrder.indexOf(b[dataIndex])
        : monthOrder.indexOf(b[dataIndex]) - monthOrder.indexOf(a[dataIndex])
    }
    return order === 'ascend' ? a[dataIndex] - b[dataIndex] : b[dataIndex] - a[dataIndex]
  }

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter)
  }

  const formatDataSource = (data: IResourcesTableData[]) => {
    return data.map(item => {
      const formattedItem = { ...item }
      columns.forEach(column => {
        const key = column.dataIndex
        if (formattedItem[key] === undefined || formattedItem[key] === null) {
          formattedItem[key] = 0
        }
      })
      return formattedItem
    })
  }

  const sortedDataSource = formatDataSource(dataSource).sort((a, b) =>
    customSorter(a, b, sortedInfo.columnKey, sortedInfo.order),
  )

  const columnsWithStyles = columns.map(column => ({
    ...column,
    sorter: true,
    sortOrder: sortedInfo.columnKey === column.key && sortedInfo.order,
    className: column.dataIndex === 'total' ? 'total-column' : '',
    render: (text: any, record: IResourcesTableData) => {
      const isTotalRow = record.month === 'Всего'
      return {
        children: <span>{text}</span>,
        props: {
          className: isTotalRow ? 'total-row-cell' : '',
        },
      }
    },
  }))

  return (
    <Table
      dataSource={sortedDataSource}
      columns={columnsWithStyles}
      rowKey="month"
      pagination={false}
      size="small"
      onChange={handleChange}
      rowClassName={record => (record.month === 'Всего' ? 'total-row' : '')}
    />
  )
}

export default ResourcesTable
