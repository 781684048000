import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { JSONSchema7, JSONSchema7Definition } from 'json-schema'
import { createSelector } from 'reselect';
import { debounce } from 'lodash'

import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import fetchAPI from '../../../../lib/utils/fetch-api'
import {
  addActionsTimeLine,
  clearCaseStore,
  initialCase, reloadActionsTimeLine,
  setAppealStage,
  setCaseId,
  setTargetId,
} from '../../../store/appeals/actions'
import { B2BMobile } from '../../../layouts/b2b-mobile'
import { cardName, name } from './consts'
import WingBlank from 'antd-mobile/lib/wing-blank';
import WhiteSpace from 'antd-mobile/lib/white-space';
import { BackToDeliveryList } from './styles';
import {
  ClientCard,
  CommentCard, DocumentCard,
  ErrorsList,
  HeaderBlock,
  ManagerCard, TDocumentItem,
  ValidationsMenu,
  HeaderLeftContent,
} from '../components'
import { ActionsList } from './actions-list'
import { setStageFromCard } from '../../../../lib/utils/cards'
//import { UpdateTaskIsStage } from '../../card-order/utils'
import { DeliveryCard } from './delivery-card'
import { collectionName } from '../measurements-edit/consts'
import { getDocumentList } from '../utils';

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  appealsCurrentStep => appealsCurrentStep,
  workflows => workflows,
  targetId => targetId
)
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

export const DeliveryEditPage = () => {
  const history = useHistory()
  const { id } = useParams()  as any
  const dispatch = useDispatch()
  const parentIdRef = useRef<any>(null)
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [deliveryData, setDeliveryData] = useState<Record<string, any>>({})
  const [forecast, setForecast] = useState<Record<string, any>>({})
  const { workflows } = useSelector(createAppealsCurrentStep)
  const { sessionData } = useSelector(createSession)
  const [validations, setValidations] = useState<JSONSchema7Definition[]>([])
  const [showSubMenuModal, setShowSubMenuModal] = useState(false)
  const [nextStage, setNextStage] = useState('')
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<JSONSchema7 | null>(null)
  const [hideHeaderPopover, setHideHeaderPopover] = useState<boolean | undefined>(undefined)
  const [managerData, setManagerData] = useState<Record<string, any>>({})
  const [author, setAuthor] = useState(0)
  const [documents, setDocuments] = useState<TDocumentItem[]>([])

  const authorId = useMemo(() => getDataOfType(sessionData, 'user.id', Number, 0), [sessionData])

  const currentStages = getDataOfType(workflows, cardName, Array, [])

  const getData = useCallback(async () => {
    const fields = [
      'title',
      'description',
      'type',
      'properties',
      'required',
      'created_by',
      'created_at',
      'updated_at',
    ].join(',')
    const responseSchemas = await fetchAPI(`/api/schemas?access_key=axioma&fields=${fields}`)
    const fetchedSchemas: JSONSchema7[] = getDataOfType(responseSchemas, 'data.data', Array, [])
    const selectedSchemas = fetchedSchemas.filter(item => item['name'] === name)
    const selectedSchema = (typeof selectedSchemas[0] === 'object') ? selectedSchemas[0] : null
    if (selectedSchema === null) {
      setPageErrors(['Некорректный ответ сервера при смене коллекции'])

      return
    }


    const collectionsObjectsResponse = await fetchAPI(`/api/collections/objects/${id}`)
    const collectionsObjectsResponseData = getDataOfType(collectionsObjectsResponse, 'data', Object, {})
    const deliveryObject = getDataOfType(collectionsObjectsResponseData, 'data.attributes', Object, {})
    const caseId = Number(deliveryObject.caseId || 0)
    const parentId = Number(collectionsObjectsResponseData['parent-id'])
    const parentIds = [parentId]

    setMatchedJSONSchema(selectedSchema)
    setAuthor(authorId)
    dispatch(setTargetId({ targetId: parentId }))
    parentIdRef.current = Number(parentId)
    const forecastResponse = await fetchAPI(`/api/order-forecast/${parentIdRef.current}`)
    const managerData = forecastResponse?.[0]?.responsibles?.[0]
    managerData && setManagerData(managerData)

    if (forecastResponse && forecastResponse['data'] && Array.isArray(forecastResponse['data'])) {
      const deliveryForecast = forecastResponse['data'].find(f => f['code'] === cardName)
      if (deliveryForecast) {
        setForecast(deliveryForecast)
      }
    }

    if(caseId) {
      parentIds.push(caseId)
    }
/*
    if (deliveryObject['case-manager']) {
      const cseManagerResponse = await fetchAPI(`/api/profiles/${deliveryObject['case-manager']}?type=organization`)

      if (cseManagerResponse && cseManagerResponse['data']) {
        setManagerData(cseManagerResponse['data'])
      }
    }
    */

    if(Number(parentId)) {
      dispatch(setCaseId({ caseId: parentId, parentIds }))
    }


    if(collectionsObjectsResponseData['created-at']){
      const paramsDispatch = addActionsTimeLine({ type: 'info', items: [
          {
            date: collectionsObjectsResponseData['created-at'],
            text: 'Создание записи'
          }
        ]})
      dispatch(paramsDispatch)
    }

    setPageErrors([])
    setDeliveryData(deliveryObject)

    const stage = deliveryObject['stage'] || 0

    dispatch(setAppealStage({ step: stage }))

    setIsDataLoading(false)
  }, [authorId, dispatch, id])

  useEffect(() => {
    getData()

    const timer = setInterval(() => getData(), 3000)

    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(initialCase({ targetId: null, cardName: cardName }))
    const result = setCaseId({ caseId: id, parentIds: [id] })
    dispatch(result)
    return () => { dispatch(clearCaseStore()) }
  }, [id, dispatch])

  useEffect(() => {
    const updateDocList = async () => {
      const docList = await getDocumentList(matchedJSONSchema, deliveryData)

      if (docList.length > 0) {
        setDocuments(docList)
      }
    }

    if (matchedJSONSchema && matchedJSONSchema['properties']) {
      updateDocList()
    }
  }, [deliveryData, matchedJSONSchema])

  const getValidatorsData = useCallback((validator: string): Record<string, any> | null => {
    let result: Record<string, any> | null = null

    if (matchedJSONSchema && matchedJSONSchema['properties'] && matchedJSONSchema['properties'][validator]) {
      result = matchedJSONSchema['properties'][validator] as Record<string, any>
    }

    return result ? { ...result, code: validator } : null
  }, [matchedJSONSchema])

  useEffect(() => {
    if(sessionData === null || workflows === null){
      return
    }
    getData()
  }, [getData, sessionData, workflows])

  const stagesName = useMemo(() => {
    const stage = currentStages[deliveryData['stage']] || { name: '' }

    return stage['name']
  }, [deliveryData, currentStages])

  const handleOpenDeliveryList = useCallback(() => {
    history.push('/logistic-delivery')
  }, [history])

  const setData = useCallback(newFromData => {
    let result = { ...deliveryData, ...newFromData }

    setDeliveryData(prevState => {
      result = {
        ...prevState,
        ...newFromData,
      }

      return result
    })

    return result
  }, [deliveryData])

  const handleCloseHeaderPopover = useCallback(() => {
    setHideHeaderPopover(true)
  }, [])

  const actionsList = useMemo(() => {
    const handleStageToWork = async (parentId, stage, stageCodeNext) => {
      setData({ stage })
      await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: parentId,
        isHistory: true,
      })
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
      setData({ stage })
    }

    const handleUpdateStage = async (parentId, stage, stageCodeNext) => {
      await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: parentId,
        isHistory: true,
      })
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
      setData({ stage })
    }

    const handleContractSigned = async (parentId, stage, stageCodeNext) => {
      /*
      await UpdateTaskIsStage({ parentId, stage: 'measurement-assigned', formData: {
          'date-completion': new Date().toISOString(),
          'status': 'completed'
        }})
      await UpdateTaskIsStage({ parentId, stage: 'order-contract-signed', formData: {
          'date-completion': new Date().toISOString(),
          'status': 'completed'
        }})
        */
      await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: parentId,
        isHistory: true,
      })
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
      setData({ stage })
    }

    const handleTransition = async (parentId, stage, stageCodeNext) => {
      await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: parentId,
        isHistory: true,
      })
      dispatch(reloadActionsTimeLine())
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      setData({ stage })
    }

    const handleOpenSubMenu = (validationsList, nextStage, newNextStageIndex, isAdditional = false) => {
      setValidations(validationsList)
      setShowSubMenuModal(true)

      if (!isAdditional) {
        setNextStage(nextStage)
      }
    }

    return (
      <ActionsList
        id={id}
        stageList={currentStages}
        formData={deliveryData}
        parentId={parentIdRef.current}
        matchedJSONSchema={matchedJSONSchema}
        onStageToWork={handleStageToWork}
        onContractSigned={handleContractSigned}
        onTransition={handleTransition}
        sessionData={sessionData}
        getValidatorsData={getValidatorsData}
        openSubMenu={handleOpenSubMenu}
        onClose={handleCloseHeaderPopover}
        onUpdateStage={handleUpdateStage}
      />
    )
  }, [
    id,
    currentStages,
    deliveryData,
    matchedJSONSchema,
    sessionData,
    getValidatorsData,
    handleCloseHeaderPopover,
    setData,
    dispatch,
  ])

  const isDisabledSwitchStage = useMemo(() => {
    let result = validations.length > 0

    if (result && deliveryData) {
      /** result = false if all validations check */
      result = validations.some(valid => !deliveryData[valid['code']])
    }

    return result
  }, [deliveryData, validations])

  const isShowHeaderPopover = useMemo(() => {
    return showSubMenuModal || (hideHeaderPopover === true) ? false : undefined
  }, [hideHeaderPopover, showSubMenuModal])

  const handlerDropHeaderPopoverShowState = useCallback(() => {
    setHideHeaderPopover(undefined)
  }, [])

  const handleCall = useCallback((value: string) => () => {
      Object.assign(window.location, { href: `tel:${value}` })
    },
    [])

  const haveManagerData = useMemo(() => {
    return Object.keys(managerData).length > 0 && managerData['profile-data']
  }, [managerData])

  const currentManagerData = useMemo(() => {
    return managerData['profile-data'] ? managerData['profile-data'] : {
      name: '',
      phone: '',
      email: '',
    }
  }, [managerData])

  const handleSubMenuClose = useCallback(() => {
    setShowSubMenuModal(false)
  }, [])
// eslint-disable-next-line
  const saveCase = useCallback(debounce(async formData => {
    const requestBody = {
      data: {
        name: '',
        title: '',
        attributes: formData,
        metadata: { schema: collectionName }
      }
    }

    const result = await fetchAPI(`/api/collections/-/objects/${id}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })
    const updated = getDataOfType(result, 'data.updated-at', [String, Number], null)

    if (updated === null) {
      console.warn('Ошибка сохранения записи')
    }

    return result
  }, 1000), [])

  const handleModalStageChange = useCallback(async () => {
    await setStageFromCard({
      cardName: cardName,
      stageCode: nextStage,
      targetId: parentIdRef.current,
      isHistory: true,
    })
    setShowSubMenuModal(false)
  }, [nextStage])

  const updateData = useCallback(async nextFormData => {
    const formData = setData(nextFormData)
    const response = await saveCase(formData)

    dispatch(reloadActionsTimeLine())

    return response
  }, [dispatch, saveCase, setData])

  const haveDocument = useMemo(() => documents.length > 0, [documents])

  return (
    <B2BMobile>
      <HeaderBlock
        leftContent={
          <HeaderLeftContent id={parentIdRef.current} name={stagesName} />
        }
        popoverOverlay={actionsList}
        show={isShowHeaderPopover}
        onAfterClose={handlerDropHeaderPopoverShowState}
      />
      <ErrorsList pageErrors={pageErrors} />
      <WingBlank size="lg">
        {!isDataLoading && (
          <>
            <WhiteSpace size="lg" />
            <BackToDeliveryList onClick={handleOpenDeliveryList}>{'<'} К списку доставок</BackToDeliveryList>
            <WhiteSpace size="lg" />
            <ClientCard onCall={handleCall} data={deliveryData} />
            <DeliveryCard data={forecast} />
            {haveManagerData && (
              <ManagerCard data={currentManagerData} onCall={handleCall} />
            )}
          </>
        )}
        {isDataLoading && (
          <span>Loading...</span>
        )}
        <CommentCard
          targetId={parentIdRef.current}
          taskOwnerId={deliveryData['measurer']}
        />
        {haveDocument && (
          <DocumentCard data={documents} />
        )}
      </WingBlank>
      <ValidationsMenu
        cardName={cardName}
        targetId={parentIdRef.current}
        author={author}
        validations={validations}
        show={showSubMenuModal}
        isCantSwitchStage={isDisabledSwitchStage}
        onClose={handleSubMenuClose}
        onSwitchStage={handleModalStageChange}
        data={deliveryData}
        updateData={updateData}
        adjustedDateKey="date-adjusted-delivery"
        adjustedTimeKey="time-adjusted-delivery"
      />
    </B2BMobile>
  )
}
