import styled from 'styled-components'
import { ClockCircleOutlined as ClockCircleOutlinedIcon } from '@ant-design/icons'

export const TimePickerRangeWrapper = styled.div`
  display: flex;
  width: 200px;
  flex-direction: row;
  justify-content: space-between;
`


export const ClockCircleOutlined = styled(ClockCircleOutlinedIcon)`
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
`