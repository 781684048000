import { IEnumsData, IFormData } from './interfaces'

export const MEASUREMENT_FORM_LOADING = 'MEASUREMENT_FORM_LOADING'
export const MEASUREMENT_FORM_DATA = 'MEASUREMENT_FORM_DATA'
export const MEASUREMENT_FORM_FAILED = 'MEASUREMENT_FORM_FAILED'
export const MEASUREMENT_FORM_ID_UPDATE = 'MEASUREMENT_FORM_ID_UPDATE'
export const MEASUREMENT_ENUMS_LOADING = 'MEASUREMENT_ENUMS_LOADING'
export const MEASUREMENT_ENUMS_DATA = 'MEASUREMENT_ENUMS_DATA'
export const MEASUREMENT_ENUMS_FAILED = 'MEASUREMENT_ENUMS_FAILED'


/* ============== ORDERS_FORM ============== */
export function setMeasurementFormLoading() {
  return async dispatch => {
    dispatch({ type: MEASUREMENT_FORM_LOADING })
  }
}

export function setMeasurementFormData(formData: IFormData) {
  return async dispatch => {
    dispatch({ type: MEASUREMENT_FORM_DATA, payload: formData })
  }
}

export function setMeasurementFormFailed(error: string) {
  return async dispatch => {
    dispatch({ type: MEASUREMENT_FORM_FAILED, payload: error })
  }
}

export function setMeasurementId(id: string) {
  return async dispatch => {
    dispatch({ type: MEASUREMENT_FORM_ID_UPDATE, payload: id })
  }
}

/* ============== end ORDERS_FORM ============== */

/* ============== ORDERS_ENUMS ============== */
export function setMeasurementEnumsLoading() {
  return async dispatch => {
    dispatch({ type: MEASUREMENT_ENUMS_LOADING })
  }
}

export function setMeasurementEnumsData(enumsData: IEnumsData) {
  return async dispatch => {
    dispatch({ type: MEASUREMENT_ENUMS_DATA, payload: enumsData })
  }
}

export function setMeasurementEnumsFailed(error: string) {
  return async dispatch => {
    dispatch({ type: MEASUREMENT_ENUMS_FAILED, payload: error })
  }
}

/* ============== end ORDERS_ENUMS ==============  */


export function reloadTablePayment() {return}
