import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Timeline } from 'antd'

import { TabContent } from './styles'
import B2BLayout from '../../layouts/b2b'
import { EditCollectionObject } from '../../components/app-edit-collection-object'
import { createCollections } from '../../../lib/utils/collections'
import { getCollectionsMetadata } from '../../store/collections/actions'

export default function AdminEditObjectPage() {
  const dispatch = useDispatch()
  const { name, id, group } = useParams() as any
  const groupId = Number(group) || 0
  const collections = useSelector(createCollections)
  const [collectionMetadata, setCollectionMetadata] = useState({})

  const breadcrumbs = [{
    href: '/admin',
    title: 'Администрирование',
  }, {
    href: '/admin/collections',
    title: 'Справочники',
  }, {
    href: `/admin/collections/${name}/objects`,
    title: (collectionMetadata && collectionMetadata['title']) || '...',
  }]

  useEffect(() => {
    dispatch(getCollectionsMetadata(name))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

   useEffect(() => {
    if (collections !== null) {
      setCollectionMetadata(collections)
    }
  }, [collections])

  const footer = useCallback(() => (
    <Tabs type="card">
      <Tabs.TabPane tab="История изменений" key="1">
        <TabContent>
          <Timeline>
            <Timeline.Item>
              {new Date().toLocaleDateString('ru-ru')} Создание новой записи
            </Timeline.Item>
          </Timeline>
        </TabContent>
      </Tabs.TabPane>
    </Tabs>
  ), [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <EditCollectionObject title="Редактирование объекта" id={id} collectionName={name} groupId={groupId} footer={footer} />
    </B2BLayout>
  )
}
