import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { columns } from './consts'
import { IRow } from './interface'
import { useChunkDataload } from '../../hooks/use-chunk-dataload'
import { useSorter } from '../../hooks/use-sorter'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'

function textUser(response, userId){
  const users = response['data']['users']
  return getDataOfType(users.find(row => Number(row['id']) === Number(userId)), 'name', String, '')
}

export default function AdminSchemasListPage() {
  const history = useHistory()
  const [dataSource, setDataSource] = useState<IRow[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [tableNode, setTableNode] = useState<HTMLDivElement | null>(null)
  const [totalData, setTotalData] = useState(0)
  const [orderBy, setOrderBy] = useState('id')
  const [orderDir, setOrderDir] = useState('desc')
  const [zeroOutPage, setZeroOutPage] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [currentOrder, setCurrentOrder] = useState({ orderBy: 'id', orderDir: 'desc' })
  const wrapperRef = useRef<HTMLDivElement>(null)
  const breadcrumbs = [{
    href: '/admin',
    title: 'Администрирование'
  }, {
    href: '/admin/schemas',
    title: 'Структуры (схемы)',
  }]
  let totalWidth: any = 0

  columns().forEach(({ width }) => {
    totalWidth += width
  })

  const handleSort = useCallback((key, direction) => {
    setOrderBy(key)
    setOrderDir(direction)
  }, [])

  const { onColumnSort } = useSorter({
    onSort: handleSort,
  })

  const parsedColumns = useMemo(() => columns(onColumnSort), [onColumnSort])

  const getData = useCallback((page = 0, limit = 10) => {
    setIsDataLoading(true)
    fetchAPI(`/api/schemas?access_key=axioma&fields=scope,title,description,metadata,created_by,owner_user,created_at,updated_at&&page=${page}&order_by=${orderBy}&order_dir=${orderDir}&limit=${limit}`)
      .then(response => {
        const rows = (response['data'] && response['data']['data']) || []
        const newDataSource: IRow[] = page === 0 ? [] : [ ...dataSource ]

        setTotalData(response['data']['total'])

        try {
          rows.forEach(item => {
            newDataSource.push({
              id: item['id'],
              ownerOrganization: item['owner-organization'],
              scope: item['scope'],
              name: item['name'],
              title: item['title'],
              description: item['description'],
              ownerUser: item['owner-user'] && textUser(response, item['owner-user']),
              group:
                (item['metadata'] && item['metadata']['group']) || 'Не задана',
              createdBy: item['created-by'],
              createdAt: item['created-at'],
              updatedAt: item['updated-at'],
            })
          })
        } catch (error) {
          console.error('Get schemas data error', error)
        }

        setDataSource(newDataSource)
        setIsDataLoading(false)
      })
      .catch(() => {
        setIsDataLoading(false)
      })
  }, [dataSource, orderBy, orderDir])

  const handleGetData = (page: number, limit: number) => {
    getData(page, limit)
    setZeroOutPage(false)
  }

  /** Add event listener on scroll of table for getting data from getData */
  useChunkDataload({
    total: totalData,
    dataCount: dataSource.length,
    zeroOutPage,
    forceUpdate,
    node: tableNode,
    dataloadCallback: handleGetData
  })

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Start request data with zero out of sorting when changing order by or order direction */
  useEffect(() => {
    if (currentOrder['orderBy'] !== orderBy || currentOrder['orderDir'] !== orderDir) {
      setCurrentOrder({
        orderDir,
        orderBy,
      })
      setZeroOutPage(currentOrder['orderBy'] !== orderBy)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, orderDir])

  /** Start request data when changed direction of current sorting */
  useEffect(() => {
    setForceUpdate(currentOrder['orderBy'] === orderBy && currentOrder['orderDir'] !== orderDir)
  }, [currentOrder, orderBy, orderDir])

  useEffect(() => {
    if (!isDataLoading && !tableNode) {
      if (wrapperRef !== null && wrapperRef.current !== null) {
        const node = wrapperRef.current.querySelector<HTMLDivElement>('.ant-table-body')

        if (node) {
          setTableNode(node)
        }
      }
    }
  }, [isDataLoading, tableNode])

  function handleCreateNewClick() {
    history.push('/admin/schemas/new')
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <div ref={wrapperRef}>
        <Button onClick={handleCreateNewClick} style={{ marginBottom: 16 }}>
          <PlusOutlined />
          Создать структуру
        </Button>
        <Table
          columns={parsedColumns}
          dataSource={dataSource}
          scroll={{ x: totalWidth, y: '72vh' }}
          pagination={false}
          loading={isDataLoading}
          onHeaderRow={(column: any) => ({
            style: {
              fontSize: (column.key && column.key.substr(-3) === '-at') ? 10 : 13,
            }
          })}
        />
      </div>
    </B2BLayout>
  )
}
