import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {ConfigProvider, Input, Space} from 'antd'
import SelectFromEnum from 'src/react-app/components/select-from-enum'
//import InputDateRange from 'src/react-app/components//input-date-range'
import {useSelector} from 'react-redux'
import {createSelector} from 'reselect'
import {
  city,
  organization,
  managers,
  auditResponsiples,
  orderAttribute,
  campaign,
  cancelTitle,
  deliveryResponsiples,
  manufactureResponsiples,
  measurementResponsiples,
  medium,
  mountingResponsiples,
  orderResponsiples,
  sourceAppeal,
  source,
  stageCode,
} from './utils'
import fetchAPI from "../../../../lib/utils/fetch-api";
import moment from "moment/moment";
import {ITime} from "../../../components/input-date-range/interface";
import locale from "antd/es/locale/ru_RU";
import {DateRangePicker} from "../../../components/input-date-range/styles";

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

function setEnumSchemaSelect(prop, oneOf, enumValues) {
  Object.assign(prop, {
    oneOf: oneOf,
    'enum': enumValues,
    view: {'name': 'select-from-enum'}
  })
}

function dateToMoment(date) {
  if (Boolean(date) === false) {
    return
  }
  return moment(date).utc(false)
}

function InputDateRange({value, onChange, name, code}) {
  const handleChange = useCallback(interval => {
    if (Array.isArray(interval) === false) {
      return onChange(undefined, code)
    }
    const [start, end] = interval
    if ((start && end) === false) {
      return onChange(undefined, code)
    }
    const timeRange = moment(start).format('YYYY.MM.DD') + '-' + moment(end).format('YYYY.MM.DD')
    onChange(timeRange, code)
  }, [onChange])
  const valueFormat = useMemo<any>(() => {
    if (Boolean(value) === false) {
      return undefined
    }
    const [start, end] = value.split('-')
    if ((start && end) === false) {
      return undefined
    }
    return [dateToMoment(start), dateToMoment(end)]
  }, [value])

  // @ts-ignore
  return (
    <ConfigProvider locale={locale}>
      <DateRangePicker
        onChange={handleChange}
        value={valueFormat}
        placeholder={[name, name]}
        style={{'width': '320px'}}
      />
    </ConfigProvider>
  )
}

function InputFilter({onChange, placeholder, code}) {
  const onPressEnter = useCallback(({target}) => {
    onChange(target?.value, code)
  }, [onChange])
  const onLocalChange = useCallback(({target}) => {
    const value = target?.value
    if (typeof value !== 'string' || value === '') {
      onChange(undefined, code)
    }
  }, [onChange])
  const onSearch = useCallback((value) => {
    onChange(value, code)
  }, [onChange])
  return <Input.Search placeholder={placeholder} onPressEnter={onPressEnter} onChange={onLocalChange}
                       onSearch={onSearch} allowClear style={{width: 200}}/>
}

function Filter({onChange}) {
  const {sessionData} = useSelector(createSession)
  const [formData, setFormData] = useState<any>(() => ({}))

  const [schemaCity, setSchemaCity] = useState<any>({})
  const [schemaOrganization, setSchemaOrganization] = useState<any>({})
  const [schemaManagers, setSchemaManagers] = useState<any>({})
  const [schemaAuditResponsiples, setSchemaAuditResponsiples] = useState<any>({})
  const [schemaCampaign, setSchemaCampaign] = useState<any>({})
  const [schemaCancelTitle, setSchemaCancelTitle] = useState<any>({})
  const [schemaDeliveryResponsiples, setSchemaDeliveryResponsiples] = useState<any>({})
  const [schemaManufactureResponsiples, setSchemaManufactureResponsiples] = useState<any>({})
  const [schemaMeasurementResponsiples, setSchemaMeasurementResponsiples] = useState<any>({})
  const [schemaMedium, setSchemaMedium] = useState<any>({})

  const [schemaOrderAttribute, setOrderAttribute] = useState<any>({})


  const [schemaMountingResponsiples, setSchemaMountingResponsiples] = useState<any>({})
  const [schemaOrderResponsiples, setSchemaOrderResponsiples] = useState<any>({})
  const [schemaSource, setSchemaSource] = useState<any>({})
  const [schemaSourceAppeal, setSchemaSourceAppeal] = useState<any>({})
  const [schemaStageCode, setSchemaStageCode] = useState<any>({})

  const [UISchemaSelectFromEnum] = useState({
    options: {width: 180, placeholder: 'Город', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnum2] = useState({
    options: {width: 180, placeholder: 'Организация', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnum3] = useState({
    options: {width: 180, placeholder: 'Менеджер', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumAuditResponsiples] = useState({
    options: {width: 180, placeholder: 'Отв. аудита', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumCampaign] = useState({
    options: {width: 180, placeholder: 'Кампания', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumCancelTitle] = useState({
    options: {width: 180, placeholder: 'Причина закрытия заказа', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumDeliveryResponsiples] = useState({
    options: {width: 180, placeholder: 'Отв. доставки', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumManufactureResponsiples] = useState({
    options: {width: 180, placeholder: 'Отв. производства', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumMeasurementResponsiples] = useState({
    options: {width: 180, placeholder: 'Отв. замера', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumMedium] = useState({
    options: {width: 180, placeholder: 'Канал', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumOrderAttribute] = useState({
    options: {width: 180, placeholder: 'Признак заказа', allowClear: true, showSearch: true,}
  })

  const [UISchemaSelectFromEnumMountingResponsiples] = useState({
    options: {width: 180, placeholder: 'Отв. монтажа', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumOrderResponsiples] = useState({
    options: {width: 180, placeholder: 'Отв. консультации', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumSource] = useState({
    options: {width: 180, placeholder: 'Источник', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumSourceAppeal] = useState({
    options: {width: 180, placeholder: 'Вид контакта', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumStageCode] = useState({
    options: {width: 180, placeholder: 'Стадия заказа', allowClear: true, showSearch: true,}
  })

  useEffect(() => {
    const getData = async () => {
      await fetchAPI('/api/order_report/enum')
        .then(result => {
          const enumItems = result || {}
          Object.keys(enumItems).forEach(code => {
            const enumValues = result?.[code].filter(Boolean) || {}
            const oneOf: Record<string, string>[] = []

            Object.keys(enumValues).forEach(enumValue => {
              return oneOf.push({
                'const': enumValues[enumValue],
                'title': enumValues[enumValue]
              })
            })

            if (code === 'city') {
              setEnumSchemaSelect(city, oneOf, enumValues)
              setSchemaCity(city)
            }
            if (code === 'organization-title') {
              setEnumSchemaSelect(organization, oneOf, enumValues)
              setSchemaOrganization(organization)
            }
            if (code === 'manager-name') {
              setEnumSchemaSelect(managers, oneOf, enumValues)
              setSchemaManagers(managers)
            }
            if (code === 'audit-responsiples') {
              setEnumSchemaSelect(auditResponsiples, oneOf, enumValues)
              setSchemaAuditResponsiples(auditResponsiples)
            }
            if (code === 'campaign') {
              setEnumSchemaSelect(campaign, oneOf, enumValues)
              setSchemaCampaign(campaign)
            }
            if (code === 'cancel-title') {
              setEnumSchemaSelect(cancelTitle, oneOf, enumValues)
              setSchemaCancelTitle(cancelTitle)
            }
            if (code === 'delivery-responsiples') {
              setEnumSchemaSelect(deliveryResponsiples, oneOf, enumValues)
              setSchemaDeliveryResponsiples(deliveryResponsiples)
            }
            if (code === 'manufacture-responsiples') {
              setEnumSchemaSelect(manufactureResponsiples, oneOf, enumValues)
              setSchemaManufactureResponsiples(manufactureResponsiples)
            }
            if (code === 'measurement-responsiples') {
              setEnumSchemaSelect(measurementResponsiples, oneOf, enumValues)
              setSchemaMeasurementResponsiples(measurementResponsiples)
            }
            if (code === 'medium') {
              setEnumSchemaSelect(medium, oneOf, enumValues)
              setSchemaMedium(medium)
            }
            if (code === 'order-attribute') {
              setEnumSchemaSelect(orderAttribute, oneOf, enumValues)
              setOrderAttribute(orderAttribute)
            }

            if (code === 'mounting-responsiples') {
              setEnumSchemaSelect(mountingResponsiples, oneOf, enumValues)
              setSchemaMountingResponsiples(mountingResponsiples)
            }
            if (code === 'order-responsiples') {
              setEnumSchemaSelect(orderResponsiples, oneOf, enumValues)
              setSchemaOrderResponsiples(orderResponsiples)
            }
            if (code === 'source') {
              setEnumSchemaSelect(source, oneOf, enumValues)
              setSchemaSource(source)
            }
            if (code === 'source-appeal') {
              setEnumSchemaSelect(sourceAppeal, oneOf, enumValues)
              setSchemaSourceAppeal(sourceAppeal)
            }
            if (code === 'stage-code') {
              setEnumSchemaSelect(stageCode, oneOf, enumValues)
              setSchemaStageCode(stageCode)
            }
          })
        })
    }
    getData()
  }, [])

  /*useEffect(() => {
    setFormData(data => ({ ...data, 'organization-id': 0 }))
    setUISchemaSelectFromEnum(({ options }) => ({ options: { ...options, disabled: true }  }))
    setUISchemaSelectFromEnum2(({ options }) => ({ options: { ...options, disabled: true }  }))
    setFormData(data => ({ ...data, 'organization-id': organizationId }))
    setUISchemaSelectFromOrganizations(({ options }) => ({ options: { ...options, disabled: true }  }))
  }, [sessionData])*/

  const onChangeLocal = useCallback((value, name) => {
    const nextformData = {}
    setFormData(prevFormData => Object.assign(nextformData, prevFormData, {[name]: value ? encodeURIComponent(value) : value}))
    onChange(nextformData)
  }, [onChange])

  const onChangeFilter = useCallback((value, code) => {
    onChangeLocal(value, `filter[${code}]`)
  }, [onChangeLocal])
  const onChangeCity = useCallback(value => {
    onChangeLocal(value, 'filter[city]')
  }, [onChangeLocal])
  const onChangeOrganization = useCallback(value => {
    onChangeLocal(value, 'filter[organization-title]')
  }, [onChangeLocal])
  const onChangeUsers = useCallback(value => {
    onChangeLocal(value, 'filter[manager-name]')
  }, [onChangeLocal])
  const onChangeAuditResponsiples = useCallback(value => {
    onChangeLocal(value, 'filter[audit-responsiples]')
  }, [onChangeLocal])
  const onChangeCampaign = useCallback(value => {
    onChangeLocal(value, 'filter[campaign]')
  }, [onChangeLocal])
  const onChangeCancelTitle = useCallback(value => {
    onChangeLocal(value, 'filter[cancel-title]')
  }, [onChangeLocal])
  const onChangeDeliveryResponsiples = useCallback(value => {
    onChangeLocal(value, 'filter[delivery-responsiples]')
  }, [onChangeLocal])
  const onChangeManufactureResponsiples = useCallback(value => {
    onChangeLocal(value, 'filter[manufacture-responsiples]')
  }, [onChangeLocal])
  const onChangeMeasurementResponsiples = useCallback(value => {
    onChangeLocal(value, 'filter[measurement-responsiples]')
  }, [onChangeLocal])
  const onChangeMedium = useCallback(value => {
    onChangeLocal(value, 'filter[medium]')
  }, [onChangeLocal])
  const onChangeOrderAttribute = useCallback(value => {
    onChangeLocal(value, 'filter[order-attribute]')
  }, [onChangeLocal])
  const onChangeMountingResponsiples = useCallback(value => {
    onChangeLocal(value, 'filter[mounting-responsiples]')
  }, [onChangeLocal])
  const onChangeOrderResponsiples = useCallback(value => {
    onChangeLocal(value, 'filter[order-responsiples]')
  }, [onChangeLocal])
  const onChangeSource = useCallback(value => {
    onChangeLocal(value, 'filter[source]')
  }, [onChangeLocal])
  const onChangeSourceAppeal = useCallback(value => {
    onChangeLocal(value, 'filter[source-appeal]')
  }, [onChangeLocal])
  const onChangeStageCode = useCallback(value => {
    onChangeLocal(value, 'filter[stage-code]')
  }, [onChangeLocal])

  return (
    <Space wrap>
      {/*<InputFilter onChange={onChangeFilter} placeholder="ID" code="id"/>*/}
      <SelectFromEnum
        value={formData['city']}
        schema={schemaCity}
        onChange={onChangeCity}
        UISchema={UISchemaSelectFromEnum}
      />
      <SelectFromEnum
        value={formData['organization-title']}
        schema={schemaOrganization}
        onChange={onChangeOrganization}
        UISchema={UISchemaSelectFromEnum2}
      />
      <SelectFromEnum
        value={formData['manager-name']}
        schema={schemaManagers}
        onChange={onChangeUsers}
        UISchema={UISchemaSelectFromEnum3}
      />
      <InputFilter onChange={onChangeFilter} placeholder="ID обращения" code="parent-id"/>
      <InputDateRange
        value={formData['case-date-fact']}
        onChange={onChangeFilter}
        code="case-date-fact"
        name="Дата создания обращения"
      />
      <InputFilter onChange={onChangeFilter} placeholder="ФИО Клиента" code="client-name"/>
      <InputFilter onChange={onChangeFilter} placeholder="Телефон клиента" code="client-phone"/>
      <SelectFromEnum
        value={formData['stage-code']}
        schema={schemaStageCode}
        onChange={onChangeStageCode}
        UISchema={UISchemaSelectFromEnumStageCode}
      />
      <SelectFromEnum
        value={formData['cancel-title']}
        schema={schemaCancelTitle}
        onChange={onChangeCancelTitle}
        UISchema={UISchemaSelectFromEnumCancelTitle}
      />
      <InputDateRange
        value={formData['order-date-fact']}
        onChange={onChangeFilter}
        code="order-date-fact"
        name="Дата консультации"
      />
     {/* <SelectFromEnum
        value={formData['order-responsiples']}
        schema={schemaOrderResponsiples}
        onChange={onChangeOrderResponsiples}
        UISchema={UISchemaSelectFromEnumOrderResponsiples}
      />*/}
      <InputDateRange
        value={formData['measurement-date-fact']}
        onChange={onChangeFilter}
        code="measurement-date-fact"
        name="Дата замера"
      />
      <SelectFromEnum
        value={formData['measurement-responsiples']}
        schema={schemaMeasurementResponsiples}
        onChange={onChangeMeasurementResponsiples}
        UISchema={UISchemaSelectFromEnumMeasurementResponsiples}
      />
      <InputDateRange
        value={formData['manufacture-date-fact']}
        onChange={onChangeFilter}
        code="manufacture-date-fact"
        name="Дата оплаты"
      />
      <SelectFromEnum
        value={formData['manufacture-responsiples']}
        schema={schemaManufactureResponsiples}
        onChange={onChangeManufactureResponsiples}
        UISchema={UISchemaSelectFromEnumManufactureResponsiples}
      />
      <InputDateRange
        value={formData['delivery-date-fact']}
        onChange={onChangeFilter}
        code="delivery-date-fact"
        name="Дата доставки"
      />
      <SelectFromEnum
        value={formData['delivery-responsiples']}
        schema={schemaDeliveryResponsiples}
        onChange={onChangeDeliveryResponsiples}
        UISchema={UISchemaSelectFromEnumDeliveryResponsiples}
      />
      <InputDateRange
        value={formData['mounting-date-fact']}
        onChange={onChangeFilter}
        code="mounting-date-fact"
        name="Дата монтажа"
      />
      <SelectFromEnum
        value={formData['mounting-date-fact']}
        schema={schemaMountingResponsiples}
        onChange={onChangeMountingResponsiples}
        UISchema={UISchemaSelectFromEnumMountingResponsiples}
      />
      <InputDateRange
        value={formData['audit-date-fact']}
        onChange={onChangeFilter}
        code="audit-date-fact"
        name="Дата аудита"
      />
      <SelectFromEnum
        value={formData['audit-responsiples']}
        schema={schemaAuditResponsiples}
        onChange={onChangeAuditResponsiples}
        UISchema={UISchemaSelectFromEnumAuditResponsiples}
      />
      <InputFilter onChange={onChangeFilter} placeholder="Номер производственного заказа"
                   code="production-order-number"/>
      <InputFilter onChange={onChangeFilter} placeholder="Сумма заказа" code="contract-sum"/>
      <InputFilter onChange={onChangeFilter} placeholder="Стоимость изделий" code="products-price"/>
      <InputFilter onChange={onChangeFilter} placeholder="Монтаж" code="mounting"/>
      <InputFilter onChange={onChangeFilter} placeholder="Доп. услуги" code="demounting"/>
      <InputFilter onChange={onChangeFilter} placeholder="Вывоз мусора" code="delivery"/>
      <InputFilter onChange={onChangeFilter} placeholder="Агентское вознаграждение" code="garbage-remove"/>
      <InputFilter onChange={onChangeFilter} placeholder="Габариты (м2)" code="square"/>
      <SelectFromEnum
        value={formData['source-appeal']}
        schema={schemaSourceAppeal}
        onChange={onChangeSourceAppeal}
        UISchema={UISchemaSelectFromEnumSourceAppeal}
      />
      <SelectFromEnum
        value={formData['campaign']}
        schema={schemaCampaign}
        onChange={onChangeCampaign}
        UISchema={UISchemaSelectFromEnumCampaign}
      />
      <InputFilter onChange={onChangeFilter} placeholder="Яндекс Метрика ID" code="yaClientId"/>
      <SelectFromEnum
        value={formData['source']}
        schema={schemaSource}
        onChange={onChangeSource}
        UISchema={UISchemaSelectFromEnumSource}
      />
      <SelectFromEnum
        value={formData['medium']}
        schema={schemaMedium}
        onChange={onChangeMedium}
        UISchema={UISchemaSelectFromEnumMedium}
      />
      <InputFilter onChange={onChangeFilter} placeholder="Содержание объявления" code="content"/>
      <InputFilter onChange={onChangeFilter} placeholder="Фраза|запрос" code="term"/>
      <SelectFromEnum
          value={formData['order-attribute']}
          schema={schemaOrderAttribute}
          onChange={onChangeOrderAttribute}
          UISchema={UISchemaSelectFromEnumOrderAttribute}
      />
    </Space>
  )
}

export default Filter