import React, { useCallback } from 'react'
import { Menu } from 'antd'
import { getDeleteSessionAction } from '../../store/session/action-creators'
import { DELETE_SESSION_DONE } from '../../store/session/actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const UserProfileMenu = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleLogoutClick = useCallback(async () => {
    const lastAction: any = await dispatch(getDeleteSessionAction())
    if (lastAction.type === DELETE_SESSION_DONE) {
      history.push('/')
      return
    }
  }, [dispatch, history])

  return (
    <Menu style={{ borderRight: 'none' }}>
      <Menu.Item key='profile/'>
        <span>Настройки профиля</span>
      </Menu.Item>
      <Menu.Item key='profile-card/'>
        <span>Карточка участника</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="/logout/" onClick={handleLogoutClick}><span>Выход</span></Menu.Item>
    </Menu>
  )
}
