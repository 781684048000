import styled, { css } from 'styled-components'

export const GroupCreateWrapper = styled.div`
  display: flex;

  position: relative;

  padding-left: 20px;
`

export const ChildrenWrapper = styled.div`
  display: inline-flex;
  align-self: center;

  svg {
    height: 24px;
    width: 24px;

    margin-right: 16px;
  }
`

export const ButtonWrapper = styled.div<{ show: boolean }>`
  position: absolute;

  top: 0;
  right: -10px;

  opacity: 0;
  
  transition: opacity 0.3s;

  cursor: pointer;

  z-index: 1000;

  ${({ show }) => show && css`
    opacity: 1;
  `}  

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const IconWrapper = styled.div`
  background: white;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.3);

  padding: 0;

  width: 26px;
  height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;
`
