import React from 'react'

export const ScanIcon = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 125" x="0px" y="0px">
        <title>scan</title>
        <path
          d="M10.66,36.1a2.86,2.86,0,0,1-2.85-2.85V10.66a2.86,2.86,0,0,1,5.71,0V33.25A2.86,2.86,0,0,1,10.66,36.1Z" />
        <path d="M33.25,13.52H10.66a2.86,2.86,0,0,1,0-5.71H33.25a2.86,2.86,0,0,1,0,5.71Z" />
        <path
          d="M10.66,92.19a2.86,2.86,0,0,1-2.85-2.85V66.75a2.86,2.86,0,0,1,5.71,0V89.34A2.86,2.86,0,0,1,10.66,92.19Z" />
        <path d="M33.25,92.19H10.66a2.86,2.86,0,0,1,0-5.71H33.25a2.86,2.86,0,0,1,0,5.71Z" />
        <path
          d="M89.34,36.1a2.86,2.86,0,0,1-2.86-2.85V10.66a2.86,2.86,0,0,1,5.71,0V33.25A2.86,2.86,0,0,1,89.34,36.1Z" />
        <path d="M89.34,13.52H66.75a2.86,2.86,0,0,1,0-5.71H89.34a2.86,2.86,0,0,1,0,5.71Z" />
        <path
          d="M89.34,92.19a2.86,2.86,0,0,1-2.86-2.85V66.75a2.86,2.86,0,0,1,5.71,0V89.34A2.86,2.86,0,0,1,89.34,92.19Z" />
        <path d="M89.34,92.19H66.75a2.86,2.86,0,0,1,0-5.71H89.34a2.86,2.86,0,0,1,0,5.71Z" />
        <path d="M92.15,52.85H7.85a2.85,2.85,0,1,1,0-5.7h84.3a2.85,2.85,0,0,1,0,5.7Z" />
      </svg>
    </div>
  )
}
