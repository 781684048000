import fetchAPI from '../../../lib/utils/fetch-api'
import {
  setActiveAvitoChatId,
  setChatAvitoData,
  setChatAvitoFailed,
  setChatAvitoLoading,
} from './actions'
import { IAvitoChat } from '../../pages/card-hr/new/ui/hr-chats/types'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

export function getChatAvito(id: string | number, card: 'case' | 'hr') {
  return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    await dispatch(setChatAvitoLoading())
    try {
      const response = await fetchAPI(`/api/avito/chat/${card}/${id}`) || []
      if (response['errors'] || response?.error) {
        let error = response['errors'] || response?.error
        if (Array.isArray(error)) {
          error =  error.join()
        }
        return dispatch(setChatAvitoFailed(error))
      }

      if (response?.length) {
        await dispatch(setActiveAvitoChatId(response[0].chat_id))
      }

      return dispatch(setChatAvitoData(response as IAvitoChat[]))
    } catch (err) {
      return dispatch(setChatAvitoFailed(err as string))
    }
  }
}
