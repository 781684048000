import React, {useState, useRef, useEffect, useCallback, forwardRef} from 'react'
import { Form, Alert, Skeleton, Modal, Checkbox  } from 'antd'
import { MapContent, MapWrapper } from './styles'
import { DefaultJsonForm } from 'src/react-app/components/default-json-form'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import fetchAPI from 'src/lib/utils/fetch-api'

const selectedSchema = {
  type: 'object',
  required: ['date-measurement', 'time-measurement', 'responsible'],
  properties: {
    'responsible': {
      type: 'pointer',
      view: {
        name: 'select-from-users'
      },
      title: 'Ответственный за замер',
    },
    'date-measurement': {
      type: 'string',
      format: 'date',
      title: 'Дата замера',
    },
    'date-plan': {
      type: 'string',
      format: 'date',
      title: 'Плановая дата замера',
    },
    'time-measurement': {
      type: 'string',
      format: 'time-range',
      title: 'Время замера',
    },
    'measurement-scan': {
      type: 'string',
      format: 'file-s3',
      title: 'Скан замера',
    },
    'comment': {
      type: 'string',
      title: 'Комментарий',
    },
  },
}


function isFormDataRequired(formData, schema) {
  const { required } = schema || {}
  const result = Array.from(required).every((value: any) => {
    if (value in formData === false) {
      return false
    }
    if (formData[value] === undefined) {
      return false
    }
    if (formData[value] === null) {
      return false
    }
    if (formData[value] === '') {
      return false
    }
    return true
  })
  return result
}

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)
const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  targetId => targetId 
)


function AdminNewObjectPage({
  visible,
  onClose,
  options = {},
  onOk,
  onChange
}: {
  visible: boolean
  onClose
  onOk
  options?: any
  onChange
}, ref) {
  const formDataRef = useRef<any>({})
  const isRequiredContentRef = useRef<boolean>(false)
  const [isCustomer, setIsCustomer] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageSuccess] = useState<string[]>([])
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [UISchema, setUISchema] = useState<any>({})
  const { sessionData } = useSelector(createSession)
  const { targetId } = useSelector(createAppealsCurrentStep)

  const getData = useCallback(async (targetId) => {
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, null)
    const UISchema = {
      responsible: {
        css: {
          gridColumn: '1 / span 24',
          gridRow: '1',
        },
        options: {
          roleId: 10,
          organizationId: organizationId,
        },
      },
      'date-measurement': {
        css: {
          gridColumn: '1 / span 11',
          gridRow: '2',
        },
        options: {
          isDisabledDatePrev: true,
        },
      },
      'date-plan': {
        css: {
          gridColumn: '12 / span 13',
          gridRow: '2',
        },
        options: {
          disabled: true,
          isDisabledDatePrev: true,
        },
      },
      'time-measurement': {
        css: {
          gridColumn: '1 / span 24',
          gridRow: '3',
        },
        options: {
          isDisabledTimePrev: true,
        },
      },
      'measurement-scan': {
        options: {
          type: 'string',
          format: 'file-s3',
          title: 'Скан замера',
        },
        css: {
          gridColumn: '1 / span 24',
          gridRow: '3',
        },
      },
      comment: {
        options: {
          type: 'textarea',
          minRows: 3,
          maxRows: 3,
        },
        css: {
          gridColumn: '1 / span 24',
          gridRow: '4',
        },
      }
    }

    const response = await fetchAPI(`/api/order-forecast/${targetId}`)
    const first = getDataOfType(response, 'data', Array, []).filter(item => item['code'] === 'measurement').shift()
    formDataRef.current['date-plan'] = getDataOfType(first, 'date', String, undefined)
    formDataRef.current['checked'] = true
    setUISchema(UISchema)
    setPageErrors([])
    setMatchedJSONSchema( () => {
      const nextItem = structuredClone(selectedSchema)
      delete nextItem['properties']['measurement-scan']
      Object.assign(nextItem, {required: nextItem?.['required'].filter(item => item !== 'measurement-scan')})
      return nextItem
    })
    setIsDataLoading(false)
  }, [sessionData])

  useEffect(() => {
    targetId && getData(targetId)
  }, [getData, sessionData, targetId])

  const [isChecked, setChecked] = useState(true)
  const [disableTelegram, setDisableTelegram] = useState(false)
  const [isTime, setTime] = useState(true)

  const onFormData = async ({ schema, formData }) => {
    isRequiredContentRef.current = isFormDataRequired(formData, schema)
    formDataRef.current = formData
    const measurementUser = formData?.['responsible']?.[0]
    if (Number.isInteger(measurementUser) && formData?.['responsible']?.length === 1){
      const isSkip = await fetchAPI(`/api/users/${measurementUser}`)
        .then(item => item?.['data']?.roles?.some?.(element => element.id === 30))

      if (isSkip) {
        // onChange({isCustomer:true})
        setIsCustomer(true)
        setDisableTelegram(true)
        setChecked(false)
        setTime(false)
      }else{
        setDisableTelegram(false)
        // onChange({isCustomer:false})
        setIsCustomer(false)
      }
    }else{
      setIsCustomer(false)
      // onChange({isCustomer:false})
    }
    if (formData?.['responsible']?.length === 0) {
      setChecked(true)
      setTime(true)
      setDisableTelegram(false)
    }
  }

  /**
   * Изменяет состояние формы для замерщика Заказчик
   */
  useEffect(() => {
    if( isCustomer ) {
      formDataRef.current['checked'] = false
      formDataRef.current['time-measurement'] = '00:00-23:59'

      setUISchema(UISchema => {
        // if(UISchema?.['measurement-scan'])
          UISchema['measurement-scan'].required = true
        return UISchema
      })

      setMatchedJSONSchema(() => {
        const nextItem = structuredClone(selectedSchema)
        delete nextItem['properties']['time-measurement']
        nextItem.required.push('measurement-scan')// Добавляет красную рамку для поля Скан замера
        return nextItem
      })

    }else{
      formDataRef.current['checked'] = true
      formDataRef.current['time-measurement'] = undefined

      setUISchema(UISchema => {
        if(UISchema?.['time-measurement']?.required)
          UISchema['time-measurement'].required = true
        return UISchema
      })

      setMatchedJSONSchema( () => {
        const nextItem = structuredClone(selectedSchema)
        delete nextItem['properties']['measurement-scan']
        // Object.assign(nextItem, {required: nextItem?.['required'].filter(item => item !== 'time-measurement')})
        return nextItem
      })
    }

  },[isCustomer, setUISchema])

  const onChecked = ({ target }) => {
    const { checked } = target 
    setChecked(checked)
    onFormData({ schema: matchedJSONSchema, formData: { ...formDataRef.current, checked } })
  }

  const onFinish = useCallback( async values => {
    const address = ref.current?.['address-object'] || ''
    if (address.length < 5) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Адрес объекта не заполнен',
      })
      return
    }
    if (!isRequiredContentRef.current) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Заполните все обязательные поля',
      })
      return
    }
    onOk(formDataRef.current)
  }, [onOk, ref])

  const onFinishFailed = errorInfo => {
    console.error('AdminNewObjectPage: form failed', errorInfo)
  }

  const onSubmit = useCallback(() => form.submit(), [form])

  return (
    <Modal
      title={`Назначение замера - заказ №${targetId}`}
      visible={visible}
      onOk={onSubmit}
      onCancel={onClose}
      className="modal-create-task"
    >
      <MapWrapper>
        <MapContent>
          <span style={{ marginBottom: 15 }}>
            {`Адрес объекта: ${ref.current?.['address-object']}`}
          </span>
          <Form
            layout="horizontal"
            form={form}
            fields={[
              {
                name: 'schema',
                value: 'cancel',
              },
            ]}
            scrollToFirstError={true}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ schema: 'cancel' }}
          >
            {pageErrors.length > 0 ? (
              <div style={{ marginBottom: 40 }}>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </div>
            ) : null}
            {pageSuccess.length > 0 ? (
              <div style={{ marginBottom: 40 }}>
                <Alert
                  message="Операция успешно выполнена:"
                  showIcon
                  type="success"
                  description={pageSuccess.join('. ')}
                />
              </div>
            ) : null}
            {isDataLoading === false ? (
              <Form.Item>
                {matchedJSONSchema && (
                  <DefaultJsonForm
                    formData={formDataRef.current}
                    schema={matchedJSONSchema}
                    onChange={onFormData}
                    UISchema={UISchema}
                  />
                )}
              </Form.Item>
            ) : (
              <Skeleton active />
            )}
          </Form>
          <Checkbox style={{ fontWeight: 'bold' }} checked={isChecked} disabled={disableTelegram} onChange={onChecked}>Отправить уведомление в телеграмм</Checkbox>
        </MapContent>
      </MapWrapper>
    </Modal>
  )
}

export default forwardRef(AdminNewObjectPage)