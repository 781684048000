import React from 'react'

export const ChangeDateIcon = (props) => {
  return (
    <div>
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 125" xmlSpace="preserve">
        <path
          d="M52.2,90.2l1.6,0.9c1.1,0.6,1.5,2,0.8,3.1c-0.4,0.7-1.2,1.1-2,1.1c-0.4,0-0.8-0.1-1.1-0.3l-7-4.1c-1-0.6-1.7-1.7-1.7-2.9  c0-1.2,0.6-2.3,1.7-2.9l7-4.1c1.1-0.6,2.5-0.3,3.1,0.8c0.6,1.1,0.3,2.5-0.8,3.1l-1.1,0.7C70.7,84,84.9,68.8,84.9,50.3  c0-1.3,1-2.3,2.3-2.3s2.3,1,2.3,2.3C89.5,71.4,73,88.8,52.2,90.2z M44,15.7c-1.1,0.6-1.5,2-0.8,3.1c0.4,0.7,1.2,1.1,2,1.1  c0.4,0,0.8-0.1,1.1-0.3l7-4.1c1-0.6,1.7-1.7,1.7-2.9c0-1.2-0.7-2.3-1.7-2.9l-7-4.1c-1.1-0.6-2.5-0.3-3.1,0.8C42.5,7.6,42.9,9,44,9.6  l1.6,0.9c-20.2,2-36.1,19.1-36.1,39.8c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3c0-18.1,13.6-33,31.1-35.2L44,15.7z M49.8,55h-2.6  c-0.7,0-1.3,0.6-1.3,1.3V59c0,0.7,0.6,1.3,1.3,1.3h2.6c0.4,0,0.7-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9v-2.6c0-0.4-0.1-0.7-0.4-0.9  C50.5,55.2,50.2,55,49.8,55z M40.6,55h-2.6c-0.7,0-1.3,0.6-1.3,1.3V59c0,0.7,0.6,1.3,1.3,1.3h2.6c0.4,0,0.7-0.1,0.9-0.4  c0.2-0.2,0.4-0.6,0.4-0.9v-2.6c0-0.4-0.1-0.7-0.4-0.9C41.3,55.2,40.9,55,40.6,55z M59.1,45.8h-2.6c-0.7,0-1.3,0.6-1.3,1.3v2.6v0  c0,0.7,0.6,1.3,1.3,1.3h2.6c0.4,0,0.7-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9v-2.6c0-0.4-0.1-0.7-0.4-0.9  C59.8,45.9,59.4,45.8,59.1,45.8z M49.8,45.8h-2.6c-0.7,0-1.3,0.6-1.3,1.3v2.6v0c0,0.7,0.6,1.3,1.3,1.3h2.6c0.4,0,0.7-0.1,0.9-0.4  c0.2-0.2,0.4-0.6,0.4-0.9v-2.6c0-0.4-0.1-0.7-0.4-0.9C50.5,45.9,50.2,45.8,49.8,45.8z M40.6,45.8h-2.6c-0.7,0-1.3,0.6-1.3,1.3v2.6v0  c0,0.7,0.6,1.3,1.3,1.3h2.6c0.4,0,0.7-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9v-2.6c0-0.4-0.1-0.7-0.4-0.9  C41.3,45.9,40.9,45.8,40.6,45.8z M66.3,70.9H30.8c-2.5,0-4.5-2-4.5-4.5V36.5c0-2.5,2-4.5,4.5-4.5h4.2v-0.7c0-2.5,2-4.5,4.5-4.5  c2.5,0,4.5,2,4.5,4.5v0.7H53v-0.7c0-2.5,2-4.5,4.5-4.5c2.5,0,4.5,2,4.5,4.5v0.7h4.2c2.5,0,4.5,2,4.5,4.5v29.9  C70.8,68.9,68.8,70.9,66.3,70.9z M30.8,66.4h35.5l0-25H30.8V66.4z" />
      </svg>
    </div>
  )
}
