import { useCallback } from 'react'
import { isFormDataRequired } from '../../../../../lib/utils/collections'
import { Modal } from 'antd'
import { nextStage, updateFormData } from '../../../../store/montage/action-creators'
import { useDispatch } from 'react-redux'

export function useSaveResult() {
  const dispatch = useDispatch()
  const saveResult = useCallback(async (id, visibleFormData, stage, is_next_stage, is_done) => {
    if (!visibleFormData) {
      return
    }
    const formData = {
      montage: { ...visibleFormData.resultObject },
      is_next_stage,
      is_done,
    }

    if ((id === 'new') && !is_next_stage) {
      const isRequiredFields = isFormDataRequired(formData.montage, visibleFormData.resultSchema)
      if (!isRequiredFields) {
        return
      }
    }

    if (is_next_stage) {
      if (!is_done && !formData.montage?.to_stage) {
        const isRequiredFields = isFormDataRequired(formData.montage, visibleFormData.resultSchema)
        if (!isRequiredFields) {
          Modal.warning({
            title: 'Предупреждение',
            content: 'Заполните все обязательные поля'
          })
          return
        }
      }
      dispatch(nextStage(id, formData))
    } else {
      dispatch(updateFormData(id, formData))
    }
  }, []
  )
  return { saveResult }
}
