import React, { memo, useCallback, useMemo } from 'react'
import { Button, Dropdown, Layout, Menu, message } from 'antd'
import { Breadcrumbs } from './breadcrumbs'
import { TopMenu } from './top-menu'
import { MainMenu } from './main-menu'
import { MenuComponent } from './menuV2'
import { BarChartOutlined } from '@ant-design/icons'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { formDataToUrlParams } from '../../pages/list-orders/utils'
import fetchAPI from '../../../lib/utils/fetch-api'
import {ButtonScrollTop} from "../../ui/ButtonScrollTop";

interface TB2BLayoutProps {
  layoutRef?: any,
  children: any,
  breadcrumbs?: Array<IBreadcrumb>,
  filterRef?: any,
  isViewScrollButton?: boolean
}

export interface IBreadcrumb {
  href?: string,
  title: string,
}

const createSession = createSelector(
    (state: any) => state.session,
    sessionData => sessionData
)

function downloadUrl(url, exportName) {
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', url)
  downloadAnchorNode.setAttribute('download', exportName)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

const { Content } = Layout

const B2BLayout = ({ children, breadcrumbs = [], layoutRef, filterRef, isViewScrollButton }: TB2BLayoutProps) => {
  const { sessionData } = useSelector(createSession)

  const isShowGoogle = useMemo(() => {
    return getDataOfType(sessionData, 'user.id', Number, null) === 7
  }, [sessionData])

  const onPrintExcelV2 = useCallback(async () => {
    const urlParams = formDataToUrlParams(filterRef?.current) + '&pageSize=9999999'
    const file = await fetchAPI(`/api/web-hooks/reports-orders/start-gen-excel-file?${urlParams}`)
    const id = file?.data?.id
    if (!Boolean(id)) {
      return
    }

    while(true) {
      await new Promise((res) => setTimeout(res, 2000))
      const statusData = await fetchAPI(`/api/web-hooks/reports-orders/status?id=${id}`)
      const content = `Идет построение отчета ${statusData?.data?.currentLength} из ${statusData?.data?.length}`
      message.loading({ content, key: id, duration: 60  })
      if (statusData?.data?.status !== 'work') {
        message.success({ content: 'Отчет сформирован!', key: id, duration: 2 })
        break
      }
    }
    const url = `/api/web-hooks/reports-orders/get-file-by-id?id=${id}`
    downloadUrl(url, 'report.xlsx')
  }, [filterRef?.current])

  const onOpenUrl = useCallback(async () => {
    const docId = '1a670M0tl5xYT3SdXP0jtk4LR0cOrbv1BulCgnT0YAnk'
    const url = `https://docs.google.com/spreadsheets/d/${docId}`
    window.open(url)
  }, [])

  const onClickItem = useCallback(async ({ key }) => {
    fetch('/api/web-hooks/test-paymen-order-price-reset/0?cmd=profiles')
    message.success('Идет обновление Google таблицы...')
  }, [])

  return (
    <div ref={layoutRef}>
      {isViewScrollButton && <ButtonScrollTop />}
      <Layout style={{ minHeight: '100vh' }}>
        {/*<MainMenu />*/}
        <MenuComponent />
        <Layout className="site-layout" style={{ backgroundColor: '#f6f8fa', padding: '0 24px 24px' }}>
          <TopMenu />
          <div style={{display: 'flex', gap: '20px', width: '450px', position: 'relative', zIndex: 999}}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            {filterRef && (
              <div style={{marginTop: '-5px', display: 'flex', gap: '10px'}}>
                <Button onClick={onPrintExcelV2}>
                  <BarChartOutlined/>
                  Печать
                </Button>
                {isShowGoogle && <Dropdown.Button onClick={onOpenUrl} overlay={
                  <Menu onClick={onClickItem}>
                    <Menu.Item key="table">Выгрузить в Google таблицу</Menu.Item>
                  </Menu>
                }>Google таблица</Dropdown.Button>}
              </div>)
            }
          </div>
          <Content style={{
            padding: 0,
            margin: 0,
            minHeight: 280,
          }}>
            <div style={{ padding: 24, minHeight: 360, backgroundColor: '#f6f8fa' }}>
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}

export default memo(B2BLayout)

