export interface IUseChunkDataloadProps {
  dataloadCallback: (page: number, limit: number) => void
  total: number
  dataCount: number
  zeroOutPage?: boolean
  forceUpdate?: boolean
  node: HTMLDivElement | null
}

export type TUseChunkDataload = (
  props: IUseChunkDataloadProps,
  ) => {
  page: number
}

export enum SortTypes {
  STRING = 'string',
  NUMBER = 'number',
}

export enum SortDir {
  DESCEND = 'descend',
  ASCEND = 'ascend',
}

export type TColumnSort = (key: string, field: string, sortType: SortTypes) => (a: any, b: any, dir: SortDir) => number
