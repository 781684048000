import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Alert, Pagination, Space, Table} from 'antd'
import {getDataOfType} from 'src/lib/utils/get-data-of-type'
import B2BLayout from 'src/react-app/layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import {afterDataColumns} from 'src/react-app/components/list-operations/consts'
import {getStagesGroup} from 'src/react-app/pages/list-orders/utils'
import {
  calcTotalWidth,
  fetchSchema,
  formatListData,
  getColumnsOfSchema,
  listColumnsHidden,
  tableRowClick
} from 'src/lib/utils/list'
import {PaginationWrapper, TableWrapper} from './styles'
import OrderFilter from 'src/react-app/components/orders-filter'
import {useSelector} from 'react-redux'
import {createSelector} from 'reselect'
import './index.css'
import dayjs from 'dayjs'
import EditCard from '../../components/list-operations/edit-card'
import StageCell from '../../components/list-operations/stage-cell'
import { CountBallInline } from '../../components/activity-timeline/styles'

function formatListId(data) {
  const parentId = Number(data['parent-id'])
  let result = data['id']

  if (parentId) {
    result = parentId
  }

  return result
}

function getFontWeightId(data) {
  const isStageNew = getDataOfType(data, 'stage', Number, null) === 0
  return isStageNew ? 'bold' : ''
}

const beforeDataColumns = [{
    title: 'ID',
    width: 70,
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    render: (_, data) => {
      return (
        <EditCard
          fontWeight={getFontWeightId(data)}
          id={formatListId(data)}
          schema={data?.schema}
          title="Редактирование"
        >{formatListId(data)}</EditCard>
      )
    },
  },
  {
    title: 'Стадия',
    width: 290,
    key: 'stage',
    render: (_, data) => {
      const { stage, stages } = data
      return (<StageCell stage={stage} stages={stages}/>)
    }
}]

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)
const createViewed = createSelector(
  (state: any) => state.viewed,
  counts => counts
)
function formatBeforeDataColumns(items) {
  items.map(item => {
    if( item.key === 'stage' )
      return Object.assign( item, {width: 200})
  })
  return items
}

function formatCurrency(val){
  return (val || 0).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB', currencyDisplay: 'symbol'}).replace("₽", "")
}

const name = 'case-order'

export default function AdminObjectsListPage() {
  const history = useHistory()
  const location = useLocation()
  const orderFiltersRef = useRef<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  const [columns, setColumns] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [, setDataColumns] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [page, setPage] = useState<number>(1)
  const { counts } = useSelector(createViewed)
  const breadcrumbs = useMemo(() => (
    [{
      href: '/orders',
      title: 'Продажи',
    }, {
      href: location.pathname,
      title: 'Заказы',
    }]
  ), [location.pathname])
  const { sessionData } = useSelector(createSession)
  const addDataColumns = useMemo(() => {
    const addDataColumns: any[] = [
      {
        title: 'Менеджер',
        width: 200,
        dataIndex: 'case-manager',
        key: 'case-manager',
        sorter: true,
        sortOrder: orderFiltersRef?.current?.field === 'case-manager' && orderFiltersRef?.current?.order
      },
      {
        title: 'Дата обращения',
        width: 200,
        dataIndex: 'reqCreatedAt',
        key: 'req-created-at',
        sorter: true,
        sortOrder: orderFiltersRef.current.field === 'reqCreatedAt' && orderFiltersRef.current.order,
        render: (key) => key && dayjs(key).format('DD.MM.YYYY H:mm')
      },
      {
        title: 'В работе менеджера с',
        width: 200,
        dataIndex: 'sales-manager-work-time',
        key: 'sales-manager-work-time',
        sorter: true,
        sortOrder: orderFiltersRef.current.field === 'sales-manager-work-time' && orderFiltersRef.current.order,
        render: (key) => key && key
      },
      {
        title: 'Дата обновления',
        dataIndex: 'updatedAt',
        key: 'updated-at',
        sorter: true,
        render: (text, { updatedAt }) => (
          <span>{dayjs(updatedAt).fromNow()}</span>
        ),
        width: 100,
      },
      {
        title: 'Дата задачи',
        width: 100,
        dataIndex: 'date-task',
        key: 'date-task',
        sorter: true,
        sortOrder: orderFiltersRef.current.field === 'date-task' && orderFiltersRef.current.order,
      },
      {
        title: 'Контактное лицо клиента',
        width: 200,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Телефон клиента',
        width: 200,
        dataIndex: 'telephone',
        key: 'telephone',
        sorter: true,
      },
      {
        title: 'Вид контакта',
        width: 200,
        dataIndex: 'source-appeal',
        key: 'source-appeal',
        sorter: true,
      },
      {
        title: 'Признак заказа',
        width: 200,
        dataIndex: 'order-attribute',
        key: 'order-attribute',
        sorter: true,
        sortOrder: orderFiltersRef.current.field === 'order-attribute' && orderFiltersRef.current.order,
      },
      {
        title: 'Адрес объекта',
        width: 200,
        dataIndex: 'address-object',
        key: 'address-object',
        render: (key) => key?.name || key || ''
      },
      {
        title: 'Сумма договора',
        width: 200,
        dataIndex: 'contract-sum',
        key: 'contract-sum',
        sorter: true,
        render: val => formatCurrency(val)
      },
      {
        title: 'Дата замера',
        width: 100,
        dataIndex: 'date-measurement',
        key: 'date-measurement',
        sorter: true,
        sortOrder: orderFiltersRef.current.field === 'date-measurement' && orderFiltersRef.current.order,
      },
      {
        title: 'Дата оплаты',
        width: 100,
        dataIndex: 'date-prepayment',
        key: 'date-prepayment',
        sorter: true,
        sortOrder: orderFiltersRef.current.field === 'date-prepayment' && orderFiltersRef.current.order,
      },
      {
        title: 'Дата доставки',
        width: 100,
        dataIndex: 'date-shipping',
        key: 'date-shipping',
        sorter: true,
        sortOrder: orderFiltersRef.current.field === 'date-shipping' && orderFiltersRef.current.order,
      },
      {
        title: 'Дата монтажа',
        width: 100,
        dataIndex: 'date-mounting',
        key: 'date-mounting',
        sorter: true,
        sortOrder: orderFiltersRef.current.field === 'date-mounting' && orderFiltersRef.current.order,
      },
      {
        title: 'Предоплата',
        width: 200,
        dataIndex: 'contract-prepayment',
        key: 'contract-prepayment',
        render: val => formatCurrency(val)
      },
      {
        title: 'Задолженность',
        width: 200,
        dataIndex: 'contract-indebtedness',
        key: 'contract-indebtedness',
        render: (val, row) => (<div style={ ( Number(val) > 0 && row?.['date-prepayment']) ? {fontWeight: 'bold', color: 'red'} : {}}>{formatCurrency(val)}</div>)
      },
      {
        title: 'Номер производственного заказа',
        width: 200,
        dataIndex: 'production-order-number',
        key: 'production-order-number',
      },
      {
        title: 'Причина отмены',
        width: 200,
        dataIndex: 'workflow-id',
        key: 'workflow-id',
        sorter: true,
      },
    ]

    const chatColumns = {
      title: 'Чаты',
      width: 60,
      dataIndex: 'unread_messages',
      key: 'unread_messages',
      sorter: true,
      sortOrder: orderFiltersRef.current.field === 'chat_count' ? orderFiltersRef.current.order : null,
      render: (_, data) => {
        const { unread_messages } = data
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {unread_messages ? (<CountBallInline title={'unread_messages'} bgColor={'countColor'}>{(unread_messages > 99 ? 99 : unread_messages )}</CountBallInline>) : '-'}
          </div>
        )
      }
    }

    if (getDataOfType(sessionData, 'organization.id', Number, null) === 1) {
      return [{ ...chatColumns },{
        title: 'Организация',
        width: 200,
        dataIndex: 'organizationTitle',
        key: 'organizationTitle',
        sorter: true,
        sortOrder: orderFiltersRef.current.field === 'organizationTitle' && orderFiltersRef.current.order,
      }].concat(addDataColumns)
    }
    return addDataColumns.concat(chatColumns)
  }, [sessionData, orderFiltersRef.current])

  const totalWidth: any = useMemo(() => calcTotalWidth(formatBeforeDataColumns(beforeDataColumns), addDataColumns, afterDataColumns.filter( item => item.key !== 'updated-at')), [addDataColumns])
  const cbTableRowClick = useCallback(({ id }) => history.push(`/order/${id}/edit`), [history])

  const deleteRecord = useCallback(async (currentId) => {
    try{
      await fetchAPI(`/api/collections/objects/${currentId}`, {method: 'DELETE'})
      setDataSource(dataSource => dataSource.filter(({ id }) => id !== currentId))
    }catch(error){
      console.error(error)
    }
  }, [])

  const getData = useCallback(async (value, addUrlParams: object = {}) => {
    let urlParams = new URLSearchParams({...value, ...addUrlParams}).toString()

    history.push({
      search: urlParams
    })
    const pageErrors: string[] = []
    const [selectedSchema, metadata, collections, stages] = await Promise.all([
      fetchSchema({ name }),
      fetchAPI(`/api/collections/${name}/metadata`),
      fetchAPI(`/api/collections-case/${name}?access_key=axioma&${urlParams}`),
      getStagesGroup('order')
    ])
    selectedSchema.properties = {
      ...selectedSchema.properties,
      ['date-measurement']: {
        type: 'string',
        format: 'date'
      },
      ['date-prepayment']: {
        type: 'string',
        format: 'date'
      },
      ['date-shipping']: {
        type: 'string',
        format: 'date'
      },
      ['date-mounting']: {
        type: 'string',
        format: 'date'
      },
      ['date-task']: {
        type: 'string',
        format: 'date'
      }
    }
    const columnsHidden = getDataOfType(metadata, 'data.columnsHidden', Array, [])
    const workflowsResponse = await fetchAPI('/api/collections/objects-grouped/reason-for-cancellation?access_key=axioma')
    const workflows = getDataOfType(workflowsResponse, 'data.data', Array, [])
    const collectionsResultData = getDataOfType(collections, 'data.data', Array, [])
    function getWorkflowName(item){
      const result = workflows.find(wf => +wf.id === +item?.['workflow-id'])
      return result?.name
    }
    const ordersIds = collectionsResultData.map(item => item['id']).join(',')
    const nextStagesData = await fetchAPI(`/api/web-hooks/get-order-next-stages?ids=${ordersIds}`)
    const nextStages = getDataOfType(nextStagesData, 'data', Array, [])
    const fieldsOrderNamesData = await fetchAPI(`/api/web-hooks/get-fields-order-names?ids=${ordersIds}`)
    const fieldsOrderNames = getDataOfType(fieldsOrderNamesData, 'data', Array, [])
    const isMetadata = getDataOfType(metadata, 'data', Object, null)
    if(isMetadata === null) {
      pageErrors.push('Некорректный ответ сервера при получении метаданных коллекции')
    }
    const dataSource: any[] = formatListData(collectionsResultData, selectedSchema)
    const newDataSource = dataSource.map((item, index) => ({
      ...item,
      ...nextStages[index],
      ...fieldsOrderNames[index],
      deleteRecord,
      stages,
      'workflow-id': getWorkflowName(item),
    }))
    const preparedDataColumns: any[] = getColumnsOfSchema(selectedSchema);
    const datalistColumnsHidden = listColumnsHidden(preparedDataColumns, columnsHidden)
    const newDataColumns = datalistColumnsHidden.map(column => ({
        ...column,
        fixed: 'left',
        width: 120,
        }
    ))
    const totalRows = getDataOfType(collections, 'data.total-rows', Number, 0)
    setTotalRows(totalRows)
    setDataColumns(newDataColumns)
    setDataSource(newDataSource)
    setPageErrors(pageErrors)
    setIsDataLoading(false)
  }, [deleteRecord])

  const onOrderFilter = useCallback(async value => {
    orderFiltersRef.current = JSON.parse(JSON.stringify(value))
    setPage(1)
    setIsDataLoading(true)
    await getData(orderFiltersRef.current, {})
    setIsDataLoading(false)
  }, [getData])

  const onChangeSort = useCallback(async (pagination, filters, sorter) => {
    setPage(1)
    setIsDataLoading(true)

    if(sorter.order && typeof sorter.order !== 'undefined'){
      orderFiltersRef.current.field = sorter.field;
      orderFiltersRef.current.order = sorter.order;
    }else{
      delete orderFiltersRef.current.field;
      delete orderFiltersRef.current.order;
    }

    orderFiltersRef.current.page = page;
    orderFiltersRef.current.pageSize = 10;

    addDataColumns.forEach( col => {
      if( col.key === orderFiltersRef.current.field || col.dataIndex === orderFiltersRef.current.field){
        col.sortOrder = orderFiltersRef.current.order
      }else{
        delete col.sortOrder
      }
    })
    //@ts-ignore
    const columns = beforeDataColumns.concat(addDataColumns, afterDataColumns.filter( item => item.key !== 'updated-at'))

    setColumns( columns )
    await getData(orderFiltersRef.current, {})
    setIsDataLoading(false)
  }, [getData, orderFiltersRef.current])

  const onPagination = useCallback(async (page, pageSize) => {
    setPage(page)
    setIsDataLoading(true)

    const params = page === 1 ? {} : {
      page: page,
      pageSize: pageSize,
    }

    await getData(orderFiltersRef.current, params)
    setIsDataLoading(false)

  }, [getData])

  useEffect(() => {
    const urlParams = Object.fromEntries(new URLSearchParams(location.search))

    if(urlParams.page) {
      setPage(parseInt(urlParams.page))
    }

    orderFiltersRef.current = Object.fromEntries(Object.entries(urlParams).filter(([key]) => key !== 'page' && key !== 'pageSize'))

    addDataColumns.forEach( col => {
      if( col.key === orderFiltersRef.current.field || col.dataIndex === orderFiltersRef.current.field){
        col.sortOrder = orderFiltersRef.current.order
      }
    })
    //@ts-ignore
    const columns = beforeDataColumns.concat(addDataColumns, afterDataColumns.filter( item => item.key !== 'updated-at'))
    setColumns( columns )

    getData(orderFiltersRef.current, urlParams)
      .catch (e => {
        console.error('error', {e})
      })
  }, [counts, getData])

  const rowClassName = useCallback(record => {
    return [0,1,3,7].includes(record['stage']) ? 'table-row-dark' :  'table-row-light'
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs} filterRef={orderFiltersRef}>
      <Space align="center">
        <OrderFilter onChange={onOrderFilter} />
      </Space>
      {pageErrors.length > 0 ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
          />
        </div>
      ) : null}
      <TableWrapper>
        <Table
          showSorterTooltip={{overlay: "Нажмите для сортировки", placement: 'bottom'}}
          columns={columns}
          rowClassName={rowClassName}
          size="small"
          dataSource={dataSource}
          scroll={{ x: totalWidth, y: '72vh' }}
          pagination={false}
          loading={isDataLoading}
          onChange={onChangeSort}
          onHeaderRow={(column: any) => ({
            style: {
              fontSize: column.key && column.key.substring(column.key.length - 3) === '-at' ? 9 : 13,
            },
          })}
          onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick) })}
        />
      </TableWrapper>
      {Boolean(totalRows) && <PaginationWrapper>
        <Pagination defaultCurrent={1} current={page} pageSize={10} total={totalRows} onChange={onPagination} showSizeChanger={false} />
      </PaginationWrapper>}
    </B2BLayout>
  )
}