import styled from 'styled-components'

export const TableWrapper = styled.table`
  border: 1px solid black;
`

export const ThWrapper = styled.th`
  border: 1px solid black;
  padding: 10px;
`

export const TdWrapper = styled.td`
  border: 1px solid black;
  padding: 10px;
`

export const TrWrapper = styled.tr`
   
`