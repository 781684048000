export enum SorterDirections {
  DESC = 'desc',
  ASC = 'asc',
}

export enum SorterDirectionsFull {
  DESCEND = 'descend',
  ASCEND = 'ascend',
}

export interface IUseSorterProps {
  onSort: (key: string, direction: SorterDirections) => void
}

export type TUseSorter = (props: IUseSorterProps) => {
  onColumnSort: TColumnSort
}

export enum TColumnSortResult {
  HIGHER = 1,
  BELOW = -1,
  SAME = 0,
}

export type TColumnSort = (
  key: string,
) => (
  a: any,
  b: any,
  direction?: SorterDirectionsFull
) =>
  TColumnSortResult
