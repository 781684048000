export const MENU_LOADING = 'MENU_LOADING'
export const MENU_DATA = 'MENU_DATA'
export const MENU_FAILED = 'MENU_FAILED'
export const ROLES_LOADING = 'ROLES_LOADING'
export const ROLES_DATA = 'ROLES_DATA'
export const ROLES_FAILED = 'ROLES_FAILED'


export function setMenuLoading() {
  return async dispatch => {
    dispatch({ type: MENU_LOADING })
  }
}

export function setMenuData(data) {
  return async dispatch => {
    dispatch({ type: MENU_DATA, payload: data })
  }
}

export function setMenuFailed(error: string) {
  return async dispatch => {
    dispatch({ type: MENU_FAILED, payload: error })
  }
}

export function setRolesLoading() {
  return { type: ROLES_LOADING }
}

export function setRolesData(data: any) {
  return { type: ROLES_DATA, payload: data }
}

export function setRolesFailed(error: string) {
  return { type: ROLES_FAILED, payload: error }
}
