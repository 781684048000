import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import React from 'react'
import { DataType } from './interface'
import { ButtonActionWrapper } from '../../../components/list-operations/styles'
import { EditTwoTone, PlusOutlined } from '@ant-design/icons'
import { Value } from '../../elements'
import { useHistory } from 'react-router-dom'
import { Sorter } from '../../../interfaces'
import { Button, Checkbox } from 'antd'
import styled from 'styled-components'

const CheckboxWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 67px;
`

const Columns: ColumnsType<DataType> = [
  {
    title: 'Активно',
    dataIndex: 'is_active',
    key: 'is_active',
    width: 70,
    fixed: 'left',
    render: (value, row) => {
      return (
        <CheckboxWrapper
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <Checkbox
            defaultChecked={value}
            checked={value}
            disabled={row.active_disabled}
            onClick={e => {
              row.setOrganizationState(row.id, !value)
            }}
          />
        </CheckboxWrapper>
      )
    }
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    fixed: 'left',
    sorter: true
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (val, record) => {
      return (
        <>
          <ExpandButton id={record.id} value={val}/>
        </>
      )
    }
  },
  {
    title: 'Бренд',
    dataIndex: 'brand',
    key: 'brand',
    sorter: true,
    render: val => <Value data={val} />
  },
  {
    title: 'Тип',
    width: 120,
    dataIndex: 'type',
    key: 'type',
    sorter: true,
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 180,
    sorter: true,
    render: key => dayjs(key).format('DD.MM.YYYY HH:mm')
  },
]

const ColumnAction: ColumnsType<DataType> = [
  {
    title: 'Действие',
    key: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    align: 'center',
    render: (_, { id, deleteRecord }) => {
      return (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <EditButton id={ id }/>
        </span>
      )
    }
  }
]

export function EditButton({ id }) {
  const history = useHistory()
  return (
    <ButtonActionWrapper title="Редакитровать">
      <EditTwoTone onClick={() => { history.push(`/new/organization/${id}`) }}/>
    </ButtonActionWrapper>
  )
}

export function ExpandButton({ value, id }) {
  const history = useHistory()
  return (
    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
      <a
        className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
        onClick={() => {
          history.push(`/offices/${id}`)
        }}
      />
      <div>{value}</div>
    </div>
  )
}

export const getColumns = (leader: boolean, sorter: Sorter) => {
  // собирает колонки для разных ролей
  const columns = leader ? Columns.concat(ColumnAction) : Columns
  // Формирует колонки и устанавливает порядок сортировки
  return columns.map(item => {
    if (item.key === sorter.sort_field) {
      item.sortOrder = sorter.sort_order
    } else {
      delete item.sortOrder
    }
    return item
  })
}
