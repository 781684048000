import styled from "styled-components";

export const ItemTitle = styled.span`
  font-size: 17px;
  font-weight: 400;
  white-space: break-spaces;
`

export const FileUploaderWrapper = styled.div`
  margin-bottom: 10px;
`

export const InputWrapper = styled.div`
  .am-list-item.am-input-item {
    border: 1px solid #d9d9d9;
    border-radius: 3px;

    padding-left: 0;
    
    min-height: 34px;
    height: 34px;
}

  .am-list-line {
    padding-left: 10px;
  }
  
  input::placeholder {
    font-size: 14px;
    color: #d9d9d9;
  }
`

export const DatepickerWrapper = styled.div`
  .am-list-item {
    padding-left: 10px;
  }
  .am-list-line {
    padding-right: 0;
  }
  .am-list-item .am-list-line .am-list-content,
  .am-list-item .am-list-line .am-list-extra {
    font-size: 14px;
  }
`

export const DatetimeWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const TextareaWrapper = styled.div`
  .am-list-item.am-textarea-item {
    border: 1px solid #d9d9d9 !important;
    border-radius: 4px;
  }
`
