export const schema = {
  type: 'object',
  properties: {
    payment_date: {
      title: 'Дата оплаты',
      type: 'string',
      format: 'date',
      options: {
        isDisabledDatePrev: false,
        picker: 'month',
        format: 'MM.YYYY',
      }
    },
    resource_id: {
      type: 'string',
      title: 'Ресурс',
      view: {
        name: 'select-from-enum'
      },
      options: {}
    },
    payment_plan: {
      type: 'number',
      title: 'План оплат'
    },
    payment_fact: {
      type: 'number',
      title: 'Факт оплат'
    }
  }
}