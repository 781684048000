import styled, { StyledComponent } from 'styled-components'

interface TableButtonWrapperProps {
  disabled?: boolean
}
export const TableButtonWrapper: StyledComponent<'div', any, TableButtonWrapperProps> = styled.div<TableButtonWrapperProps>`
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 10px;
    font-size: 20px;
    border: none;
    background: none;
    cursor: pointer;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
    transition: all 0.5s ease 0s;
  }
`
