import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Input, Button, Modal, Popconfirm } from 'antd'
import { RibonWrapper } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import fetchAPI from '../../../lib/utils/fetch-api'
import { ButtonActionWrapper } from '../../pages/list-users/styles'
import { reloadActionsTimeLine } from '../../store/appeals/actions'
import { fileUpload } from '../../connectors/file-s3-json-form/utils-files-s3'

const createCase = createSelector(
  (state: any) => state.appeals,
  targetId => targetId
)

function ModalAvitoResponse() {
  const { targetId } = useSelector(createCase)
  const inputRef = useRef<any>(null)
  const inputFile = useRef<any>(null)
  const dispatch = useDispatch()
  const [value, setValue] = useState<undefined|string>(undefined)
  const [isVisible, setVisible] = useState<boolean>(false)
  const [isDisabled, setDisabled] = useState<boolean>(false)
  const styleDisplay = { display: 'none' }

  useEffect(()=> {
    setTimeout(inputRef.current?.focus, 200)
  }, [isVisible])

  const submitModal = useCallback(async () => {
    await fetchAPI(`/api/avito/send/${targetId}`, {
      method: 'POST',
      body: JSON.stringify({ text: value })
    })
      .then( () => {
        setValue(undefined)
        setVisible(false)
        dispatch(reloadActionsTimeLine())
      })
      .catch(error => {
        console.error({ error })
      })
  }, [setValue, setVisible, targetId, value])

  const cancelModal = useCallback(() => {
    setValue(undefined)
    setVisible(false)
  },[setValue, setVisible])

  const changeHandler = useCallback(e => {
    setValue(e.target.value)
  }, [setValue])

  const openInsertFileModal = useCallback( () => {
    inputFile.current?.click()
  }, [inputFile])

  const localOnChange = useCallback(async ({ target }) => {
    if(!Boolean(target.files[0])){
      return
    }
    setDisabled(true)
    try {
      await fileUpload(target.files)
        .then(response => {
          const { url } = response
          if (url) {
            setValue(value => (value && value.length) ? `${value}\n${url}` : url)
            inputFile.current.value = ''
          } else {
            alert('Не удалось получить ссылку')
          }
        })
        .finally( () =>
          setDisabled(false)
        )
    } catch (e) {
      console.error({ e })
      setDisabled(false)
    }
  }, [inputFile, setDisabled, setValue])

  return (
    <>
      <Button onClick={() => setVisible(true)}>Ответить</Button>
      <Modal
        title="Ответить на сообщение Авито"
        visible={isVisible}
        closable={false}
        footer={[
          <ButtonActionWrapper>
            <input ref={inputFile} type="file" onChange={localOnChange} style={styleDisplay} />
            <Button key='file' onClick={openInsertFileModal} disabled={isDisabled}>
              Прикрепить файл
            </Button>
          </ButtonActionWrapper>,
          <ButtonActionWrapper title='Отмена'>
            {value && value.length
              ?
              (
                <Popconfirm title="Вы уверены? Введенный текст будет удален" onConfirm={cancelModal}>
                  <Button key='cancel'>Отмена</Button>
                </Popconfirm>
              )
              :
              <Button key='cancel' onClick={cancelModal}>Отмена</Button>
            }
          </ButtonActionWrapper>,
          <Button key="submit" onClick={submitModal} disabled={Boolean(!(value && value.length))}>
            Отправить
          </Button>,
        ]}
      >
        <RibonWrapper>
          <Input.TextArea
            placeholder="Введите сообщение"
            autoSize={{ minRows: 7, maxRows: 7 }}
            value={value}
            onChange={changeHandler}
            ref={inputRef}
            disabled={isDisabled}
          />
        </RibonWrapper>
      </Modal>
    </>
  )
}

export default ModalAvitoResponse
