import React, { memo, Suspense, useCallback, useMemo} from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { UploadFiles } from '../../../../../../components/v2/upload-files'
import { deleteTimeLineItem } from '../../../../../../store/timeline/actions'
import {
    ActivityTimelineContext,
    ActivityTimelineContextProps,
    ActivityTimeline,
    selectTimelineError,
    selectTimelineFiles,
    selectTimelineIsLoading
} from '../../../../../../components/v2/activity-timeline'

interface FilesTimelineProps {
  className?: string
  id: string | number
}

export const Files: React.FC<FilesTimelineProps> = memo((props: FilesTimelineProps) => {
  const {id, className} = props
  const dispatch = useDispatch()
  const timelineIsLoading = useSelector(selectTimelineIsLoading)
  const timelineError = useSelector(selectTimelineError)
  const timelineFiles = useSelector(selectTimelineFiles)
  const entityType = 'order'

  const deleteOrdersTimelineItem = useCallback((activityItem) => {
      dispatch(deleteTimeLineItem(activityItem))
  }, [])

  const defaultProps = useMemo(
    (): ActivityTimelineContextProps => ({
      card_id: id,
      card_type: entityType,
      deleteTimelineItem: deleteOrdersTimelineItem,
    }),
    [id],
  )

  return (
    <>
      <Suspense fallback={''}>
        <UploadFiles
          entityType={entityType}
          entityId={id}
          url={`/api/${entityType}/${id}/add-file`}
        />
      </Suspense>
      <br/>
      <Suspense fallback={''}>
        <ActivityTimelineContext.Provider value={defaultProps}>
          <ActivityTimeline
            isLoading={timelineIsLoading}
            items={timelineFiles}
            error={timelineError}
            className={className}
            emptyMessage={'Нет прикрепленных файлов'}
          />
        </ActivityTimelineContext.Provider>
      </Suspense>
    </>
  )
})
