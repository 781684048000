import React, { useState } from 'react'
import { Row, Col, Select } from 'antd'
import { connect } from 'react-redux'

function getPrefix(prefix, name) {
  return [].concat(prefix, name)
}

function getPath(prefix, name) {
  return [].concat(getPrefix(prefix, name), 'collection-name')
}

function getIsType(name, data) {
  try {
    const prop = data['properties'][name]
    if(prop.type === 'string' && prop.format === 'platform-collection'){
      return true
    }
    const isArray = prop.type === 'array'
    if(isArray){
      const prop = data['properties'][name]['items']
      if(prop.type === 'string' && prop.format === 'platform-collection'){
        return true
      }
    }
    return false
  } catch (error) {
    console.error(`getType() ${error}`)
  }
}

function getCurrentValue(data, name, prefix, collections) {
  try {
    const value = data['properties'][name]['collection-name']
    const isArray = data['properties'][name]['type'] === 'array'
    if(isArray){
      const prop = data['properties'][name]['items']
      if(prop.type === 'string' && prop.format === 'platform-collection'){
        const value = data['properties'][name]['items']['collection-name']
        return (collections.find(item => item['name'] === value ) || {title: name})['title']
      }
    }
    if (typeof value !== 'string') return
    return (collections.find(item => item['name'] === value ) || {title: name})['title']
  } catch (error) {
    console.error(`getCurrentValue() ${error}`)
  }
}

const CollectionSelect = ({ prefix, name, data, changeValueAction, collections }) => {
  const [value, setValue] = useState(getCurrentValue(data, name, prefix, collections))
  const onChange = (value, item) => {
    setValue(value)
    const path = getPath(prefix, name)
    const { name: collectionName = '' } =
    collections.find(({ id }) => id === Number(item.key)) || {}
    const isArray = data['properties'][name]['type'] === 'array'
    if(isArray){
      const prop = data['properties'][name]['items']
      if(prop.type === 'string' && prop.format === 'platform-collection'){
        const pathArray = path.slice()
        pathArray.splice(-1, 0, 'items');
        changeValueAction({ key: pathArray, value: collectionName })
        return
      }
    }
    changeValueAction({ key: path, value: collectionName })
  }
  if (!getIsType(name, data)) {
    return null
  }

  return (
    <Row type="flex" justify="end" align="middle"
      style={{
        marginTop: 15,
        marginBottom: 6,
        marginLeft: 42,
        minWidth: 372
      }}
    >
      <Col
        span={24}
        className="col-item col-item-type"
        style={{ minWidth: '100%' }}
      >
        <Select className="type-select-style" onChange={onChange} value={value}>
          {collections.map( item => (
            <Select.Option value={item.title} key={item.id}>
              {item.title}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  )
}

export default connect((state) => ({
  collections: state.setObjects?.collections,
}))(CollectionSelect)
