import { JSONSchema7, JSONSchema7Definition } from 'json-schema'

export enum TRANSITIONS_TYPES {
  ADDITIONAL = 'additional'
}
export interface IActionsListProps {
  id: number
  stageList: Record<string, any>[]
  parentId: number
  formData: Record<string, any>
  matchedJSONSchema: JSONSchema7 | null
  onStageToWork: (parentId: number, stage: string, stageCodeNext: string) => void
  onMeasurementLeft: (parentId: number, stage: string, stageCodeNext: string) => void
  onMeasurerArrived: (parentId: number, stage: string, stageCodeNext: string) => void
  onMeasurementReady: (parentId: number, stage: string, stageCodeNext: string) => void
  onContractSigned: (parentId: number, stage: string, stageCodeNext: string) => void
  onDone: (parentId: number, stage: string, stageCodeNext: string) => void
  onTransition: (parentId: number, stage: string, stageCodeNext: string) => void
  sessionData: any
  getValidatorsData?: (validator: string) => JSONSchema7Definition | null
  openSubMenu: (
    validators: JSONSchema7Definition[],
    nextStage: string,
    nextStageIndex: number,
    isAdditional?: boolean,
    ) => void
  onClose?: () => void
}

export interface IActionsListItemProps {
  stage: number
  stageList: Record<string, any>[]
  type: string
  getTransition: (item: Record<string, any>, props: Record<string, any>) => void
  checkTransitionRequired: (prevReq: string[], nextReq: string[]) => boolean
  sessionData: any
  getValidatorsData?: (validator: string) => JSONSchema7Definition | null
  openSubMenu: (
    validators: JSONSchema7Definition[],
    nextStage: string,
    nextStageIndex: number,
    isAdditional?: boolean,
    ) => void
  onClose?: () => void
}

export interface IMeasurementCardProps {
  data: Record<string, any>
}
