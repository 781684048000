import { format } from 'date-fns'
import ru from 'date-fns/locale/ru'

export const dateFNS = {
  code: 'ru',
  localize: ru,
  format: (date, dateFormat = 'dd.MM.yyyy') => format(date, dateFormat, { locale: ru }),
  options: {
    // Index of the first day of the week.
    // Sunday is 0, Monday is 1, Saturday is 6.
    weekStartsOn: 1,

    // Nth of January which is always in the first week of the year. See:
    // https://en.wikipedia.org/wiki/Week#Week_numbering
    // http://www.pjh2.de/datetime/weeknumber/wnd.php?l=en
    firstWeekContainsDate: 1,
  },
}

export const parseDate = (date: string, format = 'dd.MM.yyyy HH:mm') => {
  let result = date

  try {
    result = date ? dateFNS.format(new Date(date), format) : ''
  } catch (e) {
    console.warn(e)
  }

  return result
}
