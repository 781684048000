import moment, {Moment} from "moment";
import {IDataByDay} from "../selectors";
import {getSlotByTime} from "./getSlotByTime";
import {TimeTableSlots} from "../consts/timeTableSlots";

enum mapType {
    expired = 'error',
    new = 'success'
}

enum mapContent {
    'error' = 'просрочено',
    'success' = 'запланировано'
}
export const getPreviewTaskItemDataByTime = (value: Moment, dataByDay: IDataByDay) => {
    let currentDate = value.format('YYYY.MM.DD');

    if (!dataByDay[currentDate]) {
        return []
    }

    let keys = Object.keys(TimeTableSlots);
    let countsByTime = dataByDay[currentDate]
        ?.filter((val) => val.stage !== 'done')
        ?.reduce((acc, val) => {
                let key = moment(val.deadline).format('H');
                let slot = getSlotByTime(keys, key);
                return {
                    ...acc,
                    [slot]: {
                        ...(acc?.[slot] || {}),
                        [val.stage]: (acc?.[slot]?.[val.stage] || 0) + 1
                    }
                }
            }, {}
        )

    keys.forEach((key) => {
        if (countsByTime[key]) {
            const types = Object.keys(countsByTime[key]);
            let result: { type: string, content: string }[] = []
            types.forEach((type) =>{
                result.push({
                    type: mapType[type],
                    content: `${countsByTime[key][type]} ${mapContent[mapType[type] || '']}`
                })
            })

            countsByTime[key] = result
        }
    });
    return countsByTime;
};
