export const CALENDAR_LOADING = 'CALENDAR_LOADING'
export const CALENDAR_DATA = 'CALENDAR_DATA'
export const CALENDAR_FAILED = 'CALENDAR_FAILED'
export const CALENDAR_FILTER = 'CALENDAR_FILTER'
export const CALENDAR_TYPES_DATA = 'CALENDAR_TYPES_DATA'
export const CALENDAR_TYPES_LOADING = 'CALENDAR_TYPES_LOADING'
export const CALENDAR_TYPES_FAILED = 'CALENDAR_TYPES_FAILED'

export function setCalendarDataLoading() {
    return async dispatch => {
        dispatch({type: CALENDAR_LOADING})
    }
}

export function setCalendarData(data) {
    return async dispatch => {
        dispatch({type: CALENDAR_DATA, payload: data})
    }
}

export function setCalendarFilter(data) {
    return async dispatch => {
        dispatch({type: CALENDAR_FILTER, payload: data})
    }
}

export function setCalendarTypes(data) {
    return async dispatch => {
        dispatch({type: CALENDAR_TYPES_DATA, payload: data})
    }
}

export function setCalendarTypesLoading(data) {
    return async dispatch => {
        dispatch({type: CALENDAR_TYPES_LOADING})
    }
}

export function setCalendarTypesFailed(error: string) {
    return async dispatch => {
        dispatch({type: CALENDAR_TYPES_FAILED, payload: error})
    }
}

export function setCalendarFailed(error: string) {
    return async dispatch => {
        dispatch({type: CALENDAR_FAILED, payload: error})
    }
}
