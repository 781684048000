import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react'
import { Spin } from 'antd'
import { IMultiFileS3 } from './interface'
import { ContentWrapper, FileLink, FileNoLink, FileLinkWrapper, FileButton } from './styles'
import { fileUpload, insetFileToCollection, getFileData } from './utils-files-s3'
import { DashOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import checkIsMobile from 'is-mobile'

const createCase = createSelector(
  (state: any) => state.appeals,
  targetId => targetId
)

const styleDisplay = { display: 'none' }

function MultiFileS3Component({
  value,
  onChange,
  schema,
  UISchema,
  onStartUpload = () => {},
}: IMultiFileS3) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const [multiple, setMultiple] = useState(true)
  const fileName = useMemo(() => schema?.title, [schema])
  const key = useMemo(() => schema?.key, [schema])
  const [isPreloader, setPreloader] = useState(true)
  const [files, setFiles] = useState<Record<string, any> | Record<string, any>[]>({})
  const { targetId } = useSelector(createCase)
  const [isMobile, setIsMobile] = useState(false)
  const required = useMemo(() => Boolean(UISchema?.required), [UISchema])
  const error = required && !value

  useEffect(() => {
    const mobile = checkIsMobile()
    setMultiple( UISchema?.options?.multiple ?? true )
    setIsMobile(mobile)
  }, [UISchema])

  const inputRef = useRef<any>(null)
  const localOnChange = useCallback(async ({ target }) => {
    if(!target.files[0]){
      return
    }

    onStartUpload(true)
    setPreloader(true)
    const response = await fileUpload(target.files)
    const url = typeof response?.url === 'string' ? response?.url : response?.urs
    const isArray = Array.isArray(url)
    let ids: number[] = []
    const fileList: Record<string, any>[] = []

    if (url && isArray) {
      const idRequest: Promise<number>[] = []

      const urls = url.map( (item, index) => {
        return {
          'name': target.files[index]['name'],
          size: target.files[index]['size'],
          type: target.files[index]['type'],
          url: item
        }
      })

      const fileData = {
        code: key,
        name: fileName || target.files[0]['name'],
        'name-field': target.files[0]['name'],
        urls,
        date: new Date().toISOString()
      }
      fileList.push(fileData)
      idRequest.push(insetFileToCollection(fileData, targetId))
      /*
      url.forEach((currentUrl:string, indexUrl) => {
        const fileData = {
          code: key,
          'name-field': target.files[indexUrl]['name'],
          name: fileName || target.files[indexUrl]['name'],
          size: target.files[indexUrl]['size'],
          type: target.files[indexUrl]['type'],
          url: url[indexUrl],
          date: new Date().toISOString()
        }
        fileList.push(fileData)
        idRequest.push(insetFileToCollection(fileData, targetId))
      })
      */

      ids = await Promise.all(idRequest)

      if (fileList.length > 0) {
        fileList.forEach((_, index) => {
          fileList[index]['id'] = ids[index]
        })
      }
    } else {
      const fileData = {
        code: key,
        'name-field': target.files[0]['name'],
        name: fileName || target.files[0]['name'],
        size: target.files[0]['size'],
        type: target.files[0]['type'],
        urls: [{
          'name': target.files[0]['name'],
          size: target.files[0]['size'],
          type: target.files[0]['type'],
          url
        }],
        date: new Date().toISOString()
      }

      const id = await insetFileToCollection(fileData, targetId)
      fileData['id'] = id
      fileList.push(fileData)

      ids.push(id)
    }

    if (ids.length > 0) {
      onChange(ids)
      setFiles(fileList)
      target.value = ''
      setPreloader(false)
    } else {
      onChange('')
      setFiles([])
      target.value = ''
      setPreloader(false)
    }
  }, [onStartUpload, onChange, key, fileName, targetId])

  useEffect(() => {
    const getFile = async () => {
      let result: Record<string, any> | Record<string, any>[] = []

      if (Array.isArray(value)) {
        const filesRequests: Promise<any>[] = []

        value.forEach(item => {
          const fileData = getFileData(item)
          if (fileData !== null) {
            filesRequests.push(fileData)
          }
        })

        if (filesRequests.length > 0) {
          result = await Promise.all(filesRequests)
        }
      } else {
        const fileData = await getFileData(value)

        result.push(fileData)
      }
      result && setFiles(result)
      setPreloader(false)
    }

    if (value) {
      getFile()
    } else {
      setPreloader(false)
    }
  },[value])

  const onButton = useCallback(() => {
    inputRef?.current?.click()
  }, [])

  const onDelete = useCallback(() => {
      setFiles({})
      onChange('')
  }, [onChange])

  const isFileArray = useMemo(() => Array.isArray(files), [files])

  const firstFile = useMemo(
    () => {
      return (isFileArray ? files[0] : files) || {}
    }, [files, isFileArray]
  )

  const viewFileName = useMemo(() => {
    const getName = (file: Record<string, any>) => {
      if( typeof file.url === 'object') {
        return file.url.join(', ')
      }
      if( file?.urls ){
        return file.urls.map( f => f.name ).join(', ')
      }
      if ('name-field' in file) {
        return file['name-field']
      }
      return file.name
    }
    return getName(firstFile)
  }, [firstFile])

  const getFileUrl = useCallback((url) => Array.isArray(url) ? url[0] : url, [])

  return firstFile && (
    <ContentWrapper disabled={disabled} error={error} className='antd-input antd-input-container'>
      <input ref={inputRef} type='file' onChange={localOnChange} style={styleDisplay} multiple={multiple} />
      <FileLinkWrapper isMobile={isMobile}>
        {firstFile.id ? (
          <>
            <FileLink href={getFileUrl(firstFile.url)}>{viewFileName}</FileLink>
            {disabled || <CloseCircleOutlined onClick={onDelete} />}
          </>
        ) : (
          <FileNoLink>Не выбран</FileNoLink>
        )}
      </FileLinkWrapper>
        <FileButton disabled={disabled || isPreloader} onClick={onButton} className='ant-input'>
          {isPreloader ? <Spin size='small' /> : <DashOutlined />}
        </FileButton>
    </ContentWrapper>
  )
}

export default React.memo(MultiFileS3Component, (prev, next) => {
  const isNewValue = prev.value === next.value
  const isNewUISchema = prev?.UISchema === next?.UISchema
  return isNewValue && isNewUISchema
})
