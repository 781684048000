import React, { useCallback, useMemo } from "react"
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { IRadioGroup } from './interface'
import { Radio } from 'antd'

function RadioGroup({ value, onChange, schema, UISchema } : IRadioGroup) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const handleChange = useCallback(({ target }) => {
    onChange(target.value)
  }, [onChange])

  const objects: any[] = useMemo(() => {
    const values = getDataOfType(schema, 'enum', Array, [])
    const oneOf = getDataOfType(schema, 'oneOf', Array, [])
    const oneOfObject = Object.fromEntries(oneOf.map(item => [item['const'], item['title']]))
    return values.map(value => ({
      'value': value,
      'title': getDataOfType(oneOfObject, value, [String, Number], value)
    }))
  }, [schema])

  return (
    <Radio.Group onChange={handleChange} value={value} disabled={disabled}>
      {objects.map(({ value, title }) => (
        <Radio value={value}>{title}</Radio>
      ))}
    </Radio.Group>
  )
}

export default RadioGroup