import React, { useState, useRef, useEffect, useCallback } from 'react'
import {  Modal } from 'antd'
import { DefaultJsonForm } from 'src/react-app/components/default-json-form'
import { BrowserRouter } from 'react-router-dom'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { Provider, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { store } from 'src/browser-app'
// import fetchAPI from 'src/lib/utils/fetch-api'
const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  targetId => targetId 
)

function isFormDataRequired(formData, schema) {
  const { required } = schema || {}
  const result = Array.from(required).every((value: any) => {
    if (value in formData === false) {
      return false
    }
    if (formData[value] === undefined) {
      return false
    }
    if (formData[value] === null) {
      return false
    }
    if (formData[value] === '') {
      return false
    }
    return true
  })
  return result
}



const selectedSchema = {
  type: 'object',
  required: ['date', 'payment'],
  properties: {
    'date': {
      type: 'string',
      format: 'date',
      title: 'Дата оплаты',
    },
    'payment': {
      type: 'number',
      title: 'Cумма оплаты',
    },
  },
}

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)



export function modalClose(event){
  let target = event.target
  while (Boolean(target?.tagName) === true) {
    if (target?.tagName.toLowerCase() === 'div') {
      break
    }
    target = target?.parentNode
  }
  try{
    Array.from(target?.children as any[])[0].click()
    return true
  }catch{
    return false
  }
}

export function modalAddMutualSettlements(options) {
  let formData = { }
  return new Promise(resolve => Modal.confirm({
    icon: null,
    title: 'Данные оплаты',
    /* @ts-ignore */
    content: <Provider store={store}><Content options={options} onChange={value => { formData = value }} /></Provider>,
    onCancel: () => resolve(null),
    okButtonProps: { 
      onClick: (event) => {
        const isRequired = isFormDataRequired(formData, selectedSchema)
        if(isRequired){
          resolve(formData)
          modalClose(event)
        }
      }
    }
  }))
}

function Content({ onChange, options }){
  const formDataRef =  useRef({ 'date': new Date().toISOString(), 'type-pay': true })

  const [UISchema, setUISchema] = useState<any>({})
  const { sessionData } = useSelector(createSession)
  const { targetId } = useSelector(createAppealsCurrentStep)
  const [sumPay, ] = useState<any>(0)
  const localOnChange = useCallback(({ formData }) => {
    formDataRef.current = Object.assign(formData, { sumPay })
    console.log({ formData, test: formDataRef.current  })


    onChange(formDataRef.current)
  }, [onChange, sumPay])
  useEffect(() => {
    //const organizationId = getDataOfType(sessionData, 'organization.id', Number, null)
    //console.log({ options })
    const UISchema = {
      'date': {
        css: {
          gridColumn: '1 / span 24',
          gridRow: '1',
        },
       options: {
         disabled: getDataOfType(options, 'disable', Boolean, false),
         isDisabledDatePrev: false,
        },
      },
      'payment': {
        css: {
          gridColumn: '1 / span 11',
          gridRow: '2',
        },
        options: {
          disabled: getDataOfType(options, 'disable', Boolean, false),
        },
      },  
      'type-pay': {
        css: {
          gridColumn: '1 / span 11',
          gridRow: '3',
        },
        options: {
          disabled: getDataOfType(options, 'disable', Boolean, false),
        },
      }, 
    }

    ;(async (targetId) => {
 
      //formDataRef.current = Object.assign({ 'payment': factSum }, formDataRef.current)
      onChange(formDataRef.current)
      setUISchema(UISchema)
    })(targetId)
  }, [sessionData, targetId, options, onChange])

  return (
    /* @ts-ignore */
    <BrowserRouter>
      <DefaultJsonForm
        formData={formDataRef.current}
        schema={selectedSchema}
        onChange={localOnChange}
        UISchema={UISchema}
      />
    </BrowserRouter>
  )
}