import React, { useCallback } from 'react'
import { Collapse, Button } from 'antd'
import {  DeleteTwoTone } from '@ant-design/icons'
import { GridWrapper, ProductWrapper, ImageWrapper, TitleWrapper } from './styles'

function createMarkup (body) {
  return { __html: body }
}

function Product({ body, onDelete, disabled }) {
  const onDelete2 = useCallback(() => onDelete(body), [body, onDelete])
  return (
    <ProductWrapper>
      <ImageWrapper dangerouslySetInnerHTML={createMarkup(body?.preview ?? '')} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 5,
          width: '100%',
          paddingRight: 9,
          alignItems: 'baseline',
        }}
      >
        <TitleWrapper>{`Изделие №${body?.number ?? ''}`} </TitleWrapper>
        {disabled || <Button icon={<DeleteTwoTone />} onClick={onDelete2} />}
      </div>
    </ProductWrapper>
  )
}

function ViewProducts ({ images, onChange, disabled }) {
  /*
  const addImage = useCallback(async () => {
    const result = await modalImage()
    if (result === null) {
      return
    }
    const file = await getFileData(result['image'])
    const nextImage = {
      number: result['number'],
      preview: `<img src="${file?.url}" />`
    }
    onChange(
      images.concat(nextImage)
        .sort((a, b) => a?.['number'] - b?.['number'])
    )
  }, [images, onChange])
  */
  const onDelete = useCallback(body => {
    const nextImages = images.filter(item => item !== body)
    onChange(nextImages)
  }, [images, onChange])

  return (
    <div style={{ marginBottom: 10 }}>
      {/*disabled || <Space style={{ marginBottom: 10 }}><Button onClick={addImage}>Добавить изображение</Button></Space>*/}
      <Collapse>
        <Collapse.Panel header="Показать изображения продукции" key="1">
          <GridWrapper>
            {images.map(body => (
              <Product body={body} onDelete={onDelete} disabled={disabled} />
            ))}
          </GridWrapper>
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

export default ViewProducts
