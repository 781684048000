import React, { useCallback, useEffect } from 'react';
import B2BLayout from '../../layouts/b2b';
import { MapContent, MapTitle, MapWrapper, TaskWrapper } from './styles';
import AddressForm from './address-form';

const breadcrumbs = [
  {
    href: '/',
    title: 'Контактный центр',
  },
];

export default function AdminNewObjectPage() {
  const getData = useCallback(async () => {

  }, []);

  useEffect(() => {
    getData().catch(e => {
      console.error({ e });
    });
  }, [getData]);

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>DaData</MapTitle>
        </TaskWrapper>
        <MapContent>
          <AddressForm />
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  );
}
