import fetchAPI from '../../../lib/utils/fetch-api'
import {
  setOrdersDetailData,
  setOrdersDetailFailed,
  setOrdersDetailLoading,
} from './actions'
import {IOrdersDetailData} from "./interfaces";


export function getOrderDetailData(entityType, entityId) {
  return async dispatch => {
    dispatch(setOrdersDetailLoading())
    try {
      const response = await fetchAPI(`/api/${entityType}/detail/${entityId}`) || {products: [], services: []}
      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setOrdersDetailFailed(error))
      }

      return dispatch(setOrdersDetailData(response as IOrdersDetailData))
    } catch (err) {
      console.error(err)
      return dispatch(setOrdersDetailFailed('При загрузки данных заказа возникла ошибка'))
    }
  }
}



