import utils from '../../utils'

const langs = {
  en_US: {
    'title': 'Title',
    'import_json': 'Import JSON',
    'base_setting': 'Base Setting',
    'all_setting': 'Source Code',
    'default': 'Default',
    'description':'Description',
    "adv_setting": "Advanced Settings",
    "add_child_node": "Add child node",
    'add_sibling_node': 'Add sibling nodes',
    'delete_node': 'Delete node',
    'add_node':'Add sibling/child nodes',
    'child_node': 'Child node',
    'sibling_node':'Sibling node',
    'ok':'OK',
    'cancel':'Cancel',
    'minLength':'Min length',
    'maxLength': 'Max length',
    'pattern':'MUST be a string and SHOULD be a valid regular expression.',
    'exclusiveMinimum': 'Value strictly less than',
    'exclusiveMaximum':  'Value strictly more than',
    'minimum': 'Min',
    'maximum': 'Max',
    'unique_items': 'Unique Items',
    'min_items':'MinItems',
    'max_items': 'MaxItems',
    'checked_all': 'Checked All',
    'valid_json': 'Not valid json',
    'enum': 'Enum',
    'enum_msg': 'One value per line',
    'enum_desc': 'desc',
    'enum_desc_msg': 'enum description',
    'required': 'required',
    'mock': 'mock',
    'mockLink': 'Help'
  },
  ru_RU: {
    'title': 'Заголовок',
    'import_json': 'Импорт JSON',
    'base_setting': 'Настройки',
    'all_setting': 'Исходный код',
    'default': 'По умолчанию',
    'description':'Описание',
    "adv_setting": "Конфигурация элемента",
    "add_child_node": "Добавить дочерний элемент",
    'add_sibling_node': 'Добавить соседний элемент',
    'delete_node': 'Удалить элемент',
    'add_node':'Добавить соседний/дочерний элемент',
    'child_node': 'Дочерний элемент',
    'sibling_node':'Соседний элемент',
    'ok':'OK',
    'cancel':'Отмена',
    'minLength':'Мин. длина',
    'maxLength': 'Макс. длина',
    'pattern':'ДОЛЖНО быть строкой и должно быть валидным регулярным выражением.',
    'exclusiveMinimum': 'Значение строго меньше, чем',
    'exclusiveMaximum':  'Значение строго больше, чем',
    'minimum': 'Мин',
    'maximum': 'Макс',
    'unique_items': 'Уникальные записи',
    'min_items':'MinItems',
    'max_items': 'MaxItems',
    'checked_all': 'Checked All',
    'valid_json': 'Не валидный json',
    'enum': 'Перечисление',
    'enum_msg': 'Однао значение в строке',
    'enum_desc': 'desc',
    'enum_desc_msg': 'enum description',
    'required': 'Обязательно к заполнению',
    'mock': 'mock',
    'mockLink': 'Справка'
  },
  zh_CN: {
    'title': '标题',
    'import_json': '导入 json',
    'base_setting': '基础设置',
    'all_setting': '编辑源码',
    'default': '默认值',
    'description':'备注',
    'adv_setting': '高级设置',
    "add_child_node": "添加子节点",
    'add_sibling_node': '添加兄弟节点',
    'delete_node': 'Delete node',
    'add_node':'添加兄弟/子节点',
    'child_node': '子节点',
    'sibling_node':'兄弟节点',
    'ok':'确定',
    'cancel':'取消',
    'minLength':'最小长度',
    'maxLength': '最大长度',
    'pattern': '用正则表达式约束字符串',
    'exclusiveMinimum': '开启后，数据必须大于最小值',
    'exclusiveMaximum': '开启后，数据必须小于最大值',
    'minimum': '最小值',
    'maximum': '最大值',
    'unique_items': '开启后，每个元素都不相同',
    'min_items':'最小元素个数',
    'max_items': '最大元素个数',
    'checked_all': '全选',
    'valid_json': '不是合法的json字符串',
    'enum': '枚举',
    'enum_msg': '每行只能写一个值',
    'enum_desc': '备注',
    'enum_desc_msg': '备注描述信息',
    'required': '是否必须',
    'mock': 'mock',
    'mockLink': '查看文档'
  }
}
// eslint-disable-next-line
export default (message) => {
// eslint-disable-next-line
  return langs[utils.lang][message]
}
