import React, { useRef, useCallback } from 'react'
import { Modal, Input } from 'antd'
 

export function modalOpen() {
  let formData = {   'label': ''}
  return new Promise(resolve => {
    Modal.confirm({
      icon: null,
      title: 'Добавление вида рекламы',
      content: <Content startFormData={formData} onChange={value => {
        formData = value
      }} schema={{}} />,
      onOk: () => new Promise((ok, err) => {
        const isRequired = formData?.['label']
        if(isRequired){
          resolve(formData)
          ok(null)
        }
        err()
      }),
      onCancel(){
        resolve(null)
      }
    })
  })
}

function Content({ onChange, schema, startFormData }){
  const formDataRef =  useRef(startFormData)

   
  const localOnChange = useCallback((data, key) => {
    Object.assign(formDataRef.current, { [key]: data })
    onChange(formDataRef.current)
  }, [onChange])

  return (
    <>
      <span>Укажите вид контакта</span>
      <Input defaultValue={formDataRef.current['label']} onChange={({ target }) => localOnChange(target.value, 'label')}/>
    </>
  )
}