import {Space} from "antd";
import cls from './header-with-text-template.module.css'
import React, {memo, ReactNode} from "react";
import {classNames} from "../../../../../lib/classNames/classNames";


interface HeaderWithTextTemplateProps {
    className?: string,
    title?: ReactNode,
    text?: ReactNode
}

const HeaderWithTextTemplate: React.FC<HeaderWithTextTemplateProps> = memo((props: HeaderWithTextTemplateProps) => {
    const {className, title, text} = props;
    return (
        <Space className={classNames(cls.HeaderWithTextTemplate, {}, [className])}>
            <span className={cls.title}>{title}</span>
            <span className={cls.text}>{text}</span>
        </Space>
    )
})
export default HeaderWithTextTemplate
