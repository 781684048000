import { useCallback } from 'react'
import { isFormDataRequired } from '../../../../../lib/utils/collections'
import { Modal } from 'antd'
import { toClaim, nextStage, updateFormData } from '../../../../store/order/action-creators'
import { useDispatch } from 'react-redux'

export function useSaveResult() {
  const dispatch = useDispatch()

  const createClaim = useCallback(async id => {
    dispatch(toClaim(id))
  }, [])

  const saveResult = useCallback(async (id, visibleFormData, _stage, isOpenModal, setIsOpenModal, isNextStage = false) => {
    if (!visibleFormData) {
      return
    }
    const formData = {
      order: {...visibleFormData.resultObject},
      is_next_stage: isNextStage,
    }
    if ((id === 'new') && !isNextStage) {
      const isRequiredFields = isFormDataRequired(formData.order, visibleFormData.resultSchema)
      if (!isRequiredFields) {
        return
      }
    }
    if (isNextStage) {
      const isRequiredFields = isFormDataRequired(formData.order, visibleFormData.resultSchema)
      if (!isRequiredFields) {
        Modal.warning({
          title: 'Предупреждение',
          content: 'Заполните все обязательные поля'
        })
        return
      }
      if (visibleFormData.modal) {
        if (!isOpenModal && !visibleFormData.modalValid) {
          setIsOpenModal(true)
          return
        }
        formData.order = { ...formData.order, ...visibleFormData.modalFieldsSchema }
      }
      dispatch(nextStage(id, formData))
    } else {
      dispatch(updateFormData(id, formData))
    }
  }, []
  )
  return { saveResult, createClaim }
}
