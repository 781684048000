import styled from 'styled-components'

export const UserProfile = styled.div`
  background: none;
  padding: 15px;
  user-select: none;
  cursor: pointer;

  .ant-layout-sider-collapsed & {
    display: none;
  }
`

export const MapWrapper = styled.div`
  padding: 0px 0px;
`

export const MapTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 12px;
`

export const MapContent = styled.div`
  background: white;
  .ant-tabs-card {
    display: none;
  }
  &>.ant-form-horizontal>.ant-form-item {
    margin-bottom: 0 !important;
  } 
`

export const TabContent = styled.div`
  padding: 0px;
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 16px;
`
