import React, { memo, useCallback, useMemo, useState } from 'react'
import { Alert, Modal, Spin } from 'antd'
import { DefaultJsonForm } from '../../../../../../components/v2/default-json-form'
import schema from './schema.json'
import { convertData2JsonForm } from '../../../../../../shared/lib/convertData2JsonForm'
import { isFormDataRequired } from '../../../../../../../lib/utils/collections'

interface IFormData {
  delivery_datetime?: string
}

interface MeasurementModalProps {
  id: number | string
  isModalOpen: boolean
  onOk?: ((formData: IFormData) => void) | undefined
  onCancel?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
}

export const DeliveryModal: React.FC<MeasurementModalProps> = memo((props: MeasurementModalProps) => {
  const { isModalOpen, onOk, onCancel } = props

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [formData, setFormData] = useState<IFormData>({
    delivery_datetime: undefined,
  })

  const handleOk = useCallback(() => {
    const isRequiredFields = isFormDataRequired(formData, actualSchema)
    if (!isRequiredFields) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Заполните все обязательные поля'
      })
      return
    }
    onOk?.(formData)
  }, [formData])

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    onCancel?.(e)
  }

  const onChange = useCallback(data => {
    setFormData(prevData => {
      return {
        ...prevData,
        ...data.formData,
      }
    })
  }, [setFormData])

  interface ISchemaActual {
    resultObject: {}
    resultSchema: {
      properties: {
        delivery_datetime: any
      }
      required: string[]
    }
  }

  const actualSchema = useMemo(() => {
    const schemaActual = convertData2JsonForm(schema.fields) as never as ISchemaActual

    return schemaActual.resultSchema || {}
  }, [schema, formData])

  if (!isModalOpen) {
    return null
  }

  return (
    <Modal
      title={schema.title}
      onOk={handleOk}
      onCancel={handleCancel}
      visible={isModalOpen}
      destroyOnClose={true}
      okButtonProps={{
        disabled: loading
      }}
    >
      {loading ? (
        <Spin />
      ) : (
        error
          ? (<Alert message={error} type='error' showIcon />)
          : (
            <DefaultJsonForm
              formData={formData}
              schema={actualSchema}
              onChange={onChange}
              orientation='horizontal'
            />
          )
      )}
    </Modal>
  )
})
