import React, { useCallback, useMemo } from 'react'
import { InputNumber } from 'antd'
import { IINTEGER } from './interface'

function InputInteger({ value, onChange, UISchema }: IINTEGER){
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const parser = useCallback(value => value.replace(/\D/g, ''), [])
  return <InputNumber value={value} onChange={onChange} parser={parser} disabled={disabled} />
}

export default React.memo(InputInteger, (prev, next) => {
  if(prev.value === next.value){
    return true
  }
  return prev?.UISchema === next?.UISchema;
})
