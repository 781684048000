import React, {memo, useEffect, useState} from "react";
import {UpOutlined} from '@ant-design/icons'
import {ButtonStyled} from "./style";
import {useThrottle} from "../../../hooks/useThrottle/useThrottle";


export const ButtonScrollTop = memo(() => {
    const [isShowButton, setIsShowButton] = useState(false);
    const setIsShow = (position: number) => {
        if (position > 300) {
            setIsShowButton(true);
        } else {
            setIsShowButton(false);
        }
    }

    const onScrollSave = useThrottle(setIsShow, 200);
    const scrollEvent = () => onScrollSave(window.scrollY)


    useEffect(() => {
        window.addEventListener("scroll", scrollEvent);
        return () => {
            window.removeEventListener("scroll", scrollEvent)
        }
    }, []);
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    if (!isShowButton) {
        return null
    }

    return (
        <ButtonStyled
            type={'primary'}
            onClick={scrollToTop}
        >
            <UpOutlined/>
        </ButtonStyled>
    )
})