import { IAvitoChat } from '../../pages/card-hr/new/ui/hr-chats/types'

export const CHAT_AVITO_LOADING = 'CHAT_AVITO_LOADING'
export const CHAT_AVITO_DATA = 'CHAT_AVITO_DATA'
export const CHAT_AVITO_FAILED = 'CHAT_AVITO_FAILED'
export const CHAT_AVITO_ADD_ITEM = 'CHAT_AVITO_ADD_ITEM'
export const SET_ACTIVE_AVITO_CHAT_ID = 'SET_ACTIVE_AVITO_CHAT_ID'


export function setChatAvitoLoading() {
    return async dispatch => {
        dispatch({type: CHAT_AVITO_LOADING})
    }
}

export function setChatAvitoData(chatAvitoData: IAvitoChat[]) {
    return async dispatch => {
        dispatch({type: CHAT_AVITO_DATA, payload: chatAvitoData})
    }
}

export function setChatAvitoFailed(error: string) {
    return async dispatch => {
        dispatch({type: CHAT_AVITO_FAILED, payload: error})
    }
}

export function addChatAvitoData(chatAvitoData: IAvitoChat) {
    return async dispatch => {
        dispatch({type: CHAT_AVITO_ADD_ITEM, payload: chatAvitoData})
    }
}

export function setActiveAvitoChatId(id: string) {
    return async dispatch => {
        dispatch({type: SET_ACTIVE_AVITO_CHAT_ID, payload: id})
    }
}
