import React, { useMemo, useState, useRef, useEffect, useCallback } from 'react'
import fetchAPI from 'src/lib/utils/fetch-api'
import { SuggestItem, SuggestList, SuggestWrapper } from './styles'
import { debounce } from 'lodash'

const DADATA_TOKEN = '202f07e5711c4f92e5167758fa392229901215ac'

type TToBound = null | 'country' | 'region' | 'area' | 'city' | 'settlement' | 'street' | 'house' | 'flat'

export function InputDadata({value, onChange, schema, UISchema}: any) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const [options, setOptions] = useState<any[]>([])
  const inputRef = useRef<any>()

  const [searchValue, setSearchValue] = useState(schema?.value || value || '')
  const [isShow, setIsShow] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const className = useMemo(() => {
    if (UISchema?.options?.required && !value) {
      return 'ant-input ant-input-status-error'
    }
    return 'ant-input'
  }, [value])

  const getOptions = async ({query}: {query: string}) => {
    if (!Boolean(query)) {
      return []
    }
    const to_bound: TToBound = UISchema?.options?.to_bound || null
    const params = {
      query: query,
      count: 20,
      to_bound: {
        value: to_bound
      },
      restrict_value: true,
      locations: {
        country_iso_code: 'RU',
      }
    }

    const res = await fetchAPI(
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
      {
        method: 'POST',
        mode: 'cors',
        credentials: undefined,
        headers: {
          Authorization: `Token ${DADATA_TOKEN}`,
        },
        body: JSON.stringify(params),
      },
    )
    return res.suggestions
  }

  const localOnChange = useCallback(async value => {
    setSearchValue(value.value)
    onChange({
      address: value.value,
      federal_district: value.data.federal_district,
      region: value.data.region_with_type,
      city: value.data.city || value.data.settlement_with_type,
    })
  }, [onChange])

  useEffect(debounce(() => {
    if (searchValue) {
      onChange({
        address: searchValue,
      })
    }
  }, 300), [])

  const rows = schema?.options?.rows || 2

  const onSearch = useCallback(async svalue => {
    setSearchValue(svalue)
    const suggestions = await getOptions({ query: svalue })
    setOptions(suggestions.filter(suggestion =>
      suggestion.data.city || suggestion.data.settlement
    ))
    setSelectedIndex(-1)
  }, [onChange])

  const handleFocus = useCallback(() => {
    setIsShow(true)
  }, [])

  const handleBlur = useCallback(() => {
    setTimeout(() => setIsShow(false), 300)
  }, [])

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'ArrowDown') {
      setSelectedIndex(prevIndex => Math.min(prevIndex + 1, options.length - 1))
    } else if (e.key === 'ArrowUp') {
      setSelectedIndex(prevIndex => Math.max(prevIndex - 1, 0))
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      e.preventDefault()
      localOnChange(options[selectedIndex])
      setIsShow(false)
    }
  }, [options, selectedIndex, localOnChange])

  useEffect(() => {
    if (selectedIndex >= 0 && selectedIndex < options.length) {
      setSearchValue(options[selectedIndex].value)
    }
  }, [selectedIndex, options])

  return (
    <SuggestWrapper>
      <textarea
        style={{ resize: 'none' }}
        onChange={({target}) => onSearch(target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        rows={rows}
        placeholder={'Введите населённый пункт'}
        value={searchValue}
        className={className}
        disabled={disabled}
      />
      {options.length > 0 && (
        <SuggestList isShow={isShow}>
          {options.map((suggest, index) => (
            <SuggestItem
              key={suggest.value}
              onClick={() => localOnChange(suggest) as any}
              style={{ backgroundColor: index === selectedIndex ? '#e6f7ff' : 'transparent' }}
            >
              {suggest.value}
            </SuggestItem>
          ))}
        </SuggestList>
      )}
    </SuggestWrapper>
  )
}

export default InputDadata
