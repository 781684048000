import styled from 'styled-components'

export const UserProfile = styled.div`
  background: none;
  padding: 15px;
  user-select: none;
  cursor: pointer;

  .ant-layout-sider-collapsed & {
    display: none;
  }
`

export const MapWrapper = styled.div`
  padding: 10px 0px;
`

export const MapTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 12px;
`

export const MapContent = styled.div`
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  background: white;

  padding: 36px;
`

export const TabContent = styled.div`
  padding: 10px
`

export const AlertWrapper = styled.div`
  margin-bottom: 40px;
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 20px;
  margin-bottom: 16px;
`
