import React, { useCallback, useEffect, useMemo, useState } from 'react'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { Table } from 'antd'
import { IDataRoles } from './index'

interface IRolesData {
  dataSource: undefined | IDataRoles[]
  onChange: any
  selected: number[]
  user_id: number
}

function TableRoles({ dataSource, onChange, selected, user_id }: IRolesData){
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(selected)
  const [loading, setLoading] = useState<boolean>(false)

  const columns = useMemo(() => [{
      title: 'Роль',
      width: 290,
      key: 'name',
      dataIndex: 'name'
    }
  ], [])

  const onSelect = useCallback(async (row, state) => {
    const role_id = row.id
    setLoading(true)
    await fetchAPI(`/api/employee/set_role`, {
      method: 'POST',
      body: JSON.stringify({ role_id, user_id, active: state })
    })
      .then(() => {
        setSelectedRowKeys(keys => keys.includes(role_id) ? keys.filter(key => key !== role_id) : keys.concat(role_id))
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [onChange, user_id, selectedRowKeys])

  useEffect(() => {
    setSelectedRowKeys(selected)
  },[selected])

  const rowSelection = useMemo(() => ({
    selectedRowKeys,
    onSelect,
    hideSelectAll: true
  }), [selectedRowKeys, onSelect])

  const onHeaderRow = useCallback((column: any) => ({
    style: {
      fontSize: (column.key && column.key.substr(-3) === '-at') ? 9 : 13,
    }
  }), [])

  return (<Table
    size="small"
    rowKey="id"
    pagination={false}
    rowSelection={rowSelection}
    columns={columns}
    dataSource={dataSource}
    loading={loading}
    onHeaderRow={onHeaderRow}
  />)
}

export default TableRoles
