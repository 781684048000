
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Form, Alert, Skeleton, Modal } from 'antd'
import { MapContent, MapWrapper } from './styles'
import { DefaultJsonForm } from 'src/react-app/components/default-json-form'

function isFormDataRequired(formData, schema){
  const { required } = schema || {}
  const result = Array.from(required).every((value: any) => {
    if((value in formData) === false){
      return false
    }
    if(formData[value] === undefined){
      return false
    }
    if(formData[value] === null){
      return false
    }
    if(formData[value] === ''){
      return false
    }
    return true
  })
  return result
} 

const UISchema = {
  'reason-for-cancellation': {
    css: {
      gridColumn: '1 / span 1',
      gridRow: '1',
    },
  },
  comment: {
    options: {
      type: 'textarea',
      minRows: 6,
      maxRows: 6,
    },
    css: {
      gridColumn: '1 / span 24',
      gridRow: '2',
    },
  },
}

export default function AdminNewObjectPage({
  visible,
  onClose,
  options = {},
  onOk
}: {
  visible: boolean
  onClose
  onOk
  options?: any
}) {
  const formDataRef = useRef<any>({})
  const isRequiredContentRef = useRef<boolean>(false)
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageSuccess] = useState<string[]>([])
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [form] = Form.useForm()

  const getData = useCallback(async () => {
    const selectedSchema = {
      type: 'object',
      required: ['reason-for-cancellation'],
      properties: {
        'reason-for-cancellation': {
          type: 'string',
          format: 'platform-collection',
          'collection-name': 'reason-for-cancellation',
          title: 'Причина отмены',
        },
        comment: {
          type: 'string',
          title: 'Комментарий',
        },
      },
    }

    setPageErrors([])
    setMatchedJSONSchema(selectedSchema)
    setIsDataLoading(false)
  }, [])
  useEffect(() => {
    getData()
  }, [getData])

  const onFormData = ({ schema, formData }) => {
    isRequiredContentRef.current = isFormDataRequired(formData, schema)
    formDataRef.current = formData
  }

  const onFinish = async values => {
    const { comment } = formDataRef.current
    if(isRequiredContentRef.current === false){
      Modal.warning({
        title: 'Отмена обращения',
        content: 'Заполните все обязательные поля'
      })
      return
    }

    onOk({ comment: comment || 'Косультация закрыта' })

  }

  const onFinishFailed = errorInfo => {
    console.error('AdminNewObjectPage: form failed', errorInfo)
  }

  const onSubmit = useCallback(() => form.submit(), [form])

  return (
    <Modal
      title="Отмена обращения"
      visible={visible}
      onOk={onSubmit}
      onCancel={onClose}
      className="modal-create-task"
    >
      <MapWrapper>
        <MapContent>
          <Form
            layout="horizontal"
            form={form}
            fields={[
              {
                name: 'schema',
                value: 'cancel',
              },
            ]}
            scrollToFirstError={true}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ schema: 'cancel' }}
          >
            {pageErrors.length > 0 ? (
              <div style={{ marginBottom: 40 }}>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </div>
            ) : null}
            {pageSuccess.length > 0 ? (
              <div style={{ marginBottom: 40 }}>
                <Alert
                  message="Операция успешно выполнена:"
                  showIcon
                  type="success"
                  description={pageSuccess.join('. ')}
                />
              </div>
            ) : null}
            {isDataLoading === false ? (
              <Form.Item>
                {matchedJSONSchema && (
                  <DefaultJsonForm
                    formData={formDataRef.current}
                    schema={matchedJSONSchema}
                    onChange={onFormData}
                    UISchema={UISchema}
                  />
                )}
              </Form.Item>
            ) : (
              <Skeleton active />
            )}
          </Form>
        </MapContent>
      </MapWrapper>
    </Modal>
  )
}

