import React, { useState, useCallback, FC, useEffect, useMemo } from 'react';
import Modal from 'antd-mobile/lib/modal';
import List from 'antd-mobile/lib/list';
import Button from 'antd-mobile/lib/button'

import { ButtonWrapper, ModalBody } from './styles';
import { ITeamOrderAcceptanceModalProps } from './interface'
import { parseDate } from '../../../../utilits'
import { insetNoteToCollection } from '../../../../views/modal-add-notes/utils-notes'
import { AdditionalValidationMenuComponent } from '../validations-menu/additional-validation-menu-component'

export const TeamOrderAcceptanceModal: FC<ITeamOrderAcceptanceModalProps> = props => {
  const {
    onSwitchStage,
    initShow,
    targetId,
    author,
    onClose: handleOnClose,
    onFallBackStage,
  } = props
  const [show, setShow] = useState(false)
  const [showChoiceContent, setShowChoiceContent] = useState(false)
  const [showDateContent, setShowDateContent] = useState(false)
  const [dateContent, setDateContent] = useState<{
    date: string, time: { start: string; end: string }, comment: string
  }>({ date: '', time: { start: '', end: '' }, comment: '' })

  useEffect(() => {
    if (typeof initShow !== 'undefined') {
      setShow(initShow)
    }
  }, [initShow])

  const handleClose = useCallback(() => {
    handleOnClose()
    setShow(false)
    setShowChoiceContent(false)
    setShowDateContent(false)
  }, [handleOnClose])

  const handleSubmit = useCallback(() => {
    handleClose()
    onSwitchStage()
  }, [handleClose, onSwitchStage])

  const handleUpdateDate = useCallback(async () => {
    const timeObject = dateContent['time'] || {}
    const time = timeObject ? `${timeObject['start']}-${timeObject['end']}` : ''
    const contentList = [`Изменена дата монтажа на: ${parseDate(dateContent['date'], 'dd.MM.yyyy')} ${time}`]

    if (dateContent['comment']) {
      contentList.push(dateContent['comment'])
    }

    const content = contentList.join('. ')

    await insetNoteToCollection({ date: new Date().toISOString(), content, author }, targetId)
    onFallBackStage(dateContent)
    handleClose()
  }, [author, dateContent, handleClose, onFallBackStage, targetId])

  const handleShowDateContent = useCallback(() => {
    setShowDateContent(true)
    setShowChoiceContent(false)
  }, [])

  const handleChangeDateData = useCallback((newData) => {
    setDateContent(prevState => ({
      ...prevState,
      ...newData,
    }))
  }, [])

  const cantChangeDate = useMemo(() => (
    !dateContent['time']['start'] &&
    !dateContent['time']['end'] &&
    !dateContent['date']), [dateContent]
  )

  return (
    <Modal
      transparent
      visible={show}
      onClose={handleClose}
      animationType="slide-up"
    >
      {showDateContent && (
        <List renderHeader={() => <div>Изменить дату</div>}>
          <List.Item className="Item">
            <AdditionalValidationMenuComponent
              value={dateContent['date']}
              onChange={handleChangeDateData}
              format="datepicker"
              code="date"
            />
          </List.Item>
          <List.Item className="Item">
            <AdditionalValidationMenuComponent
              value={dateContent['time']}
              onChange={handleChangeDateData}
              format="timepicker"
              code="time"
            />
          </List.Item>
          <List.Item className="Item">
            <AdditionalValidationMenuComponent
              value={dateContent['comment']}
              onChange={handleChangeDateData}
              format="textarea"
              code="comment"
            />
          </List.Item>
          <List.Item>
            <ButtonWrapper>
              <Button type="ghost" onClick={handleClose}>Отмена</Button>
              <Button
                type="primary"
                onClick={handleUpdateDate}
                disabled={cantChangeDate}
              >
                Изменить
              </Button>
            </ButtonWrapper>
          </List.Item>
        </List>
      )}
      {showChoiceContent && (
        <List renderHeader={() => <div>Принять частично</div>}>
          <ModalBody>
            Продолжить монтаж или изменить дату монтажа
          </ModalBody>
          <List.Item>
            <ButtonWrapper>
              <Button type="ghost" onClick={handleShowDateContent}>Изменить дату</Button>
              <Button
                type="primary"
                onClick={handleSubmit}
              >
                Продолжить
              </Button>
            </ButtonWrapper>
          </List.Item>
        </List>
      )}
      {!showDateContent && !showChoiceContent && (
        <List renderHeader={() => <div>Принять продукцию</div>}>
          <ModalBody>
            Принять продукцию частично или полностью
          </ModalBody>
          <List.Item>
            <ButtonWrapper>
              <Button
                type="primary"
                onClick={handleSubmit}
              >
                Полностью
              </Button>
            </ButtonWrapper>
          </List.Item>
        </List>
      )}
    </Modal>
  )
}

// <Button type="ghost" onClick={handleShowChoiceContent}>Частично</Button>