import React, {memo, ReactNode, useMemo} from "react";
import HeaderWithDateTemplateText from "../header-with-text-template/header-with-text-template";
import {useFormatDate} from "../../hooks/useFormatDate";

interface HeaderWithDateTemplateProps {
    className?: string,
    title?: ReactNode,
    date?: string
}

const HeaderWithDateTemplate: React.FC<HeaderWithDateTemplateProps> = memo((props: HeaderWithDateTemplateProps) => {
    const {className, title, date} = props;
    const formatDate = useMemo<string>(
        () => useFormatDate(date),
        [date],
    );

    return (
        <HeaderWithDateTemplateText className={className} title={title} text={formatDate} />
    )
})
export default HeaderWithDateTemplate
