import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { caseDeleteFile, caseEditNote } from 'src/react-app/store/appeals/actions'
import ActivityTimeLine from 'src/react-app/components/activity-timeline'

const createActionsTimeLine = createSelector(
  (state: any) => state.appeals,
  appealsActionsTimeLine => appealsActionsTimeLine,
  usersProfile => usersProfile,
  taskStages => taskStages
)

function ActivityTimeLineConnector() {
  const { appealsActionsTimeLine, usersProfile, taskStages } = useSelector(createActionsTimeLine)
  const dispatch = useDispatch()
  const actions = useMemo(() => ({
    deleteFile: props => dispatch(caseDeleteFile(props)),
    editNote: props => dispatch(caseEditNote(props))
  }), [dispatch])

  const data = useMemo(() => ({
    actionsItems: appealsActionsTimeLine.filter(item => ['file', 'dynamic-file', 'note'].includes(item['type'])),
    usersProfile: usersProfile,
    taskStages: taskStages
  }), [appealsActionsTimeLine, usersProfile, taskStages])

  return (
    <div style={{ width: 'calc(100vw - 28px)', marginLeft: 15, display: 'flex', flexDirection: 'column', marginBottom: 10, background: '#f0ffff' }}>
      <ActivityTimeLine actions={actions} data={data} />
    </div>
  )
}

export default ActivityTimeLineConnector

