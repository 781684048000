import React from 'react'
import { LinkButton } from './styles'

function SchemaCell(name) {
  const toSchema = () => `/admin/schemas/${name}/`

  return (
    <>
      {name ? (
        <LinkButton to={toSchema}>
          {name}
        </LinkButton>
      ) : (
        'Не задана'
      )}

    </>
  )
}

export default SchemaCell
