import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal, Skeleton, Switch, Form} from 'antd'
import {BrowserRouter} from 'react-router-dom'
import {getDataOfType} from 'src/lib/utils/get-data-of-type'
import {Provider, useSelector} from 'react-redux'
import {createSelector} from 'reselect'
import {store} from 'src/browser-app'
import fetchAPI from 'src/lib/utils/fetch-api'
import FormDataEditor from '../../components/json-form-object-editor'
import {isCustomer} from '../../utilits'

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  targetId => targetId 
)

function isFormDataRequired(formData, schema) {
  const { required } = schema || {}
  return Array.from(required).every((value: any) => {
    if (!(value in formData)) {
      return false
    }
    if (formData[value] === undefined) {
      return false
    }
    if (formData[value] === null) {
      return false
    }
    return formData[value] !== '';
  })
}

const selectedSchemaSkeleton = {
  type: 'object',
  required: [
    'organization-manufacture',
    'date-delivery',
  ],
  properties: {
    'organization-manufacture': {
      title: 'Производство',
      type: 'pointer',
      view: {
        name: 'select-from-organizations-v2',
      },
    },
    'date-delivery': {
      type: 'string',
      format: 'date',
      title: 'Дата доставки',
    },
    need_mount: {
      type: 'switch',
      title: 'Монтаж',
    },
    responsible: {
      type: 'hidden',
      view: {
        name: 'select-from-user',
      },
      title: 'Монтажная бригада',
    },
    comment: {
      type: 'string',
      title: 'Комментарий',
    },
  },
}

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

export function modalClose(event) {
  let target = event.target
  while (Boolean(target?.tagName) === true) {
    if (target?.tagName.toLowerCase() === 'div') {
      break
    }
    target = target?.parentNode
  }
  try{
    Array.from(target?.children as any[])[0].click()
    return true
  }catch{
    return false
  }
}

/**
 * Модалка "Отправить в производство"
 * */

export function modalBrigade(data) {
  let formData = {
    value: {},
    schema: {}
  }
  return new Promise(resolve => Modal.confirm({
    icon: null,
    title: null,//'Выбор монтажной бригады',
    /* @ts-ignore */
    content: <Provider store={store}><Content data={data} onChange={value => Object.assign(formData, value)}/></Provider>,
    onCancel: () => resolve(null),
    okButtonProps: { 
      onClick: (event) => {
        const isRequired = isFormDataRequired(formData.value, formData.schema)
        if(isRequired){
          resolve(formData.value)
          modalClose(event)
        }
      }
    }
  }))
}

function Content({ onChange, data }){
  const formDataRef = useRef({}) //  'organization-manufacture': 2, 'organization-delivery': 3
  const [UISchema, setUISchema] = useState<any>({})
  const [isBusy, setIsBusy] = useState<boolean>(true)
  const { sessionData } = useSelector(createSession)
  const { targetId } = useSelector(createAppealsCurrentStep)
  const [localData, setLocalData] = useState<any>({})
  const [selectedSchema, setSelectedSchema] = useState<any>({})
  const [customer, setIsCustomer] = useState<boolean>(false)

  const reCalcSelectedSchema = useMemo(() => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    if (data?.["mounting"] === true) {
      if (roles.includes(12) === false) {
        return selectedSchema
      }
      return selectedSchema
    }

    const properties = Object.assign({}, getDataOfType(selectedSchema, 'properties', Object, {}))
    delete properties['responsible']
    delete properties['date']
    delete properties['date-plan']
    delete properties['time']
    return {
      ...selectedSchema,
      'required': ['organization-manufacture'],
      'properties': properties
    }
  }, [sessionData, data])

  const localOnChange = useCallback(({ formData }) => {
    const showMountSelect = formData?.need_mount === true
    setSelectedSchema(Selected => {
      if(showMountSelect) {
        Selected.required.push('responsible')
        Selected.properties.responsible.type = 'pointer'
      }else{
        Selected.required = Selected.required.filter(item => item !== 'responsible')
        Selected.properties.responsible.type = 'hidden'
      }
      return Selected
    })
    if( formData["date-shipping"] && !formData["date-delivery"]){
      const date = new Date(formData["date-shipping"])
      date.setDate( date.getDate() + 1 )
      formData["date-delivery"] = date.toISOString();
    }
    if( !customer && formData["date-delivery"] && !formData["date"]){
      const date = new Date(formData["date-delivery"])
      date.setDate( date.getDate() + 1 )
      formData["date"] = date.toISOString();
    }
    setIsCustomer( ( formData?.responsible && isCustomer(+formData.responsible) ) || false )
    formDataRef.current = formData
    setLocalData({...formData})
    onChange({ value: localData, schema: selectedSchema })
  }, [onChange, reCalcSelectedSchema, localData, selectedSchema, customer])

  // Перерисовывает форму
  /*
  useEffect(() => {
    const required = [
      'organization-manufacture',
      'date-shipping',
      'organization-delivery',
      'date-delivery',
      'responsible',
    ]

    setSelectedSchema( schema => {
      if(!customer) {
        required.push('date')
        if(schema.properties) {
          schema.properties.date = {
            type: 'string',
            format: 'date',
            title: 'Дата выезда бригады'
          }
        }
      }else{
        delete schema["properties"]["date"]
      }
      schema.required = required
      return schema
    })

    setUISchema(UISchema => {
      if(UISchema) {
        if (customer) {
          if ('date' in UISchema)
            delete UISchema["date"]
        } else {
          UISchema["date"] = {
            css: {
              gridColumn: '1 / span 11',
              gridRow: '6',
            },
            options: {isDisabledDatePrev: true}
          }
        }
      }
      return UISchema
    })
  }, [customer])
*/
  useEffect(() => {
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, null)
    const UISchema = {
      'organization-manufacture': {
        css: {
          gridColumn: '1 / span 24',
          gridRow: '1',
        },
        itemStyle:{marginBottom:24},
        options: {
          types: [2],
          region: data.region,
          isFirst: true,
          disabled: false,
          hidden: true
        },
      },
      'date-delivery': {
        css: {
          gridColumn: '1 / span 11',
          gridRow: '2',
        },
        itemStyle:{marginBottom:24},
        options: {
          isDisabledDatePrev: true,
        },
      },
      responsible: {
        itemStyle:{marginBottom:24},
        css: {
          gridColumn: '1 / span 24',
          gridRow: '4',
        },
       options: {
          roleId: 15,
          organizationId: organizationId,
        },
      },
      comment: {
        options: {
          type: 'textarea',
          minRows: 3,
          maxRows: 3,
        },
        css: {
          gridColumn: '1 / span 24',
          gridRow: '8',
        },
      },
      need_mount: {
        options: {
          type: 'switch'
        }
      }
    };
    (async (targetId) => {
      await fetchAPI(`/api/order-forecast/${targetId}`)
        .then( response => {
          const first = getDataOfType(response, 'data', Array, []).find(item => item['code'] === 'mounting')
          const dateShippingPlan = getDataOfType(response, 'data', Array, []).find(item => item['code'] === 'manufacture')
          const stageDelivery = getDataOfType(response, 'data', Array, []).find(item => item['code'] === 'delivery')
          formDataRef.current = Object.assign({
            'date-plan': getDataOfType(first, 'date', String, undefined),
            'date-shipping-plan': getDataOfType(dateShippingPlan, 'date', String, undefined),
            'date-delivery-plan': getDataOfType(stageDelivery, 'date', String, undefined),
            'organization-manufacture': undefined,
            'organization-delivery': undefined,
          }, formDataRef.current)

          setLocalData( formDataRef.current )
          setSelectedSchema(selectedSchemaSkeleton)
          setUISchema(UISchema)
          setIsBusy(false)
        })
    })(targetId)
  }, [sessionData, targetId, data])

  if(isBusy)
    return <Skeleton active/>

  return (
    <>
      <BrowserRouter>
        <FormDataEditor
          ref={formDataRef}
          formData={localData}
          schema={selectedSchema}
          onChange={localOnChange}
          UISchema={UISchema}
        />
      </BrowserRouter>
    </>
  )
}
