import React, { FC, useCallback } from 'react'
import { TreeSelect } from 'antd'
import { IAdminTreeNodeProps } from './interface'
import { ICollectionsGroup } from '../../views/admin-edit-group-form'

const { TreeNode } = TreeSelect;

/** Render component of Tree select */
export const AdminTreeSelect: FC<IAdminTreeNodeProps> = (props) => {
  const { children, selectedId, onChange } = props

  const renderTreeNode = useCallback((group: ICollectionsGroup) => (
    <TreeNode value={group['id'] || ''} title={group['name']} key={group['id']}>
      {
        group['children'] && group['children'].length > 0
        && group['children'].map(subGroup => renderTreeNode(subGroup))
      }
    </TreeNode>
  ), [])

  return (
    <TreeSelect
      showSearch
      value={selectedId}
      placeholder="Выберите группу"
      allowClear
      treeDefaultExpandAll
      onChange={onChange}
    >
      {children.length > 0 && children.map(group => renderTreeNode(group))}
    </TreeSelect>
  )
}
