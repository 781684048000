import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import B2BLayout from '../../../layouts/b2b'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { DefaultJsonForm } from '../../../components/v2/default-json-form'
import { Alert, Skeleton, Button } from 'antd'
import { PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import { schema } from './schema'
import { objectToUrl, renameKeys, setEnumFields } from '../../utils'
import { ModalAddBrand } from './modal-add-brand'
import { ModalAddType } from './modal-add-type'
import './index.css'

const breadcrumbs = [
  {
    href: '/admin',
    title: 'Админка',
  },
  {
    href: '/new/organizations',
    title: 'Список организаций',
  },
]

export default function AdminNewObjectPage() {
  const {id} = useParams() as any
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [pageSuccess, setPageSuccess] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [isOpenModalAddBrand, setIsOpenModalAddBrand] = useState<boolean>(false)
  const [isOpenModalAddType, setIsOpenModalAddType] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>({})

  const saveCase = useCallback(
    async () => {
      const formData = { ...formDataRef.current }
      await fetchAPI(`/api/organization-new/${id}`, {
        method: 'POST', body: JSON.stringify(formData)
      }).then(result => {
        if (result?.error) {
          const errors =
            Array.isArray(result['errors']) && result['errors']?.length > 0
              ? result['errors'].map((error) => error['detail'])
              : ['Неизвестная ошибка при выполнении запроса']
          setPageSuccess([])
          setPageErrors(errors)
        } else {
          history.push(`/new/organizations`)
          /*
          if (id === 'new') {
            history.push(`/new/organization/${result.id}`)
          } else {
            setPageSuccess(['Запись успешно обновлена.'])
            setPageErrors([])
          }
          */
        }
      })
    },
    [history, id],
  )

  const getData = useCallback(async () => {
    {
      if (id !== 'new') {
        const data = await fetchAPI(`/api/organization-new/${id}`)
        const formData = data.reduce((obj, cur) => ({ ...obj, [cur.name]: cur.value }), {})
        formDataRef.current = { ...formData }
      }

      const enumsList = { types: ['organization_types', 'brands', 'roles'] }
      const enums = await fetchAPI(`/api/enum-list?${objectToUrl(enumsList)}`)
      const fixKeys = renameKeys(enums, { organization_types: 'type_id', brands: 'brand_id', roles: 'task_responsible_roles' })

      if (schema?.properties?.brand_id) {
        schema.properties.brand_id = {
          ...schema.properties.brand_id,
          options: {
            allowClear: true,
            afterButton: (<Button
              onClick={() => setIsOpenModalAddBrand(true)}>
              <PlusOutlined />
            </Button>)
          }
        }
      }

      if (schema?.properties?.type_id) {
        schema.properties.type_id = {
          ...schema.properties.type_id,
          options: {
            afterButton: (<Button
              onClick={() => setIsOpenModalAddType(true)}>
              <PlusOutlined />
            </Button>)
          }
        }
      }

      setEnumFields(fixKeys, schema)
      setMatchedJSONSchema(schema)
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [id, setFormData])

  const onCloseAddBrand = useCallback(({id, name}) => {
    if (id && name) {
      setMatchedJSONSchema(prevSchema => {
        const newSchema = { ...prevSchema }
        if (newSchema.properties.brand_id.oneOf && newSchema.properties.brand_id.enum) {
          newSchema.properties.brand_id.oneOf.push({const: id, title: name})
          newSchema.properties.brand_id.enum.push(id)
        }
        return newSchema
      })
      const updatedFormData = { ...formData, brand_id: id }
      formDataRef.current = { ...updatedFormData }
      setFormData(updatedFormData)
    }
    setIsOpenModalAddBrand(false)
  }, [setFormData])

  const onCloseAddType = useCallback((result) => {
    const { id, value } = result
    if (id && value) {
      setMatchedJSONSchema(prevSchema => {
        const newSchema = { ...prevSchema }
        if (newSchema.properties.type_id.oneOf && newSchema.properties.type_id.enum) {
          newSchema.properties.type_id.oneOf.push({ const: id, title: value })
          newSchema.properties.type_id.enum.push(id)
        }
        return newSchema
      })

      const updatedFormData = { ...formData, type_id: id }
      formDataRef.current = { ...updatedFormData }
      setFormData(updatedFormData)
    }
    setIsOpenModalAddType(false)
  }, [setFormData])

  useEffect(() => {
    getData().catch(e => {
      console.error({e})
    })
  }, [getData])

  const onFormData = useCallback(({formData}) => {
    setFormData(formData)
  }, [setFormData])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            {id === 'new' ?
              (<>Добавление организации</>)
              :
              (<>Организация <span>{formDataRef.current?.name}</span></>)
            }
          </MapTitle>
        </TaskWrapper>
        <MapContent>
          <ModalAddBrand
            isOpen={isOpenModalAddBrand}
            onClose={onCloseAddBrand}
          />
          <ModalAddType
            isOpen={isOpenModalAddType}
            onClose={onCloseAddType}
          />
          {pageErrors.length > 0 ? (
            <AlertWrapper>
              <Alert
                message="При выполнении операции возникла ошибка:"
                showIcon
                type="error"
                description={pageErrors.join('. ')}
              />
            </AlertWrapper>
          ) : null}
          {pageSuccess.length > 0 ? (
            <div style={{marginBottom: 40}}>
              <Alert
                message={pageSuccess.join('. ')}
                showIcon
                type="success"
              />
            </div>
          ) : null}
          {!isDataLoading ? (
            <>
              <DefaultJsonForm
                formData={formDataRef.current}
                schema={matchedJSONSchema}
                onChange={onFormData}
                orientation="vertical"
              />
              <Button onClick={saveCase} style={{marginBottom: 16, marginTop: 16}} type="primary">
                <SaveOutlined />
                Сохранить
              </Button>
            </>
          ) : (
            <Skeleton active/>
          )}
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
