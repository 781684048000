import React, {memo, Suspense, useCallback, useEffect, useState} from "react";
import {Layout, Modal, Spin} from "antd";
import {TransferItem} from "../../model/types/types";
import {SettingsTransferAsync} from "../settings-transfer/settings-transfer.async";
import type {TransferDirection} from "antd/es/transfer";
import {setJsonSettings} from "../../../../../store/session/actions";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {saveJsonSettings} from "../../../../../store/session/action-creators";
import {getJsonSettingsByKey} from "../../model/selectors/store";


interface TableSettingsModalProps {
    className?: string,
    isOpen?: boolean,
    onCancel?: () => void,
    items: TransferItem[]
}

const Fallback = memo(() => (
    <Layout style={{background: 'white', textAlign: 'center', minWidth: '500px'}}>
        <Spin size="large"/>
    </Layout>
))

const TableSettingsModal: React.FC<TableSettingsModalProps> = (props) => {
    const {isOpen, onCancel, items} = props;
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const jsonSettings = useSelector((state) => getJsonSettingsByKey(state, pathname));

    useEffect(() => {
        if (!jsonSettings || jsonSettings?.length === 0) {
            return
        }
        setTargetKeys(jsonSettings);
    }, [])

    const onChangeTransfer = useCallback((newTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
        setTargetKeys(newTargetKeys);
    }, []);

    const swapKeyIndex = useCallback((swapIndex: number, index: number) => {
        setTargetKeys((prevState) => {
            const tmp = [...prevState];
            tmp[swapIndex] = [tmp[index], tmp[index] = tmp[swapIndex]][0];
            return tmp
        })
    }, [])

    const onSaveTableSettings = useCallback(() => {
        dispatch(setJsonSettings({
            [pathname]: targetKeys
        }))
        dispatch(saveJsonSettings())
        onCancel?.()
    }, [targetKeys])

    return (
        <Modal
            visible={isOpen}
            onCancel={onCancel}
            destroyOnClose={true}
            title={'Выберите столбцы для отображения'}
            width={'fit-content'}
            onOk={onSaveTableSettings}
        >
            <Suspense fallback={<Fallback/>}>
                <SettingsTransferAsync
                    dataSource={items}
                    targetKeys={targetKeys}
                    onChange={onChangeTransfer}
                    swapKeyIndex={swapKeyIndex}
                />
            </Suspense>
        </Modal>
    )
}

export default memo(TableSettingsModal);