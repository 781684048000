import styled from 'styled-components';

export const FieldName = styled.span`
  font-weight: bold;
`;

export const BackToDeliveryList = styled.a`
  font-size: 19px;
  color: #404040;
  padding: 21px 0;
  text-align: left;
`

export const PageTitle = styled.div`
  display: flex;

  font-size: 16px;
  font-weight: bold;
`

export const ActionListItemText = styled.span`
  white-space: nowrap;
`
