import React, { memo, useCallback, useEffect, useState } from 'react'
import { Alert, Form, Skeleton } from 'antd'
import { useSelector } from 'react-redux'
import {
  getAppealsFormData,
  getAppealsFormError,
  getAppealsFormLoading,
  getVisibleFormData,
} from '../../selectors'
import { useDebounce } from 'src/react-app/hooks/useDebounce/useDebounce'
import { HStack, VStack } from '../../../../../ui/Stack'
import { HSteps } from '../../../../../ui/Steps'
import { DefaultJsonForm } from '../../../../../components/v2/default-json-form'
import { AppealsFormsHeader } from './appeals-form-header/appeals-form-header'
import { useSaveResult } from '../../hooks/useSaveResult'
import { useHistory } from 'react-router-dom'

interface AppealsFormsProps {
  className?: string
  id: string | number,
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
}

export const AppealsForms: React.FC<AppealsFormsProps> = memo((props: AppealsFormsProps) => {
  const {id} = props
  const [form] = Form.useForm()
  const appealsFormData = useSelector(getAppealsFormData)
  const appealsFormIsLoading = useSelector(getAppealsFormLoading)
  const appealsFormError = useSelector(getAppealsFormError)
  const [isDisabled, setIsDisabled] = useState<boolean>()
  const visibleFormData = useSelector(getVisibleFormData)
  const {saveResult} = useSaveResult()
  const history = useHistory()

  const saveCase = useCallback(async (nextCardType) => {
    await saveResult(id, visibleFormData, appealsFormData?.stages?.current, nextCardType)
  }, [history, id, visibleFormData])

  useEffect(() => {
    const redirectUrl = appealsFormData?.redirectUrl
    if (redirectUrl) {
      history.push(redirectUrl)
    }
  }, [appealsFormData])

  const debounceSaveCase = useDebounce(saveCase, 1000)

  const onChangeForm = useCallback(
    async ({formData}) => {
      if (!visibleFormData) {
        return
      }
      const isFilesEdit = JSON.stringify(formData) === JSON.stringify(visibleFormData?.resultObject)
      if (isFilesEdit) {
        return
      }
      visibleFormData.resultObject = {...formData}
      debounceSaveCase()
    }, [visibleFormData, debounceSaveCase]
  )

  const handleToOrder = useCallback(async () => {
    setIsDisabled(true)
    await saveCase('order')
    setIsDisabled(false)
  }, [isDisabled, saveCase])

  const handleToClaim = useCallback(async () => {
    setIsDisabled(true)
    await saveCase('claim')
    setIsDisabled(false)
  }, [isDisabled, saveCase])

  if (appealsFormError && !appealsFormIsLoading && !appealsFormData) {
    return (
      <HStack gap={'8'} className={'mapWrapper'}>
        При выполнении запроса возникла ошибка.
        Попробуйте перезагрузить страницу или открыть карточку повторно.
      </HStack>
    )
  }

  return (
    <>
      <AppealsFormsHeader
        id={id}
        isFinished={appealsFormData?.stages && appealsFormData?.stages?.current === Object.keys(appealsFormData?.stages?.items).length - 1}
        stage={appealsFormData?.stages?.current}
        toOrder={handleToOrder}
      />
      <HStack gap={"8"} className={'mapContent'} max>
        <Form
          {...formItemLayout}
          layout='horizontal'
          form={form}
          scrollToFirstError={true}
          style={{width: '100%'}}
        >
          {appealsFormError && !appealsFormIsLoading ? (
            <div className={'alertWrapper'}>
              <Alert
                message='При выполнении операции возникла ошибка:'
                showIcon
                type='error'
                description={appealsFormError}
              />
            </div>
          ) : null}
          {!appealsFormIsLoading ? (
            <VStack gap={'16'} max align={'stretch'} className={'appealsForm'}>
              {appealsFormData?.stages && (
                <HSteps
                  items={Object.values(appealsFormData?.stages?.items)}
                  current={appealsFormData?.stages?.current}
                  history={appealsFormData?.stages?.history}
                />)
              }
              {
                visibleFormData && <DefaultJsonForm
                  formData={{...visibleFormData.resultObject}}
                  schema={visibleFormData.resultSchema}
                  onChange={onChangeForm}
                  orientation='horizontal'
                />
              }
            </VStack>
          ) : (
            <Skeleton active/>
          )}
        </Form>
      </HStack>
    </>
  )
})
