import React from 'react'
import ActivityTimeLine from 'src/react-app/connectors/activity-timeline'
import OrderExecutionPlan from 'src/react-app/views/order-execution-plan'

function EventRibbonTab({ name, schema, formData, onChange }) {
return (
  <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
    <div style={{ flexBasis: '50%' }}>
     <OrderExecutionPlan />
    </div>
    <div style={{ flexBasis: '50%', paddingLeft: 20 }} className="actions-time-line">
      <ActivityTimeLine />
    </div>
  </div>
  )
}

export default EventRibbonTab


