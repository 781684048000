import {getDetailTaskItemData} from "./getDetailTaskItemData";
import moment, {Moment} from "moment";
import {IDataByDay, IDataByDayItem} from "../selectors";
import {getSlotByTime} from "./getSlotByTime";
import {TimeTableSlots} from "../consts/timeTableSlots";
let keys = Object.keys(TimeTableSlots);

export const getDetailTimeSlotData = (value: Moment, dataByDay: IDataByDay, time) => {
   const result: IDataByDayItem[] = []
   getDetailTaskItemData(value, dataByDay)?.forEach((itemData) => {
      let h = moment(itemData.deadline).format('H');
      let slot = getSlotByTime(keys, h);
      if (slot == time) {
         result.push(itemData)
      }
   })
   return result
}