import React, { useCallback, useState } from 'react'
import { Modal, Select, Form, Button, message } from 'antd'
import fetchAPI from '../../../../../../lib/utils/fetch-api'
import { useHistory } from 'react-router-dom'

const { Option } = Select

interface IOrganization {
  id: number
  name: string
  offices: IOffice[]
}

interface IOffice {
  id: number
  name: string
  managers: IManager[]
}

interface IManager {
  id: number
  name: string
}

interface ChangeRegionModalProps {
  id: string | number
  disabled: boolean
}

interface IFormData {
  organization: number | null
  office: number | null
  manager: number | null
}

const ChangeRegionModal: React.FC<ChangeRegionModalProps> = ({ id, disabled })  => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const history = useHistory()
  const [formData, setFormData] = useState<IFormData>({
    organization: null,
    office: null,
    manager: null,
  })
  const [organizations, setOrganizations] = useState<IOrganization[]>([])
  const [offices, setOffices] = useState<IOffice[]>([])
  const [managers, setManagers] = useState<IManager[]>([])

  const fetchOrganizations = useCallback(async () => {
    await fetchAPI(`/api/change_office/${id}`)
      .then(res => {
        setOrganizations(res.organization)
        if (res.organization.length === 1) {
          setFormData(prevData => ({ ...prevData, organization: res.organization[0].id }))
          setOffices(res.organization[0].offices)
        }
      })
      .catch(error => {
        message.error('Ошибка при загрузке данных', error)
      })
  }, [setOrganizations, setFormData])

  const handleOpenModal = () => {
    setFormData( prevData => ({ ...prevData, organization: null, office: null }))
    fetchOrganizations().catch(error => console.error(error))
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOrgChange = useCallback((value: number) => {
    const selectedOrg = organizations.find(org => org.id === value)
    setFormData({ organization: value, office: null, manager: null })
    setOffices(selectedOrg?.offices || [])
    if (selectedOrg) {
      if (selectedOrg?.offices.length === 1) {
        setFormData(prevData => ({ ...prevData, office: selectedOrg.offices[0].id }))
        handleOfficeChange(selectedOrg.offices[0].id)
      } else {
        setFormData(prevData => ({
          ...prevData,
          office: null,
        }))
      }
    }
  }, [organizations, setOffices, setFormData])

  const handleOfficeChange = useCallback((value: number) => {
    const managers = offices.find(office => office.id === value)?.managers
    if (managers?.length === 1) {
      setFormData(prevData => ({ ...prevData, office: value, manager: managers[0].id }))
    } else {
      setFormData(prevData => ({ ...prevData, office: value, manager: null }))
    }
    setManagers(managers || [])
  }, [offices, setFormData, setManagers])

  const handleManagerChange = useCallback((value: number) => {
    setFormData(prevData => ({ ...prevData, manager: value }))
  }, [setFormData])

  const handleSubmit = async () => {
    if (!formData.organization || !formData.office) {
      message.error('Выберите организацию и офис.')
      return
    }

    await fetchAPI('/api/change_office', {
        method: 'POST',
        body: JSON.stringify({ ...formData, id }),
      })
      .then(() => {
        handleCloseModal()
        history.push('/orders')
      })
      .catch(error => {
        message.error('Ошибка при сохранении данных', error)
      })
  }

  return (
    <>
      <Button onClick={handleOpenModal} disabled={disabled}>Передать в другой офис</Button>
      <Modal
        title='Выбор региона'
        visible={isModalOpen}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form layout='vertical'>
          <Form.Item label='Организация' required>
            <Select
              value={formData.organization}
              onChange={handleOrgChange}
              placeholder='Выберите организацию'
              allowClear
            >
              {organizations.map(org => <Option key={org.id} value={org.id}>{org.name}</Option>)}
            </Select>
          </Form.Item>

          <Form.Item label='Офис' required>
            <Select
              value={formData.office}
              onChange={handleOfficeChange}
              placeholder='Выберите офис'
              allowClear
              disabled={!formData.organization}
            >
              {formData.organization && offices.map(office => <Option key={office.id} value={office.id}>{office.name}</Option>)}
            </Select>
          </Form.Item>

          <Form.Item label='Менеджер' required>
            <Select
              value={formData.manager}
              onChange={handleManagerChange}
              placeholder='Выберите менеджера'
              allowClear
              disabled={!formData.organization || !formData.office}
            >
              {formData.organization && formData.office && managers.map(manager => <Option key={manager.id} value={manager.id}>{manager.name}</Option>)}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type='primary' onClick={handleSubmit}>
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ChangeRegionModal
