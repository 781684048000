import {Button} from "antd";
import React, {memo, Suspense, useCallback, useState} from "react";
import {ExcelFileAsync} from "../excel-file/excel-file.async";


export interface ExportExcelButtonProps {
    fileName?: string,
    path: string,
    columns: any[],
    onPrintClick: (path: string) => Promise<any>,
}


export const ExportExcelButton: React.FC<ExportExcelButtonProps> = memo((props) => {
    const {path, columns, onPrintClick, fileName = 'file'} = props;
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const onClickMenuItem = useCallback(async () => {
        setIsLoading(true)
        try {
            const data = await onPrintClick(path);
            setData(data)
            setIsMounted(true)
        } catch (e) {
            console.log('ExportExcelButton', e)
        }
    }, [path, columns])


    const onMountedExcelComponent = useCallback(() => {
        setIsLoading(false)
        setIsMounted(false)
    }, [])

    return (<>
            <Button type={'default'} onClick={onClickMenuItem} loading={isLoading}>
                Выгрузка в Excel
            </Button>

            <Suspense fallback={''}>
                {
                    isMounted && <ExcelFileAsync
                        onMountedExcelComponent={onMountedExcelComponent}
                        fileName={fileName}
                        columns={columns}
                        data={data}
                    />
                }
            </Suspense>
        </>
    )
})
