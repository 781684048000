import React, { useEffect, useState, useMemo } from 'react'
import { Table } from 'antd'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import dayjs from 'dayjs'
import { DarkLink } from 'src/react-app/components/dark-link'
/* 
function CellSchema({ schema }) {
  if (schema === 'appeal') {
    return <span>Обращение</span>
  }
  if (schema === 'case-order') {
    return <span>Заказ</span>
  }
  return <></>
} */

/* function DateFactFormat({data}) {
  return (
  <Space direction="vertical">
    {data.map(item=> (
      <>
      <Space direction="vertical">
      {item['title']}
    </Space>
    <Space direction="vertical">
      {dayjs(item['date']).format('DD.MM.YYYY')}
    </Space>
      </>
    )
    )}
  </Space>
  )
}

function DatePlanFormat({data}) {
  return (
  <Space direction="vertical">
    {data.map(item=> (
      <>
      <Space direction="vertical">
      {item['title']}
    </Space>
    <Space direction="vertical">
      {dayjs(item['date']).add(item['hours-needed'], 'hour').format('DD.MM.YYYY')}
    </Space>
      </>
    )
    )}
  </Space>
  )
}

function ResponsiblesFormat({data}) {
  return (
  <Space direction="vertical">
    {data.map(item=> (
      <>
      <Space direction="vertical">
      {item['title']}
    </Space>
    <Space direction="vertical">
      {(item['responsibles'] || []).map(item => {
      return <Space direction="vertical">
      {item['login']}
      </Space>
    
      })}
    </Space>
      </>
    )
    )}
  </Space>
  )
} */

function getStageFromData(data){
  const stages = [
    'Новая',
    'В работе',
    'Замер',
    'Оплата',
    'Производство',
    'Доставка',
    'Мотаж',
    'Аудит',
    'Выполнено'
  ]
  const numStage = getDataOfType(data, 'attributes.stage', Number, 0)
  return stages[numStage]
}


function CellId({ id, schema }) {
  const url = useMemo(() => {
    if (schema === 'appeal') {
      return `/case/${id}/edit`
    }
    if (schema === 'case-order') {
      return `/order/${id}/edit`
    }
    return ''
  }, [schema, id])

  return <DarkLink to={url}>{id}</DarkLink>
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (id, { schema }) => <CellId id={id} schema={schema} />,
  },
  {
    title: 'Дата создания',
    dataIndex: 'date',
    key: 'date',
    render: text => dayjs(text).format('DD.MM.YYYY HH:mm'),
  },
  {
    title: 'Сумма',
    dataIndex: 'sum',
    key: 'sum',
    render: text => text,
  },
  {
    title: 'Стадия',
    dataIndex: 'data',
    key: 'data',
    render: data => getStageFromData(data),
  },
  {
    title: 'Оценка исполнения заказа',
    dataIndex: '22',
    key: 'sum22',
    render: text => <></>,
  },
]

function TabGeneral({ clientId }){
  const [data, setData] = useState([])
  useEffect(() => {
    (async () => {
      const result = await fetchAPI(`/api/employees-from-orders/${clientId}`).then(items => ({ data: items }))
     // const array = result as any []
      const array = getDataOfType(result, 'data', Array, [])
/*       const data = array.map(item => ({
        id: getDataOfType(item, 'id', [String, Number], null),
        schema: getDataOfType(item, 'data.metadata.schema', [String], null),
        date: getDataOfType(item, 'created_at', [String], null),
      })) */
      setData(array)
    })()

  }, [clientId])

  return <Table dataSource={data} columns={columns} pagination={false} key='id' />
}

export default TabGeneral
