import React from 'react';
import { FieldName } from './styles'
import { parseDate } from '../../../utilits'
import Card from 'antd-mobile/lib/card';
import WhiteSpace from 'antd-mobile/lib/white-space';

export const DeliveryCard = (props) => {
  const { data } = props

  return (
    <>
      <Card>
        <Card.Header
          title="Даты"
          extra={<span>Доставка</span>}
        />
        <Card.Body>
          <div><FieldName>Назначенная:</FieldName> {parseDate(data['date'])}</div>
          {data['date-adjusted'] && <div><FieldName>Скорректированная:</FieldName> {parseDate(data['date-adjusted'])}</div>}
          {data['date-fact'] && <div><FieldName>Фактическая:</FieldName> {parseDate(data['date-fact'])}</div>}
        </Card.Body>
      </Card>
      <WhiteSpace size="lg" />
    </>
  )
}
