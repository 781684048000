import { TRANSITIONS_TYPES } from '../measurements-edit/interface'

export const collectionName = 'card-mounting'

export const cardName = 'mounting'

export const additionalTransitions = {
  'work': [{
    name: 'Изменить дату',
    nextValidators: [
      {
        code: 'date',
        format: 'datepicker',
        title: 'Дата скорректированная',
        type: 'string',
      },
      {
        code: 'time',
        format: 'timepicker',
        title: 'Время скорретированное',
        type: 'string',
      },
      {
        code: 'comment',
        format: 'textarea',
        title: 'Комментарий',
        type: 'string',
      },
    ],
    roles: [],
    target: 'date-change',
    type: TRANSITIONS_TYPES.ADDITIONAL,
  }],
  'delivery-planned': [{
    name: 'Изменить дату',
    nextValidators: [
      {
        code: 'date',
        format: 'datepicker',
        title: 'Дата скорректированная',
        type: 'string',
      },
      {
        code: 'time',
        format: 'timepicker',
        title: 'Время скорретированное',
        type: 'string',
      },
      {
        code: 'comment',
        format: 'textarea',
        title: 'Комментарий',
        type: 'string',
      },
    ],
    roles: [],
    target: 'date-change',
    type: TRANSITIONS_TYPES.ADDITIONAL,
  }]
}
