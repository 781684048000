import { css } from 'styled-components'

export default css`
  @font-face {
    font-family: 'SourceSansPro';
    src: url('../assets/fonts/SourceSansPro-Bold.woff2') format('woff2'),
      url('../assets/fonts/SourceSansPro-Bold.woff') format('woff'),
      url('../assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
  }
  @font-face {
    font-family: 'SourceSansPro';
    src: url('../assets/fonts/SourceSansPro-Regular.woff2') format('woff2'),
      url('../assets/fonts/SourceSansPro-Regular.woff') format('woff'),
      url('../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
  }
  @font-face {
    font-family: 'SourceSansPro';
    src: url('../assets/fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
      url('../assets/fonts/SourceSansPro-SemiBold.woff') format('woff'),
      url('../assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
  }
`
