import React, { FC, useCallback } from 'react';
import Card from 'antd-mobile/lib/card';
import WhiteSpace from 'antd-mobile/lib/white-space';

import { FieldGroup, FieldName, FieldContent } from '../../measurements-edit/styles'
import { IDocumentCardProps } from './interface'

export const DocumentCard: FC<IDocumentCardProps> = props => {
  const { data = [] } = props

  const getName = useCallback((field: Record<string, any>) => {
    const name = field['fieldName'] || field['fieldKey']
/*     if (name === 'Скан договора') {
      return 'Скан замера'
    } */
    return name
  }, [])

  const haveItemData = useCallback(item => item['data'] && item['data'].length > 0, [])
//  <FieldGroup key={`document-group-${item['fieldKey']}`}>
  return (
    <>
      <Card>
        <Card.Header
          title="Документы"
        />
        <Card.Body>
          {data.length > 0 && data.map(item => (
            <FieldGroup key={`document-group-${item['fieldKey']}`}>
              <FieldName>
                {getName(item)}:
              </FieldName>
              {haveItemData(item) && item['data'].map(itemData => (
                <FieldContent key={`document-link-${itemData['name']}`}>
                  <a href={itemData['attributes']['url']}>{itemData['name']}</a>
                </FieldContent>
              ))}
            </FieldGroup>
          ))}
        </Card.Body>
      </Card>
      <WhiteSpace size="lg" />
    </>
  )
}
