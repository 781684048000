import React, { FC, useCallback } from 'react'
import { Form, Input } from 'antd'

import { IEditGroupProps } from './interface'
import { formItemLayout } from '../../forms/app-edit-collection-form/consts'
import { AdminTreeSelect } from '../../components/admin-tree-node'

export const EditGroupForm: FC<IEditGroupProps> = (props) => {
  const { groups, form, onChange, selectedParentGroupId } = props

  const handleChangeGroup = useCallback((value) => {
    onChange({
      'parent-id': value,
    })
  }, [onChange])

  const handleInputChange = useCallback((event) => {
    onChange({
      name: event.target.value,
    })
  }, [onChange])

  return (
    <Form layout="horizontal" {...formItemLayout} form={form}>
      <Form.Item
        label="Наименование группы"
        name="name"
        rules={[
          {
            required: true,
            message: 'Поле обязательно к заполнению',
          },
        ]}
      >
        <Input onChange={handleInputChange} placeholder="Укажите наименование группы" />
      </Form.Item>
      <Form.Item
        label="Родительская группа"
      >
        <AdminTreeSelect children={groups} selectedId={selectedParentGroupId} onChange={handleChangeGroup} />
      </Form.Item>
    </Form>
  )
}
