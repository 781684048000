import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Form, Alert, Skeleton, Button } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import B2BLayout from '../../layouts/b2b'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import {
  createUISchemaHorizontal,
  createUISchemaVertical,
  getGridColumn,
  getGridRow
} from 'src/lib/utils/grid-fild-orientation'
import claimFieldsSchema from "./json-schema.json";
import fetchAPI from 'src/lib/utils/fetch-api'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const breadcrumbs = [
  {
    href: '/',
    title: 'Контактный центр',
  },
  {
    href: `/mails`,
    title: 'Почта',
  },
]

export default function AdminNewObjectPage() {
  const { id } = useParams()  as any
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [mailHtml, setMailHtml] = useState<string>('')
  const [mailFrom, setMailFrom] = useState<string>('')

  const getData = useCallback(async () => {
    {
      const result = id !== 'new' && await fetchAPI(`/api/organization_imap_email/${id}`) || []
      const data = getDataOfType(result, 'data', Array, [])
      const mailData = getDataOfType(data, '[0]', Object, {})

      setMailHtml(mailData?.body || '')
      setMailFrom(mailData?.from || '')
    }

    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>

      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            Почтовое сообщение от <span>{`${mailFrom}`}</span>
          </MapTitle>
        </TaskWrapper>
        <MapContent>
          {isDataLoading === false ? (
            mailHtml && (
              <div dangerouslySetInnerHTML={{ __html: mailHtml }}></div>
            )
          ) : (
            <Skeleton active />
          )}
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
