import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import dayjs from 'dayjs';
import { Table, Button, Space, Dropdown, Menu } from 'antd'
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import fetchAPI from 'src/lib/utils/fetch-api'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { modalAppoint } from './modal'
import Adaptor from 'src/react-app/connectors/tab-list-ordering-services/services'
import ViewProducts from './view-products'
import TablePayments  from './payments-table'
import { TableDatesActPrint } from './styles'
import { reloadTablePayment } from 'src/react-app/store/appeals/actions'
import { fileUploadV2 } from 'src/react-app/connectors/file-s3-json-form-specification/utils-files-s3'
import './index.css'

export function priceFormat(price: number) {
  return Number(price).toFixed(0)
}

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData,
)

const name = 'order-specification'

const caseSelector = createSelector(
  (state: any) => state.appeals,
  targetId => targetId,
  cardName => cardName,
  stageCode => stageCode,
  tablePayment => tablePayment
)

function downloadUrl(url, exportName) {
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', url)
  downloadAnchorNode.setAttribute('download', exportName)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

function formatCurrency(val){
  return (val || 0).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB', currencyDisplay: 'symbol'}).replace("₽", "")
}

const EditableTable = ({ currentStage, disabled, id, options, targetId: ll }: { currentStage?: any , disabled?: any , id?: any , options?: any, targetId?: any }) => {
  const dispatch = useDispatch()
  const { targetId, cardName, stageCode, tablePayment } = useSelector(caseSelector)
  const [localData, setLocalData] = useState<any>([])
  const [source, setSource] = useState<any>({})
  const [square, setSquare] = useState<number>(0)
  const [images, setImages] = useState<any>([])
  // const [dateUpdateProducts, setDateUpdateProducts] = useState(Date.now())
  const idRef = useRef(0)
  const uploadFileRef = useRef<any>()
  const { sessionData } = useSelector(createSession)

  const save = useCallback(async formData => {
    const requestBody = {
      data: {
        name: '',
        title: '',
        attributes: formData,
        metadata: { schema: name }
      },
      'parent-id': targetId
    }
    if (Number.isInteger(idRef.current)) {
      const result = await fetchAPI(`/api/collections/-/objects/${idRef.current}`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
      })
      if (result?.['data']?.['updated-at'] === undefined) {
        console.warn('Ошибка сохранения записи')
      }
    }
    if (!Number.isInteger(idRef.current)) {
      const result = await fetchAPI(`/api/collections/${name}`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
      })
      if (result?.['data']?.['updated-at'] === undefined) {
        console.warn('Ошибка сохранения записи')
      }
      idRef.current = result?.['data']?.['id'] || null
    }

  }, [dispatch, targetId])

  const saveTable = useCallback(data => {
    save({...source, table: data, 'square': square ?? 0, 'images': images}).then(r => console.log({ r }))
  }, [save, square, source, images])

  const productsPush = useCallback(() => {
    const currentDate = new Date().toISOString()
    if (cardName === 'manufacture' && stageCode === 'expect-shipment') {
      setLocalData(prevData => {
        const nextData = prevData.map(item => Object.assign({}, item, { 
          'manufacture': currentDate,
          // Проставляется автоматически изпроизводство, принятие груза водителем
          'loading': currentDate
        }))
        saveTable(nextData)
        return nextData
      })
    }
    if (cardName === 'delivery' && stageCode === 'crew-assigned') {
      setLocalData(prevData => {
        const nextData = prevData.map(item => Object.assign({}, item, { 'loading': currentDate }))
        saveTable(nextData)
        return nextData
      })
    }
    if (cardName === 'delivery' && stageCode === 'delivered-client') {
      setLocalData(prevData => {
        const nextData = prevData.map(item => Object.assign({}, item, { 'shipment': currentDate }))
        saveTable(nextData)
        return nextData
      })
    }
    if (cardName === 'mounting' && stageCode === 'team-arrived') {
      setLocalData(prevData => {
        const nextData = prevData.map(item => Object.assign({}, item, { 'mounting': currentDate }))
        saveTable(nextData)
        return nextData
      })
    }
  }, [cardName, stageCode, saveTable])

  const isNotProductsPush = useMemo(() => {
    if (cardName === 'manufacture' && stageCode === 'expect-shipment') {
      console.log( tablePayment, tablePayment?.targetId && tablePayment?.sum - tablePayment?.paymentsSumAll === 0 )
      return (tablePayment?.targetId && tablePayment?.sum > tablePayment?.paymentsSumAll)
    }
    if (cardName === 'delivery' && stageCode === 'crew-assigned') {
      const isNotManufacture = localData
        .map(item => dayjs(item?.['manufacture'] ?? null).isValid())
        .includes(false)
      const isShipment = localData
        .map(item => dayjs(item?.['shipment'] ?? null).isValid())
        .includes(true)
      return isNotManufacture && isShipment
    }
    if (cardName === 'delivery' && stageCode === 'delivered-client') {
      const isNotLoading = localData
        .map(item => dayjs(item?.['loading'] ?? null).isValid())
        .includes(false)
      const isMounting = localData
        .map(item => dayjs(item?.['mounting'] ?? null).isValid())
        .includes(true)
      return  isNotLoading && isMounting
    }
    if (cardName === 'mounting' && stageCode === 'team-arrived') {
      return localData
        .map(item => dayjs(item?.['shipment'] ?? null).isValid())
        .includes(false)
    }
    return true
  }, [cardName, stageCode, localData, tablePayment])

  const ButtonPush = useCallback(() => {
    if (cardName === 'manufacture' && stageCode === 'expect-shipment') {
      return <Button disabled={isNotProductsPush} onClick={productsPush}>Отгрузить все</Button>
    }
    if (cardName === 'delivery' && stageCode === 'crew-assigned') {
      return <Button disabled={isNotProductsPush} onClick={productsPush}>Принять все</Button>
    }
    if (cardName === 'delivery' && stageCode === 'delivered-client') {
      return <Button disabled={isNotProductsPush} onClick={productsPush}>Отгрузить все</Button>
    }
    if (cardName === 'mounting' && stageCode === 'team-arrived') {
      return <Button disabled={isNotProductsPush} onClick={productsPush}>Принять все</Button>
    }
    return <>Прием-передача</>
  }, [cardName, stageCode, productsPush, isNotProductsPush])

  const onDeleteRow = useCallback(data => setLocalData(prevData => {
    const nextData = prevData.filter(item => {
      return item !== data
    })
    saveTable(nextData)
    return nextData
  }), [saveTable])

  const onDeleteRows = useCallback(() => setLocalData(() => {
    const nextData = []
    saveTable(nextData)
    return nextData
  }), [saveTable])

  const columns: any = useMemo(() => {
    const columns: any = [
      {
        dataIndex: 'name',
        editable: true,
        required: false,
        title: 'Наименование',
        type: 'string',
        width: 300,
      },
      {
        dataIndex: 'price',
        editable: true,
        required: false,
        title: 'Стоимость',
        type: 'number',
        width: 150,
        render: val => formatCurrency(val)
      },
    ]

    const addFields = [
      {
        dataIndex: 'cost',
        editable: true,
        required: false,
        title: 'Себестоимость',
        type: 'number',
        width: 150,
        render: val => formatCurrency(val)
      },
      {
        dataIndex: 'margin',
        required: false,
        title: 'Маржа',
        type: 'number',
        width: 150,
        render: (val, rec) => {
          return formatCurrency((rec?.price && rec?.cost) ? Number(rec.price) - Number(rec.cost) : 0)
        }
      }
    ]

    const eventFields = [{
      dataIndex: 'delete',
      title: <Button icon={<DeleteTwoTone/>} onClick={onDeleteRows} />,
      editable: true,
      required: false,
      width: 50,
      render: (_, data, index) => <>
        <Button icon={<DeleteTwoTone/>} onClick={() => onDeleteRow(data)} />
        <Button style={{ marginTop: 8 }} icon={<EditTwoTone />} onClick={() => editProduct(data, index)} />
      </>
    },{
      dataIndex: 'act-source',
      className: 'table-act-source',
      editable: true,
      required: false,
      title: <ButtonPush />,
      type: 'number',
      width: 80,
      render: (_, data) => <TableDatesActPrint data={data} stageCode={''} />
    }]

    const roles = getDataOfType(sessionData, 'roles', Array, [])
    const addAdditionalRows = ( roles.includes(19) || roles.includes(23) || roles.includes(28) )
    const finishColumns = addAdditionalRows ? columns.concat( addFields, eventFields ) : columns.concat( eventFields )

    return finishColumns
      .filter(({ dataIndex }) => {
        if (addAdditionalRows){
          return true
        }
        /*
        const isGo = getDataOfType(sessionData, 'roles', Array, []).includes(19)
        if (isGo) {
          return true
        }
        */
        if (['manufacture', 'delivery'].includes(cardName)) {
          return !['delete', 'unit-price', 'price'].includes(dataIndex)
        }
        if (['case', 'measurement'].includes(cardName)) {
          return !['delete', 'act-source'].includes(dataIndex)
        }
        if (['mounting'].includes(cardName)) {
          return !['delete'].includes(dataIndex)
        }
        const stages = ['prepayment', 'manufacture', 'delivery', 'mounting', 'audit', 'done']
        if (cardName === 'order' && stages.includes(stageCode)) {
          return !['delete'].includes(dataIndex)
        }
        return true
      })
  }, [cardName, stageCode, onDeleteRow, onDeleteRows, ButtonPush, sessionData])


  const onPrint = useCallback(() => {
    const id = targetId
    if (id === null || id === 0) {
      return
    }
    downloadUrl(`/api/print/excel/${id}`, `products-${id}.xlsx`)

  }, [targetId])

  const getData = useCallback(async targetId => {
    const response = await fetchAPI(`/api/collections/${name}/?parentId=${targetId}`)
    const first = getDataOfType(response, 'data.data[0]', Object, {})
    // setDateUpdateProducts(new Date(first?.['updated-at']).valueOf())
    idRef.current = getDataOfType(first, 'id', Number, null)
    const dataProto = getDataOfType(first, 'data.attributes.table', Array, [])
    setLocalData(dataProto)
    const source = getDataOfType(first, 'data.attributes', Object, {})
    const square = getDataOfType(first, 'data.attributes.square', Number, 0)
    const images = getDataOfType(first, 'data.attributes.images', Array, [])
    setSource(source)
    setSquare(square)
    setImages(images)
  }, [])

  /*
  const onSpecImport = useCallback(async () => {
    await getData(targetId)
    dispatch(setCalcDelivery({ isCalcDelivery: true }))
  }, [targetId, getData, dispatch])
  */

  const onPrintPdf = useCallback( async ({ key }) => {
    if (key === 'offerv2') {
      uploadFileRef.current.click()
      return
    }
    const result = await fetchAPI('/api/web-hooks/print-pdf', {
      method: 'POST',
      body: JSON.stringify({url: `/api/web-hooks/print-pdf-template?target-id=${targetId}&type=${key}`
    })})
    const url = getDataOfType(result, 'data.url', String, null)
    downloadUrl(url, `${key}-${targetId}.pdf`)
  }, [targetId])

  const onFileDone = useCallback(async ({ target }) => {
    const response = await fileUploadV2(target.files, targetId)
    console.log({ response })
    window.open( `/api/web-hooks/test-kp-print/${targetId}`)
    Object.assign(uploadFileRef.current, { value: null })
  }, [targetId])

  const printHidden = useMemo(() => {
    return true
  }, [])

  const addProduct = useCallback(async () => {
     const result = await modalAppoint()
     if (result === null) {
       return null
     }
     setLocalData(prevData => {
      const nextData = prevData.concat(result).sort((a, b) => a?.['number'] - b?.['number'])
      saveTable(nextData)
      return nextData
    })
    dispatch(reloadTablePayment())
  }, [saveTable, dispatch])

  const editProduct = useCallback(async (data, index) => {
    const result = await modalAppoint(data)
    if (result === null) {
      return null
    }
    setLocalData(prevData => {
    prevData[index] = result
     const nextData = prevData.concat().sort((a, b) => a?.['number'] - b?.['number'])
     saveTable(nextData)
     return nextData
   })
 }, [saveTable])

  useEffect(() => {
    if (targetId === null) {
      return
    }
    getData(targetId).then(r => console.log({ r }))
  }, [targetId, getData])

/*
  const saveSquare = useCallback(value=> {
    setSquare(value)
    save({ ...source, table: localData, 'square': value ?? 0, 'images': images })
  }, [save, source, localData, images])
*/

  const saveImages = useCallback(images => {
    setImages(images)
    save({...source, table: localData, 'square': square ?? 0, 'images': images}).then(r => console.log({ r }))
   }, [save, source, localData, square])

  const editDisabled = useMemo(() => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    if (roles.includes(19)) {
      return false
    }
    const stages = ['new', 'work', 'measurement']
    if (cardName === 'order' && stages.includes(stageCode)) {
      return false
    }
    return !(cardName === 'measurement' && ['measurer-arrived'].includes(stageCode))

  }, [cardName, stageCode, sessionData])

/*
  const onFucusOut = useCallback(async ()=> {
    dispatch(setCalcDelivery({ isCalcDelivery: true }))
    await fetchAPI('/api/web-hooks/calc-delivery', {
      method: 'POST',
      body: JSON.stringify({ 'target-id': targetId }),
    })
  }, [dispatch, targetId])

  const numberInputRef = useRef<any>(null)
  useEffect(() => {
    setTimeout(() => {
      //numberInputRef.current.onfocusout(onFucusOut)
    }, 2000)
  }, [onFucusOut])
  const updateProductsDisabled = useMemo(() => {
    const stages = ['new', 'work', 'measurement']
    if (cardName === 'order' && stages.includes(stageCode)) {
      return false
    }
    if (cardName === 'case' && ['new'].includes(stageCode)) {
      return false
    }
    return true
  }, [cardName, stageCode])

  const updateHelpProductsDisabled = useMemo(() => {
        if (Date.now() > dayjs(dateUpdateProducts).add(5, 'day').valueOf() ) {
      return true
    }
    const stages = ['new', 'work', 'measurement']
    if (cardName === 'order' && stages.includes(stageCode)) {
      return false
    }
    if (cardName === 'case' && ['new'].includes(stageCode)) {
      return false
    }
    return true
  }, [cardName, stageCode, dateUpdateProducts])

  const updateProductsPrice = useCallback(async () => {
    const result = await fetchAPI('/api/web-hooks/calculate-products-update-order', {
      method: 'POST',
      body: JSON.stringify({ 'target-id': targetId }),
    })
    const nextData = result?.['nextData']?.['attributes']?.['table'] || []
    setDateUpdateProducts(Date.now())
    if (Array.isArray(nextData) && nextData.length > 0) {
      saveTable(nextData)
      setLocalData(nextData)
    }
  }, [targetId, saveTable ])
*/
  return (
    <div>
      <ViewProducts images={images} onChange={saveImages} disabled={editDisabled} />
    <div>
      <Space style={{ marginBottom: 20 }}>
      {/*editDisabled || <ButtonImport value={undefined} onChange={onSpecImport}/>*/}
      {editDisabled || <Button onClick={addProduct}>
          Добавить изделие
      </Button>}
      {/*updateProductsDisabled || <Button onClick={updateProductsPrice}>
          Актуализировать цены на изделия
      </Button>*/}
      <input type="file" style={{ display: 'none' }} onChange={onFileDone} ref={uploadFileRef} accept=".zip"/>
      <Dropdown.Button overlay={
          <Menu onClick={onPrintPdf}>
          <Menu.Item key="invoice">Счет</Menu.Item>
          <Menu.Item key="offer">Коммерческое предложение</Menu.Item>
          <Menu.Item key="offerv2">Коммерческое предложение из файла</Menu.Item>
        </Menu>
      }>Печать</Dropdown.Button>
        {printHidden || <Button onClick={onPrint}>Экспорт в эксель</Button>}
        {/*updateHelpProductsDisabled && <span>Рекомендуем актуализировать цены</span>*/}
      </Space>
        <Table
          bordered
          dataSource={localData}
          columns={columns}
          pagination={false}
        />
      {/*
        <Space style={{ marginTop: 10 }}>
          <span>Габариты (м2):</span>
          <InputNumber ref={numberInputRef} onPressEnter={onFucusOut} value={square} onChange={saveSquare} UISchema={{ options: {disabled: editDisabled }}}  />
        </Space>
      */}
      </div>
      <Adaptor />
      <TablePayments targetId={targetId} />
    </div>
  )
}

export default EditableTable
