import {TimeTableSlots} from "../consts/timeTableSlots";

export const getSlotByTime = (keys, search) => {
    let min = 0;
    let max = keys.length - 1;

    if (TimeTableSlots[search]) {
        return search;
    }

    if (TimeTableSlots[keys[max]].end <= search) {
        return keys[max];
    }

    if (TimeTableSlots[keys[min]].start >= search) {
        return keys[min];
    }

    while (min <= max) {
        let index = Math.floor((min + max) / 2);

        if (TimeTableSlots[keys[index]].start < search && TimeTableSlots[keys[index]].end > search) {
            return keys[index];
        }

        if (TimeTableSlots[keys[index]].start < search && TimeTableSlots[keys[index]].end < search) {
            min = index + 1
        }

        if (TimeTableSlots[keys[index]].start > search && TimeTableSlots[keys[index]].end > search) {
            max = index - 1
        }
    }

    return keys[0];
}