import React, {memo, useCallback} from "react";
import {
    ExportExcelButton,
    format2Print,
    KeyPath
} from "../../components/v2/export-excel-button";
import {Enums} from "../../shared/types/EnumsType";
import fetchAPI from "../../../lib/utils/fetch-api";

export interface HrPrintButtonProps {
    path: string,
    columns: any[],
    enums: Enums,
    stages: any[],
}

export const HrPrintButton: React.FC<HrPrintButtonProps> = memo((props) => {
    const {path, columns, stages, enums} = props;

    const handlePrintClick = useCallback(async () => {
        try {
            const response = await fetchAPI(path);
            const data = response.data;
            if (!data) {
                return
            }

            const viewColumnNames = columns.map((column) => column.dataIndex);
            const count = data.length;

            for (let i = 0; i < count; i++) {
                let keys = Object.keys(data[i])
                keys.forEach((key) => {
                    if (!viewColumnNames.includes(key)) {
                        return;
                    }

                    if (key === 'stage') {
                        data[i][key] = stages.find((stage) => stage.code === data[i][key])?.name || data[i][key];
                        return;
                    }

                    if (key === 'department') {
                        data[i][key] = enums?.department ? enums.department.find(
                            department => department.value == data[i][key]
                        )?.label : data[i][key];
                        return;
                    }

                    if (key === 'created_by') {
                        data[i][key] = enums?.interview_responsible ? enums.interview_responsible.find(
                            responsible => responsible.value == data[i][key])?.label : data[i][key];
                        return;
                    }

                    if (key === 'cancel_reason') {
                        data[i][key] = enums?.cancel_reason ? enums.cancel_reason.find(
                            reason => reason.value == data[i][key])?.label : data[i][key];
                        return;
                    }


                    data[i][key] = format2Print(key, data[i][key]);
                })
            }

            return data
        } catch (e) {
            console.log('handlePrintClick', e)
        }
    }, [enums, columns, stages, path])


    return (
            <ExportExcelButton
                fileName={'Учет кандидатов'}
                path={path}
                columns={columns}
                onPrintClick={handlePrintClick}
            />
    )
})