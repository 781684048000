import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Table, Alert, Pagination } from 'antd'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import B2BLayout from 'src/react-app/layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import { afterDataColumns, beforeDataColumns } from 'src/react-app/components/list-operations/consts'
import { getStagesGroup } from 'src/react-app/pages/list-orders/utils'
import { calcTotalWidth, tableRowClick, getColumnsOfSchema, fetchSchema, listColumnsHidden, formatListData } from 'src/lib/utils/list'
import { PaginationWrapper } from './styles'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import OrderFilter from './orders-filter'
import './index.css'
import dayjs from 'dayjs'

const createSession = createSelector(
    (state: any) => state.session,
    sessionData => sessionData
)

const createViewed = createSelector(
  (state: any) => state.viewed,
  counts => counts
)

const name = 'card-froze'

export default function AdminObjectsListPage() {
  const location = useLocation()
  const history = useHistory()
  const orderFiltersRef = useRef<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  const [columns, setColumns] = useState<any[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [dataColumns, setDataColumns] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [page, setPage] = useState<number>(1)
  const { counts } = useSelector(createViewed)
  const breadcrumbs = useMemo(() => (
    [{
      href: '/frozes',
      title: 'Замеры',
    }, {
      href: location.pathname,
      title: 'Список замеров',
    }]
  ), [location.pathname])

  const { sessionData } = useSelector(createSession)
  const addDataColumns = useMemo(() => {
    const addDataColumns: any[] = [
        {
          title: "Дата обращения",
          dataIndex: "case-date",
          key: "case-date",
          width: 120,
          sorter: true
        },
        {
          title: "ФИО менеджера",
          dataIndex: "case-manager",
          key: "case-manager",
          width: 120,
          sorter: true
        },
        {
          title: "email менеджера",
          dataIndex: "email-manager",
          key: "email-manager",
          width: 120
        },
        {
          title: "Телефон менеджера",
          dataIndex: "phone-manager",
          key: "phone-manager",
          width: 120
        },
        {
          title: "Ответственный за замер",
          dataIndex: "measurer",
          key: "measurer",
          width: 120,
          sorter: true
        },
        {
          title: "Адрес офиса агента\\дилера",
          dataIndex: "address-office",
          key: "address-office",
          width: 120
        },
        {
          title: "Электронная почта клиента",
          dataIndex: "email",
          key: "email",
          width: 120
        },
        {
          title: "Тип клиента",
          dataIndex: "client-type",
          key: "client-type",
          width: 120,
          sorter: true
        },
        {
          title: "Название компании",
          dataIndex: "company-name",
          key: "company-name",
          width: 120
        },
        {
          title: "Вид деятельности",
          dataIndex: "kind-activity",
          key: "kind-activity",
          width: 120
        },
        {
          title: "Сайт",
          dataIndex: "website",
          key: "website",
          width: 120
        },
        {
          title: "Контактное лицо клиента",
          dataIndex: "name",
          key: "name",
          width: 120
        },
        {
          title: "Доп.лицо клиента",
          dataIndex: "decision-maker",
          key: "decision-maker",
          width: 120
        },
        {
          title: "Телефон клиента",
          dataIndex: "telephone",
          key: "telephone",
          width: 120
        },
        {
          title: "Доп.телефон клиента",
          dataIndex: "additional-contact",
          key: "additional-contact",
          width: 120
        },
        {
          title: "Мессенджер",
          dataIndex: "messenger",
          key: "messenger",
          width: 120
        },
        {
          title: "Страница в соцсетях",
          dataIndex: "page-social-networks",
          key: "page-social-networks",
          width: 120
        },
        {
          title: "Регион",
          dataIndex: "region",
          key: "region",
          width: 120,
          sorter: true
        },
        {
          title: "Город",
          dataIndex: "city",
          key: "city",
          width: 120,
          sorter: true
        },
        {
          title: "Адрес проживания",
          dataIndex: "address",
          key: "address",
          width: 120
        },
        {
          title: "Адрес объекта",
          dataIndex: "address-object",
          key: "address-object",
          width: 120
        },
        {
          title: "Вид рекламы",
          dataIndex: "source-appeal",
          key: "source-appeal",
          width: 120,
          sorter: true
        },
        {
          title: "Сумма заказа",
          dataIndex: "order-price",
          key: "order-price",
          width: 120,
          sorter: true,
          render: item => item??0
        },
        {
          title: "Сумма оплаты",
          dataIndex: "order-payment",
          key: "order-payment",
          width: 120,
          sorter: true,
          render: item => item??0
        },
        {
          title: "Признак заказа",
          dataIndex: "order-attribute",
          key: "order-attribute",
          width: 120
        },
        {
          title: "Скан спецификации",
          dataIndex: "scan-spec-products",
          key: "scan-spec-products",
          width: 120
        },
        {
          title: "Номер производственного заказа",
          dataIndex: "production-order-number",
          key: "production-order-number",
          width: 120
        },
        {
          title: "Тип доставки",
          dataIndex: "type-delivery",
          key: "type-delivery",
          width: 120,
          sorter: true
        },
        {
          title: "Тип машины",
          dataIndex: "type-car",
          key: "type-car",
          width: 120,
          sorter: true
        },
        {
          title: "Номер машины",
          dataIndex: "number-car",
          key: "number-car",
          width: 120
        },
        {
          title: "Тариф",
          dataIndex: "tariff",
          key: "tariff",
          width: 120,
          sorter: true
        },
        {
          title: "Доплата за доп.работы",
          dataIndex: "payment-additional-work",
          key: "payment-additional-work",
          width: 120
        },
        {
          title: "Штраф",
          dataIndex: "fine",
          key: "fine",
          width: 120,
          sorter: true
        },
        {
          title: "Номер договора с конечным клиентом",
          dataIndex: "number-contract",
          key: "number-contract",
          width: 120,
          sorter: true
        },
        {
          title: "Скан договора",
          dataIndex: "contract-scan",
          key: "contract-scan",
          width: 120
        },
        {
          title: "Скан замера",
          dataIndex: "measurement-scan",
          key: "measurement-scan",
          width: 120
        },
        {
          title: "Скан чека",
          dataIndex: "check-scan",
          key: "check-scan",
          width: 120
        },
        {
          title: "Дата замера (План)",
          dataIndex: "date-measurement",
          key: "date-measurement",
          width: 120,
          sorter: true
        },
        {
          title: "Время замера (План)",
          dataIndex: "time-measurement",
          key: "time-measurement",
          width: 120,
          sorter: true
        },
        {
          title: "Дата замера (Скор.)",
          dataIndex: "date-adjusted-measurement",
          key: "date-adjusted-measurement",
          width: 120,
          sorter: true
        },
        {
          title: "Время замера (Скор.)",
          dataIndex: "time-adjusted-measurement",
          key: "time-adjusted-measurement",
          width: 120,
          sorter: true
        },
        {
          title: "Дата изготовления (Скор.)",
          dataIndex: "date-adjusted-manufacture",
          key: "date-adjusted-manufacture",
          width: 120,
          sorter: true
        },
        {
          title: "Время изготовления (Скор.)",
          dataIndex: "time-adjusted-manufacture",
          key: "time-adjusted-manufacture",
          width: 120,
          sorter: true
        },
        {
          title: "Дата доставки (Скор.)",
          dataIndex: "date-adjusted-delivery",
          key: "date-adjusted-delivery",
          width: 120,
          sorter: true
        },
        {
          title: "Время доставки (Скор.)",
          dataIndex: "time-adjusted-delivery",
          key: "time-adjusted-delivery",
          width: 120,
          sorter: true
        },
        {
          title: "Дата монтажа (Скор.)",
          dataIndex: "date-adjusted-mounting",
          key: "date-adjusted-mounting",
          width: 120,
          sorter: true
        },
        {
          title: "Время монтажа (Скор.)",
          dataIndex: "time-adjusted-mounting",
          key: "time-adjusted-mounting",
          width: 120,
          sorter: true
        },
        {
          title: "Дата аудита (Скор.)",
          dataIndex: "date-adjusted-audit",
          key: "date-adjusted-audit",
          width: 120,
          sorter: true
        },
        {
          title: "Время аудита (Скор.)",
          dataIndex: "time-adjusted-audit",
          key: "time-adjusted-audit",
          width: 120,
          sorter: true
        },
        {
          title: "Дата замера (факт)",
          dataIndex: "date-measurement-fact",
          key: "date-measurement-fact",
          width: 120,
          sorter: true
        },
        {
          title: "Дата приезда",
          dataIndex: "date-arrival",
          key: "date-arrival",
          width: 120,
          sorter: true
        },
        {
          title: "Дата выезда",
          dataIndex: "date-departure",
          key: "date-departure",
          width: 120,
          sorter: true
        },
        {
          title: "Дата договора",
          dataIndex: "date-contract",
          key: "date-contract",
          width: 120,
          sorter: true
        },
        {
          title: "Лента событий",
          dataIndex: "event-ribbon",
          key: "event-ribbon",
          width: 120
        }
      ]
      if (getDataOfType(sessionData, 'organization.id', Number, null) === 1) {
        return [{
          title: 'Организация',
          width: 200,
          dataIndex: 'organizationTitle',
          key: 'organizationTitle',
          sorter: true,
          sortOrder: orderFiltersRef.current.field === 'organizationTitle' && orderFiltersRef.current.order,
        }].concat(addDataColumns)
      }
    return addDataColumns;
  }, [sessionData, orderFiltersRef.current]);

  const totalWidth: any = useMemo(() => calcTotalWidth(beforeDataColumns, addDataColumns, afterDataColumns), [addDataColumns])

  // @ts-ignore
  const cbTableRowClick = useCallback(({ id }) => history.push(`/froze/${id}/edit`), [history])

  const deleteRecord = useCallback(async (currentId) => {
    try{
      await fetchAPI(`/api/collections/objects/${currentId}`, { method: 'DELETE'})
      setDataSource(dataSource => dataSource.filter(({ id }) => id !== currentId))
    }catch(error){
      console.error(error)
    }
  },[])

  const getData = useCallback(async (value, addUrlParams: object = {}) => {
    let urlParams = new URLSearchParams({...value, ...addUrlParams}).toString()

    history.push({
      search: urlParams
    })

    const pageErrors: string[] = []
    const [selectedSchema, metadata, collections, stages] = await Promise.all([
      fetchSchema({ name }),
      fetchAPI(`/api/collections/${name}/metadata`),
      fetchAPI(`/api/collections-case/${name}?access_key=axioma&${urlParams}`),
      getStagesGroup('measurement')
    ])
    const columnsHidden = getDataOfType(metadata, 'data.columnsHidden', Array, [])
    const collectionsResultData = getDataOfType(collections, 'data.data', Array, [])
    const isMetadata = getDataOfType(metadata, 'data', Object, null)
    if(isMetadata === null) {
      pageErrors.push('Некорректный ответ сервера при получении метаданных коллекции')
    }
    const dataSource: any[] = formatListData(collectionsResultData, selectedSchema)
    const newDataSource = dataSource.map(item => ({
      ...item,
      deleteRecord,
      stages,
    }))
    const preparedDataColumns: any[] = getColumnsOfSchema(selectedSchema)
    const datalistColumnsHidden = listColumnsHidden(preparedDataColumns, columnsHidden)
    const newDataColumns = datalistColumnsHidden.map(column => ({
        ...column,
        width: 120,
        /*render: (text, { id, schema }) => (
        <EditCard id={id} schema={schema} title="Редактирование">
         {FieldHideText({ text })}
        </EditCard>
        )*/}
    ))
    const totalRows = getDataOfType(collections, 'data.total-rows', Number, 0)
    setTotalRows(totalRows)
    setDataColumns(newDataColumns)
    setDataSource(newDataSource)
    setPageErrors(pageErrors)
    setIsDataLoading(false)
  }, [deleteRecord])

  const onPagination = useCallback(async ( page, pageSize) => {
    setPage(page)
    const urlParams = {
      page: page,
      pageSize: pageSize
    }
    setIsDataLoading(true)
    await getData(orderFiltersRef.current, urlParams)
    setIsDataLoading(false)
  }, [getData])

  const onChangeSort = useCallback(async (pagination, filters, sorter) => {
    setPage(1)
    setIsDataLoading(true)

    if(sorter.order && typeof sorter.order !== 'undefined'){
      orderFiltersRef.current.field = sorter.field;
      orderFiltersRef.current.order = sorter.order;
    }else{
      delete orderFiltersRef.current.field;
      delete orderFiltersRef.current.order;
    }

    orderFiltersRef.current.page = page;
    orderFiltersRef.current.pageSize = 10;

    addDataColumns.forEach( col => {
      if( col.key === orderFiltersRef.current.field || col.dataIndex === orderFiltersRef.current.field){
        col.sortOrder = orderFiltersRef.current.order
      }else{
        delete col.sortOrder
      }
    })
    const columns = beforeDataColumns.concat(addDataColumns, afterDataColumns)

    setColumns( columns )
    await getData(orderFiltersRef.current, {})
    setIsDataLoading(false)
  }, [getData, orderFiltersRef.current])

  useEffect(() => {
    const urlParams = Object.fromEntries(new URLSearchParams(location.search))

    if(urlParams.page) {
      setPage(parseInt(urlParams.page))
    }

    orderFiltersRef.current = Object.fromEntries(Object.entries(urlParams).filter(([key, value]) => key !== 'page' && key !== 'pageSize'))

    addDataColumns.forEach( col => {
      if( col.key === orderFiltersRef.current.field || col.dataIndex === orderFiltersRef.current.field){
        col.sortOrder = orderFiltersRef.current.order
      }
    })
    const columns = beforeDataColumns.concat(addDataColumns, afterDataColumns)
    setColumns( columns )

    getData(orderFiltersRef.current, urlParams)
  }, [counts, getData])

  const rowClassName = useCallback(record => {
    const currentDate = dayjs().from('DD.MM.YYYY')
    const date = dayjs(getDataOfType(record, 'data.attributes.date-measurement-fact', String, null)).from('DD.MM.YYYY')
    if (currentDate === date) {
      return 'table-row-dark'
    }
    return record['stage'] === 0 ? 'table-row-dark' :  'table-row-light'
  }, [])

  const onOrderFilter = useCallback(async value => {
    orderFiltersRef.current = JSON.parse(JSON.stringify(value))
    setPage(1)
    setIsDataLoading(true)
    await getData(orderFiltersRef.current, {})
    setIsDataLoading(false)
  }, [getData])



  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {(pageErrors.length > 0) ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
            />
        </div>
      ): null}
      <OrderFilter onChange={onOrderFilter}/>
      <Table
        showSorterTooltip={{overlay: "Нажмите для сортировки", placement: 'bottom'}}
        columns={columns}
        rowClassName={rowClassName}
        size="small"
        dataSource={dataSource}
        scroll={{ x: totalWidth, y: '72vh' }}
        pagination={false}
        loading={isDataLoading}
        onChange={onChangeSort}
        onHeaderRow={(column: any) => ({
          style: {
            fontSize: (column.key && column.key.substr(-3) === '-at') ? 9 : 13,
          }
        })}
        onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick)})}
        rowKey="id"
        />
      {Boolean(totalRows) && <PaginationWrapper>
        <Pagination defaultCurrent={1} current={page} pageSize={10} total={totalRows} onChange={onPagination} showSizeChanger={false} />
      </PaginationWrapper>}
    </B2BLayout>
  )
}
