import React, {useCallback, useContext, useMemo, useState} from 'react'
import {Button, Space, Timeline, Tooltip} from 'antd'
import {formatDate} from './activity-timeline-Item'
import moment from 'moment'
import dayjs from 'dayjs'
import {CheckSquareOutlined} from '@ant-design/icons'
import {getDataOfType} from 'src/lib/utils/get-data-of-type'
import ActivityTimelineContext from './activity-timeline-context'
import {
  Avatar,
  DotStyle,
  NotLinkButton,
  TaskButtonCloseWrapper,
  TimeLineContentHeaderWrapper,
  TimeLineContentWrapper
} from './styles'
import {modalOpen} from './activity-timeline-task-modal'
import fetchAPI from 'src/lib/utils/fetch-api'

const urlAvatar = 'https://bipbap.ru/wp-content/uploads/2017/05/VOLKI-krasivye-i-ochen-umnye-zhivotnye.jpg'

function getStatusTask(stageCode, date) {
  if (stageCode === 'done') {
    return 'done'
  }
  const currentDate = Date.now()
  if (new Date(date).valueOf() > currentDate) {
    return 'not-expired-and-not-done'
  }
  return 'expired-and-not-done'
}

function getTaskBackgroundColor(stageCode, date) {
  const status = getStatusTask(stageCode, date)
  if (status === 'done') {
    return '#40a9ff63'
  }
  if (status === 'not-expired-and-not-done') {
    return '#f4f9ee'
  }
  return '#ffe8e8'
}

function getTextByStageCode(code) {
  if (code === 'new') {
    return 'Новая'
  }
  if (code === 'work') {
    return 'В работе'
  }
  return 'Завершена'
}

function ActivityTimelineTask({type, body, date, id}: any) {
  const contextProps = useContext(ActivityTimelineContext)
  const stageName = useMemo(() => {
    return getTextByStageCode(body['stage-code'])
  }, [body])

  const [commentDoneLocal, setCommentDoneLocal] = useState<string | null>(null)
  const dateFormat = useMemo(() => formatDate(date), [date])

  const dateCompletion = useMemo(() => {
    return getDataOfType(body, 'data.attributes.date-completion', String, '')
  }, [body])

  const isExpired = useMemo(() => {
    const currentDate = moment().valueOf()
    if (date >= currentDate) {
      return ''
    }
    const dateCompletionFormat = dateCompletion && moment(dateCompletion).valueOf()
    return !Boolean(dateCompletionFormat) || (moment(date).valueOf() <= dateCompletionFormat)
  }, [date, dateCompletion])

  const dateExpired = useMemo(() => {
    return dayjs(date).to(dateCompletion || dayjs(), true)
  }, [dateCompletion, date])

  const comment = getDataOfType(body, 'data.attributes.comment', String, '')
  const commentDone = useMemo(() => {
    const commentDone = getDataOfType(body, 'data.attributes.comment-done', String, null)
    return commentDone || commentDoneLocal
  }, [body, commentDoneLocal])

  const stageCode = useMemo(() => body['stage-code'], [body])

  const dateCompletionFormat = dateCompletion && formatDate(dateCompletion)
  const responsible = useMemo(() => {
    const {usersProfile} = contextProps[type]
    return getDataOfType(body, 'data.attributes.responsible', Array, [])
      .map(userId => {
        const item = usersProfile.find(({id}) => String(userId) === String(id))
        return (
          (getDataOfType(item, 'profile-data.name', String, '') || getDataOfType(item, 'name', String, '')) + ` (ID: ${userId})`
        )
      })
      .join(', ')
  }, [contextProps, type, body])

  const author = useMemo(() => {
    const {usersProfile} = contextProps[type]
    const authorId = getDataOfType(body, 'data.attributes.author', [Number, String], 0)
    const item = usersProfile.find(({id}) => String(authorId) === String(id))
    return (getDataOfType(item, 'profile-data.name', String, '') || getDataOfType(item, 'name', String, '')) + ` (ID: ${authorId})`
  }, [contextProps, type, body])

  const cardHref = useMemo(() => {
    const href = getDataOfType(body, 'data.attributes.a-href', String, null)
    const text = getDataOfType(body, 'data.attributes.a-text', String, null)
    if (href === null || text === null) {
      return null
    }
    return {href, text}
  }, [body])

  const taskType = useMemo(() => {
    return getDataOfType(body, 'type', String, null)
  }, [body])

  const currentDate = moment().valueOf()
  const isCurrentDateLess = date <= currentDate
  const dotColor = isCurrentDateLess ? '#2fc4f5' : '#97c807'
  const backgroundColor = getTaskBackgroundColor(stageCode, date)

  const onTaskClose = useCallback(async () => {
    const result: any = await modalOpen()
    const content = result?.content
    if (!Boolean(content)) {
      return
    }
    setCommentDoneLocal(content)

    fetchAPI('/api/web-hooks/set-task', {
      method: 'POST', body: JSON.stringify({
        'taskId': id,
        'stageCode': 'done',
        'formData': {'comment-done': content}
      })
    })
  }, [id])

  const isButtonClose = useMemo(() => {
    const tag = getDataOfType(body, 'data.attributes.tag', String, null)
    const stageCode = getDataOfType(body, 'stage-code', String, 'done')
    return !(tag || commentDone || stageCode === 'done');
  }, [body, commentDone])

  return (
    <Timeline.Item
      dot={
        <DotStyle color={dotColor}>
          <CheckSquareOutlined/>
        </DotStyle>
      }
    >
      <TimeLineContentWrapper color={backgroundColor}>
        <TimeLineContentHeaderWrapper>
          <Space>
            <NotLinkButton>Задача</NotLinkButton>
            <span style={{color: '#a8adb4'}}>{dateFormat}</span>
          </Space>
          <Space>
            <Avatar src={urlAvatar}/>
            <Tooltip placement="top" title="Автор задачи"><span>{author}</span></Tooltip>
          </Space>
        </TimeLineContentHeaderWrapper>
        {(isExpired) && (
          <span
            style={{color: '#ca0808', fontWeight: 'bold'}}
          >{`Просрочена: на ${dateExpired}`}</span>
        )}
        {responsible && (
          <Space>
            <span>Ответственные:</span>
            <span style={{color: '#a8adb4'}}>{responsible}</span>
          </Space>
        )}
        <Space>
          <span>Стадия:</span>
          <span style={{color: '#a8adb4'}}>{stageName}</span>
        </Space>
        <Space>
          <span>Тип:</span>
          <span style={{color: '#a8adb4'}}>{taskType}</span>
        </Space>
        {(cardHref) && (
          cardHref.text
        )}
        <span>{comment}</span>
        {dateCompletionFormat && <span>{`Дата завершения: ${dateCompletionFormat}`}</span>}
        {commentDone && <span>{`Причина завершения: ${commentDone}`}</span>}
        {isButtonClose && (<TaskButtonCloseWrapper>
          <Button onClick={onTaskClose} size={'small'}> Завершить задачу</Button>
        </TaskButtonCloseWrapper>)}
      </TimeLineContentWrapper>
    </Timeline.Item>
  )
}

export default ActivityTimelineTask


    
