import React, {useEffect, useState, useMemo, useCallback} from 'react'
import {Table} from 'antd'
import fetchAPI from '../../../../../lib/utils/fetch-api'
import {getDataOfType} from '../../../../../lib/utils/get-data-of-type'
import dayjs from 'dayjs'
import {DarkLink} from '../../../../components/dark-link'

function getStageFromData(code) {
  const stages = {
    'new': 'Новая',
    'work': 'Консультация',
    'prepayment': 'Предоплата',
    'measurement': 'Замер',
    'manufacture': 'Производство',
    'delivery': 'Доставка',
    'mounting': 'Монтаж',
    'audit': 'Аудит',
    'done': 'Выполнено',
    'cancel': 'Отменено'
  }

  return stages[code]
}


function CellId({id, schema}) {
  const url = useMemo(() => {
    if (schema === 'appeal') {
      return `/case/${id}/edit`
    }
    if (schema === 'case-order') {
      return `/order/${id}/edit`
    }
    return ''
  }, [schema, id])

  return <DarkLink to={url}>{id}</DarkLink>
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (id, {schema}) => <CellId id={id} schema={schema}/>,
  },
  {
    title: 'Дата создания',
    dataIndex: 'date',
    key: 'date',
    render: text => dayjs(text).format('DD.MM.YYYY HH:mm'),
  },
  {
    title: 'Сумма',
    dataIndex: 'sum',
    key: 'sum',
    render: text => text,
  },
  {
    title: 'Стадия',
    dataIndex: 'stage_code',
    key: 'stage_code',
    render: data => getStageFromData(data),
  },
]

type TablePaginationPosition = 'bottomCenter'

function TabOrderList({clientId}) {
  const [data, setData] = useState([])
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 15,
    total: 10,
    position: [bottomCenter],
    showSizeChanger: false
  })

  const updatePagination = useCallback(newState => {
    setPagination({
      ...pagination,
      ...newState
    })
  }, [pagination, setPagination])

  const handleTableChange = (pagination) => {
    getData(pagination.current)
  }

  const getData = useCallback(async (page) => {
    try {
      const result = await fetchAPI(`/api/get-orders-list/${clientId}?page=${page}&page_size=15`)

      const array = getDataOfType(result, 'data', Array, [])
      const pagination = getDataOfType(result, 'pagination', Object, [])
      const data = array.map((item, index) => ({
        key: index,
        id: item.id,
        date: item.created_at,
        sum: item.sum,
        stage_code: item.stage_code,
      }))
      setData(data)
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [clientId])

  useEffect(() => {
    getData(1)
  }, [getData])

  return <Table dataSource={data} columns={columns} pagination={pagination} onChange={handleTableChange} key='id'/>
}

export default TabOrderList
