export const collectionsName = 'card-delivery'

export const deliverySortList = ['new', 'crew-assigned', 'accepted-driver', 'work', 'delivered-client', 'other']

export const deliverySortData: Record<string, any[]> = {
  new: [],
  'crew-assigned': [],
  'accepted-driver': [],
  work: [],
  'delivered-client': [],
  other: [],
}
