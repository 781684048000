import {ITaskCalendarFilter} from "./interfaces";
import {setCalendarData, setCalendarDataLoading, setCalendarFailed} from "./actions";
import fetchAPI from "../../../lib/utils/fetch-api";

export function getTasksListToCalendar(filter?: ITaskCalendarFilter) {
    return async (dispatch) => {
        dispatch(setCalendarDataLoading());
        try {
            //@ts-ignore
            const params = new URLSearchParams(filter);
            params.set('page_size', '100')
            const response = await fetchAPI(`/api/v2/tasks?${params.toString()}`) || [];

            if (response['errors']) {
                let error = response['errors'];
                if (Array.isArray(error)) {
                    error = error.join();
                }
                return dispatch(setCalendarFailed(error));
            }

            return dispatch(setCalendarData(response.data));
        } catch (err) {
            return dispatch(setCalendarFailed(err as string));
        }
    }
}