import React, {memo, useCallback, useRef, useState} from "react";
import {isFormDataRequired} from "../../../../../../../lib/utils/collections";
import {Button, Modal} from "antd";
import {nextStage} from "../../../../../../store/hr/action-creators";
import {HrModal} from "../../hr-modal";
import {useDispatch, useSelector} from "react-redux";
import {getVisibleCancelModal} from "../../../selectors";

interface HrCancelModalProps {
    className?: string
    id: string | number,
    isDisabled?: boolean
}


export const HrCancelModal: React.FC<HrCancelModalProps> = memo( (props: HrCancelModalProps) => {
    const {id, isDisabled} = props;
    const dispatch = useDispatch();
    const visibleCancelModal = useSelector(getVisibleCancelModal);
    const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
    const formRef = useRef({...visibleCancelModal})

    const onFormDataModal = useCallback(
        async ({formData}) => {
            if (!formRef.current) {
                return;
            }
            const isFilesEdit = JSON.stringify(formData) === JSON.stringify(formRef.current?.fields);
            if (isFilesEdit) {
                return;
            }
            formRef.current.fields = {...formData}
        }, [formRef]
    )

    const handleOkCancelModal = useCallback(async () => {
        if (!formRef.current) {
            return;
        }
        const formData = {...formRef.current?.fields}
        const isRequiredFields = isFormDataRequired(formData, formRef.current.schema)

        if (!isRequiredFields) {
            Modal.warning({
                title: 'Предупреждение',
                content: 'Заполните обязательные поля'
            })
            return
        }
        dispatch(nextStage(id, formData));
        setIsOpenCancelModal(false);
    }, [setIsOpenCancelModal, formRef]);


    const handleCloseCancelModal = useCallback(() => {
        setIsOpenCancelModal(false);
    }, [setIsOpenCancelModal]);

    const handleOnClickCancelButton = useCallback(() => {
        setIsOpenCancelModal(true);
    }, [setIsOpenCancelModal]);

    return (
        <>
            <Button onClick={handleOnClickCancelButton}
                    style={{marginBottom: 16, marginTop: 16}}
                    type="default"
                    danger>
                {visibleCancelModal && visibleCancelModal?.title}
            </Button>
            {
                visibleCancelModal && (
                    visibleCancelModal?.fields && (
                        <HrModal
                            schema={visibleCancelModal?.schema}
                            onChange={onFormDataModal}
                            formData={visibleCancelModal.fields}
                            isOpen={isOpenCancelModal}
                            onCancel={handleCloseCancelModal}
                            onOk={handleOkCancelModal}
                            centered={true}
                            disabled={isDisabled}
                            title={visibleCancelModal?.title}
                        />
                    )
                )
            }
        </>
    )
})