import React, { FC, useCallback, useMemo } from 'react';
import Card from 'antd-mobile/lib/card';
import WhiteSpace from 'antd-mobile/lib/white-space';

import { FieldGroup, FieldName, PhoneButton } from './styles'
import { IClientCardProps } from './interface'
import { PhoneIcon } from '../../../../assets/icons'

export const ClientCard: FC<IClientCardProps> = props => {
  const { onCall, data } = props
  const {
    name,
    telephone,
    'address-object': addressObject,
    'additional-contact': additionalPhone,
    'decision-maker': additionalName
  } = data

  const haveAdditionalContact = useMemo(() => {
    return additionalPhone || additionalName
  }, [additionalName, additionalPhone])

  const city = useMemo(() => {
    return props?.['data']?.['city'] ?? ''
  }, [props]) 

  const CardItem = useCallback((props) => {
      return (
        <Card>
          <Card.Header
            title={props['title']}
            extra="Контакты"
          />
          <Card.Body>
            <FieldGroup>
              <FieldName>Имя:</FieldName>
              {props['name']}
            </FieldGroup>
            <FieldGroup>
              <FieldName>
                Телефон:
              </FieldName>
              <PhoneButton
                href={`tel:${props['telephone']}`}
                onClick={onCall(props['telephone'])}
              >
                {props['telephone']}&nbsp;<PhoneIcon />
              </PhoneButton>
            </FieldGroup>
            <FieldGroup>
              <FieldName>Адрес:</FieldName>
              {`${city}, ${props['addressObject']}` || '- не указан -'}
            </FieldGroup>
          </Card.Body>
        </Card>
      )
  }, [onCall, city])

  return (
    <>
      <CardItem title="Клиент" name={name} telephone={telephone} addressObject={addressObject} />
      <WhiteSpace size="lg" />
      {haveAdditionalContact && (
        <>
          <CardItem title="Доп.контакт" name={additionalName} telephone={additionalPhone} addressObject={addressObject} />
          <WhiteSpace size="lg" />
        </>
      )}
    </>
  )
}
