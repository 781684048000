import React, { Component, PureComponent } from 'react'
import { Dropdown, Menu, Row, Col, Select, message, Tooltip } from 'antd'
import CollectionSelect from './CollectionSelect'
import {
  CaretDownOutlined,
  CaretRightOutlined,
  SettingOutlined,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import './schemaJson.css'
import _ from 'underscore'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { JSONPATH_JOIN_CHAR, SCHEMA_TYPE, defaultSchema } from '../../utils.js'
import LocaleProvider from '../LocalProvider/index.js'
import MockSelect from '../MockSelect/index.js'
import InputTitle from './InputTitle'
import InputDescription from './InputDescription'

const keysDefaultSchema = Object.keys(defaultSchema)
const valuesDefaultSchema = Object.values(defaultSchema)

function convertCurrenType(current) {
  if (current.type === 'array' && current?.items?.type === 'object' && current?.format === 'table') {
    const index = valuesDefaultSchema.findIndex(({ items }) => items?.type === 'object')
    return keysDefaultSchema[index]
  }
  if (current.type !== 'string') {
    return current.type
  }
  if (('format' in current) === false) {
    return current.type
  }
  const index = valuesDefaultSchema.findIndex(({ format }) => current.format === format)
  return keysDefaultSchema[index]
}

const Option = Select.Option
const mapping = (name, data, showEdit, showAdv) => {
  if (data.type === 'array' && data?.items?.type === 'object') {
    return <SchemaArray prefix={name} data={data} showEdit={showEdit} showAdv={showAdv} className="items-type-object" />
  }
  if (data.type === 'array') {
    return <SchemaArray prefix={name} data={data} showEdit={showEdit} showAdv={showAdv} />
  }
  if (data.type === 'object') {
    const nameArray = [].concat(name, 'properties')
    return <SchemaObject prefix={nameArray} data={data} showEdit={showEdit} showAdv={showAdv} />
  }
  return null
}

class SchemaArray extends PureComponent {
  constructor(props, context) {
    super(props)
    this._tagPaddingLeftStyle = {}
    this.Model = context.Model.schema
  }

  componentWillMount() {
    const { prefix } = this.props
    let length = prefix.filter(name => name !== 'properties').length
    this.__tagPaddingLeftStyle = {
      paddingLeft: `${20 * (length + 1)}px`,
    }
  }

  getPrefix() {
    return [].concat(this.props.prefix, 'items')
  }

  // 修改数据类型
  handleChangeType = value => {
    let prefix = this.getPrefix()
    let key = [].concat(prefix, 'type')
    this.Model.changeTypeAction({ key, value })
  }

  // 修改备注信息
  handleChangeDesc = value => {
    let prefix = this.getPrefix()
    let key = [].concat(prefix, `description`)
    //let value = e.target.value
    this.Model.changeValueAction({ key, value })
  }

  // 修改mock信息
  handleChangeMock = e => {
    let prefix = this.getPrefix()
    let key = [].concat(prefix, `mock`)
    let value = e ? { mock: e } : ''
    this.Model.changeValueAction({ key, value })
  }

  handleChangeTitle = value => {
    let prefix = this.getPrefix()
    let key = [].concat(prefix, `title`)
    //let value = e.target.value
    this.Model.changeValueAction({ key, value })
  }

  // 增加子节点
  handleAddChildField = () => {
    let prefix = this.getPrefix()
    let keyArr = [].concat(prefix, 'properties')
    this.Model.addChildFieldAction({ key: keyArr })
    this.Model.setOpenValueAction({ key: keyArr, value: true })
  }

  handleClickIcon = () => {
    let prefix = this.getPrefix()
    // 数据存储在 properties.name.properties下
    let keyArr = [].concat(prefix, 'properties')
    this.Model.setOpenValueAction({ key: keyArr })
  }

  handleShowEdit = (name, type) => {
    let prefix = this.getPrefix()
    this.props.showEdit(prefix, name, this.props.data.items[name], type)
  }

  handleShowAdv = () => {
    this.props.showAdv(this.getPrefix(), this.props.data.items)
  }

  render() {
    const { data, prefix, showEdit, showAdv, className = '' } = this.props
    const items = data.items
    let prefixArray = [].concat(prefix, 'items')
    const currentSelectValue = convertCurrenType(items)
    let prefixArrayStr = [].concat(prefixArray, 'properties').join(JSONPATH_JOIN_CHAR)
    let showIcon = this.context.getOpenValue([prefixArrayStr])
    return (
      !_.isUndefined(data.items) && (
        <div className={`array-type ${className}`}>
          <Row className="array-item-type" type="flex" justify="space-around" align="middle">
            <Col
              span={8}
              className="col-item name-item col-item-name"
              style={this.__tagPaddingLeftStyle}
            >
              <Row type="flex" justify="space-around" align="middle">
                <Col span={2} className="down-style-col">
                  {items.type === 'object' ? (
                    <span className="down-style" onClick={this.handleClickIcon}>
                      {showIcon ? <CaretDownOutlined /> : <CaretRightOutlined />}
                    </span>
                  ) : null}
                </Col>
                <Col span={22}>
                  <InputTitle
                    placeholder={LocaleProvider('title')}
                    value={items.title}
                    onChange={this.handleChangeTitle}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={4} className="col-item col-item-type">
              <Select
                name="itemtype"
                className="type-select-style"
                onChange={this.handleChangeType}
                value={currentSelectValue}
              >
                {SCHEMA_TYPE.map((item, index) => {
                  return (
                    <Option value={item.type} key={index}>
                      {item.title}
                    </Option>
                  )
                })}
              </Select>
            </Col>
            {this.context.isMock && (
              <Col span={4} className="col-item col-item-mock">
                <MockSelect
                  schema={items}
                  showEdit={() => this.handleShowEdit('mock', items.type)}
                  onChange={this.handleChangeMock}
                />
              </Col>
            )}
            <Col span={this.context.isMock ? 4 : 5} className="col-item col-item-desc">
              <InputDescription
                handleShowEdit={() => this.handleShowEdit('description')}
                placeholder={LocaleProvider('description')}
                value={items.description}
                onChange={this.handleChangeDesc}
              />
            </Col>
            <Col span={this.context.isMock ? 2 : 3} className="col-item col-item-setting">
              <span className="adv-set" onClick={this.handleShowAdv}>
                <Tooltip placement="top" title={LocaleProvider('adv_setting')}>
                  <SettingOutlined />
                </Tooltip>
              </span>
              {items.type === 'object' ? (
                <span onClick={this.handleAddChildField}>
                  <Tooltip placement="top" title={LocaleProvider('add_child_node')}>
                    <PlusOutlined />
                  </Tooltip>
                </span>
              ) : null}
            </Col>
            <Col span={this.context.isMock ? 3 : 4} className="col-item col-item-mock"></Col>
          </Row>
          <div className="option-formStyle">{mapping(prefixArray, items, showEdit, showAdv)}</div>
        </div>
      )
    )
  }
}

SchemaArray.contextTypes = {
  getOpenValue: PropTypes.func,
  Model: PropTypes.object,
  isMock: PropTypes.bool,
}

class SchemaItem extends PureComponent {
  constructor(props, context) {
    super(props)
    this._tagPaddingLeftStyle = {}
    // this.num = 0
    this.Model = context.Model.schema
  }

  componentWillMount() {
    const { prefix } = this.props
    let length = prefix.filter(name => name !== 'properties').length
    this.__tagPaddingLeftStyle = {
      paddingLeft: `${20 * (length + 1)}px`,
    }
  }

  getPrefix() {
    return [].concat(this.props.prefix, this.props.name)
  }

  // 修改节点字段名
  handleChangeName = e => {
    const { data, prefix, name } = this.props
    let value = e.target.value
    if (data.properties[value] && typeof data.properties[value] === 'object') {
      return message.error(`The field "${value}" already exists.`)
    }
    this.Model.changeNameAction({ value, prefix, name })
  }

  // 修改备注信息
  handleChangeDesc = value => {
    let prefix = this.getPrefix()
    let key = [].concat(prefix, 'description')
    //let value = e.target.value
    this.Model.changeValueAction({ key, value })
  }

  // 修改mock 信息
  handleChangeMock = e => {
    let prefix = this.getPrefix()
    let key = [].concat(prefix, `mock`)
    let value = e ? { mock: e } : ''
    this.Model.changeValueAction({ key, value })
  }

  handleChangeTitle = value => {
    let prefix = this.getPrefix()
    let key = [].concat(prefix, `title`)
    //let value = e.target.value 
    this.Model.changeValueAction({ key, value })
  }

  // 修改数据类型
  handleChangeType = value => {
    let prefix = this.getPrefix()
    let key = [].concat(prefix, 'type')
    this.Model.changeTypeAction({ key, value })
  }

  // 删除节点
  handleDeleteItem = e => {
    const { prefix, name } = this.props
    let nameArray = this.getPrefix()
    this.Model.deleteItemAction({ key: nameArray })
    this.Model.enableRequireAction({ prefix, name, required: false })
  }
  /*
  展示备注编辑弹窗
  editorName: 弹窗名称 ['description', 'mock']
  type: 如果当前字段是object || array showEdit 不可用
  */
  handleShowEdit = (editorName, type) => {
    const { data, name, showEdit } = this.props
    showEdit(this.getPrefix(), editorName, data.properties[name][editorName], type)
  }

  // 展示高级设置弹窗
  handleShowAdv = () => {
    const { data, name, showAdv } = this.props
    showAdv(this.getPrefix(), data.properties[name])
  }

  //  增加子节点
  handleAddField = () => {
    const { prefix, name } = this.props
    this.Model.addFieldAction({ prefix, name })
  }

  // 控制三角形按钮
  handleClickIcon = () => {
    let prefix = this.getPrefix()
    // 数据存储在 properties.xxx.properties 下
    let keyArr = [].concat(prefix, 'properties')
    this.Model.setOpenValueAction({ key: keyArr })
  }

  // 修改是否必须
  handleEnableRequire = e => {
    const { prefix, name } = this.props
    let required = e.target.checked
    // this.enableRequire(this.props.prefix, this.props.name, e.target.checked);
    this.Model.enableRequireAction({ prefix, name, required })
  }

  render() {
    let { name, data, prefix, showEdit, showAdv } = this.props
    let value = data.properties[name]
    let currentSelectValue = convertCurrenType(value)
    let prefixArray = [].concat(prefix, name)
    let prefixStr = prefix.join(JSONPATH_JOIN_CHAR)
    let prefixArrayStr = [].concat(prefixArray, 'properties').join(JSONPATH_JOIN_CHAR)
    let show = this.context.getOpenValue([prefixStr]) || true
    let showIcon = this.context.getOpenValue([prefixArrayStr]) || true
    return show ? (
      <div className="type-object">
        <Row type="flex" justify="space-around" align="middle">
          <Col
            span={8}
            className="col-item name-item col-item-name"
            style={this.__tagPaddingLeftStyle}
          >
            <Row type="flex" justify="space-around" align="middle">
              <Col span={2} className="down-style-col">
                {value.type === 'object' ? (
                  <span className="down-style" onClick={this.handleClickIcon}>
                    {showIcon ? <CaretDownOutlined /> : <CaretRightOutlined />}
                  </span>
                ) : null}
              </Col>
              <Col span={22}>
                <InputTitle
                  value={value.title}
                  onChange={this.handleChangeTitle}
                  checked={
                    _.isUndefined(data.required) ? false : data.required.indexOf(name) !== -1
                  }
                  onChecked={this.handleEnableRequire}
                  placeholder={LocaleProvider('title')}
                />
              </Col>
            </Row>
          </Col>
          <Col span={4} className="col-item col-item-type">
            <Select
              className="type-select-style"
              onChange={this.handleChangeType}
              value={currentSelectValue}
            >
              {SCHEMA_TYPE.map((item, index) => {
                return (
                  <Option value={item.type} key={index}>
                    {item.title}
                  </Option>
                )
              })}
            </Select>
          </Col>
          {this.context.isMock && (
            <Col span={4} className="col-item col-item-mock">
              <MockSelect
                schema={value}
                showEdit={() => this.handleShowEdit('mock', value.type)}
                onChange={this.handleChangeMock}
              />
            </Col>
          )}
          <Col span={this.context.isMock ? 4 : 5} className="col-item col-item-desc">
            <InputDescription
              handleShowEdit={() => this.handleShowEdit('description')}
              placeholder={LocaleProvider('description')}
              value={value.description}
              onChange={this.handleChangeDesc}
            />
          </Col>
          <Col span={this.context.isMock ? 2 : 3} className="col-item col-item-setting">
            <span className="adv-set" onClick={this.handleShowAdv}>
              <Tooltip placement="top" title={LocaleProvider('adv_setting')}>
                <SettingOutlined />
              </Tooltip>
            </span>
            <span className="delete-item" onClick={this.handleDeleteItem}>
              <Tooltip placement="top" title={LocaleProvider('delete_node')}>
                <CloseOutlined />
              </Tooltip>
            </span>
            {value.type === 'object' ? (
              <DropPlus prefix={prefix} name={name} />
            ) : (
              <span onClick={this.handleAddField}>
                <Tooltip placement="top" title={LocaleProvider('add_sibling_node')}>
                  <PlusOutlined />
                </Tooltip>
              </span>
            )}
          </Col>
          <Col span={this.context.isMock ? 3 : 4} className="col-item col-item-mock" />
          <CollectionSelect
            prefix={this.props.prefix}
            name={this.props.name}
            data={this.props.data}
            changeValueAction={this.Model.changeValueAction}
          />
        </Row>
        <div className="option-formStyle">{mapping(prefixArray, value, showEdit, showAdv)}</div>
      </div>
    ) : null
  }
}

SchemaItem.contextTypes = {
  getOpenValue: PropTypes.func,
  Model: PropTypes.object,
  isMock: PropTypes.bool,
}

class SchemaObjectComponent extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      _.isEqual(nextProps.data, this.props.data) &&
      _.isEqual(nextProps.prefix, this.props.prefix) &&
      _.isEqual(nextProps.open, this.props.open)
    ) {
      return false
    }
    return true
  }

  render() {
    const { data, prefix, showEdit, showAdv } = this.props
    return (
      <div className="object-style">
        {Object.keys(data.properties).map((name, index) => (
          <SchemaItem
            key={name}
            data={this.props.data}
            name={name}
            prefix={prefix}
            showEdit={showEdit}
            showAdv={showAdv}
          />
        ))}
      </div>
    )
  }
}

const SchemaObject = connect(state => ({
  open: state.schema.open,
}))(SchemaObjectComponent)

const DropPlus = (props, context) => {
  const { prefix, name } = props
  const Model = context.Model.schema
  const menu = (
    <Menu>
      <Menu.Item>
        <span onClick={() => Model.addFieldAction({ prefix, name })}>
          {LocaleProvider('sibling_node')}
        </span>
      </Menu.Item>
      <Menu.Item>
        <span
          onClick={() => {
            Model.setOpenValueAction({ key: [].concat(prefix, name, 'properties'), value: true })
            Model.addChildFieldAction({ key: [].concat(prefix, name, 'properties') })
          }}
        >
          {LocaleProvider('child_node')}
        </span>
      </Menu.Item>
    </Menu>
  )

  return (
    <Tooltip placement="top" title={LocaleProvider('add_node')}>
      <Dropdown overlay={menu}>
        <PlusOutlined />
      </Dropdown>
    </Tooltip>
  )
}

DropPlus.contextTypes = {
  Model: PropTypes.object,
}

const SchemaJson = props => {
  const item = mapping([], props.data, props.showEdit, props.showAdv)
  return <div className="schema-content">{item}</div>
}

export default SchemaJson
