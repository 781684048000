import React, { useRef, useCallback, useState } from 'react'
import { Modal, Input } from 'antd'
import { InputAdr } from './index'

const selectedSchema = {
  type: 'object',
  required: ['name', 'telephone'],
  properties: {
    'name': {
      type: 'string',
      title: 'ФИО',
    },
    'telephone': {
      type: 'string',
      format: 'phone',
      title: 'Телефон',
    },
  },
}

export function modalOpen({ value, targetId, apartment, address }: any) {
  let formData = { name: value, value: { value: address},  'apartment': apartment}
  return new Promise(resolve => {
    Modal.confirm({
      icon: null,
      title: 'Изменение адреса',
      content: <Content startFormData={formData} onChange={value => {
        formData = value
      }} schema={selectedSchema} />,
      onOk: () => new Promise((ok, err) => {
        const isRequired = formData?.value?.value && formData?.['apartment']
        if(isRequired){
          resolve(formData)
          ok(null)
        }
        err()
      }),
      onCancel(){
        resolve(null)
      }
    })
  })
}

function Content({ onChange, schema, startFormData }){
  const formDataRef =  useRef(startFormData)

  const [valueAdr, setValueAdr] = useState<any>(startFormData?.['value']?.['value'] || startFormData?.['name'] || '')
  const localOnChange = useCallback((data, key) => {
    Object.assign(formDataRef.current, { [key]: data })
    const adr = formDataRef.current?.value?.value || ''
    const ap = formDataRef.current?.apartment || ''
    Object.assign(formDataRef.current, { name: `${adr}, ${ap}` })

    onChange(formDataRef.current)
    setValueAdr(formDataRef.current?.['value'])
  }, [onChange])

  return (
    <>
      <InputAdr
        value={valueAdr}
        schema={schema}
        onChange={value => localOnChange(value, 'value')}
      />
      <span>Номер квартиры</span>
      <Input defaultValue={formDataRef.current['apartment']} onChange={({ target }) => localOnChange(target.value, 'apartment')}/>
    </>
  )
}