import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Badge, Menu, Spin } from 'antd'
import * as icons from '@ant-design/icons'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'

import { LoaderWrapper } from './styles'
import { DefaultMenu } from './menu'
import { preventLink } from '../../../lib/utils/helpers'
import { adminMenuItems, cardKeys } from './consts'
import { IMenuItemParsed } from './interface'
import { IMenuItem } from '../../store/menu/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { getMenu } from '../../store/menu/actions'
import { createSelector } from 'reselect'
import { IStore } from '../../store/interfaces'
import { filterRoles } from 'src/react-app/pages/card-order/utils'
import { getDeleteSessionAction } from '../../store/session/action-creators'
import { DELETE_SESSION_DONE } from '../../store/session/actions'

const { SubMenu } = Menu

export function isView(sessionData, roles) {
	const currentRoles = getDataOfType(sessionData, 'roles', Array, [])
  return Boolean(filterRoles(currentRoles, roles).length)
}

function accessSubMenu(items, sessionData) {
  const isAdd = item => isView(sessionData, item['roles'] ?? [])
  const subItemsFilter = item =>
    Array.isArray(item['items']) ? { items: accessSubMenu(item['items'], sessionData) } : null

  return items
    .filter(isAdd)
    .map(item => ({ ...item, ...subItemsFilter(item) }))
}

const parseMenu = (menu, sessionData) => {
  const result:IMenuItemParsed[] = []
  const initMenu = {
    key: '',
    items: [],
    icon: '',
    roles: []
  }

  if (menu !== null && menu.constructor === Array) {
    menu.forEach(menuItem => {
      const properties = menuItem['data']['attributes'] || {}
      const submenu = properties['sub-menu'] || initMenu
      const isAdd = isView(sessionData, properties['roles'] ?? [])
      if (isAdd) {
        result.push({
          title: properties['sub-menu-name'],
          key: submenu['key'],
          order: properties['sub-menu-order'],
          items: accessSubMenu(submenu['items'] ?? [], sessionData),
          icon: submenu['icon'],
          roles: (properties['roles'] ?? []) as any []
        })
      }
      result.sort((a, b) => a.order - b.order)
    })
  }

  return result
}

const createMenu = createSelector(
  (state: IStore) => state.menu,
  menu => ({ menu })
)

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

const createViewed = createSelector(
  (state: any) => state.viewed,
  counts => counts
)

function SubMenuTitle({ menukey, title, counts }) {
  const count = useMemo(() => {
    if (menukey === 'sales') {
      return counts['order'] + counts['measurement'] + counts['mounting']
    }
    if (menukey === 'call-center') {
      return counts['case'] + counts['phone']
    }
    if (menukey === 'production') {
      return counts['manufacture']
    }
    if (menukey === 'logistic') {
      return counts['delivery']
    }
    return null
  }, [counts, menukey])

  if (count) {
    return <>{title}<Badge count={count} offset={[ 10, 0]} /></>
  }
  return title
}

export const MainMenu = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { menu } = useSelector(createMenu)
  const { sessionData } = useSelector(createSession)
  const { counts } = useSelector(createViewed)
  const [mainMenu, setMainMenu] = useState<IMenuItemParsed[]>([])
  const currentSubMenuKey = useMemo(() =>
      `/${location.pathname && location.pathname.split('/')[1]}`,
    [location.pathname]
  )

  useEffect(() => {
    if (menu.items?.length === 0) {
      dispatch(getMenu())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (menu.items.length > 0) {
      const parsedMenu = parseMenu(menu.items, sessionData)
      setMainMenu(parsedMenu)
    }
  }, [menu.items, menu.items.length, sessionData])

  const handleSideMenuClick = useCallback((event) => {
    const { key: targetURI } = event

    history.push(targetURI)
  }, [history])

  const isClearPath = useCallback((key: string) => key?.indexOf('/') !== 0, [])

  const selectedKeys = useMemo(() => {
    const result: string[] = []
    const items: IMenuItem[] = [
      ...adminMenuItems,
      ...mainMenu,
    ]
    const pathname = location.pathname
    const clearPathname = pathname.replace('/', '')

    items.forEach(item => {
      if ((pathname?.indexOf(item.key) === 0 || clearPathname?.indexOf(item.key) === 0) && item.key !== currentSubMenuKey) {
        result.push(`${isClearPath(item.key) ? '/' : ''}${item.key}`)
      }
      if (item.items && item.items.length > 0) {
        item.items.forEach(subItem => {
          if (pathname?.indexOf(subItem.key) === 0 || clearPathname?.indexOf(subItem.key) === 0) {
            result.push(`${isClearPath(subItem?.key) ? '/' : ''}${subItem?.key}`)
          }
        })
      }
    })

    return result
  }, [currentSubMenuKey, isClearPath, location.pathname, mainMenu])

  const getMenuIcon = useCallback((icon) => {
    const result = {}
    const Icon = icon ? icons[icon] : null

    if (Icon) {
      result['icon'] = <Icon />
    }

    return result
  }, [])

  const getMenuLink = useCallback((key: string) => key?.indexOf('/') === 0 ? key : `/${key}`, [])

  const getMenuSubItem = useCallback(menuItem => {
    let count = null
    if (menuItem?.key in cardKeys) {
      const key = cardKeys[menuItem?.key]
      count = counts[key]
    }

    return (
      <Menu.Item
        {...getMenuIcon(menuItem?.icon)}
        key={getMenuLink(menuItem.key)}
        className={selectedKeys.includes(getMenuLink(menuItem.key)) ? 'ant-menu-item-selected' : ''}
      >
        <Link to={getMenuLink(menuItem.key)} onClick={preventLink}>
          <span>{menuItem.title}</span>
        </Link>
        <Badge count={count} offset={[ 10, 0]}/>
      </Menu.Item>
    )
  }, [getMenuIcon, getMenuLink, selectedKeys, counts])

  const getMenuItem = useCallback(menuItem =>
    !menuItem.items || menuItem.items.length === 0
      ? getMenuSubItem(menuItem)
      : (
        <SubMenu key={getMenuLink(menuItem?.key)} title={<SubMenuTitle menukey={menuItem?.key} title={menuItem?.title} counts={counts} />} {...getMenuIcon(menuItem?.icon)}>
          {menuItem.items?.length > 0 && menuItem.items.map(
            menuSubItem => menuSubItem.items && menuSubItem.items.length > 0 ? (
              <SubMenu key={getMenuLink(menuSubItem?.key)} title={menuSubItem?.title} {...getMenuIcon(menuSubItem?.icon)}>
                {menuSubItem.items.map(menuSubSubItem => getMenuSubItem(menuSubSubItem))}
              </SubMenu>
            ) : getMenuSubItem(menuSubItem),
          )}
        </SubMenu>
      ), [getMenuIcon, getMenuLink, getMenuSubItem, counts])

  const handleLogoutClick = useCallback(async () => {
    const lastAction: any = await dispatch(getDeleteSessionAction())

    if (lastAction.type === DELETE_SESSION_DONE) {
      history.push('/')
    }
  }, [dispatch, history])

  return (
    <div>
      {menu.isLoading && (
        <LoaderWrapper>
          <Spin />
        </LoaderWrapper>
      )}
      {menu.isLoading === false && mainMenu.length === 0 && (
        <DefaultMenu
          currentKey={location.pathname}
          currentSubMenuKey={currentSubMenuKey}
          onClick={handleSideMenuClick}
        />
      )}
      <Menu
        theme="dark"
        defaultSelectedKeys={selectedKeys}
        defaultOpenKeys={[currentSubMenuKey]}
        mode="inline"
        style={{ cursor: 'pointer' }}
        onClick={handleSideMenuClick}
      >
        {mainMenu.length > 0 && mainMenu.map(menuItem => getMenuItem(menuItem))}
        <Menu.Item onClick={handleLogoutClick}><span>Выход</span></Menu.Item>
      </Menu>
    </div>
  )
}
