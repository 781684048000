import React, { useMemo, FC } from 'react';
import { Picker } from 'antd-mobile';
import { IStageFilterProps } from './interface'
import { PopoverItem } from '../header-list-filters/styles';

export const StageFilter: FC<IStageFilterProps> = props => {
  const { stagesFilterList, stageSelected, onChange } = props
  const filterProps: any = useMemo(() => ({
    data: [[{ label: 'Все стадии', value: '' }, ...stagesFilterList]],
    title: 'Фильтрация по стадиям',
    cascade: false,
    extra: '',
    value: stageSelected,
    onChange,
    locale: {
      okText: 'Выбрать',
      dismissText: 'Отмена',
    },
  }), [onChange, stageSelected, stagesFilterList])

  return (
    <Picker {...filterProps} >
      <PopoverItem>Фильтр по стадиям</PopoverItem>
    </Picker>
  )
}
