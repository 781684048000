import fetchAPI from '../../../../../lib/utils/fetch-api'

interface SetPaymentProps {
  date: string,
  sum: number,
}

export const setPayment = async (id: string | number, formData: SetPaymentProps, signal) => {
  try {
    const response = await fetchAPI(`/api/case/payment/${id}`, {
      method: 'PUT',
      body: JSON.stringify(formData),
      signal,
    })

    if (response['errors']) {
      let error = response['errors']
      if (Array.isArray(error)) {
        error = error.join()
      }
      throw new Error(error)
    }

    return response
  } catch (err) {
    throw(err)
  }
}
