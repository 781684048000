export const organization = {
  "title": "Организация",
  "type": "pointer",
  "view": {
    "name": "select-from-organizations"
  }
}

export const user = {
  "title": "Менеджер",
  "type": "pointer",
  "view": {
    "name": "select-from-user"
  }
}

export const stage = {
  "title": "Регион",
  "type": "pointer",
  "view": {
    "name": "select-from-regions"
  }
}

export const dateRange = {
  "type": "string",
  "format": "date-range",
  "title": "Дата"
}