import React, {Suspense, useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import B2BLayout from "../../layouts/b2b";

import {getTaskTypeHrTasksList} from "../../store/hr/action-creators";
import {TasksCalendar} from "../../components/v2/tasks-calendar";
import {getTasksListToCalendar} from "../../store/calendar/action-creators";
import {HStack} from "../../ui/Stack";
import {ModalCreateTaskByHrResponse} from "../../views/modal-create-task-searchByHrResponse";

const breadcrumbs = [{
    href: '/tasks',
    title: 'Задачи',
}]


const AdminNewObjectPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        let mounted = true
        if (mounted) {
            dispatch(getTaskTypeHrTasksList())
        }
        return () => {
            mounted = false
        };
    }, [dispatch])


    const getTasksList = useCallback((filter) => {
        dispatch(getTasksListToCalendar(filter));
    }, [dispatch])

    return (
        <B2BLayout breadcrumbs={breadcrumbs}>
            <HStack align={'end'} justify={"end"} style={{marginBottom: '20px'}}>
                <Suspense fallback={''}>
                    <ModalCreateTaskByHrResponse
                        entityType={'hr'}
                        titleInputSearch={'ID отклика'}
                    />
                </Suspense>
            </HStack>
            <TasksCalendar
                getList={getTasksList}
            />
        </B2BLayout>
    )
}

export default AdminNewObjectPage;
