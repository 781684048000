import {MontageFormDataI} from "../types/types";
import fetchAPI from "../../../../../lib/utils/fetch-api";

export const setMontageEditDate = async (
    formData: MontageFormDataI,
    signal: AbortSignal
) => {
    try {
        const response =  await fetchAPI(
            `/api/montage/change-date`,
            { method: 'POST', body: JSON.stringify(formData), signal }
        )

        if (response['errors']) {
            let error = response['errors'];
            if (Array.isArray(error)) {
                error = error.join();
            }
            throw new Error(error);
        }
        return response;
    } catch (err) {
        console.error(err)
        throw(err)
    }
}
