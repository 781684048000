import React, { FC, useEffect, useState, useCallback, Fragment } from 'react';
import WhiteSpace from 'antd-mobile/lib/white-space';

import { FieldGroup } from './styles'
import Card from 'antd-mobile/lib/card';
import { ICommentCardProps } from './interface'
import fetchAPI from '../../../../../lib/utils/fetch-api'
import { parseDate } from '../../../../utilits'

export const CommentCard: FC<ICommentCardProps> = props => {
  const { targetId, taskOwnerId } = props
  const [tasksList, setTasksList] = useState<Record<string, any>[]>([])

  useEffect(() => {
    const accumulateTasks = async () => {
      const taskResponse = await fetchAPI(`/api/collections/tasks/?parentId=${targetId}&access_key=axioma`)
      const tasksList = (
        taskResponse
          && taskResponse['data']
          && taskResponse['data']['data']
          && Array.isArray(taskResponse['data']['data'])
      ) ? taskResponse['data']['data']
        : []

      const ownTasks: Record<string, any>[] = []

      tasksList.forEach(task => {
        const taskAttributes = (
          task['data']
          && task['data']['attributes']
          && Object.keys(task['data']['attributes']).length > 0
        ) ? task['data']['attributes']
          : []

        if (taskAttributes['responsible'].includes(taskOwnerId)) {
          ownTasks.push(task)
        }
      })

      if (ownTasks.length > 0) {
        setTasksList(ownTasks)
      }
    }

    if (targetId) {
      accumulateTasks()
    }
  }, [targetId, taskOwnerId])

  const getCommentDate = useCallback(task => {
    let result = ''
    const taskAttributes = task['data'] && task['data']['attributes'] ? task['data']['attributes'] : null

    if (taskAttributes && taskAttributes['time']) {
      result = parseDate(taskAttributes['time'])
    }

    return result
  }, [])

  const getCommentText = useCallback((task) => {
    let result = ''
    const taskAttributes = task['data'] && task['data']['attributes'] ? task['data']['attributes'] : null

    if (taskAttributes && taskAttributes['comment']) {
      result = taskAttributes['comment']
    }

    return result
  }, [])

  return (
    <>
      {tasksList.length > 0 && tasksList.map(task => (
        <Fragment key={`task-list-${task['id']}`}>
          <Card>
            <Card.Header
              title="Комментарий"
              extra={getCommentDate(task)}
            />
            <Card.Body>
              <FieldGroup>
                {getCommentText(task)}
              </FieldGroup>
            </Card.Body>
          </Card>
          <WhiteSpace size="lg" />
        </Fragment>
      ))}
    </>
  )
}
