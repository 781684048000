import React from 'react'
import { PhoneWrapper } from './styles'

export const PhoneIcon = () => {
  return (
    <PhoneWrapper>
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 125" xmlSpace="preserve">
        <path
          d="M89.89,80.82l-6.43,6.43c-2.85,2.85-6.63,4.4-10.58,4.4c-0.62,0-1.24-0.04-1.87-0.12c-13.41-1.66-28.18-9.69-40.52-22.03  C18.15,57.17,10.13,42.4,8.47,28.99c-0.57-4.62,0.99-9.15,4.29-12.45l6.43-6.43c2.34-2.34,6.15-2.34,8.48,0l14.14,14.14  c1.13,1.13,1.76,2.64,1.76,4.24c0,1.6-0.62,3.11-1.76,4.24l-7.78,7.78l25.46,25.46l7.78-7.78c2.34-2.34,6.14-2.34,8.48,0  l14.14,14.14C92.23,74.67,92.23,78.48,89.89,80.82z" />
      </svg>
    </PhoneWrapper>)
}
