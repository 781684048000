export enum KeyPath {
    PAGE = 'PAGE',
    ALL_BY_SETTINGS = 'ALL_BY_SETTINGS',
    ALL = 'ALL'
}

interface BasicDataPaths {
    path: string,
    key: KeyPath,
    label?: string,
}

// type OptionalDataPaths = {
//     key?: KeyPath,
//     label?: string,
// }

//export type ExcelPathsType = AtLeastOne<OptionalDataPaths> & BasicDataPaths
export type ExcelPathsType = BasicDataPaths


