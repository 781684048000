import {createSelector} from "reselect";
import moment, {Moment} from "moment";
import {EntityType} from "../../../../../shared/types/EntityType";
import {ITaskCalendarStageType} from "../../../../../store/calendar/interfaces";

export interface IDataByDayItem {
    id: number,
    type?: string
    deadline?:string,
    card_id?: number,
    card_type?: EntityType,
    task?: string,
    stage: ITaskCalendarStageType,
    responsible?: string[]
}

export type IDataByDay = Record<string, IDataByDayItem[]>

export const createSession = createSelector(
    (state: any) => state.session,
    sessionData => sessionData,
)

const selectTaskCalendar = state => state.calendar;

export const getTaskCalendarDataLoading = createSelector(
    selectTaskCalendar,
    ({isLoading}) => isLoading
);
export const getTaskCalendarError = createSelector(
    selectTaskCalendar,
    ({error}) => error
);

const getTaskCalendarStateData = createSelector(
    selectTaskCalendar,
    ({data}) => data
);

export const getTaskCalendarFilter = createSelector(
    selectTaskCalendar,
    ({filter}) => filter
);

export const getTaskCalendarTypes = createSelector(
    selectTaskCalendar,
    ({types}) => types
);

export const getTaskCalendarTypesData = createSelector(
    getTaskCalendarTypes,
    ({data: types}) => types
);

export const getTaskCalendarData = createSelector(
    getTaskCalendarStateData,
    (data) => {
        const dataByDay: IDataByDay = {}

        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                let date = moment(data[i]?.deadline).format('YYYY.MM.DD');
                let item = {
                    id: data[i]?.id,
                    type: data[i]?.type,
                    deadline: data[i]?.deadline,
                    stage: data[i]?.stage,
                    card_id: data[i]?.card_id,
                    card_type: data[i]?.card_type,
                    task: data[i]?.task,
                    responsible:  data[i]?.responsible
                };
                (dataByDay[date]) ? dataByDay[date].push(item) : dataByDay[date] = [item]
            }
        }

        return dataByDay;
    }
)


