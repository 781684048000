import React, { useRef, useCallback } from 'react'
import { Modal } from 'antd'
//import { DefaultJsonForm } from 'src/react-app/components/default-json-form'
//import { isFormDataRequired } from 'src/lib/utils/collections'
import { BrowserRouter } from 'react-router-dom'
import { MapContent, MapWrapper } from './styles'
import CardUser from '../modal'


export function modalClose(event){
  let target = event?.target
  while (Boolean(target?.tagName) === true) {
    if (target?.tagName.toLowerCase() === 'div') {
      break
    }
    target = target?.parentNode
  }
  try{
    Array.from(target?.children as any[])[0].click()
    return true
  }catch{
    return false
  }
}

function modalAppointDrivert(id): Promise<any> {
  let data = {} as any;
  return new Promise(resolve => Modal.confirm({
    icon: null,
    title: 'Добавить сотрудника',
    content: <Content onChange={event => modalClose(event)} id={id} data={data} />,
    onCancel: () => resolve(null),
    okButtonProps: { 
      onClick: (event) => {
        modalClose(event);
       //console.log('result', result)
       //if (result === true || result === null) {
         (async () => {
           await data?.save()
           resolve(null)
           
         })()
        
        
       //}
      },
      //style: { display: 'none' }
    } 
  }))
}

function Content({ onChange, id, data }){
  const formDataRef =  useRef({})
  const localOnChange = useCallback(({ formData }) => {
    formDataRef.current = formData
    onChange(formData)
  }, [onChange])

  return (
    /* @ts-ignore */
    <BrowserRouter>
    <MapContent>
      <MapWrapper>
         <CardUser onChange={localOnChange} id={id} data={data} />
      </MapWrapper>
      </MapContent>
    </BrowserRouter>
  )
}

export default modalAppointDrivert