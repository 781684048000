import React, { useState, useCallback, FC } from 'react';
import Popover from 'antd-mobile/lib/popover'
import NavBar from 'antd-mobile/lib/nav-bar'
import Icon from 'antd-mobile/lib/icon'

import { PopoverIconWrapper, SelectedFilterItemsWrapper } from './styles';
import { IMountingListFiltersProps } from './interface'
import { StageFilter } from '../stage-filter'

const Item = Popover.Item;

export const HeaderListFilters: FC<IMountingListFiltersProps> = (props) => {
  const { stagesFilterList, stageSelected, onChangeStage, stagesList } = props
  const [visible, setVisible] = useState(false)
  const [, setSelected] = useState('')

  const handleSelect = useCallback((opt) => {
    setVisible(false)
    setSelected(opt['props']['value'])
  }, [])

  const handleToggleVisible = useCallback((visibleState: boolean) => {
    setVisible(visibleState)
  }, [])

  const PopoverContent = useCallback(() => {
    return (
      <Popover
        mask
        visible={visible}
        onVisibleChange={handleToggleVisible}
        onSelect={handleSelect}
        overlay={[
          (<Item key="4">
            <StageFilter
              stageSelected={stageSelected}
              stagesFilterList={stagesFilterList}
              onChange={onChangeStage}
            />
          </Item>)
        ]}
      >
        <PopoverIconWrapper>
          <Icon type="ellipsis" />
        </PopoverIconWrapper>
      </Popover>
    )
  }, [
    handleSelect,
    handleToggleVisible,
    onChangeStage,
    stageSelected,
    stagesFilterList,
    visible,
  ])

  const SelectedFilterItems = useCallback(() => {
    const stageNameList = stageSelected
      .map(
        stage => stagesList.find(
          ({ code }) => code === stage))
      .map(
        stage => stage ? stage['name'] : ''
      )
    const stageName = stageNameList.length === 0 ? 'Все стадии' : `Стадия: ${stageNameList.join(', ')}`

    return (
      <SelectedFilterItemsWrapper>{stageName}</SelectedFilterItemsWrapper>
    )
  }, [stageSelected, stagesList])

  return (
    <NavBar
      mode="light"
      leftContent={<SelectedFilterItems />}
      rightContent={<PopoverContent />}
    />
  )
}
