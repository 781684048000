import { IDatepickerLocale } from './interface'

export const datepickerLocale: IDatepickerLocale = {
  DatePickerLocale: {
    year: '',
    month: '',
    day: '',
    hour: 'час',
    minute: 'минтуа',
  },
  extra: '',
  okText: 'Выбрать',
  dismissText: 'Отмена',
}
