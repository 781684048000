import {
    APPEALS_FORM_LOADING,
    APPEALS_FORM_DATA,
    APPEALS_FORM_FAILED,
    APPEALS_ENUMS_LOADING,
    APPEALS_ENUMS_DATA,
    APPEALS_ENUMS_FAILED,
    APPEALS_FORM_ID_UPDATE,
} from './actions'
import {IAppealsState} from './interfaces'

const initialState: IAppealsState = {
  form: {
    data: null,
    isLoading: false,
    error: '',
  },
  enums: {
    data: null,
    isLoading: false,
    error: '',
  },
  redirectUrl: undefined
}

export const appeal = (
  state = initialState,
  {type, payload},
) => {
  switch (type) {
    case APPEALS_FORM_LOADING:
      return {
        ...state,
        id: null,
        form: {
          ...state['form'],
          isLoading: true,
          data: null,
          error: ''
        }
      }
    case APPEALS_FORM_DATA:
      return {
         ...state,
        form: {
          ...state['form'],
          isLoading: false,
          data: payload,
          error: ''
        }
      }
    case APPEALS_FORM_FAILED:
      return {
        ...state,
        form: {
          ...state['form'],
          isLoading: false,
          error: payload
        }
      }
    case APPEALS_FORM_ID_UPDATE:
      return {
        ...state,
        id: payload,
      }
    case APPEALS_ENUMS_LOADING:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: true,
          data: null,
          error: ''
        }
      }
    case APPEALS_ENUMS_DATA:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: false,
          data: payload,
          error: ''
        }
      }
    case APPEALS_ENUMS_FAILED:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: false,
          error: payload
        }
      }
  default:
    return state
  }
}
