import fetchAPI from '../../../lib/utils/fetch-api'

export const GET_COLLECTIONS_REQUEST = 'GET_COLLECTIONS_REQUEST'
export const GET_COLLECTIONS_SUCCESS = 'GET_COLLECTIONS_SUCCESS'
export const GET_COLLECTIONS_ERROR = 'GET_COLLECTIONS_ERROR'

export const getCollectionsMetadata = (name: string) => async dispatch => {
  dispatch({
    type: GET_COLLECTIONS_REQUEST,
  })
  try {
    const response = await fetchAPI(`/api/collections/${name}/metadata`)

    if (response['errors']) {
      return dispatch({
        type: GET_COLLECTIONS_ERROR,
        payload: response,
        error: true,
      })
    }

    return dispatch({
      type: GET_COLLECTIONS_SUCCESS,
      payload: response['data'],
      error: false,
    })
  } catch (err) {
    return dispatch({
      type: GET_COLLECTIONS_ERROR,
      payload: err,
      error: true,
    })
  }
}
