import {isValidCancelBKP} from "../lib/isValidCancelBKP";
import {isValidCancelAKP} from "../lib/isValidCancelAKP";
import {isValidWorkOrder} from "../lib/isValidWorkOrder";
import {isValidMeasurements} from "../lib/isValidMeasurements";
import {isValidContracts} from "../lib/isValidContracts";
import {isValidForPercentLD} from "../lib/isValidForPercentLD";
import {isValidForPercentMD} from "../lib/isValidForPercentMD";
import {isValidCoContract} from "../lib/isValidCoContract";

export const calcOrders = (orders, ordersPrepayment: number, selectedMonths: string[], selectedYears: string[], manager: string) => {
    let cancel_before_kp: number = orders.filter((i) => isValidCancelBKP(i)).length,
        co: number = orders.length,
        cancel_after_kp: number = orders.filter((i) => isValidCancelAKP(i)).length,
        workOrders: number = orders.filter((i) => isValidWorkOrder(i)).length,
        measurements: number = orders.filter((i) => isValidMeasurements(i, selectedMonths, selectedYears)).length,
        contracts: number = orders.filter((i) => isValidContracts(i, selectedMonths, selectedYears)).length,
        percent_ld_count: number = orders.filter((i) => isValidForPercentLD(i, selectedMonths, selectedYears)).length / co,
        percent_md_count: number = orders.filter((i) => isValidForPercentMD(i, selectedMonths, selectedYears)).length / measurements,
        percent_cc_count: number = ordersPrepayment / co,
        average_score: number = orders.filter((i) => isValidCoContract(i, selectedMonths, selectedYears)).reduce((acc, i) => acc + i['products-price'], 0) / ordersPrepayment

    if (percent_md_count === Infinity) {
        percent_md_count = 0
    }
    if (percent_cc_count === Infinity) {
        percent_cc_count = 0
    }
    return {
        manager: manager,
        cancel_before_kp,
        co,
        cancel_after_kp,
        workOrders,
        measurements,
        contracts,
        percent_ld: percent_ld_count || 0,
        percent_md: percent_md_count || 0,
        percent_cc: percent_cc_count || 0,
        average_score: average_score || 0
    }
}