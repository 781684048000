export const STAGE_PLAN_LOADING = 'STAGE_PLAN_LOADING'
export const STAGE_PLAN_DATA = 'STAGE_PLAN_DATA'
export const STAGE_PLAN_FAILED = 'STAGE_PLAN_FAILED'
export const STAGE_PLAN_CLEAR = 'STAGE_PLAN_CLEAR'


export function setStagePlanLoading() {
    return async dispatch => {
        dispatch({type: STAGE_PLAN_LOADING})
    }
}

export function setStagePlanData(data) {
    return async dispatch => {
        dispatch({type: STAGE_PLAN_DATA, payload: data})
    }
}

export function setStagePlanFailed(error: string) {
    return async dispatch => {
        dispatch({type: STAGE_PLAN_FAILED, payload: error})
    }
}

export function setStagePlanClear() {
    return async dispatch => {
        dispatch({type: STAGE_PLAN_CLEAR})
    }
}
