/** Prepare flat children list to tree view */
export const prepareChildren = (groupChildren, targetGroup) => {
  const result = [...groupChildren]

  result.forEach((groupChildren, groupChildrenIndex) => {
    if (groupChildren.id === targetGroup['parent-id']) {
      const resultChildren = result[groupChildrenIndex].children || []

      result[groupChildrenIndex].children = [
        ...resultChildren,
        targetGroup,
      ]
    } else if (groupChildren.children && groupChildren.children.length > 0) {
      prepareChildren(groupChildren.children, targetGroup)
    }
  })

  return result
}

export const prepareGroups = groups => {
  let result: any[] = []
  if(Array.isArray(groups)){
    groups.sort((a, b) => a['parent-id'] - b['parent-id'])

    const rootGroups = groups.filter(group => 'parent-id' in group && group['parent-id'] === null)
    const childGroups = groups.filter(group => 'parent-id' in group && group['parent-id'] !== null)

    result = [...rootGroups]

    childGroups.forEach(group => {
      result = prepareChildren(result, group)
    })
  }

  return result
}
