import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Modal, Tooltip } from 'antd'
import { BlockOutlined } from '@ant-design/icons'

import { AdminCollectionEditorProps } from './interface'
import { CollectionObjectList } from '../app-collection-object-list/collection-object-list'
import { EditCollectionObject } from '../app-edit-collection-object'
import { CollectionObjectListType } from '../app-collection-object-list/interface'
import { getSchemaArrayEnum } from '../../../lib/utils/collections'

export const CollectionEditor: FC<AdminCollectionEditorProps> = (
  {
    id,
    schema = {
      title: 'Редактор',
      'collection-name': '',
    },
    onClose,
    onRowSelect,
  }
) => {
  const {
    title,
    'collection-name': collectionName,
  } = schema
  const [showObjectListModal, setObjectListShowModal] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const [selectedRowSelectId, setSelectedRowSelectId] = useState<string | null>(null)
  const [firstStep, setFirstStep] = useState(true)

  useEffect(() => {
    if (id) {
      setSelectedRowId(id)
    }
  }, [id])

  const handleAfterSave = useCallback((id) => {
    setSelectedRowSelectId(null)

    if (id) {
      const newSelectedId = getSchemaArrayEnum(collectionName, collectionName, id)

      setSelectedRowSelectId(newSelectedId)
      setSelectedRowId(id)
    }

    setFirstStep(true)
  }, [collectionName])

  const handleToggleModal = useCallback(() => {
    setObjectListShowModal(prevState => !prevState)
  }, [])

  const handleClose = useCallback(() => {
    setObjectListShowModal(false)

    if (onClose && onClose.constructor === Function) {
      onClose()
    }
  }, [onClose])

  const handleAddNew = useCallback(() => {
    setSelectedRowId(null)
    setSelectedRowSelectId(null)
    setFirstStep(false)
  }, [])

  const handleBack = useCallback(() => {
    setFirstStep(true)
  }, [])

  const handleEdit = useCallback(({ id }) => {
    setSelectedRowId(id)
    setFirstStep(false)
  }, [])

  const handleRowSelect = useCallback((id, selectId) => {
    setSelectedRowId(id)
    setSelectedRowSelectId(selectId)
  }, [])

  const handleSelect = useCallback(() => {
    if (selectedRowSelectId !== null) {
      if (onRowSelect && onRowSelect.constructor === Function) {
        onRowSelect(selectedRowSelectId)
      }
    }

    setObjectListShowModal(false)
  }, [onRowSelect, selectedRowSelectId])

  const isCanSelect = useMemo(() => {
    return !firstStep || selectedRowId === null
  }, [firstStep, selectedRowId])

  const footer = useMemo(() => {
    const result = [
      <Button onClick={handleClose} key="close">Отмена</Button>,
      <Button onClick={handleSelect} type="primary" key="select" disabled={isCanSelect}>Выбрать</Button>,
    ]

    if (!firstStep) {
      result.unshift(
        <Button onClick={handleBack} key="back">Назад</Button>
      )
    }

    return result
  }, [firstStep, handleBack, handleClose, handleSelect, isCanSelect])

  const editTitle = useMemo(() => {
    return selectedRowId ? 'Редактирование объекта' : 'Добавление новой записи'
  }, [selectedRowId])

  return (
    <>
      <Tooltip placement="top" title="Редактор записей">
        <Button icon={<BlockOutlined />} onClick={handleToggleModal} />
      </Tooltip>
      <Modal
        title={title}
        visible={showObjectListModal}
        forceRender
        width="90vw"
        onCancel={handleClose}
        footer={footer}
      >
        {firstStep ? (
          <CollectionObjectList
            collectionName={collectionName}
            viewType={CollectionObjectListType.SIMPLE}
            onAddNew={handleAddNew}
            onEdit={handleEdit}
            onRowSelect={handleRowSelect}
            outerSelectedId={selectedRowId}
            isModalView
          />
        ) : (
          <EditCollectionObject
            id={selectedRowId}
            title={editTitle}
            collectionName={collectionName}
            onAfterSave={handleAfterSave}
            needOwnCollections={true}
          />
        )}
      </Modal>
    </>)
}
