import styled from 'styled-components'

export const TableWrapper = styled.div`
  tr {
    cursor: pointer;
  }
`
export const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`