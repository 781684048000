import React, { useCallback, FC } from 'react'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import { cardName } from './consts'

import { IActionsListProps } from './interface'
import { ActionsListItem } from './actions-list-item'
import { isFormDataRequired } from '../../../../lib/utils/collections'

function genJSONSchemaStage(JSONSchema, requiredArr){
  const required = getDataOfType(JSONSchema, 'required', Array, []).concat(requiredArr)
  const requiredUnic = Array.from(new Set(required))
  return ({ ...JSONSchema, required: requiredUnic })
}

export const ActionsList: any = (props) => {
  const {
    parentId,
    formData,
    stageList,
    onStageToWork,
    onMountingLeft,
    onMountingArrived,
    onContractSigned,
    onDone,
    onTransition,
    onTeamAcceptance,
    matchedJSONSchema,
    sessionData,
    getValidatorsData,
    openSubMenu,
    onClose = () => {},
    onCancel = () => {}
  } = props

  const getTransition = useCallback(async (transition, params) => {
    const stage = params['nextStageIndex']
    const stageCodeNext = transition['target']

    switch (stageCodeNext) {
      case 'work':
        await onStageToWork(parentId, stage, stageCodeNext)

        break
      case 'mounting-left':
        await onMountingLeft(parentId, stage, stageCodeNext)

        break
      case 'measurer-arrived':
        await onMountingArrived(parentId, stage, stageCodeNext)

        break
      case 'mounting-ready':
        break
      case 'mounting-scan':
        break
      case 'contract-signed':
        await onContractSigned(parentId, stage, stageCodeNext)

        break
      case 'team-order-acceptance':
        await onTeamAcceptance(parentId, stage, stageCodeNext)

        break
      case 'done':
        await onDone(parentId, stage, stageCodeNext)

        break
      default:
        onTransition(parentId, stage, stageCodeNext)

        break
    }

  },[
    onStageToWork,
    parentId,
    onMountingLeft,
    onMountingArrived,
    onContractSigned,
    onTeamAcceptance,
    onDone,
    onTransition,
  ])

  const transitionRequired = useCallback((prevFields, nextFields) => {
    const gen = genJSONSchemaStage(matchedJSONSchema, nextFields)

    return isFormDataRequired(formData, gen);
  }, [formData, matchedJSONSchema])


  return (
    <ActionsListItem
      stage={formData['stage']}
      stageList={stageList}
      type={cardName}
      getTransition={getTransition}
      checkTransitionRequired={transitionRequired}
      sessionData={sessionData}
      getValidatorsData={getValidatorsData}
      openSubMenu={openSubMenu}
      onClose={onClose}
      onCancel={onCancel}
    />
  )
}
