import {
  RELOAD_ACTIONS_TIME_LINE,
  ADD_ACTIONS_TIME_LINE,
  SET_CASE_STAGE,
  SET_USERS_PROFILE,
  SET_USERS,
  SET_CASE_ID,
  CLEAR_CASE_STORE,
  CASE_DELETE_FILE,
  CASE_EDIT_NOTE,
  SET_TASK_STAGES,
  SET_STAGE_COLLECTION,
  SET_WORKFLOWS,
  SET_TARGET_ID,
  SET_CARD_NAME,
  SET_TABLE_PAYMENT,
  SET_CALC_DELIVERY
} from './actions'

function taskSort(a, b){
  return b.date - a.date
}

const initialState: any = {
  appealsActionsTimeLine: [],
  appealsCurrentStep: 0,
  parentIds: null,
  targetId: null,
  stageCode: null,
  stageCollection: null,
  workflows: null,
  usersProfile: [],
  users: [],
  taskStages: [],
  cardName: null,
  tablePayment: null,
  isCalcDelivery: true,
}

export const appealsReducer = (
  state = initialState,
  { type, ...props }: any,
) => {
  switch (type) {
    case RELOAD_ACTIONS_TIME_LINE: {
      const appealsActionsTimeLine = state.appealsActionsTimeLine.filter(({ type }) => (type === 'task') === false)
      return {
        ...state,
        appealsActionsTimeLine: appealsActionsTimeLine.concat(props.items).sort(taskSort)
      }
    }
    case ADD_ACTIONS_TIME_LINE:{
      const appealsActionsTimeLine = state.appealsActionsTimeLine.filter(({ type, id }) => {
        return props.items
                .filter(({ type }) => (type === 'task') === false)
                .map(({ id }) => id).includes(id) === false
      })
      return {
        ...state,
        appealsActionsTimeLine: appealsActionsTimeLine.concat(props.items).sort(taskSort)
      }
    }
    case SET_CASE_STAGE: {
      return {
        ...state,
        appealsCurrentStep: props.step,
        stageCode: props.stageCode,
      }
    }
    case SET_TARGET_ID: {
      return {
        ...state,
        targetId: props.targetId
      }
    }
    case SET_CARD_NAME: {
      return {
        ...state,
        cardName: props.cardName
      }
    }
    case SET_TABLE_PAYMENT: {
      return {
        ...state,
        tablePayment: props.tablePayment
      }
    }
    case SET_USERS_PROFILE: {
      return {
        ...state,
        usersProfile: props.items
      }
    }
    case SET_USERS: {
      return {
        ...state,
        users: props.items
      }
    }
    case SET_CASE_ID: {
      return {
        ...state,
        caseId: props.parentIds[0],
        parentIds: props.parentIds ? props.parentIds: state.parentIds
      }
    }
    case CLEAR_CASE_STORE: {
      return initialState
    }
    case SET_CALC_DELIVERY: {
      return {
        ...state,
        isCalcDelivery: props.isCalcDelivery,
      }
    }
    case CASE_DELETE_FILE: {
      return {
        ...state,
        appealsActionsTimeLine: state.appealsActionsTimeLine.filter(item => {
          if ((item.type === 'file') && (item.id === props.id)) {
            return false
          }
          return true
        })
      }
    }
    case CASE_EDIT_NOTE: {
      return {
        ...state,
        appealsActionsTimeLine: state.appealsActionsTimeLine.map(item => {
          if ((item.type === 'note') && (item.id === props.id)) {
            return { ...item, body: { ...item.body, content: props.content } }
          }
          return item
        })
      }
    }
    case SET_TASK_STAGES: {
      return {
        ...state,
        taskStages: props.items
      }
    }
    case SET_STAGE_COLLECTION: {
      return {
        ...state,
        stageCollection: props.items
      }
    }
    case SET_WORKFLOWS: {
      return {
        ...state,
        workflows: props.items
      }
    }
    default:
      return state
  }
}
