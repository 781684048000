import styled from 'styled-components'

export const TableWrapper = styled.div`
  tr {
    cursor: pointer;
  }
 .table-row-color-not-expired-and-not-done {
   background-color: #37bb3766;
 }
 .table-row-color-expired-and-not-done {
   background-color: #eb2f2f87;
 }
 .table-row-color-done {
   background-color: #40a9ff63;
 } 
`
export const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`