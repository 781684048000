import styled from 'styled-components'
import {ITaskCalendarStageType} from "../../../../../store/calendar/interfaces";

export const TaskIcon = styled.span<{$stage: ITaskCalendarStageType}>`
  background-color: ${({$stage}) => {
      switch ($stage) {
        case 'new':
            return 'green';
        case 'expired':
          return 'red';
        default:
            return 'yellow'
      }
  }};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
`
export const NotLinkButton = styled.span`
  display: inline-block;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  white-space: nowrap;
  transition: color 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    color: #40a9ff;
  }
`
