import React, {useState, useEffect, useRef, memo, useCallback, MutableRefObject} from 'react'
import {Input, Button, Modal} from 'antd'
import {RibonWrapper} from './styles'
import {setNote} from './api/setNote'
import {EntityType} from '../../shared/types/EntityType'


interface ModalAddNoteProps {
  entityType: EntityType
  entityId: number
  isOpen?: boolean
  onClose?: () => void
}

export const ModalAddNote: React.FC<ModalAddNoteProps> = memo((props: ModalAddNoteProps) => {
  const {entityType, entityId, isOpen, onClose} = props
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>
  const [value, setValue] = useState<string>('')
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      inputRef.current?.focus()
    }, 200)
    return () => clearTimeout(timer)
  }, [isOpen])

  const handleSetNote = useCallback(async () => {
    if (value.trim() === '') {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Заметка не может быть пустой'
      })
      return
    }
    setIsDisabled(true)
    const response = await setNote({
      note: value,
      card_id: entityId,
      card_type: entityType,
    })
    if (response) {
      setValue('')
      handleOnClose()
    } else {
      console.log(response)
    }
    setIsDisabled(false)
  }, [value, entityId, entityType, setValue, setIsDisabled])

  const handleOnClose = useCallback(() => {
    if (onClose) {
      onClose?.()
      setValue('')
    }
  }, [onClose])

  return (
    <Modal
      title='Добавить заметку'
        visible={isOpen}
        onCancel={handleOnClose}
        footer={[
          <Button key='back' onClick={handleOnClose}>Отмена</Button>,
          <Button key='submit' disabled={isDisabled} onClick={handleSetNote}>Добавить заметку</Button>,
        ]}
    >
      <RibonWrapper>
        <Input.TextArea
          placeholder="Введите заметку"
          autoSize={{minRows: 7, maxRows: 7}}
          value={value}
          onChange={({target}) => setValue(target.value)}
          ref={inputRef}
        />
      </RibonWrapper>
    </Modal>
  )
})
