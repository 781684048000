import { get } from 'lodash'
/*
    Пытается получить тип данных из объекта по path, возвратит данные подходящего типа, либо defaultValue
    Удобна для динамический проверки типов REST API response
*/
export function getDataOfType(object: any, path: string | string[], types: any | any[], defaultValue: any) {
  const value = get(object, path)
  if (Array.isArray(types)) {
    return types.some(type => isType(value, type)) ? value : defaultValue
  }
  return isType(value, types) ? value : defaultValue
}

function isType(value, type): boolean {
	if ((value ?? null) === null) {
		return value === getType(type)
	}
	return Object.getPrototypeOf(value) === getType(type)
}

function getType(type){
  return type?.prototype ?? type
}