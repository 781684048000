import {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {createSession, getTaskCalendarTypesData} from "../selectors";

export const useFilterParams = () => {
    const {sessionData} = useSelector(createSession);
    const isLeader = sessionData.roles.includes(19);
    const isDealerLeader = sessionData.roles.includes(25);
    const enums = useSelector(getTaskCalendarTypesData);
    const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)));


    const enumsStagesEnum: EnumType[] = enums['stages']?.filter((v) => v.id !== 'done')?.map((v) => ({
        'label': v.value,
        'value': v.id
    })) || [];
    const tasksTypeEnum: EnumType[] = enums['task_type']?.map((v) => ({'label': v.value, 'value': v.id})) || [];
    const responsibleEnum: EnumType[] = enums['responsible']?.map((v) => ({'label': v.value, 'value': v.id})) || [];

    const fields = useMemo<filtersType[]>(() => [
        {
            name: 'id',
            placeholder: 'ID',
            type: 'input-integer',
            value: urlParams?.id || undefined,
            options: {
                width: '170px',
                tooltip: 'ID',
            }
        },
        {
            name: 'phone',
            placeholder: 'Телефон',
            type: 'phone',
            value: urlParams?.phone || undefined,
            options: {
                width: '170px',
                tooltip: 'Телефон',
            }
        },
        {
            name: 'stage',
            placeholder: 'Стадия',
            type: 'select',
            value: urlParams?.stage || undefined,
            options: {
                width: '170px',
                enum: enumsStagesEnum,
                tooltip: 'Стадия',
            }
        },
        {
            name: 'task_type',
            placeholder: 'Тип задачи',
            type: 'select',
            value: urlParams?.task_type || undefined,
            options: {
                width: '170px',
                enum: tasksTypeEnum,
                tooltip: 'Тип задачи',
            }
        },
        {
            name: 'responsible',
            placeholder: 'Ответственный',
            type: 'select',
            value: urlParams?.responsible || undefined,
            disabled: !isDealerLeader,
            options: {
                width: '170px',
                enum: responsibleEnum,
                tooltip: 'Ответственный',
            },
        }

    ], [enums, urlParams])

    return {fields, urlParams};
}
