import { TDocumentItem } from '../components'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { TLongPolling } from './interface'

export const getValidatorsData = (matchedJSONSchema, validator: string): Record<string, any> | null => {
  let result: Record<string, any> | null = null

  if (matchedJSONSchema && matchedJSONSchema['properties'] && matchedJSONSchema['properties'][validator]) {
    result = matchedJSONSchema['properties'][validator] as Record<string, any>
  }

  return result ? { ...result, code: validator } : null
}

export const getDocumentList = async (matchedJSONSchema, data) => {
  const result: TDocumentItem[] = []
  const requestList: any[] = []

  if (matchedJSONSchema && matchedJSONSchema['properties']) {
    Object.keys(matchedJSONSchema['properties']).forEach(fieldKey => {
      if (
        matchedJSONSchema['properties']
        && matchedJSONSchema['properties'][fieldKey]['format'] === 'file-s3'
      ) {
        if (data[fieldKey]) {
          if (Array.isArray(data[fieldKey])) {
            data[fieldKey].forEach(fileId => {
              requestList.push({
                fieldKey,
                request: fetchAPI(`/api/collections/objects/${fileId}`),
              })
            })
          } else {
            requestList.push({
              fieldKey,
              request: fetchAPI(`/api/collections/objects/${data[fieldKey]}`),
            })
          }
        }
      }
    })
  }

  if (requestList.length > 0) {
    let response: {
      data: any,
      errors: string[] | null
    }[] = await Promise.all(requestList.map(({ request }) => request))
      .then(res => res.map(item => {
        const resResult: any[] = []
        const resErrors: any[] = []

        if (item['errors'] && Array.isArray(item['errors'])) {
          resErrors.push(...item['errors'])
        }

        if (item['data']) {
          resResult.push(item['data'])
        }

        return { data: resResult, errors: resErrors }
      }))
      .then((res) => res.map(({ data: item, errors }) => {
        let resResult: Record<string, any> = {}
        let resErrors: string[] | null = null

        if (errors) {
          resErrors = errors
        }

        if (item && Array.isArray(item)) {
          resResult = item.map(itemPart => {
            return itemPart
            && itemPart['data']
              ? itemPart['data']
              : {}
          })
        }

        return { data: resResult, errors: resErrors }
      }))

    const groupedResponse = response.reduce((acc, item, index) => {
      const fieldKey = (requestList[index] && requestList[index]['fieldKey']) || 'other'

      if (item['data'] && item['data'][0]) {
        acc[fieldKey] = Array.isArray(acc[fieldKey]) ? [
          ...acc[fieldKey],
          item['data'][0],
        ] : [item['data'][0]]
      }

      return acc
    }, {})

    Object.keys(groupedResponse).forEach(fieldKey => {
      const fieldName = getValidatorsData(matchedJSONSchema, fieldKey) || ''

      if (
        groupedResponse[fieldKey]
        && Array.isArray(groupedResponse[fieldKey])
        && groupedResponse[fieldKey].length > 0
      ) {
        result.push({
          data: groupedResponse[fieldKey],
          fieldKey,
          fieldName: fieldName['title'],
        })
      }
    })
  }

  return result
}

/** take from https://learn.javascript.ru/long-polling */
export const longPollingSubscribe: TLongPolling = async props => {
  const { fetchUrl, fetchParams = {}, callBack } = props
  let response = await fetchAPI(fetchUrl, fetchParams);

  if (response.status === 502) {
    // Статус 502 - это таймаут соединения;
    // возможен, когда соединение ожидало слишком долго
    // и сервер (или промежуточный прокси) закрыл его
    // давайте восстановим связь
    await longPollingSubscribe(props);
  } else if (response.status !== 200) {
    // Какая-то ошибка, покажем её
    callBack(response.statusText);
    // Подключимся снова через секунду.
    await new Promise(resolve => setTimeout(resolve, 1000));
    await longPollingSubscribe(props);
  } else {
    // Получим и покажем сообщение
    let message = await response.text();
    callBack(message);
    // И снова вызовем subscribe() для получения следующего сообщения
    await longPollingSubscribe(props);
  }
}
