import React, {memo, useCallback, useState} from 'react'
import {Button, Form, InputNumber, Modal} from 'antd'
import { InputPaymentAmountI } from '../model/types/types'
import { PlusOutlined } from '@ant-design/icons'
import { ModalPaymentAmount } from './modal/modal-payment-amount'
import './styles.css'

export const InputPaymentAmount: React.FC<InputPaymentAmountI> = memo((props: InputPaymentAmountI) => {
  const {
    render,
    name,
    title,
    UISchema,
    otherProps,
    rules,
    required
  } = props
  const [isOpenModalPayment, setIsOpenModalPayment] = useState<boolean>(false)

  const openModal = useCallback(async () => {
    const sum = otherProps?.sum || 0
    if (sum <= 0) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Внесите данные по заказу.'
      })
      return
    }
    setIsOpenModalPayment(true)
  },[otherProps, setIsOpenModalPayment])

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      // maxWidth: '330px',
      width: '100%'
    }}>
      <Form.Item
        label={<span style={{ whiteSpace: 'nowrap' }}>Сумма заказа</span>}
        //  name='payment-amount-sum'
        style={{ margin: '0'}}
      >
        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
          <InputNumber value={otherProps?.sum || 0} disabled={true}/>
        </div>
      </Form.Item>
      <Form.Item
        label='Задолженность'
        style={{ margin: '0' }}
        //name='payment-amount-debt'
      >
          <InputNumber value={otherProps?.debt || 0} disabled={true}/>
      </Form.Item>
      <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end' }} className={'payment-wrapper'}>
        <Form.Item
          label={<span style={{ whiteSpace: 'nowrap' }}>{title}</span>}
          name={name}
          style={{ margin: '0' }}
          rules={rules}
          required={required}
        >
          {render}
        </Form.Item>
        <Button
          onClick={openModal} disabled={otherProps?.options?.disabledButton}
          icon={<PlusOutlined/>}
        />
      </div>
      {
        Boolean(otherProps?.case_id) && (
          <ModalPaymentAmount
            entityId={otherProps?.case_id}
            debt={otherProps?.debt || 0}
            isOpen={isOpenModalPayment}
            onClose={() => setIsOpenModalPayment(false)}
          />
        )
      }
    </div>
  )
})