import {
    DELIVERIES_FORM_LOADING,
    DELIVERIES_FORM_DATA,
    DELIVERIES_FORM_FAILED,
    DELIVERIES_ENUMS_LOADING,
    DELIVERIES_ENUMS_DATA,
    DELIVERIES_ENUMS_FAILED,
    DELIVERIES_TIMELINE_LOADING,
    DELIVERIES_TIMELINE_DATA,
    DELIVERIES_TIMELINE_FAILED,
    DELIVERIES_TIMELINE_CLEAR,
    DELIVERIES_TIMELINE_ADD_ITEM,
    DELIVERIES_TIMELINE_UPDATE_ITEM,
    DELIVERIES_TIMELINE_DELETE_ITEM,
    DELIVERIES_STAGE_PLAN_LOADING,
    DELIVERIES_STAGE_PLAN_DATA,
    DELIVERIES_STAGE_PLAN_FAILED,
    DELIVERIES_STAGE_PLAN_CLEAR,
    DELIVERIES_FORM_ID_UPDATE,
} from './actions'
import {IDeliveriesState} from './interfaces'
import {ActivityItems, ActivityUpdateItem} from '../../components/v2/activity-timeline'
import {setTimelineItem} from '../../components/v2/activity-timeline/services/setTimelineItem'
import {updateTimelineItemByIndex} from '../../components/v2/activity-timeline/services/updateTimelineItemByIndex'
import {deleteTimelineItem} from '../../components/v2/activity-timeline/services/deleteTimelineItem'
import {updateTimelineItemByItemId} from '../../components/v2/activity-timeline/services/updateTimelineItemByItemId'

const initialState: IDeliveriesState = {
  form: {
    data: null,
    isLoading: false,
    error: '',
  },
  enums: {
    data: null,
    isLoading: false,
    error: '',
  },
  timeline: {
    isLoading: false,
    error: '',
    data: {
      items: [],
      itemsDates: [],
      total: undefined,
      offset: undefined,
      page: undefined,
      limit: undefined,
    }
  },
  stagePlan: {
    items: [],
    isLoading: false,
    error: ''
  },
  redirectUrl: undefined
}

export const deliveries = (
  state = initialState,
  {type, payload},
) => {
  switch (type) {
    case DELIVERIES_FORM_LOADING:
      return {
        ...state,
        id: null,
        form: {
          ...state['form'],
          isLoading: true,
          data: null,
          error: ''
        }
      }
    case DELIVERIES_FORM_DATA:
      return {
         ...state,
        form: {
          ...state['form'],
          isLoading: false,
          data: payload,
          error: ''
        }
      }
    case DELIVERIES_FORM_FAILED:
      return {
        ...state,
        form: {
          ...state['form'],
          isLoading: false,
          error: payload
        }
      }
    case DELIVERIES_FORM_ID_UPDATE:
      return {
        ...state,
        id: payload,
      }
    case DELIVERIES_STAGE_PLAN_LOADING:
      return {
        ...state,
        stagePlan: {
            isLoading: true,
            items: [],
            error: ''
        }
      }
    case DELIVERIES_STAGE_PLAN_DATA:
      return {
        ...state,
        stagePlan: {
          isLoading: false,
          items: payload,
          error: ''
        }
      }
    case DELIVERIES_STAGE_PLAN_FAILED:
      return {
        ...state,
        stagePlan: {
          items: [],
          isLoading: false,
          error: payload
        }
      }
    case DELIVERIES_STAGE_PLAN_CLEAR:
      return {
        ...state,
        stagePlan: {
          items: [],
          isLoading: false,
          error: ''
        }
      }
    case DELIVERIES_ENUMS_LOADING:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: true,
          data: null,
          error: ''
        }
      }
    case DELIVERIES_ENUMS_DATA:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: false,
          data: payload,
          error: ''
        }
      }
    case DELIVERIES_ENUMS_FAILED:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: false,
          error: payload
        }
      }
    case DELIVERIES_TIMELINE_LOADING:
      return {
        ...state,
        timeline: {
          isLoading: true,
          error: '',
          data: {
            items: [],
            itemsDates: [],
            total: undefined,
            offset: undefined,
            page: undefined,
            limit: undefined,
          }
        },
      }
    case DELIVERIES_TIMELINE_DATA:
      return {
        ...state,
        timeline: {
          isLoading: false,
          error: '',
          data: payload
        },
      }
    case DELIVERIES_TIMELINE_FAILED:
      return {
        ...state,
        timeline: {
          ...state['timeline'],
          isLoading: false,
          error: payload,
          data: {
            items: [],
            itemsDates: [],
            total: undefined,
            offset: undefined,
            page: undefined,
            limit: undefined,
          }
        },
      }
    case DELIVERIES_TIMELINE_CLEAR:
      return {
        ...state,
        timeline: {
          isLoading: false,
          error: '',
          data: {
            items: [],
            itemsDates: [],
            total: undefined,
            offset: undefined,
            page: undefined,
            limit: undefined,
          }
        },
      }
    case DELIVERIES_TIMELINE_ADD_ITEM: {
      const newActivityItems = payload as ActivityItems[]
      let timelineItems: ActivityItems[] = state.timeline.data?.items || []
      let timelineDaysItems = state.timeline.data?.itemsDates || []

      if (!newActivityItems.length) {
        return state
      }

      const {
        timelineItems: timelineItemsMod,
        timelineDates: timelineDaysItemsMod
      } = setTimelineItem(newActivityItems, timelineItems, timelineDaysItems)

      return {
        ...state,
        timeline: {
          ...state.timeline,
          data: {
            ...state.timeline.data,
            items: [...timelineItemsMod],
            itemsDates: [...timelineDaysItemsMod]
          }
        },
      }
    }
  case DELIVERIES_TIMELINE_UPDATE_ITEM: {
    const newDataItem = payload as ActivityUpdateItem
    let timelineItems: ActivityItems[] = state.timeline.data?.items || []
    let timelineDaysItems = state.timeline.data?.itemsDates || []
    const timelineItemsMod = newDataItem?.index || newDataItem?.index === 0
      ? updateTimelineItemByIndex(newDataItem, timelineItems, timelineDaysItems)
      : updateTimelineItemByItemId(newDataItem, timelineItems, timelineDaysItems)

    return {
      ...state,
      timeline: {
      ...state.timeline,
        data: {
          ...state.timeline.data,
          items: [...timelineItemsMod],
        }
      },
    }
  }
  case DELIVERIES_TIMELINE_DELETE_ITEM: {
    const deletedItem = payload as ActivityUpdateItem
    let timelineItems: ActivityItems[] = state.timeline.data?.items || []
    let timelineDaysItems = state.timeline.data?.itemsDates || []

    const {
      timelineItems: timelineItemsMod,
      timelineDates: timelineDaysItemsMod
    } = deleteTimelineItem(deletedItem, timelineItems, timelineDaysItems)

    return {
      ...state,
      timeline: {
        ...state.timeline,
        data: {
          ...state.timeline.data,
          items: [...timelineItemsMod],
          itemsDates: [...timelineDaysItemsMod]
        }
      },
    }
  }
  default:
    return state
  }
}
