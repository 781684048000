import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Alert, Button, Modal, Skeleton, Space} from 'antd'
import {JSONSchema7} from 'json-schema'
import {AlertWrapper, MapContent, MapTitle, MapWrapper, TaskWrapper} from './styles'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import {getDataOfType} from 'src/lib/utils/get-data-of-type'
import {DefaultJsonForm} from 'src/react-app/components/default-json-form'
import StepCollectionObject from 'src/react-app/views/step-collection-object'
import ModalCreateTask from 'src/react-app/views/modal-create-task'
import EventRibbonTab from 'src/react-app/forms/case-event-ribbon-tab'
import {createUISchemaHorizontal, recalcUISchemaHorizontal,} from 'src/lib/utils/grid-fild-orientation'
import ButtonAddNotes from 'src/react-app/views/modal-add-notes'
import {
  addActionsTimeLine,
  clearCaseStore,
  initialCase,
  reloadActionsTimeLine,
  reloadTablePayment,
  setAppealStage,
  setCaseId,
  setTargetId
} from 'src/react-app/store/appeals/actions'
import StuckFiles from 'src/react-app/components/tab-stuck-files'
import ModalMeasurement from 'src/react-app/views/modal-measurement'
import {modalBrigade} from 'src/react-app/views/modal-brigade'
import {modalAddPayment} from 'src/react-app/views/modal-add-payment'
import TableProduct from 'src/react-app/connectors/json-form-product-table/adaptor-tab'
import {accessUISchema, createObject, getObjectFirst, isFormDataDifferenceFilesS3,} from './utils'
import {isFormDataRequired} from 'src/lib/utils/collections'
import {debounce} from 'lodash'
import {createSelector} from 'reselect'
import StepButtonsTransition from 'src/react-app/views/step-buttons-transition'
import {addValidateFieldsUISchema} from 'src/react-app/pages/card-order/utils'
import modalOrderCancellation from 'src/react-app/views/modal-order-cancellation'
import {setCardCancel, setStageFromCard} from 'src/lib/utils/cards'
import {insetNoteToCollection} from '../../views/modal-add-notes/utils-notes'
import modalChangeRegion from 'src/react-app/views/modal-change-region'

function genJSONSchemaStage(JSONSchema, requiredArr) {
  const required = getDataOfType(JSONSchema, 'required', Array, []).concat(requiredArr)
  const requiredUniq = Array.from(new Set(required))
  return { ...JSONSchema, required: requiredUniq }
}

function genJSONSchemaNamesRequired(JSONSchema) {
  return getDataOfType(JSONSchema, 'required', Array, [])
}

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  appealsCurrentStep => appealsCurrentStep,
  stageCode => stageCode,
  workflows => workflows,
  targetId => targetId,
)
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData,
)

const breadcrumbs = [
  {
    href: '/sales/orders',
    title: 'Продажи',
  },
  {
    href: '',
    title: 'Заказы',
    back: true,
  },
]

const name = 'case-order'
const cardName = 'order'

export default function AdminNewObjectPage() {
  const { id } = useParams() as any
  const dispatch = useDispatch()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(false)
  const [isModalCreateTask, setModalCreateTask] = useState<boolean>(false)
  const [isModalMeasurement, setModalMeasurement] = useState<boolean>(false)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [selectedSchema, setSelectedSchema] = useState<string[]>([])
  const formDataRef = useRef<any>(null)
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<JSONSchema7 | null>(null)
  const [UISchema, setUISchema] = useState<any>(null)
  const { appealsCurrentStep, workflows, targetId, stageCode, isCalcDelivery } = useSelector(createAppealsCurrentStep)
  const { sessionData } = useSelector(createSession)
  const [isRevertLoading, setRevertLoading] = useState<Boolean>(false)
  const [region, setRegion] = useState<any>(null)
  const [organization, setOrganization] = useState<any>(null)
  const [required, setRequired] = useState<any>(null)
  const [manager, setManager] = useState<any>(null)
  const [formKey, setFormKey] = useState<number>(0)

  function setDisabledFields(accessUISchema) {

    if(appealsCurrentStep >= 1){
      if ('telephone' in accessUISchema)
        accessUISchema['telephone'].options.disabled = true
      if ('order-price' in accessUISchema)
        accessUISchema['order-price'].options.disabled = true
    }
    if(appealsCurrentStep >= 2){
      if('name' in accessUISchema)
        accessUISchema['name'].options.disabled = true
      /*
      if('order-attribute' in accessUISchema)
        accessUISchema['order-attribute'].options.disabled = true
      */
    }

    return accessUISchema
  }

  useEffect(() => {
    const currentStageAccess = getDataOfType(workflows, `${cardName}[${appealsCurrentStep}].access-rights`, Array, null)
    if (currentStageAccess === null)
      return
    setUISchema(UISchema => recalcUISchemaHorizontal( setDisabledFields( accessUISchema( UISchema || {}, currentStageAccess, sessionData) ) ) )
  }, [workflows, appealsCurrentStep, sessionData])

  const customTabs = useMemo(
    () => ({
      'stuck-products': {
        isVirtual: true,
        schema: {
          properties: {
            'stuck-products': {
              title: 'Данные заказа',
              description: null,
            },
          },
        },
        render: () => (
          <TableProduct
            currentStage={'loading'}
            disabled={true}
            id={id}
            options={false}
            targetId={targetId}
          />
        ),
      },
      'stuck-files': {
        isVirtual: true,
        schema: {
          properties: {
            'stuck-files': {
              title: 'Прикрепленные файлы',
              description: null,
            },
          },
        },
        render: () => <StuckFiles />,
      },
      'event-ribbon': EventRibbonTab,
    }),
    [id, targetId],
  )
  const saveCase = useCallback(debounce(async (formData) => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    const leader = roles.includes(19)
    if (stageCode === 'done' && !leader) return
    let requestBody = {
      data: {
        name: '',
        title: '',
        attributes: formData,
        metadata: { schema: name }
      }
    }

    const result = await fetchAPI(`/api/collections/-/objects/${id}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })

    const updated = getDataOfType(result, 'data.updated-at', [String, Number], null)
    if (updated === null) {
      console.warn('Ошибка сохранения записи')
    }
  }, 1000), [organization, stageCode, sessionData])

  useEffect(() => {
    dispatch(initialCase({ targetId: null, cardName: cardName }))
    const result = setCaseId({ caseId: id, parentIds: [id] })
    dispatch(result)
    return () => {
      dispatch(clearCaseStore())
    }
  }, [id, dispatch])

  const getData = useCallback(async () => {

    if (isDataLoading)
      return

    setIsDataLoading(true)
    const fields = [
      'title',
      'description',
      'type',
      'properties',
      'required',
      'created_by',
      'created_at',
      'updated_at',
    ].join(',')

    const responseSchemas = await fetchAPI(`/api/schemas?access_key=axioma&fields=${fields}`)
    const fetchedSchemas: JSONSchema7[] = getDataOfType(responseSchemas, 'data.data', Array, [])
    const selectedSchemas = fetchedSchemas.filter(item => item['name'] === name)
    const selectedSchema = typeof selectedSchemas[0] === 'object' ? selectedSchemas[0] : null
    if (selectedSchema === null) {
      setPageErrors(['Некорректный ответ сервера при схемы коллекции'])
      return
    }
    const responseObject = await fetchAPI(`/api/collections/objects/${id}`)
    const object = responseObject['data']
    const schema = object['data']['metadata']['schema']
    const attributes = object['data']['attributes']
    const formData = {
      ...attributes,
      'workflow-id': +object?.['workflow-id']
    }
    const stageCode = getDataOfType(object, 'stage-code', String, null)
    const parentId = Number(object['parent-id'])
    setOrganization( object['owner-organization'] )
    dispatch(setTargetId({ targetId: parentId }))

    if( !selectedSchema?.required)
      selectedSchema.required = []

    if( stageCode === 'new' )
      selectedSchema.required = [...selectedSchema.required, 'name']

    if( stageCode === 'work' )
      selectedSchema.required = [...selectedSchema.required, 'order-attribute', 'address-object']

    if( stageCode === 'measurement' )
      selectedSchema.required = [...selectedSchema.required, 'production-order-number', 'scan-spec-products', 'contract-scan']

    setRequired(selectedSchema.required)

    setSelectedSchema(schema)
    if (object['created-at']) {
      const paramsDispatch = addActionsTimeLine({
        type: 'info',
        items: [
          {
            date: object['created-at'],
            text: 'Создание записи',
          },
        ],
      })
      dispatch(paramsDispatch)
    }
    setPageErrors([])
    formDataRef.current = formData
    const stage = formData['stage'] || 0
    setMatchedJSONSchema(selectedSchema)
    const UISchema = createUISchemaHorizontal(selectedSchema)
    const currentCardOrganizationId = Number(
      getDataOfType(object, 'owner-organization', String, null),
    )
    if ('region' in UISchema) {
      UISchema['region'] = {
        ...UISchema['region'],
        url_params: {
          has_organizations: true
        }
      }
    }
    if ('case-manager' in UISchema) {
      UISchema['case-manager'] = {
        ...UISchema['case-manager'],
        options: {
          roleId: 2,
          organizationId: currentCardOrganizationId,
        },
      }
    }
    if ('inn' in UISchema) {
      UISchema['inn'] = {
        ...UISchema['inn'],
        options: {
          organizationId: currentCardOrganizationId,
        },
      }
    }
    if ('measurer' in UISchema) {
      UISchema['measurer'] = {
        ...UISchema['measurer'],
        options: {
          roleId: 10,
          organizationId: currentCardOrganizationId,
        },
      }
    }
    if ('leader-installer' in UISchema) {
      UISchema['leader-installer'] = {
        ...UISchema['leader-installer'],
        options: {
          roleId: 15,
          organizationId: currentCardOrganizationId,
        },
      }
    }
    if ('additional-contact' in UISchema) {
      UISchema['additional-contact'] = {
        ...UISchema['additional-contact'],
        options: {
          targetId: parentId,
        }
      }
    }

    setUISchema(UISchema)
    const currentStageAccess = getDataOfType(workflows, `${cardName}[${stage}].access-rights`, Array, [])
    dispatch(setAppealStage({ stageCode: stageCode, type: cardName, isHistory: false }))
    setUISchema(UISchema => recalcUISchemaHorizontal( setDisabledFields( accessUISchema(UISchema || {}, currentStageAccess, sessionData) ) ) )
    const region = formDataRef.current?.region
    // setIsDataLoading(false)
    if(region)
      setRegion(region)

    if( formDataRef.current?.['case-manager'] )
      setManager( formDataRef.current?.['case-manager'] )

    setIsDataLoading(false)
  }, [dispatch, id, sessionData, workflows, organization, setOrganization])

  useEffect(() => {
    if (sessionData === null || workflows === null) {
      return
    }
    getData()
  }, [getData, sessionData, workflows])

  useEffect(() => {
    if (sessionData === null || workflows === null)
      return
    if (isCalcDelivery === false)
      return
  }, [isCalcDelivery, sessionData, workflows])

  const onPrepayment = useCallback(async () => {
    const isPay = await fetchAPI(`/api/collections/order-specification/?parentId=${targetId}`)
    .then(item => item?.data?.data?.[0]?.data?.attributes?.table?.reduce?.((acc, item: any) => acc + item?.['price'] || 0, 0))
    .then(Boolean)
    if (!isPay) {
      Modal.warning({
        title: 'Не удалось изменить стадию',
        content: 'Пустой заказ не может быть отправлен в производство',
      })
      return
    }
    const stageCodeNext = 'prepayment'
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    if (roles.includes(18)) {
      const nextDataCard = await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: targetId,
        isHistory: true,
        addFormData: {
          'date-prepayment': new Date().toISOString(),
        }
      })
      formDataRef.current = getDataOfType(nextDataCard, 'data.formData', Object, null)
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadTablePayment())
      return true
    }
    const result = await getObjectFirst({ collectionName: 'card-froze', parentId: targetId })
    const isDone =  getDataOfType(result, 'stage-code', String, 'new') === 'done'
    if (!isDone) {
      Modal.warning({
        title: 'Не удалось изменить стадию',
        content: 'Замер не произведен',
      })
      return
    }

    const nextDataCard = await setStageFromCard({
      cardName: cardName,
      stageCode: stageCodeNext,
      targetId: targetId,
      isHistory: true,
      addFormData: {
        'date-prepayment': new Date().toISOString(),
      }
    })
    formDataRef.current = getDataOfType(nextDataCard, 'data.formData', Object, null)
    dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
    dispatch(reloadTablePayment())
  }, [dispatch, targetId, sessionData])

  const onFormData = useCallback( async ({ formData }) => {
    // Изменение региона на стадии "Консультация"
    const newRegion = formData?.region

    if( (appealsCurrentStep === 0 || appealsCurrentStep === 1) && region && newRegion !== region && !isDataLoading) {
      const res = await modalChangeRegion([newRegion])
      if( res === null ){
        /** TODO Вернуть предыдущее состояние через formDataRef не получается, DefaultJsonForm не реагирует на изменения **/
        getData()
      }else{
        setRegion(newRegion)
        saveCase(formData)
        setTimeout( async () => {
          await fetchAPI(`/api/object/change_owner/${targetId}`, {
            method: 'POST',
            body: JSON.stringify({id: res['organization']}),
          })

          const reasonTransfer = res['reason-transfer']
          const organizationNew = res['organization']
          await insetNoteToCollection({
            date: new Date().toISOString(),
            content: JSON.stringify({region: {old: region, new: newRegion}, organization: {old: String(organization), new: String(organizationNew)}, reason: reasonTransfer}),
            author: sessionData?.user?.id,
            type: 'region-change'
          }, targetId)
          window.location.reload()
        }, 1200)
      }
    }else{
      if( formData['case-manager'] !== manager ){
        await insetNoteToCollection({
          date: new Date().toISOString(),
          content: JSON.stringify({manager: {old: manager, new: formData['case-manager']}}),
          author: sessionData?.user?.id,
          type: 'manager-change'
        }, targetId)
        setManager( formData['case-manager'] )
        dispatch( reloadActionsTimeLine() )
      }

      const isFilesEdit = isFormDataDifferenceFilesS3(
        formDataRef.current,
        formData,
        matchedJSONSchema,
      )
      if (isFilesEdit) {
        dispatch(reloadActionsTimeLine())
      }
      formData['stage'] = appealsCurrentStep
      // formDataRef.current = {...formData, stage: appealsCurrentStep}
      if (
        isFilesEdit &&
        appealsCurrentStep === 2 &&
        formData['contract-scan'] &&
        formData['check-scan']
      ) {
        onPrepayment()
      }
      saveCase(formData)
    }
    // checkRequired()
  }, [
    isDataLoading,
    appealsCurrentStep,
    region,
    getData,
    saveCase,
    organization,
    sessionData?.user?.id,
    targetId,
    dispatch,
    manager,
    matchedJSONSchema,
    onPrepayment,
    formDataRef,
    formDataRef.current
  ])
/*
  const checkRequired = useCallback( () => {
    if(required) {
      const form = formDataRef.current
      setMatchedJSONSchema( JSONSchema => {
        if( JSONSchema?.required ) {
          JSONSchema.required = required.filter(v => !form[v])
        }
        return JSONSchema
      })
      setSelectedSchema([''])
    }
  }, [formDataRef.current, setSelectedSchema, required])
*/
  const onShapeFreeze = useCallback(async modalData => {
    setModalMeasurement(false)
    const stageCodeNext = 'measurement'
    const measurer = getDataOfType(modalData, 'responsible', [Array], null).map(Number)
    const measurerScan = getDataOfType(modalData, 'measurement-scan', [Array], null)
    const result = await setStageFromCard({
      cardName: cardName,
      stageCode: stageCodeNext,
      targetId: targetId,
      isHistory: true,
      addFormData: {
        'stage': 3,
        'measurer': measurer, // Ответственный
        'measurement-scan': measurerScan?.[0],
        'date-measurement': getDataOfType(modalData, 'date-measurement', String, null),
        'time-measurement': getDataOfType(modalData, 'time-measurement', String, null),
        'comment': getDataOfType(modalData, 'comment', String, null),
        'checked': getDataOfType(modalData, 'checked', Boolean, null),
      }
    })
    formDataRef.current = getDataOfType(result, 'data.formData', Object, null)
    const frozeId = getDataOfType(result, 'data.onStageData.card-measurement-id', Number, null)
    if ( frozeId === null) {
      return
    }
    dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
    /*
    await addTask({
      'parent-id': targetId,
      responsible: [measurer, 'me'],
      attributes: {
        stage: 'measurement-assigned',
        comment:
          'Заказ. Сделать замер. Задача создана автоматически. ' + (modalData['comment'] || ''),
        'card-id': frozeId,
        'a-href': `/froze/${frozeId}/edit`,
        'a-text': `Карточка замера: № ${targetId}`,
      },
    })
    */
    dispatch(reloadActionsTimeLine())
  }, [targetId, dispatch])

  const transition = useCallback(async transition => {
    const stageCodeNext = transition['target']

    setUISchema( UISchema => {
      if( 'address-object' in UISchema )
        UISchema['address-object'].required = true
      if( 'order-attribute' in UISchema )
        UISchema['order-attribute'].required = true
      return UISchema
    })

    setMatchedJSONSchema(matchedJSONSchema => {
      if(matchedJSONSchema?.required) {
        matchedJSONSchema.required.push('address-object', 'order-attribute');
      }
      return matchedJSONSchema
    })

    if (stageCodeNext === 'work') {
      const stageCodeNext = 'work'
      await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: targetId,
        isHistory: true,
      })
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName }))
      dispatch(reloadActionsTimeLine())
    }
    if (stageCodeNext === 'measurement') {
      setModalMeasurement(true)

      setMatchedJSONSchema(matchedJSONSchema => {
        if(matchedJSONSchema?.required)
          matchedJSONSchema.required.push('production-order-number', 'number-contract', 'scan-spec-products', 'contract-scan');
        return matchedJSONSchema
      })
    }
    if (stageCodeNext === 'prepayment') {
      await onPrepayment()
    }
    if (stageCodeNext === 'manufacture') {
      const ownerUser = await fetchAPI(`/api/collections/card-froze/?parentId=${targetId}`).then(item => item?.data?.data?.[0]?.['owner-user'])
      const isMounting = await fetchAPI(`/api/users/${ownerUser}`).then(item => item?.['data']?.['roles']?.some?.(element => element.id === 30))
      const region = getDataOfType(formDataRef.current, 'region', String, 'msk')
      const result =  await modalBrigade({ region, 'mounting': isMounting })
      const responsible = getDataOfType(result, 'responsible', [Number, String], null)
      const date = getDataOfType(result, 'date', String, null)
      const time = getDataOfType(result, 'time', String, null)
      const dateShipping = getDataOfType(result, 'date-shipping', String, null)
      const dateDelivery = getDataOfType(result, 'date-delivery', String, null)
      const comment = getDataOfType(result, 'comment', String, null)
      const organizationManufacture = getDataOfType(result, 'organization-manufacture', Number, null)
      const organizationDelivery = getDataOfType(result, 'organization-delivery', Number, null)
      if (result) {
        const nextCardData = await setStageFromCard({
          cardName: cardName,
          stageCode: stageCodeNext,
          targetId: targetId,
          isHistory: true,
          addFormData: {
            'leader-installer': responsible,
            'date-shipping': dateShipping,
            'date-carriage': dateDelivery,
            'date-plan-assembly-team-arrived': date,
            'time-plan-assembly-team-arrived': time,
          }
        })
        const nextFormData = getDataOfType(nextCardData, 'data.formData', Object, {})
        formDataRef.current = nextFormData
        const formData = { ...nextFormData, stage: 0 }
        if (comment) {
          const authorId = getDataOfType(sessionData, 'user.id', Number, 0)
          insetNoteToCollection({ date: new Date().toISOString(), content: comment, author: authorId }, targetId)
        }
        await Promise.all([
          createObject({ formData, parentId: targetId, collectionName: 'card-manufacture', owner_organization: organizationManufacture }),
          createObject({ formData, parentId: targetId, collectionName: 'card-delivery', owner_organization: organizationDelivery }),
        ])
        dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
        dispatch(reloadActionsTimeLine())
      }
    }
  }, [dispatch, onPrepayment, sessionData, targetId])

  const transitionRequired = useCallback((_, nextFields) => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    if (roles.includes(18)) {
      return true
    }
    const gen = genJSONSchemaStage(matchedJSONSchema, nextFields)
    const names = genJSONSchemaNamesRequired(gen)
    const isRequired = isFormDataRequired(formDataRef.current, gen)
    if (isRequired) {
      return true
    }
    setUISchema(UISchema => addValidateFieldsUISchema(UISchema, names))
    return false
  }, [matchedJSONSchema, sessionData])

  const onModalCancellation = useCallback(async () => {
    if(stageCode === 'work' && !formDataRef.current?.['order-attribute']){
      setUISchema(UISchema => addValidateFieldsUISchema(UISchema, ["order-attribute"]))
      return false
    }
    const stageCodeNext = 'done'
    const result = await modalOrderCancellation()
    console.log({ result })
    if (result !== null) {
      await setCardCancel({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: targetId,
        isHistory: true,
        id: result?.['reason-cancellation'],
        comment: result?.['comment'],
      })
      formDataRef.current['workflow-id'] = result?.['reason-cancellation']
      formDataRef.current['reason-for-cancellation'] = result?.['reason-cancellation']
      await dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
    }
  }, [dispatch, targetId, stageCode, formDataRef, formDataRef.current])

  const isAddPayment = useMemo(() => {
    return false // stageCode === 'prepayment'
  }, [])

  const onButtonPayment = useCallback(async () => {
    const result: any = await modalAddPayment({})
    if (result !== null) {
      await fetchAPI(`/api/web-hooks/add-payment-contract`, {
        method: 'POST',
        body: JSON.stringify({
          targetId: targetId,
          pay: {
            sum: result['payment'],
            date: result['date'],
          },
        }),
      })
    }
    dispatch(reloadTablePayment())
  }, [targetId, dispatch])

  const onModalRet = useCallback(async () => {
    setRevertLoading(true)
    const body = {}
    body['target-id'] = targetId
    await fetchAPI(`/api/web-hooks/recurrence-payment`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    setRevertLoading(false)
    dispatch(reloadTablePayment())
  /*
    await setStageFromCard({
      cardName: cardName,
      stageCode: 'done',
      targetId: targetId,
      isHistory: true,
    })
  */
    dispatch(setAppealStage({ stageCode: 'done', type: cardName, isHistory: true }))
  }, [targetId, dispatch])

  const isRet = useMemo(() => {
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, 0)
    return organizationId === 1 && !isRevertLoading
  }, [sessionData, isRevertLoading])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>Заказ №{targetId}</MapTitle>
          <Space size={'small'}>
            <ButtonAddNotes />
            {isAddPayment && (
              <Button onClick={onButtonPayment}>
                Внести оплату
              </Button>
            )}
            <Button disabled>Отправить сообщение</Button>
            <Button onClick={() => setModalCreateTask(true)}>Создать задачу</Button>
            <StepButtonsTransition
              type="order"
              transition={transition}
              transitionRequired={transitionRequired}
            />
            {isRet && <Button danger onClick={onModalRet} disabled={stageCode === 'done'}>
              Возврат
            </Button>}
            <Button danger onClick={onModalCancellation} disabled={stageCode === 'done'}>Отмена</Button>
          </Space>
        </TaskWrapper>
        <MapContent>
          <ModalCreateTask visible={isModalCreateTask} onClose={() => setModalCreateTask(false)} />
          <ModalMeasurement
            visible={isModalMeasurement}
            onClose={() => setModalMeasurement(false)}
            onOk={onShapeFreeze}
            options={formDataRef.current}
            onChange={() => { return }}
            ref={formDataRef}
          />
          <>
            <StepCollectionObject type="order" />
              {pageErrors.length > 0 ? (
                <AlertWrapper>
                  <Alert
                    message="При выполнении операции возникла ошибка:"
                    showIcon
                    type="error"
                    description={pageErrors.join('. ')}
                  />
                </AlertWrapper>
              ) : null}
              {!isDataLoading && matchedJSONSchema ? (
                <>
                  <DefaultJsonForm
                    key={formKey}
                    formData={formDataRef.current}
                    schema={matchedJSONSchema}
                    onChange={onFormData}
                    customTabs={customTabs}
                    UISchema={UISchema}
                  />
                </>
              ) : (
                <Skeleton active />
              )}
          </>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
