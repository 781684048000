import {ICancelModal, IEnumsData, IFormData} from "./interfaces";

export const HR_FORM_LOADING = 'HR_FORM_LOADING'
export const HR_FORM_DATA = 'HR_FORM_DATA'
export const HR_FORM_SUCCESS_UPDATE = 'HR_FORM_SUCCESS_UPDATE'
export const HR_FORM_ID_UPDATE = 'HR_FORM_ID_UPDATE'
export const HR_FORM_FAILED = 'HR_FORM_FAILED'
export const HR_CANCEL_FORM_DATA = 'HR_CANCEL_FORM_DATA'
export const HR_ENUMS_LOADING = 'HR_ENUMS_LOADING'
export const HR_ENUMS_DATA = 'HR_ENUMS_DATA'
export const HR_ENUMS_FAILED = 'HR_ENUMS_FAILED'

/* ============== HR_FORM ============== */
export function setHrFormLoading() {
    return async dispatch => {
        dispatch({type: HR_FORM_LOADING})
    }
}

export function setHrFormSuccessUpdate() {
    return async dispatch => {
        dispatch({type: HR_FORM_SUCCESS_UPDATE})
    }
}

export function setHrFormCandidateId(id: string) {
    return async dispatch => {
        dispatch({type: HR_FORM_ID_UPDATE, payload: id})
    }
}
export function setHrFormData(formData: IFormData) {
    return async dispatch => {
        dispatch({type: HR_FORM_DATA, payload: formData})
    }
}

export function setHrFormFailed(error: string) {
    return async dispatch => {
        dispatch({type: HR_FORM_FAILED, payload: error})
    }
}

export function setHrCancelFormData(data: ICancelModal) {
    return async dispatch => {
        dispatch({type: HR_CANCEL_FORM_DATA, payload: data})
    }
}

/* ============== end HR_FORM ============== */


/* ============== HR_ENUMS ============== */
export function setHrEnumsLoading() {
    return async dispatch => {
        dispatch({type: HR_ENUMS_LOADING})
    }
}

export function setHrEnumsData(enumsData: IEnumsData) {
    return async dispatch => {
        dispatch({type: HR_ENUMS_DATA, payload: enumsData})
    }
}

export function setHrEnumsFailed(error: string) {
    return async dispatch => {
        dispatch({type: HR_ENUMS_FAILED, payload: error})
    }
}

/* ============== end HR_ENUMS ==============  */

