import {IEnumsData, IFormData} from "./interfaces";
import {ActivityItems, ActivityUpdateItem, ITimelineResponse} from "../../components/v2/activity-timeline/types/types";


export const DELIVERIES_FORM_LOADING = 'DELIVERIES_FORM_LOADING'
export const DELIVERIES_FORM_DATA = 'DELIVERIES_FORM_DATA'
export const DELIVERIES_FORM_FAILED = 'DELIVERIES_FORM_FAILED'
export const DELIVERIES_FORM_ID_UPDATE = 'DELIVERIES_FORM_ID_UPDATE'
export const DELIVERIES_ENUMS_LOADING = 'DELIVERIES_ENUMS_LOADING'
export const DELIVERIES_ENUMS_DATA = 'DELIVERIES_ENUMS_DATA'
export const DELIVERIES_ENUMS_FAILED = 'DELIVERIES_ENUMS_FAILED'
export const DELIVERIES_TIMELINE_LOADING = 'DELIVERIES_TIMELINE_LOADING'
export const DELIVERIES_TIMELINE_DATA = 'DELIVERIES_TIMELINE_DATA'
export const DELIVERIES_TIMELINE_FAILED = 'DELIVERIES_TIMELINE_FAILED'
export const DELIVERIES_TIMELINE_CLEAR = 'DELIVERIES_TIMELINE_CLEAR'
export const DELIVERIES_TIMELINE_ADD_ITEM = 'DELIVERIES_TIMELINE_ADD_ITEM'
export const DELIVERIES_TIMELINE_UPDATE_ITEM = 'DELIVERIES_TIMELINE_UPDATE_ITEM'
export const DELIVERIES_TIMELINE_DELETE_ITEM = 'DELIVERIES_TIMELINE_DELETE_ITEM'
export const DELIVERIES_STAGE_PLAN_LOADING = 'DELIVERIES_STAGE_PLAN_LOADING'
export const DELIVERIES_STAGE_PLAN_DATA = 'DELIVERIES_STAGE_PLAN_DATA'
export const DELIVERIES_STAGE_PLAN_FAILED = 'DELIVERIES_STAGE_PLAN_FAILED'
export const DELIVERIES_STAGE_PLAN_CLEAR = 'DELIVERIES_STAGE_PLAN_CLEAR'

/* ============== DELIVERIES_FORM ============== */
export function setDeliveriesFormLoading() {
    return async dispatch => {
        dispatch({type: DELIVERIES_FORM_LOADING})
    }
}

export function setDeliveriesFormData(formData: IFormData) {
    return async dispatch => {
        dispatch({type: DELIVERIES_FORM_DATA, payload: formData})
    }
}

export function setDeliveriesFormFailed(error: string) {
    return async dispatch => {
        dispatch({type: DELIVERIES_FORM_FAILED, payload: error})
    }
}

export function setDeliveryId(id: string) {
    return async dispatch => {
        dispatch({type: DELIVERIES_FORM_ID_UPDATE, payload: id})
    }
}



/* ============== end DELIVERIES_FORM ============== */


/* ============== DELIVERIES_ENUMS ============== */
export function setDeliveriesEnumsLoading() {
    return async dispatch => {
        dispatch({type: DELIVERIES_ENUMS_LOADING})
    }
}

export function setDeliveriesEnumsData(enumsData: IEnumsData) {
    return async dispatch => {
        dispatch({type: DELIVERIES_ENUMS_DATA, payload: enumsData})
    }
}

export function setDeliveriesEnumsFailed(error: string) {
    return async dispatch => {
        dispatch({type: DELIVERIES_ENUMS_FAILED, payload: error})
    }
}

/* ============== end DELIVERIES_ENUMS ==============  */

/* ============== DELIVERIES_TIMELINE ============== */

export function setDeliveriesTimelineLoading() {
    return async dispatch => {
        dispatch({type: DELIVERIES_TIMELINE_LOADING})
    }
}

export function setDeliveriesTimelineData(formData: ITimelineResponse) {
    return async dispatch => {
        dispatch({type: DELIVERIES_TIMELINE_DATA, payload: formData})
    }
}

export function setDeliveriesTimelineFailed(error: string) {
    return async dispatch => {
        dispatch({type: DELIVERIES_TIMELINE_FAILED, payload: error})
    }
}

export function setDeliveriesTimelineClear() {
    return async dispatch => {
        dispatch({type: DELIVERIES_TIMELINE_CLEAR})
    }
}

export function setDeliveriesStagePlanClear() {
    return async dispatch => {
        dispatch({type: DELIVERIES_STAGE_PLAN_CLEAR})
    }
}

export function addDeliveriesTimeLineItem(activityItems: ActivityItems[]) {
    return async dispatch => {
        dispatch({type: DELIVERIES_TIMELINE_ADD_ITEM, payload: activityItems})
    }
}

export function updateDeliveriesTimeLineItem(activityItem: ActivityUpdateItem) {
    return async dispatch => {
        dispatch({type: DELIVERIES_TIMELINE_UPDATE_ITEM, payload: activityItem})
    }
}

export function deleteDeliveriesTimeLineItem(activityItem: ActivityUpdateItem) {
    return async dispatch => {
        dispatch({type: DELIVERIES_TIMELINE_DELETE_ITEM, payload: activityItem})
    }
}


/* ============== end DELIVERIES_TIMELINE ==============  */


/* ============== DELIVERIES_STAGE_PLAN ============== */


export function setDeliveriesStagePlanLoading() {
    return async dispatch => {
        dispatch({type: DELIVERIES_STAGE_PLAN_LOADING})
    }
}

export function setDeliveriesStagePlanData(data) {
    return async dispatch => {
        dispatch({type: DELIVERIES_STAGE_PLAN_DATA, payload: data})
    }
}

export function setDeliveriesStagePlanFailed(error: string) {
    return async dispatch => {
        dispatch({type: DELIVERIES_STAGE_PLAN_FAILED, payload: error})
    }
}

/* ============== end DELIVERIES_STAGE_PLAN ==============  */
