import { useState, useEffect, useCallback } from 'react';
import fetchAPI from 'src/lib/utils/fetch-api';
import { ICandidateData, IColumn, IResource, IFilterOption } from '../interfaces/interfaces';

const monthOrder = [
  'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
];

export const useFetchData = (
    selectedYears: string[],
    selectedMonths: string[],
    selectedResources: string[],
    selectedDepartments: string[],
    selectedVacancies: string[],
    selectedManagers: string[],
) => {
  const [candidateData, setCandidateData] = useState<ICandidateData[]>([]);
  const [stagesData, setStagesData] = useState<any[]>([]);
  const [paymentsData, setPaymentsData] = useState<any[]>([]);
  const [resourcesColumns, setResourcesColumns] = useState<IColumn[]>([]);
  const [stagesColumns, setStagesColumns] = useState<IColumn[]>([]);
  const [years, setYears] = useState<IFilterOption[]>([]);
  const [resources, setResources] = useState<IResource[]>([]);
  const [departments, setDepartments] = useState<IFilterOption[]>([]);
  const [vacancies, setVacancies] = useState<IFilterOption[]>([]);
  const [managers, setManagers] = useState<IFilterOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false);
  const [isPaymentsLoaded, setIsPaymentsLoaded] = useState<boolean>(false);
  const [cancelReasons, setCancelReasons] = useState<{ name: string, value: number }[]>([]);
  const [arResultVacancy, setArResultVacancy] = useState<{ vacancy: string, count: number }[]>([]);

  useEffect(() => {
    loadFilterData();
    loadPaymentsData();
  }, []);

  useEffect(() => {
    if (initialLoadComplete) {
      loadData();
    }
  }, [
    selectedYears,
    selectedMonths,
    selectedResources,
    selectedDepartments,
    selectedVacancies,
    selectedManagers,
    initialLoadComplete,
  ]);

  const loadFilterData = async () => {
    setLoading(true);

    const result = await fetchAPI('/api/hr/request/report/filters', {
      headers: {
        Authorization: 'Basic ' + btoa('zeokna:1q55CVboYQ'),
      },
    });

    const { years, resources, departments, vacancies, managers } = result;

    setYears(years);
    setResources(resources);
    setDepartments(departments);
    setVacancies(vacancies);
    setManagers(managers);
    setLoading(false);
    setInitialLoadComplete(true);
  };

  const loadPaymentsData = async () => {
    if (isPaymentsLoaded) return;

    const paymentsResult = await fetchAPI('/api/hr/payments/?page_size=all', {
      headers: {
        Authorization: 'Basic ' + btoa('zeokna:1q55CVboYQ'),
      },
    });

    const paymentsData = paymentsResult.data || [];
    setPaymentsData(paymentsData);
    setIsPaymentsLoaded(true);
  };

  const loadData = async () => {
    setLoading(true);
    const filters: any = {
      years: selectedYears.join(','),
      months: selectedMonths.join(','),
      resources: selectedResources.join(','),
      departments: selectedDepartments.join(','),
      vacancies: selectedVacancies.join(','),
      managers: selectedManagers.join(','),
    };

    const queryString = new URLSearchParams(filters).toString();

    const result = await fetchAPI(`/api/hr/request/report/?${queryString}`, {
      headers: {
        Authorization: 'Basic ' + btoa('zeokna:1q55CVboYQ'),
      },
    });

    const data = result.data || [];

    const formattedData: ICandidateData[] = [];
    const resourcesSet = new Set<string>();
    const cancelReasonCounts: Record<string, number> = {};
    const arResultVacancyTemp: { vacancy: string, count: number }[] = [];

    data.forEach((item: any) => {
      const date = new Date(item.created_at);
      const month =
          date.toLocaleString('ru-RU', { month: 'long', timeZone: 'UTC' }).charAt(0).toUpperCase() +
          date.toLocaleString('ru-RU', { month: 'long', timeZone: 'UTC' }).slice(1);
      const year = date.getFullYear().toString();
      const key = `${month}_${year}`;
      const resource = item.resource || 'Не известно';
      const resourceKey = resource.toLowerCase().replace(/\s+/g, '_');

      resourcesSet.add(resource);

      let entry = formattedData.find(entry => entry.month === month && entry.year === year);
      if (!entry) {
        entry = {
          month,
          year,
          total: 0,
          avito: 0,
          hh: 0,
          author: 0,
          not_specified: 0,
          telegram: 0,
          friends: 0,
          transport_ad: 0,
          website: 0,
        };
        formattedData.push(entry);
      }

      entry[resourceKey] = (entry[resourceKey] || 0) + 1;
      entry.total += 1;

      const cancelReason = item.cancel_reason;
      if (cancelReason) {
        if (!cancelReasonCounts[cancelReason]) {
          cancelReasonCounts[cancelReason] = 0;
        }
        cancelReasonCounts[cancelReason]++;
      }

      if (item.stage === 'Завершен' && item.cancel_reason === null && item.end_probation_period) {
        const existingVacancy = arResultVacancyTemp.find(v => v.vacancy === item.vacancy);
        if (existingVacancy) {
          existingVacancy.count += 1;
        } else {
          arResultVacancyTemp.push({ vacancy: item.vacancy, count: 1 });
        }
      }
    });

    const stagesFormattedData: Record<string, any> = data.reduce((acc: any, item: any) => {
      let date = new Date(item.created_at);

      const month =
          date.toLocaleString('ru-RU', { month: 'long', timeZone: 'UTC' }).charAt(0).toUpperCase() +
          date.toLocaleString('ru-RU', { month: 'long', timeZone: 'UTC' }).slice(1);
      const year = date.getFullYear().toString();
      const key = `${month}_${year}`;

      if (!acc[key]) {
        acc[key] = {
          month,
          year,
          total: new Set(),
          new: new Set(),
          in_review: new Set(),
          interview: new Set(),
          rejected: new Set(),
          hired: new Set(),
          accepted: new Set(),
        };
      }

      acc[key].total.add(item.phone);

      if (item.stage === 'Новый отклик') {
        acc[key].new.add(item.phone);
      }

      if (item.stage === 'На рассмотрении') {
        acc[key].in_review.add(item.phone);
      }

      if (item.stage === 'Собеседование') {
        acc[key].interview.add(item.phone);
      }

      if (item.stage === 'Завершен' && item.cancel_reason !== null) {
        acc[key].rejected.add(item.phone);
      }

      if (item.stage === 'Завершен' && item.cancel_reason === null && item.end_probation_period) {
        acc[key].accepted.add(item.phone);
      }

      if (item.stage === 'Оформление') {
        acc[key].hired.add(item.phone);
      }

      return acc;
    }, {});

    const stagesDataProcessed = Object.values(stagesFormattedData).map((item: any) => ({
      ...item,
      total: item.total.size,
      new: item.new.size,
      in_review: item.in_review.size,
      interview: item.interview.size,
      rejected: item.rejected.size,
      hired: item.hired.size,
      accepted: item.accepted.size,
    }));



    const resourceList = Array.from(resourcesSet);
    const dynamicResourcesColumns = resourceList.map(resource => ({
      title: resource,
      dataIndex: resource.toLowerCase().replace(/\s+/g, '_'),
      key: resource.toLowerCase().replace(/\s+/g, '_'),
    }));

    const columns = [
      { title: 'Месяц', dataIndex: 'month', key: 'month' },
      ...dynamicResourcesColumns,
      { title: 'Всего', dataIndex: 'total', key: 'total' },
    ];

    const sortedData = formattedData.sort((a, b) => {
      const yearComparison = a.year.localeCompare(b.year);
      return yearComparison !== 0
          ? yearComparison
          : monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month);
    });

    setResourcesColumns(columns);
    setStagesColumns([
      { title: 'Месяц', dataIndex: 'month', key: 'month' },
      { title: 'Кол-во откликов', dataIndex: 'total', key: 'total' },
      { title: 'Новые', dataIndex: 'new', key: 'new' },
      { title: 'На рассмотрении', dataIndex: 'in_review', key: 'in_review' },
      { title: 'На стадии Собеседование', dataIndex: 'interview', key: 'interview' },
      { title: 'Отказы', dataIndex: 'rejected', key: 'rejected' },
      { title: 'Приняты на работу', dataIndex: 'accepted', key: 'accepted' },
    ]);
    setCandidateData(sortedData);
    setStagesData(stagesDataProcessed);

    const cancelReasonsArray = Object.keys(cancelReasonCounts).map(reason => ({
      name: reason,
      value: cancelReasonCounts[reason],
    }));
    setCancelReasons(cancelReasonsArray);

    setArResultVacancy(arResultVacancyTemp);

    setLoading(false);
  };

  const refetchData = () => {
    loadData();
  };

  return {
    candidateData,
    stagesData,
    paymentsData,
    resourcesColumns,
    stagesColumns,
    years,
    resources,
    departments,
    vacancies,
    managers,
    loading,
    refetchData,
    cancelReasons,
    arResultVacancy,
  };
};
