import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'antd'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getPreparedSchema } from '../../../lib/utils/schema'
import localizedServerErrors from '../../../lib/utils/localized-server-errors'
import { AdminCollectionItemConnector, IFromData, initialFormDataValues } from '../../connectors/admin-collection-item'
import B2BLayout from '../../layouts/b2b'
import { EditCollectionForm } from '../../forms/app-edit-collection-form'

export default function AdminNewCollectionPage() {
  const history = useHistory()
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [formData, setFormData] = useState<IFromData>(initialFormDataValues)
  const [form] = Form.useForm()
  const breadcrumbs = useMemo(() => [
    {
      href: '/admin',
      title: 'Администрирование',
    },
    {
      href: '/admin/collections',
      title: 'Справочники',
    },
  ], [])

  // @todo Extract to Redux Store to avoid double call
  useEffect(() => {
    const getSchemaName = async () => {
      try {
        const response = await fetchAPI(`/api/total/collections`)
        const total: number = Number(response['data']) + 1
        const collectionName = `collection-${total}`

        form.setFieldsValue({ name: collectionName })
        setFormData(prevState => ({
          ...prevState,
          name: collectionName,
        }))
        setPageErrors([])
      } catch(err){
        setPageErrors(['Некорректный ответ сервера'])
      }
    }

    getSchemaName()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = async values => {
    //Удалим пробелы и табуляцию, если есть
    const newName = String(values.name).trim()
    if(values.name !== newName){
      values.name = newName
      form.setFieldsValue({ name: newName })
    }
    const preparedSchema = values.schema && getPreparedSchema(values.schema)
    const data = {
      'name': values.name ? `${values.name}`.toLowerCase() : '',
      'title': values.title,
      'description': values.description || values.title,
      'group_id': values.group_id,
    }
    const requestBody = {
      'data': {
        ...data,
        'metadata': {
          ...data,
          'schema': preparedSchema,
        }
      }
    }
    const result = await fetchAPI('/api/collections', {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })
    if (result['data'] && result['data']['id']) {
      setPageErrors([])
      history.push(`/admin/collections/${result['data']['name']}/objects`)
    } else {
      const errors =
        ((Array.isArray(result['errors'])) && (result['errors'].length > 0))
        ? result['errors'].map(error => localizedServerErrors(error['detail']))
        : ['Неизвестная ошибка при выполнении запроса']
      setPageErrors(errors)
    }
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {AdminCollectionItemConnector({
        title: 'Создание нового справочника',
        form,
        formData,
        setFormData,
        pageErrors,
        onFinish,
        idFieldExtra: 'Внимание! Допустимы только латинские букв (a-z, A-Z) и символ -',
      })(EditCollectionForm)}
    </B2BLayout>
  )
}
