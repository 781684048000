import React, { useState, useEffect, useMemo, useCallback } from 'react'
import B2BLayout from '../../../layouts/b2b'
import { Button, Table } from 'antd'
import { getColumns } from './columns'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { useHistory } from 'react-router-dom'
import { MapTitle } from '../../appeals/card/styles'
import { PlusOutlined } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd/es/table'
import { Sorter, State } from '../../../interfaces'
import { objectToUrl } from '../../utils'
import { DataType } from './interface'

const createSession = createSelector(
  (state: State) => {
    return state.session
  },
  sessionData => sessionData,
)

const CustomersList = () => {
  const history = useHistory()
  const [data, setData] = useState<DataType[]>([])
  const { sessionData } = useSelector(createSession)
  // const isLeader = getDataOfType(sessionData, 'organization.id', Number, null) === 1
  const isLeader = true
  const [loading, setLoading] = useState(false)
  const [sorter, setSorter] = useState<Sorter>({})
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 10,
    position: ['bottomCenter'],
    showSizeChanger: false,
  })

  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(
    () => [
      {
        href: '/admin',
        title: 'Админка',
      },
    ],
    [],
  )

  useEffect(() => {
    try {
      /* Пытаюсь получить список */
      getData()
    } catch (e) {
      alert('Не удалось получить данные, пожалуйста, перезагрузите страницу')
    }
  }, [])

  /**
   * Обновляет состояние пагинации
   */
  const updatePagination = useCallback(
    newState => {
      setPagination({
        ...pagination,
        ...newState,
      })
    },
    [pagination, setPagination],
  )

  const setOrganizationState = useCallback( async (id, state) => {
    fetchAPI(`/api/organizations-new/state`, { method: 'POST', body: JSON.stringify({ id, state }) })
      .then(() => {
        getData()
      })
      .catch(e => {
        alert('При выполнении запроса возникла ошибка')
        console.error(e)
      })
  }, [])

  /**
   * Получает записи
   */
  const getData = useCallback(() => {
    try {
      setLoading(true)
      const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI(`/api/organizations-new/list/?${objectToUrl(currentUrlParams)}`).then(({ data, pagination, sorter }) => {
        if (data) {
          updatePagination(pagination)
          setSorter(sorter)
          setData(data.map(item => ({
            ...item,
            setOrganizationState,
          })))
        }
        setLoading(false)
      })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [])

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param filters
   * @param sorter
   */
  const handleTableChange = (pagination, filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    const current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current,
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    getData()
  }

  /**
   * Отправляет на страницу деталки
   * @param record
   */
  const handleRowClick = record => {
    const { id } = record
    history.push(`/offices/${id}`)
  }

  const handleCreate = () => {
    history.push(`/new/organization/new`)
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <MapTitle>Список организаций</MapTitle>
        <Button onClick={handleCreate}>
          <PlusOutlined /> Добавить организацию
        </Button>
      </div>
      <Table
        showSorterTooltip={{ overlay: 'Нажмите для сортировки', placement: 'bottom' }}
        columns={getColumns(isLeader, sorter)}
        rowKey={r => r?.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={record => ({ onClick: () => handleRowClick(record) })}
        scroll={{
          x: 'max-content',
          y: '72vh',
        }}
        size="small"
      />
    </B2BLayout>
  )
}

export default CustomersList
