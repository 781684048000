import React, { useCallback, FC, useState, useEffect, useMemo } from 'react';
import Card from 'antd-mobile/lib/card';

import { CardTitle } from '../delivery-list/styles'
import { PhoneButton } from '../measurements-edit/styles'
import { IMeasurementListItemProps } from './interface'
import { parseDate } from '../../../utilits'
import WhiteSpace from 'antd-mobile/lib/white-space';

export const MeasurementListItem: FC<IMeasurementListItemProps> = (props) => {
  const { data, onOpenEdit, stagesList } = props
  const [adjustedDate, setAdjustedDate] = useState('')

  useEffect(() => {
    let result = data && data['date-adjusted-measurement'] ? `${parseDate(data['date-adjusted-measurement'], 'dd.MM.yyyy')} ${data['time-adjusted-measurement']}` : null

    if (result) {
      setAdjustedDate(result)
    }
  }, [data])

  const getStage = useCallback(({ stage = 0 }) => {
    let result = stagesList[stage]

    if (result && 'name' in result) {
      result = result['name']
    }

    return result
  }, [stagesList])

  const handleCall = useCallback((value: string) => () =>
    Object.assign(window.location, { href: `tel:${value}` }), [])

    const getContent = useCallback(({ name, 'address-object': address, city }) => (
      <>
        <div>
          <b>ФИО: </b>{name}
        </div>
        {address && (
          <div><b>Адрес: </b>{`${city}, ${address}`}</div>
        )}
      </>
    ), [])

  const titleDate = useMemo(() => {
    const date = `${data['date-measurement']} ${data['time-measurement']}`

    return adjustedDate ? adjustedDate : date
  }, [adjustedDate, data])

  return (
    <Card onClick={onOpenEdit(data['id'])}>
      <Card.Header
        title={<CardTitle>{data['parent-id']}: {getStage(data)}</CardTitle>}
        extra={<CardTitle><b>Дата замера:</b> {titleDate}</CardTitle>}
      />
      <Card.Body>
        <div>{getContent(data)}</div>
        <WhiteSpace size="lg" />
        <PhoneButton
          href={`tel:${data['telephone']}`}
          onClick={handleCall(data['telephone'])}
        >
          <b>Телефон:&nbsp;</b>{data['telephone']}
        </PhoneButton>
      </Card.Body>
  </Card>
  )
}
