import React, {memo, useMemo} from 'react';
import './styles.css';
import {IOrdersByManager, IOrdersByManagerColumns} from "../../model/types/types";
import {Table, Typography} from "antd";
import {HStack} from "../../../../ui/Stack";
import {formatPrice} from "../../../utils";

const {Title} = Typography;

export interface OrdersByManagersTableProps {
    data?: IOrdersByManager
    columns?: IOrdersByManagerColumns
}

const OrdersByManagersTable = (props: OrdersByManagersTableProps) => {
    const {data, columns} = props;
    if (!data || !columns) {
        return <Typography.Text>Сервер не может найти запрошенный ресурс. </Typography.Text>;
    }
    const months = useMemo(() => Object.keys(data), [data]);

    return (
        <div>{
            months.map((month) => (
                    <Table
                        title={() => <Title level={5}>{month.replace(/[0-9]/g, '')}</Title>}
                        key={month}
                        dataSource={Object.values(data[month]).flat()}
                        columns={columns}
                        pagination={false}
                        size="small"
                        style={{marginBottom: '40px'}}
                        footer={(currentPageData) => (
                            <HStack justify={"between"} align={"start"} max>
                                <span style={{fontWeight: 'bolder'}}> Всего:</span>
                                <span style={{fontWeight: 'bolder'}}>
                                       {
                                           formatPrice(currentPageData.reduce((acc, val) => val.sum + acc, 0))
                                       }
                            </span>
                            </HStack>
                        )}
                    />
                )
            )
        }</div>
    )
}

export default memo(OrdersByManagersTable);

