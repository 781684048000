import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {Table, Alert, Pagination, Space, Button} from 'antd'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import B2BLayout from 'src/react-app/layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import { formDataToUrlParams } from 'src/react-app/pages/list-orders/utils'
import { calcTotalWidth, tableRowClick, getColumnsOfSchema, fetchSchema } from 'src/lib/utils/list'
import { TableWrapper, PaginationWrapper } from './styles'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import MailsFilter from './mails-filter'
import './index.css'
import {PlusOutlined, FileExcelOutlined} from "@ant-design/icons"

const createViewed = createSelector(
  (state: any) => state.viewed,
  counts => counts
)

export default function AdminObjectsListPage() {
  const history = useHistory()
  const location = useLocation()
  const MailsFiltersRef = useRef<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [, setDataColumns] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [page, setPage] = useState<number>(1)
  const { counts } = useSelector(createViewed)
  const breadcrumbs = useMemo(() => (
    [{
      href: '/',
      title: 'Контактный центр',
    }, {
      href: location.pathname,
      title: 'Почта',
    }]
  ), [location.pathname])
  //const { sessionData } = useSelector(createSession)

  const addDataColumns = useMemo(() => {
    const addDataColumns: any[] = [
      {
        title: 'ID',
        width: 100,
        dataIndex: 'id',
        key: 'id',
        render: (_, item) => getDataOfType(item, 'id', Number, '')
      },
      {
        title: 'Дата',
        width: 200,
        dataIndex: 'date',
        key: 'date',
        render: (key) => key && dayjs(key).format('DD.MM.YYYY')
      },
      {
        title: 'Тема письма ',
        width: 300,
        dataIndex: 'subject',
        key: 'subject',
        render: (key, item) => (key && key.length > 100) ? key.slice(0, 100) + '...' : key
      },
      {
        title: 'От кого',
        width: 150,
        dataIndex: 'from',
        key: 'from',
        render: (key, item) => key
      },
      {
        title: 'Кому',
        width: 150,
        dataIndex: 'to',
        key: 'to',
        render: (key, item) => key
      },
    ]
    return addDataColumns
  }, [])

  const totalWidth: any = useMemo(() => calcTotalWidth( addDataColumns), [ addDataColumns])

  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(addDataColumns).filter(item => item.title), [addDataColumns])
  const cbTableRowClick = useCallback((item) => item['id'] && history.push(`/mails/${item['id']}`), [history])

  const deleteRecord = useCallback(async (currentId) => {
    try{
      await fetchAPI(`/api/collections/objects/${currentId}`, { method: 'DELETE'})
      setDataSource(dataSource => dataSource.filter(({ id }) => id !== currentId))
    }catch(error){
      console.error(error)
    }
  }, [])

  const getData = useCallback(async (value, addUrlParams?: string) => {
    const urlParams = formDataToUrlParams(value)
    const pageErrors: string[] = []
    const [collections] = await Promise.all([
      fetchAPI(`/api/organization_imap_email?${urlParams}&${addUrlParams}`),
    ])
    const collectionsResultData = getDataOfType(collections, 'data.data', Array, [])

    const dataSource: any[] = collectionsResultData
    const newDataSource = dataSource.map((item) => ({
      ...item,
      deleteRecord,
    }))

    const totalRows = getDataOfType(collections, 'data.total-rows', Number, 0)
    setTotalRows(totalRows)
    setDataSource(newDataSource)
    setPageErrors(pageErrors)
    setIsDataLoading(false)
  }, [deleteRecord])

  const onPagination = useCallback(async ( page, pageSize) => {
    setPage(page)
    const urlParams = `page=${page}&pageSize=${pageSize}`
    setIsDataLoading(true)
    await getData(MailsFiltersRef.current, urlParams)
    setIsDataLoading(false)
  }, [getData])

  useEffect(() => {
    const urlParams = `page=${page}&pageSize=10`
    page === 1 && getData(MailsFiltersRef.current,urlParams)
  }, [counts, page, getData])

  const rowClassName = useCallback(record => {
    const isColor = record['orders-quantity'] >= 2 && record['orders-quantity-new'] >= 1
    return isColor ? 'table-client-row-dark' :  'table-client-row-light'
  }, [])

  const onMailsFilter = useCallback(value => {
    Object.assign(MailsFiltersRef.current, value)
    getData(value)
  }, [getData])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <Space align="baseline">
        <MailsFilter onChange={onMailsFilter} />
      </Space>
      {pageErrors.length > 0 ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
          />
        </div>
      ) : null}
      <TableWrapper>
        <Table
          columns={finalColumns}
          rowClassName={rowClassName}
          size="small"
          dataSource={dataSource}
          scroll={{ x: totalWidth, y: '72vh' }}
          pagination={false}
          loading={isDataLoading}
          onHeaderRow={(column: any) => ({
            style: {
              fontSize: column.key && column.key.substr(-3) === '-at' ? 9 : 13,
            },
          })}
          onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick) })}
        />
      </TableWrapper>
      {Boolean(totalRows) && <PaginationWrapper>
        <Pagination defaultCurrent={1} pageSize={10} total={totalRows} onChange={onPagination} showSizeChanger={false} />
      </PaginationWrapper>}
    </B2BLayout>
  )
}
