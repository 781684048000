import React, {useState, useEffect, useCallback, useMemo, useContext} from 'react'
import {Table, Button} from 'antd'
import {useHistory} from 'react-router-dom'
import mailModal from '../modals/mail-modal'
import fetchAPI from 'src/lib/utils/fetch-api'
import {getDataOfType} from 'src/lib/utils/get-data-of-type'

const TabMailContext = React.createContext({})

function ButtonEdit({id}) {
  const props = useContext<any>(TabMailContext)
  return <Button onClick={() => props.editRow(id)}>Редактировать</Button>
}

function TabMail({onChange, id}) {
  const history = useHistory()

  const columns = useMemo(() => [
    {
      title: 'E-mail',
      width: 290,
      key: 'user',
      dataIndex: 'user'
    },
    {
      title: 'Пароль',
      width: 290,
      key: 'password',
      dataIndex: 'password',
    },
    {
      title: 'Хост',
      width: 290,
      key: 'host',
      dataIndex: 'host',
    },
    {
      title: 'Порт',
      width: 290,
      key: 'port',
      dataIndex: 'port',
    },
    {
      title: 'TLS',
      width: 290,
      key: 'tls',
      dataIndex: 'tls',
      render: (key, item) => (key === true) ? 'Да' : 'Нет'
    },
    {
      title: null,
      width: 290,
      key: 'edit',
      dataIndex: 'id',
      render: id => <ButtonEdit id={id}/>,
    }
  ], [])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataSource, setMails] = useState<any[]>([])
  const [selected, setSelectedMails] = useState<any[]>([])

  const getData = useCallback(async () => {
    setIsLoading(true)
    const result = await fetchAPI(`/api/organization_imap/${id}`)
    const data = getDataOfType(result, 'data', Array, [])

    setSelectedRowKeys(data.filter(i => i.active).map(i => i.id))
    setMails(data)
    setIsLoading(false)
  }, [id])

  useEffect(() => {
    getData()
  }, [getData])

  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>(selected)


  const onSelect = useCallback(async (row, state) => {
    const id = row.id
    setIsLoading(true)
    await fetchAPI(`/api/imap_email/set`, {
      method: 'POST',
      body: JSON.stringify({ id, active: state })
    })
      .then(data => {
        setSelectedRowKeys(keys => keys.includes(data.id) ? keys.filter(key => key !== data.id) : keys.concat(data.id))
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [onChange, id, selectedRowKeys])

  const rowSelection = useMemo(() => ({
    selectedRowKeys,
    onSelect,
    hideSelectAll: true
  }), [selectedRowKeys, onSelect])

  const onHeaderRow = useCallback((column: any) => ({
    style: {
      fontSize: (column.key && column.key.substr(-3) === '-at') ? 9 : 13,
    }
  }), [])

  const contextProps = useMemo(() => ({
    editRow: async (mailId) => {
      const formData = dataSource.find(item => item.id === mailId)
      const newFormData = await mailModal(formData)

      if (newFormData === null) {
        return
      }
      await fetchAPI(`/api/organization_imap/${mailId}`, {
        method: 'POST',
        body: JSON.stringify({...newFormData, organization_id: id, tls: !!newFormData.tls})
      })

      getData()
    }
  }), [history, id, dataSource])

  const addMail = useCallback(async () => {
    const formData = await mailModal()

    if (formData === null) {
      return
    }
    await fetchAPI(`/api/organization_imap`, {
      method: 'POST',
      body: JSON.stringify({...formData, organization_id: id, tls: !!formData.tls})
    })

    getData()

  }, [id, getData])

  return (<>
    <Button onClick={addMail}>Добавить почту</Button>
    <TabMailContext.Provider value={contextProps}>
      <Table
        size="small"
        rowKey="id"
        pagination={false}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        onHeaderRow={onHeaderRow}
      />
    </TabMailContext.Provider>
  </>)
}

export default TabMail
