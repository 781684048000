import React, {memo, useCallback, useRef} from "react";
import moment, {Moment} from "moment";
import './style.css';
import {classNames} from "../../../../../../lib/classNames/classNames";
import {TimeTableSlots} from "../../../model/consts/timeTableSlots";
import {Badge, BadgeProps} from "antd";

interface ListDataItem {
    type: string,
    content: string
}


interface HoursCellTemplateProps {
    listData: Record<string, ListDataItem[]>,
    value: Moment,
    clickCell: (value: Moment, time) => void
}

const slots = Object.entries(TimeTableSlots);
export const HoursCellTemplate: React.FC<HoursCellTemplateProps> = memo((props: HoursCellTemplateProps) => {
    const {clickCell, value, listData} = props

    const handleClickHours = useCallback((evt) => {
        evt?.stopPropagation();
        clickCell(value, evt.target.value)
    }, [clickCell, value])
    // className={classNames('', {['open']: !listData[key]})}
    return (
        <div className={classNames('custom-call')} onClick={handleClickHours}>
            <ul className="call-time-list">
                {
                    slots.map(([key, slot]) => (
                            <li
                                value={key}
                                key={`custom_call_${listData[key]}_${slot.view}`}
                            >
                                {slot.view} {
                                listData[key]?.map((item) => (
                                    <Badge
                                        key={`${item.content}__${item.type}`}
                                        status={item.type as BadgeProps['status']}
                                        text={item.content}
                                        style={{pointerEvents: "none", fontSize: '12px'}}
                                    />
                                ))
                            }
                            </li>
                        )
                    )
                }
            </ul>
        </div>
    )
});
