import {
    MEASUREMENT_FORM_LOADING,
    MEASUREMENT_FORM_DATA,
    MEASUREMENT_FORM_FAILED,
    MEASUREMENT_ENUMS_LOADING,
    MEASUREMENT_ENUMS_DATA,
    MEASUREMENT_ENUMS_FAILED,
    MEASUREMENT_FORM_ID_UPDATE,
} from './actions'
import { IMeasurementState } from './interfaces'

const initialState: IMeasurementState = {
  form: {
    data: null,
    isLoading: false,
    error: '',
  },
  enums: {
    data: null,
    isLoading: false,
    error: '',
  },
  redirectUrl: undefined
}

export const measurementReducer = (
    state = initialState,
    {type, payload},
) => {
  switch (type) {
    case MEASUREMENT_FORM_LOADING:
      return {
        ...state,
        id: null,
        form: {
          ...state['form'],
          isLoading: true,
          data: null,
          error: ''
        }
      }
    case MEASUREMENT_FORM_DATA:
      return {
        ...state,
        form: {
          ...state['form'],
          isLoading: false,
          data: payload,
          error: ''
        }
      }
    case MEASUREMENT_FORM_FAILED:
      return {
        ...state,
        form: {
          ...state['form'],
          isLoading: false,
          error: payload
        }
      }
    case MEASUREMENT_FORM_ID_UPDATE:
      return {
        ...state,
        id: payload,
      }
    case MEASUREMENT_ENUMS_LOADING:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: true,
          data: null,
          error: ''
        }
      }
    case MEASUREMENT_ENUMS_DATA:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: false,
          data: payload,
          error: ''
        }
      }
    case MEASUREMENT_ENUMS_FAILED:
      return {
        ...state,
        enums: {
          ...state['enums'],
          isLoading: false,
          error: payload
        }
      }
    default:
      return state
  }
}
