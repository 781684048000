import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import {HStack, VStack} from "../../../../../ui/Stack";
import {Alert, Form, Modal, Skeleton} from "antd";
import {HSteps} from "../../../../../ui/Steps";
import {DefaultJsonForm} from "../../../../../components/v2/default-json-form";
import {useDispatch, useSelector} from "react-redux";
import {
    getHrFormData,
    getHrFormError,
    getHrFormLoading,
    getVisibleFormData,
} from "../../selectors";
import {useDebounce} from "../../../../../hooks/useDebounce/useDebounce";
import {useHrSaveResultForms} from "../../hooks/useHrSaveResultForms";
import {HrModal} from "../hr-modal";
import {isFormDataRequired} from "../../../../../../lib/utils/collections";
import {nextStage} from "../../../../../store/hr/action-creators";
import {HrFormsHeader} from "./hr-form-header/hr-form-header";

interface HrFormsProps {
    className?: string
    id: string | number,
}

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 24},
    },
}


export const HrForms: React.FC<HrFormsProps> = memo((props: HrFormsProps) => {
    const {id} = props;
    const hrFormData = useSelector(getHrFormData);
    const hrFormIsLoading = useSelector(getHrFormLoading);
    const hrFormError = useSelector(getHrFormError);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState<boolean>();
    const visibleFormData = useSelector(getVisibleFormData);
    const {saveResult} = useHrSaveResultForms();
    const formRef = useRef<typeof visibleFormData>()

    useEffect(() => {
        if (visibleFormData) {
            formRef.current = visibleFormData
        }
    }, [visibleFormData] )

    const saveCase = useCallback(async (isNextStage = false) => {
            await saveResult(
                id,
                formRef.current,
                hrFormData?.stages?.current,
                isOpenModal,
                setIsOpenModal,
                isNextStage
            )
        },
        [history, id, formRef]
    )
    const debounceSaveCase = useDebounce(saveCase, 1000);


    const onFormDataCandidate = useCallback(
        async ({formData}) => {
            if (!formRef.current) {
                return;
            }
            formRef.current.candidate = {...formData}
            debounceSaveCase();

        }, [formRef, debounceSaveCase]
    )

    const onFormDataRequest = useCallback(
        async ({formData}) => {
            if (!formRef.current) {
                return;
            }
            formRef.current.request = {...formData}
            debounceSaveCase();
        }, [formRef, debounceSaveCase]
    )

    const handleCancel = useCallback(() => {
        setIsOpenModal(false);
    }, [setIsOpenModal]);

    const onFormDataModal = useCallback(
        async ({formData}) => {
            if (!formRef.current) {
                return;
            }
            const isFilesEdit = JSON.stringify(formData) === JSON.stringify(formRef.current?.modal);
            if (isFilesEdit) {
                return;
            }
            formRef.current.modal = {...formData}
        }, [formRef]
    )

    const handleOk = useCallback(async () => {
        if (!formRef.current) {
            return;
        }
        const isRequiredModal = isFormDataRequired(formRef.current.modal, formRef.current.modalFieldsSchema)
        if (!isRequiredModal) {
            Modal.warning({
                title: 'Предупреждение',
                content: 'Заполните все обязательные поля'
            })
            return
        }
        setIsDisabled(true);
        setIsOpenModal(false);
        await saveCase(true);
        setIsDisabled(false);
    }, [setIsDisabled, saveCase, formRef]);


    const handleOnClick = useCallback(async () => {
        setIsDisabled(true);
        await saveCase(true);
        setIsDisabled(false);
    }, [saveCase])

    if (hrFormError && !hrFormIsLoading && !hrFormData) {
        return (
            <HStack gap={"8"} className={'mapWrapper'}>
                При выполнении запроса возникла ошибка.
                Попробуйте перезагрузить страницу или открыть карточку повторно.
            </HStack>
        )
    }

    return (
        <>
            <HrFormsHeader
                id={id}
                dateCreated={visibleFormData ? visibleFormData?.dateCreated : ''}
                stage={hrFormData?.stages?.current}
                nextStageOnClick={handleOnClick}
                isDisabled={isDisabled}
            />
            <HStack gap={"8"} className={'mapContent'} max>
                {hrFormError && !hrFormIsLoading ? (
                    <div className={'alertWrapper'}>
                        <Alert
                            message="При выполнении операции возникла ошибка:"
                            showIcon
                            type="error"
                            description={hrFormError}
                        />
                    </div>
                ) : null}
                {!hrFormIsLoading ? (
                    <VStack gap={"16"} max align={"stretch"} className={'hrForm'}>

                        {hrFormData?.stages && (
                            <HSteps items={Object.values(hrFormData?.stages?.items)}
                                    current={hrFormData?.stages?.current}
                                    history={hrFormData?.stages?.history}
                            />)
                        }

                        <HStack gap={"8"} max>
                            <span className={'h3'}>Кандидат</span>
                        </HStack>

                        {
                            visibleFormData && <DefaultJsonForm
                                formData={visibleFormData?.candidate}
                                schema={visibleFormData?.candidateFieldsSchema}
                                onChange={onFormDataCandidate}
                                orientation="horizontal"
                            />
                        }

                            <div className={'h3'}>Обращение</div>

                            {
                                visibleFormData && <DefaultJsonForm
                                    formData={visibleFormData.request}
                                    schema={visibleFormData.requestFieldsSchema}
                                    onChange={onFormDataRequest}
                                    orientation="horizontal"
                                />
                            }

                    </VStack>
                ) : (
                    <Skeleton active/>
                )}
                {
                    visibleFormData && (
                        !hrFormIsLoading && visibleFormData?.modal && (
                            <HrModal
                                schema={visibleFormData.modalFieldsSchema}
                                onChange={onFormDataModal}
                                formData={visibleFormData.modal}
                                isOpen={isOpenModal}
                                onCancel={handleCancel}
                                onOk={handleOk}
                                centered={true}
                                disabled={isDisabled}
                                title={visibleFormData?.modalTitle}
                            />
                        )
                    )
                }
            </HStack>
        </>
    )
})
