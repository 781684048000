import React, {useEffect, useMemo, memo} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import B2BLayout from '../../../layouts/b2b'
import {createSession, getDeliveryId, getDeliveriesFormError, getDeliveriesFormLoading} from './selectors'
import {
  DefaultJsonFormContextProps
} from '../../../components/v2/default-json-form/context/default-json-form-context-props'
import {getDeliveriesEnums, getDeliveriesForm} from '../../../store/deliveries/action-creators'
import {HStack} from '../../../ui/Stack'
import DefaultJsonFormContext from '../../../components/v2/default-json-form/context/default-json-form-context'
import DeliveriesTabs from './tabs/deliveries-tabs'
import {DeliveriesForms} from './deliveries-forms/deliveries-forms'

const breadcrumbs = [
  {
    href: '/logistics/deliveries',
    title: 'Логистика',
  },
  {
    href: '/logistics/deliveries',
    title: 'Доставки',
  }
]

function AdminNewObjectPage() {
  const {id} = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const deliveryId = useSelector(getDeliveryId)
  const entityType = 'delivery'
  const deliveriesFormIsLoading = useSelector(getDeliveriesFormLoading)
  const deliveriesFormError = useSelector(getDeliveriesFormError)
  const {sessionData} = useSelector(createSession)
  const isShowByAccess = sessionData.roles.includes(19)

  const defaultProps = useMemo(
    (): DefaultJsonFormContextProps => ({
      entityId: id,
      entityType: entityType,
    }), [id])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      const getInitPage = async (id) => {
        //await dispatch(getDeliveriesEnums(id))
        await dispatch(getDeliveriesForm(id))
      }

      getInitPage(id).catch(console.error)
    }
    return () => {
      mounted = false
    }
  }, [id, isShowByAccess, dispatch])

  useEffect(() => {
    if (id === 'new' && deliveryId) {
      history.push({
        pathname: `/logistics/deliveries/${deliveryId}`,
      })
    }
    return () => {}
  }, [id, deliveryId])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {
        (deliveriesFormError && !deliveriesFormIsLoading) ? (
          <HStack gap={"8"} className={'mapWrapper'}>
            При выполнении запроса возникла ошибка.
            <br/>
            {deliveriesFormError}
            <br/>
            Попробуйте перезагрузить страницу или открыть обращение повторно.
          </HStack>
        ) : (
          <DefaultJsonFormContext.Provider value={defaultProps}>
            <DeliveriesForms id={id}/>
            <DeliveriesTabs id={id}/>
          </DefaultJsonFormContext.Provider>
        )
      }
    </B2BLayout>
  )
}

export default memo(AdminNewObjectPage)
