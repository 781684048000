import React, { FC } from 'react';
import { FieldGroup, FieldName, PhoneButton } from '../../measurements-edit/styles'
import { PhoneIcon } from '../../../../assets/icons'
import { IManagerCardProps } from './interface'
import Card from 'antd-mobile/lib/card';
import WhiteSpace from 'antd-mobile/lib/white-space';

export const ManagerCard: FC<IManagerCardProps> = (props) => {
  const { data, onCall } = props
  const { name, phone /*, email*/ } = data

  return (
    <>
      <Card>
        <Card.Header
          title="Менеджер"
          extra="Контакты"
        />
        <Card.Body>
          <FieldGroup>
            <FieldName>Имя:</FieldName>
            {name}
          </FieldGroup>
          <FieldGroup>
            <FieldName>
              Телефон:
            </FieldName>
            <PhoneButton
              href={`tel:${phone}`}
              onClick={onCall(phone)}
            >
              {phone}&nbsp;<PhoneIcon />
            </PhoneButton>
          </FieldGroup>
          {/*<FieldGroup>
            <FieldName>Email:</FieldName>
            <a href={`mailto: ${email}`}>
              {email}
            </a>
           </FieldGroup>*/}
        </Card.Body>
      </Card>
      <WhiteSpace size="lg" />
    </>
  )
}
