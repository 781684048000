import React, { useState, useEffect, useRef, memo, useCallback, MutableRefObject } from 'react'
import {Button, Modal} from 'antd'
import { DefaultJsonForm } from '../../../components/v2/default-json-form'
import { saveBrand } from './saveBrand'
import { ISaveBrandRequest } from './types'

interface ModalAddBrandProps {
  isOpen?: boolean
  onClose?: (
    data?: {
      id?: number,
      name?: string
    }
  ) => void
}

export interface ISaveBrand {
  name?: string
  code?: string
  logo_url?: string
}

export const ModalAddBrand: React.FC<ModalAddBrandProps> = memo((props: ModalAddBrandProps) => {
  const {isOpen, onClose} = props
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>
  const [value, setValue] = useState<ISaveBrand>({
    name: undefined,
    code: undefined,
    logo_url: undefined,
  })
  const [isDisabled, setIsDisabled] = useState(false)


  useEffect(() => {
    const timer = setTimeout(() => {
      inputRef.current?.focus()
    }, 200)
    return () => clearTimeout(timer)
  }, [isOpen])

  const handleSaveBrand = useCallback(async () => {
    if (value?.name?.trim() === '') {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Название не может быть пустым'
      })
      return
    }
    if (value?.code?.trim() === '') {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Код не может быть пустым'
      })
      return
    }
    await saveBrand(value as ISaveBrandRequest)
      .then(result => {
        if (result) {
          setIsDisabled(true)
          handleOnClose({ id: result?.id, name: result?.name})
        }
      })
  }, [value, setValue, setIsDisabled])

  const handleOnClose = useCallback(data => {
    if (onClose) {
      onClose?.(data)
      setValue({
        name: undefined,
        code: undefined,
        logo_url: undefined,
      })
    }
  }, [onClose, value, setValue])

  const formData = {
    logo_url: null,
    name: null,
    code: null,
  }

  const schema = {
    type: 'object',
    required: ['name', 'code'],
    properties: {
      name: {
        title: 'Название',
        type: 'string',
        disabled: false,
        required: true,
        options: {
          required: true,
        }
      },
      code: {
        title: 'Код',
        type: 'string',
        disabled: false,
        required: true,
      },
      logo_url: {
        format: 'file-s3',
        type: 'string',
        title: 'Лого Бренда',
        options: {
          disabled: false,
          isNewUrl: true
        },
      },
    }
  }

  const onChange = useCallback(({ formData, schema }) => {
    const url = formData?.logo_url?.[0]?.url
    setValue({
      name: formData.name,
      code: formData.code,
      logo_url: undefined
    })
    if (url) {
      setValue(value => ({
        ...value,
        logo_url: url,
      }))
    }
  }, [setValue, value])

  return (
    <Modal
      title="Добавить новый Бренд"
      visible={isOpen}
      onCancel={handleOnClose}
      footer={[
        <Button key="back" onClick={handleOnClose}>Отмена</Button>,
        <Button key="submit" disabled={isDisabled} onClick={handleSaveBrand}>Добавить</Button>,
      ]}
    >
      <DefaultJsonForm
        formData={formData}
        schema={schema}
        onChange={onChange}
        orientation="horizontal"
      />
    </Modal>
  )
})
