import fetchAPI from './fetch-api'

export const getPreparedSchema = (value: Array<any>) => {
  return value?.map(item => item.join('/'))
}

const prepareProperties = properties => {
  const result = {}
  const keys = properties ? Object.keys(properties) : []

  if (keys.length > 0) {
    keys.forEach(key => {
      const lowerKey = key.toLowerCase()
      result[lowerKey] = properties[key]
    })
  }

  return result
}

export interface IValues {
  name: string
  title: string
  description: string
  schema: {
    type: string
    required: string[]
    properties: []
  }
}

export const getPreparedSchemaData = (values: IValues) => ({
  'name': values.name ? `${values.name}`.toLowerCase() : '',
  'title': values.title,
  'description': values.description,
  'metadata': {},
  'type': values.schema.type,
  'required': 'required' in values.schema ? values.schema.required : null,
  'properties': prepareProperties(values.schema.properties),
  'definitions': {},
  'any-of': null,
  'one-of': null,
  'all-of': null,
})

export function localizedServerErrors(error: string): string {
  {
    const regexp = /^Schema "(.+)" is already exists$/
    if(regexp.test(error)){
      const [, name] = regexp.exec(error) as any[]
      return `Схема с индитификатором "${name}" уже существует`
    }
  }
  {
    const regexp = /^invalid schema name "(.+)"$/
    if(regexp.test(error)){
      const [, name] = regexp.exec(error) as any[]
      return `Схема имеет некорректный индетификатор "${name}"`
    }
  }
  return error
}

export function validationSchemaProperties(properties = {}){
  const propertiesKeys: Array<string> = Object.keys(properties)
  const propertiesValues: Array<any> = Object.values(properties)
  const errors: Array<string> = []

  if (propertiesKeys.length > 0) {
    propertiesValues.forEach((value, index) => {
      if(value.type !== "string"){
        return
      }
      if(value.format !== "platform-collection"){
        return
      }
      if(!('collection-name' in value) || (value['collection-name'] === '')){
        const filedName = propertiesKeys[index]
        errors.push(`В схеме справочника: ${filedName}, отсутствует ссылка на справочник`)
        return
      }
    })
  } else {
    errors.push('Добавьте хотя бы одно поле в конфигураторе структуры (схемы)')
  }

  return errors
}

export const createSchema = async (values) => {
  const requestBody = {
    'data': getPreparedSchemaData(values),
  }

  return await fetchAPI('/api/schemas', {
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
}

export const updateSchema = async (values) => {
  const requestBody = {
    'data': getPreparedSchemaData(values),
  }

  return await fetchAPI(`/api/schemas/${values.id}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
}

export const validateSchemaProperties:
  (props: {
    values: any;
    onSuccess?: () => void;
    onError?: (error: string[]) => void;
  }) => void = ({ values, onSuccess, onError }) => {
  const resultValidProperties = validationSchemaProperties(values?.schema?.properties)

  if (onError && onError.constructor === Function) {
    if (resultValidProperties.length > 0) {
      onError(resultValidProperties)
    }
  }

  if (onSuccess) {
    if (resultValidProperties.length === 0) {
      onSuccess()
    }
  }
}
