import React from 'react'
import { Select } from 'antd'
import { IYearFilterProps, IFilterOption } from '../interfaces/interfaces'

const { Option } = Select

const YearFilter: React.FC<IYearFilterProps> = ({ selectedYears, onYearChange, years, style }) => {
  return (
    <Select
      mode="multiple"
      value={selectedYears}
      onChange={onYearChange}
      style={style}
      placeholder="Все года"
      allowClear
    >
      {years.map(year => (
        <Option key={year.id} value={year.id}>
          {year.value}
        </Option>
      ))}
    </Select>
  )
}

export default YearFilter
