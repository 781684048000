import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Tooltip } from 'antd'
import { PlusOutlined, EditOutlined } from '@ant-design/icons'

import { ICollectionsGroup } from '../admin-edit-group-form'
import { ISelectGroup } from './interface'
import fetchAPI from '../../../lib/utils/fetch-api'
import { prepareGroups } from '../../utilits/groups'
import { AdminTreeSelect } from '../../components/admin-tree-node'
import { AdminEditGroup } from '../admin-edit-group'

export const SelectGroup: FC<ISelectGroup> = (props) => {
  const { onChange, initSelectedGroup, getCreateGroupURL, getUpdateGroupURL, getGroupsURL, targetData } = props
  const [selectedGroupId, setSelectedGroupId] = useState<number>()
  const [showGroupModal, setShowGroupModal] = useState(false)
  const [flatGroups, setFlatGroups] = useState<ICollectionsGroup[]>([])
  const [preparedGroups, setPreparedGroups] = useState<any[]>([])
  const [currentGroup, setCurrentGroup] = useState<ICollectionsGroup | undefined>()

  const getGroups = useCallback(async () => {
    let groups

    try {
      groups = await fetchAPI(getGroupsURL)
      setFlatGroups(groups['data'])
    } catch (error) {
      console.error('Something going wrong: ', error)
    }

    return groups['data']
  }, [getGroupsURL])

  const setEditData = useCallback((groupId?: number) => {
    if (flatGroups.length > 0) {
      const group = flatGroups.find(item => item.id === groupId)

      setSelectedGroupId(groupId)
      if (group) {
        setCurrentGroup(group)
      } else {
        setCurrentGroup(undefined)
      }
    }
  }, [flatGroups])

  useEffect(() => {
    getGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPreparedGroups(prepareGroups(flatGroups))
  }, [flatGroups])

  const handleChangeGroup = useCallback((value) => {
    setEditData(value)
    onChange(value)
  }, [onChange, setEditData])

  useEffect(() => {
    if (typeof initSelectedGroup !== 'undefined' && initSelectedGroup) {
      handleChangeGroup(Number(initSelectedGroup))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSelectedGroup, flatGroups])

  const groupEditorButtonTitle = useMemo(() => selectedGroupId
    ? 'Редактировать группу'
    : 'Создать группу',
    [selectedGroupId])

  const groupEditorButtonIcon = useMemo(() => selectedGroupId
    ? <EditOutlined />
    : <PlusOutlined />,
    [selectedGroupId])

  const handleOpenGroupModal = useCallback(() => {
    setShowGroupModal(true)
  }, [])

  const toggleGroupModal = useCallback(() => {
    setShowGroupModal(prevState => !prevState)
  }, [])

  const handleAfterGroupSave = useCallback((group) => {
    getGroups()
    setSelectedGroupId(group['id'])
    onChange(group['id'])
  }, [getGroups, onChange])

  const isEdit = useMemo(() => {
    return typeof selectedGroupId !== 'undefined'
  }, [selectedGroupId])

  return (
    <>
      <AdminTreeSelect children={preparedGroups} selectedId={selectedGroupId} onChange={handleChangeGroup} />
      <AdminEditGroup
        createURL={getCreateGroupURL}
        updateURL={getUpdateGroupURL}
        showModal={showGroupModal}
        group={currentGroup}
        groups={preparedGroups}
        toggleModal={toggleGroupModal}
        isEdit={isEdit}
        onAfterSave={handleAfterGroupSave}
        targetData={targetData}
      >
        <Tooltip placement="top" title={groupEditorButtonTitle}>
          <Button
            icon={groupEditorButtonIcon}
            onClick={handleOpenGroupModal}
          />
        </Tooltip>
      </AdminEditGroup>
    </>
  )
}
