import fetch from 'isomorphic-fetch'

function createHeaders(headers) {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  }
}


/**
 * @todo Add OAuth Bearer authenficiation support
 *
 * @param path Example: `/api/session` or `/session`
 * @param options
 */
async function fetchAPI(path: string, options: any = {}): Promise<Record<string, any>> {
  let result = {}

  try {
    const response = await fetch(path, {
      credentials: 'include',
      ...options,
      headers: createHeaders(options.headers),
    })

    if (response) {
      result = response.json()
    }
  } catch (e) {
    console.error(e)
  }

  return result
}

export default fetchAPI
