import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import dayjs from 'dayjs';
import { Table, Input, Form, Button, Space } from 'antd'
import { CloseSquareFilled, SaveFilled, EditTwoTone, DeleteTwoTone, MenuOutlined } from '@ant-design/icons'
// @ts-ignore
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import getTableTransformSchemaAndFormData, { objectFilter } from '../../../lib/utils/get-table-transform-schema-and-form-data'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getInputNode } from 'src/react-app/components/json-form-object-editor'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import './index.css'

function downloadObjectAsJson(exportObj, exportName) {
  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj, null, 2))
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', exportName + '.json')
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

function loadFileJson(target) {
  return new Promise((resolve, reject) => {
    if(Boolean(target?.files[0]) === false){
      return reject()
    }
    const fr = new FileReader()
    fr.onload = ({ target }) => {
      // @ts-ignore
      resolve(JSON.parse(target.result))
      // @ts-ignore
      target.value = null
    }
    fr.readAsText(target.files[0])
  })
}

const DragHandle = sortableHandle(() => (
  <button
    style={{
      marginLeft: 10,
      fontSize: 20,
      border: 'none',
      background: 'none',
      cursor: 'pointer',
    }}
  >
    <MenuOutlined style={{ cursor: 'pointer', color: '#1890ff', fontSize: 16 }} />
  </button>
))
  const SortableItem = sortableElement(props => {
  return <tr {...props}  />
});
const SortableContainer = sortableContainer(props => <tbody {...props} />);

function getEditInputNode(column){
  // @ts-ignore
  return getInputNode(column) ?? <Input />
}

function Cell({ editing, column, record, index, objects, dataRoles, children, ...restProps }) {
  const inputNode =  useMemo(() => getEditInputNode(column), [column])
  const formatText = useMemo(() => {
    if (record === undefined) {
      return
    }
    const { dataIndex } = column
    if (dataIndex === undefined) {
      return
    }

    const text = record[dataIndex]
    if (column?.view?.name === 'select-from-enum') {
      const oneOf = getDataOfType(column, 'oneOf', Array, [])
      const oneOfObject = Object.fromEntries(oneOf.map(item => [item['const'], item['title']]))
      return getDataOfType(oneOfObject, text, [String, Number], text)
    }
    if (column?.view?.name === 'select-from-roles') {
      if(Array.isArray(text) && Array.isArray(dataRoles) ){
         const formatText = text.map(id => {
          return getDataOfType(dataRoles.find(item => Number(id) === Number(item['id'])), 'title', [String, Number], '')
        }).join(', ')
        return formatText
      }
    }

    if (column?.type === 'boolean') {
      return text ? 'Да' : 'Нет'
    }
    if ((column?.type === 'string') === false) {
      return text
    }
    if(column?.format === 'date-time'){
      return dayjs(text).format('DD.MM.YYYY HH:mm')
    }
    if(column?.format === 'date'){
      return dayjs(text).format('DD.MM.YYYY')
    }
    if(column?.format === 'platform-collection'){
      const { value } = objects.find(({ id }) => id === text) || {}
      return value || text
    }

    return text
  }, [record, column, objects, dataRoles])
  if (editing) {
    return (
      <td {...restProps}>
        <Form.Item
          name={column?.dataIndex}
          style={{ margin: 0 }}
          className={'json-form-array-table-row-item'}
          rules={[
            {
              required: column?.required,
              message: ' ',
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      </td>
    )
  }
  if ((column?.type === 'array') === true) {
    return <td {...restProps}>{formatText}</td>
  }
  if ((column?.type === 'string') === false) {
    return <td {...restProps}>{children}</td>
  }

  return <td {...restProps}>{formatText}</td>
}

function columnsTitleTransform(columns){
  return columns.map(item => {
    if (item.required === true) {
      return {
        ...item,
        className: 'ant-column-item-required drag-visible-hidden'
      }
    }
    return item
  })
}

const EditableTable = ({ name, schema, formData, onChange }) => {

  const fileRef = useRef(null)

  const { data, columns } = useMemo(() => {
    const { tables } = getTableTransformSchemaAndFormData(schema, formData)
    return tables.find(table => table.name === name)
  }, [name, schema, formData])
  const [objects, setObjects] = useState<any>([])
  const [dataRoles, setDataRoles] = useState<any>([])


  const CellWrapper: any = useCallback(props => <Cell {...props} objects={objects} dataRoles={dataRoles} />, [objects, dataRoles])
  useEffect(() => {
    (async () => {
      const fields = Object.keys(
          objectFilter(schema['properties'][name]['items']['properties'], ([,value]) => {
            const isString = value?.type === 'string'
            const isCollection = value?.format === 'platform-collection'
            return isString && isCollection
          })
      )
      const ids = data.map(item => {
        const ids = objectFilter(item, ([key]) => fields.includes(key))
        return Object.values(ids)
      }).flat().filter(id => id).sort().join(',')
      const objectsFormatIds = objects.map(({ id }) => id).sort().join(',')
      if(ids === ''){
        return
      }
      if(ids === objectsFormatIds){
        return
      }
      const objectsResult = await fetchAPI(`/api/objects?ids=${ids}`)
      const objectsFormat = objectsResult['data'].map(({ id, data }) => {
        const value = Object.values({ ...data['attributes'], ...data['fieldEnumNames'] }).join(
          ' | ',
        )
        return { id, value }
      })
      setObjects(objectsFormat)
    })();
    (async () => {
      const result = await fetchAPI(`/api/roles`)
      const roles = getDataOfType(result, 'data', Array, [])
      setDataRoles(roles)
    })();
    // eslint-disable-next-line
  },[name, data, schema])

  const onSaveData = useCallback(data => {
    const newFormData = { ...formData, [name]: data.map(({ key, ...item }) => item) }
    onChange({ formData: newFormData })
  }, [onChange, name, formData])
  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')
  const isEditing = useCallback( record => record.key === editingKey, [editingKey])
  const addRow = useCallback(() => {
    const addColumn = Object.fromEntries(columns.map(({ dataIndex }) => [dataIndex, '']))
    const newData = [{ key: 0, ...addColumn }].concat(
      data.map(({ key, ...item }) => ({ key: Number(key) + 1, ...item }))
    )
    onSaveData(newData)
    form.setFieldsValue(addColumn)
    // @ts-ignore
    setEditingKey(0)
  }, [data, columns, onSaveData, form])
  const deleteRecord = useCallback( ({ key: currentKey }) => {
    onSaveData(data.filter(({ key }) => key !== currentKey))
  }, [data, onSaveData])
  const handleEdit = useCallback(record => {
    const addColumn = Object.fromEntries(columns.map(({ dataIndex }) => [dataIndex, '']))
    form.setFieldsValue({ ...addColumn, ...record })
    setEditingKey(record.key)
  }, [columns, form])
  const handleCancel = useCallback(() => setEditingKey(''), [])
  const handleSave = useCallback(async key => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex(item => key === item.key)
      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        onSaveData(newData)
        setEditingKey('')
      } else {
        newData.push(row)
        onSaveData(newData)
        setEditingKey('')
      }
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo)
    }
  }, [data, form, onSaveData])
  const mergedColumns = useMemo(() => [
    ...columnsTitleTransform(columns),
    {
      title: 'Действие',
      dataIndex: 'operation',
      className: 'drag-visible',
      width: '160px',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <button
              type='button'
              onClick={() => handleSave(record.key)}
              style={{
                marginLeft: 10,
                fontSize: 20,
                border: 'none',
                background: 'none',
                cursor: 'pointer',
              }}
            >
              <SaveFilled style={{color: '#52acff'}} />
            </button>
            <button
              type='button'
              onClick={handleCancel}
              style={{
                marginLeft: 10,
                fontSize: 20,
                border: 'none',
                background: 'none',
                cursor: 'pointer',
              }}
            >
              <CloseSquareFilled style={{color: '#52acff'}} />
            </button>
          </span>
        ) : (
          <>
            <button
              type='button'
              disabled={editingKey !== ''}
              onClick={() => handleEdit(record)}
              style={{
                marginLeft: 10,
                fontSize: 20,
                border: 'none',
                background: 'none',
                cursor: 'pointer',
              }}
            >
              <EditTwoTone />
            </button>
            <button
              type='button'
              style={{
                marginLeft: 10,
                fontSize: 20,
                border: 'none',
                background: 'none',
                cursor: 'pointer',
              }}
              onClick={() => deleteRecord(record)}
            >
              <DeleteTwoTone />
            </button>
            <DragHandle />
          </>
        )
      },
    },
  ].map(column => {
    if (!column.editable) {
      return column
    }
    return {
      className: 'drag-visible-hidden',
      ...column,
      onCell: record => ({
        record,
        column,
        editing: isEditing(record),
      }),
    }
  }), [columns, isEditing, editingKey, handleEdit, handleSave, handleCancel, deleteRecord])
  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return
    }
    //Поменяем местами значения Array
    const newData = data.map((item, index, array) => {
      if(index === newIndex){
        return array[oldIndex]
      }
      if(index === oldIndex){
        return array[newIndex]
      }
      return item
    })
    onSaveData(newData)
  }, [data, onSaveData])
  const DraggableBodyRow = useCallback(({ className, style, ...restProps }) => {
    const index = data.findIndex(({ key }) => key === restProps['data-row-key'])
    return <SortableItem index={index} {...restProps} style={style} />
  }, [data])
  const DraggableContainer = useCallback(
    props => {
      return (
        <SortableContainer
          useDragHandle
          helperClass="row-dragging"
          onSortEnd={onSortEnd}
          {...props}
        />
      )
    },
    [onSortEnd],
  )
  const components = useMemo(() => ({
    body: { cell: CellWrapper, wrapper: DraggableContainer, row: DraggableBodyRow }
  }), [DraggableContainer, DraggableBodyRow, CellWrapper])


  const onExport = useCallback(() => {
    const fields = columns.map(item => item['dataIndex'])
    const dataExport = data.map(item => objectFilter(item, ([key]) => fields.includes(key)))
    downloadObjectAsJson(dataExport, 'table')
  },[data, columns])

  const onImport = useCallback(async () => {
    // @ts-ignore
    fileRef?.current?.click()
  },[])

  const onFile = useCallback(async ({ target }) => {
    const data: any[] = (await loadFileJson(target)) as any[]
    if(Array.isArray(data) === false){
      return
    }
    const fields = columns.map(item => item['dataIndex'])
    const dataImport = data
                        .map(item => objectFilter(item, ([key]) => fields.includes(key)))
                        .map((item, index) => ({ ...item, key: index }))
    onSaveData(dataImport)
  }, [columns, onSaveData])

  return (
    <Form form={form} component={false} onFinish={() => null} onFinishFailed={alert}>
      <input type='file' style={{ display: 'none' }} ref={fileRef} onChange={onFile}/>
      <Space style={{ marginBottom: 20 }}>
        <Button disabled={String(editingKey).trim() !== ''} onClick={addRow} >Добавить</Button>
        <Button disabled={String(editingKey).trim() !== ''} onClick={onExport} >Экспортировать</Button>
        <Button disabled={String(editingKey).trim() !== ''} onClick={onImport} >Импортировать</Button>
      </Space>
      <Table
        components={components}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  )
}

export default EditableTable
