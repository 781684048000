import React, { useState, useCallback, useMemo } from 'react'
import { Upload, Progress } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

const { Dragger } = Upload;



function UploadFiles({ fileOnError, fileOnDone, targetId = NaN }) {
  const [fileList, setFileList] = useState([])
  const [percent, sePercent] = useState(0)

  const localOnChange = useCallback(({ file, fileList }) => {
    const isError = 'error' in file
    const isDone = file.status === 'done'
    if(isError){
      fileOnError(file)
    }
    if(isDone) {
      fileOnDone(file)
    }

    const [count, percentAll] = fileList.reduce(([count, percent], file) => {
      const isError = 'error' in file
      if(isError){
        return [count, percent]
      }
      return [count + 1, percent + file.percent]
    }, [1, 100])
    const percent = Math.trunc(percentAll / count) 
    const fileListFilter = fileList.filter(({ status }) => ['done', 'error'].includes(status) === false)
    setFileList(fileListFilter)
    sePercent(percent)
  }, [fileOnError, fileOnDone])

  const props = useMemo(() => ({
    name: 'file',
    multiple: true,
    action: Number.isInteger(targetId) ? `/api/upload/${targetId}`: '/api/upload',
  }), [targetId])

  return (
    <Dragger {...props} onChange={localOnChange} fileList={fileList}>
      <p className="ant-upload-drag-icon">
        {percent ? <Progress type="circle" percent={percent} />: <InboxOutlined />}
      </p>
      <p className="ant-upload-text">Нажмите или перетащите для загрузки файлов</p>
      <p className="ant-upload-hint">
        Вы можете добавить сразу несколько файлов
      </p>
    </Dragger>
  )
}

export default UploadFiles