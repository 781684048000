import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import B2BLayout from '../../../layouts/b2b'
import { Button, Form, Input, Popconfirm, Table, Typography } from 'antd'
import { EditOutlined, PlusOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { ButtonActionWrapper } from '../../../components/list-operations/styles'
import { useHistory, useParams } from 'react-router-dom'
import { objectToUrl } from '../../utils'

const requiredFields = ['name']

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          rules={[
            {
              required: requiredFields.includes(dataIndex),
              message: `Обязательное поле ${title}!`
            }
          ]}
        >
          <Input ref={record.isNew ? record.inputRef : null} />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

type TablePaginationPosition = 'bottomCenter'

interface IEnumsDataType {
  id: number
  value: null | string
}

interface IRecord {
  id: number
  value: null | string
  collection?: null | string
  sort?: number
  deleted_at?: null | string
  collection_id?: number
}

const Enum = () => {
  const {id} = useParams() as any
  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(() => (
    [{
      href: '/admin',
      title: 'Админка',
    },{
      href: '/admin/enum-collections',
      title: 'Коллекции',
    }]
  ), [])

  const [form] = Form.useForm()
  const [data, setData] = useState<IEnumsDataType[]>([])
  const [editingKey, setEditingKey] = useState<null|number>(null)
  const [collectionName, setCollectionName] = useState<null|string>(null)
  const [loading, setLoading] = useState(false)
  const [sorter, setSorter] = useState<any>({})
  const history = useHistory()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 10,
    position: [bottomCenter],
    showSizeChanger: false
  })

  const isEditing = record => record.id === editingKey

  /**
   * Обновляет состояние пагинации
   */
  const updatePagination = useCallback(newState => {
    setPagination({
      ...pagination,
      ...newState
    })
  }, [pagination, setPagination])

  useEffect(() => {
    getData()
  }, [])

  useEffect( () => {
    setPagination( cur => ({ ...cur, disabled: editingKey !== null}) )
  }, [editingKey])

  /**
   * Получает записи
   */
  const getData = useCallback(() => {
    try {
      setLoading(true)
      const urlParams = Object.fromEntries(new URLSearchParams(location.search))
      const url = location.search.length ? `${id}?${objectToUrl(urlParams)}` : id
      fetchAPI(`/api/enum-collection/${url}`)
        .then(({data, pagination, sorter, collection_name}) => {
          if (data) {
            updatePagination(pagination)
            setSorter(sorter)
            setData(data)
            setCollectionName(collection_name)
          }
          setLoading(false)
        })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [])


  const edit = record => {
    form.setFieldsValue({value: '', ...record})
    setEditingKey(record.id)
  }

  const cancel = (id) => {
    if (id === 0) {
      setData(rows => rows.filter(item => item.id !== id))
    }
    form.setFieldsValue({value: ''})
    setEditingKey(null)
  }

  const save = async (rowId: number) => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex(item => rowId === item.id)
      const newValue = newData[index]?.id === 0
      const valueId = newData[index]?.id
      if(newValue)
        row.collection_id = id
      if (index > -1) {
        const url = newValue ? '/api/enum/new' : `/api/enum/${valueId}`
        fetchAPI(
          url,
          {
            method: 'POST',
            body: JSON.stringify(row)
          })
          .then(result => {
            const {value} = result
            setData(data => data.map(item => {
              if (item?.id === 0 || item?.id === rowId)
                item = { id: result.id, value }
              return item
            }))
            setEditingKey(null)
            form.setFieldsValue({value: ''})
          })
          .catch(e => {
            console.error('Save error', {e})
          })
      }
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo)
    }
  }

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param filters
   * @param sorter
   */
  const handleTableChange = (pagination, filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    let current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined || current?.sort_order === null) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({search: urlParams})
    getData()
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 60,
      render: v => (v !== 0) ? v : null,
      sorter: editingKey === null,
      sortOrder: (sorter.sort_field === 'id') ? sorter.sort_order : null
    },
    {
      title: 'Значение',
      dataIndex: 'value',
      editable: true,
      sorter: editingKey === null,
      sortOrder: (sorter.sort_field === 'value') ? sorter.sort_order : null
    },
    {
      title: 'Действия',
      dataIndex: 'operation',
      width: 110,
      style: '',
      render: (_: any, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span style={{display: 'flex', justifyContent: 'center'}} onClick={e => {e.stopPropagation()}}>
            <Typography.Link onClick={() => save(record.id)} style={{marginRight: 8}}>
            <ButtonActionWrapper title="Редактировать">
              <SaveOutlined />
            </ButtonActionWrapper>
            </Typography.Link>
            <ButtonActionWrapper title="Сохранить">
              <Popconfirm title="Изменения не сохранятся, отменить?" onConfirm={() => cancel(record.id)}>
                <StopOutlined />
              </Popconfirm>
            </ButtonActionWrapper>
          </span>
        ) : (
          <span style={{display: 'flex', justifyContent: 'center'}} onClick={e => {e.stopPropagation()}}>
            <Typography.Link disabled={editingKey !== null} onClick={() => edit(record)}>
              <ButtonActionWrapper title="Редактировать">
                <EditOutlined />
              </ButtonActionWrapper>
            </Typography.Link>
          </span>
        )
      },
    },
  ]

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record: IRecord) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      })
    }
  })

  const addRow = useCallback(() => {
    const newRow = {
      id: 0,
      value: null,
      isNew: true,
      inputRef: inputRef,
    }
    setData((rows) => [newRow, ...rows])
    setEditingKey(0)
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 0)
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10, alignItems: 'center' }}>
        <h3 style={{ fontWeight: 'bold', fontSize: 18, marginBottom: 0 }}>
          Список <span>&laquo;{collectionName}&raquo;</span>
        </h3>
        <Button onClick={addRow} disabled={editingKey !== null}>
          <PlusOutlined /> Добавить запись
        </Button>
      </div>
      <Form form={form} component={false}>
        <Table
          loading={loading}
          components={{body: {cell: EditableCell}}}
          size="small"
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          onChange={handleTableChange}
          pagination={false}
        />
      </Form>
    </B2BLayout>
  )
}

export default Enum
