import styled from "styled-components";

export const BackToMountingList = styled.a`
  font-size: 19px;
  color: #404040;
  padding: 21px 0;
  text-align: left;
`

export const FieldGroup = styled.div`
  display: flex;
  margin: 10px 0;
`

export const FieldName = styled.span`
  font-weight: bold;
  color: #108EE9;
  margin-right: 10px;
`;

export const ActionListItemText = styled.span`
  white-space: nowrap;
`
