export const adminMenuItems = [
  {
    key: '/admin/collections',
    title: 'Справочники',
  },
  {
    key: '/admin/schemas',
    title: 'Структуры',
  },
  {
    key: '/admin/registry',
    title: 'Регистры',
  },
  {
    key: '/admin/counters',
    title: 'Нумераторы',
  },
]

export const cardKeys = {
  'call-center/cases': 'case',
  'sales/orders': 'order',
  '/sales/measurements': 'measurement',
  'sales/mounting': 'mounting',
  'production-page': 'manufacture',
  'logistic-delivery': 'delivery',
  'call-center-telephony': 'phone'
}
