import type {ColumnsType} from 'antd/es/table'
import dayjs from 'dayjs'
import React from 'react'
import {DataType} from './interface'
import { Checkbox } from 'antd'
import {Value} from '../../elements'
import styled from 'styled-components'

const CheckboxWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 67px;
`

const Columns: ColumnsType<DataType> = [
  {
    title: 'Активно',
    dataIndex: 'is_active',
    key: 'is_active',
    width: 70,
    fixed: 'left',
    render: (value, row) => {
      return (
        <CheckboxWrapper
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <Checkbox
            defaultChecked={value}
            checked={value}
            onClick={e => {
              row.setOfficeState(row.id, !value)
            }}
          />
        </CheckboxWrapper>
      )
    }
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    fixed: 'left',
    sorter: true
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    sorter: true
  },
  {
    title: 'Город',
    dataIndex: 'city',
    key: 'city',
    width: 130,
    sorter: true
  },
  {
    title: 'Адрес',
    width: 250,
    dataIndex: 'address',
    key: 'address',
    sorter: true,
    render: val => <Value data={val}/>
  },
  {
    title: 'Дата добавления',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 170,
    sorter: true,
    render: key => dayjs(key).format('DD.MM.YYYY HH:mm')
  }
]
/*
const ColumnAction: ColumnsType<DataType> = [
  {
    title: 'Действие',
    key: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    align: 'center',
    render: (_, {id, deleteRecord}) => {
      return (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <DeleteButton id={id} deleteRecord={deleteRecord}/>
        </span>
      )
    }
  }
]

export function DeleteButton({id, deleteRecord}) {
  const localDeleteRecord = useCallback(() => deleteRecord(id), [deleteRecord, id])
  return (
    <Popconfirm
      title="Вы уверены?"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => localDeleteRecord()}
    >
      <ButtonActionWrapper title="Удалить">
        <DeleteTwoTone />
      </ButtonActionWrapper>
    </Popconfirm>
  )
}
*/
export const getColumns = (leader, sorter) => {
  return Columns.map(item => {
    if (item.key === sorter.sort_field)
      item.sortOrder = sorter.sort_order
    else
      delete item.sortOrder
    return item
  })
}

