import React, { useCallback } from 'react'
import { Tooltip } from 'antd'
import EditCard from './edit-card'
import { DeleteTwoTone } from '@ant-design/icons'
import { OperationsWrapper, ButtonActionWrapper, ToolTwoTone } from './styles'
import {createSelector} from "reselect";
import {useSelector} from "react-redux";


const createSession = createSelector(
    (state: any) => state.session,
    sessionData => sessionData,
)

function TableListOperations({ id, schema, deleteRecord}){
  const localDeleteRecord = useCallback(() => deleteRecord(id), [deleteRecord, id])
  const { sessionData } = useSelector(createSession)
  const leader = sessionData.roles.indexOf(19) !== -1
  return (
    <OperationsWrapper>
      <EditCard id={id} schema={schema} title="Редактирование">
        <ToolTwoTone />
      </EditCard>
        {leader &&
        (<Tooltip placement="top" title="Удалить">
          <ButtonActionWrapper onClick={localDeleteRecord}>
            <DeleteTwoTone/>
          </ButtonActionWrapper>
        </Tooltip>)
        }
    </OperationsWrapper>
  )
}

export default TableListOperations
