import fetchAPI from '../../../lib/utils/fetch-api'
import { IAvitoChat } from '../../pages/card-hr/new/ui/hr-chats/types'
import { CHAT_AVITO_ADD_ITEM } from '../chat_avito/actions'

export const GET_MENU_REQUEST = 'GET_MENU_REQUEST'
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS'
export const GET_MENU_ERROR = 'GET_MENU_ERROR'

export const getMenu = () => async dispatch => {
  dispatch({
    type: GET_MENU_REQUEST,
  })
  try {
    const response = await fetchAPI('/api/collections/mainmenu')

    if (response['errors']) {
      return dispatch({
        type: GET_MENU_ERROR,
        payload: response,
        error: true,
      })
    }

    return dispatch({
      type: GET_MENU_SUCCESS,
      payload: (response['data'] && response['data']['data']) || [],
      error: false,
    })
  } catch (err) {
    return dispatch({
      type: GET_MENU_ERROR,
      payload: err,
      error: true,
    })
  }
}
