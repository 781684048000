export const organization = {
  "title": "Организация",
  "type": "pointer",
  "view": {
    "name": "select-from-organizations"
  }
}

export const user = {
  "title": "Пользователь",
  "type": "pointer",
  "view": {
    "name": "select-from-user"
  }
}

export const stage = {
  "type": "string",
  "title": "Стадия",
  "enum": [
    "not-expired-and-not-done",
    "expired-and-not-done",
    "done",
  ],
  "oneOf": [
    {
      "const": "not-expired-and-not-done",
      "title": "Запланировано"
    },
    {
      "const": "expired-and-not-done",
      "title": "Просрочено"
    },
    {
      "const": "done",
      "title": "Завершено"
    }
  ],
  "view": {
    "name": "select-from-enum"
  }
}

export const region = {
  "title": "Регион",
  "type": "pointer",
  "view": {
    "name": "select-from-regions"
  }
}

export const dateRange = {
  "type": "string",
  "format": "date-range",
  "title": "Дата"
}


