export const organization = {
  "title": "Организация",
  "type": "pointer",
  "view": {
    "name": "select-from-organizations"
  }
}

export const user = {
  "title": "Менеджер",
  "type": "pointer",
  "view": {
    "name": "select-from-user"
  }
}

export const stage = {
  "type": "string",
  "title": "Стадия",
  "enum": [
    "new",
    "work",
    "expect-shipment",
    "shipment-date-changed",
    "shipped-from-production",
    "done",
  ],
  "oneOf": [
    {
      "const": "new",
      "title": "Новая"
    },
    {
      "const": "work",
      "title": "В работе"
    },
    {
      "const": "expect-shipment",
      "title": "Готов к отгрузке"
    },
    {
      "const": "shipment-date-changed",
      "title": "Дата отгрузки изменена"
    },
    {
      "const": "shipped-from-production",
      "title": "Отгружен"
    },
    {
      "const": "delivery",
      "title": "Доставка"
    },
    {
      "const": "mounting",
      "title": "Монтаж"
    },
    {
      "const": "done",
      "title": "Выполнено"
    }
  ],
  "view": {
    "name": "select-from-enum"
  }
}

export const dateRange = {
  "type": "string",
  "format": "date-range",
  "title": "Дата"
}