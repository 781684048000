import { Drawer } from 'antd'
import React, { useState, useRef, useEffect, useCallback } from 'react'
import {  useHistory } from 'react-router-dom'
import { Form, Alert, Skeleton, Button, Tabs, Space } from 'antd'
import { SaveOutlined, PlusOutlined } from '@ant-design/icons'
import {
  AlertWrapper,
} from 'src/react-app/pages/card-client/styles'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { DefaultJsonForm } from 'src/react-app/components/default-json-form'
import { createUISchemaVertical } from 'src/lib/utils/grid-fild-orientation'
import TabGeneral from 'src/react-app/pages/card-client/tab-general'
import TableCallLog from 'src/react-app/pages/card-client/table-call-log'
import {
  GeneralSpace
} from './styles'


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}



const schemaData =   {
  type: 'user',
  title: 'Сотрудник',
  description: 'Сотрудник',
  profile_schema: {
    type: 'object',
    properties: {
      email: {
        type: 'string',
        format: 'email',
        title: 'Почта'
      },
      name: {
        type: 'string',
        title: 'ФИО'
      },
      phone: {
        type: 'string',
        format: 'phone',
        title: 'Телефон 1'
      },
      'passport-issued': {
        type: 'string',
        title: 'Кем выдан паспорт'
      },
      'passport-number': {
        type: 'string',
        title: 'Серия и номер паспорта'
      },
    }
  } 
}
function AdminNewObjectPage({ isOpen, setOpen, id }) {
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)

  const saveCase = useCallback(async () => {
    const formData = { ...formDataRef.current }
    await fetchAPI(`/api/user-create-or-update/${id}`, {
      method: 'POST',
      body: JSON.stringify({
        'profile-type-id': 2,
        'profile-data': formData,
        'user-data': {},
      }),
    })

    //history.push('/call-center/clients')
  }, [id])

  const onClose = () => {
    setOpen(false)
  }

  const getData = useCallback(async () => {
    {
      const result = await fetchAPI(`/api/profiles-types?type=user`)
      const profilesTypes = getDataOfType(result, 'data', Array, [])
      const defaultPrifileId = getDataOfType(profilesTypes, '[0].id', [String, Number], null)
      const defaultSelectedSchema = getDataOfType(profilesTypes, '[0].profile-schema', Object, null)
      {
        const result = await fetchAPI(`/api/profiles?type=user&user-id=${id}`)

        const data = getDataOfType(result, 'data', Array, [])
        const profileData = getDataOfType(data, '[0].profile-data', Object, {})
        const prifileId = getDataOfType(data, '[0].id', [String, Number], defaultPrifileId)
        const prifileTypeId = Number(getDataOfType(data, '[0].profile-type', [String, Number], 1))
        const selectedSchema = schemaData['profile_schema']  

        formDataRef.current = { ...profileData }
        setMatchedJSONSchema(selectedSchema)
        const UISchema = createUISchemaVertical(selectedSchema)
        setUISchema(UISchema)
      }
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData()
  }, [getData])

  const onFormData = useCallback(({ formData }) => {
    formDataRef.current = formData
  }, [])

  const createOrder = useCallback(async () => {
    const result = await fetchAPI(`/api/web-hooks/order`, {
      method: 'POST',
      body: JSON.stringify({
        telephone: getDataOfType(formDataRef.current, 'phone', String, undefined),
        name: getDataOfType(formDataRef.current, 'name', String, undefined),
        email: getDataOfType(formDataRef.current, 'email', String, undefined),
        city: getDataOfType(formDataRef.current, 'city', String, undefined),
        address: getDataOfType(formDataRef.current, 'address', String, undefined),
        'address-object': getDataOfType(formDataRef.current, 'address', String, undefined),
      }),
    })

    console.log(result)
    onClose()
    const orderId = getDataOfType(result, 'data.id', Number, null)
    history.push(`/case/${orderId}/edit`)
    
  }, [history, onClose])

  return (
    <Drawer
      title='Карточка клиента'
      placement={'bottom'}
      closable={false}
      onClose={onClose}
      visible={isOpen}
      key={'bottom'}
      height={460}
      extra={
        <Space size={'small'}>
          <Button type='primary' icon={<PlusOutlined />} onClick={createOrder}>
            Создать заказ
          </Button>
          <Button onClick={saveCase} style={{ marginBottom: 16, marginTop: 16 }} type='primary'>
            <SaveOutlined />
            Сохранить
          </Button>
        </Space>
      }
    >
     <GeneralSpace>
      <Form {...formItemLayout} layout='horizontal' form={form} scrollToFirstError={true}>
        {pageErrors.length > 0 ? (
          <AlertWrapper>
            <Alert
              message='При выполнении операции возникла ошибка:'
              showIcon
              type='error'
              description={pageErrors.join('. ')}
            />
          </AlertWrapper>
        ) : null}
        {isDataLoading === false ? (
          UISchema && (
            <DefaultJsonForm
              formData={formDataRef.current}
              schema={matchedJSONSchema}
              onChange={onFormData}
              UISchema={UISchema}
            />
          )
        ) : (
          <Skeleton active />
        )}
      </Form>
      <Tabs type='card'>
        <Tabs.TabPane tab='История обращений' key='1'>
          <TabGeneral clientId={id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Журнал звонков' key='2'>
          <TableCallLog userId={id} />
        </Tabs.TabPane>
      </Tabs>
      </GeneralSpace>
    </Drawer>
  )
}

export default AdminNewObjectPage

