export const city = {
  "type": "string",
  "title": "Город",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const organization = {
  "type": "string",
  "title": "Организация",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const managers = {
  "type": "string",
  "title": "Менеджер",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const auditResponsiples = {
  "type": "string",
  "title": "Отв. аудита",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const orderAttribute = {
  "type": "string",
  "title": "Признак заказа",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const campaign = {
  "type": "string",
  "title": "Кампания",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const cancelTitle = {
  "type": "string",
  "title": "Причина закрытия заказа",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const deliveryResponsiples = {
  "type": "string",
  "title": "Отв. доставки",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const manufactureResponsiples = {
  "type": "string",
  "title": "Отв. производства",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const measurementResponsiples = {
  "type": "string",
  "title": "Отв. замера",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const medium = {
  "type": "string",
  "title": "Канал",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const mountingResponsiples = {
  "type": "string",
  "title": "Отв. монтажа",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const orderResponsiples = {
  "type": "string",
  "title": "Отв. консультации",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const source = {
  "type": "string",
  "title": "Источник",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const sourceAppeal = {
  "type": "string",
  "title": "Вид контакта",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}
export const stageCode = {
  "type": "string",
  "title": "Стадия заказа",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}