import dayjs from 'dayjs'

export function insertSpaceActivityTimeLine(ActivityTimeLineArray, index, cbSpace){
  const newArr = ActivityTimeLineArray.concat()
  let accDate = ''
  while ((newArr.length === index) === false) {
    const dateTarget = dayjs(newArr[index].date).format('DD.MM.YYYY')
    if (accDate === dateTarget) {
      index++
      continue
    }
    const insertItem = cbSpace(newArr[index].date, dateTarget)  
    newArr.splice(index, 0, insertItem)
    index += 2
    accDate = dateTarget
  }
  return newArr
}

export function insertSpaceActivityTimeLineSpace(dateTarget, dateTargetFormat){
  const isToday = dateTargetFormat === dayjs().format('DD.MM.YYYY')
  const insertItem = {
    id: dateTargetFormat,
    date: dateTarget,
    sort: dateTarget,
    type: 'space',
    body: {
      color: isToday ? '#2fc6f5' :'#dde5e8',
      colorText:  isToday ? '#FFF' :'#000',
      text: formatDate(dateTarget)
    }
  }
  return insertItem
}

export function formatDate(date){
  const isToday = dayjs().format('DD.MM.YYYY') === dayjs(date).format('DD.MM.YYYY')
  if (isToday) {
    return `сегодня`
  }
  const isYesterday = dayjs().subtract(1, 'day').format('DD.MM.YYYY') === dayjs(date).format('DD.MM.YYYY')
  if (isYesterday) {
    return `вчера`
  }
  return dayjs(date).format('D MMMM')
}

