import React, { useRef, useCallback } from 'react'
import { Modal, Input } from 'antd'
import InputMask from "react-input-mask"

function normalizePhone(value: string | number): string | number {
  if(typeof value !== 'string'){
   return value
 }
 return value.replace(/[-_() ]/g, '')
}


export function modalOpen() {
  let formData = {   'label': ''}
  return new Promise(resolve => {
    Modal.confirm({
      icon: null,
      title: 'Добавление телефона',
      content: <Content startFormData={formData} onChange={value => {
        formData = value
      }} schema={{}} />,
      onOk: () => new Promise((ok, err) => {
        const isRequired = formData?.['label']
        if(isRequired){
          resolve(formData)
          ok(null)
        }
        err()
      }),
      onCancel(){
        resolve(null)
      }
    })
  })
}

function Content({ onChange, schema, startFormData }){
  const formDataRef =  useRef(startFormData)

   
  const localOnChange = useCallback((data, key) => {
    Object.assign(formDataRef.current, { [key]: data })
    onChange(formDataRef.current)
  }, [onChange])

  return (
    <>
      <span>Укажите телефон</span>
      <InputMask mask="+7 (999) 999-99-99" onChange={({ target }) => localOnChange(normalizePhone(target.value), 'label')} className='ant-input' />
    </>
  )
}