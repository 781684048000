import React, { useRef, useCallback } from 'react';
import { Modal } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { MapContent, MapWrapper } from './styles';
import CardUser from './modal';

export function modalClose(event: React.MouseEvent): boolean {
  let target = event.target as HTMLElement;
  while (target && target.tagName) {
    if (target.tagName.toLowerCase() === 'div') {
      break;
    }
    target = target.parentElement as HTMLElement;
  }
  try {
    (target.children[0] as HTMLElement).click();
    return true;
  } catch {
    return false;
  }
}

function modalAddUser(id: number, org_id: number, users_count: number): Promise<any> {
  let data = {} as any;
  return new Promise(resolve => {
    Modal.confirm({
      icon: null,
      title: 'Добавить сотрудника',
      content: <Content onChange={modalClose} officeId={id} organizationId={org_id} data={data} users_count={users_count} />,
      onCancel: () => resolve(null),
      okButtonProps: {
        onClick: async (event) => {
          modalClose(event as React.MouseEvent);
          await data.save();
          resolve(null);
        }
      }
    });
  });
}

interface ContentProps {
  onChange: (event: React.MouseEvent) => void
  officeId: number
  organizationId: number
  data: any
  users_count: number
}

function Content({ onChange, officeId, organizationId, users_count, data }: ContentProps) {
  const formDataRef = useRef({})
  const localOnChange = useCallback(({ formData }) => {
    formDataRef.current = formData
    onChange(formData)
  }, [onChange])

  return (
    <BrowserRouter>
      <MapContent>
        <MapWrapper>
          <CardUser onChange={localOnChange} officeId={officeId} organizationId={organizationId} data={data} users_count={users_count} />
        </MapWrapper>
      </MapContent>
    </BrowserRouter>
  )
}

export default modalAddUser
