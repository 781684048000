import {ActivityItems, ActivityUpdateItem, ITimeline} from "../../components/v2/activity-timeline/types/types";
import {
    TIMELINE_ADD_ITEM,
    TIMELINE_CLEAR,
    TIMELINE_DATA,
    TIMELINE_DELETE_ITEM,
    TIMELINE_FAILED,
    TIMELINE_LOADING,
    TIMELINE_UPDATE_ITEM
} from "./actions";
import {setTimelineItem} from "../../components/v2/activity-timeline/services/setTimelineItem";
import {updateTimelineItemByIndex} from "../../components/v2/activity-timeline/services/updateTimelineItemByIndex";
import {updateTimelineItemByItemId} from "../../components/v2/activity-timeline/services/updateTimelineItemByItemId";
import {deleteTimelineItem} from "../../components/v2/activity-timeline/services/deleteTimelineItem";


const initialState: ITimeline = {
    isLoading: false,
    error: '',
    data: {
        items: [],
        itemsDates: [],
        total: undefined,
        offset: undefined,
        page: undefined,
        limit: undefined,
    }
}

export const timelineReducer = (
    state = initialState,
    {type, payload},
) => {
    switch (type) {
        case TIMELINE_LOADING:
            return {
                ...state,
                isLoading: true,
                error: '',
            }
        case TIMELINE_DATA:
            return {
                ...state,
                isLoading: false,
                error: '',
                data: payload
            }
        case TIMELINE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
                data: {
                    ...initialState
                }
            }
        case TIMELINE_CLEAR:
            return {
                ...state,
                isLoading: false,
                error: '',
                data: {
                    ...initialState
                }
            }
        case TIMELINE_ADD_ITEM:
            const newActivityItems = payload as ActivityItems[];
            let timelineItems: ActivityItems[] = state.data?.items || [];
            let timelineDaysItems = state.data?.itemsDates || [];

            if (!newActivityItems.length) {
                return state;
            }

            const {
                timelineItems: timelineItemsMod,
                timelineDates: timelineDaysItemsMod
            } = setTimelineItem(newActivityItems, timelineItems, timelineDaysItems);

            return {
                ...state,
                data: {
                    ...state.data,
                    items: [...timelineItemsMod],
                    itemsDates: [...timelineDaysItemsMod]
                }
            }
        case TIMELINE_UPDATE_ITEM: {
            const newDataItem = payload as ActivityUpdateItem;
            let timelineItems: ActivityItems[] = state.data?.items || [];
            let timelineDaysItems = state.data?.itemsDates || [];
            const timelineItemsMod = newDataItem?.index || newDataItem?.index === 0
                ? updateTimelineItemByIndex(newDataItem, timelineItems, timelineDaysItems)
                : updateTimelineItemByItemId(newDataItem, timelineItems, timelineDaysItems);

            return {
                ...state,
                data: {
                    ...state.data,
                    items: [...timelineItemsMod],
                }
            }
        }
        case TIMELINE_DELETE_ITEM: {
            const deletedItem = payload as ActivityUpdateItem;
            let timelineItems: ActivityItems[] = state.data?.items || [];
            let timelineDaysItems = state.data?.itemsDates || [];

            const {
                timelineItems: timelineItemsMod,
                timelineDates: timelineDaysItemsMod
            } = deleteTimelineItem(deletedItem, timelineItems, timelineDaysItems);

            return {
                ...state,
                data: {
                    ...state.data,
                    items: [...timelineItemsMod],
                    itemsDates: [...timelineDaysItemsMod]
                }
            }
        }
        default:
            return state
        }
    }
