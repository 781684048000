import React, { FC } from 'react';
import NoticeBar from 'antd-mobile/lib/notice-bar';

import { NoticeWrapper } from './styles';
import { IErrorsListProps } from './interface'
import WhiteSpace from 'antd-mobile/lib/white-space';

export const ErrorsList: FC<IErrorsListProps> = (props) => {
  const { pageErrors = [] } = props

  return (
    <>
      {(pageErrors.length > 0) ? (
        <>
          <NoticeWrapper>
            <NoticeBar mode="closable" icon={null}>
              При выполнении операции возникла ошибка: {pageErrors.join('. ')}
            </NoticeBar>
          </NoticeWrapper>
          <WhiteSpace size="lg" />
        </>
    ): null}
    </>
  )
}
