import React, { useRef, useCallback } from 'react'
import { Modal } from 'antd'
import { DefaultJsonForm } from 'src/react-app/components/default-json-form'
import { isFormDataRequired } from 'src/lib/utils/collections'
import { BrowserRouter } from 'react-router-dom'
import { MapContent, MapWrapper } from './styles'

const UISchema = {
  'year': {
    css: {
      gridColumn: '1 / span 24',
      gridRow: '1',
    },
  },
}

const selectedSchema = {
  type: 'object',
  required: ['year'],
  properties: {
    'year': {
      "type": "string",
      "title": "Выберите год",
      "enum": [
        "2023",
        "2024"
      ],
      "oneOf": [
        {
          "const": "2023",
          "title": "2023"
        },
        {
          "const": "2024",
          "title": "2024"
        }
      ],
      "view": {
        "name": "select-from-enum"
      }
    },
  },
}

export function modalClose(event){
  let target = event.target
  while (Boolean(target?.tagName) === true) {
    if (target?.tagName.toLowerCase() === 'div') {
      break
    }
    target = target?.parentNode
  }
  try{
    Array.from(target?.children as any[])[0].click()
    return true
  }catch{
    return false
  }
}

async function modalClaimsExport(): Promise<any> {
  let formData = {
    year: '2024'
  }
  return new Promise(async resolve => Modal.confirm({
    icon: null,
    title: 'Экспорт в эксель',
    content: <Content UISchema={UISchema} onChange={value => { formData = value }} />,
    onCancel: () => resolve(null),
    okButtonProps: { 
      onClick: async (event) => {
        const isRequired = isFormDataRequired(formData, selectedSchema)
        if (isRequired) {
          resolve(formData)
          modalClose(event)
        }
      }
    }
  }))
}

function Content({ onChange, UISchema }){
  const formDataRef = useRef({
    year: '2024'
  })
  const localOnChange = useCallback(({ formData }) => {
    formDataRef.current = formData
    onChange(formData)
  }, [onChange])

  return (
    /* @ts-ignore */
    <BrowserRouter>
    <MapContent>
      <MapWrapper>
        <DefaultJsonForm
          formData={formDataRef.current}
          schema={selectedSchema}
          onChange={localOnChange}
          UISchema={UISchema}
        />
      </MapWrapper>
      </MapContent>
    </BrowserRouter>
  )
}

export default modalClaimsExport