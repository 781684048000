import { useMemo } from 'react'

const useBreadcrumbs = () => {
  return useMemo(() => (
    [{
      href: '/admin',
      title: 'Админка',
    },
      {
        href: '/new/organizations',
        title: 'Организации',
      }]
  ), [])
}

export default useBreadcrumbs
