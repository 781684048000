import React from 'react'
import { Skeleton, Statistic } from 'antd'
import { StatisticsCard, StatisticsContainer } from '../styles'
import { IStatisticsProps } from '../interfaces/interfaces'

const Statistics: React.FC<IStatisticsProps & { loading: boolean }> = ({
                                                                         totalPayments,
                                                                         pricePerResponse,
                                                                         pricePerCandidate,
                                                                         totalResponses,
                                                                         totalInterviews,
                                                                         totalHired,
                                                                         totalAccepted,
                                                                         totalRejected,
                                                                         loading,
                                                                       }) => {
  return (
    <StatisticsContainer>
      <StatisticsCard>
        {loading ? (
          <Skeleton active />
        ) : (
          <Statistic
            title="Стоимость размещения вакансий"
            value={Math.round(totalPayments).toLocaleString('ru-RU')}
          />
        )}
      </StatisticsCard>
      <StatisticsCard>
        {loading ? (
          <Skeleton active />
        ) : (
          <Statistic
            title="Цена за отклик"
            value={Math.round(pricePerResponse).toLocaleString('ru-RU')}
          />
        )}
      </StatisticsCard>
      <StatisticsCard>
        {loading ? (
          <Skeleton active />
        ) : (
          <Statistic
            title="Цена за кандидата"
            value={Math.round(pricePerCandidate).toLocaleString('ru-RU')}
          />
        )}
      </StatisticsCard>
      <StatisticsCard>
        {loading ? (
          <Skeleton active />
        ) : (
          <Statistic title="Кол-во откликов" value={totalResponses.toLocaleString('ru-RU')} />
        )}
      </StatisticsCard>
      <StatisticsCard>
        {loading ? (
          <Skeleton active />
        ) : (
          <Statistic title="На стадии Собеседование" value={totalInterviews.toLocaleString('ru-RU')} />
        )}
      </StatisticsCard>
      <StatisticsCard>
        {loading ? (
          <Skeleton active />
        ) : (
          <Statistic title="Отказы" value={totalRejected.toLocaleString('ru-RU')} />
        )}
      </StatisticsCard>
      <StatisticsCard>
        {loading ? (
          <Skeleton active />
        ) : (
          <Statistic title="Приняты на работу" value={totalAccepted.toLocaleString('ru-RU')} />
        )}
      </StatisticsCard>
    </StatisticsContainer>
  )
}

export default Statistics
