import React from 'react'
import { Bar } from 'react-chartjs-2'
import { IBarChartProps } from '../interfaces/interfaces'

const BarChart: React.FC<IBarChartProps> = ({ data }) => {
  return (
    <div>
      <Bar data={data} />
    </div>
  )
}

export default BarChart
