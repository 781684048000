import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Form, Alert, Skeleton, Modal } from 'antd'
import { JSONSchema7 } from 'json-schema'
import { MapContent, MapWrapper } from './styles'
import fetchAPI from 'src/lib/utils/fetch-api'
import { isFormDataRequired } from 'src/lib/utils/collections'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { DefaultJsonForm } from 'src/react-app/components/default-json-form'
import { useDispatch } from 'react-redux'
import { reloadActionsTimeLine } from 'src/react-app/store/appeals/actions'
import ArrayMultiUserProfile from 'src/react-app/connectors/array-multi-user-profile'
import { getTaskStageId } from 'src/lib/utils/utils-task'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'


const createAppeals = createSelector(
  (state: any) => state.appeals,
  targetId => targetId
)
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

function formatFormData(formData) {
  const nextTime = formData?.date.split?.('T')?.[0] + 'T' + formData?.time.split?.('T')?.[1] 
	const date = formData.time ? nextTime : new Date(new Date(formData?.date || 0).setHours(17, 0)).toISOString()
  return {...formData, date }
}


const protoUISchema = {
  date: {
    css: {
      gridColumn: '1 / span 1',
      gridRow: '1',
    },
    options: {
      isDisabledDatePrev: true,
    },
  },
  time: {
    css: {
      gridColumn: '3 / span 5',
      gridRow: '1',
    },
    options: {
      isDisabledTimePrev: true,
    },
  },
  responsible: {
    css: {
      gridColumn: '1 / span 24',
      gridRow: '2',
    },
    render: ArrayMultiUserProfile,
  },
  'type-task': {
    css: {
      gridColumn: '1 / span 24',
      gridRow: '3',
    },
  },
  comment: {
    options: {
      type: 'textarea',
      minRows: 3,
      maxRows: 3,
    },
    css: {
      gridColumn: '1 / span 24',
      gridRow: '4',
    },
  },
  'appeal-number': {
    hidden: true,
  },
  contact: {
    hidden: true,
  },
  author: {
    hidden: true
  },
  'date-completion': {
    hidden: true
  },
  'status': {
    hidden: true
  }
}

const name = 'tasks'


export default function AdminNewObjectPage({ visible, onClose }: {visible: boolean, onClose}) {
  const dispatch = useDispatch()
  const { targetId } = useSelector(createAppeals)
  const { sessionData } = useSelector(createSession)
  const formDataRef = useRef<any>({})
  const isRequiredContentRef = useRef<boolean>(false)
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageSuccess, setPageSuccess] = useState<string[]>([])
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<JSONSchema7 | null>(null)
  const [form] = Form.useForm()
  const [UISchema, setUISchema] = useState<any>(protoUISchema)

  const getData = useCallback(async () => {
    const fields = [
      'title',
      'description',
      'type',
      'properties',
      'required',
      'created_by',
      'created_at',
      'updated_at',
    ].join(',')
    const responseSchemas = await fetchAPI(`/api/schemas?access_key=axioma&fields=${fields}`)
    const fetchedSchemas: JSONSchema7[] = getDataOfType(responseSchemas, 'data.data', Array, [])
    const selectedSchemas = fetchedSchemas.filter(item => item['name'] === name)
    const selectedSchema = (typeof selectedSchemas[0] === 'object') ? selectedSchemas[0] : null
    if (selectedSchema === null) {
      setPageErrors(['Некорректный ответ сервера при cхемы коллекции'])
      return
    }
    const responseMetadata = await fetchAPI(`/api/collections/${name}/metadata`)
    const data = getDataOfType(responseMetadata, 'data', Object, null)
    if (data === null) {
      setPageErrors(['Некорректный ответ сервера при получении метаданных коллекции'])
      return
    }

    formDataRef.current = data
    setPageErrors([])

    delete selectedSchema?.['properties']?.['time']
    if(selectedSchema.required)
      selectedSchema.required = [...selectedSchema.required , 'responsible']
    setMatchedJSONSchema(selectedSchema)
    setIsDataLoading(false)
  }, [])

  useEffect(() => { getData() }, [getData])

  const onFormData = ({ schema, formData }) => {
    isRequiredContentRef.current = isFormDataRequired(formData, schema)
    formDataRef.current = { ...formData, time: new Date(new Date(formData?.date || 0).setHours(17, 0)).toISOString() }
    const currentDate = dayjs().format('DD.MM.YYYY')
    const date = dayjs(formData['date']).format('DD.MM.YYYY')
    if (date === currentDate) {
      setUISchema(({...UISchema}) => Object.assign(UISchema, {
        time: {
          ...UISchema['time'],
          options: {
            isDisabledTimePrev: true,
          }
        }
      }))
    }
    if (date !== currentDate) {
      setUISchema(({...UISchema}) => Object.assign(UISchema, {
        time: {
          ...UISchema['time'],
          options: {
            isDisabledTimePrev: false,
          }
        }
      }))
    }
  }

  const onFinish = useCallback(async (values) => {
    const author = getDataOfType(sessionData, 'user.id', Number, 0)
    if(isRequiredContentRef.current === false){
      setPageErrors(['Не заполнены обязательные поля'])
      return
    }

    const stageNewId = await getTaskStageId('new')

    const requestBody = {
      'targetId': targetId,
      'workflowId': formDataRef.current?.['type-task'] || 0,
      // 'organization': null,
      'responsibles': formDataRef.current?.['responsible'] || [],
      'author': author,
      'formData': { ...formatFormData(formDataRef.current), author: author, status: stageNewId },
    }

 

    const result: any = await fetchAPI(`/api/web-hooks/new-task`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })
    if (result) {
      setPageSuccess(['Запись успешно создана.'])
      setPageErrors([])
      {
        const result = reloadActionsTimeLine()
        dispatch(result)
      }

      setPageSuccess([])
      formDataRef.current = {}
      setIsDataLoading(old =>  !old)
      setIsDataLoading(old =>  !old)

      onClose()
    } else {
      const errors =
        Array.isArray(result['errors']) && result['errors']?.length > 0
          ? result['errors'].map((error) => error['detail'])
          : ['Неизвестная ошибка при выполнении запроса']
      setPageSuccess([])
      setPageErrors(errors)
    }
  }, [dispatch, sessionData, targetId, onClose])

  const onFinishFailed = errorInfo => {
      console.error('AdminNewObjectPage: form failed', errorInfo)
  }

  const onSubmit = useCallback(() => {
    form.submit()
  }, [form])

  return (
    <Modal
      title="Создать задачу"
      visible={visible}
      onOk={onSubmit}
      onCancel={onClose}
      className="modal-create-task"
    >
      <MapWrapper>
        <MapContent>
            <Form
              layout="horizontal"
              form={form}
              fields={[
                {
                  name: 'schema',
                  value: name,
                },
              ]}
              scrollToFirstError={true}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{
                schema: name,
              }}
            >
              {pageErrors.length > 0 ? (
                <div style={{ marginBottom: 40 }}>
                  <Alert
                    message="При выполнении операции возникла ошибка:"
                    showIcon
                    type="error"
                    description={pageErrors.join('. ')}
                  />
                </div>
              ) : null}
              {pageSuccess.length > 0 ? (
                <div style={{ marginBottom: 40 }}>
                  <Alert
                    message="Операция успешно выполнена:"
                    showIcon
                    type="success"
                    description={pageSuccess.join('. ')}
                  />
                </div>
              ) : null}
              {isDataLoading === false ? (
                    matchedJSONSchema && UISchema && (
                      <DefaultJsonForm
                        formData={formDataRef.current}
                        schema={matchedJSONSchema}
                        onChange={onFormData}
                        UISchema={UISchema}
                      />
                    )
              ) : <Skeleton active />}
            </Form>
        </MapContent>
      </MapWrapper>
    </Modal>
  )
}
