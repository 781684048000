import React, { useCallback, useEffect, useState } from 'react'
import { Space, Input } from 'antd'
import SelectFromEnum from 'src/react-app/components/select-from-enum'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import SelectFromOrganizations from 'src/react-app/connectors/select-from-organizations'
import InputDateRange from 'src/react-app/components/input-date-range'
import { stage, organization, dateRange } from './utils'

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData,
)

function InputFilter({ onChange, value }) {
  const [localValue, setLocalValue] = useState('')

  const onPressEnter = useCallback(({ target }) => {
    onChange(target?.value)
  }, [onChange])

  useEffect(() => {
    if( value )
      setLocalValue(value)
  },[])

  const onLocalChange = useCallback(({ target }) => {
    const value = target?.value
    setLocalValue(value)
    if (typeof value !== 'string' || value === '') {
      onChange(undefined)
    }
  }, [onChange])

  const onSearch = useCallback((value) => {
    onChange(value)
  }, [onChange])

  return <Input.Search prefix="ID:" onPressEnter={onPressEnter} value={localValue} onChange={onLocalChange} onSearch={onSearch} allowClear style={{ width: 200 }} />
}


const UISchemaSelectFromEnum = {
  options: { width: 200, placeholder: 'Стадия', allowClear: true, showSearch: true,  }
}

const rolesAll = [19, 12, 3, 4, 5, 6, 18]

function isDublicate(arr1, arr2) {
  const concatArray = arr1.concat(arr2)
  return concatArray.length > Array.from(new Set(concatArray)).length
}

function Filter({ onChange }) {
  const { sessionData } = useSelector(createSession)
  const [formData, setFormData] = useState<any>(() =>({
    'organization-id': undefined,
    'date-range': undefined,
    'stage-code': undefined,
    'user-id': undefined,
    'id': undefined,
  }))
  const [UISchemaSelectFromOrganizations, setUISchemaSelectFromOrganizations] = useState({
    options: { width: 200, allowClear: true, types: [3], showSearch: true, placeholder: 'Организация' }
  })

  useEffect(() => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, 0)
    if (isDublicate(roles, rolesAll) === false) {
      setFormData(data => ({ ...data, 'organization-id': organizationId }))
      setUISchemaSelectFromOrganizations(({ options }) => ({ options: { ...options, disabled: true }  }))
    }
  }, [sessionData])




  const onChangeLocal = useCallback((value, name) => {
    const nextformData = {}
    setFormData(prevFormData => Object.assign(nextformData, prevFormData, { [name]: value }))
    onChange(nextformData)
  }, [onChange])

  const onChangeOrganization = useCallback(value => {
    onChangeLocal(value, 'organization-id')
  }, [onChangeLocal])
  const onChangeDateRange = useCallback(value => {
    onChangeLocal(value, 'date-range')
  }, [onChangeLocal])
  const onChangeStageCode = useCallback(value => {
    onChangeLocal(value, 'stage-code')
  }, [onChangeLocal])
  const onChangeId = useCallback(value => {
    onChangeLocal(value, 'id')
  }, [onChangeLocal])

  return (
    <Space>
      <InputFilter onChange={onChangeId} value={formData['id']} />
      <SelectFromOrganizations
        value={formData['organization-id']}
        schema={organization}
        onChange={onChangeOrganization}
        UISchema={UISchemaSelectFromOrganizations}
      />
      <SelectFromEnum
        value={formData['stage-code']}
        schema={stage}
        onChange={onChangeStageCode}
        UISchema={UISchemaSelectFromEnum}
      />
      <InputDateRange
        value={formData['date-range']}
        schema={dateRange}
        onChange={onChangeDateRange}
      />
    </Space>
  )
}

export default Filter
