import React, { useEffect, useMemo, memo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import B2BLayout from '../../../layouts/b2b'
import { createSession, getMeasurementId, getMeasurementFormError, getMeasurementFormLoading } from './selectors'
import {
  DefaultJsonFormContextProps
} from '../../../components/v2/default-json-form/context/default-json-form-context-props'
import { getMeasurementEnums, getMeasurementForm } from '../../../store/measurement/action-creators'
import { HStack } from '../../../ui/Stack'
import DefaultJsonFormContext from '../../../components/v2/default-json-form/context/default-json-form-context'
import MeasurementTabs from './ui/tabs/measurement-tabs'
import { MeasurementForms } from './ui/measurement-forms/measurement-forms'

const breadcrumbs = [
  {
    href: '/measurements',
    title: 'Замеры',
  },
  {
    href: '/measurements',
    title: 'Список замеров',
  }
]

function AdminNewObjectPage() {
  const {id} = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const appealId = useSelector(getMeasurementId)
  const entityType = 'measurement'
  const appealsFormIsLoading = useSelector(getMeasurementFormLoading)
  const appealsFormError = useSelector(getMeasurementFormError)
  const {sessionData} = useSelector(createSession)
  const isShowByAccess = sessionData.roles.includes(19)

  const defaultProps = useMemo(
    (): DefaultJsonFormContextProps => ({
      entityId: id,
      entityType: entityType,
    }), [id])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      const getInitPage = async (id) => {
        await dispatch(getMeasurementEnums(id))
        await dispatch(getMeasurementForm(id))
      }

      getInitPage(id).catch(console.error)
    }
    return () => {
      mounted = false
    }
  }, [id, isShowByAccess, dispatch])

  useEffect(() => {
    if (id === 'new' && appealId) {
      history.push({
        pathname: `/appeal/${appealId}`,
      })
    }
    return () => {}
  }, [id, appealId])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {
        (appealsFormError && !appealsFormIsLoading) ? (
          <HStack gap={"8"} className={'mapWrapper'}>
            При выполнении запроса возникла ошибка.
            <br/>
            {appealsFormError}
            <br/>
            Попробуйте перезагрузить страницу или открыть страницу повторно.
          </HStack>
        ) : (
          <DefaultJsonFormContext.Provider value={defaultProps}>
            <MeasurementForms id={id}/>
            <MeasurementTabs id={id}/>
          </DefaultJsonFormContext.Provider>
        )
      }
  </B2BLayout>
  )
}

export default memo(AdminNewObjectPage)
