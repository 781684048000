import React, { useState, useEffect } from 'react'
import { Button, Modal, Checkbox, Space  } from 'antd'
import fetchAPI from 'src/lib/utils/fetch-api'

function ModalColumnSetting({ collectionName, onSave }){
  const [isVisible, setVisible] = useState<any>(false)
  const [columnsList, setColumnsList] = useState<any>([])
  const [collectionMetadata, setCollectionMetadata] = useState<any>([])

  const setKeyChecked = ((value, checked) =>  {
    const itemIndex = columnsList.findIndex(({ key }) => key === value)
    const newColumnsList = columnsList.map((item, index) => {
      if(itemIndex === index){
        return { ...item, checked}
      }
      return item
    })
    setColumnsList(newColumnsList)
  })

  const localOnSave = async () => {
    const columnsHidden = columnsList.filter(({ checked }) => checked).map(({ key }) => key)
    const data: any = { ...collectionMetadata,  columnsHidden  }
    // @ts-ignore
    await fetchAPI(`/api/collections/${collectionName}/metadata`,{
      method: 'POST',
      body: JSON.stringify({data}),
    })

    onSave()
    setVisible(false)
  }

  useEffect(() => {
    (async ()=> {
      const schemasResult = (await fetchAPI('/api/schemas?access_key=axioma&fields=title,metadata,properties'))['data']['data']
      const metadataResult = (await fetchAPI(`/api/collections/${collectionName}/metadata`))['data']
      const schemas = (metadataResult && metadataResult['schema']) || []
      const columnsHidden = (metadataResult && metadataResult['columnsHidden']) || []
      const allProperties = schemasResult.filter(({ name }) => schemas.includes(name)).map(({ properties}) => properties)
      const properties = allProperties.reduce((acc, current) => {
        return {...acc, ...current }
      }, {})
      const propertiesKeys = Object.entries(properties).map(([key, value]) => {
        // @ts-ignore
        return { key, title: value.title || key, checked: columnsHidden.includes(key) }
      })
      setColumnsList(propertiesKeys)
      setCollectionMetadata(metadataResult)
    })()
  },[collectionName])

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        Настройка скрытия колонок
      </Button>
      <Modal
        title="Настройка скрытия колонок справочника"
        visible={isVisible}
        onOk={localOnSave}
        onCancel={() => setVisible(false)}
      >
        <Space direction="vertical">
          {columnsList.map(({ key, title, checked }) => {
            return (
              <Checkbox
                key={key}
                checked={checked}
                onChange={({ target }) => setKeyChecked(key, target.checked)}
              >
                {title}
              </Checkbox>
            )
          })}
        </Space>
      </Modal>
    </>
  )
}

export default ModalColumnSetting


