import React, { useRef, useCallback, useState, useEffect } from 'react'
import {Form, Input, Modal} from 'antd'
import { DefaultJsonForm } from 'src/react-app/components/default-json-form'
import { isFormDataRequired } from 'src/lib/utils/collections'
import { BrowserRouter } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { store } from 'src/browser-app'
import {getDataOfType} from '../../../lib/utils/get-data-of-type'

const selectedSchema = {
  type: 'object',
  required: ['name', 'price'],
  properties: {
    name: {
      type: 'string',
      title: 'Наименования',
    },
    price: {
      type: 'number',
      title: 'Стоимость',
    },
    cost: {
      type: 'number',
      title: 'Себестоимость',
    }
  },
}

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

export function modalClose(event){
  let target = event.target
  while (Boolean(target?.tagName)) {
    if (target?.tagName.toLowerCase() === 'div') {
      break
    }
    target = target?.parentNode
  }
  try{
    Array.from(target?.children as any[])[0].click()
    return true
  }catch{
    return false
  }
}

export function modalAppoint(data = {}): Promise<any> {
  let formData = data
  return new Promise(resolve => Modal.confirm({
    icon: null,
    width: 500,
    title: 'Добавить изделие',
    content: <Provider store={store}>
      <Content onChange={value => { formData = value }} formData={formData} />
    </Provider>,
    onCancel: () => resolve(null),
    okButtonProps: { 
      onClick: (event) => {
        const isRequired = isFormDataRequired(formData, selectedSchema)
        if(isRequired){
          resolve(formData)
          modalClose(event)
        }
      }
    }
  }))
}

function Content({ onChange, formData }){
  const formDataRef = useRef({ ...formData, name: 'изделия с доп. комплектующими' })
  const [additionalFormRef] = Form.useForm()
  const [UISchema, setUISchema] = useState<any>({})
  const { sessionData } = useSelector(createSession)

  const showLeaderFields = useCallback( () => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    return roles.includes(23) || roles.includes(28) || roles.includes(19)
  }, [sessionData])

  const localOnChange = useCallback(({ formData }) => {
    formDataRef.current = formData
    onChange(formData)
    calcMargin()
  }, [onChange, formDataRef])

  useEffect(() => {
    const UISchema = {
      name: {
        css: {
          gridColumn: '1 / span 24',
          gridRow: '1',
        },
        options: {
          disabled: true
        }
      },
      price: {
        itemStyle: {
          width: '50%',
          marginBottom: 24
        },
        style: {
          width: '100%'
        },
        css: {
          gridColumn: '1 / span 24',
          gridRow: '2',
        },
      },
      cost: {
        itemStyle: {
          width: '50%',
          marginBottom: 24
        },
        style: {
          width: '100%'
        },
        css: {
          gridColumn: '1 / span 24',
          gridRow: '3',
        },
      },
    }
    setUISchema(UISchema)
  }, [sessionData])

  const calcMargin = useCallback( () => {
    const cost = formDataRef.current.cost
    const price = formDataRef.current.price
    additionalFormRef.setFieldsValue({margin: (price && cost) ? price - cost : '-' })
  }, [additionalFormRef])

  return (
    <BrowserRouter>
      <DefaultJsonForm
        formData={formDataRef.current}
        schema={selectedSchema}
        onChange={localOnChange}
        UISchema={UISchema}
      />
      { showLeaderFields() &&
        (<Form layout='vertical' form={additionalFormRef} initialValues={{margin: '-'}} onChange={calcMargin}>
          <div style={{width: '50%'}}>
            <Form.Item label='Маржа' name='margin' key='margin'>
              <Input disabled={true} />
            </Form.Item>
          </div>
        </Form>
        )
      }
    </BrowserRouter>
  )
}