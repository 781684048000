export const organization = {
  "title": "Организация",
  "type": "pointer",
  "view": {
    "name": "select-from-organizations"
  }
}

export const user = {
  "title": "Менеджер",
  "type": "pointer",
  "view": {
    "name": "select-from-user"
  }
}

export const stage = {
  "type": "string",
  "title": "Стадия",
  "enum": [
    "new",
    "measurement",
    "prepayment",
    "manufacture",
    "delivery",
    "mounting",
    "done",
  ],
  "oneOf": [
    {
      "const": "new",
      "title": "Новая"
    },
    {
      "const": "work",
      "title": "В работе"
    },
    {
      "const": "measurement",
      "title": "Замер"
    },
    {
      "const": "prepayment",
      "title": "Предоплата"
    },
    {
      "const": "manufacture",
      "title": "Производство"
    },
    {
      "const": "delivery",
      "title": "Доставка"
    },
    {
      "const": "mounting",
      "title": "Монтаж"
    },
    {
      "const": "done",
      "title": "Выполнено"
    }
  ],
  "view": {
    "name": "select-from-enum"
  }
}

export const region = {
  "title": "Регион",
  "type": "pointer",
  "view": {
    "name": "select-from-regions"
  }
}

export const dateRange = {
  "type": "string",
  "format": "date-range",
  "title": "Дата"
}


