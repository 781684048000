import React, { useCallback, FC } from 'react'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import { cardName } from './consts'

import { IActionsListProps } from './interface'
import { ActionsListItem } from './actions-list-item'
import { isFormDataRequired } from '../../../../lib/utils/collections'

function genJSONSchemaStage(JSONSchema, requiredArr){
  const required = getDataOfType(JSONSchema, 'required', Array, []).concat(requiredArr)
  const requiredUnique = Array.from(new Set(required))
  return ({ ...JSONSchema, required: requiredUnique })
}

export const ActionsList: FC<IActionsListProps> = props => {
  const {
    parentId,
    formData,
    stageList,
    onStageToWork,
    onContractSigned,
    onTransition,
    matchedJSONSchema,
    sessionData,
    getValidatorsData,
    openSubMenu,
    onUpdateStage,
    onClose = () => {},
  } = props

  const getTransition = useCallback(async (transition, params) => {
    const stage = params['nextStageIndex']
    const stageCodeNext = transition['target']

    switch (stageCodeNext) {
      case 'work':
        await onStageToWork(parentId, stage, stageCodeNext)

        break
      case 'accepted-driver':
      case 'delivered-client':
      case 'return-production':
      case 'not-accepted-client':
      case 'accepted-client':
      case 'done':
        onUpdateStage(parentId, stage, stageCodeNext)

        break
      case 'contract-signed':
        await onContractSigned(parentId, stage, stageCodeNext)

        break
      default:
        onTransition(parentId, stage, stageCodeNext)

        break
    }

  },[onStageToWork, parentId, onUpdateStage, onContractSigned, onTransition])

  const transitionRequired = useCallback((prevFields, nextFields) => {
    const gen = genJSONSchemaStage(matchedJSONSchema, nextFields)

    return isFormDataRequired(formData, gen);
  }, [formData, matchedJSONSchema])


  return (
    <ActionsListItem
      stage={formData['stage']}
      stageList={stageList}
      type={cardName}
      getTransition={getTransition}
      checkTransitionRequired={transitionRequired}
      sessionData={sessionData}
      getValidatorsData={getValidatorsData}
      openSubMenu={openSubMenu}
      onClose={onClose}
    />
  )
}
