import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Form, Modal, Popconfirm, Spin } from 'antd'
import { SaveOutlined } from '@ant-design/icons'

import { LoadingWrapper } from '../admin-select-group/styles'
import { EditGroupForm } from '../admin-edit-group-form'
import { ICollectionsGroup } from '../admin-edit-group-form'
import fetchAPI from '../../../lib/utils/fetch-api'
import { IAdminEditGroupProps } from './interface'
import { AlertWrapper } from '../../forms/app-edit-object-form/styles'

export const AdminEditGroup: FC<IAdminEditGroupProps> = (props) => {
  const { children, showModal, isEdit, createURL, updateURL, toggleModal, group, groups, onAfterSave, targetData } = props

  const [isLoading, setIsLoading] = useState(false)
  const [selectedParentGroupId, setSelectedParentGroupId] = useState<number>()
  const [newGroup, setNewGroup] = useState<ICollectionsGroup | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const [error, setError] = useState('')
  const [editForm] = Form.useForm()

  const setEditData = useCallback(() => {
    if (group) {
      const groupName = group.id ? group.name : ''
      setSelectedParentGroupId(group['parent-id'])
      editForm.setFieldsValue({
        'parent-id': group['parent-id'],
        name: groupName,
      })
      setNewGroup(group)
    } else {
      setSelectedParentGroupId(undefined)
      editForm.setFieldsValue({
        'parent-id': null,
        name: null,
      })
      setNewGroup(null)
    }
  }, [editForm, group])

  const handleToggleModal = useCallback(() => {
    setOpenModal(false)
    setTimeout(() => toggleModal(), 300)
  }, [toggleModal])

  useEffect(() => {
    if (showModal) {
      setEditData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, showModal])

  useEffect(() => {
    if (showModal !== openModal) {
      setTimeout(() => setOpenModal(showModal), 300)
    }
  }, [openModal, showModal])

  const handleGroupFormChange = useCallback((data: any | null) => {
    setError('')
    setNewGroup(prevState => ({
      ...prevState,
      ...data,
    }))
    editForm.setFieldsValue(data)

    if (data && 'parent-id' in data && data['parent-id'] !== null) {
      setSelectedParentGroupId(data['parent-id'])
    }
  }, [editForm])

  const editorGroupModalTitle = useMemo(() => isEdit
    ? 'Редактирование группы'
    : 'Создание группы',
    [isEdit])

  const confirmSchemaMessage = useMemo(() => isEdit
    ? 'Изменить данные группы?'
    : 'Создать группу?',
    [isEdit])

  const handleGroupModalSubmitForm = useCallback(() => {
    const startSubmit = async () => {
      setIsLoading(true)

      try {
        const targetURL = newGroup?.id ? updateURL(newGroup?.id) : createURL()
        const method = newGroup?.id ? 'PUT' : 'POST'

        const response = await fetchAPI(targetURL, {
          method,
          body: JSON.stringify({
            data: {
              ...newGroup,
              ...(targetData || {})
            },
          })
        })

        if (onAfterSave && typeof onAfterSave === 'function') {
          onAfterSave(response['data'])
        }
      } catch (error) {
        console.error('Something going wrong: ', error)
      }

      handleToggleModal()
      setIsLoading(false)
      editForm.resetFields()
    }

    if (newGroup && newGroup.name.trim().length > 0) {
      startSubmit()
    } else {
      setError('Не все обязательные поля заполнены')
    }
  }, [newGroup, handleToggleModal, editForm, updateURL, createURL, targetData, onAfterSave])

  const onSubmit = useMemo(() => !isLoading ? handleGroupModalSubmitForm : undefined,
    [handleGroupModalSubmitForm, isLoading])

  return (
    <>
      {children}
      {showModal && (
        <Modal
          title={editorGroupModalTitle}
          visible={openModal}
          forceRender
          width="50vw"
          okText={
            <Popconfirm title={confirmSchemaMessage} okText="Да" cancelText="Нет" onConfirm={onSubmit}>
              <span>
                <SaveOutlined />&nbsp;
                Сохранить
              </span>
            </Popconfirm>
          }
          cancelText="Отмена"
          onCancel={handleToggleModal}
        >
          {isLoading && (
            <LoadingWrapper>
              <Spin />
            </LoadingWrapper>
          )}
          {error.length > 0 && (
            <AlertWrapper>
              <Alert
                message="При выполнении операции возникла ошибка:"
                showIcon
                type="error"
                description={error}
              />
            </AlertWrapper>
          )}
          <EditGroupForm
            groups={groups}
            onChange={handleGroupFormChange}
            selectedParentGroupId={selectedParentGroupId}
            form={editForm}
          />
        </Modal>
      )}
    </>
  )
}
