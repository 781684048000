export const schema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      title: 'Название'
    },
    type_id: {
      type: 'string',
      title: 'Тип организации',
      view: {
        name: 'select-from-enum'
      },
      options: {}
    },
    brand_id: {
      type: 'string',
      title: 'Бренд',
      allowClear: true,
      view: {
        name: 'select-from-enum'
      },
      options: {}
    },
    task_responsible_roles: {
      type: 'string',
      title: 'Ответственные роли по задачам',
      allowClear: true,
      view: {
        name: 'select-from-enum'
      },
      options: {
        multiple: true
      }
    }
  }
}