import type {ColumnsType} from 'antd/es/table'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {DataType} from './interface'
import {Popconfirm} from "antd";
import {ButtonActionWrapper} from '../../../components/list-operations/styles'
import {DeleteTwoTone} from '@ant-design/icons'
import {Value} from '../../elements'

const Columns: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    fixed: 'left'
  },
  {
    title: 'ФИО',
    dataIndex: 'name',
    key: 'name',
    width: 170,
  },
  {
    title: 'Логин',
    dataIndex: 'login',
    key: 'login',
    width: 170,
  },
  {
    title: 'Телефон',
    width: 120,
    dataIndex: 'phone',
    key: 'phone',
    render: val => <Value data={val}/>
  },
  {
    title: 'Манго SIP',
    dataIndex: 'mango_sip',
    key: 'mango_sip',
    width: 130,
  },
  {
    title: 'Регионы',
    dataIndex: 'region',
    key: 'region',
    width: 130,
  },
  {
    title: 'Города',
    dataIndex: 'city',
    key: 'city',
    width: 130,
  },
  {
    title: 'Дата приема',
    dataIndex: 'employment_date',
    key: 'employment_date',
    width: 120,
    sorter: true,
    render: key =>  key ? dayjs(key).format('DD.MM.YYYY HH:mm') : ''
  },
  {
    title: 'Активность',
    dataIndex: 'is_active',
    key: 'is_active',
    width: 120,
    sorter: true,
    render: val => val ? 'Да' : 'Нет'
  },
  {
    title: 'Дата увольнения',
    dataIndex: 'dismissal_date',
    key: 'dismissal_date',
    width: 120,
    sorter: true,
    render: key => key ? dayjs(key).format('DD.MM.YYYY HH:mm') : ''
  },
]

const ColumnAction: ColumnsType<DataType> = [
  {
    title: 'Действие',
    key: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    align: 'center',
    render: (_, {id, deleteRecord}) => {
      return (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <DeleteButton id={id} deleteRecord={deleteRecord}/>
        </span>
      )
    }
  }
]

export function DeleteButton({id, deleteRecord}) {
  const localDeleteRecord = useCallback(() => deleteRecord(id), [deleteRecord, id])
  return (
    <Popconfirm
      title="Вы уверены?"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => localDeleteRecord()}
    >
      <ButtonActionWrapper title="Удалить">
        <DeleteTwoTone />
      </ButtonActionWrapper>
    </Popconfirm>
  )
}

export const getColumns = (leader, sorter) => {
  // собирает колонки для разных ролей
  const columns = leader ? Columns.concat(ColumnAction) : Columns
  // Формирует колонки и устанавливает порядок сортировки
  return columns.map(item => {
    if (item.key === sorter.sort_field)
      item.sortOrder = sorter.sort_order
    else
      delete item.sortOrder
    return item
  })
}

