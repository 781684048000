import React, { useMemo } from 'react'
import { Input } from 'antd'
import { IInputText } from './interface'

function InputPassword({ value, onChange, schema, UISchema }: IInputText){
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const suffix = useMemo(() => UISchema?.suffix, [UISchema])
  return (
    <div
      style={{ position: 'relative' }}
    >
      <Input.Password
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder='Введите пароль'
        autoComplete='new-password'
        style={{ paddingRight: suffix ? '2.5em' : undefined }}
      />
      {suffix && (
        <div
          style={{
            position: 'absolute',
            zIndex: 999,
            right: 11,
            top: 5
          }}
        >
          {suffix}
        </div>
      )}
    </div>
  )
}

export default InputPassword
