export const organization = {
  "title": "Организация",
  "type": "pointer",
  "view": {
    "name": "select-from-organizations"
  }
}

export const region = {
  "title": "Регион",
  "type": "pointer",
  "view": {
    "name": "select-from-region"
  }
}

export const city = {
  "title": "Город",
  "type": "pointer",
  "view": {
    "name": "select-from-city"
  }
}

export const dateRange = {
  "type": "string",
  "format": "date-range",
  "title": "Дата"
}