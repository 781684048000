// @ts-nocheck
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Button, Table } from 'antd'
import { PlusOutlined, ToolOutlined, FolderAddOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { TableRowSelection } from 'antd/lib/table/interface'
import qs from 'query-string'

import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import FieldHideText from '../../pages/list-admin-objects/field-hide-text'
import { getSchemaArrayEnum } from '../../../lib/utils/collections'
import { CollectionObjectListType, ICollectionObjectListProps, IGroupedObjects } from './interface'
import { afterDataColumns, beforeDataColumns, groupsColumns } from './consts'
import { ButtonWrapper, SelectedLabel, TableWrapper } from './styles'
import { useChunkDataload } from '../../hooks/use-chunk-dataload'
import { useSorter } from '../../hooks/use-sorter'
import ButtonModalColumn from 'src/react-app/views/modal-column-setting'
import { AdminEditGroup } from '../../views/admin-edit-group'
import { prepareGroups } from '../../utilits/groups'
import { EditableCell } from '../editable-cell'
import { DarkLink } from '../dark-link'
import {importCollection, exportCollection, importSchedex} from './utils-collection'
import { downloadFileJson, loadFileJson } from 'src/lib/utils/utils-json'

const createTableFields = (attributes, fieldEnumNames) => {
  const fields = {}
  const attributesKeys = Object.keys(attributes)
  attributesKeys.forEach(key => {
    if (fieldEnumNames && key in fieldEnumNames) {
      fields[key] = Object.values(fieldEnumNames[key])
        .filter(value => typeof value === 'string')
        .join(' | ')

      return
    }

    fields[key] = attributes[key]
  })

  return fields
}

export const CollectionObjectList: FC<ICollectionObjectListProps> = ({
  collectionName,
  isModalView,
  viewType = CollectionObjectListType.FULL,
  onAddNew,
  onEdit,
  onRowSelect,
  outerSelectedId,
}) => {
  const history = useHistory()
  const [dataSource, setDataSource] = useState<any[]>([])
  const [totalData, setTotalData] = useState(0)
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [dataColumns, setDataColumns] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const [tableNode, setTableNode] = useState<HTMLDivElement | null>(null)
  const [orderBy, setOrderBy] = useState('updated-at')
  const [orderDir, setOrderDir] = useState('desc')
  const [zeroOutPage, setZeroOutPage] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [currentOrder, setCurrentOrder] = useState({ orderBy: 'id', orderDir: 'desc' })
  const [showGroupModal, setShowGroupModal] = useState(false)
  const [preparedGroups, setPreparedGroups] = useState<any[]>([])
  const [flatGroups, setFlatGroups] = useState<any[]>([])
  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const expandedList = useRef<Record<number, boolean>>({})
  const isFullView = useMemo(() => {
    return viewType === CollectionObjectListType.FULL
  }, [viewType])
  const isDeliveryPricesCollection = useMemo(() => {
    return collectionName === 'delivery-prices-zones'
  }, [collectionName])

  const getCreateGroupURL = useCallback(() => '/collections/objects-groups/', [])
  const getUpdateGroupURL = useCallback(id => `/collections/objects-groups/${id}`, [])

  const toggleGroupModal = useCallback(() => {
    setShowGroupModal(prevState => !prevState)
  }, [])

  const handleCreateGroup = useCallback(() => {
    setShowGroupModal(true)
  }, [])

  const handleEdit = useCallback(
    ({ id, collectionName, groupId }) => () => {
      if (onEdit && onEdit.constructor === Function) {
        onEdit({ id, collectionName, groupId })
      } else {
        history.push(`/admin/collections/${collectionName}/objects/${id}/${groupId}/edit`)
      }
    },
    [history, onEdit],
  )

  const handleDeleteRecord = useCallback(
    currentId => async () => {
      try {
        await fetchAPI(`/api/collections/objects/${currentId}`, { method: 'DELETE' })
        setDataSource(prevState => prevState.filter(({ id }) => id !== currentId))
      } catch (error) {
        console.error(error)
      }
    },
    [],
  )

  /** Update sort data */
  const handleSort = useCallback((key, direction) => {
    setOrderBy(key)
    setOrderDir(direction)
  }, [])

  /** Getting method for sorting table */
  const { onColumnSort } = useSorter({
    onSort: handleSort,
  })

  const DataColumn = useCallback(
    ({ id, name, text }) => (
      <SelectedLabel htmlFor={id} key={`${name}-${id}`}>
        {FieldHideText({ text })}
      </SelectedLabel>
    ),
    [],
  )

  /** Request data from API */
// eslint-disable-next-line
  const getData = useCallback(
// eslint-disable-next-line
    async (page = 0, limit = 10) => {
// eslint-disable-next-line
      setIsDataLoading(true)
      // eslint-disable-next-line
      const prepareDataSource: (rows: any[]) => any[] = rows => {
        // eslint-disable-next-line
        const result: IGroupedObjects[] = []

        rows.forEach(item => {
          const isGroup = 'is-group' in item && item['is-group']
          const key = isGroup ? item['id'] * 1000 : item['id']
          const attributes = (item['data'] && item['data']['attributes']) || {}
          const fieldEnumNames = item['data'] && item['data']['fieldEnumNames']
          const children =
            item['children'] && item['children']?.length > 0
              ? prepareDataSource(item['children'])
              : null
          const schemaNameString = getDataOfType(item, 'data.metadata.schema', String, null)
          const schemaNameArray = getDataOfType(item, 'data.metadata.schema', Array, null)
          const fields = createTableFields(attributes, fieldEnumNames)

          result.push({
            key,
            id: item['id'],
            ...fields,
            name: item['name'],
            'collection-name': collectionName,
            schema: schemaNameString || schemaNameArray,
            groupId: item['group-id'],
            children,
            isGroup,
            createdAt: item['created-at'],
            updatedAt: item['updated-at'],
          })
        })

        return result
      }

      const schemasResult = await fetchAPI(
        '/api/schemas?access_key=axioma&fields=title,metadata,properties',
      )
      const metadataResult = await fetchAPI(`/api/collections/${collectionName}/metadata`)
      const columnsHidden =
        (metadataResult && metadataResult['data'] && metadataResult['data']['columnsHidden']) || []
      const collectionsQuery = qs.stringify({
        access_key: 'axioma',
        page,
        limit,
        order_by: orderBy,
        order_dir: orderDir,
      })
      const collectionsResult = await fetchAPI(
        `/api/collections/objects-grouped/${collectionName}?${collectionsQuery}`,
      )

      const collectionData =
        (collectionsResult && collectionsResult['data'] && collectionsResult['data']['data']) || []

      if (metadataResult) {
        if (typeof metadataResult['data'] === 'object') {
          setPageErrors([])
        } else {
          setPageErrors(['Некорректный ответ сервера при получении метаданных коллекции'])
        }
      }

      if (collectionData.length > 0) {
        const preparedRows = prepareDataSource(collectionData)

        const propertyByName: object = {}

        setTotalData(collectionsResult['data']['total'])

        if (schemasResult) {
          const rows = (schemasResult['data'] && schemasResult['data']['data']) || []
          const availableSchemas =
            (metadataResult['data'] && metadataResult['data']['schema']) || []

          rows.forEach((row = {}) => {
            if (availableSchemas.indexOf(row['name']) >= 0) {
              const properties = row['properties'] || {}

              Object.keys(properties).forEach(propName => {
                propertyByName[propName] = properties[propName]
              })
            }
          })
        }

        const newDataSource: any[] = page === 0 ? [] : [...dataSource]
        const dataColumnByKey: object = {}

        collectionData.forEach(collectionItem => {
          const attributes = (collectionItem['data'] && collectionItem['data']['attributes']) || {}

          Object.keys(attributes).forEach(name => {
            dataColumnByKey[name] = {
              title: propertyByName[name] && propertyByName[name].title,
              width: 120,
              dataIndex: name,
              key: name,
              fixed: 'left',
            }
          })

          const properties = (collectionItem['data'] && collectionItem['data']['attributes']) || {}
          const filteredProperties = {}

          Object.keys(properties).forEach(propertyKey => {
            if (properties[propertyKey]?.constructor !== Object) {
              filteredProperties[propertyKey] = properties[propertyKey]
              dataColumnByKey[propertyKey] = {
                title:
                  (propertyByName[propertyKey] && propertyByName[propertyKey].title) || propertyKey,
                width: 120,
                dataIndex: propertyKey,
                key: propertyKey,
                fixed: 'left',
                sorter: onColumnSort(propertyKey),
              }
            }
          })
        })

        const preparedDataColumns: any[] = Object.keys(dataColumnByKey).map(
          column => dataColumnByKey[column],
        )
        const newDataColumns = preparedDataColumns
          .filter(item => item.title)
          .map(column => {
            column.render = (text, { id, 'collection-name': collectionName, groupId, name }) =>
              isModalView ? (
                <DataColumn id={id} name={name} text={text} />
              ) : (
                <DarkLink
                  to={`/admin/collections/${collectionName}/objects/${id}/${groupId}/edit`}
                  title="Отредактировать запись"
                >
                  <DataColumn id={id} name={name} text={text} />
                </DarkLink>
              )

            return column
          })

        setDataColumns(newDataColumns.filter(({ key }) => columnsHidden.includes(key) === false))
        setDataSource(newDataSource.concat(preparedRows))
      }

      setIsDataLoading(false)
    },
    [collectionName, dataSource, isModalView, onColumnSort, orderBy, orderDir, DataColumn],
  )

  const getGroups = useCallback(async () => {
    const groupsResult = await fetchAPI(`/api/collections/objects-groups/${collectionName}`)
    setFlatGroups(groupsResult['data'])
  }, [collectionName])

  const handleGetData = useCallback(
    (page: number, limit: number) => {
      getData(page, limit)
      getGroups()
      setZeroOutPage(false)
    },
    [getData, getGroups],
  )

  const handleDataFetch = useCallback(async () => {
    getData()
    getGroups()
  }, [getData, getGroups])

  const handleGroupUpdate = useCallback(
    async record => {
      const dataItem = dataSource.find(item => item.id === record?.id)

      if (dataItem && dataItem.name === record['name']) {
        return
      }

      setIsDataLoading(true)
      const targetURL = `/api/collections/objects-groups/${record?.id}`
      const data = {
        name: record['name'],
      }
      await fetchAPI(targetURL, {
        method: 'PUT',
        body: JSON.stringify({
          data: data,
        }),
      })
      await handleDataFetch()
      setIsDataLoading(false)
    },
    [dataSource, handleDataFetch],
  )

  const totalWidth: any = useMemo(() => {
    const groupsWidth = groupsColumns({
      groups: [],
      handleGroupUpdate,
      onAfterGroupCreate: handleDataFetch,
      sortCallback: onColumnSort,
      selectedId,
      isModalView,
    }).reduce((acc: any, { width }) => acc + width, 0)
    const beforeWidth = isFullView
      ? beforeDataColumns().reduce((acc: any, { width }) => acc + width, 0)
      : 0
    const dataWidth = dataColumns.reduce((acc: any, { width }) => acc + width, 0)
    const afterWidth = afterDataColumns().reduce((acc: any, { width }) => acc + width, 0)

    return groupsWidth + beforeWidth + dataWidth + afterWidth
  }, [
    handleGroupUpdate,
    handleDataFetch,
    onColumnSort,
    selectedId,
    isModalView,
    isFullView,
    dataColumns,
  ])

  const finalColumns = useMemo(() => {
    let result: any[] = [
      ...groupsColumns({
        groups: preparedGroups,
        handleGroupUpdate,
        onAfterGroupCreate: handleDataFetch,
        sortCallback: onColumnSort,
        expandedList: expandedList.current,
        selectedId,
        isModalView,
      }),
    ]

    if (isFullView) {
      result = result.concat(beforeDataColumns(onColumnSort))
    }

    result = result.concat([
      ...dataColumns,
      ...afterDataColumns({
        onEdit: handleEdit,
        onDelete: handleDeleteRecord,
        selectedId,
        onSort: onColumnSort,
        isModalView,
      }),
    ])

    return result.filter(item => item.title)
  }, [
    preparedGroups,
    handleGroupUpdate,
    handleDataFetch,
    onColumnSort,
    selectedId,
    isModalView,
    isFullView,
    dataColumns,
    handleEdit,
    handleDeleteRecord,
  ])

  /** Add event listener on scroll of table for getting data from getData */
  useChunkDataload({
    total: totalData,
    dataCount: dataSource.length,
    node: tableNode,
    zeroOutPage,
    forceUpdate,
    dataloadCallback: handleGetData,
  })

  /** Start request data with zero out of sorting when changing order by or order direction */
  useEffect(() => {
    if (currentOrder['orderBy'] !== orderBy || currentOrder['orderDir'] !== orderDir) {
      setCurrentOrder({
        orderDir,
        orderBy,
      })
      setZeroOutPage(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, orderDir])

  /** Start request data when changed direction of current sorting */
  useEffect(() => {
    setForceUpdate(currentOrder['orderBy'] === orderBy && currentOrder['orderDir'] !== orderDir)
  }, [currentOrder, orderBy, orderDir])

  useEffect(() => {
    if (!isDataLoading && !tableNode) {
      if (tableWrapperRef !== null && tableWrapperRef.current !== null) {
        const node = tableWrapperRef.current.querySelector<HTMLDivElement>('.ant-table-body')

        if (node) {
          setTableNode(node)
        }
      }
    }
  }, [isDataLoading, tableNode])

  useEffect(() => {
    if (outerSelectedId) {
      setSelectedId(outerSelectedId)
    }
  }, [outerSelectedId])

  useEffect(() => {
    setPreparedGroups(prepareGroups(flatGroups))
  }, [flatGroups])

  const handleAddNew = () => {
    if (onAddNew && onAddNew.constructor === Function) {
      onAddNew(collectionName)
    } else {
      history.push(`/admin/collections/${collectionName}/objects/new`)
    }
  }

  const handleEditSchema = () => {
    history.push(`/admin/collections/${collectionName}`)
  }

  const rowSelection: { rowSelection: TableRowSelection<any> } | {} = useMemo(() => {
    return onRowSelect
      ? {
          rowSelection: {
            selectedRowKeys: [selectedId],
            type: 'radio',
            onChange: (selectedRowKeys, selectedRows) => {
              const row = selectedRows[0]
              const schema = row['schema'] ? row['schema'][0] : ''

              if (onRowSelect.constructor === Function) {
                onRowSelect(row['id'], getSchemaArrayEnum(schema, row['collectionName'], row['id']))
              }

              setSelectedId(row['id'])
            },
            getCheckboxProps: record => ({
              id: record.id,
            }),
          },
        }
      : {}
  }, [onRowSelect, selectedId])

  const components = useMemo(
    () => ({
      body: {
        cell: EditableCell,
      },
    }),
    [],
  )

  const handleExpand = useCallback((expand, record) => {
    expandedList.current[record['key']] = expand
  }, [])

  const onImportCollection = useCallback(async () => {
    const json = await loadFileJson()
    await importCollection(collectionName, json)

    alert('Импорт завершён')
  }, [collectionName])

  const onExportCollection = useCallback(async () => {
    const json = await exportCollection(collectionName)
    downloadFileJson(json, collectionName)
  }, [collectionName])

  const onImportSchedex = useCallback(async () => {
    const zones = await fetchAPI(`/api/schedex/scheduling-zones/`)
    const json = await exportCollection(collectionName)

    const updatedJson = zones.map(zone => {
      const existingZone = json.find(item => item.name === zone.name)

      if (existingZone) {
        return {
          ...existingZone,
          data: {
            ...existingZone.data,
            attributes: {
              ...existingZone.data.attributes,
              area: zone.area,
              color: zone.color
            }
          }
        }
      } else {
        return {
          name: zone.name,
          "group-name": null,
          data: {
            name: zone.name,
            attributes: {
              area: zone.area,
              color: zone.color
            },
            metadata: {
              schema: ["delivery-prices-zones"]
            }
          }
        }
      }
    })

    await importSchedex(collectionName, updatedJson)

    alert('Импорт Шедекс завершён')

    console.log(updatedZones)
  }, [])


  return (
    <TableWrapper ref={tableWrapperRef}>
      <ButtonWrapper>
        <Button onClick={handleAddNew}>
          <PlusOutlined />
          Добавить запись
        </Button>
        {isFullView && (
          <Button onClick={handleEditSchema}>
            <ToolOutlined />
            Редактировать справочник
          </Button>
        )}
        <ButtonModalColumn collectionName={collectionName} onSave={() => getData()} />
        <AdminEditGroup
          createURL={getCreateGroupURL}
          updateURL={getUpdateGroupURL}
          showModal={showGroupModal}
          groups={preparedGroups}
          toggleModal={toggleGroupModal}
          onAfterSave={handleDataFetch}
          targetData={{ 'collection-name': collectionName }}
        >
          <Button onClick={handleCreateGroup}>
            <FolderAddOutlined />
            Создать группу
          </Button>
        </AdminEditGroup>
        <Button onClick={onExportCollection}>Экспорт</Button>
        <Button onClick={onImportCollection}>Импорт</Button>
        {isDeliveryPricesCollection && (
          <Button onClick={onImportSchedex}>Импорт Шедекс</Button>
        )}
      </ButtonWrapper>
      {pageErrors.length > 0 ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
          />
        </div>
      ) : null}
      <Table
        {...rowSelection}
        components={components}
        size="small"
        columns={finalColumns}
        dataSource={dataSource}
        scroll={{ x: totalWidth, y: '77vh' }}
        pagination={false}
        loading={isDataLoading}
        onHeaderRow={(column: any) => ({
          style: {
            fontSize: column.key && column.key.substr(-3) === '_at' ? 9 : 13,
          },
        })}
        expandable={{
          onExpand: handleExpand,
        }}
      />
    </TableWrapper>
  )
}
