import React, { useEffect, useMemo, memo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import B2BLayout from '../../../layouts/b2b'
import { createSession, getMontageId, getMontageFormError, getMontageFormLoading } from './selectors'
import {
  DefaultJsonFormContextProps
} from '../../../components/v2/default-json-form/context/default-json-form-context-props'
import { getMontageEnums, getMontageForm } from '../../../store/montage/action-creators'
import { HStack } from '../../../ui/Stack'
import DefaultJsonFormContext from '../../../components/v2/default-json-form/context/default-json-form-context'
import MontageTabs from './ui/tabs/montage-tabs'
import { MontageForms } from './ui/montage-forms/montage-forms'

const breadcrumbs = [
  {
    href: '/montage',
    title: 'Монтаж',
  },
  {
    href: '/montage',
    title: 'Список монтажей',
  }
]

function AdminNewObjectPage() {
  const {id} = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const appealId = useSelector(getMontageId)
  const entityType = 'montage'
  const appealsFormIsLoading = useSelector(getMontageFormLoading)
  const appealsFormError = useSelector(getMontageFormError)
  const {sessionData} = useSelector(createSession)
  const isShowByAccess = sessionData.roles.includes(19)

  const defaultProps = useMemo(
    (): DefaultJsonFormContextProps => ({
      entityId: id,
      entityType: entityType,
    }), [id])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      const getInitPage = async (id) => {
        await dispatch(getMontageEnums(id))
        await dispatch(getMontageForm(id))
      }

      getInitPage(id).catch(console.error)
    }
    return () => {
      mounted = false
    }
  }, [id, isShowByAccess, dispatch])

  useEffect(() => {
    if (id === 'new' && appealId) {
      history.push({
        pathname: `/montage/${appealId}`,
      })
    }
    return () => {}
  }, [id, appealId])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {
        (appealsFormError && !appealsFormIsLoading) ? (
          <HStack gap={"8"} className={'mapWrapper'}>
            При выполнении запроса возникла ошибка.
            <br/>
            {appealsFormError}
            <br/>
            Попробуйте перезагрузить страницу или открыть страницу повторно.
          </HStack>
        ) : (
          <DefaultJsonFormContext.Provider value={defaultProps}>
            <MontageForms id={id}/>
            <MontageTabs id={id}/>
          </DefaultJsonFormContext.Provider>
        )
      }
  </B2BLayout>
  )
}

export default memo(AdminNewObjectPage)
