import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Table, Input, Form, Space, Checkbox, Button } from 'antd'
import { CloseSquareFilled, SaveFilled, EditTwoTone, DeleteTwoTone } from '@ant-design/icons'
import fetchAPI from 'src/lib/utils/fetch-api'
import { TableButtonWrapper } from './styles'
import InputPhone from 'src/react-app/components/input-phone'


function getNode({ inputType, editing, dataIndex, record, children }: any) {
  if (dataIndex === 'is-active') {
    return <Checkbox checked={record[dataIndex]} disabled={!editing} />
  }
  if (dataIndex === 'entity_data') {
    const options = { disabled: !editing }
    // @ts-ignore
    return editing ? <InputPhone UISchema={ options }/> : children
  }

  return editing ? <Input /> : children
}

function getEditableCell(columns2, getNode) {
  const cell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const required = columns2?.find?.(item => item?.['dataIndex'] === dataIndex)?.required || false
    const node = getNode({
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
    })
    const message = 'Укажите ' + (title?.toLocaleLowerCase?.() || '')
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            valuePropName={dataIndex === 'is-active' ? 'checked' : undefined}
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[{ required, message }]}
          >
            {node}
          </Form.Item>
        ) : (
          node
        )}
      </td>
    )
  }
  return cell
}

function getOnCell(col, editingKey) {
  return record => ({
    record,
    inputType: col?.dataIndex === 'is-active' ? 'checkbox' : 'text',
    dataIndex: col?.dataIndex,
    title: col.title,
    editing: record.key === editingKey,
  })
}

const columns: any = [
  {
    title: 'Телефон',
    dataIndex: 'entity_data',
    width: 500,
    editable: true,
    required: true,
  },
  {
    title: 'ФИО',
    dataIndex: 'name',
    width: '70%',
    //editable: true,
    //required: true,
  },

]

async function deleteSource({ id }) {
  
}

async function updateSource({ id, source }) {
  
}

async function createSource({ source }) {
  console.log({ source })
  const userId = source['created-by']
  //entity_data
  return fetchAPI(`/api/web-hooks/address-book/${userId}`, {
    method: 'POST',
    body: JSON.stringify({
      phone: source['entity_data'],
    }),
  }).then(item => item?.['data']?.['id'] || Date.now())
}

async function fetchSources(userId) {
  return fetchAPI(`/api/web-hooks/address-book/${userId}`).then(r => r?.['data'] || [])
}

const ContextSourcesTable = ({ userId }) => {
  const [form] = Form.useForm()
  const [data, setData] = useState<any>([])
  const [editingKey, setEditingKey] = useState<string>('')
 
  useEffect(() => {
    
    fetchSources(userId)
      .then(result => result?.map(item => ({ ...item, key: String(item.id) })))
      .then(setData)
      
  }, [userId])

  const edit = useCallback(
    record => {
      form.setFieldsValue({ 'is-active': true, ...record })
      setEditingKey(record.key)
    },
    [form, setEditingKey],
  )

  const cancel = useCallback(
    (id?: number) => {
      if (!id) {
        setData([].concat)
      }
      form.resetFields()
      setEditingKey('')
    },
    [form, setData, setEditingKey],
  )

  const save = useCallback(
    async record => {
      try {
        const row = await form.validateFields()
        const source = { ...row, 'created-by': Number(userId) }
        if (record.id) {
          updateSource({ id: record.id, source })
        }
        if (Boolean(record.id) === false) {
          
          const id = await createSource({ source })
          const nextSouece = { source, id }
          console.log({ ...source, id })
          setData(nextData => [{ ...source, id }].concat(nextData).filter(item => item['entity_data']))
        }
        form.resetFields()
        setEditingKey('')
      } catch (errInfo) {
        console.warn('Validate Failed:', errInfo)
      }
    },
    [form, setEditingKey, userId],
  )

  const addRow = useCallback(() => {
    const newRow = {
      'name': '',
      'entity_data': '',
      key: 'new',
      'created-by': Number(userId),
    }
    const nextData = [newRow, ...data]
    setData(nextData)
    setEditingKey('new')
  }, [data, userId])

  const handleDeleteContextSource = useCallback(
    async (id: number) => {
       deleteSource({ id })
    },
    [],
  )

  const actionsRender = useCallback(
    (_, record) => {
      const editable = record.key === editingKey
      return editable ? (
        <TableButtonWrapper>
          <button onClick={() => save(record)}>
            <SaveFilled style={{ color: '#ACACAC' }} />
          </button>
          <button onClick={() => cancel(record.id)}>
            <CloseSquareFilled style={{ color: '#ACACAC' }} />
          </button>
        </TableButtonWrapper>
      ) : (
        <TableButtonWrapper disabled={Boolean(editingKey)}>
          <button disabled={Boolean(editingKey)} onClick={() => edit(record)}>
            <EditTwoTone twoToneColor="#ACACAC" />
          </button>
          <button
            disabled={Boolean(editingKey)}
            onClick={() => handleDeleteContextSource(record.id)}
          >
            <DeleteTwoTone twoToneColor="#ACACAC" />
          </button>
        </TableButtonWrapper>
      )
    },
    [handleDeleteContextSource, save, cancel, edit, editingKey],
  )

  const mergedColumns = useMemo(() => {
    const activesColumns = columns.concat({
      fixed: 'right',
      title: 'Действие',
      width: 40,
      render: actionsRender,
      dataIndex: 'actives',
    })
    return activesColumns.map(col => {
      if (col.editable) {
        return { ...col, onCell: getOnCell(col, editingKey) }
      }
      return { ...col }
    })
  }, [actionsRender, editingKey])
  const cell = useMemo(() => getEditableCell(mergedColumns, getNode), [mergedColumns])

  return (
    <Form form={form} component={false}>
      <Form.Item>
        <Space size="large">
          <Button disabled={Boolean(editingKey)} onClick={addRow}>
            Добавить
          </Button>
        </Space>
      </Form.Item>
      <Table
        components={{ body: { cell } }}
        loading={false}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        rowKey="entity_data"
        pagination={false}
      />
    </Form>
  )
}

export default ContextSourcesTable
