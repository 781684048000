import React, { useMemo } from 'react'
import dayjs from 'dayjs'

function Table({ data, stageCode }) {
  const [manufacture, loading, shipment, mounting] = useMemo(() => [
    dayjs(data?.['manufacture'] ?? null).isValid() ? dayjs(data?.['manufacture']).format('DD.MM.YYYY') : 'Нет',
    dayjs(data?.['loading'] ?? null).isValid() ? dayjs(data?.['loading']).format('DD.MM.YYYY') : 'Нет',
    dayjs(data?.['shipment'] ?? null).isValid() ? dayjs(data?.['shipment']).format('DD.MM.YYYY') : 'Нет',
    dayjs(data?.['mounting'] ?? null).isValid() ? dayjs(data?.['mounting']).format('DD.MM.YYYY') : 'Нет',
  ], [data])

  return (
    <table className='table-act-source'>
      <tbody>
        <tr>
          <th scope="row">Производство:</th>
          <td>{manufacture}</td>
        </tr>
        <tr>
          <th scope="row">Погрузка:</th>
          <td>{loading}</td>
        </tr>
        <tr>
          <th scope="row">Клиент:</th>
          <td>{shipment}</td>
        </tr>
        <tr>
          <th scope="row">Монтаж:</th>
          <td>{mounting}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default Table