import React, { useCallback, useRef, useState, FC, useEffect, useMemo } from 'react';
import FileS3 from '../../../../connectors/multi-file-s3-json-form/multi-file-s3-json-form'
import { InputWrapper, FileUploaderWrapper } from './styles'
import InputItem from 'antd-mobile/lib/input-item';
import { IValidationMenuComponentProps } from './interface'

export const ValidationMenuComponent: FC<IValidationMenuComponentProps> = props => {
  const { format, code, value, title = '', updateData, onInProcess = () => {} } = props

  const inputRef = useRef<Record<string, HTMLInputElement>>({})
  const [inputValue, setInputValue] = useState<Record<string, any>>({})
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (value && code) {
      setInputValue({
        [code]: value,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (inputRef.current && inputRef.current[code]) {
      inputRef.current[code].focus()
    }
  }, [code])

  const handleUploadDocument = useCallback((index: number) => (fileId: number[]) => {
    let filesId: number[] = fileId

    onInProcess(false)

    setInputValue(prevState => {
      const prevFilesId = prevState[code] || []

      prevFilesId.splice(index, 1)
      filesId = prevFilesId
      filesId.push(...fileId)

      return {
        ...prevState,
        [code]: filesId,
      }
    })

    updateData({
      [code]: filesId,
    })
  }, [code, onInProcess, updateData])

  const handleInputChange = useCallback(newValue => {
    let newInputValue = inputValue

    setInputValue(prevState => {
      newInputValue = {
        ...prevState,
        [code]: newValue,
      }

      return newInputValue
    })

    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      if (Object.keys(newInputValue).length > 0) {
        updateData(newInputValue)
      }
    }, 1000)
  }, [code, inputValue, updateData])

  const handlers = useMemo(() => ({
    'file-s3': handleUploadDocument,
    'production-order-number': handleInputChange,
    'number-contract': handleInputChange,
  }), [handleInputChange, handleUploadDocument])

  const getNewFileIndex = useCallback(() => (
    Array.isArray(inputValue[code]) ? inputValue[code].length : 0
  ), [code, inputValue])

  return (
    <>
      {format === 'file-s3' && (
        <>
          {Array.isArray(inputValue[code]) && inputValue[code].map((itemValue, index) => itemValue && (
            <FileUploaderWrapper key={`document-file-${itemValue}`}>
              <FileS3
                schema={{ 'title': title }}
                value={itemValue}
                onChange={handleUploadDocument(index)}
                onStartUpload={onInProcess}
              />
            </FileUploaderWrapper>
          ))}
          <FileS3
            schema={{ 'title': title }}
            key={`default-file-uploader-${getNewFileIndex()}`}
            value={[]}
            onChange={handleUploadDocument(getNewFileIndex())}
            onStartUpload={onInProcess}
          />
        </>
      )}
      {code === 'number-contract' && (
        <InputWrapper>
          <InputItem
            ref={ref => inputRef.current[code] = ref}
            type="number"
            placeholder="Введите номер договора"
            value={inputValue[code]}
            onChange={handlers[code]}
          />
        </InputWrapper>
      )}
      {code === 'production-order-number' && (
        <InputWrapper>
          <InputItem
            type="number"
            placeholder="Введите номер заказа"
            value={inputValue[code]}
            onChange={handlers[code]}
          />
        </InputWrapper>
      )}
    </>
  )
}
