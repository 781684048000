import styled from "styled-components";

export const PopoverIconWrapper = styled.div`
  height: 100%;

  padding: 0 15px;
  margin-right: -15px;

  display: flex;
  align-items: center;
`

export const PopoverItem = styled.span`
  white-space: nowrap;
`

export const SelectedFilterItemsWrapper = styled.span`
  white-space: nowrap;
  color: #888888;
`
