import fetchAPI from '../../../lib/utils/fetch-api'
import {
  setAppealId,
  setAppealsEnumsData,
  setAppealsEnumsFailed,
  setAppealsEnumsLoading,
  setAppealsFormData,
  setAppealsFormFailed,
  setAppealsFormLoading,
} from './actions'

export function getAppealsForm(id) {
  return async dispatch => {
    dispatch(setAppealsFormLoading())
    try {
      const response = await fetchAPI(`/api/appeal/${id}`) || []
      if (response['errors'] || response?.error) {
        let error = response['errors'] || response?.error
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setAppealsFormFailed(error))
      }
      return dispatch(setAppealsFormData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setAppealsFormFailed('При загрузки полей возникла ошибка'))
    }
  }
}

export function nextStage(id, formData) {
  return async dispatch => {
    dispatch(setAppealsFormLoading())
    try {
      const response =  await fetchAPI(
        `/api/appeal/${id}`,
        { method: 'POST', body: JSON.stringify(formData) }
      )

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error =  error.join( )
        }
        return dispatch(setAppealsFormFailed(error))
      }

      return dispatch(setAppealsFormData(response))
    } catch (err) {
      return dispatch(setAppealsFormFailed(err as string))
    }
  }
}

export function updateFormData(id, formData) {
  return async dispatch => {
    try {
      const response = await fetchAPI(
        `/api/appeal/${id}`,
        {
          method: 'POST', body: JSON.stringify(formData)
        }
      )

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setAppealsFormFailed(error))
      }

      if ((!id || id === 'new') && response?.id) {
        return dispatch(setAppealId(response.id))
      }

      return
    } catch (err) {
      return dispatch(setAppealsFormFailed(err as string))
    }
  }
}

export function getAppealsEnums(id: string) {
  return async dispatch => {
    dispatch(setAppealsEnumsLoading())
    try {
      const response = await fetchAPI(`/api/appeal/${id}/enum`)

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setAppealsEnumsFailed(error))
      }

      return dispatch(setAppealsEnumsData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setAppealsEnumsFailed('При загрузки enum возникла ошибка'))
    }
  }
}
