import React, { useCallback, useEffect, useMemo, useRef, useImperativeHandle, useState } from 'react'
import { ITime, ITimePickerRange } from './interface'
import MaskedInput from 'react-input-mask'
import { TimePickerRangeWrapper, ClockCircleOutlined } from './styles'


const InputTime = React.forwardRef(function ({ value, onChange, UISchema, onPressEnter }: ITime, ref) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const refContainer = useRef<any>(null)
  useImperativeHandle(ref, () => ({
    focus: () => {
      refContainer.current?.state?.input?.focus()
    }
  }))

  const localOnChange = useCallback(({ target })=> {
    onChange(target.value)
  }, [onChange])

  const mask = useMemo(()=> ([/[0-2]/, /[0-9]/,  ':', /[0-5]/, /[0-9]/]), [])

  return <MaskedInput 
    className='ant-input' 
    alwaysShowMask={true}
    maskPlaceholder="__:__"
    suffix={<ClockCircleOutlined />}
    value={value} 
    mask={mask}
    disabled={disabled} 
    style={{ width: 90 }} 
    onPressEnter={onPressEnter} 
    onChange={localOnChange} 
    ref={refContainer} />
})

function TimePickerRange({ value, onChange, UISchema }: ITimePickerRange) {
  const refContainer = useRef<any>(null)
  const [localValue, setLocalValue] = useState<any>(() => {
    if (typeof value !== 'string') {
      return
    }
    const [leftValue, rightValue] = value?.split('-')
    const isLeftValue = leftValue.split(':').every(d => isFinite(Number(d)))
    const isRightValue = rightValue.split(':').every(d => isFinite(Number(d)))
    if (isLeftValue && isRightValue) {
      return value
    }
  })
  const localOnChange = useCallback((value) => {
    setLocalValue(value)
    if (typeof value !== 'string') {
      return
    }
    const [leftValue, rightValue] = value?.split('-')
    const isLeftValue = leftValue.split(':').every(d => isFinite(Number(d)))
    const isRightValue = rightValue.split(':').every(d => isFinite(Number(d)))

    if ((isLeftValue && isRightValue) && (localValue !== value)) {
      onChange(value)
    }
  }, [localValue, onChange])

  useEffect(() => {
    if (!(typeof value === 'string')) {
      return
    }
    const [leftValue, rightValue] = value?.split('-')
    const isLeftValue = leftValue.split(':').every(d => isFinite(Number(d)))
    const isRightValue = rightValue.split(':').every(d => isFinite(Number(d)))
    if ((isLeftValue && isRightValue) && (localValue !== value)) {
      setLocalValue(value)
    }
  }, [value, localValue])

  const [leftValue, rightValue] = useMemo(() => {
    if (typeof localValue !== 'string') {
      return ['__:__', '__:__']
    }
    return localValue.split('-')
  }, [localValue]) 

  const leftOnChange = useCallback((nextValue)=> {
    localOnChange(`${nextValue}-${rightValue}`)
  }, [rightValue, localOnChange])

  const rightOnChange = useCallback((nextValue)=> {
    localOnChange(`${leftValue}-${nextValue}`)
  }, [leftValue, localOnChange])

  const onPressEnter = useCallback(({ target }) => {
    target?.blur()
    refContainer.current.focus()
  }, [])

  return (
    <TimePickerRangeWrapper className="time-range">
      <InputTime value={leftValue} onChange={leftOnChange} onPressEnter={onPressEnter} UISchema={UISchema}/>
      <InputTime value={rightValue} onChange={rightOnChange} UISchema={UISchema} ref={refContainer} />
    </TimePickerRangeWrapper>
  )
}

export default React.memo(TimePickerRange, (prev, next) => {
  if(prev.value === next.value){
    return true
  }
  return prev?.UISchema === next?.UISchema;
})
