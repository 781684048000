import styled from 'styled-components'

export const MapWrapper = styled.div`
  padding: 0 0;
`

export const MapTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 12px;
`

export const MapContent = styled.div`
  background: white;
  .ant-tabs-card {
    display: none;
  }
  &>.ant-form-horizontal>.ant-form-item {
    margin-bottom: 0 !important;
  } 
`

export const TabContent = styled.div`
  padding: 0;
`

export const AlertWrapper = styled.div`
  margin-bottom: 40px;
`

export const TaskWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`
